<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { removeWatermark, setWaterMark } from "@/libs/waterMark";
export default {
  name: "App",
  provide(){
    return{
      reload:this.reload
    }
  },
  data(){
    return{
      isRouterAlive:true
    }
  },
  components: {},
  mounted() {
    let fullName = this.getStore("useInfo")
      ? JSON.parse(this.getStore("useInfo")).nickName+"   "+new Date().Format("yyyy-MM-dd hh:mm:ss")
      : "";
    setWaterMark("", fullName);
    // this.$nextTick(() => {
    //   // 禁用选择
    //   document.onselectstart = new Function("event.returnValue=false");
    // });
  },
  methods:{
    reload(){
      this.isRouterAlive=false
      this.$nextTick(()=>{
        this.isRouterAlive=true
      })
    }
  },
  destroyed() {
    removeWatermark();
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #f0f0f0;
  /*background: url(assets/bg1.jpg) center !important;
		background-size: cover;*/
  // background: #1F2D3D;
  font-family: Microsoft YaHei, Arial, Helvetica, sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
}
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.toolbar {
  background: #f2f2f2;
  padding: 10px;
  //border:1px solid #dfe6ec;
  margin: 10px 0;
  .el-form-item {
    margin-bottom: 10px;
  }
}

.box-height {
  max-width: 1640px;
  padding: 0 20px;
  margin: 0 auto;
}
.el-collapse-item__header{
  color: #606266!important;
}
.el-pagination{
  color: #606266!important
}
// ::-webkit-scrollbar{
//   display: none
// }
// ::-webkit-scrollbar {
//     width: 5px;
//   }

@import "./common/css/drawer.less";
@import "./common/css/canEdit.less";
@import "./common/css/common.less";
</style>