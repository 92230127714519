 <template>
  <section >
    <el-form @submit.native.prevent :model="backgroundSurveyForm" label-width="130px" ref="backgroundSurveyForm">
      <el-form-item
        label="背调时间："
        :rules="[
      { required: true, message: '请选择背调时间', trigger: 'change' },
    ]"
        prop="time"
      >
        <el-date-picker 
          v-model="backgroundSurveyForm.time"
          type="date"
          style="width:360px"
          placeholder="选择日期"
          value-format="yyyy-MM-dd"
        ></el-date-picker>
      </el-form-item>

      <el-form-item label="背调报告：" prop="filePath" class="is-required">
        <imgUpload  :fileInfoList="fileInfoList"></imgUpload>
      </el-form-item>
    </el-form>
  </section>
</template>
<script>
import imgUpload from "./imgUpload";
import { addOrEditBackground, getOperaterecordById } from "../api/api";
export default {
  components: {
    imgUpload
  },
  props: ["projectResumeId","projectResumeId1"],
  data() {
    return {
      backgroundSurveyForm: {
        time: new Date().Format("yyyy-MM-dd"),
        filePath: null,
        fileName: null,
        id: 0,
        version: 0
      },
      saveloading: false,
      fileInfoList: []
    };
  },

  methods: {
    saveBackgroundSurvey: function(formName) {
      return new Promise( (resolve, reject) => {
        this.$refs[formName].validate(valid => {
          if (valid) {
            if (!this.fileInfoList.length) {
              reject();
              this.$message.error("请上传背调报告!");
              return;
            }
            this.saveloading = true;
            let queryData = {
              id: this.backgroundSurveyForm.id,
              objectContent: {
                files: [],
                recommendTime: this.backgroundSurveyForm.time
              },
              projectResumeId:  this.projectResumeId,
              version: this.backgroundSurveyForm.version
            };
            for (let i in this.fileInfoList) {
              queryData.objectContent.files.push({
                fileName: this.fileInfoList[i].name,
                fileUrl: this.fileInfoList[i].url
              });
            }
            // return;
            addOrEditBackground(queryData).then(res => {
            this.$emit('changeSaveLoading', false);

              if (res.success) {
                this.$message.success("操作成功");
                this.$refs.backgroundSurveyForm.resetFields();
                this.fileInfoList = [];
                this.$emit("loadList");
              }
              resolve();
              this.saveloading = false;
            });
          } else {
            reject();
            return false;
          }
        });
      })

    },
    loadEditInfo(row) {
      getOperaterecordById({
        id: row.id
      }).then(res => {
        if (res.success) {
          this.backgroundSurveyForm.time =
            res.result.objectContent.recommendTime;
          this.backgroundSurveyForm.fileName =
            res.result.objectContent.files[0].fileName;
          this.backgroundSurveyForm.filePath =
            res.result.objectContent.files[0].fileUrl;
          for (let i in res.result.objectContent.files) {
            this.fileInfoList.push({
              name: res.result.objectContent.files[i].fileName,
              url: res.result.objectContent.files[i].fileUrl
            });
          }
          this.backgroundSurveyForm.id = res.result.id;
          this.backgroundSurveyForm.version = res.result.version;
        }
      });
    }
  }
};
</script>
