<template>
  <section class="financialTasksFormRulePP">
    <el-form
      :model="financialTasksForm"
      ref="financialTasksForm"
      :validate-on-rule-change="false"
      label-width="150px"
      :rules="
        financialTasksForm.type == '特殊费用'
          ? financialTasksFormRuleAnother
          : financialTasksFormRule
      "
    >
      <el-collapse v-model="activeNames">
        <el-collapse-item title="开票信息" class="payInfo" name="1">
          <div style="padding: 15px">
            <el-row class="financialTasksFormInfo">
              <el-col :span="24" v-if="financialTasksForm.type == '特殊费用'"
                ><el-tag
                  style="display: block; margin-bottom: 20px"
                  type="danger"
                  >注意：特殊费用不计入开票业绩，请谨慎选择，如有疑问请咨询管理员</el-tag
                ></el-col
              >

              <el-col :span="10">
                <el-form-item label="开票类型：" class="is-required">
                  <div style="display: flex">
                    <el-form-item label="" prop="invoiceType" style="width: 100px;margin-right: 10px;height: 34px">
                      <el-select
                        v-model="financialTasksForm.invoiceType"
                        @change="typeChange"
                        style="width: 100%;height: 34px"
                        placeholder="请选择"
                      >
                        <el-option label="正常开票" value="正常开票"></el-option>
                        <el-option label="红冲开票" value="红冲开票"></el-option>
                        </el-select>
                      </el-form-item>
                    <el-form-item label="" prop="type" style="width: calc( 100% - 110px);height: 34px">
                      <el-select
                        v-model="financialTasksForm.type"
                        @change="typeChange"
                        style="width: 100%;height: 34px"
                        placeholder="请选择"
                      >
                        <el-option label="首付款" value="0"></el-option>
                        <el-option label="尾款" value="1"></el-option>
                        <el-option label="预付款" value="2"></el-option>
                        <el-option label="全款" value="3"></el-option>
                        <el-option label="特殊费用" value="特殊费用"></el-option>
                        <el-option label="其他" value="4"></el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :offset="2" :span="10">
                <el-form-item label="服务费用：">
                  <el-input
                    :value="projectDetail.money"
                    class="disabledInput"
                    disabled
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="已开票比例：">
                  <el-input
                    :value="projectDetail.percent"
                    class="disabledInput"
                    disabled
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :offset="2" :span="10">
                <el-form-item label="已开票金额：">
                  <el-input
                    :value="projectDetail.invoiceMoney"
                    class="disabledInput"
                    disabled
                  ></el-input>
                </el-form-item>
              </el-col>

              <el-col
                :span="10"
                style="position: relative"
                v-show="financialTasksForm.type != '特殊费用'"
              >
                <el-form-item
                  label="开票比例："
                  prop="percent"
                  key="financialTasksFormpercent"
                  class="percent"
                >
                  <el-input
                    v-model.number="financialTasksForm.percent"
                    onKeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                    type="number"
                    style="vertical-align: middle"
                    :disabled="isPercentAbled"
                  >
                    <template slot="suffix">%</template>
                  </el-input>
                </el-form-item>
                <el-button
                  size="mini"
                  type="primary"
                  plain
                  v-show="financialTasksForm.type != '特殊费用'"
                  style="position: absolute; right: -70px; top: 5px"
                  @click="calculate(1)"
                  >计算</el-button
                >
              </el-col>
              <el-col
                :offset="financialTasksForm.type != '特殊费用' ? 2 : 0"
                style="position: relative"
                :span="10"
              >
                <el-form-item label="开票金额：" prop="money">
                  <el-input
                    type="number"
                    v-model.number="financialTasksForm.money"
                    autocomplete="off"
                    placeholder="正常开票和红冲开票均填写正数"
                    @mousewheel.native.prevent
                    :disabled="isMoneyAbled"
                  ></el-input>
                </el-form-item>
                <el-button
                  size="mini"
                  type="primary"
                  plain
                  v-show="financialTasksForm.type != '特殊费用'"
                  style="position: absolute; right: -70px; top: 5px"
                  @click="calculate(2)"
                  >计算</el-button
                >
              </el-col>
            </el-row>
            <el-row class="financialTasksFormInfo">
              <el-col :span="10">
                <el-form-item
                  prop="conventionMakeInvoiceDate"
                  label="约定开票时间："
                >
                  <el-date-picker
                    v-model="financialTasksForm.conventionMakeInvoiceDate"
                    type="date"
                    placeholder="请选择日期"
                    value-format="yyyy/MM/dd"
                    style="width: 100%"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :offset="2" :span="10">
                <el-form-item
                  prop="conventionToAccountDate"
                  label="约定到账时间："
                  :rules="[
                    { required:financialTasksForm.invoiceType!=='红冲开票', message: '请选择约定到账时间', trigger: 'change' },
                  ]"
                >
                  <el-date-picker
                    v-model="financialTasksForm.conventionToAccountDate"
                    type="date"
                    placeholder="请选择日期"
                    value-format="yyyy/MM/dd"
                    style="width: 100%"
                  ></el-date-picker>
                </el-form-item>
              </el-col>

              <el-col :span="10">
                <el-form-item
                  prop="filePath"
                  label="付款通知书："
                  class="filePathBook"
                >
                  <el-upload
                    ref="upload"
                    class="upload-demo"
                    drag
                    :headers="myHeaders"
                    :action="actionUrl"
                    :on-success="handleInfoSuccess"
                    :file-list="fileInfoList"
                    :on-remove="handleRemove"
                    :before-upload="beforeUpload"
                    accept=".doc, .docx, .pdf, .jpg, .xls, .xlsx"
                  >
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">
                      将文件拖到此处，或 <span class="tabHref">点击上传</span>
                    </div>
                    <div
                      slot="tip"
                      style="
                        margin-top: -5px;
                        font-size: 12px;
                        line-height: 1.5;
                        color: #606266;
                      "
                    >
                      支持docx，doc，xlsx，xls，pdf，jpg文件，单份不超过20M
                    </div>
                  </el-upload>
                </el-form-item>
              </el-col>
              <el-col :span="22">
                <el-form-item
                  prop="remark"
                  label="备注："
                  style="margin-bottom: 14px"
                >
                  <el-input
                    placeholder="可备注邮寄/盖章等信息，如无备注可填写“无”"
                    v-model.trim="financialTasksForm.remark"
                    maxlength="100"
                    type="textarea"
                    :rows="3"
                    style="width: 100%"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="22">
                <el-form-item
                  label="关联合同："
                  :prop="financialTasksForm.noContract ? '' : 'contractId'"
                >
                  <div style="display: flex; align-items: center">
                    <el-select
                      v-model="financialTasksForm.contractId"
                      style="width: 100%; margin-right: 10px"
                      placeholder="请选择"
                      :disabled="financialTasksForm.noContract"
                    >
                      <el-option
                        v-for="i in companyList"
                        :key="i.contractId"
                        :label="i.contractName"
                        :value="i.contractId"
                      ></el-option>
                    </el-select>
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="根据公司财务规定，为确保三流一致请选择关联合同，您可选择‘生效中’或‘待归档’的合同"
                      placement="bottom"
                    >
                      <i
                        class="iconfont icon icon-wenhao-xiangsu"
                        style="margin-right: -25px"
                      ></i>
                    </el-tooltip>
                  </div>
                  <div>
                    <el-checkbox
                      v-model="financialTasksForm.noContract"
                      label="走无合同审批流程"
                      @change="chioceNoContract"
                      :disabled="projectDetail.hasValidContract"
                    ></el-checkbox>
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="适用与客户目前没有签订合同，双方以协议、邮件等方式约定按次、岗位个数等方式付款；需提供双方往来邮件、协商内容、对方身份证明等材料；申请后将由Lucy审批-->法务审批；"
                      placement="bottom"
                    >
                      <i
                        class="iconfont icon icon-wenhao-xiangsu"
                        style="font-size: 20px; margin-left: 15px"
                      ></i>
                    </el-tooltip>
                  </div>
                  <div
                    class="DialogTips tip"
                    v-if="financialTasksForm.noContract"
                  >
                    <p>走该流程前请先与Lucy沟通确认。</p>
                    <p>本次审批流程仅针对此次开票，请尽快与客户签订合同。</p>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="22" v-if="financialTasksForm.noContract">
                <el-form-item prop="noContractReason" label="申请原因：">
                  <el-select
                    v-model="financialTasksForm.noContractReason"
                    style="width: 100%"
                    placeholder="请选择"
                    clearable
                    @change="handelNoContractReason"
                  >
                    <el-option
                      v-for="i in reasonList"
                      :key="i"
                      :label="i"
                      :value="i"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col
                :span="22"
                v-if="
                  financialTasksForm.noContract &&
                  financialTasksForm.noContractReason == '合同超期尚未续约'
                "
              >
                <el-form-item
                  prop="noContractRelatedContractId"
                  label="关联到期合同："
                  :rules="[
                    {
                      required: true,
                      message: '请选择关联到期合同',
                      trigger: 'change',
                    },
                  ]"
                >
                  <el-select
                    v-model="financialTasksForm.noContractRelatedContractId"
                    style="width: 100%"
                    placeholder="请选择"
                    @change="chioceNoContractRelatedContractId"
                    :disabled="
                      !valiNoContractdata.first &&
                      valiNoContractdata.noContractRelatedContractId
                    "
                  >
                    <el-option
                      v-for="i in noContractCompanyList"
                      :key="i.id"
                      :label="i.name"
                      :value="i.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col
                :span="22"
                v-if="
                  financialTasksForm.noContract &&
                  (financialTasksForm.noContractReason == '正在签约中' ||
                    financialTasksForm.noContractReason == '合同超期尚未续约')
                "
              >
                <el-form-item prop="agreedSignDate" label="约定签约时间：">
                  <el-date-picker
                    v-model="financialTasksForm.agreedSignDate"
                    type="date"
                    placeholder="请选择日期"
                    value-format="yyyy-MM-dd"
                    style="width: 100%"
                    :disabled="
                      !valiNoContractdata.first &&
                      valiNoContractdata.agreedSignDate
                    "
                  ></el-date-picker>
                  <p v-if="isShowTips" class="tip">约定签约时间已超期！</p>
                </el-form-item>
              </el-col>
              <el-col
                :span="22"
                v-if="
                  financialTasksForm.noContract &&
                  financialTasksForm.noContractReason
                "
              >
                <el-form-item
                  prop="noContractCommentFile"
                  label="证明材料："
                  class="filePathBook is-required"
                >
                  <el-upload
                    ref="upload"
                    class="upload-demo"
                    drag
                    :headers="myHeaders"
                    :action="actionUrl"
                    :on-success="handleInfoSuccess1"
                    :file-list="fileInfoList1"
                    :on-remove="handleRemove1"
                    :before-upload="beforeUpload1"
                    accept=".doc, .docx, .pdf, .jpg, .xls, .xlsx, .ppt, .pptx, .jpeg, .png"
                  >
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">
                      将文件拖到此处，或 <span class="tabHref">点击上传</span>
                    </div>
                    <div
                      slot="tip"
                      style="
                        margin-top: -5px;
                        font-size: 12px;
                        line-height: 1.5;
                        color: #606266;
                        padding-bottom: 10px;
                      "
                    >
                      支持docx，doc，xlsx，xls，ppt，pptx，pdf，jpg，jpeg，png文件，单份不超过20M，最多支持20份文件。
                    </div>
                  </el-upload>
                </el-form-item>
              </el-col>
              <el-col
                :span="22"
                v-if="
                  financialTasksForm.noContract &&
                  financialTasksForm.noContractReason
                "
              >
                <el-form-item
                  prop="noContractComment"
                  label="情况说明："
                  style="margin-bottom: 14px"
                >
                  <el-input
                    placeholder="请输入情况说明"
                    v-model.trim="financialTasksForm.noContractComment"
                    maxlength="100"
                    type="textarea"
                    :rows="3"
                    style="width: 100%"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col
                :span="22"
                v-if="
                  financialTasksForm.noContract &&
                  financialTasksForm.noContractReason == '正在签约中'
                "
              >
                <el-form-item
                  prop="conventionMakeInvoiceDate"
                  label="关联签约中的合同："
                >
                  <el-select
                    v-model="financialTasksForm.noContractRelatedContractId"
                    style="width: 100%"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="i in noContractCompanyList"
                      :key="i.id"
                      :label="i.name"
                      :value="i.id"
                    ></el-option>
                  </el-select>
                  <p
                    style="
                      margin-top: 5px;
                      font-size: 12px;
                      line-height: 1.5;
                      color: #606266;
                      padding-bottom: 10px;
                    "
                  >
                    请关联在审批流程中的合同（如有）
                  </p>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-collapse-item>
        <el-collapse-item title="业绩分配" name="3" class="payInfo">
          <template slot="title">
            <div class="scoreTot">
              业绩分配<span
                >（当前分配比例总和：<s>{{ totalPercent }}%</s>）</span
              >
            </div>
          </template>
          <div class="searchList">
            <el-collapse accordion>
              <el-collapse-item
                class="1"
                :name="index"
                v-for="(
                  item, index
                ) in financialTasksForm.performanceAllocations"
                :key="index"
              >
                <template slot="title">
                  <div class="liStyle">
                    <span>
                      <el-select
                        placeholder="请选择"
                        v-model="item.sysAllocationPlanId"
                        @change="changeItem($event, index)"
                        size="mini"
                        disabled
                      >
                        <el-option
                          v-for="(i, indexKPI) in JSON.parse(
                            defaultPerformanceAllocations
                          )"
                          :key="'sel' + indexKPI"
                          :label="i.roleName"
                          :value="i.sysAllocationPlanId"
                        ></el-option>
                      </el-select>
                    </span>
                    <span>
                      <el-select
                        disabled
                        v-model="
                          financialTasksForm.performanceAllocations[index]
                            .userId
                        "
                        placeholder="请选择"
                        collapse-tags
                        filterable
                        style="margin-left: 10px"
                        size="mini"
                        @focus="selected()"
                        @change="checkUser($event, index)"
                        popper-class="selectManager"
                      >
                        <el-input
                          v-model="searchUserId"
                          class="searchInput"
                          size="small"
                          style="
                            width: 90%;
                            margin-left: 5%;
                            margin-bottom: 5px;
                          "
                          @change="changed()"
                        >
                          <i
                            slot="suffix"
                            @click="searchOptions"
                            class="iconfont iconsousuo"
                            style="
                              height: 32px;
                              line-height: 32px;
                              display: block;
                            "
                          ></i>
                        </el-input>
                        <el-option
                          value="0"
                          disabled
                          label="请输入关键字搜索"
                        ></el-option>
                        <el-option
                          v-for="(i, index) in searchUserList"
                          :key="'selUser' + index"
                          :label="i.name"
                          :value="i.id"
                        ></el-option>
                      </el-select>
                    </span>
                    <el-form-item
                      label="比例："
                      style="
                        display: inline-block;

                        margin-top: 8px;
                      "
                      label-width="80px"
                      class="is-required"
                    >
                      <!-- float: right -->
                      <div @click.stop style="display: inline-block">
                        <el-input-number
                          v-model="item.percent"
                          disabled
                          :min="0"
                          :max="100"
                          label="比例"
                        ></el-input-number>
                      </div>
                    </el-form-item>
                  </div>
                </template>
                <div class="contentformance">
                  <el-form>
                    <el-row>
                      <el-col :span="11">
                        <el-form-item label="集团：">
                          <span
                            v-for="(_item, idx) in item.allocationItems
                              ? item.allocationItems.groups
                              : []"
                            :key="idx"
                            >{{ _item.departmentName }} -
                            {{ _item.percent }}%<span
                              v-if="
                                item.allocationItems.groups &&
                                idx != item.allocationItems.groups.length - 1
                              "
                              >、</span
                            ></span
                          >
                        </el-form-item>
                      </el-col>
                      <el-col :offset="1" :span="11">
                        <el-form-item label="地区：">
                          <span
                            v-for="(_item, idx) in item.allocationItems
                              ? item.allocationItems.areas
                              : []"
                            :key="idx"
                            >{{ _item.departmentName }} -
                            {{ _item.percent }}%<span
                              v-if="
                                item.allocationItems.areas &&
                                idx != item.allocationItems.areas.length - 1
                              "
                              >、</span
                            ></span
                          >
                        </el-form-item>
                      </el-col>
                      <el-col :span="11">
                        <el-form-item label="团队：">
                          <span
                            v-for="(_item, idx) in item.allocationItems
                              ? item.allocationItems.teams
                              : []"
                            :key="idx"
                            >{{ _item.departmentName }} -
                            {{ _item.percent }}%<span
                              v-if="
                                item.allocationItems.teams &&
                                idx != item.allocationItems.teams.length - 1
                              "
                              >、</span
                            ></span
                          >
                        </el-form-item>
                      </el-col>
                      <el-col :offset="1" :span="11">
                        <el-form-item label="子团队：">
                          <span
                            v-for="(_item, idx) in item.allocationItems
                              ? item.allocationItems.subTeams
                              : []"
                            :key="idx"
                            >{{ _item.departmentName }} -
                            {{ _item.percent }}%<span
                              v-if="
                                item.allocationItems.subTeams &&
                                idx != item.allocationItems.subTeams.length - 1
                              "
                              >、</span
                            ></span
                          >
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>
        </el-collapse-item>
      </el-collapse>
    </el-form>
    <el-dialog
      title="编辑"
      :visible.sync="dialogVisibleOfEdit"
      width="45%"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :append-to-body="true"
      class="editPercent"
    >
      <el-row :gutter="20" style="padding-bottom: 10px">
        <el-col :span="24">
          <el-button class="fr" size="mini" type="primary" @click="addPercent()"
            >添加</el-button
          >
        </el-col>
      </el-row>
      <el-form ref="percentForm" :model="percentForm" :rules="ruleForm">
        <el-row
          style="
            padding: 0 30px 0 10px;
            position: relative;
            height: auto;
            margin-bottom: 15px;
          "
          :gutter="20"
          v-for="(i, idx) in currentAllocationItems"
          :key="idx"
        >
          <el-col :span="12" style="height: 40px">
            <el-form-item label-width="0" :prop="'departmentId' + idx">
              <el-select
                v-model="i.departmentId"
                @change="changeDepartment($event, i)"
                clearable
                filterable
              >
                <el-option
                  v-for="item in currentList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="height: 40px">
            <el-form-item
              label="比例%"
              label-width="80px"
              :prop="'percent' + idx"
            >
              <el-input
                v-model.number.trim="i.percent"
                style="width: 100%"
                type="number"
                @mousewheel.native.prevent
              ></el-input>
            </el-form-item>
          </el-col>
          <i
            class="iconfont iconguanbi iconOfClose"
            style="
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              color: #ccc;
            "
            @click="delPercent(idx)"
          ></i>
        </el-row>
      </el-form>
      <div style="padding: 20px 10px 0; color: #ff3f3f">
        累计比例：{{ total }}%（非100%不可保存）
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="dialogVisibleOfEdit = false"
          >取 消</el-button
        >
        <el-button size="mini" type="primary" @click="saveEditPercent()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisibleOfTips"
      width="45%"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :append-to-body="true"
      :before-close="closeTips"
    >
      <div class="tipsContent">
        <p
          class="t_tit"
          style="
            font-size: 18px;
            font-weight: bold;
            text-align: center;
            margin: 15px 0;
          "
        >
          <i class="iconfont el-icon-circle-check" style="font-size: 20px"></i>
          提交成功，开票申请进入审批流程
        </p>
        <div class="t_list">
          <el-steps :active="2" align-center>
            <el-step
              icon="el-icon-d-arrow-right"
              title=""
              description=""
            ></el-step>
            <el-step
              icon="el-icon-arrow-right"
              v-for="(item, index) in tipsData"
              :key="index"
              :title="item.status"
              :description="item.flowContent"
            ></el-step>

            <el-step
              icon="el-icon-video-pause"
              title=""
              description=""
            ></el-step>
          </el-steps>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="closeTips">关 闭</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="信息补充和确认"
      :visible.sync="dialogVisibleOfMsg"
      width="45%"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :append-to-body="true"
      :before-close="closeMsg"
    >
      <div>
        <p style="font-weight: bold; text-align: center">
          申请开票金额<span style="color: red; font-size: 18px">
            {{ queryData.financeTask.payAmount }}元</span
          >
        </p>
        <p style="text-align: center; margin: 15px 0">
          请确认与金蝶申请的开票金额相同
        </p>
        <el-form v-if="!incompleteInvoicing">
          <el-form-item
            class="is-required"
            prop="conventionNextMakeInvoiceDate"
            label="约定下次开票时间："
            style="margin: 15px 50px"
          >
            <el-date-picker
              v-model="queryData.conventionNextMakeInvoiceDate"
              type="date"
              placeholder="请选择日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="closeMsg">取消</el-button>
        <el-button size="mini" type="primary" @click="sumbitFinancetask"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </section>
</template>

<script>
import Cookies from "js-cookie";
import * as api from "../../api/http";
import {
  addOrEditFinancetask,
  getOperaterecordById,
  editProjectInfo,
  getUserBaseInfos,
  financeTaskDefaultInfo,
  getGroupsByType, // 获取集团
  getAreasByType, // 获取地区
  getTeamsByType, // 获取团队
  getSubTeamsByType, // 获取子团队
  departAllocations, //获取用户的集团,地区,团队分配数据
  validateInvoicedPercent, //校验开票比例
  getCompanyContractList, // 获取关联合同
  financeTaskNoContractValidate, //根据无合同开票的原因校验参数
  financeTaskNoContractOptions, //获取无合同开票的关联合同选项
  financeTaskValidateCompletelyInvoice, //校验是否完全开票
} from "../../api/api";
export default {
  props: [
    "projectResumeId",
    "financeTaskId",
    "projectResumeId1",
    "rowFoo",
    "companyId",
  ],
  data() {
    let checkCentPercent = (rule, value, callback) => {
      // debugger
      if (!value || value == 0) {
        return callback(new Error("开票比例不能为空或者0"));
      }
      setTimeout(() => {
        if (value > 100 || value < 0) {
          callback(new Error("请输入1-100的数字"));
        } else {
          callback();
        }
      }, 500);
    };
    let checkMoney = (rule, value, callback) => {
      if (value > this.projectDetail.money) {
        callback(new Error("开票金额不能大于服务费用"));
      } else {
        callback();
      }
    };
    return {
      financialTasksForm: {
        contractId: null,
        invoiceType: null,
        type: null,
        percent: "",
        money: "",
        fineName: null,
        filePath: null,
        conventionMakeInvoiceDate: null,
        conventionToAccountDate: null,
        remark: null,
        version: 0,
        performanceAllocations: [],
        agreedSignDate: "",
        noContractRelatedContractId: "",
        noContractComment: "",
        noContractReason: "",
      },
      activeNames: ["1", "2"],
      financialTasksFormRuleAnother: {
        invoiceType: [
          { required: true, message: "请选择开票类型", trigger: "change" },
        ],
        type: [
          { required: true, message: "请选择开票类型", trigger: "change" },
        ],
        contractId: [
          { required: true, message: "请选择关联合同", trigger: "change" },
        ],
        conventionToAccountDate: [
          { required: true, message: "请选择约定到账时间", trigger: "change" },
        ],
        money: [
          {
            required: true,
            message: "请输入金额，仅限两位小数",
            trigger: "blur",
          },
          {
            pattern: /^\d{1,9}(\.\d{1,2})?$/,
            message: "金额精确到两位小数点,不超过1000000000",
          },
        ],
        noContractComment: [
          { required: true, message: "请输入情况说明", trigger: "blur" },
        ],
        agreedSignDate: [
          { required: true, message: "请选择约定签约时间", trigger: "change" },
        ],
        noContractReason: [
          { required: true, message: "请选择申请原因", trigger: "change" },
        ],
        remark: [
          { required: true, message: "请输入备注", trigger: "blur" },
        ],
      },
      financialTasksFormRule: {
        type: [
          { required: true, message: "请选择开票类型", trigger: "change" },
        ],
        invoiceType: [
          { required: true, message: "请选择开票类型", trigger: "change" },
        ],
        contractId: [
          { required: true, message: "请选择关联合同", trigger: "change" },
        ],
        conventionToAccountDate: [
          { required: true, message: "请选择约定到账时间", trigger: "change" },
        ],
        percent: [
          {
            required: true,
            trigger: "blur",
            validator: checkCentPercent,
          },
        ],
        money: [
          {
            required: true,
            message: "请输入金额，仅限两位小数",
            trigger: "blur",
          },
          {
            pattern: /^\d{1,9}(\.\d{1,2})?$/,
            message: "金额精确到两位小数点,不超过1000000000",
          },
          {
            trigger: ["blur", "change"],
            validator: checkMoney,
          },
        ],
        noContractComment: [
          { required: true, message: "请输入情况说明", trigger: "blur" },
        ],
        agreedSignDate: [
          { required: true, message: "请选择约定签约时间", trigger: "change" },
        ],
        noContractReason: [
          { required: true, message: "请选择申请原因", trigger: "change" },
        ],
        remark: [
          { required: true, message: "请输入备注", trigger: "blur" },
        ],
      },
      projectDetail: {
        money: "",
        contractInvoiceList: [],
        invoiceMoneyAndPercent: "",
      },
      isPercentAbled: false,
      isMoneyAbled: false,
      myHeaders: {
        Authorization: Cookies.get("accessToken"),
      },
      actionUrl: null,
      fileInfoList: [],
      fileInfoList1: [],
      defaultPerformanceAllocations: null,
      searchUserList: [],
      userList: [],
      searchUserId: "",
      performanceList: [],
      dialogVisibleOfEdit: false,
      currentAllocationItems: [],
      currentList: [],
      currentIndex: 0,
      currentEditType: "",
      groupsList: [],
      areasList: [],
      teamsList: [],
      routeDiff: "",
      subTeamsList: [],
      saveloading: false,
      companyList: [],
      noContractCompanyList: [],
      dialogVisibleOfTips: false,
      tipsData: [],
      reasonList: ["客户暂不签约", "正在签约中", "合同超期尚未续约", "双方不签约", "其他"],
      valiNoContractdata: {}, //无合同校验数据
      isShowTips: false,
      dialogVisibleOfMsg: false,
      queryData: {
        financeTask: {
          payAmount: 0,
        },
      },
      incompleteInvoicing: null,
    };
  },
  computed: {
    totalPercent: function () {
      let totalPercent = 0;
      for (let i in this.financialTasksForm.performanceAllocations) {
        if (
          this.financialTasksForm.performanceAllocations[i].percent &&
          this.financialTasksForm.performanceAllocations[i].id != -1
        ) {
          totalPercent +=
            this.financialTasksForm.performanceAllocations[i].percent;
        }
      }
      return totalPercent;
    },
    total: {
      get() {
        let total = 0;
        if (
          this.currentAllocationItems &&
          this.currentAllocationItems.length > 0
        ) {
          this.currentAllocationItems.forEach((item) => {
            if (item.departmentId && item.percent) {
              total += item.percent;
            }
          });
        }
        return total;
      },
      set() {},
    },
    percentForm() {
      let percentForm = {};
      this.currentAllocationItems.forEach((item, index) => {
        percentForm["departmentId" + index] = item.departmentId;
        percentForm["percent" + index] = item.percent;
      });
      return percentForm;
    },
    ruleForm() {
      let ruleForm = {};
      this.currentAllocationItems.forEach((item, index) => {
        ruleForm["departmentId" + index] = [
          { required: true, message: "内容不能为空", trigger: "change" },
        ];
        ruleForm["percent" + index] = [
          {
            type: "number",
            required: true,
            message: "内容不能为空",
            trigger: "change",
          },
          {
            pattern: /^([1-9]|[1-9]\d)(\.\d{1,2})?$|^100$/,
            message: "范围1-100，仅限两位小数",
          },
        ];
      });
      return ruleForm;
    },
  },
  created() {
    this.actionUrl = api.ApiBaseUrl + "/api/file/uploadOne";
    // 获取财务任务初始分配方案
    this.getProjectDetailMethod();
    //获取用户列表
    this.loadUserBase();
    this.getGroups(); // 获取集团
    this.getAreas(); // 获取地区
    this.getTeams(); // 获取团队
    this.getSubTeams(); // 获取子团队
    this.getCompanyContractList(); // 获取关联合同
  },
  watch: {
    rowFoo(n, o) {
      this.getProjectDetailMethod();
    },
  },
  methods: {
    //开票类型切换
    typeChange(type) {
      this.isMoneyAbled = false;
      this.isPercentAbled = false;
      this.financialTasksForm.percent = "";
      this.financialTasksForm.money = "";
    },
    //开票金额计算
    calculate(type) {
      if (type == 1) {
        // debugger
        this.$refs.financialTasksForm.validateField(
          "percent",
          (percentValidate) => {
            if (!percentValidate) {
              this.financialTasksForm.money = +(
                (this.projectDetail.money * this.financialTasksForm.percent) /
                100
              ).toFixed(2);
              this.isMoneyAbled = true;
              this.isPercentAbled = false;
              this.$refs.financialTasksForm.clearValidate("money");
            } else {
              this.$message({
                message: "请输入符合要求的开票比例",
                type: "warning",
              });
            }
          }
        );
      } else {
        this.$refs.financialTasksForm.validateField(
          "money",
          (moneyValidate) => {
            if (!moneyValidate) {
              this.financialTasksForm.percent = +(
                100 *
                (
                  this.financialTasksForm.money / this.projectDetail.money
                ).toFixed(4)
              ).toFixed(2);
              this.isPercentAbled = true;
              this.isMoneyAbled = false;
              this.$refs.financialTasksForm.clearValidate("percent");
            } else {
              this.$message({
                message: "请输入符合要求的开票金额",
                type: "warning",
              });
            }
          }
        );
      }
    },
    //通知书移除
    handleRemove(file, fileList) {
      for (let i in this.fileInfoList) {
        if (this.fileInfoList[i].url == file.url) {
          this.fileInfoList.splice(i, 1);
          break;
        }
      }
      if (this.fileInfoList.length) {
        this.financialTasksForm.filePath = fileList[0].response.result;
        this.financialTasksForm.fileName = fileList[0].name;
      } else {
        this.financialTasksForm.filePath = null;
        this.financialTasksForm.fileName = null;
      }
      this.$refs["financialTasksForm"].validateField("filePath");
    },
    handleInfoSuccess: function (res, file, fileList) {
      this.fileInfoList.push({
        name: file.name,
        url: file.response.result,
      });
      this.financialTasksForm.filePath = res.result;
      this.financialTasksForm.fileName = file.name;
      this.$refs["financialTasksForm"].validateField("filePath");
    },
    // 通知书上传之前
    beforeUpload: function (file) {
      const fileType = file.name.split(".")[file.name.split(".").length - 1];
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (this.fileInfoList.length > 9) {
        this.$message.error("最多支持上传10份");
        return false;
      } else {
        if (
          fileType != "doc" &&
          fileType != "docx" &&
          fileType != "pdf" &&
          fileType != "jpg" &&
          fileType != "xlsx" &&
          fileType != "xls"
        ) {
          this.$message.error("文件格式不正确");
          return false;
        }
        if (!isLt20M) {
          this.$message.error("文件文件大小不能超过 20MB!");
        }
        return isLt20M;
      }
    },
    //通知书移除
    handleRemove1(file, fileList) {
      for (let i in this.fileInfoList1) {
        if (this.fileInfoList1[i].url == file.url) {
          this.fileInfoList1.splice(i, 1);
          break;
        }
      }
      if (this.fileInfoList1.length) {
        this.financialTasksForm.filePath = fileList[0].response.result;
        this.financialTasksForm.fileName = fileList[0].name;
      } else {
        this.financialTasksForm.filePath = null;
        this.financialTasksForm.fileName = null;
      }
      this.$refs["financialTasksForm"].validateField("filePath");
    },
    handleInfoSuccess1: function (res, file, fileList) {
      this.fileInfoList1.push({
        name: file.name,
        url: file.response.result,
      });
      this.financialTasksForm.filePath = res.result;
      this.financialTasksForm.fileName = file.name;
      this.$refs["financialTasksForm"].validateField("filePath");
    },
    // 通知书上传之前
    beforeUpload1: function (file) {
      const fileType = file.name.split(".")[file.name.split(".").length - 1];
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (this.fileInfoList1.length > 19) {
        this.$message.error("最多支持上传20份");
        return false;
      } else {
        if (
          fileType != "doc" &&
          fileType != "docx" &&
          fileType != "pdf" &&
          fileType != "jpg" &&
          fileType != "xlsx" &&
          fileType != "xls" &&
          fileType != "ppt" &&
          fileType != "pptx" &&
          fileType != "jpeg" &&
          fileType != "png"
        ) {
          this.$message.error("文件格式不正确");
          return false;
        }
        if (!isLt20M) {
          this.$message.error("文件文件大小不能超过 20MB!");
        }
        return isLt20M;
      }
    },
    //业绩分配切换
    changeItem: function (val, index) {
      let defaultPerformanceAllocations = JSON.parse(
        this.defaultPerformanceAllocations
      );
      for (let i in defaultPerformanceAllocations) {
        if (defaultPerformanceAllocations[i].sysAllocationPlanId == val) {
          defaultPerformanceAllocations[i].id = 0;
          defaultPerformanceAllocations[i].userId = "";
          defaultPerformanceAllocations[i].percent = "";
          defaultPerformanceAllocations[i].allocationItems = {
            groups: [],
            areas: [],
            teams: [],
            subTeams: [],
          };
          this.$set(
            this.financialTasksForm.performanceAllocations,
            index,
            defaultPerformanceAllocations[i]
          );
          this.performanceList[index] = (
            this.userList.find((item) => {
              return item.id == defaultPerformanceAllocations[i].userId;
            }) || {}
          ).userName;
          break;
        }
      }
    },
    //获取关联合同
    getCompanyContractList() {
      getCompanyContractList({ companyId: this.companyId }).then((res) => {
        if (res.success) {
          this.companyList = res.result;
        }
      });
    },
    selected() {
      this.searchUserList = this.userList;
      this.searchUserId = "";
    },
    checkUser(e, index) {
      this.searchUserList = this.userList;
      this.searchUserId = "";
      this.performanceList[index] = this.userList.find((item) => {
        return item.id == e;
      }).userName;
      this.financialTasksForm.performanceAllocations[index].userId = e;
      if (e) {
        departAllocations({ userId: e }).then((res) => {
          if (res.success) {
            this.financialTasksForm.performanceAllocations[
              index
            ].allocationItems = res.result;
            Object.keys(
              this.financialTasksForm.performanceAllocations[index]
                .allocationItems
            ).map((i) => {
              if (
                !this.financialTasksForm.performanceAllocations[index]
                  .allocationItems[i]
              ) {
                return (this.financialTasksForm.performanceAllocations[
                  index
                ].allocationItems[i] = []);
              }
            });
          }
        });
      } else {
        this.financialTasksForm.performanceAllocations[index].allocationItems =
          { groups: [], areas: [], teams: [], subTeams: [] };
      }
    },
    changed() {
      this.searchUserList = this.userList;
    },
    // 获取财务任务初始分配方案
    getProjectDetailMethod() {
      this.$route.path == "/financeEnquiries"
        ? (this.routeDiff = editProjectInfo(this.financeTaskId))
        : (this.routeDiff = financeTaskDefaultInfo({
            projectResumeId: this.projectResumeId,
          }));
      this.routeDiff.then((res) => {
        if (res.success) {
          this.projectDetail = res.result;
          this.defaultPerformanceAllocations = JSON.stringify(
            res.result.performanceAllocations
          );
          this.financialTasksForm.performanceAllocations = [];
          this.performanceList = [];
          let performanceIdList = [];
          for (let i in res.result.performanceAllocations) {
            if (res.result.performanceAllocations[i].id != -1) {
              this.financialTasksForm.performanceAllocations.push(
                res.result.performanceAllocations[i]
              );
              performanceIdList.push(
                this.userList.find((item) => {
                  return item.id == res.result.performanceAllocations[i].userId;
                }) || {}
              );
            }
          }
          this.financialTasksForm.performanceAllocations.map((item) => {
            if (!item.allocationItems) {
              return (item.allocationItems = {
                groups: [],
                areas: [],
                teams: [],
                subTeams: [],
              });
            } else {
              Object.keys(item.allocationItems).map((i) => {
                if (!item.allocationItems[i]) {
                  return (item.allocationItems[i] = []);
                }
              });
            }
          });
          performanceIdList.forEach((item) => {
            this.performanceList.push(item.userName || "");
          });
        }
        for (let i in res.result.fileList) {
          this.fileInfoList.push({
            name: res.result.fileList[i].fileName,
            url: res.result.fileList[i].filePath,
          });
        }
        if (res.result.fileList) {
          this.financialTasksForm.filePath = res.result.fileList[0].filePath;
        }
        this.financialTasksForm.contractId = res.result.contractId;
        if (res.result.financeTask) {
          if (res.result.financeTask.payType == "尾款") {
            this.financialTasksForm.type = "1";
          } else if (res.result.financeTask.payType == "首付款") {
            this.financialTasksForm.type = "0";
          } else if (res.result.financeTask.payType == "预付款") {
            this.financialTasksForm.type = "2";
          }
          // ((100 * ((this.financialTasksForm.money / this.projectDetail.money).toFixed(4))).toFixed(2))
          this.$route.path == "/financeEnquiries"
            ? (this.financialTasksForm.percent = (
                100 *
                (res.result.financeTask.payAmount / res.result.money)
              ).toFixed(2))
            : (this.financialTasksForm.percent =
                res.result.financeTask.payPercentage);
          this.financialTasksForm.money = res.result.financeTask.payAmount;
          this.financialTasksForm.type = res.result.financeTask.payType;
          this.financialTasksForm.invoiceType = res.result.financeTask.invoiceType;
          this.financialTasksForm.conventionMakeInvoiceDate =
            res.result.financeTask.conventionMakeInvoiceDate;
          this.financialTasksForm.conventionToAccountDate =
            res.result.financeTask.conventionToAccountDate;
          this.financialTasksForm.remark = res.result.financeTask.remark;
          // this.financialTasksForm.contractId = res.result.financeTask.contractId;
        }
      });
    },
    searchOptions() {
      this.searchUserList = this.searchUserList.filter((item) => {
        return item.userName.indexOf(this.searchUserId) != -1;
      });
    },
    // 编辑分配比例
    editOrganization(type, index) {
      this.dialogVisibleOfEdit = true;
      this.currentEditType = type;
      this.currentIndex = index;
      this.total = 0;
      if (type == "groups") {
        this.currentList = this.groupsList;
        this.currentAllocationItems = JSON.parse(
          JSON.stringify(
            this.financialTasksForm.performanceAllocations[this.currentIndex]
              .allocationItems.groups
          )
        );
      } else if (type == "areas") {
        this.currentList = this.areasList;
        this.currentAllocationItems = JSON.parse(
          JSON.stringify(
            this.financialTasksForm.performanceAllocations[this.currentIndex]
              .allocationItems.areas
          )
        );
      } else if (type == "teams") {
        this.currentList = this.teamsList;
        this.currentAllocationItems = JSON.parse(
          JSON.stringify(
            this.financialTasksForm.performanceAllocations[this.currentIndex]
              .allocationItems.teams
          )
        );
      } else if (type == "subTeams") {
        this.currentList = this.subTeamsList;
        this.currentAllocationItems = JSON.parse(
          JSON.stringify(
            this.financialTasksForm.performanceAllocations[this.currentIndex]
              .allocationItems.subTeams
          )
        );
      }
    },
    // 添加分配比例项
    addPercent() {
      this.currentAllocationItems.push({
        departmentId: "",
        departmentName: "",
        percent: 0,
      });
    },
    changeDepartment(e, i) {
      this.currentList.forEach((item) => {
        if (item.id == e) {
          i.departmentName = item.name;
        }
      });
    },
    // 删除分配比例项
    delPercent(i) {
      this.currentAllocationItems.splice(i, 1);
    },
    // 保存分配比例
    saveEditPercent() {
      this.$refs["percentForm"].validate((valid) => {
        if (valid) {
          if (this.total != 100 && this.total > 0) {
            this.$message.error("当前分配比例总和不是100%");
            return;
          }
          const departmentIdArr = [];
          let flag = false;
          this.currentAllocationItems.forEach((item) => {
            if (item.departmentId && item.percent) {
              departmentIdArr.push(item.departmentId);
              for (let i = 0; i < departmentIdArr.length - 1; i++) {
                if (departmentIdArr[i] == item.departmentId) {
                  flag = true;
                  break;
                }
              }
            }
          });
          if (flag) {
            this.$message.error("分配比例中有重复字段");
            return;
          }
          this.dialogVisibleOfEdit = false;
          this.currentAllocationItems = this.currentAllocationItems.filter(
            (item) => {
              return item.departmentId && item.percent;
            }
          );
          if (this.currentEditType == "groups") {
            this.financialTasksForm.performanceAllocations[
              this.currentIndex
            ].allocationItems.groups = JSON.parse(
              JSON.stringify(this.currentAllocationItems)
            );
          } else if (this.currentEditType == "areas") {
            this.financialTasksForm.performanceAllocations[
              this.currentIndex
            ].allocationItems.areas = JSON.parse(
              JSON.stringify(this.currentAllocationItems)
            );
          } else if (this.currentEditType == "teams") {
            this.financialTasksForm.performanceAllocations[
              this.currentIndex
            ].allocationItems.teams = JSON.parse(
              JSON.stringify(this.currentAllocationItems)
            );
          } else if (this.currentEditType == "subTeams") {
            this.financialTasksForm.performanceAllocations[
              this.currentIndex
            ].allocationItems.subTeams = JSON.parse(
              JSON.stringify(this.currentAllocationItems)
            );
          }
        }
      });
    },
    loadUserBase() {
      getUserBaseInfos().then((res) => {
        if (res.success) {
          this.userList = res.result;
          this.searchUserList = res.result;
        }
      });
    },
    // 获取集团
    getGroups() {
      getGroupsByType().then((res) => {
        if (res.success) {
          this.groupsList = res.result;
        }
      });
    },
    // 获取地区
    getAreas() {
      getAreasByType().then((res) => {
        if (res.success) {
          this.areasList = res.result;
        }
      });
    },
    // 获取团队
    getTeams() {
      getTeamsByType().then((res) => {
        if (res.success) {
          this.teamsList = res.result;
        }
      });
    },
    // 获取子团队
    getSubTeams() {
      getSubTeamsByType().then((res) => {
        if (res.success) {
          this.subTeamsList = res.result;
        }
      });
    },
    //保存
    saveFinancialTask(formName) {
      return new Promise((resolve, reject) => {
        this.$refs.financialTasksForm.validate((valid) => {
          // debugger
          if (valid) {
            this.saveloading = true;

            if (this.totalPercent != 100) {
              reject();
              this.$message.error("当前分配比例总和不是100%");
              return;
            }
            for (let i in this.financialTasksForm.performanceAllocations) {
              if (!this.financialTasksForm.performanceAllocations[i].userId) {
                reject();
                this.$message.error("业绩分配人不能为空");
                return;
              }
              if (
                !Number.isInteger(
                  this.financialTasksForm.performanceAllocations[i].percent
                )
              ) {
                reject();
                this.$message.error("请输入数字值");
                return;
              }
            }
            let queryData = {
              contractId: this.financialTasksForm.contractId,
              financeTask: {
                payType: this.financialTasksForm.type,
                invoiceType: this.financialTasksForm.invoiceType,
                payPercentage: this.financialTasksForm.percent,
                payAmount: this.financialTasksForm.money,
                conventionMakeInvoiceDate:
                  this.financialTasksForm.conventionMakeInvoiceDate,
                conventionToAccountDate:
                  this.financialTasksForm.conventionToAccountDate,
                remark: this.financialTasksForm.remark,
                projectResumeId: this.projectResumeId,
                noContract: this.financialTasksForm.noContract,
                noContractComment: this.financialTasksForm.noContractComment,
                noContractReason: this.financialTasksForm.noContractReason,
                noContractRelatedContractId:
                  this.financialTasksForm.noContractRelatedContractId,
                agreedSignDate: this.financialTasksForm.agreedSignDate,
              },
              performanceAllocations:
                this.financialTasksForm.performanceAllocations,
              fileList: [],
              noContractProveFiles: [],
            };
            if (this.projectDetail.financeTask) {
              queryData.financeTask.version =
                this.projectDetail.financeTask.version;
              queryData.financeTask.id = this.projectDetail.financeTask.id;
            }
            for (let i in this.fileInfoList) {
              queryData.fileList.push({
                fileName: this.fileInfoList[i].name,
                filePath: this.fileInfoList[i].url,
              });
            }
            if (
              this.financialTasksForm.noContract &&
              this.fileInfoList1.length == 0
            ) {
              reject();
              this.$message.error("请上传证明材料");
              return;
            } else {
              for (let i in this.fileInfoList1) {
                queryData.noContractProveFiles.push({
                  fileName: this.fileInfoList1[i].name,
                  filePath: this.fileInfoList1[i].url,
                });
              }
            }
            this.$emit("changeSaveLoading", true);
            this.$emit("saveEditFinance", true);
            validateInvoicedPercent(queryData).then((res) => {
              if (res.success) {
                this.dialogVisibleOfMsg = true;
                this.queryData = queryData;
                if(queryData.financeTask.invoiceType=='红冲开票'){
                  this.incompleteInvoicing=true
                }else{
                  financeTaskValidateCompletelyInvoice(queryData).then((res1) => {
                    if (res1.success) {
                      this.incompleteInvoicing = res1.result;
                    }
                  });
                }
              } else {
                this.saveloading = false;
                this.$emit("changeSaveLoading", false);
                // this.$message.error("累计开票比例已超过100%，请调整");
              }
            });
          } else {
            reject("false");
            return false;
          }
        });
      });
    },
    closeTips() {
      this.dialogVisibleOfTips = false;
      this.$emit("loadList");
    },
    loadEditInfo(row) {
      getOperaterecordById({
        id: row.id,
      }).then((res) => {
        if (res.success) {
          this.financialTasksForm.content = res.result.objectContent.reason;
          this.financialTasksForm.id = res.result.id;
          this.financialTasksForm.version = res.result.version;
        }
      });
    },
    addSearch() {
      //添加搜索条件
      this.financialTasksForm.performanceAllocations.push({
        allocationItems: { groups: [], areas: [], teams: [], subTeams: [] },
        roleName: "",
        userId: "",
        percent: 0,
        version: 0,
        id: 0,
      });
      this.performanceList.push("");
    },
    chioceNoContract(val) {
      if (val) {
        this.financialTasksForm.contractId = "";
      }
      // this.$refs.financialTasksFormRule.clearValidate();
      this.$refs["financialTasksForm"].clearValidate(["contractId"]);
      // this.$refs['financialTasksFormRule'].clearValidate(['contractId']);
    },
    //申请原因选择
    handelNoContractReason(val) {
      this.cleanNoContract();
      this.financialTasksForm.noContractReason = val;
      financeTaskNoContractOptions({
        companyId: this.projectDetail.companyId,
        reason: this.financialTasksForm.noContractReason,
      }).then((res) => {
        if (res.success) {
          this.noContractCompanyList = res.result;
        }
      });
      if (
        val == "正在签约中" ||
        (val == "合同超期尚未续约" &&
          this.financialTasksForm.noContractRelatedContractId)
      ) {
        this.validataNoContract();
      }
    },
    //选择
    chioceNoContractRelatedContractId() {
      this.validataNoContract();
    },
    cleanNoContract() {
      this.financialTasksForm.noContractRelatedContractId = "";
      this.financialTasksForm.agreedSignDate = "";
      this.financialTasksForm.noContractComment = "";
      this.fileInfoList1 = [];
      this.isShowTips = false;
      this.valiNoContractdata.first = true;
      this.financialTasksForm.noContractReason = "";
    },
    //校验数据
    validataNoContract() {
      financeTaskNoContractValidate({
        companyId: this.projectDetail.companyId,
        contractId: this.financialTasksForm.noContractRelatedContractId,
        reason: this.financialTasksForm.noContractReason,
      }).then((res) => {
        if (res.success) {
          this.valiNoContractdata = res.result;
          if (!this.valiNoContractdata.first) {
            this.financialTasksForm.agreedSignDate =
              this.valiNoContractdata.agreedSignDate;
            this.financialTasksForm.noContractComment =
              this.valiNoContractdata.noContractComment;
            for (let i in this.valiNoContractdata.noContractProveFiles) {
              this.fileInfoList1.push({
                name: this.valiNoContractdata.noContractProveFiles[i].fileName,
                url: this.valiNoContractdata.noContractProveFiles[i].filePath,
              });
            }
            this.financialTasksForm.noContractRelatedContractId =
              this.valiNoContractdata.noContractRelatedContractId;
            let date1_s = this.financialTasksForm.agreedSignDate.replace(
              /\-/g,
              "/"
            );
            let date1_unix = Date.parse(date1_s);
            let date2_unix = +new Date();
            if (date1_unix < date2_unix) {
              this.isShowTips = true;
            } else {
              this.isShowTips = false;
            }
          }
        }
      });
    },
    //关闭信息确认与补充弹窗
    closeMsg() {
      this.dialogVisibleOfMsg = false;
      this.saveloading = false;
      this.$emit("changeSaveLoading", false);
    },
    sumbitFinancetask() {
      if (!this.incompleteInvoicing) {
        if (!this.queryData.conventionNextMakeInvoiceDate) {
          this.$message.error("请选择约定下次开票时间");
          return false;
        }
      }
      addOrEditFinancetask(this.queryData).then((res1) => {
        // debugger
        if (res1.success) {
          if (this.financialTasksForm.noContract) {
            this.dialogVisibleOfTips = true;
            this.tipsData = res1.result;
          } else {
            this.$emit("loadList");
            this.$message.success("操作成功");
          }
        } else {
          reject("false");
        }
        // resolve("success");
        this.saveloading = false;
        this.financialTasksForm.percent = "";
        this.financialTasksForm.money = "";
        this.$emit("changeSaveLoading", false);
      });
    },
  },
};
</script>

<style scoped>
/* .financialTasksFormInfo {
  padding: 0 15px;
} */
.financialTasksFormInfo :deep(.el-form-item__content) {
  margin-bottom: 15px;
}
</style>
<style lang="scss">
.el-checkbox.is-bordered + .el-checkbox.is-bordered {
  margin-left: 0;
}
.el-checkbox.is-bordered.el-checkbox--small .el-checkbox__label {
  width: 100%;
}
.invoiceStyle {
  border: solid 1px #ededed;
  border-left: 2px #777788 solid;
  padding: 20px 20px 5px 20px;
  box-sizing: border-box;

  font-size: 12px;
  .el-col-12 {
    margin-bottom: 15px;
  }
}
</style>
<style lang='less'>
.financialTasksFormRulePP {
  /*   max-height: 600px;
  overflow: scroll;
  overflow-x: hidden; */
  .addSearch {
    padding-top: 20px;
    height: 32px;
    line-height: 30px;
    position: relative;
    z-index: 5;
    // box-sizing: border-box;
    cursor: pointer;
    .addSearchBtn {
      text-align: center;
      margin: 0;
      border: 1px dashed #ebebeb;
      box-sizing: border-box;
      font-size: 12px;
      color: #888888;
      i {
        font-size: 14px;
        margin-right: 5px;
      }
      &:hover {
        border-color: #ff3f3f;
        color: #ff3f3f;
      }
    }
  }
  .iconguanbi {
    margin: 0 10px;
    color: #dddee1;
    font-size: 14px;
    &:hover {
      color: #ff3f3f;
    }
  }
  .el-collapse-item__content,
  .el-form-item {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .filePathBook {
    margin: 5px 0 8px;
    .el-form-item__error {
      top: auto;
      bottom: -2px;
    }
  }
  .searchList {
    font-size: 0;
    .el-input--mini .el-input__inner,
    .el-select .el-input .el-select__caret {
      line-height: 32px;
      height: 32px;
    }
    .el-form-item__label,
    .el-form-item__content {
      font-size: 12px;
      line-height: normal;
      line-height: 32px;
    }
  }
  .el-form-item.is-error .el-input-group__append {
    border-color: #f56c6c;
  }
  .el-form-item.is-success .el-input-group__append {
    border-color: #67c23a;
  }
  .el-input.is-disabled .el-input-group__append {
    border-color: #e4e7ed;
    background-color: #f5f7fa !important;
    color: #c0c4cc;
    cursor: not-allowed;
  }
  .el-collapse,
  .el-collapse-item__header,
  .el-collapse-item__wrap {
    border: none;
  }
  .el-collapse {
    .payInfo {
      margin-bottom: 10px;
      border: 1px solid rgb(236, 236, 236);
      .el-collapse-item {
        margin: 8px;
        border: 1px #e5e5e5 solid;
      }
      .el-collapse-item__header {
        // padding: 0 15px;
        height: 40px;
        line-height: 32px;
        font-weight: bold;
        border-bottom: 1px #ededed solid;
        background-color: #f0f0f0;
        font-size: 14px;
        color: #333;
        padding: 0 15px;
        .liStyle {
          padding-bottom: 8px;
        }
      }
      .scoreTot {
        font-size: 14px;
        padding: 0 10px;
        color: #333;
        width: 100%;
        line-height: 36px;
        background-color: #f0f0f0;
        font-weight: bold;
        span {
          font-weight: normal;
          color: #606266;
          s {
            text-decoration: none;
            color: #e58a00;
          }
        }
        .addSearchBtn {
          float: right;
          color: #526ecc;
          font-size: 12px;
          i {
            font-size: 14px;
          }
        }
      }
    }
    .el-input {
      //   width: 320px;
      .el-input-group__append {
        background: none;
        border-left: none;
        border-radius: 2px;
      }
    }
    .el-upload {
      width: 100%;
    }
    .el-upload-dragger {
      width: 100%;
      height: 147px;
      overflow: hidden;
      border-radius: 2px;
    }
    .el-upload-dragger .el-upload__text {
      line-height: normal;
    }
    .iconshangchuan {
      font-size: 44px;
      color: #bbbbbb;
      display: inline-block;
      margin-top: 30px;
    }
    .el-upload__text {
      font-size: 15px;
      color: #333333;
      em {
        display: inline-block;
        margin-bottom: 7px;
      }
    }
  }
  .tip {
    height: auto;
    line-height: 23px;
    color: #e60012;
    padding: 5px 15px;
  }
  .tips {
    position: absolute;
    right: 50px;
    font-size: 12px;
    color: #bbbbbb;
  }
  .el-collapse-item__arrow {
    color: #bbbbbb;
    margin: 0 0 0 auto;
    font-size: 12px;
  }
  .el-collapse-item__header {
    position: relative;
    font-size: 12px;
    background: none;
    &::after {
      content: "展开";
      position: absolute;
      right: 30px;
      color: #bbbbbb;
    }
    &.is-active::after {
      content: "收起";
    }
  }
  .disabledInput .el-input__inner {
    padding: 0 10px;
  }
  // .tipsContent {
  //   .t_tit {
  //     font-size: 18px;
  //     font-weight: bold;
  //     text-align: center;
  //   }
  // }
}
.titleOfPerformance1 {
  position: relative;
  border-bottom: 1px dashed #ededed;
  margin: 5px;
  .el-collapse-item__arrow.el-icon-arrow-right {
    margin: 0 auto;
    position: absolute;
    font-size: 18px;
    right: 10px;
    font-weight: bold;
  }
  .el-collapse-item__header::after {
    content: none !important;
  }
  .el-collapse-item__header {
    height: auto;
    padding-left: 0 !important;
  }
  &:hover {
    border-bottom: 1px dashed #ff3f3f;
  }
}
.contentformance {
  padding: 15px 20px;
  .el-form-item {
    padding: 5px 0;
    margin-bottom: 0 !important;
    .el-form-item__label {
      font-size: 14px;
    }
  }
}
</style>