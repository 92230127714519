import axios from 'axios'
import {
    setStore
} from '@/libs/storage';
import {
    Message
} from 'element-ui' // element-ui 
import Cookies from 'js-cookie';
export const ApiBaseUrl = process.env.VUE_APP_baseUrl
export const PreviewUrl = process.env.VUE_APP_previewUrl
// 创建axios实例
const http = axios.create({
    baseURL: ApiBaseUrl,
    timeout: 60000 // 请求超时时间                                   
})

// 添加request拦截器 
http.interceptors.request.use(config => {
    let accessToken = Cookies.get('accessToken');
    if (accessToken) {
        config.headers['Authorization'] = accessToken
    }
    return config
}, error => {
    Promise.reject(error)
})
// 添加respone拦截器
http.interceptors.response.use(response => {
    // console.log(response)
    if (response.headers['content-disposition']) {
        return response
    } else {
        const data = response.data;
        // console.log(data)
        // 根据返回的code值来做不同的处理(和后端约�
        switch (data.code) {
            case 401:
                // 未登�清除已登录状�
                setStore('useInfo', '');
                Cookies.set('accessToken', '');
                sessionStorage.removeItem("activeIndex")
                location.reload()
                break;
            case 403:
                // 没有权限
                if (data.message !== null) {
                    Message.error(data.message);
                } else {
                    Message.error("未知错误");
                }
                break;
            case 404:
                // 没有权限
                if (data.message !== null) {
                    Message.error(data.message);
                } else {
                    Message.error("未知错误");
                }
                break;
            case 500:
                // 错误
                if (data.message !== null) {
                    Message.error(data.message);
                } else {
                    Message.error("未知错误");
                }
                break;
            default:
                return data;
        }
        return data;
    }
}, (err) => {
    // 返回状态码不为200时候的错误处理
    if (err.response) {
        let data = err.response.data
        switch (data.code) {
            case 500:
                if (data.message !== null) {
                    Message.error(data.message);
                } else {
                    Message.error("未知错误");
                }
                break;
            case 400:
                Message.error('参数错误，请联系管理员');
                break;
            case 404:
                Message.error('请求方法错误');
                break;
        }
        return data;
    } else if (err.message == '终止请求') {

    } else {
        Message.error("请求超时");
    }

    // Message.error(err.toString());
    return Promise.resolve(err);
});

export const getRequest = (url, params) => {
    let accessToken = Cookies.get('accessToken');
    return http({
        method: 'get',
        url: `${url}`,
        params: params,
        headers: {
            'Authorization': accessToken
        }
    });
};
export const getRequest2 = (url) => {
    return http({
        method: 'get',
        // responseType: 'blob',
        headers: {
        },
        url
    });
};
export const getRequestWithStreamResponse = (url, params) => {//下载
    let accessToken = Cookies.get('accessToken');
    return http({
        method: 'get',
        url: `${url}`,
        params: params,
        responseType: 'blob',
        headers: {
            'Authorization': accessToken
        }
    });
};
export const getRequestCanCancel = (url, params, that) => {
    let accessToken = Cookies.get('accessToken');
    return http({
        method: 'get',
        url: `${url}`,
        params: params,
        cancelToken: new axios.CancelToken(function executor(c) { // 设置 cancel token
            that.source = c;
        }),
        headers: {
            'Authorization': accessToken
        }
    });
};
export const postRequest = (url, params) => {
    let accessToken = Cookies.get("accessToken");
    return http({
        method: 'post',
        url: `${url}`,
        data: params,
        transformRequest: [function (data) {
            let ret = '';
            for (let it in data) {
                ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&';
            }
            return ret;
        }],
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': accessToken
        }
    });
};
export const postRequestUseBodyParam = (url, params) => {
    let accessToken = Cookies.get("accessToken");
    return http({
        method: 'post',
        url: `${url}`,
        data: params,
        headers: {
            'Authorization': accessToken
        },
    });
};
export const postRequestCanCancel = (url, params, cancelToken) => {
    let accessToken = Cookies.get("accessToken");
    return http({
        method: 'post',
        url: `${url}`,
        data: params,
        headers: {
            'Authorization': accessToken
        },
        cancelToken
    });
};
export const putRequest = (url, params) => {
    let accessToken = Cookies.get("accessToken");
    return http({
        method: 'put',
        url: `${url}`,
        data: params,
        transformRequest: [function (data) {
            let ret = '';
            for (let it in data) {
                ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&';
            }
            return ret;
        }],
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': accessToken
        }
    });
};
export const putRequestUseBodyParams = (url, params) => {
    let accessToken = Cookies.get("accessToken");
    return http({
        method: 'put',
        url: `${url}`,
        data: params,
        headers: {
            'Authorization': accessToken
        },
    });
};

export const deleteRequest = (url, params) => {
    let accessToken = Cookies.get('accessToken');
    return http({
        method: 'delete',
        url: `${url}`,
        params: params,
        headers: {
            'Authorization': accessToken
        }
    });
};

export const uploadFileRequest = (url, params) => {
    let accessToken = Cookies.get('accessToken');
    return http({
        method: 'post',
        url: `${url}`,
        params: params,
        headers: {
            'Authorization': accessToken
        }
    });
};
//html请求
export const getHtmlRequest = (url, params) => {
    return http({
        method: 'get',
        url: `${url}`,
        params: params,
        headers: {
            'access': 'CNJH7A347D791K2FCREMYFTG1STJ6T'
        }
    });
};
export const postHtmlRequest = (url, params) => {
    return http({
        method: 'post',
        url: `${url}`,
        data: params,
        headers: {
            'access': 'CNJH7A347D791K2FCREMYFTG1STJ6T'
        },
    });
};