import dialog from "./components/dialog";
import drawer from "./components/drawer";
import {router} from "./router";

export default {
    seed:1,
    install(Vue,options){
        let that = this
        Vue.prototype.$dialog = function (params) {
           return  that.dialog(Vue,params)
        }
        Vue.prototype.$drawer = function (params) {
          return   that.drawer(Vue,params)
        }
    },

    getTemplate(t){

        if(typeof t =='string'){
            return router[t]
        }else{
            return t
        }
    },

    dialog(Vue,params){
        this.seed++
        let _template = this.getTemplate(params.template)

        let props = params.props || {}
        let options = params.options || {}
        let constructor = Vue.extend(dialog)
        let emit= params.emit || {}
        let instance = new constructor({
            propsData: {
                props:props,
                fromTemplate:_template,
                seed:this.seed,
                emit:emit,
                ...options
            },
            store:window.$globalHub.$store,
            router: window.$globalHub.$router
        })

        instance.id = 'modal-' + this.seed
        instance.vm = instance.$mount()
        document.body.appendChild(instance.vm.$el)
        return instance.vm
    },

    drawer(Vue,params){
        this.seed++
        let _template = this.getTemplate(params.template)
        let props = params.props || {}
        let options = params.options || {}
        let constructor = Vue.extend(drawer)
        let emit= params.emit || {}
        let instance = new constructor({
            propsData: {
                props:props,
                fromTemplate:_template,
                seed:this.seed,
                emit:emit,
                ...options
            },
            store:window.$globalHub.$store,
            router: window.$globalHub.$router
        })

        instance.id = 'modal-' + this.seed
        instance.vm = instance.$mount()
        document.body.appendChild(instance.vm.$el)
        return instance.vm
    }
}
