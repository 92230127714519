<template>
  <section class="connections">
    <!-- <div> -->
      <el-form
        style="margin: 0"
        @submit.native.prevent
        :model="searchInfo"
        ref="searchInfo"
      >
        <el-row
          :gutter="24"
          style="width: 100%; margin: 10px 0 0; background-color: #fff"
          class="searchBox"
        >
          <el-col :span="24">
            <el-col :span="8" class="labelAppend" style="height: 49px">
              <el-form-item label="公司名称：" prop="company">
                <el-input
                  @focus="isShowCompany = true"
                  @blur="blurCompany($event)"
                  v-model="searchInfo.company"
                  placeholder="支持模糊搜索"
                  style="width: calc(100% - 75px); position: relative"
                >
                </el-input>
                <el-button
                  v-show="isShowCompany"
                  style="
                    padding: 9px;
                    background: #ff3f3f;
                    color: white;
                    position: absolute;
                    right: 0;
                    top: 0px;
                  "
                  icon="el-icon-search"
                  @click="submitForm()"
                ></el-button>
              </el-form-item>
            </el-col>
            <el-col :span="8" class="labelAppend" style="height: 49px">
              <el-form-item label="职位：" prop="title">
                <el-input
                  @focus="isShowTitle = true"
                  @blur="blurTitle($event)"
                  v-model="searchInfo.title"
                  placeholder="支持模糊搜索"
                  style="width: calc(100% - 47px); position: relative"
                >
                </el-input>
                <el-button
                  v-show="isShowTitle"
                  style="
                    padding: 9px;
                    background: #ff3f3f;
                    color: white;
                    position: absolute;
                    right: 0;
                    top: 0px;
                  "
                  icon="el-icon-search"
                  @click="submitForm()"
                ></el-button>
              </el-form-item>
            </el-col>
            <el-col :span="8" style="height: 49px">
              <el-form-item label="联系方式：" prop="contacts">
                <el-select
                  multiple
                  collapse-tags
                  clearable
                  v-model="searchInfo.contacts"
                  style="width: calc(100% - 75px)"
                  @change="submitForm()"
                >
                  <el-option label="有电话" value="有电话"></el-option>
                  <el-option label="有邮箱" value="有邮箱"></el-option>
                  <el-option label="有微信" value="有微信"></el-option>
                  <el-option
                    label="有其他联系方式"
                    value="有其他联系方式"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="10" style="height: 49px">
              <el-form-item label="在职期间：" prop="time">
                <el-date-picker
                  style="width: calc(100% - 75px)"
                  v-model="timeVal"
                  value-format="yyyy-MM-dd"
                  @change="getTime"
                  type="monthrange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-col>
          <el-col
            :span="24"
            class="clearfix"
            style="
              display: flex;
              padding: 10px 0;
              align-items: center;
              border-top: 1px #ececec solid;
            "
          >
            <p class="el-form-item__label" style="min-width: 100px">
              快捷查询：
            </p>
            <el-checkbox-group
              class="radioGroupBtn"
              size="mini"
              v-model="searchInfo.quicks"
              @change="workExperienceChanged($event)"
              text-color="#e60012"
            >
              <el-checkbox
                v-for="(i, index) in quicksList"
                :key="index"
                :label="i.value"
                :title="i.name"
                border
                style="margin-right: 0; margin-bottom: 10px"
                >{{ i.name }}</el-checkbox
              >
            </el-checkbox-group>
          </el-col>
        </el-row>
      </el-form>
      <div>
        <el-pagination
          style="float: right"
          ref="pageNationOne"
          :hide-on-single-page="pagination.total > 10 ? false : true"
          v-if="pagination.total > 0 && !listLoading"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pagination.pageNo"
          :page-sizes="[10, 20, 50, 100]"
          :pager-count="5"
          :page-size="pagination.pageSize"
          layout="total,prev, pager, next,sizes"
          :total="pagination.total"
        ></el-pagination>
        <div>
        <el-row :gutter="24" style="clear: both">
          <el-col :span="24">
            <el-table
              v-if="ListData.length > 0"
              :header-cell-style="{
                'background-color': '#f5f7fa',
                height: '35px',
              }"
              class="tabBorder"
              :data="ListData"
              v-loading="listLoading"
              style="width: 100%"
            >
              <el-table-column prop="types" label="类型" width="100">
                <template slot-scope="scope">
                  <span
                    v-for="(item, index) in scope.row.types"
                    :key="index"
                    class="tag"
                    :class="{
                      tag01: item == '朋友',
                      tag06: item == '同事',
                      tag21: item == '校友',
                    }"
                    style="margin-right:4px"
                    >{{ item }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop
                label="姓名"
                width="100"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <div style="display: flex">
                    <p class="tabHref" @click="goNewPage(scope.row)">
                      {{ scope.row.name ? scope.row.name : "--" }}
                    </p>
                    <el-tooltip
                      v-if="scope.row.secure"
                      class="item"
                      effect="dark"
                      content="人才保密中"
                      placement="top-start"
                    >
                      <i
                        v-if="scope.row.secure"
                        class="badgeIcon iconfont icon-mi"
                        style="color: #da3737"
                      ></i>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop label="联系方式" width="80">
                <template slot-scope="scope">
                  <div style="display: flex">
                    <p
                      v-if="scope.row.contactsOrNot"
                      class="tabHref"
                      @click="goNewPage(scope.row)"
                    >
                      是
                    </p>
                    <p v-else>否</p>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="lastTitle" label="目前职位">
                <template slot-scope="scope">
                  {{ scope.row.lastTitle ? scope.row.lastTitle : "-" }}
                </template>
              </el-table-column>
              <el-table-column
                prop="lastCompany"
                label="目前公司"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{ scope.row.lastCompany ? scope.row.lastCompany : "-" }}
                </template>
              </el-table-column>
              <el-table-column prop="school" label="毕业学校">
                <template slot-scope="scope">
                  {{ scope.row.school ? scope.row.school : "-" }}
                </template>
              </el-table-column>
              <el-table-column
                prop
                label="操作"
                align="left"
                width="80"
              >
                <template slot-scope="scope">
                  <span class="tabHref" @click="supplementary(scope.row)"
                    >关系补充</span
                  >
                </template>
              </el-table-column>
            </el-table>
            <div class="nodata" v-else>
              <img src="../../assets/indexNoData.png" alt="" />
              <span>暂未找到人脉</span>
            </div>
          </el-col>
        </el-row>
        <el-pagination
          style="float: right"
          ref="pageNationOne"
          :hide-on-single-page="pagination.total > 10 ? false : true"
          v-if="pagination.total > 0 && !listLoading"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pagination.pageNo"
          :page-sizes="[10, 20, 50, 100]"
          :pager-count="5"
          :page-size="pagination.pageSize"
          layout="total,prev, pager, next,sizes"
          :total="pagination.total"
        ></el-pagination>
      </div>
    </div>
    <el-dialog
      title="关系补充"
      :visible.sync="supplementaryVisible"
      :modal-append-to-body="false"
      append-to-body
      :close-on-click-modal="false"
      :before-close="handleClose"
      width="600px"
    >
      <div class="dialogContent">
        <el-checkbox-group v-model="supplementaryData.types">
          <el-checkbox label="朋友"></el-checkbox>
          <el-checkbox label="亲属"></el-checkbox>
        </el-checkbox-group>
        <p>如需增加关系类型，请联系管理员</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" plain size="mini" @click="handleClose"
          >取消</el-button
        >
        <el-button
          type="primary"
          :disabled="supplementaryData.types.length == 0"
          size="mini"
          @click="submitSupplementary"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </section>
</template>
      
<script>
import {
  resumeConnectionsPage, //人脉分页列表
  resumeConnectionsAdd, //补充关系
} from "../../api/api";
import {
  statisticsClickConnectionsResultClick, //记录人脉匹配结果点击量
} from "../../api/buryingPoint";
export default {
  props: ["resumeId"],
  data() {
    return {
      searchInfo: {
        company: "",
        contacts: [],
        employmentStart: "",
        employmentEnd: "",
        resumeId: "",
        page: {},
        quicks: [],
      },
      isShowCompany: false,
      isShowTitle: false,
      timeVal: "",
      pagination: {
        total: 0,
        pageNo: 1,
        pageSize: 50,
      },
      quicksList: [
        { value: "同事候选人最近1家公司", name: "同事（候选人最近1家公司）" },
        { value: "同事候选人最近3家公司", name: "同事（候选人最近3家公司）" },
        { value: "同事人脉最近1家公司", name: "同事（人脉最近1家公司）" },
        { value: "校友同专业", name: "校友（同专业）" },
        { value: "校友同年级", name: "校友（同年级）" },
      ],
      ListData: [],
      supplementaryData: {
        connectResumeId: "",
        types: [],
        resumeId: "",
      },
      supplementaryVisible: false,
      listLoading: false,
    };
  },
  methods: {
    //客户输入
    blurCompany(e) {
      setTimeout(() => {
        this.isShowCompany = false;
      }, 500);
    },
    //职位输入
    blurTitle(e) {
      setTimeout(() => {
        this.isShowTitle = false;
      }, 500);
    },
    //任职时间
    getTime(val) {
      this.timeVal = val;
      this.submitForm();
    },
    //快捷查询
    workExperienceChanged() {
      // this.loadList();
      this.submitForm();
    },
    //查询条件
    submitForm() {
      this.pagination.pageNo = 1;
      this.loadList();
    },
    handleSizeChange(val) {
      //pageSize 改变时会触发
      this.pagination.pageSize = val;
      this.pagination.pageNo = 1;
      this.loadList();
    },
    handleCurrentChange(val) {
      this.pagination.pageNo = val;
      this.loadList();
    },
    //获取列表数据
    loadList() {
      this.listLoading = true;
      this.searchInfo.page = {
        current: this.pagination.pageNo,
        size: this.pagination.pageSize,
        searchCount: false,
      };
      this.searchInfo.resumeId = this.resumeId;
      this.searchInfo.employmentStart = this.timeVal ? this.timeVal[0] : "";
      this.searchInfo.employmentEnd = this.timeVal ? this.timeVal[1] : "";
      resumeConnectionsPage(this.searchInfo).then((res) => {
        if (res.success) {
          this.ListData = res.result.records;
          this.pagination.total = res.result.total;
        }
        this.listLoading = false;
      });
    },
    //新窗口详情
    goNewPage(data) {
      if (
        data.secure &&
        !this.hasMenuPage("resume:resumeList:seeSecure") &&
        !data.canViewSecure
      ) {
        this.$confirm("人才保密中，无法查看详情", "提示", {
          showConfirmButton: false,
          cancelButtonText: "关闭",
          customClass: "altCls",
          dangerouslyUseHTMLString: true,
        })
          .then(() => {})
          .catch(() => {});
        return false;
      }
      let newpage = this.$router.resolve({
        path: "/resume/detailPage",
        query: { resumeId: data.id },
      });
      window.open(newpage.href, "_blank");
      console.log(data.types)
      statisticsClickConnectionsResultClick({
        anyContacts: data.contactsOrNot,
        relationShip: data.types.join("、"),
        resumeId: data.id,
        superResumeId: this.resumeId,
      }).then();
    },
    //关系补充
    supplementary(row) {
      this.supplementaryData.connectResumeId = row.id;
      let data = row.types;
      for (var i = 0; i < data.legth; i++) {
        if (data[i] == "朋友" || data[i] == "亲属") {
          this.supplementaryData.types.push(data[i]);
        }
      }
      // this.supplementaryData.types = data;
      console.log(this.supplementaryData.types);
      this.supplementaryVisible = true;
    },
    handleClose() {
      this.supplementaryData.types = [];
      this.supplementaryVisible = false;
    },
    //保存补充关系
    submitSupplementary() {
      this.supplementaryData.resumeId = this.resumeId;
      if (this.supplementaryData.types.length == 0) {
        this.$message.error("请选择关系");
        return false;
      }
      resumeConnectionsAdd(this.supplementaryData).then((res) => {
        if (res.success) {
          this.$message.success("操作成功");
          this.supplementaryVisible = false;
          this.loadList();
        }
      });
    },
  },
  destroyed() {
    this.canScroll();
  },
  mounted() {
    this.noScroll(); //禁止滚动
  },
};
</script>
      
<style lang='scss' scoped>
.nodata {
  text-align: center;
  margin: 90px 0;
  img {
    width: 130px;
    height: 75px;
  }
  p {
    font-size: 14px;
    color: #a8a8a8;
    margin: 10px;
  }
  .el-button.is-round {
    display: block;
    margin: 5px auto;
  }
}
.dialogContent {
  margin: 0 auto;
  text-align: center;
  p {
    color: #999;
    margin: 20px 0 0;
  }
}
</style>