<template>
<span :title="getText" v-html="text" :style="'max-width:'+width+'px'">

</span>
</template>

<script>
    export default {
        props:{
            width:{
                type:String|Number,
                default:"300"
            },
            text:{
                type:String,
                default:''
            }
        },
        computed:{
            getText(){
                return this.text.replace(/<[^>]+>/g,"");
            }
        },
        name: "FixedLengthSpan"
    }
</script>

<style scoped>
    span{
        float: left;
        white-space:nowrap;
        overflow:hidden;
        text-overflow:ellipsis;
    }/*超出部分文字以...显示*/

</style>
