import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

let router = [
    {
        path: '/',
        component: () => import('@/Home'),
        meta: {
            name: '首页'
        },
        children: [{
                path: '/Home',
                meta: {
                    name: '首页'
                },
                component: () => import('@/views/index')
            }, {
                path: '/customer',
                name: '客户',
                meta: {
                    name: '客户'
                },
                authority: 'customer',
                component: resolve => require(['@/views/customer/index'], resolve),

            }, {
                path: '/resume',
                component: () => import('@/views/resume/index'),
                authority: 'resume:resumeList',
                meta: {
                    name: '人才'
                }
            },
            {
                path: '/resumeInfo',
                component: () => import('@/components/resumeDetail'),
                meta: {
                    name: '人才详情'
                }
            },
             {
                path: '/upResume',
                component: () => import('@/views/resume/upResume'),
                authority: 'resume:resumeList',
                meta: {
                    name: '上传人才'
                },
                },
            {
                path:'/resume/importRecord',
                authority: 'resume:resumeRecode',
                component: ()=>import('@/views/resume/record'),
                meta:{
                    name:'导入记录'
                }
            },
            {
                path:'/resume/pending',
                authority: 'resume:resumeRecode',
                component: ()=>import('@/views/resume/pending'),
                meta:{
                    name:'待处理人才'
                }
            },
            {
                path: '/Pipeline',
                component: () => import('@/views/Pipeline/index'),
                authority: 'Pipeline',
                meta: {
                    name: 'Pipeline'
                },
            },
            {
                path: '/project',
                component: () => import('@/views/project/index'),
                authority: 'progream',
                meta: {
                    name: '项目'
                },
            },
            {
                path: '/project/details',
                component: () => import('@/views/project/details'),
                authority: 'progream',
                meta: {
                    name: '项目详情'
                },
            },
            {
                path: '/message/finance',
                component: () => import('@/views/message/finance'),
                authority: '',
                meta: {
                    name: '财务消息/项目消息/期报/合同消息'
                },
                name: '财务消息/项目消息/期报/合同消息'
            },
            {
                path: '/setting',
                component: () => import('@/views/setting/index'),
                meta: {
                    name: '系统配置'
                },
            },
            {
                path: '/setting/roles',
                component: () => import('@/views/setting/roles'),
                authority: 'setting:role',
                meta: {
                    name: '角色管理'
                },
            }, {
                path: '/setting/addressbook',
                component: () => import('@/views/setting/addressbook'),
                authority: 'setting:user',
                meta: {
                    name: '通讯录'
                },
            }, {
                path: '/setting/wordtemp',
                component: () => import('@/views/setting/wordtemp'),
                authority: 'setting:wordtemp',
                meta: {
                    name: '模板文件'
                },
            }, {
                path: '/setting/defaultkpi',
                component: () => import('@/views/setting/defaultkpi'),
                authority: 'setting:defaultkpi',
                meta: {
                    name: '默认业绩分配方案'
                },
            }, {
                path: '/setting/specialCom',
                component: () => import('@/views/setting/specialCom'),
                authority: 'setting:specialCom',
                meta: {
                    name: '特殊公司'
                },
            }, {
                path: '/setting/marktemp',
                component: () => import('@/views/setting/marktemp'),
                authority: 'setting:marktemp',
                meta: {
                    name: '备注配置'
                },
            }, {
                path: '/setting/marktemp/config',
                component: () => import('@/components/markTemp/Configremarks'),
                authority: 'setting:marktemp',
                meta: {
                    name: '配置备注'
                },
            }, {
                path: '/setting/marktemp/details',
                component: () => import('@/components/markTemp/Checkmarks'),
                authority: 'setting:marktemp',
                meta: {
                    name: '查看配置'
                },
            }, {
                path: '/setting/signcompany',
                component: () => import('@/views/setting/signcompany'),
                authority: 'setting:signcompany',
                meta: {
                    name: '签约公司配置'
                },
            }, {
                path: '/setting/workflow',
                component: () => import('@/views/setting/workflow'),
                authority: 'setting:workflow',
                meta: {
                    name: '审批流配置'
                },
            }, {
                path: '/setting/resumeConfig',
                component: () => import('@/views/setting/resumeConfig'),
                authority: 'setting:resumeconfig',
                meta: {
                    name: '人才字段匹配'
                },
            }, {
                path: '/setting/customfield',
                component: () => import('@/views/setting/customfield'),
                authority: 'setting:customfield',
                meta: {
                    name: '自定义字段'
                },
            },
            {
                path: '/menuPre',
                component: () => import('@/views/setting/menuPre'),
                authority: '',
                meta: {
                    name: '菜单权限配置'
                },
            },
            {
                path: '/account',
                component: () => import('@/views/setting/account'),
                authority: '',
                name: '账户设置',
                meta: {
                    name: '账户设置'
                },
            },
            {
                path: '/calendar',
                component: () => import('@/views/calendar/index'),
                authority: '',
                meta: {
                    name: '日程'
                },
            },
            {
                path: '/contracts',
                component: () => import('@/views/contracts/index'),
                authority: 'contract:index',
                meta: {
                    name: '合同管理'
                },
            },
            {
                path: '/contractApproval',
                component: () => import('@/views/contracts/contractApproval'),
                authority: 'contract:approval',
                meta: {
                    name: '合同审批'
                },
            },
            {
                path: '/setting/quartzJob',
                component: () => import('@/views/setting/quartzJob'),
                authority: '',
                name: '定时任务',
                meta: {
                    name: '定时任务'
                },
            },
            {
                path: '/setting/xbWorkflow',
                component: () => import('@/views/setting/xbWorkflow'),
                authority: '',
                name: '喜报审批流配置',
                meta: {
                    name: '喜报审批流配置'
                },
            },
            {
                path: '/setting/systemAnnouncement',
                component: () => import('@/views/setting/systemAnnouncement'),
                authority: '',
                name: '系统公告',
                meta: {
                    name: '系统公告'
                },
            },
            {
                path: '/setting/labelLibrary',
                component: () => import('@/views/setting/labelLibrary'),
                authority: 'setting:labelLibrary',
                name: '标签库',
                meta: {
                    name: '标签库'
                },
            },
            {
                path: '/setting/customLabelLibrary',
                component: () => import('@/views/setting/customLabelLibrary'),
                authority: 'setting:customLabelLibrary',
                name: '个性化标签库',
                meta: {
                    name: '个性化标签库'
                },
            },
            {
                path: '/setting/thesaurus',
                component: () => import('@/views/setting/thesaurus'),
                authority: 'setting:thesaurus',
                name: '同义词库',
                meta: {
                    name: '同义词库'
                },
            },
            {
                path: '/setting/workdayManagement',
                component: () => import('@/views/setting/workdayManagement'),
                authority: 'setting:workdayManagement',
                name: '工作日管理',
                meta: {
                    name: '工作日管理'
                },
            },
            {
                path: '/setting/bonusModel',
                component: () => import('@/views/setting/bonusModel'),
                authority: 'setting:bonusModel',
                name: '奖金计算模型',
                meta: {
                    name: '奖金计算模型'
                },
            },
            {
                path: '/setting/consultantBonus',
                component: () => import('@/views/setting/consultantBonus'),
                authority: 'setting:consultantBonus',
                name: '顾问奖金设置',
                meta: {
                    name: '顾问奖金设置'
                },
            },
            {
                path: '/resume/details',
                component: () => import('@/views/resume/resumeDetails'),
                authority: '',
                name: '人才详情',
                meta: {
                    name: '人才-人才详情'
                },
            },
            {
                path: '/resume/detailPage',
                component: () => import('@/views/resume/detailPage'),
                authority: '',
                meta: {
                    name: '人才-人才详情'
                },
            },
            {
                path: '/financeManagement',
                component: () => import('@/views/finance/management'),
                authority: 'finance:index',
                meta: {
                    name: '财务管理'
                },
            },
            {
                path: '/financeEnquiries',
                component: () => import('@/views/finance/enquiries'),
                authority: 'finance:financeHandle',
                meta: {
                    name: '财务查询'
                },
            },
            {
                path: '/report/Kpi',
                component: () => import('@/views/report/KpiData'),
                authority: 'record:KpiData',
                meta: {
                    name: 'KPI'
                },
            },
            {
                path: '/report/teamKpi',
                component: () => import('@/views/report/teamKpi'),
                authority: 'record:teamKpi',
                meta: {
                    name: '团队KPI'
                },
            },
            {
                path: '/report/realtime',
                component: () => import('@/views/report/realtime'),
                authority: 'record:realtime',
                meta: {
                    name: '实时监控'
                },
            },
            {
                path: '/report/performance',
                component: () => import('@/views/report/performance'),
                authority: 'record:performance',
                meta: {
                    name: '业绩分析'
                },
            },
            {
                path: '/report/PerformanceTable',
                component: () => import('@/views/report/PerformanceTable'),
                authority: 'record:PerformanceTable',
                meta: {
                    name: '业绩报表'
                },
            },
            {
                path: '/report/Pool',
                component: () => import('@/views/report/Pool'),
                authority: 'record:Pool',
                meta: {
                    name: '业绩池'
                },
            },
            {
                path: '/report/performanceConfig',
                component: () => import('@/views/report/performanceConfig'),
                authority: 'record:performanceConfig',
                meta: {
                    name: '业绩目标配置'
                },
            },
            {
                path: '/report/emailStatistics',
                component: () => import('@/views/report/emailStatistics'),
                authority: 'record:emailStatistics',
                meta: {
                    name: '邮件统计'
                },
            },
            {
                path: '/report/overdueCollection',
                component: () => import('@/views/report/overdueCollection'),
                authority: 'record:overdueCollection',
                meta: {
                    name: '逾期回款报表'
                },
            },
            {
                path: '/report/bonusCalculationSsc',
                component: () => import('@/views/report/bonusCalculationSsc'),
                authority: 'record:bonusCalculationSsc',
                meta: {
                    name: '奖金测算_ssc'
                },
            },
            {
                path: '/report/bonusChargeSsc',
                component: () => import('@/views/report/bonusChargeSsc'),
                authority: 'record:bonusChargeSsc',
                meta: {
                    name: '负责人确认奖金_SSC'
                },
            },
            {
                path: '/report/accrualCalculationSsc',
                component: () => import('@/views/report/accrualCalculationSsc'),
                authority: 'record:accrualCalculationSsc',
                meta: {
                    name: '应发计提测算_ssc'
                },
            },
            {
                path: '/report/accrualReport',
                component: () => import('@/views/report/accrualReport'),
                authority: 'record:accrualReport',
                meta: {
                    name: '计提报表'
                },
            },
            {
                path: '/report/bonusReport',
                component: () => import('@/views/report/bonusReport'),
                authority: 'record:bonusReport',
                meta: {
                    name: '奖金报表'
                },
            },
            {
                path: '/report/consultantCalculations',
                component: () => import('@/views/report/consultantCalculations'),
                // authority: 'record:consultantCalculations',
                meta: {
                    name: '顾问测算'
                },
            },
            {
                path: '/report/differenceLastYear',
                component: () => import('@/views/report/differenceLastYear'),
                name: '去年差额',
                meta: {
                    name: '去年差额'
                },
            },
            {
                path: '/tools/happyNewsApproval',
                component: () => import('@/views/tools/happyNewsApproval'),
                authority: 'tools:happyNewsApproval',
                meta: {
                    name: '喜报审批'
                },
            },
            {
                path: '/tools/emailSet',
                component: () => import('@/views/tools/emailSet'),
                authority: 'tools:emailSet',
                meta: {
                    name: '邮件设置'
                },
            },
            {
                path: '/tools/exportingRecords',
                component: () => import('@/views/tools/exportingRecords'),
                authority: 'tools:exportingRecords',
                meta: {
                    name: '导出记录'
                },
            },
            {
                path: '/Pipeline/offerResume',
                component: () => import('@/views/Pipeline/offerResume'),
                authority: 'Pipeline',
                meta: {
                    name: '我offer的候选人'
                },
            },
            {
                path: '/Pipeline/approvalSubmit',
                component: () => import('@/views/Pipeline/approvalSubmit'),
                authority: 'Pipeline',
                meta: {
                    name: '我提交的审批'
                },
            },
            {
                path: '/Pipeline/teamPipeline',
                component: () => import('@/views/Pipeline/teamPipeline'),
                authority: 'Pipeline:teamPipeline',
                meta: {
                    name: '团队Pipeline'
                },
            },
            {
                path: '/tools/noContractCommentApproval',
                component: () => import('@/views/tools/noContractCommentApproval'),
                authority: 'tools:noContractCommentApproval',
                meta: {
                    name: '无合同开票审批'
                },
            },
            {
                path: '/pendingApproval',
                component: () => import('@/views/pendingApproval'),
                authority: 'pendingApproval',
                meta: {
                    name: '待审批'
                },
            },     
            {
                path: '/feedbackCheck',
                component: () => import('@/views/feedbackCheck'),
                authority: 'feedbackCheck',
                meta: {
                    name: '反馈查重问题'
                },
            }, 
            {
                path: '/similarResume',
                component: () => import('@/views/resume/similarResume'),
                meta: {
                    name: '相似候选人'
                },
            },  
                   
        ]
    }
    ,{
        path: '/supplierAllBack',
        component: () => import('@/views/supplierAllBack'),
        name: '',
    },
    {
        path: '/login',
        name: '',
        component: () => import('@/views/Login'),
    },
    {
        path: '/test',
        name: '',
        component: () => import('@/views/test'),
    },
    {
        path: '/404',
        component: () => import('@/views/errorPage/404'),
        name: '',
        hidden: true
    },
    {
        path: '/403',
        meta: {
            title: '403-权限不足'
        },
        name: 'error-403',
        component: () => import('@/views/errorPage/403.vue')
    },
    {
        path: '/resetPwd',
        component: () => import('@/views/resetPwd.vue'),
        name: '重置密码'
    },
    {
        path: '/pdf',
        name: '如何获取授权码?',
        component: () => import('@/views/pdf.vue'),
    },
    {
        path: '/activation',
        name: '卡思优派CSC招聘',
        meta: {
            name: '卡思优派CSC招聘'
        },
        component: () => import('@/views/htmlAdaptive/activation.vue'),
    },
    {
        path: '/updateResume',
        name: '更新简历',
        meta: {
            name: '更新简历'
        },
        component: () => import('@/views/htmlAdaptive/updateResume.vue'),
    },
    {
        path: '/uploadFile',
        name: '更新简历',
        meta: {
            name: '更新简历'
        },
        component: () => import('@/views/htmlAdaptive/uploadFile.vue'),
    },
    {
        path: '/onlineEdit',
        name: '在线编辑简历',
        meta: {
            name: '在线编辑简历'
        },
        component: () => import('@/views/htmlAdaptive/onlineEdit.vue'),
    },
    // {
    //     path: '/500',
    //     meta: {
    //         title: '500-服务端错误'
    //     },
    //     name: 'error-500',
    //     component: () => import('@/views/errorPage/500.vue')
    // },
]

export default router
