<template>
  <div class="digScollDom">
    <el-row>
      <el-col style="display: flex; justify-content: space-between">
        <div>
          <el-button size="mini" @click="addNew">新增项目</el-button>
          <el-button
            v-if="ListData.length > 0"
            type="default"
            plain
            size="mini"
            @click.native="downExport"
            style="margin: 0 10px"
          >
            导出
          </el-button>
        </div>
        <el-pagination
          :hide-on-single-page="filter.page.total > 10 ? false : true"
          v-if="filter.page.total > 0"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="filter.page.current"
          :page-size="filter.page.size"
          :pager-count="5"
          layout="total,prev, pager, next"
          :total="filter.page.total"
          :page-sizes="[10, 20, 50, 100]"
        ></el-pagination>
      </el-col>
      <el-col>
        <el-table
          :header-cell-style="{
            'background-color': '#f5f7fa',
            height: '35px',
          }"
          :data="ListData"
          class="tabBorder"
          style="width: 100%"
          v-loading="listLoading"
        >
          <el-table-column prop="id" label="ID" width="100"> </el-table-column>
          <el-table-column
            label="项目名称"
            min-width="200"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <span
                v-if="scope.row.canView"
                class="tabHref"
                @click="detailsPop(scope.row.id)"
                >{{ scope.row.projectName }}</span
              >
              <el-popover trigger="click" content="无法查看保密项目">
                <span
                  style="cursor: pointer"
                  v-if="!scope.row.canView"
                  slot="reference"
                  >********</span
                >
              </el-popover>
              <span class="secureIcon" v-if="scope.row.secure">保密</span>
            </template>
          </el-table-column>
          <el-table-column prop="managerName" label="项目经理" width="200">
          </el-table-column>
          <el-table-column label="操作时间" width="200">
            <template slot-scope="scope">
              {{
                scope.row.lastOperateTime
                  ? scope.row.lastOperateTime.substring(0, 10)
                  : "-"
              }}
            </template>
          </el-table-column>
          <el-table-column prop="createdUserName" label="状态">
            <template slot-scope="scope">
              <span
                class="tag"
                :class="{
                  tag02: scope.row.status == '进行中',
                  tag03: scope.row.status == '成功',
                  tag05: scope.row.status == '失败',
                  tag06: scope.row.status == '暂停',
                  tag07: scope.row.status == '取消',
                }"
                >{{ scope.row.status }}</span
              >
            </template>
          </el-table-column>
          <el-table-column label="加入项目">
            <template slot-scope="scope">
              <span
                :class="scope.row.countJoinProject ? 'tabHref' : ''"
                @click="
                  scope.row.countJoinProject
                    ? detailsPop(scope.row.id, 'join')
                    : ''
                "
                v-if="scope.row.canView"
                >{{ scope.row.countJoinProject }}</span
              >
              <span v-else>*</span>
            </template>
          </el-table-column>
          <el-table-column label="简历推荐">
            <template slot-scope="scope">
              <span
                :class="scope.row.countResumeRecommend ? 'tabHref' : ''"
                @click="
                  scope.row.countResumeRecommend
                    ? detailsPop(scope.row.id, 'recommend')
                    : ''
                "
                v-if="scope.row.canView"
                >{{ scope.row.countResumeRecommend }}</span
              >
              <span v-else>*</span>
            </template>
          </el-table-column>
          <el-table-column label="客户面试">
            <template slot-scope="scope">
              <span
                :class="scope.row.countClientInterview ? 'tabHref' : ''"
                @click="
                  scope.row.countClientInterview
                    ? detailsPop(scope.row.id, 'interview')
                    : ''
                "
                v-if="scope.row.canView"
                >{{ scope.row.countClientInterview }}</span
              >
              <span v-else>*</span>
            </template>
          </el-table-column>
          <el-table-column label="Offer"
            ><template slot-scope="scope">
              <span
                :class="scope.row.countOffer ? 'tabHref' : ''"
                @click="
                  scope.row.countOffer ? detailsPop(scope.row.id, 'offer') : ''
                "
                v-if="scope.row.canView"
                >{{ scope.row.countOffer }}</span
              >
              <span v-else>*</span>
            </template></el-table-column
          >
        </el-table>
        <el-pagination
          :hide-on-single-page="filter.page.total > 10 ? false : true"
          v-if="filter.page.total > 0"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="filter.page.current"
          :page-size="filter.page.size"
          :pager-count="5"
          layout="total,prev, pager, next"
          :total="filter.page.total"
          :page-sizes="[10, 20, 50, 100]"
        ></el-pagination>
      </el-col>
    </el-row>
    <el-drawer
      :visible.sync="drawerVisible"
      custom-class="drawerClass"
      direction="ttb"
      :modal="false"
      append-to-body
      :before-close="handleClose"
    >
      <div slot="title" class="drawerTitle">新增项目</div>
      <div class="drawerCon">
        <add-project
          v-if="drawerVisible"
          :customerId="cusId"
          :isCustomerInfo="false"
          @loadList="
            drawerVisible = false;
            loadList();
          "
        ></add-project>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import {
  getProjectPageList,
  permissionSecure,
  exportProjectList,
} from "../../api/api";
import {
  statisticsClickProjectAdd, //记录点击新增项目
} from "../../api/buryingPoint";
import addProject from "./../project/addorEdit.vue";
import { ApiBaseUrl } from "../../api/http";
export default {
  name: "cusToPro",
  props: ["cusId"],
  components: { addProject },
  data() {
    return {
      drawerVisible: false,
      filter: {
        filter: "",
        ids: [],
        id: null,
        name: "",
        managerIds: [],
        companyId: null,
        createdEndTime: "",
        createdStartTime: "",
        ownProject: false,
        participantIds: [],
        statusList: [],
        userId: null,
        page: {
          asc: [],
          ascs: [],
          countId: "",
          desc: [],
          descs: [],
          hitCount: true,
          maxLimit: null,
          optimizeCountSql: true,
          orders: [
            {
              asc: false,
              column: "lastOperateTime",
            },
          ],
          current: 1,
          size: 10,
          searchCount: true,
          pages: null,
          records: [],
          total: 0,
        },
      },
      ListData: [],
      listLoading: false,
      baseUrl: ApiBaseUrl,
    };
  },
  created() {
    this.loadList();
  },
  methods: {
    addNew() {
      statisticsClickProjectAdd({
        entry: "客户详情-项目tab-新增项目",
      }).then();
      this.drawerVisible = true;
    },
    handleClose() {
      this.drawerVisible = false;
    },
    handleSizeChange(val) {
      //pageSize 改变时会触发
      this.filter.page.size = val;
      this.filter.page.current = 1;
      this.loadList();
    },
    handleCurrentChange(val) {
      //currentPage 改变时会触发
      this.filter.page.current = val;
      this.loadList();
    },
    detailsPop(id, type) {
      permissionSecure({ id: id }).then((res) => {
        //跳转项目pipeline页面 待开发
        if (res.success) {
          if (res.result) {
            let newUrl = this.$router.resolve({
              path: "/project/details",
              query: { id: id, type: type },
            });
            window.open(newUrl.href, "_blank");
          } else {
            this.$message.error("暂无查看权限");
          }
        }
      });
    },
    loadList() {
      this.listLoading = true;
      this.filter.companyId = this.cusId;
      this.$emit("loadCount");
      getProjectPageList(this.filter).then((res) => {
        if (res.success) {
          this.listLoading = false;
          this.ListData = res.result.records;
          this.filter.page.total = res.result.total;
        }
        this.listLoading = false;
      });
    },
    downExport() {
      //导出
      let setData = false;
      let isconfirm = true;
      var int = setTimeout(function () {
        let that = this;
        setData = true;
        clearInterval(int);
        let div =
          "<p style='font-weight:bold'>后台处理中，稍后请在 " +
          "<a href='/tools/exportingRecords' style='color:#526ecc'>导出记录</a>" +
          " 获取文件</p>";
        if (isconfirm) {
          that.$globalHub
            .$confirm(div, "提示", {
              showCancelButton: false,
              showConfirmButton: false,
              customClass: "altCls",
              dangerouslyUseHTMLString: true,
            })
            .then(() => {})
            .catch(() => {});
        }
      }, 5000);
      exportProjectList(this.filter).then((res) => {
        if (res.success) {
          if (!setData) {
            isconfirm = false;
            location.href = this.baseUrl + res.result;
          }
        }
      });
    },
  },
  destroyed() {
    this.canScroll();
  },
  mounted() {
    this.noScroll(); //禁止滚动
  },
};
</script>

<style lang="scss" scoped>
.borderR {
  border-right: 1px solid #e5e5e5;
  padding-right: 8px;
  margin-right: 8px;
}
</style>