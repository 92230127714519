<template>
  <section class="createRecode">
    <el-form
      @submit.native.prevent
      :model="createRecode"
      ref="createRecode"
      label-width="100px"
    >
      <el-row :gutter="24" v-if="type" style="padding: 0 40px">
        <el-col :span="24">
          <el-form-item
            label="备注方案："
            prop="conenT"
            :rules="{
              required: true,
              message: '请选择备注方案',
              trigger: 'change',
            }"
          >
            <el-select
              style="width: 100%"
              v-model="createRecode.conenT"
              value-key="id"
              placeholder="请选择"
              v-if="!disabled"
              :disabled="disabled"
              clearable
              @change="getPlanInfo($event)"
              popper-class="selectPrpper"
              :popper-append-to-body="false"
              id="addMarkReacode"
            >
              <el-option-group label="客户沟通" v-if="type == '客户沟通'">
                <el-option
                  v-for="(i, index) in RecodePlan1"
                  :key="i.id"
                  :label="i.name"
                  :value="i"
                  :id="'addMarkReacodeRecodePlan1' + index"
                ></el-option>
              </el-option-group>
              <el-option-group
                label="候选人沟通"
                v-else-if="type == '候选人沟通'"
              >
                <el-option
                  v-for="(i, index) in RecodePlan1"
                  :key="i.id"
                  :label="i.name"
                  :value="i"
                  :id="'addMarkReacodeRecodePlan2' + index"
                ></el-option>
                <el-option
                  v-for="(i, index) in RecodePlan3"
                  :key="i.id"
                  :label="i.name"
                  :value="i"
                  :id="'addMarkReacodeRecodePlan3' + index"
                ></el-option>
              </el-option-group>
              <el-option-group label="项目信息" v-else-if="type == '项目信息'">
                <el-option
                  v-for="(i, index) in RecodePlan1"
                  :key="i.id"
                  :label="i.name"
                  :value="i"
                  :id="'addMarkReacodeRecodePlan1' + index"
                ></el-option>
              </el-option-group>
              <div v-else>
                <el-option
                  v-for="(i, index) in RecodePlan1"
                  :key="i.id"
                  :label="i.name"
                  :value="i"
                  :id="'addMarkReacodeRecodePlan1' + index"
                ></el-option>
              </div>
              <el-option-group
                :label="
                  type == '客户沟通'
                    ? '客户拜访'
                    : type == '项目信息'
                    ? '候选人反馈'
                    : '其他'
                "
                v-if="RecodePlan2.length > 0"
              >
                <el-option
                  v-for="(i, index) in RecodePlan2"
                  :key="i.id"
                  :label="i.name"
                  :value="i"
                  :id="'addMarkReacodeRecodePlan2' + index"
                ></el-option>
              </el-option-group>
            </el-select>
            <el-input
              style="width: 100%"
              disabled
              v-if="disabled"
              v-model="inputNameVal"
              id="addMarkReacodeInputName"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24" v-if="hasSelType && relativeType == '客户'">
          <el-form-item
            label="联系人："
            prop="relatePersonId"
            :rules="{
              required: true,
              message: '请选择联系人',
              trigger: 'change',
            }"
          >
            <el-select
              style="width: 100%"
              filterable
              v-model="createRecode.relatePersonId"
              placeholder="请选择"
              popper-class="selectPrpper"
              :popper-append-to-body="false"
              id="addMarkReacodeRelatePersonId"
            >
              <el-option
                v-for="(i, index) in contactsList"
                :key="i.id"
                :label="i.name"
                :value="i.id"
                :id="'addMarkReacodeContactsList' + index"
              >
              </el-option>
            </el-select>
            <i
              class="iconfont icon-xz_bd addContact"
              @click="addContact"
              id="addMarkReacodeAddContact"
            ></i>
          </el-form-item>
        </el-col>
        <el-col :span="24" v-if="hasResume">
          <el-form-item
            label="关联候选人："
            prop="relatePersonId"
            :rules="{
              required: true,
              message: '请选择关联候选人',
              trigger: 'change',
            }"
          >
            <el-select
              style="width: 100%"
              filterable
              v-model="createRecode.relatePersonId"
              placeholder="请选择"
              popper-class="selectPrpper"
              :popper-append-to-body="false"
              id="addMarkReacodeRrelatePersonId"
            >
              <el-option
                v-for="(i, index) in ResumeOptionList"
                :key="i.id"
                :label="i.name"
                :value="i.id"
                :id="'addMarkReacodeResumeOptionList' + index"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-form
      @submit.native.prevent
      label-position="top"
      v-if="RecodePlanQA.length > 0"
    >
      <el-row :gutter="24" style="padding: 0 40px">
        <el-col
          :offset="1"
          :span="23"
          v-for="(item, index) in RecodePlanQA"
          :key="item.id"
        >
          <el-form-item
            :label="index + 1 + '.' + item.title + '：'"
            :required="item.required"
            class="selectLabel"
          >
            <div v-if="item.type == '单选型' && item.selectType == '单选'">
              <el-radio-group v-model="item.answer">
                <p v-for="(i, _index) in item.options" :key="_index">
                  <el-radio
                    :label="i"
                    @click.native.prevent="clickItem(i, index)"
                    :id="'addMarkReacodeRecodePlanQARadio' + index"
                    >{{ i }}</el-radio
                  >
                </p>
              </el-radio-group>
            </div>
            <div v-if="item.type == '单选型' && item.selectType == '下拉'">
              <el-select
                v-model="item.answer"
                clearable
                placeholder="请选择"
                style="width: 100%"
                popper-class="selectPrpper"
                :popper-append-to-body="false"
                id="addMarkReacodeRecodePlanQADXxl"
              >
                <el-option
                  v-for="(i, _index) in item.options"
                  :key="_index"
                  :label="i"
                  :value="i"
                  :id="'addMarkReacodeRecodePlanQASelect' + _index"
                ></el-option>
              </el-select>
            </div>
            <div v-if="item.type == '多选型'">
              <el-checkbox-group
                v-model="item.answer1"
                id="addMarkReacodeRecodePlanQACheckbox"
              >
                <p v-for="(i, _index) in item.options" :key="_index">
                  <el-checkbox :label="i">{{ i }}</el-checkbox>
                </p>
              </el-checkbox-group>
            </div>
            <div v-if="item.type == '文本描述型'">
              <el-input
                v-model="item.answer"
                placeholder="请输入内容"
                type="textarea"
                :rows="4"
                id="addMarkReacodeRecodePlanQAInput"
              ></el-input>
            </div>
            <div v-if="item.type == '日期型'">
              <el-date-picker
                prefix-icon="el-icon-date"
                v-model="item.answer"
                type="date"
                placeholder="选择日期"
                value-format="yyyy/MM/dd"
                style="width: 100%"
                popper-class="selectPrpper"
                :popper-append-to-body="false"
                id="addMarkReacodeRecodePlanQADate"
              ></el-date-picker>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-dialog
      title="客户联系人"
      :visible.sync="dialogVisibleLinkMan"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :before-close="handClose"
      width="600px"
      append-to-body
    >
      <el-form
        @submit.native.prevent
        :model="linkManFrom"
        ref="linkManFrom"
        :rules="rulesLink"
        style="padding: 0 50px"
        :validate-on-rule-change="false"
        label-width="100px"
        label-position="right"
      >
        <el-form-item label="姓名：" prop="name">
          <el-input
            placeholder="请输入内容"
            v-model="linkManFrom.name"
            maxlength="20"
            id="addMarkReacodeLinkManName"
          ></el-input>
        </el-form-item>
        <el-form-item label="职位：" prop="position">
          <el-input
            placeholder="请输入内容"
            v-model="linkManFrom.position"
            maxlength="20"
            id="addMarkReacodeLinkManPosition"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系电话：" prop="tel">
          <el-input
            placeholder="请输入内容"
            v-model="linkManFrom.tel"
            maxlength="50"
            id="addMarkReacodeLinkManTel"
          ></el-input>
        </el-form-item>
        <el-form-item label="邮箱：" prop="email">
          <el-input
            placeholder="请输入内容"
            v-model="linkManFrom.email"
            maxlength="50"
            id="addMarkReacodeLinkManEmail"
          ></el-input>
        </el-form-item>
        <el-form-item label="微信或其他：" prop="wechatOrOther">
          <el-input
            placeholder="请输入内容"
            v-model="linkManFrom.wechatOrOther"
            maxlength="50"
            id="addMarkReacodeLinkManWechatOrOther"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            dialogVisibleLinkMan = false;
            resetForm('linkManFrom');
          "
          size="small"
          id="addMarkReacodeLinkManCencel"
          >取 消</el-button
        >
        <el-button
          type="primary"
          size="small"
          @click="submitF('linkManFrom')"
          id="addMarkReacodeLinkManSave"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </section>
</template>

<script>
import {
  getRecordPlanList,
  getCommentColumnById,
  addOrEditCommonRecord,
  getContactsBaseInfos, //获取客户联系人
  comContactsAddOrEdit, //新增客户联系人
  GetsysResumeOption, //获取关联候选人
} from "../../api/api";
export default {
  props: [
    "customId",
    "resumeId",
    "relativeType",
    "type",
    "linkManId",
    "inputName",
    "disableEdit",
    "clearSelect",
    "fromPipe",
  ], //relativeType：备注类型/人才 客户 项目；type：备注方案类型
  data() {
    return {
      hasSelType: false,
      hasResume: false,
      contactsList: [], //联系人列表
      ResumeOptionList: [], //候选人列表
      RecodePlan1: [], //备注方案List
      RecodePlan3: [], //备注方案List
      RecodePlan2: [], //备注方案List
      RecodePlanQA: [], //备注方案ListQA
      disabled: false,
      createRecode: {
        commentId: "",
        id: 0,
        conenT: "",
        relate: "",
        relatePersonId: "",
        commentColumns: [], //问题答案
        relativeId: "",
        relativeType: "",
        version: 0,
        resumeId: "",
      },
      dialogVisibleLinkMan: false,
      linkManFrom: {
        companyId: null,
        email: "",
        id: 0,
        name: "",
        position: "",
        tel: "",
        version: 0,
        wechatOrOther: "",
      },
      rulesLink: {
        name: [
          {
            required: true,
            trigger: "blur",
            message: "请输入姓名",
          },
        ],
        position: [
          {
            required: true,
            trigger: "blur",
            message: "请输入职位名称",
          },
        ],
      },
      inputNameVal:''
    };
  },
  watch: {},
  created() {
    if (this.relativeType == "客户") {
      //客户联系人list
      this.loadContactsLitst();
    }
    if (this.type) {
      this.loadRecodePlanList(this.type);
      if (this.type == "客户沟通") {
        this.loadRecodePlanList("客户拜访");
      }
      if (this.type == "项目信息") {
        this.loadRecodePlanList("候选人反馈");
      }
      if (this.type == "候选人沟通") {
        this.loadRecodePlanList("其他");
      }
    }
    this.disabled = false;
    if (this.disableEdit) {
      this.disabled = true;
    }
    if(this.inputName){
      this.inputNameVal=this.inputName
    }
    this.createRecode.relativeType = this.relativeType;
  },
  methods: {
    handClose() {
      this.dialogVisibleLinkMan = false;
      this.resetForm("linkManFrom");
    },
    laodResumeOption() {
      //关联候选人
      GetsysResumeOption({ id: this.customId }).then((res) => {
        if (res.success) {
          this.ResumeOptionList = res.result;
        }
      });
    },
    loadContactsLitst() {
      //获取客户联系人
      if (this.customId) {
        getContactsBaseInfos({ companyId: this.customId }).then((res) => {
          if (res.success) {
            this.contactsList = res.result;
            this.createRecode.relatePersonId = "";
            if (this.linkManId) {
              this.createRecode.relatePersonId = this.linkManId;
            } else {
              if (res.result && res.result.length > 0) {
                this.createRecode.relatePersonId = res.result[0].id;
              }
            }
          }
        });
      }
    },
    addContact() {
      this.dialogVisibleLinkMan = true;
    },
    submitF(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (
            !this.linkManFrom.tel &&
            !this.linkManFrom.email &&
            !this.linkManFrom.wechatOrOther
          ) {
            this.$message.error("联系电话/邮箱/微信或其他至少填写1项");
            return false;
          }
          this.linkManFrom.companyId = this.customId;
          comContactsAddOrEdit(this.linkManFrom).then((res) => {
            if (res.success) {
              this.$message.success("保存成功");
              this.dialogVisibleLinkMan = false;
              this.resetForm("linkManFrom");
              this.loadContactsLitst();
            }
          });
        } else {
          return false;
        }
      });
    },
    getPlanInfo(e, ans) {
      //获取备注方案问题
      if (e) {
        this.hasSelType = true;
        if (e.type == "候选人反馈") {
          this.hasResume = true;
          this.laodResumeOption();
        } else {
          this.hasResume = false;
        }
        this.createRecode.commentId = e.id;
        getCommentColumnById({ commentId: e.id }).then((res) => {
          if (res.success) {
            this.RecodePlanQA = res.result;
            if (ans) {
              this.RecodePlanQA.forEach((item) => {
                ans.forEach((i) => {
                  if (item.id == i.commentColumnId) {
                    if (i.type != "多选型") {
                      this.$set(item, "answer", i.value);
                      this.$set(item, "answer1", []);
                    } else {
                      this.$set(item, "answer", "");
                      this.$set(
                        item,
                        "answer1",
                        i.value ? i.value.split(",") : []
                      );
                    }
                  }
                });
              });
            } else {
              this.RecodePlanQA.forEach((item) => {
                this.$set(item, "answer1", []);
                this.$set(item, "answer", "");
              });
            }
          }
        });
      } else {
        this.hasSelType = false;
        this.RecodePlanQA = [];
      }
    },
    loadRecodePlanList(type) {
      //加载备注记录List
      getRecordPlanList({
        type: type,
      }).then((res) => {
        if (res.success) {
          if (type == "客户拜访" || type == "候选人反馈") {
            //客户 项目方案组
            this.RecodePlan2 = res.result;
          } else if (type == "其他") {
            this.RecodePlan2 = res.result;
          } else {
            this.RecodePlan1 = res.result;
          }
        }
      });
    },
    saveMarkRecode() {
      this.$emit("changeSaveLoading", true);
      return new Promise((resolve, reject) => {
        this.$refs["createRecode"].validate((valid) => {
          if (valid) {
            this.createRecode.commentColumns = [];
            for (let i = 0; i < this.RecodePlanQA.length; i++) {
              if (
                this.RecodePlanQA[i].required &&
                (this.RecodePlanQA[i].answer == "" ||
                  this.RecodePlanQA[i].answer == null) &&
                this.RecodePlanQA[i].answer1.length == 0
              ) {
                reject();
                this.$message.error("请将必填项填写完整");
                this.$emit("changeSaveLoading", false);
                return false;
              }
            }
            this.RecodePlanQA.map((item) => {
              this.createRecode.commentColumns.push({
                columnTitle: item.title,
                commentColumnId: item.id,
                type: item.type,
                value:
                  item.type == "多选型" ? item.answer1.join(",") : item.answer,
              });
            });
            this.createRecode.relativeId = Number(this.customId);
            this.createRecode.resumeId = Number(this.resumeId);
            delete this.createRecode.conenT;
            delete this.createRecode.relate;
            addOrEditCommonRecord(this.createRecode).then((res) => {
              if (res.success) {
                this.$emit("changeSaveLoading", false);
                if (this.fromPipe) {
                  this.$message.success("操作成功");
                } else {
                  this.$message.success(res.message);
                }
                this.$emit("loadRecodeList", this.createRecode);
                this.RecodePlanQA = [];
                this.disabled = false;
                this.removeStore("RecordItem");
              }
              resolve();
            });
          } else {
            this.$emit("changeSaveLoading", false);
            this.$emit("Invalidate", true);
            reject();
          }
        });
      });
    },
    loadEditInfo() {
      //获取编辑信息
      let row = JSON.parse(this.getStore("RecordItem"));
      // 是否可编辑
      // this.disabled = true;
      this.createRecode = row;
      this.createRecode.conenT = {
        id: row.commentId,
        name: row.commentName,
        type: row.type,
      };
      if (this.disableEdit) {
        this.inputNameVal = row.commentName;
      }
      this.getPlanInfo(this.createRecode.conenT, row.commentColumns);
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    clickItem(i, index) {
      if (i == this.RecodePlanQA[index].answer) {
        this.RecodePlanQA[index].answer = "";
      } else {
        this.RecodePlanQA[index].answer = i;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.el-radio-group p {
  padding: 5px 0;
}
.addContact {
  color: #898989;
  font-size: 18px;
  position: absolute;
  display: inline-block;
  right: -30px;
  top: 10px;
  height: 18px;
  line-height: 18px;
  &:hover {
    color: #e60012;
  }
}
</style>
<style lang="scss">
.createRecode {
  position: relative;
  max-height: 50vh;
  overflow-x: hidden;
  overflow-y: auto;
  .el-checkbox-group {
    p {
      height: 28px;
      label {
        height: 28px;
        line-height: 28px;
        span {
          line-height: 28px;
        }
      }
    }
  }
}
.selectLabel {
  margin-bottom: 10px !important;
  label {
    padding: 0 !important;
  }
}
.selectPrpper {
  z-index: 9999 !important;
}
</style>

