<template>
  <div>
    <el-dialog
      title="上传附件"
      :visible.sync="dialogVisibleAttach"
      width="600px"
      :before-close="cancel"
      append-to-body
    >
      <el-form
        @submit.native.prevent
        :model="attachFrom"
        ref="attachFrom"
        style="padding: 0 50px"
        :validate-on-rule-change="false"
        label-width="100px"
        :rules="rulesAttach"
        label-position="right"
      >
        <el-form-item label="附件类型：" prop="type">
          <el-select
            placeholder="请选择"
            filterable
            v-model="attachFrom.type"
            style="width: 100%"
            id="uploadAttachMent"
          >
            <el-option
              v-for="(item, index) in AttachTypeList"
              :key="'att_' + index"
              :value="item.name"
              :label="item.name"
              :id="'uploadAttachMentList'+index"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上传附件：" prop="fileList">
          <el-upload
            ref="upload"
            class="upload-demo"
            drag
            multiple
            :headers="myHeaders"
            :action="actionUrl"
            :before-upload="beforeUpload"
            :on-success="handleInfoSuccess"
            :on-error="handleInfoError"
            :on-exceed="handleInfoChange"
            :file-list="fileInfoList"
            :on-remove="handleRemove"
            accept=".doc,.docx,.xls,.xlsx,.pdf,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/png,image/jpeg,application/zip,"
            :limit="10"
            id="uploadAttachMentFileList"
          >
            <i class="el-icon-upload" style="margin-top: 50px"></i>
            <div class="el-upload__text" style="line-height: 1">
              将文件拖到此处，或
              <span class="tabHref">点击上传</span>
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel" size="small"  id="uploadAttachMentCencel">取 消</el-button>
        <el-button
          type="primary"
          size="small"
          :loading="isattachFrom"
          @click="submitAttach('attachFrom')"
          id="uploadAttachMentSave"
          >保 存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  attachmentAddOrEdit, //新增编辑附件
} from "../api/api";
import Cookies from "js-cookie";
import * as data from "../common/json/commData.json";
import { ApiBaseUrl } from "../api/http";
export default {
  name: "attachment",
  props: ["objectId", "way", "moduleType", "dialogVisibleAttach"],
  data() {
    return {
      //附件
      AttachTypeList:
        this.moduleType == "客户" ? data.cusAttachType : data.proAttachType,
      actionUrl: ApiBaseUrl + "/api/file/uploadOne",
      myHeaders: {
        Authorization: Cookies.get("accessToken"),
      },
      fileInfoList: [], //上传List文件
      attachFrom: {
        fileList: [],
        id: null,
        moduleType: "",
        objectId: null,
        type: "",
      },
      rulesAttach: {
        type: [
          {
            required: true,
            trigger: "change",
            message: "请选择附件类型",
          },
        ],
        fileList: [
          {
            required: true,
            message: "请上传附件",
          },
        ],
      },
      docData: [],
      isattachFrom: false,
    };
  },
  created() {
    this.attachFrom.type = this.moduleType == "客户" ? "公司架构" : "JD";
  },
  methods: {
    cancel() {
      //取消
      this.$emit("cancelAttath");
    },
    submitAttach(formName) {
      this.isattachFrom = true;
      //保存附件
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.attachFrom.moduleType = this.moduleType;
          this.attachFrom.objectId = this.objectId;
          attachmentAddOrEdit(this.attachFrom).then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.fileInfoList = [];
              if (this.way == 1) {
                this.$emit("loadReload", this.objectId);
              }
              if (this.way == 2) {
                this.$emit("loadAttach", this.objectId);
              }
              this.isattachFrom = false;
            }
          });
        } else {
          this.isattachFrom = false;
          return false;
        }
      });
    },
    handleInfoSuccess(res, file, fileList) {
      if (fileList && fileList.length > 0) {
        this.$refs["attachFrom"].clearValidate(["fileList"]); // 去掉file验证
      }
      this.fileInfoList = fileList;
      this.attachFrom.fileList = [];
      fileList.forEach((item) => {
        this.attachFrom.fileList.push({
          filePath: item.response ? item.response.result : "",
          fileName: item.name,
          fileType: item.raw.type,
        });
      });
    },
    handleInfoError(err) {
      this.$message.error("文件上传出错");
    },
    beforeUpload: function (file) {
      const isLt = file.size / 1024 / 1024 < 20;
      if (!isLt) {
        this.$message.error("上传文件大小不能超过 20MB!");
      }
      return isLt;
    },
    handleRemove(file, fileList) {
      this.attachFrom.fileList = [];
      fileList.forEach((item) => {
        this.attachFrom.fileList.push({
          filePath: item.response.result,
          fileName: item.name,
          fileType: item.raw.type,
        });
      });
    },
    handleInfoChange(files, fileList) {
      this.$message.error("最多上传10份文件");
    },
  },
};
</script>

<style lang="scss" scoped>
.borderR {
  border-right: 1px solid #e5e5e5;
  padding-right: 8px;
  margin-right: 8px;
}
.attmType {
  padding: 0 8px;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  font-size: 12px;
  display: inline-block;
}
.txl {
  border-color: #eca427;
  color: #eca427;
}
.gsjg {
  border-color: #52ceac;
  color: #52ceac;
}
</style>