<template>
  <section style="background-color: #fff">
    <div class="drawerCon_head">
      <p class="name">
        {{ comInfo.name }}
        <span
          class="comStatus"
          v-if="
            comInfo.status == '开发中' ||
            comInfo.status == 'trial_case' ||
            comInfo.status == '签约中' ||
            comInfo.status == '已签约'
          "
          >客户保护中</span
        >
        <span class="comStatus" v-if="comInfo.dueDate">{{
          comInfo.dueDate
        }}</span>
        <el-popover placement="bottom-start" width="300" trigger="hover">
          <div>
            根据公司管理规定，客户保护机制如下：<br />
            【开发中】的客户保护期为30天，30天后没有更新客户状态则自动变为普通公司。<br />
            【trial_case】的客户保护期为90天，90天后没有更新客户状态将自动变为普通公司。但如果该客户下有候选人达到了offer阶段则始终保护。<br />
            【已签约】的客户合同期内永久保护，合同到期或终止合作时取消保护。
          </div>
          <i slot="reference" class="iconfont icon-kh_wh"></i>
        </el-popover>
        <span class="subInfo">
          创建人：<i>{{ comInfo.creatorName }}（{{ comInfo.createdTime }}）</i>
        </span>
      </p>
      <p
        v-if="comInfo.influenceLabels && comInfo.influenceLabels.length > 0"
        style="margin-top: 3px"
      >
        <span
          class="tag"
          v-for="(item, index) in comInfo.influenceLabels"
          :key="index"
          :style="
            item.bordered
              ? { 'border-color': item.color, color: item.color }
              : {}
          "
          style="margin-bottom: 5px; margin-right: 5px"
          v-html="item.name"
          >{{ item.name }}</span
        >
      </p>
    </div>
    <div class="drawerCon_con">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="公司详情" name="1"></el-tab-pane>
        <el-tab-pane
          :label="
            Count.attachmentCount ? '附件' + Count.attachmentCount : '附件0'
          "
          name="2"
        ></el-tab-pane>
        <el-tab-pane
          :label="Count.markCount ? '备注' + Count.markCount : '备注0'"
          name="3"
        ></el-tab-pane>
        <el-tab-pane
          :label="Count.projectCount ? '项目' + Count.projectCount : '项目0'"
          name="4"
        ></el-tab-pane>
        <el-tab-pane
          :label="Count.resumeCount ? '人才' + Count.resumeCount : '人才0'"
          name="8"
        ></el-tab-pane>
        <el-tab-pane
          :label="Count.contractCount ? '合同' + Count.contractCount : '合同0'"
          name="5"
        ></el-tab-pane>
        <el-tab-pane
          :label="Count.scheduleCount ? '日程' + Count.scheduleCount : '日程0'"
          name="6"
        ></el-tab-pane>
        <el-tab-pane label="操作日志" name="7"></el-tab-pane>
      </el-tabs>
      <div class="info_con" v-if="activeName == '1'">
        <div
          class="drawerCon_con_tabs"
          style="flex: none; width: calc(100% - 240px)"
        >
          <p class="drawerCon_from_tit">基本信息</p>
          <el-form label-width="100px" label-position="left">
            <el-row :gutter="24">
              <el-col :span="11">
                <el-form-item label="公司名称：">
                  <div class="canEdit">
                    {{ comInfo.name }}
                    <i
                      @click.stop="showComEdit(comInfo.id)"
                      class="el-icon-edit tabHref"
                    ></i>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :offset="1" :span="12">
                <el-form-item label="简称：">
                  <div class="canEdit">
                    {{ comInfo.commonCompanyName }}
                    <el-popover
                      placement="bottom"
                      title="编辑"
                      popper-class="popperEdit"
                      trigger="click"
                      @show="getComInfoById(comInfo.id)"
                    >
                      <i slot="reference" class="el-icon-edit tabHref"></i>
                      <div class="popcon">
                        <div class="popcon-input">
                          <el-input
                            size="mini"
                            style="width: 300px"
                            v-model="company.company.commonCompanyName"
                          ></el-input>
                        </div>
                        <div class="subBtn">
                          <el-button
                            size="mini"
                            @click="cancel"
                            icon="el-icon-close"
                          ></el-button
                          ><el-button
                            type="primary"
                            size="mini"
                            icon="el-icon-check"
                            @click="checkVal('', 2, 'commonCompanyName')"
                          ></el-button>
                        </div>
                      </div>
                    </el-popover></div
                ></el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="24">
              <el-col :span="11">
                <el-form-item label="行业：">
                  <div class="canEdit">
                    {{
                      comInfo.industryName
                        ? comInfo.industryName
                        : "&nbsp;&nbsp;"
                    }}
                    <el-popover
                      placement="bottom"
                      title="编辑"
                      popper-class="popperEdit"
                      trigger="click"
                      @show="getComInfoById(comInfo.id)"
                    >
                      <i slot="reference" class="el-icon-edit tabHref"></i>
                      <div class="popcon">
                        <div class="popcon-input">
                          <el-cascader
                            v-model="company.companyIndustryIds"
                            :options="ColumnInList"
                            :props="props"
                            style="width: 300px"
                            clearable
                            filterable
                            collapse-tags
                            :show-all-levels="false"
                            placeholder="请选择"
                          ></el-cascader>
                        </div>
                        <div class="subBtn">
                          <el-button
                            size="mini"
                            @click="cancel"
                            icon="el-icon-close"
                          ></el-button
                          ><el-button
                            type="primary"
                            size="mini"
                            icon="el-icon-check"
                            @click="checkVal('', 1, 'companyIndustryIds')"
                          ></el-button>
                        </div>
                      </div>
                    </el-popover></div
                ></el-form-item>
              </el-col>
              <el-col :offset="1" :span="12">
                <el-form-item label="类型：">
                  <div>
                    <span
                      class="tag"
                      :class="{
                        tag01: comInfo.status == 'trial_case',
                        tag02: comInfo.status == '开发中',
                        tag03: comInfo.status == '已签约',
                        tag04: comInfo.status == '签约中',
                        tag05: comInfo.status == '终止合作',
                        tag06: comInfo.status == '合同到期',
                        tag07: comInfo.status == '普通公司',
                      }"
                      >{{ comInfo.status }}</span
                    >
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="24">
              <el-col :span="12">
                <el-form-item label="企业性质：">
                  <div class="canEdit">
                    {{
                      comInfo.natureTypeName
                        ? comInfo.natureTypeName
                        : "-"
                    }}
                    <el-popover
                      placement="bottom"
                      title="编辑"
                      popper-class="popperEdit"
                      trigger="click"
                      @show="getComInfoById(comInfo.id)"
                    >
                      <i slot="reference" class="el-icon-edit tabHref"></i>
                      <div class="popcon">
                        <div class="popcon-input">
                          <el-select
                            style="width: 300px"
                            v-model="company.company.natureType"
                            clearable
                            filterable
                            placeholder="请选择"
                          >
                            <el-option
                              v-for="(item, index) in natureTypeList"
                              :key="'n_' + index"
                              :value="item.value"
                              :label="item.name"
                            >
                            </el-option>
                          </el-select>
                        </div>
                        <div class="subBtn">
                          <el-button
                            size="mini"
                            @click="cancel"
                            icon="el-icon-close"
                          ></el-button
                          ><el-button
                            type="primary"
                            size="mini"
                            icon="el-icon-check"
                            @click="checkVal('', 2, 'natureType')"
                          ></el-button>
                        </div>
                      </div>
                    </el-popover></div
                ></el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item label="城市：">
                  <div class="canEdit">
                    {{ comInfo.cityName ? comInfo.cityName : "-" }}
                    <el-popover
                      placement="bottom"
                      title="编辑"
                      popper-class="popperEdit"
                      trigger="click"
                      @show="getComInfoById(comInfo.id)"
                    >
                      <i slot="reference" class="el-icon-edit tabHref"></i>
                      <div class="popcon">
                        <div class="popcon-input">
                          <el-cascader
                            style="width: 300px"
                            v-model="company.allCityIds"
                            :options="cityList"
                            :props="{
                              value: 'id',
                              label: 'name',
                              children: 'children',
                              checkStrictly: true,
                            }"
                            placeholder="请选择"
                          ></el-cascader>
                        </div>
                        <div class="subBtn">
                          <el-button
                            size="mini"
                            @click="cancel"
                            icon="el-icon-close"
                          ></el-button
                          ><el-button
                            type="primary"
                            size="mini"
                            icon="el-icon-check"
                            @click="checkVal('', 1, 'allCityIds')"
                          ></el-button>
                        </div>
                      </div>
                    </el-popover></div
                ></el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="BD线索顾问：">
                  <div class="canEdit">
                    {{
                      comInfo.clueConsultantName
                        ? comInfo.clueConsultantName
                        : "-"
                    }}
                    <el-popover
                      placement="bottom"
                      title="编辑"
                      popper-class="popperEdit"
                      trigger="click"
                      v-if="comInfo.canEditLeadingConsultant"
                      @show="getComInfoById(comInfo.id)"
                    >
                      <i slot="reference" class="el-icon-edit tabHref"></i>
                      <div class="popcon">
                        <div class="popcon-input">
                          <el-select
                            style="width: 300px"
                            v-model="company.company.clueConsultantId"
                            clearable
                            filterable
                            placeholder="请选择"
                          >
                            <el-option
                              v-for="i in userList"
                              :key="i.id"
                              :label="i.name"
                              :value="i.id"
                            >
                            </el-option>
                          </el-select>
                        </div>
                        <div class="subBtn">
                          <el-button
                            size="mini"
                            @click="cancel"
                            icon="el-icon-close"
                          ></el-button
                          ><el-button
                            type="primary"
                            size="mini"
                            icon="el-icon-check"
                            @click="checkVal('', 2, 'clueConsultantId')"
                          ></el-button>
                        </div>
                      </div>
                    </el-popover></div
                ></el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="24">
              <el-col :span="11">
                <el-form-item label="地址：">
                  <div class="canEdit">
                    {{ comInfo.address ? comInfo.address : "-" }}
                    <el-popover
                      placement="bottom"
                      title="编辑"
                      popper-class="popperEdit"
                      trigger="click"
                      @show="getComInfoById(comInfo.id)"
                    >
                      <i slot="reference" class="el-icon-edit tabHref"></i>
                      <div class="popcon">
                        <div class="popcon-input">
                          <el-input
                            size="mini"
                            maxlength="100"
                            style="width: 300px"
                            v-model.trim="company.company.address"
                          ></el-input>
                        </div>
                        <div class="subBtn">
                          <el-button
                            size="mini"
                            @click="cancel"
                            icon="el-icon-close"
                          ></el-button
                          ><el-button
                            type="primary"
                            size="mini"
                            icon="el-icon-check"
                            @click="checkVal('', 2, 'address')"
                          ></el-button>
                        </div>
                      </div>
                    </el-popover>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :offset="1" :span="12">
                <el-form-item label="BD主导顾问：">
                  <div class="canEdit">
                    {{
                      comInfo.leadingConsultantName
                        ? comInfo.leadingConsultantName
                        : "-"
                    }}
                    <el-popover
                      placement="bottom"
                      title="编辑"
                      popper-class="popperEdit"
                      trigger="click"
                      v-if="comInfo.canEditClueConsultant"
                      @show="getComInfoById(comInfo.id)"
                    >
                      <i slot="reference" class="el-icon-edit tabHref"></i>
                      <div class="popcon">
                        <div class="popcon-input">
                          <el-select
                            clearable
                            filterable
                            style="width: 300px"
                            v-model="company.company.leadingConsultantId"
                            placeholder="请选择"
                          >
                            <el-option
                              v-for="i in userList"
                              :key="i.id"
                              :label="i.name"
                              :value="i.id"
                            >
                            </el-option>
                          </el-select>
                        </div>
                        <div class="subBtn">
                          <el-button
                            size="mini"
                            @click="cancel"
                            icon="el-icon-close"
                          ></el-button
                          ><el-button
                            type="primary"
                            size="mini"
                            icon="el-icon-check"
                            @click="checkVal('', 2, 'leadingConsultantId')"
                          ></el-button>
                        </div>
                      </div>
                    </el-popover></div
                ></el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="24">
              <el-col :span="11">
                <el-form-item label="公司电话：">
                  <div class="canEdit">
                    {{ comInfo.tel ? comInfo.tel : "-" }}
                    <el-popover
                      placement="bottom"
                      title="编辑"
                      popper-class="popperEdit"
                      trigger="click"
                      @show="getComInfoById(comInfo.id)"
                    >
                      <i slot="reference" class="el-icon-edit tabHref"></i>
                      <div class="popcon">
                        <div class="popcon-input">
                          <el-input
                            placeholder="请输入"
                            size="mini"
                            maxlength="15"
                            style="width: 300px"
                            v-model.trim="company.company.tel"
                          ></el-input>
                        </div>
                        <div class="subBtn">
                          <el-button
                            size="mini"
                            @click="cancel"
                            icon="el-icon-close"
                          ></el-button
                          ><el-button
                            type="primary"
                            size="mini"
                            icon="el-icon-check"
                            @click="checkVal('', 2, 'tel')"
                          ></el-button>
                        </div>
                      </div>
                    </el-popover>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :offset="1" :span="12">
                <el-form-item label="公司网址：">
                  <div class="canEdit">
                    {{ comInfo.website ? comInfo.website : "-" }}
                    <el-popover
                      placement="bottom"
                      title="编辑"
                      popper-class="popperEdit"
                      trigger="click"
                      @show="getComInfoById(comInfo.id)"
                    >
                      <i slot="reference" class="el-icon-edit tabHref"></i>
                      <div class="popcon">
                        <div class="popcon-input">
                          <el-input
                            placeholder="请不要输入超过50个字"
                            maxlength="50"
                            size="mini"
                            style="width: 300px"
                            v-model.trim="company.company.website"
                          ></el-input>
                        </div>
                        <div class="subBtn">
                          <el-button
                            size="mini"
                            @click="cancel"
                            icon="el-icon-close"
                          ></el-button
                          ><el-button
                            type="primary"
                            size="mini"
                            icon="el-icon-check"
                            @click="checkVal('', 2, 'website')"
                          ></el-button>
                        </div>
                      </div>
                    </el-popover></div
                ></el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item label="子母公司：">
                  <span class="chCom" v-if="comInfo.parentChildRelationType">{{
                    comInfo.parentChildRelationType
                  }}</span>
                  <span v-else>-</span>
                </el-form-item>
              </el-col>
              <el-col
                :offset="1"
                :span="12"
                v-if="comInfo.parentChildRelationType == '子公司'"
              >
                <el-form-item label="母公司名称：">
                  <div class="canEdit">
                    {{ comInfo.parentName ? comInfo.parentName : "-" }}
                    <el-popover
                      placement="bottom"
                      title="编辑"
                      popper-class="popperEdit"
                      trigger="click"
                      @show="getComInfoById(comInfo.id)"
                    >
                      <i slot="reference" class="el-icon-edit tabHref"></i>
                      <div class="popcon">
                        <div class="popcon-input">
                          <el-select
                            placeholder="请选择"
                            filterable
                            v-model="company.company.parentId"
                            style="width: 300px"
                          >
                            <el-option
                              v-for="item in AllCompany"
                              :key="item.id"
                              :value="item.id"
                              :label="item.name"
                            >
                            </el-option>
                          </el-select>
                        </div>
                        <div class="subBtn">
                          <el-button
                            size="mini"
                            @click="cancel"
                            icon="el-icon-close"
                          ></el-button
                          ><el-button
                            type="primary"
                            size="mini"
                            icon="el-icon-check"
                            @click="checkVal('', 2, 'parentId')"
                          ></el-button>
                        </div>
                      </div>
                    </el-popover>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :offset="1" :span="12">
                <el-form-item label="参与顾问：">
                  <div class="canEdit">
                    <span
                      v-if="
                        comInfo.participantNames &&
                        comInfo.participantNames.length == 0
                      "
                      >-</span
                    >
                    <span
                      v-else
                      v-for="(item, index) in comInfo.participantNames"
                      :key="index"
                      >{{ item }}</span
                    >
                    <el-popover
                      placement="bottom"
                      title="编辑"
                      popper-class="popperEdit"
                      trigger="click"
                      v-if="comInfo.canEditParticipant"
                      @show="getComInfoById(comInfo.id)"
                    >
                      <i slot="reference" class="el-icon-edit tabHref"></i>
                      <div class="popcon">
                        <div class="popcon-input">
                          <el-cascader
                            class="searchCascader"
                            style="width: 300px"
                            filterable
                            clearable
                            :options="options2"
                            collapse-tags
                            :show-all-levels="false"
                            v-model="comInfo.participantIds"
                            :props="props"
                            placeholder="请选择小类"
                            ref="dataRef"
                            @change="getSearch"
                            :filter-method="filterFun"
                          >
                          </el-cascader>
                        </div>
                        <div class="subBtn">
                          <el-button
                            size="mini"
                            @click="cancel"
                            icon="el-icon-close"
                          ></el-button
                          ><el-button
                            type="primary"
                            size="mini"
                            icon="el-icon-check"
                            @click="checkVal('', 1, 'participantIds')"
                          ></el-button>
                        </div>
                      </div>
                    </el-popover></div
                ></el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="合作情况：">
                  <div class="canEdit">
                    <span
                      v-if="comInfo.cooperationLabels"
                      class="tag"
                      v-for="(item, index) in comInfo.cooperationLabels"
                      :key="index"
                      :style="
                        item.bordered
                          ? { 'border-color': item.color, color: item.color }
                          : {}
                      "
                      style="margin-bottom: 5px; margin-right: 5px"
                      v-html="item.name"
                      >{{ item.name }}</span
                    >
                    <span v-else>-</span>
                    <!-- {{ comInfo.website ? comInfo.website : "-" }} -->
                    <el-popover
                      placement="bottom"
                      title="编辑"
                      popper-class="popperEdit"
                      trigger="click"
                      @show="getComInfoById(comInfo.id)"
                    >
                      <i slot="reference" class="el-icon-edit tabHref"></i>
                      <div class="popcon">
                        <div class="popcon-input">
                          <el-select
                            clearable
                            filterable
                            multiple
                            collapse-tags
                            style="width: 300px"
                            v-model="company.labelIds"
                            placeholder="请选择"
                          >
                            <el-option
                              v-for="i in labelList"
                              :key="i.id"
                              :label="i.name"
                              :value="i.id"
                            >
                            </el-option>
                          </el-select>
                        </div>
                        <div class="subBtn">
                          <el-button
                            size="mini"
                            @click="cancel"
                            icon="el-icon-close"
                          ></el-button
                          ><el-button
                            type="primary"
                            size="mini"
                            icon="el-icon-check"
                            @click="checkVal('', 1, 'labelIds')"
                          ></el-button>
                        </div>
                      </div>
                    </el-popover></div
                ></el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <p class="drawerCon_from_tit">联系人</p>
          <el-row class="innertab">
            <el-table
              :header-cell-style="{
                'background-color': '#f5f7fa',
              }"
              :data="comInfo.contactsList"
              style="width: 100%"
            >
              <el-table-column prop="name" label="姓名" width="150">
                <template slot-scope="scope">
                  {{ comInfo.canViewContacts ? scope.row.name : "********" }}
                </template>
              </el-table-column>
              <el-table-column label="备注" width="80">
                <template slot-scope="scope">
                  <div>
                    <el-popover
                      placement="bottom-start"
                      width="450"
                      trigger="hover"
                      popper-class="popperH"
                      :open-delay="1000"
                      @show="showMark(scope.row, '人才')"
                      @hide="showMark(scope.row, '人才')"
                    >
                      <el-row :gutter="24">
                        <div v-if="remarkDatas.length > 0">
                          <div
                            v-for="(item, index) in remarkDatas"
                            class="time-line-div"
                            :key="index"
                          >
                            <div class="time-line_l">
                              <p class="time">{{ item.createdTime }}</p>
                              <p class="name">{{ item.creatorName }}</p>
                            </div>
                            <p class="line"></p>
                            <div class="time-line_r" style="padding: 0 10px">
                              <div class="time-line_con">
                                <p class="r_title">
                                  <span
                                    class="tag"
                                    :class="{
                                      tag15:
                                        item.type == '项目信息' ||
                                        item.type == '客户沟通',
                                      tag09: item.type == '候选人反馈',
                                      tag14: item.type == '客户拜访',
                                    }"
                                    >{{ item.commentName }}</span
                                  >
                                  <span
                                    v-if="item.relatePersonName"
                                    style="margin-left: 10px"
                                    >联系人：{{ item.relatePersonName }}</span
                                  >
                                </p>
                                <ul class="r_con">
                                  <li
                                    v-for="(i, index) in item.commentColumns"
                                    :key="'Col' + index"
                                  >
                                    <p class="tit">
                                      {{ index + 1 }}.{{ i.columnTitle }}：
                                    </p>
                                    <p class="con">
                                      <span v-html="i.valueHtml"></span>
                                    </p>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div class="dott"></div>
                        </div>
                        <div v-else style="color: #999">数据加载中…</div>
                      </el-row>
                      <div
                        slot="reference"
                        class="tabHref"
                        @click="addMark(scope.row.id)"
                      >
                        <i class="iconfont icon-pl_bz"></i>
                        <span style="padding-left: 3px">{{
                          scope.row.commentRecordCount
                        }}</span>
                      </div>
                    </el-popover>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="职位"
                :show-overflow-tooltip="true"
                width="150"
              >
                <template slot-scope="scope">
                  {{ scope.row.position ? scope.row.position : "-" }}
                </template>
              </el-table-column>
              <el-table-column
                label="电话"
                :show-overflow-tooltip="true"
                min-width="120"
              >
                <template slot-scope="scope">
                  {{
                    comInfo.canViewContacts
                      ? scope.row.tel
                        ? scope.row.tel
                        : "-"
                      : "********"
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="邮箱"
                :show-overflow-tooltip="true"
                width="150"
              >
                <template slot-scope="scope">
                  {{
                    comInfo.canViewContacts
                      ? scope.row.email
                        ? scope.row.email
                        : "-"
                      : "********"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="微信或其他" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  {{
                    comInfo.canViewContacts
                      ? scope.row.wechatOrOther
                        ? scope.row.wechatOrOther
                        : "-"
                      : "********"
                  }}
                </template>
              </el-table-column>
              <el-table-column
                prop="lastContactTime"
                width="150"
                label="最近联系时间"
              >
                <template slot-scope="scope">
                  {{
                    scope.row.lastContactTime ? scope.row.lastContactTime : "-"
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                width="60"
                v-if="comInfo.canEditContacts"
              >
                <template slot-scope="scope">
                  <span class="tabHref" @click="editLinkMan(scope.row)"
                    >编辑</span
                  >
                </template>
              </el-table-column>
            </el-table>
          </el-row>
          <p class="drawerCon_from_tit">公司简介</p>
          <el-form label-width="100px" label-position="left">
            <el-row :gutter="24">
              <el-col :span="22">
                <el-form-item label="公司简介：">
                  <div class="canEdit">
                    {{ comInfo.description ? comInfo.description : "-" }}
                    <el-popover
                      placement="bottom"
                      title="编辑"
                      popper-class="popperEdit"
                      trigger="click"
                      @show="getComInfoById(comInfo.id)"
                    >
                      <i slot="reference" class="el-icon-edit tabHref"></i>
                      <div class="popcon">
                        <div class="popcon-input">
                          <el-input
                            size="mini"
                            maxlength="500"
                            style="width: 300px"
                            v-model="company.company.description"
                            type="textarea"
                          ></el-input>
                        </div>
                        <div class="subBtn">
                          <el-button
                            size="mini"
                            @click="cancel"
                            icon="el-icon-close"
                          ></el-button
                          ><el-button
                            type="primary"
                            size="mini"
                            icon="el-icon-check"
                            @click="checkVal('', 2, 'description')"
                          ></el-button>
                        </div>
                      </div>
                    </el-popover>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div class="info_con_r">
          <div class="btnList clearfix">
            <div class="block" @click="Edit">
              <i class="iconfont icon-kh_gsxq_jrwj"></i>
              <p class="demonstration">编辑</p>
            </div>
            <div class="block" @click="addMark('')">
              <i class="iconfont icon-kh_gsxq_bz"></i>
              <p class="demonstration">备注</p>
            </div>
            <div class="block" @click="fileManagement = true">
              <i class="iconfont icon-kh_gsxq_jrwj"></i>
              <p class="demonstration">加入文件夹</p>
            </div>
            <div class="block" @click="scheduleClick">
              <i class="iconfont icon-kh_gsxq_zdrc"></i>
              <p class="demonstration">新增日程</p>
            </div>
            <div class="block" @click="addContracts">
              <i class="iconfont icon-kh_gsxq_xzht"></i>
              <p class="demonstration">新增合同</p>
            </div>
            <div class="block" @click="addProByCus">
              <i class="iconfont icon-kh_gsxq_xzxm"></i>
              <p class="demonstration">新增项目</p>
            </div>
            <div class="block" @click="uploadAttach">
              <i class="iconfont icon-kh_gsxq_bscfj"></i>
              <p class="demonstration">上传附件</p>
            </div>
            <div class="block" @click="addLSC">
              <i class="iconfont icon-kh_gsxq_zjlxr"></i>
              <p class="demonstration">增加联系人</p>
            </div>
          </div>
        </div>
      </div>
      <div class="mark_con" v-if="activeName != '1'">
        <!-- 附件 -->
        <attach-ment
          v-if="activeName == '2'"
          :objectId="customId"
          @loadCount="loadCounts(customId)"
          :moduleType="'客户'"
        ></attach-ment>
        <div class="time-line" v-if="activeName == '3'">
          <!-- 备注 -->
          <mark-reacode
            v-if="activeName == '3'"
            :objectId="customId"
            :moduleType="'客户'"
            :key="rocodeKey"
          ></mark-reacode>
        </div>
        <!-- 项目 -->
        <project-by-cusId
          :cusId="customId"
          @loadCount="loadCounts(customId)"
          v-if="activeName == '4'"
        ></project-by-cusId>
        <!-- 合同 -->
        <contract-by-cusId
          :cusId="customId"
          @toCusInfo="activeName = '1'"
          @loadCount="loadCounts(customId)"
          v-if="activeName == '5'"
        ></contract-by-cusId>
        <!-- 日程 -->
        <div v-if="activeName == '6'">
          <el-button size="mini" @click="scheduleClick">新增日程</el-button>
          <div>
            <el-table
              :header-cell-style="{
                'background-color': '#f5f7fa',
              }"
              class="tabBorder"
              :data="scheduleDta"
              style="width: 100%"
            >
              <el-table-column
                prop="scheduleType"
                label="任务类型"
                width="100"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span
                    class="tag"
                    :class="{
                      tag03: scope.row.scheduleType == '客户拜访',
                      tag04: scope.row.scheduleType == '客户电话',
                      tag02: scope.row.scheduleType == '客户邮件',
                    }"
                    >{{ scope.row.scheduleType }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                prop="targetName"
                label="任务对象"
                width="300"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span class="tabHref" @click="Toggle()">{{
                    scope.row.targetName
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="关联人"
                prop="relatePersonName"
                width="200"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span>{{
                    scope.row.relatePersonName
                      ? scope.row.relatePersonName
                      : "-"
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column label="备注" show-overflow-tooltip>
                <template slot-scope="scope">
                  {{ scope.row.comment ? scope.row.comment : "-" }}
                </template>
              </el-table-column>
              <el-table-column label="任务时间" width="250" prop="scheduleTime">
              </el-table-column>
              <el-table-column
                label="创建人"
                width="260"
                show-overflow-tooltip
                prop="createUserName"
              >
              </el-table-column>
              <el-table-column label="参与者" width="260">
                <template slot-scope="scope">
                  {{ scope.row.joinUserName ? scope.row.joinUserName : "-" }}
                </template>
              </el-table-column>
              <el-table-column label="操作" width="80">
                <template slot-scope="scope">
                  <span class="tabHref" @click="editSchedule(scope.row.id)"
                    >编辑</span
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>

        <!-- 操作日志 -->
        <operate-list
          v-if="activeName == '7'"
          :objectId="customId"
          @toCusInfo="activeName = '1'"
        ></operate-list>
        <!-- 人才 -->
        <resume-table
          :cusId="customId"
          ref="resumeTable"
          v-if="activeName == '8'"
        ></resume-table>
      </div>
      <div
        v-if="activeName == '4'"
        class="mark_con"
        style="border-top: 1px #ececec solid; padding: 0"
      >
        <p
          style="
            height: 32px;
            line-height: 32px;
            padding-right: 15px;
            padding-bottom: 0px;
            padding-left: 15px;
            background-color: rgb(245, 245, 245);
            color: #606266;
            font-weight: 600;
            border-bottom: 1px #ececec solid;
          "
        >
          交付概览
        </p>
        <div style="padding: 0 10px">
          <el-table
            :header-cell-style="{
              'background-color': '#f5f7fa',
              height: '35px',
            }"
            :data="deliveryData"
            v-sticky="{ top: 0, parent: '.digScollDom' }"
            class="tabBorder"
            style="width: 100%"
            v-loading="deliveryLoading"
          >
            <el-table-column prop="period" label="周期" width="100">
            </el-table-column>
            <el-table-column label="新增项目量" min-width="120">
              <template slot-scope="scope">
                {{ scope.row.newProject ? scope.row.newProject : "-" }}
              </template>
            </el-table-column>
            <el-table-column label="加入项目量" min-width="120">
              <template slot-scope="scope">
                {{ scope.row.addProject ? scope.row.addProject : "-" }}
              </template>
            </el-table-column>
            <el-table-column label="简历推荐量" min-width="120">
              <template slot-scope="scope">
                {{
                  scope.row.resumeRecommend ? scope.row.resumeRecommend : "-"
                }}
              </template>
            </el-table-column>
            <el-table-column label="客户面试_初量" min-width="130">
              <template slot-scope="scope">
                {{ scope.row.interviewFirst ? scope.row.interviewFirst : "-" }}
              </template>
            </el-table-column>
            <el-table-column label="客户面试_终量" min-width="130">
              <template slot-scope="scope">
                {{ scope.row.interviewFinal ? scope.row.interviewFinal : "-" }}
              </template>
            </el-table-column>
            <el-table-column label="Offer量" width="110">
              <template slot-scope="scope">
                {{ scope.row.offer ? scope.row.offer : "-" }}
              </template>
            </el-table-column>
            <el-table-column label="入职量" width="110">
              <template slot-scope="scope">
                {{ scope.row.entry ? scope.row.entry : "-" }}
              </template>
            </el-table-column>
            <el-table-column label="成功量" width="110">
              <template slot-scope="scope">
                {{ scope.row.suc ? scope.row.suc : "-" }}
              </template>
            </el-table-column>
            <el-table-column label="开票量" width="110">
              <template slot-scope="scope">
                {{ scope.row.makeInvoice ? scope.row.makeInvoice : "-" }}
              </template>
            </el-table-column>
            <el-table-column label="回款量" width="110">
              <template slot-scope="scope">
                {{ scope.row.account ? scope.row.account : "-" }}
              </template>
            </el-table-column>
            <el-table-column label="offer总额" width="160">
              <template slot-scope="scope">
                {{ scope.row.offerSum ? scope.row.offerSum : "-" }}
              </template>
            </el-table-column>
            <el-table-column label="开票总额" width="160">
              <template slot-scope="scope">
                {{ scope.row.makeInvoiceSum ? scope.row.makeInvoiceSum : "-" }}
              </template>
            </el-table-column>
            <el-table-column label="回款总额" width="120">
              <template slot-scope="scope">
                {{ scope.row.accountSum ? scope.row.accountSum : "-" }}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <!-- 添加备注 -->
    <el-dialog
      title="备注"
      :visible.sync="dialogMarkVisible"
      width="600px"
      append-to-body
      :before-close="handleCloseResult"
    >
      <addMarkReacode
        v-if="dialogMarkVisible"
        :customId="customId"
        relativeType="客户"
        type="客户沟通"
        :linkManId="linkManId"
        @changeSaveLoading="changeSaveLoading"
        @loadRecodeList="loadRecodeList"
        ref="saveMark"
      ></addMarkReacode>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogMarkVisible = false" size="small"
          >取 消</el-button
        >
        <el-button
          type="primary"
          size="small"
          :loading="saveloading"
          @click="saveMark"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="查询结果"
      :visible.sync="dialogVisible"
      width="720px"
      append-to-body
      :before-close="handleCloseResult"
    >
      <ul class="comList">
        <li v-for="item in fuzzyItems" :key="item.id">
          <div class="comName">
            <p>{{ item.name }}</p>
          </div>
          <span>{{ item.base }}</span
          ><span class="tabHref fr" @click.stop="checkCom(item)">选择</span>
        </li>
        <li v-for="item in specialItems" :key="item">
          <div class="comName">
            <p>{{ item }}</p>
            <span class="specicon">特殊公司</span>
          </div>
          <span class="tabHref fr" @click.stop="checkCom(item)">选择</span>
        </li>
      </ul>
    </el-dialog>
    <el-drawer
      title="客户联系人"
      :visible.sync="dialogVisibleLSC"
      custom-class="drawerClass"
      direction="ttb"
      :modal="false"
      append-to-body
    >
      <div class="drawerCon">
        <add-lsc-info
          v-if="dialogVisibleLSC"
          @loadcusInfoById="loadcusInfoById(customId)"
          @drawer="dialogVisibleLSC = false"
          :customId="customId"
        ></add-lsc-info>
      </div>
    </el-drawer>
    <!-- 编辑联系人 -->
    <el-dialog
      :title="dialogTitleLinkMan"
      :visible.sync="dialogVisibleLinkMan"
      width="600px"
      append-to-body
      destroy-on-close
    >
      <el-form
        v-if="dialogTitleLinkMan"
        @submit.native.prevent
        :model="linkManFrom"
        ref="linkManFrom"
        :rules="rulesLink"
        style="padding: 0 50px"
        :validate-on-rule-change="false"
        label-width="100px"
        label-position="right"
      >
        <el-form-item label="姓名：" prop="name">
          <el-input
            placeholder="请输入内容"
            v-model.trim="linkManFrom.name"
            maxlength="20"
          ></el-input>
        </el-form-item>
        <el-form-item label="职位：" prop="position">
          <el-input
            placeholder="请输入内容"
            v-model.trim="linkManFrom.position"
            maxlength="20"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系电话：" prop="tel">
          <el-input
            placeholder="请输入内容"
            v-model.trim="linkManFrom.tel"
            maxlength="50"
          ></el-input>
        </el-form-item>
        <el-form-item label="邮箱：" prop="email">
          <el-input
            placeholder="请输入内容"
            v-model.trim="linkManFrom.email"
            maxlength="50"
          ></el-input>
        </el-form-item>
        <el-form-item label="微信或其他：" prop="wechatOrOther">
          <el-input
            placeholder="请输入内容"
            v-model.trim="linkManFrom.wechatOrOther"
            maxlength="50"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleLinkMan = false" size="small"
          >取 消</el-button
        >
        <el-button
          :loading="linkManFromLoading"
          type="primary"
          size="small"
          @click="submitF('linkManFrom')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 日程 -->
    <el-dialog
      :title="scheduleTitle"
      :visible.sync="scheduleDialog"
      width="600px"
      append-to-body
    >
      <schedule
        ref="schdeule"
        v-if="scheduleDialog"
        :targetId="customId"
        :moduleType="moduleType"
        :propScheduleData="propScheduleData"
      ></schedule>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="default"
          plain
          size="mini"
          @click="scheduleDialog = false"
          >取消</el-button
        >
        <el-button
          type="primary"
          :loading="saveloading"
          size="mini"
          @click="saveSchedule()"
          >保存</el-button
        >
      </span>
    </el-dialog>
    <!-- 上传附件 -->
    <upload-attach-ment
      v-if="VisibleAttach"
      :way="1"
      @loadReload="attachToloadInfo"
      @cancelAttath="VisibleAttach = false"
      :dialogVisibleAttach="VisibleAttach"
      :objectId="customId"
      :moduleType="'客户'"
    ></upload-attach-ment>
    <!-- 项目 -->
    <el-drawer
      :visible.sync="drawerVisible"
      custom-class="drawerClass"
      direction="ttb"
      :modal="false"
      append-to-body
      :before-close="handledraweClose"
    >
      <div slot="title" class="drawerTitle">{{ drawerTitle }}</div>
      <div class="drawerCon">
        <add-project
          v-if="drawerTitle == '新增项目'"
          :customerId="customId"
          :isCustomerInfo="true"
          @loadList="
            drawerVisible = false;
            drawerTitle = '';
          "
        ></add-project>
        <add-contract
          ref="saveInfo"
          :customId="customId"
          v-if="drawerTitle == '新增合同'"
          @changeSaveLoading="changeSaveLoading"
          @loadList="
            loadCounts(customId);
            drawerVisible = false;
            drawerTitle = '';
          "
          entry="客户详情"
        ></add-contract>
        <addoreEditCus
          v-if="drawerTitle == '编辑客户'"
          @loadList="
            getComInfoById(customId);
            drawerVisible = false;
            drawerTitle = '';
          "
          @tuComInfo="
            loadcusInfoById(customId);
            drawerVisible = false;
            drawerTitle = '';
          "
          :customId="customId"
          :canEditContacts="comInfo.canEditContacts"
        ></addoreEditCus>
        <div
          class="drawerCon_footer fixedFoot"
          v-if="drawerTitle == '新增合同'"
        >
          <el-button type="default" plain size="mini" @click="handledraweClose"
            >取消</el-button
          >
          <el-button
            type="primary"
            size="mini"
            style="margin-right"
            :loading="saveloading"
            @click="save()"
            >保存</el-button
          >
        </div>
      </div>
    </el-drawer>
    <!-- 编辑公司名称 -->
    <el-dialog
      :visible.sync="visibleCom"
      :modal-append-to-body="false"
      append-to-body
      title="编辑"
      :close-on-click-modal="false"
      width="600px"
    >
      <el-form
        @submit.native.prevent
        :model="company"
        ref="company"
        :validate-on-rule-change="false"
        label-width="100px"
      >
        <el-form-item prop="company.name" label="公司名称：">
          <el-input
            placeholder="请输入内容"
            v-model="company.company.name"
            maxlength="100"
            class="comCls"
          >
            <template slot="append">
              <el-icon
                class="el-icon-search"
                @click.native="getComInfo(company.company.name)"
              ></el-icon> </template
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visibleCom = false" size="small">取 消</el-button>
        <el-button
          type="primary"
          size="small"
          @click="checkVal('com', 2, 'name')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 文件夹 -->
    <el-dialog
      :visible.sync="fileManagement"
      :modal-append-to-body="false"
      append-to-body
      title="文件夹管理"
      @close="updateFavoriteList"
      :close-on-click-modal="false"
      width="720px"
    >
      <file
        v-if="fileManagement"
        @getFavoriteData="childByValue"
        @length="getLength"
        :type="'客户'"
        :checkbox="isCheckbox"
      ></file>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="fileManagement = false">关闭</el-button>
        <el-button
          size="mini"
          :loading="saveloading"
          @click="saveFavorite"
          :disabled="favoriteList.favoriteIds.length == 0"
          type="primary"
          >保存</el-button
        >
      </span>
    </el-dialog>
  </section>
</template>

<script>
import {
  GetCompanyById,
  editSchedule,
  getRelatedCount,
  GetBusinessInfo,
  addOrEditCustomer,
  GetFuzzyQuery,
  GetCustomerDetailById,
  GetsysCompanyOption,
  getCommonColumnList,
  getEnableUserBaseInfos,
  scheduleList,
  comContactsAddOrEdit, //新增或编辑联系人
  addToFavorite,
  cityTree,
  customerCoopOptions,
  GetCompanyContactCommentrecordList,
  treeList,
  companyDeliveryOverview, //交付概览
} from "../../api/api";
import {
  statisticsContractAdd, //点击新增合同
  statisticsClickProjectAdd, //记录点击新增项目
} from "../../api/buryingPoint";
import schedule from "../schedule/schedule";
import * as data from "../../common/json/commData.json";
import attachMent from "../attachMent";
import uploadAttachMent from "../uploadAttachMent";
import operateList from "./operateList"; //操作日志
import projectByCusId from "./project"; //项目
import contractByCusId from "./contract"; //合同
import resumeTable from "./resume"; //人才
import addContract from "../contract/addContract";
import addProject from "./../project/addorEdit";
import addMarkReacode from "../remark/remark"; //备注
import markReacode from "../remark/markRecode"; //备注List
import addoreEditCus from "./addorEditCustomer";
import AddLscInfo from "./addLSCInfo";
import file from "../file/file";
export default {
  name: "customerInfo",
  props: ["customId", "activeToInfo"],
  components: {
    file,
    schedule,
    attachMent,
    uploadAttachMent,
    operateList,
    projectByCusId,
    addProject,
    addMarkReacode,
    markReacode,
    contractByCusId,
    addContract,
    addoreEditCus,
    AddLscInfo,
    resumeTable,
  },
  data() {
    return {
      dialogVisibleLSC: false,
      markVisible: false,
      linkManId: null,
      favoriteList: {
        favoriteIds: [],
        itemIds: [],
      },
      isCheckbox: {
        val: true,
        edit: true,
      },
      fileManagement: false,
      saveloading: false,
      dialogMarkVisible: false,
      drawerVisible: false,
      drawerTitle: "",
      VisibleAttach: false,
      //修改公司名
      canSaveCom: false,
      visibleCom: false,
      //修改公司名
      success: true,
      moduleType: { type: "客户" },
      propScheduleData: {},
      linkManFrom: {
        companyId: 0,
        email: "",
        id: 0,
        name: "",
        position: "",
        tel: "",
        version: 0,
        wechatOrOther: "",
      },
      rulesLink: {
        name: [
          {
            required: true,
            trigger: "blur",
            message: "请输入姓名",
          },
        ],
        position: [
          {
            required: true,
            trigger: "blur",
            message: "请输入职位名称",
          },
        ],
      },

      //客户详情
      activeName: "1",
      comInfo: "",
      Count: "",
      ColumnInList: [],
      cityList: [],
      userList: [],
      fuzzyItems: [],
      AllCompany: [],
      specialItems: [],
      scheduleDta: [],
      dialogVisible: false,
      scheduleListType: {
        id: 0,
        type: "客户",
      },
      scheduleTitle: "新增日程",
      scheduleDialog: false,
      dialogVisibleLinkMan: false,
      dialogTitleLinkMan: "",
      props: {
        value: "id",
        label: "name",
        children: "children",
        multiple: true,
      },
      customerType: data.customerType,
      company: {
        allCityIds: [],
        changeStatus: "",
        childrenCompany: [],
        clueConsultantInfo: {
          canEdit: true,
          name: "",
          relativeId: 0,
        },
        company: {
          address: "",
          cityId: null,
          cityIds: "",
          cityParentId: null,
          clueConsultantId: null,
          commonCompanyName: "",
          companyTrackerId: null,
          creditCode: "",
          description: "",
          failReason: "",
          id: null,
          industryId: null,
          industryIds: "",
          industryParentId: null,
          leadingConsultantId: null,
          name: "",
          otherName: "",
          parentId: null,
          parentChildRelationType: "无",
          signDate: "",
          status: "开发中",
          natureType:'',
          tags: "",
          tel: "",
          version: null,
          website: "",
        },
        companyIndustryIds: [],
        companyIndustryList: [],
        companyTrackerName: "",
        contactsList: [
          {
            companyId: null,
            email: "",
            id: null,
            name: "",
            position: "",
            tel: "",
            version: null,
            wechatOrOther: "",
          },
        ],
        contractSigned: true,
        hasResetPermission: true,
        leadingConsultantInfo: {
          canEdit: true,
          name: "",
          relativeId: null,
        },
      },
      labelList: [],
      linkManFromLoading: false,
      remarkDatas: [],
      options2: [],
      deliveryData: [],
      deliveryLoading: false,
      rocodeKey:0,
      natureTypeList:[
      {name:'央企',value:'CENTRAL_ENTERPRISES'},  
      {name:'国企',value:'NATIONAL_ENTERPRISES'},  
      {name:'外资-欧美',value:'EUROPE_AND_UNITED_STATES_FUNDED_ENTERPRISES'},  
      {name:'外资-非欧美',value:'NO_EUROPE_AND_UNITED_STATES_FUNDED_ENTERPRISES'},  
      {name:'民营',value:'PRIVATE_ENTERPRISES'},  
      {name:'政府/事业单位',value:'GOVERNMENT'},  
      ]
    };
  },
  watch: {
    customId(newValue, oldValue) {
      let that = this;
      that.loadcusInfoById(newValue);
    },
  },
  methods: {
    updateFavoriteList() {
      //更新文件夹list
      this.$emit("loadList", "flie");
    },
    addToFavorite() {
      if (this.multipleSelection && this.multipleSelection.length > 0) {
        this.fileManagement = true;
        this.isCheckbox.edit = true;
      } else {
        this.$message.error("请先选择公司");
      }
    },
    saveFavorite() {
      this.saveloading = true;
      this.favoriteList.itemIds = [];
      this.favoriteList.itemIds.push(this.customId);
      addToFavorite(this.favoriteList).then((res) => {
        if (res.success) {
          this.fileManagement = false;
          this.$message.success("操作成功");
          this.saveloading = false;
        }
      });
    },
    childByValue(childValue) {
      this.favoriteList.favoriteIds = childValue;
    },
    getLength(val) {
      if (val) {
        this.isShowSaveBtn = true;
      } else {
        this.isShowSaveBtn = false;
      }
    },
    addMark(val) {
      let _this = this;
      //新增备注
      if (val) {
        this.linkManId = val;
        if (val.id) {
          this.setStore("RecordItem", val);
          setTimeout(function () {
            _this.$refs.saveMark.loadEditInfo();
          }, 100);
        }
      } else {
        this.linkManId = null;
      }
      this.dialogMarkVisible = true;
    },
    saveMark() {
      //保存
      this.$refs.saveMark.saveMarkRecode();
    },
    loadRecodeList() {
      //加载备注记录List
      this.activeName = "3";
      this.dialogMarkVisible = false;
      this.rocodeKey++
    },
    changeSaveLoading(val) {
      this.saveloading = val;
    },
    addProByCus() {
      statisticsClickProjectAdd({
        entry: "客户详情-新增项目",
      }).then();
      this.drawerVisible = true;
      this.drawerTitle = "新增项目";
    },
    addContracts() {
      statisticsContractAdd({ entry: "客户详情" }).then();
      this.drawerVisible = true;
      this.drawerTitle = "新增合同";
    },
    //保存合同
    save() {
      this.$refs.saveInfo.save("contract");
    },
    handledraweClose() {
      this.drawerVisible = false;
      this.drawerTitle = "";
    },
    uploadAttach() {
      //上传附件
      this.VisibleAttach = true;
    },
    attachToloadInfo(id) {
      this.loadcusInfoById(id);
      this.VisibleAttach = false;
      this.activeName = "2";
    },
    editSchedule(id) {
      this.scheduleTitle = "编辑日程";
      this.scheduleDialog = true;
      editSchedule(id).then((res) => {
        this.propScheduleData = res.result;
      });
    },
    saveSchedule() {
      this.saveloading = true;
      this.$refs.schdeule
        .submitForm("ruleForm")
        .then(() => {
          this.scheduleDialog = false;
          this.scheduleList();
          this.loadCounts(this.customId);
          this.saveloading = false;
        })
        .catch((error) => {
          this.saveloading = false;
        });
    },
    submitF(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.linkManFromLoading = true;
          if (
            !this.linkManFrom.tel &&
            !this.linkManFrom.email &&
            !this.linkManFrom.wechatOrOther
          ) {
            this.$message.error("联系电话/邮箱/微信或其他至少填写1项");
            this.linkManFromLoading = false;
            return false;
          }
          comContactsAddOrEdit(this.linkManFrom).then((res) => {
            if (res.success) {
              if (res.result) {
                if (res.result.repeat) {
                  let h = this.$createElement;
                  this.$msgbox({
                    title: "提示",
                    message: h(
                      "div",
                      {
                        style: "text-align:center;",
                      },
                      [
                        h(
                          "strong",
                          {
                            style:
                              "font-size:16px;font-weight: 800;margin-top: 10px;",
                          },
                          "编辑成功"
                        ),
                        h(
                          "p",
                          {
                            style: "margin: 30px 0px;color:#e60012",
                          },
                          [
                            "客户联系人无法正常保存到人才库（人才重复），请及时到",
                            h(
                              "span",
                              {
                                class: "tabHref",
                                on: {
                                  click: () => {
                                    this.goResunme();
                                  },
                                },
                              },
                              "【待处理人才】"
                            ),
                            "处理",
                          ]
                        ),
                      ]
                    ),
                    cancelButtonText: "关闭",
                    showConfirmButton: false,
                    dangerouslyUseHTMLString: true,
                    customClass: "msgClass1",
                    beforeClose: "closeConf",
                  })
                    .then(() => {})
                    .catch(() => {
                      this.dialogVisibleLinkMan = false;
                      this.loadcusInfoById(this.customId);
                    });
                } else {
                  let div = this.customId
                    ? "<p style='font-weight:bold;text-align:center'>编辑成功</p>" +
                      "<div style='text-align:center'>联系人信息已同步至人才库</div>"
                    : "<p style='font-weight:bold;text-align:center'>新增成功</p>" +
                      "<div style='text-align:center'>联系人已同步至人才库</div>";
                  this.$confirm(div, "提示", {
                    showConfirmButton: false,
                    cancelButtonText: "关闭",
                    customClass: "altCls",
                    dangerouslyUseHTMLString: true,
                  })
                    .then(() => {})
                    .catch(() => {
                      this.dialogVisibleLinkMan = false;
                      this.loadcusInfoById(this.customId);
                    });
                }
              } else {
                this.$message.success("操作成功");
                this.dialogVisibleLinkMan = false;
                this.loadcusInfoById(this.customId);
              }
              this.linkManFromLoading = false;
            }
          });
        } else {
          return false;
        }
      });
    },
    goResunme() {
      this.dialogVisibleLinkMan = false;
      this.loadcusInfoById(this.customId);
      this.$msgbox.close();
      let newUrl = this.$router.resolve({
        path: "/resume/pending",
      });
      window.open(newUrl.href, "_blank");
    },
    scheduleClick() {
      this.scheduleDialog = true;
      this.scheduleTitle = "新增日程";
    },
    editLinkMan(row) {
      this.linkManFrom = JSON.parse(JSON.stringify(row));
      this.dialogTitleLinkMan = "编辑联系人";
      this.dialogVisibleLinkMan = true;
    },
    loadcusInfoById(id) {
      GetCompanyById(id).then((res) => {
        if (res.success) {
          this.comInfo = res.result;
        }
      });
      this.loadCounts(id);
    },
    loadCounts(id) {
      getRelatedCount(id).then((res) => {
        if (res.success) {
          this.Count = res.result;
        }
      });
    },
    loadLabelBase() {
      //获取用户列表
      customerCoopOptions().then((res) => {
        if (res.success) {
          this.labelList = res.result;
        }
      });
    },
    handleCloseResult() {
      this.dialogVisible = false;
      this.canSaveCom = false;
      this.dialogMarkVisible = false;
    },
    Edit() {
      this.drawerVisible = true;
      this.drawerTitle = "编辑客户";
      // this.$emit("toEdit", this.customId);
    },
    loadAllCompany() {
      //获取所有母公司
      GetsysCompanyOption().then((res) => {
        if (res.success) {
          this.AllCompany = res.result;
        }
      });
    },
    loadUserBase() {
      //获取用户列表
      getEnableUserBaseInfos().then((res) => {
        if (res.success) {
          this.userList = res.result;
        }
      });
    },
    LoadColumnList() {
      //加载自定义列表
      this.ColumnInList = [];
      this.cityList = [];
      getCommonColumnList({
        type: "行业",
      }).then((res) => {
        if (res.success) {
          this.ColumnInList = res.result;
        }
      });
      cityTree().then((res) => {
        if (res.success) {
          this.cityList = res.result;
        }
      });
    },
    getComInfoById(id) {
      //获取编辑详情
      this.customId = id;
      GetCustomerDetailById(id).then((res) => {
        this.formLoading = false;
        if (res.success) {
          this.company = res.result;
          this.company.allCityIds = eval(res.result.company.cityIds);
          this.creditCode = res.result.company.creditCode;
          this.$set(this.company, "companyIndustryIds", []);
          if (
            res.result.companyIndustryList &&
            res.result.companyIndustryList.length > 0
          ) {
            res.result.companyIndustryList.forEach((item, index) => {
              if (item.industryIds) {
                this.company.companyIndustryIds[index] = eval(item.industryIds);
              }
            });
          }
        }
      });
    },
    cancel() {
      document.body.click();
    },
    showComEdit(id) {
      this.visibleCom = true;
      this.getComInfoById(id);
    },
    checkCom(item) {
      if (typeof item == "object") {
        GetBusinessInfo({
          comId: this.customId,
          companyType: item.companyType,
          id: item.id,
          name: item.name,
        }).then((res) => {
          if (res.success) {
            if (res.result.error_code == 0) {
              this.company.company.address = res.result.registeredAddress;
              this.company.company.creditCode = res.result.creditCode;
              this.company.company.name = item.name;
              this.canSaveCom = true;
              this.dialogVisible = false;
            } else if (res.result.error_code == 2) {
              this.$confirm("该公司已创建，请选择是否查看?", "提示", {
                confirmButtonText: "查看",
                cancelButtonText: "取消",
                customClass: "altCls",
                type: "warning",
              })
                .then(() => {
                  this.dialogVisible = false;
                  this.loadcusInfoById(res.result.comId);
                })
                .catch(() => {
                  this.$message({
                    type: "info",
                    message: "已取消",
                  });
                });
            } else {
              this.$alert(res.result.reason, "提示", {
                confirmButtonText: "确定",
                callback: (action) => {},
              });
            }
          }
        });
      } else {
        this.company.name = item;
        this.canSaveCom = true;
        this.dialogVisible = false;
      }
    },
    getComInfo(val) {
      //校验公司名
      if (val) {
        GetFuzzyQuery({ keyword: val }).then((res) => {
          if (res.success) {
            this.specialItems = res.result.specialItems;
            this.fuzzyItems = res.result.fuzzyItems;
            this.dialogVisible = true;
          }
        });
      }
    },
    checkVal(name, type, formName) {
      console.log(name, type, formName);
      if (name == "com") {
        if (this.canSaveCom) {
          //已获取天眼查数据
          if (this.company.company[formName]) {
            //修改内容
            addOrEditCustomer(this.company).then((res) => {
              if (res.success) {
                this.$message.success("操作成功");
                this.canSaveCom = false;
                this.visibleCom = false;
                this.loadcusInfoById(this.customId);
                this.$emit("loadList");
              }
            });
          } else {
            this.$message.error("内容不能为空");
          }
        } else {
          //未获取
          this.getComInfo(this.company.company.name);
        }
      } else {
        if (
          (type == 1 && this.company[formName]) ||
          (type == 2 && this.company.company[formName])
        ) {
          //修改内容
          addOrEditCustomer(this.company).then((res) => {
            if (res.success) {
              this.$message.success("操作成功");
              document.body.click();
              this.canSaveCom = false;
              this.visibleCom = false;
              this.loadcusInfoById(this.customId);
              this.$emit("loadList");
            }
          });
        } else {
          this.$message.error("内容不能为空");
        }
      }
    },
    scheduleList() {
      this.scheduleListType.id = this.customId;
      scheduleList(this.scheduleListType).then((res) => {
        this.scheduleDta = res.result;
      });
    },
    handleClick(tab) {
      this.activeName = tab.name;
      switch (tab.name) {
        case "1":
          this.loadcusInfoById(this.customId);
          break;
        case "4":
          this.getDeliveryOverview();
          break;
        case "6":
          this.scheduleList();
          break;
      }
    },
    //日程客户任务对象点击
    Toggle() {
      this.activeName = "1";
      this.loadcusInfoById(this.customId);
    },
    showMark(row, type) {
      let _this = this;
      _this.time = false;
      _this.remarkDatas = [];
      this.timer = setTimeout(function () {
        _this.loadRecodeList1(row, type);
      }, 1000);
    },
    // //加载备注记录List
    loadRecodeList1(row, type) {
      this.dialogVisible = false;
      this.remarkDatas = [];
      GetCompanyContactCommentrecordList(row.id).then((res) => {
        if (res.success) {
          this.remarkDatas = [];
          this.remarkDatas = res.result.map((result) => ({
            ...result,
            commentColumns: (result.commentColumns || []).filter(
              (d) => d.value
            ),
          }));
        }
      });
      // getCommonRecordList({
      //   relativeId: row.resumeId,
      //   type: type,
      // }).then((res) => {
      //   if (res.success) {
      //     this.remarkDatas = res.result;
      //   }
      // });
    },
    clearSelect() {
      if (this.timer) {
        clearInterval(this.timer);
      }
    },
    // 获取参与顾问
    permissionTree() {
      treeList().then((res) => {
        if (res.success) {
          this.options2 = res.result;
          // this.changeDetSelect(this.options2)
        }
      });
    },
    //参与顾问
    getSearch(val) {
      // const _nodeId = this.$refs.dataRef.getCheckedNodes(true);
      // this.company.participantIds = this.participantIds.map((i) => {
      //   return i[i.length - 1];
      // });
      this.company.participantIds = val;
    },
    filterFun(list, value) {
      // 弹窗 - 拼音过滤
      return list.data.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
    },
    addLSC() {
      if (this.comInfo.canEditContacts) {
        this.dialogVisibleLSC = true;
      } else {
        let div = "<div>您不是参与者，无法增加联系人</div>";
        this.$confirm(div, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          customClass: "altCls",
          dangerouslyUseHTMLString: true,
        })
          .then(() => {
            // //跳简历编辑
            // this.drawerdialogVisible = true;
          })
          .catch(() => {});
      }
    },
    //交付概览
    getDeliveryOverview() {
      this.deliveryLoading = true;
      companyDeliveryOverview({
        companyId: this.customId,
      }).then((res) => {
        if (res.success) {
          // console.log(res);
          this.deliveryData = res.result;
        }
        this.deliveryLoading = false;
      });
    },
  },
  created() {
    this.loadcusInfoById(this.customId);
    this.loadAllCompany();
    this.LoadColumnList();
    this.loadUserBase(); //获取用户
    this.loadLabelBase(); //获取标签列表
    this.permissionTree(); //获取参与顾问
    if (this.activeToInfo) {
      setTimeout(() => {
        this.activeName = this.activeToInfo;
        if (this.activeName == "4") {
          this.getDeliveryOverview();
        }
      }, 500);
    }
  },
};
</script>
<style lang="scss" scoped>
.drawerCon_head {
  p {
    margin: 0;
  }
  .name {
    font-size: 16px;
    font-weight: bold;
    color: #333;
    line-height: 21px;
    .comStatus {
      font-weight: normal;
      padding: 0 5px;
      height: 20px;
      line-height: 20px;
      border-radius: 2px;
      background-color: #fde9e9;
      color: #f05656;
      display: inline-block;
      margin-right: 5px;
      font-size: 12px;
      vertical-align: initial;
    }
    .icon-kh_wh {
      color: #898989;
      font-weight: normal;
      vertical-align: middle;
      &:hover {
        color: #f05656;
      }
    }
  }
  .subInfo {
    font-size: 13px;
    font-weight: normal;
    color: #999;
    float: right;
    i {
      color: #606266;
      font-style: normal;
    }
  }
}

.drawerCon_con {
  border: 1px solid #e5e5e5;
  margin-top: 15px;
  :deep(.el-tabs__nav-wrap) {
    padding: 0 15px;
  }
  :deep(.el-tabs__nav-wrap::after) {
    height: 1px;
  }
  .info_con {
    display: flex;
    justify-content: space-between;
    padding: 0 10px 10px;
    .drawerCon_con_tabs {
      flex: 1;
      margin-right: 10px;
      border: 1px solid #e5e5e5;
      :deep(.el-form) {
        padding: 0 40px 0 20px;
      }
      :deep(.el-form-item__label) {
        line-height: 20px !important;
        font-size: 13px;
      }
      :deep(.el-form-item__content) {
        line-height: 20px !important;
        color: #333;
        font-size: 13px;
      }
      .drawerCon_from_tit {
        height: 35px;
        line-height: 35px;
        font-size: 14px;
        color: #333;
        text-align: left;
        font-weight: bold;
        padding: 0 15px;
        margin: 0 0 20px;
        background-color: #f5f5f5;
        border-bottom: 1px solid #e5e5e5;
      }
      .chCom {
        border: 1px solid #898989;
        font-size: 12px;
        padding: 3px 5px;
        border-radius: 2px;
        color: #898989;
      }
      .innertab {
        margin: 20px 10px;
      }
      .el-form-item__content:hover {
        .el-icon-edit {
          display: inline-block;
        }
      }
    }
    .info_con_r {
      width: 240px;
      border: 1px solid #e5e5e5;
      .btnList {
        font-size: 12px;
        color: #606266;
        margin-top: 20px;
        .block {
          width: 50%;
          float: left;
          margin: 0 0 25px;
          text-align: center;
          cursor: pointer;
          &:hover {
            color: #e60012;
            i {
              color: #e60012;
            }
          }
          i {
            font-size: 24px;
            color: #898989;
          }
          p {
            margin: 5px 0 0;
          }
        }
      }
    }
  }
  .mark_con {
    padding: 0 10px 10px;
  }
  .mark_con {
    .time-line {
      position: relative;
      padding: 0 15px;
    }
  }
}
</style>
<style lang="scss" scoped>
.time-line-div {
  position: relative;
  min-height: 85px;
  margin-top: 15px;
  &:nth-child(1) {
    margin-top: 0 !important;
  }
  .time-line_l {
    position: absolute;
    left: 0;
    width: 135px;
    text-align: right;
    p {
      margin: 0;
      line-height: 1;
    }
    .time {
      padding-top: 24px;
      font-size: 12px;
      color: #999;
    }
    .name {
      font-size: 13px;
      margin-top: 10px;
      color: #606266;
    }
  }
  .line {
    position: absolute;
    left: 155px;
    width: 12px;
    height: 12px;
    top: 20px;
    background: #e4e7ed;
    border-radius: 50%;
    z-index: 10;
  }
  .time-line_r {
    position: relative;
    left: 191px;
    width: calc(100% - 180px);
  }
  .time-line_con {
    border: 1px solid #e5e5e5;
    .r_title {
      height: 40px;
      line-height: 40px;
      background-color: #f5f5f5;
      margin: 0;
      padding-left: 10px;
      color: #606266;
      border-bottom: 1px solid #e5e5e5;
    }
    .r_con {
      padding: 15px 20px;
      font-size: 13px;
      line-height: 21px;
      .tit {
        font-size: 13px;
        color: #606266;
        // margin-top: 8px;
      }
      .con {
        color: #333;
        font-style: 13px;
        line-height: 20px;
      }
    }
  }
}
.dott {
  position: absolute;
  width: 5px;
  left: 173px;
  height: calc(100% - 45px);
  top: 15px;
  bottom: 5px;
  z-index: 1;
  background-color: #e5e5e5;
}
</style>