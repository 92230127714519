<template>
  <section style="background-color: #fff">
    <div class="drawerCon_from">
      <el-form
        @submit.native.prevent
        :model="formdata"
        ref="formdata"
        :rules="rules"
        :validate-on-rule-change="false"
        v-loading="formLoading"
        label-width="120px"
        label-position="right"
        class="addOrEditPro"
      >
        <p class="drawerCon_from_tit">基本信息</p>
        <el-row :gutter="24">
          <el-col :offset="1" :span="10">
            <el-form-item label="职位：" prop="position">
              <el-input
                placeholder="请输入职位名称"
                maxlength="50"
                v-model.trim="formdata.position"
                id="addProjectPosition"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :offset="1" :span="10">
            <el-form-item label="客户公司：" prop="companyId">
              <el-select
                style="width: 100%"
                clearable
                filterable
                v-model="formdata.companyId"
                @change="loadContactsLitst(formdata.companyId, 'sign')"
                placeholder="请选择"
                id="addProjectCompanyId"
              >
                <el-option
                  v-for="(i, index) in companyList"
                  :key="i.id"
                  :label="i.name"
                  :value="i.id"
                  :id="'addProjectCompanyList' + index"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :offset="1" :span="10">
            <el-form-item label="需求数量：" prop="hireCount">
              <el-input
                placeholder="请输入数量"
                type="number"
                class="numberHeight"
                v-model.number="formdata.hireCount"
                id="addProjectHireCount"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :offset="1" :span="10">
            <el-form-item
              label="客户联系人："
              class="fromInput"
              prop="companyContactIds"
            >
              <el-select
                style="width: 100%"
                filterable
                collapse-tags
                multiple
                v-model="formdata.companyContactIds"
                placeholder="请选择"
                id="addProjectCompanyContactIds"
              >
                <el-option
                  v-for="(i, index) in contactsList"
                  :key="i.id"
                  :label="i.name"
                  :value="i.id"
                  :id="'addProjectCompanyContactList' + index"
                >
                </el-option>
              </el-select>
              <i
                class="iconfont icon-xz_bd addContact"
                @click="addContact"
                id="addProjectCompanyAddContact"
              ></i>
            </el-form-item>
          </el-col>
          <el-col :offset="1" :span="10">
            <el-form-item label="职能：" class="fromInput" prop="funIds">
              <el-cascader
                filterable
                clearable
                collapse-tags
                :show-all-levels="false"
                v-model="formdata.funIds"
                :options="posList"
                style="width: 100%"
                :props="{
                  value: 'id',
                  label: 'name',
                  children: 'children',
                  multiple: true,
                }"
                placeholder="请选择"
                id="addProjectFunIds"
              ></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :offset="1" :span="10">
            <el-form-item label="月薪：">
              <el-row>
                <el-col :span="12">
                  <el-form-item label-width="0" prop="monthlySalary">
                    <el-input
                      type="number"
                      class="numberHeight"
                      v-model.number="formdata.monthlySalary"
                      id="addProjectMonthlySalary"
                    >
                      <span slot="suffix">元/月</span>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="2" style="text-align: center; color: #6e6f73"
                  >X</el-col
                >
                <el-col :span="9">
                  <el-form-item label-width="0" prop="monthCount">
                    <el-input
                      type="number"
                      class="numberHeight"
                      v-model.number="formdata.monthCount"
                      id="addProjectMonthCount"
                      ><span slot="suffix">个月</span></el-input
                    >
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
          <el-col :offset="1" :span="10">
            <el-form-item label="状态：" prop="status">
              <el-select
                placeholder="请选择"
                filterable
                v-model="formdata.status"
                :style="
                  formdata.status == '失败' ? 'width: 50%' : 'width: 100%'
                "
                id="addProjectStatus"
              >
                <el-option
                  v-for="(item, index) in projectType"
                  :key="'a_' + index"
                  :value="item.value"
                  :label="item.value"
                  :id="'addProjectStatusList' + index"
                >
                </el-option>
              </el-select>
              <el-select
                v-if="formdata.status == '失败'"
                filterable
                v-model="formdata.failRemark"
                placeholder="请选择失败原因"
                style="width: 50%"
                id="addProjectFailRemark"
              >
                <el-option
                  v-for="(item, index) in projectFailType"
                  :key="'c_F_' + index"
                  :value="item.val"
                  :label="item.name"
                  :id="'addProjectFailRemarkList' + index"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :offset="1" :span="10">
            <el-form-item label="城市：" prop="cityIds">
              <el-cascader
                style="width: 100%"
                class="fromInput"
                v-model="formdata.cityIds"
                collapse-tags
                filterable
                :options="cityList"
                :props="{
                  value: 'id',
                  label: 'name',
                  children: 'children',
                  multiple: true,
                  checkStrictly: true,
                }"
                id="addProjectCityIds"
                placeholder="请选择"
              ></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :offset="1" :span="10">
            <el-form-item label="开始时间：" prop="startDate">
              <el-date-picker
                v-model="formdata.startDate"
                value-format="yyyy-MM-dd"
                type="date"
                :clearable="false"
                style="width: 100%"
                placeholder="请选择日期"
                id="addProjectStartDate"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :offset="1" :span="10">
            <el-form-item label="结束时间：" prop="dueDate"
              ><el-date-picker
                v-model="formdata.dueDate"
                value-format="yyyy-MM-dd"
                :picker-options="endOptions"
                :clearable="false"
                type="date"
                style="width: 100%"
                placeholder="请选择日期"
                id="addProjectDueDate"
              >
              </el-date-picker
            ></el-form-item>
          </el-col>
          <el-col :offset="1" :span="10">
            <el-form-item label="合同：" prop="contractId">
              <el-select
                placeholder="请选择"
                filterable
                v-model="formdata.contractId"
                style="width: 100%"
                no-data-text="该客户下无合同"
                id="addProjectContractId"
              >
                <el-option
                  v-for="(item, index) in contractList"
                  :key="item.id"
                  :value="item.id"
                  :label="item.name"
                  :disabled="!item.canSelected"
                  :id="'addProjectContractList' + index"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :offset="1" :span="10">
            <el-form-item label="参与顾问：" prop="permissionUserIds">
              <el-select
                style="width: 100%"
                v-model="formdata.permissionUserIds"
                clearable
                filterable
                class="fromInput"
                collapse-tags
                multiple
                ref="permissionRef"
                placeholder="请选择"
                id="addProjectPermissionUserIds"
              >
                <div
                  style="
                    overflow: hidden;
                    background: #eee;
                    padding: 10px 5px;
                    margin-bottom: 5px;
                  "
                >
                  <el-button
                    size="mini"
                    @click="formdata.permissionUserIds = allChecked"
                    id="addProjectPermissionUserIdsAllChecked"
                    >全选</el-button
                  >
                  <el-button
                    size="mini"
                    @click="formdata.permissionUserIds = []"
                    id="addProjectPermissionUserIdsAllUnChecked"
                    >全不选</el-button
                  >
                  <el-button
                    size="mini"
                    style="float: right"
                    @click="$refs['permissionRef'].blur()"
                    id="addProjectPermissionUserIdsCheck"
                    >确认</el-button
                  >
                </div>
                <el-option
                  v-for="(i, index) in userList"
                  :key="i.id"
                  :label="i.name"
                  :value="i.id"
                  :id="'addProjectPermissionUserList' + index"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :offset="1" :span="10">
            <el-form-item label="项目BD：" prop="newprojectManagerId">
              <el-select
                style="width: 100%"
                clearable
                filterable
                v-model="formdata.newprojectManagerId"
                placeholder="请选择"
                id="addProjectNewprojectManagerId"
              >
                <el-option
                  v-for="(i, index) in userList"
                  :key="i.id"
                  :label="i.name"
                  :value="i.id"
                  :id="'addProjectNewprojectManagerList' + index"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :offset="1" :span="10">
            <el-form-item label="项目经理：" prop="managerId">
              <el-select
                style="width: 100%"
                clearable
                filterable
                v-model="formdata.managerId"
                placeholder="请选择"
                id="addProjectManagerId"
              >
                <el-option
                  v-for="(i, index) in userList"
                  :key="i.id"
                  :label="i.name"
                  :value="i.id"
                  :id="'addProjectManagerList' + index"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :offset="1" :span="21">
            <el-form-item label="职位别名：" class="is-required">
              <div class="inputContent">
                <span
                  v-for="(x, xindex) in positionAlias"
                  :key="xindex"
                  :title="x"
                  style="
                    border: 1px #e9e9eb solid;
                    margin: 2px 3px;
                    position: relative;
                  "
                  ><span class="inputBorder">{{ x }}</span
                  ><i
                    class="el-tag__close el-icon-close"
                    style="position: absolute; right: 8px; top: 8px"
                    @click="delAliasItem(xindex)"
                    :id="'addProjectPositionAliadel' + xindex"
                  ></i
                ></span>
                <el-input
                  placeholder="多个时用空格隔开"
                  maxlength="50"
                  v-model="formdata.positionAlia"
                  @input="getAliasInpData"
                  @blur="handleTargetAliasBlur"
                  id="addProjectPositionAlia"
                ></el-input>
              </div>
              <el-tooltip
                content=" 指与该项目匹配的人选的岗位名称。（比如，职位：ATE，可能的职位别名：ATE工程师、ATE测试工程师等）"
                placement="bottom"
                effect="dark"
              >
                <i class="iconfont icon-wenhao-xiangsu addContact"></i>
              </el-tooltip>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :offset="1" :span="21">
            <el-form-item label="职位描述：" prop="jd">
              <el-input
                placeholder="请输入"
                type="textarea"
                v-model="formdata.jd"
                maxlength="500"
                id="addProjectJd"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :offset="1" :span="21">
            <el-form-item label="备注：" prop="comment">
              <el-input
                placeholder="可填写客户偏好、交付计划、团队协同注意事项等信息"
                type="textarea"
                v-model="formdata.comment"
                maxlength="500"
                id="addProjectComment"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <p class="drawerCon_from_tit">
          目标人才<span style="color: #999; font-size: 12px"
            >（完善以下信息将会更精准的为项目推荐人才）</span
          >
        </p>
        <el-row :gutter="24">
          <el-col :offset="1" :span="10">
            <el-form-item
              label="人才行业："
              prop="preferenceCandidateIndustryIds"
              :rules="
                formdata.notLimitIndustry
                  ? {
                      required: false,
                      trigger: 'change',
                      message: '请选择人才行业',
                    }
                  : {
                      required: true,
                      trigger: 'change',
                      message: '请选择人才行业',
                    }
              "
            >
              <el-cascader
                :key="isResouceShow"
                style="width: 100%; height: 34px; min-width: 180px"
                clearable
                size="mini"
                :disabled="formdata.notLimitIndustry"
                filterable
                :show-all-levels="false"
                :options="ColumnInList"
                collapse-tags
                v-model="formdata.preferenceCandidateIndustryIds"
                :props="{
                  value: 'id',
                  label: 'name',
                  children: 'children',
                  multiple: true,
                  checkStrictly: true,
                }"
                id="addProjectPreferenceCandidateIndustryIds"
              ></el-cascader>
              <el-checkbox
                v-model="formdata.notLimitIndustry"
                @change="Haschecked($event)"
                style="position: absolute; margin-left: 10px"
                id="addProjectNotLimitIndustry"
                >不限</el-checkbox
              >
            </el-form-item>
          </el-col>
          <el-col :offset="1" :span="10">
            <el-form-item label="工作年限：" prop="serviceYear">
              <el-select
                v-if="isShowSelect"
                style="width: 100%"
                clearable
                filterable
                v-model="formdata.serviceYear"
                placeholder="请选择"
                @change="handleServiceYear"
                id="addProjectServiceYear"
              >
                <el-option
                  v-for="(i, index) in serviceYearList"
                  :key="index"
                  :label="i"
                  :value="i"
                  :id="'addProjectServiceYearList' + index"
                >
                </el-option>
              </el-select>
              <el-row v-if="!isShowSelect">
                <el-col :span="11">
                  <el-form-item label-width="0" prop="serviceYearMin">
                    <el-input
                      type="number"
                      class="numberHeight"
                      placeholder="最小年限"
                      v-model.number="formdata.serviceYearMin"
                      id="addProjectServiceYearMin"
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="2" style="text-align: center; color: #6e6f73"
                  >-</el-col
                >
                <el-col :span="11">
                  <el-form-item label-width="0" prop="serviceYearMax">
                    <el-input
                      type="number"
                      class="numberHeight"
                      placeholder="最大年限"
                      v-model.number="formdata.serviceYearMax"
                      id="addProjectServiceYearMax"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <span
                  class="tabHref"
                  @click="isShowSelect = true"
                  style="right: -40px; position: absolute"
                  id="addProjectShowSelect"
                  >返回</span
                >
              </el-row>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :offset="1" :span="10">
            <el-form-item label="人才标签：" prop="labelIds">
              <div
                class="inputContent"
                style="
                  display: flex;
                  justify-content: space-between;
                  padding: 2px 10px;
                  align-items: center;
                "
              >
                <p
                  v-if="labelIdsList && labelIdsList.length == 0"
                  style="color: #b9becb"
                >
                  请选择人才标签
                </p>
                <div v-else>
                  <span
                    v-for="(x, xindex) in labelIdsList"
                    :key="'labelIds' + xindex"
                    :title="x.name"
                    class="l_label"
                    style="border: 1px #e9e9eb solid; margin: 8px 0"
                    ><span class="inputBorder">{{ x.name }}</span
                    ><i
                      class="el-tag__close el-icon-close"
                      @click="delItem1(xindex)"
                      :id="'addProjectLabelIds' + xindex"
                    ></i
                  ></span>
                </div>
                <p
                  class="tabHref"
                  style="min-width: 50px"
                  @click="showagsDig"
                  id="addProjectShowLabelDig"
                >
                  标签库
                </p>
              </div>
            </el-form-item>
          </el-col>
          <el-col :offset="1" :span="10">
            <el-form-item label="年龄：" prop="age">
              <el-row>
                <el-col :span="11">
                  <el-form-item label-width="0" prop="ageMin">
                    <el-input
                      type="number"
                      class="numberHeight"
                      placeholder="最小年龄"
                      v-model.number="formdata.ageMin"
                      id="addProjectAgeMin"
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="2" style="text-align: center; color: #6e6f73"
                  >-</el-col
                >
                <el-col :span="11">
                  <el-form-item label-width="0" prop="ageMax">
                    <el-input
                      type="number"
                      class="numberHeight"
                      placeholder="最大年龄"
                      v-model.number="formdata.ageMax"
                      id="addProjectAgeMax"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :offset="1" :span="21">
            <el-form-item label="目标公司：" prop="targetCompanies">
              <div class="inputContent">
                <span
                  v-for="(x, xindex) in dataValues"
                  :key="xindex"
                  :title="x"
                  style="
                    border: 1px #e9e9eb solid;
                    margin: 2px 3px;
                    position: relative;
                  "
                  ><span class="inputBorder">{{ x }}</span
                  ><i
                    class="el-tag__close el-icon-close"
                    style="position: absolute; right: 8px; top: 8px"
                    @click="delItem(xindex)"
                    :id="'addProjectDataValues' + xindex"
                  ></i
                ></span>
                <el-input
                  v-model="formdata.dataValue"
                  style="width: 350px; border: 0"
                  placeholder="请填写公司全称或简称，多个公司之间用空格隔开"
                  @input="getInpData"
                  @blur="handleTargetCompanyBlur"
                  id="addProjectDataValue"
                >
                </el-input>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <p class="drawerCon_from_tit">附件</p>
        <el-row :gutter="24">
          <el-col :offset="1" :span="10">
            <el-form-item label="附件类型：" prop="attachmentInput.type">
              <el-select
                placeholder="请选择"
                style="width: 100%"
                filterable
                v-model="formdata.attachmentInput.type"
                id="addProjectAttachmentInputType"
              >
                <el-option
                  v-for="(item, index) in proAttachType"
                  :key="'p_' + index"
                  :value="item.name"
                  :label="item.name"
                  :id="'addProjectProAttachType' + index"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :offset="1" :span="10">
            <el-form-item label="附件：">
              <el-upload
                ref="upload"
                class="upload-demo"
                multiple
                :headers="myHeaders"
                :action="actionUrl"
                :before-upload="beforeUpload"
                :on-success="handleInfoSuccess"
                :on-error="handleInfoError"
                :on-exceed="handleInfoChange"
                :file-list="fileInfoList"
                :on-remove="handleRemove"
                accept=".doc,.docx,.xls,.xlsx,.pdf,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/png,image/jpeg,application/zip,"
                :limit="10"
                id="addProjectFile"
              >
                <el-button size="mini">选择文件</el-button>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="succUp" v-show="innerVisible">
      <div class="succUpBox">
        <div v-if="isloading">
          <el-progress
            :stroke-width="20"
            :percentage="percentageNum"
            :text-inside="true"
            :color="customColorMethod"
            style="margin-bottom: 20px"
          ></el-progress>
          <p>{{ projectId ? "项目编辑中..." : "项目创建中..." }}</p>
          <p class="tips" style="margin-top: 10px">
            请勿刷新此页（刷新会导致{{ projectId ? "编辑" : "创建" }}失败）
          </p>
        </div>
      </div>
    </div>
    <div class="drawerCon_footer fixedFoot">
      <el-button
        size="small"
        @click="resetForm('formdata', '点击取消按钮关闭弹框')"
        id="addProjectCencel"
        >取消</el-button
      >
      <el-button
        type="primary"
        size="small"
        style="margin-right: 15px"
        :loading="isformdata"
        @click="saveAdd('formdata')"
        id="addProjectSave"
        >保存</el-button
      >
    </div>
    <el-dialog
      title="客户联系人"
      :visible.sync="dialogVisibleLinkMan"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :before-close="handClose"
      width="600px"
      append-to-body
    >
      <el-form
        @submit.native.prevent
        :model="linkManFrom"
        ref="linkManFrom"
        :rules="rulesLink"
        style="padding: 0 50px"
        :validate-on-rule-change="false"
        label-width="100px"
        label-position="right"
      >
        <el-form-item label="姓名：" prop="name">
          <el-input
            placeholder="请输入内容"
            v-model="linkManFrom.name"
            id="addProjectLinkManFromName"
            maxlength="20"
          ></el-input>
        </el-form-item>
        <el-form-item label="职位：" prop="position">
          <el-input
            placeholder="请输入内容"
            v-model="linkManFrom.position"
            maxlength="20"
            id="addProjectLinkManFromPosition"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系电话：" prop="tel">
          <el-input
            placeholder="请输入内容"
            v-model="linkManFrom.tel"
            maxlength="50"
            id="addProjectLinkManFromTel"
          ></el-input>
        </el-form-item>
        <el-form-item label="邮箱：" prop="email">
          <el-input
            placeholder="请输入内容"
            v-model="linkManFrom.email"
            maxlength="50"
            id="addProjectLinkManFromEmail"
          ></el-input>
        </el-form-item>
        <el-form-item label="微信或其他：" prop="wechatOrOther">
          <el-input
            placeholder="请输入内容"
            v-model="linkManFrom.wechatOrOther"
            maxlength="50"
            id="addProjectLinkManFromWechatOrOther"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            dialogVisibleLinkMan = false;
            resetForm('linkManFrom');
          "
          size="small"
          id="addProjectLinkManFromCencel"
          >取 消</el-button
        >
        <el-button
          type="primary"
          size="small"
          @click="submitF('linkManFrom')"
          id="addProjectLinkManFromSave"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 标签管理 -->
    <el-dialog
      title="人才标签"
      :visible.sync="dialogVisibleOfTag"
      append-to-body
      :close-on-click-modal="false"
      width="960px"
      custom-class="addTagDig tagsDigContent"
      :before-close="closeTagDig"
    >
      <div class="tagsDigContent">
        <div class="serachInp">
          <el-input
            placeholder="搜索标签"
            class="input-with-select"
            v-model="searchTag"
          >
            <el-button @click="search" type="primary" slot="append"
              >查询</el-button
            >
          </el-input>
          <div class="searchHoverContent" v-if="searchHoverContentIsShow">
            <div
              class="row"
              v-for="(i, index) in serchChoiceList"
              :key="index"
              @click="chioceData(i)"
            >
              <p v-html="i.name">{{ i.name }}</p>
              <p>{{ i.type }}</p>
            </div>
            <div
              class="nodata"
              v-if="serchChoiceList.length == 0"
              @click="(searchHoverContentIsShow = false), (searchTag = '')"
            >
              暂无数据……
            </div>
          </div>
        </div>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="技能标签" name="first">
            <div
              class="tagsContent"
              @click="(searchHoverContentIsShow = false), (searchTag = '')"
            >
              <div class="tagsContent_L">
                <p
                  :class="i.isActive ? 'tagsContent_L_active' : ''"
                  v-for="(i, index) in addTypeList"
                  :key="index"
                  @click="chioceType(i)"
                  style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <span>{{ i.name }}</span>
                  <span v-if="i.hitsNums > 0" class="tagNums">{{
                    i.hitsNums
                  }}</span>
                </p>
              </div>
              <div class="tagsContent_R">
                <el-checkbox-group
                  class="tagLis"
                  size="mini"
                  v-model="createTag.labelIds"
                >
                  <el-checkbox
                    v-for="item in newTagList"
                    :label="item.id"
                    :key="item.id"
                    border
                    :style="
                      item.bordered
                        ? { 'border-color': item.color, color: item.color }
                        : {}
                    "
                    >{{ item.name }}</el-checkbox
                  >
                </el-checkbox-group>
                <el-empty
                  v-if="newTagList == null"
                  style="text-align: center"
                  description="暂无标签"
                ></el-empty>
              </div></div
          ></el-tab-pane>
          <el-tab-pane label="履历标签" name="second"
            ><div
              class="tagsContent"
              @click="(searchHoverContentIsShow = false), (searchTag = '')"
            >
              <div class="tagsContent_R" style="padding: 20px 20px">
                <el-checkbox-group
                  class="tagLis"
                  size="mini"
                  v-model="createTag.labelIds1"
                >
                  <el-checkbox
                    v-for="item in resumeOptionsList"
                    :label="item.id"
                    :key="item.id"
                    border
                    :style="
                      item.bordered
                        ? { 'border-color': item.color, color: item.color }
                        : {}
                    "
                    >{{ item.name }}</el-checkbox
                  >
                </el-checkbox-group>
                <el-empty
                  v-if="resumeOptionsList == null"
                  style="text-align: center"
                  description="暂无标签"
                ></el-empty>
              </div></div
          ></el-tab-pane>
        </el-tabs>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="default" plain size="mini" @click="closeTagDig"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="mini"
          :loading="saveloading"
          @click="saveTags"
          >保存</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisibleOfEdit"
      width="600px"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :append-to-body="true"
      custom-class="editPercent"
      :before-close="handClose"
    >
      <div style="text-align: center">
        <p style="font-size: 20px; margin: 15px 0; font-weight: 800">
          <i
            class="iconfont el-icon-warning"
            style="margin-right: 8px; font-size: 20px"
          ></i
          >重复职位提醒
        </p>
        <div>
          <p
            v-for="(i, index) in repeatData"
            :key="index"
            style="margin-bottom: 10px"
          >
            此客户已由【{{ i.creator }}】于【{{
              i.createTime
            }}】创建了相同的职位，<span
              class="tabHref"
              @click="goDetails(i.projectId)"
              >点击查看</span
            >
          </p>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" type="primary" @click="saveAdd('formdata')"
          >继续保存</el-button
        >
      </span>
    </el-dialog>
  </section>
</template>

<script>
import {
  getCommonColumnList,
  getEnableUserBaseInfos,
  getContactsBaseInfos,
  GetsysCompanyOption,
  addOrEditProject,
  getProjectById,
  comContactsAddOrEdit,
  getContractBaseInfos,
  cityTree,
  categoryAllTypeOptions, //标签选项
  projectValidateRepeat, //校验重复的
} from "../../api/api";
import {
  statisticsResumePositionAutomatic, //记录人岗自动匹配-自动匹配人才点击量
  statisticsClickFunctionEscape, //记录功能或页面跳出
} from "../../api/buryingPoint";
import * as data from "../../common/json/commData.json";
import { ApiBaseUrl } from "../../api/http";
import Cookies from "js-cookie";
export default {
  props: ["projectId", "customerId", "isQuick", "isCopy", "isCustomerInfo"],
  inject: ["reload"],
  name: "addorEdit",
  data() {
    let checkCent = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("需求数量不能为空或者0"));
      }
      setTimeout(() => {
        if (!Number.isInteger(value) || value < 0) {
          callback(new Error("请输入大于0的数值或整数"));
        } else if (value > 999999) {
          callback(new Error("最多不能超过6位数"));
        } else {
          callback();
        }
      }, 500);
    };
    let checkNum = (rule, value, callback) => {
      if (value) {
        //有值
        setTimeout(() => {
          if (!Number.isInteger(value) || value < 0) {
            callback(new Error("请输入大于0的数值或整数"));
          } else {
            callback();
          }
        }, 500);
      } else {
        callback();
      }
    };
    let checkNumMax = (rule, value, callback) => {
      if (value) {
        //有值
        setTimeout(() => {
          if (!Number.isInteger(value) || value < 0) {
            callback(new Error("请输入大于0的数值或整数"));
          } else if (
            this.formdata.serviceYearMin !== null &&
            value < this.formdata.serviceYearMin
          ) {
            callback(new Error("最大年限不能小于最小年限"));
          } else {
            callback();
          }
        }, 500);
      } else {
        if (value !== "" && value == 0) {
          callback(new Error("请输入大于0的数值或整数"));
        } else {
          callback();
        }
      }
    };
    let checkNumMin = (rule, value, callback) => {
      if (value) {
        //有值
        setTimeout(() => {
          if (!Number.isInteger(value) || value < 0) {
            callback(new Error("请输入大于0的数值或整数"));
          } else if (
            this.formdata.serviceYearMax !== null &&
            value > this.formdata.serviceYearMax
          ) {
            callback(new Error("最小年限不能大于最大年限"));
          } else {
            callback();
          }
        }, 500);
      } else {
        if (value !== "" && value == 0) {
          callback(new Error("请输入大于0的数值或整数"));
        } else {
          callback();
        }
      }
    };
    let checkAgeMax = (rule, value, callback) => {
      if (value) {
        //有值
        setTimeout(() => {
          if (!Number.isInteger(value) || value < 0) {
            callback(new Error("请输入大于0的数值或整数"));
          } else if (
            this.formdata.ageMin !== null &&
            value < this.formdata.ageMin
          ) {
            callback(new Error("最大年龄不能小于最小年龄"));
          } else {
            callback();
          }
        }, 500);
      } else {
        if (value !== "" && value == 0) {
          callback(new Error("请输入大于0的数值或整数"));
        } else {
          callback();
        }
      }
    };
    let checkAgeMin = (rule, value, callback) => {
      if (value) {
        //有值
        setTimeout(() => {
          if (!Number.isInteger(value) || value < 0) {
            callback(new Error("请输入大于0的数值或整数"));
          } else if (
            this.formdata.ageMax !== null &&
            value > this.formdata.ageMax
          ) {
            callback(new Error("最小年龄不能大于最大年龄"));
          } else {
            callback();
          }
        }, 500);
      } else {
        if (value !== "" && value == 0) {
          callback(new Error("请输入大于0的数值或整数"));
        } else {
          callback();
        }
      }
    };
    return {
      dialogVisibleLinkMan: false,
      linkManFrom: {
        companyId: null,
        email: "",
        id: 0,
        name: "",
        position: "",
        tel: "",
        version: 0,
        wechatOrOther: "",
      },
      rulesLink: {
        name: [
          {
            required: true,
            trigger: "blur",
            message: "请输入姓名",
          },
        ],
        position: [
          {
            required: true,
            trigger: "blur",
            message: "请输入职位名称",
          },
        ],
      },
      allChecked: [],
      endOptions: {
        // 限制可选的结束年月
        disabledDate: (date) => {
          if (this.formdata.startDate) {
            let beginDate =
              new Date(this.formdata.startDate).getTime() - 86400000;
            return date && date < beginDate;
          }
        },
      },
      formLoading: false,
      projectType: data.projectType,
      proAttachType: data.proAttachType,
      dialogVisible: false,
      specialItems: [],
      fuzzyItems: [],
      companyList: [],
      contactsList: [],
      cityList: [],
      posList: [],
      userList: [],
      contractList: [],
      actionUrl: ApiBaseUrl + "/api/file/uploadOne",
      myHeaders: {
        Authorization: Cookies.get("accessToken"),
      },
      fileInfoList: [], //上传List文件
      formdata: {
        attachmentInput: {
          fileList: [],
          id: null,
          moduleType: "项目",
          objectId: null,
          type: "JD",
          version: 0,
        },
        cityIds: [],
        companyContactId: null,
        companyContactIds: [],
        companyId: null,
        contractId: null,
        dueDate: "",
        funIds: [],
        functionId: 1,
        functionIds: "",
        functionParentId: null,
        hireCount: null,
        id: 0,
        jd: "",
        managerId: JSON.parse(this.getStore("uid")),
        monthCount: null,
        monthlySalary: null,
        newprojectManagerId: JSON.parse(this.getStore("uid")),
        permissionUserIds: [JSON.parse(this.getStore("uid"))],
        position: "",
        projectCityList: [
          {
            cityId: 0,
            cityIds: "",
            cityParentId: 0,
            id: 0,
            projectId: 0,
            version: 0,
          },
        ],
        projectFunctionList: [
          {
            functionId: 0,
            functionIds: "",
            functionParentId: 0,
            id: 0,
            projectId: 0,
            version: 0,
          },
        ],
        secure: false,
        startDate: new Date(),
        status: "进行中",
        version: 0,
        dataValue: null,
        comment: null,
        targetCompanies: [],
        labelIds: [],
        serviceYearMin: null,
        serviceYearMax: null,
        notLimitIndustry: false,
      },
      rules: {
        position: [
          {
            required: true,
            trigger: "blur",
            message: "请输入职位名称",
          },
        ],
        companyId: [
          {
            required: true,
            trigger: "change",
            message: "请选择客户公司",
          },
        ],
        hireCount: [
          {
            required: true,
            type: "number",
            trigger: "blur",
            validator: checkCent,
          },
        ],
        companyContactIds: [
          {
            required: true,
            trigger: "change",
            message: "请选择客户联系人",
          },
        ],
        monthlySalary: [
          {
            type: "number",
            min: 0,
            max: 999999999,
            message: "请输入0-999999999的数值",
            trigger: "blur",
          },
        ],
        monthCount: [
          {
            type: "number",
            trigger: "blur",
            validator: checkNum,
          },
          {
            type: "number",
            min: 0,
            max: 99,
            message: "请输入0-99的数值",
            trigger: "blur",
          },
        ],
        funIds: [
          {
            required: true,
            trigger: "change",
            message: "请选择职能",
          },
        ],
        startDate: [
          {
            required: true,
            trigger: "change",
            message: "请选择开始日期",
          },
        ],
        status: [
          {
            required: true,
            trigger: "change",
            message: "请选择状态",
          },
        ],
        cityIds: [
          {
            required: true,
            trigger: "change",
            message: "请选择城市",
          },
        ],
        permissionUserIds: [
          {
            required: true,
            trigger: "change",
            message: "请选择参与顾问",
          },
        ],
        managerId: [
          {
            required: true,
            trigger: "change",
            message: "请选择项目经理",
          },
        ],
        // preferenceCandidateIndustryIds: [
        //   {
        //     required: true,
        //     trigger: "change",
        //     message: "请选择人才行业",
        //   },
        // ],
        serviceYearMin: [
          {
            type: "number",
            min: 1,
            trigger: "blur",
            validator: checkNumMin,
          },
        ],
        serviceYearMax: [
          {
            type: "number",
            min: 1,
            trigger: "blur",
            validator: checkNumMax,
          },
        ],
        ageMin: [
          {
            type: "number",
            min: 1,
            trigger: "blur",
            validator: checkAgeMin,
          },
        ],
        ageMax: [
          {
            type: "number",
            min: 1,
            trigger: "blur",
            validator: checkAgeMax,
          },
        ],
      },
      isformdata: false,
      dataValues: [],
      positionAlias: [],
      projectFailType: data.projectFailType,
      dialogVisibleOfTag: false,
      addTypeList: [],
      newTagSerchList: [],
      chioceIndex: 0,
      dataCount: [],
      choiceTypeName: "",
      createTag: {
        resumeIds: "",
        labelIds: [],
        labelIds1: [],
      },
      searchTag: "",
      newTagList: [],
      saveloading: false,
      activeName: "first",
      repeatData: [],
      repeat: true,
      dialogVisibleOfEdit: false,
      labelIds: [],
      resumeOptionsList: [],
      labelIdsList: [],
      searchHoverContentIsShow: false,
      serchChoiceList: [],
      isloading: false,
      isShowComplation: false,
      innerVisible: false,
      percentageNum: 0,
      myInterval: null,
      isOperation: false, //是否进行有效操作
      ColumnInList: [], //行业
      isShowSelect: true,
      serviceYearList: ["1-3年", "3-5年", "5-10年", "10年以上", "自定义"],
      isResouceShow: 0,
    };
  },
  watch: {
    projectId(newValue, oldValue) {},
    labelIdsListArr: {
      //监听选中的标签id
      handler(nVal, oVal) {
        let list = null;
        list = oVal.concat(nVal).filter(function (v, i, arr) {
          return arr.indexOf(v) === arr.lastIndexOf(v);
        });
        this.addTypeList.forEach((item) => {
          if (item.name == this.choiceTypeName) {
            item.hitsNums = item.hitsNums + (nVal.length - oVal.length);
          } else {
            item.labels.forEach((i) => {
              if (list.length > 1) {
                list.forEach((x) => {
                  if (x == i.id) {
                    item.hitsNums++;
                  }
                });
              } else {
                if (i.id == list[0]) {
                  item.hitsNums = item.hitsNums + (nVal.length - oVal.length);
                }
              }
            });
          }
        });
      },
      deep: true,
    },
  },
  computed: {
    labelIdsListArr() {
      return JSON.parse(JSON.stringify(this.createTag.labelIds));
    },
  },
  methods: {
    addContact() {
      if (this.formdata.companyId) {
        this.dialogVisibleLinkMan = true;
      } else {
        this.$message.error("请先选择客户公司");
      }
    },
    submitF(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (
            !this.linkManFrom.tel &&
            !this.linkManFrom.email &&
            !this.linkManFrom.wechatOrOther
          ) {
            this.$message.error("联系电话/邮箱/微信或其他至少填写1项");
            return false;
          }
          this.linkManFrom.companyId = this.formdata.companyId;
          comContactsAddOrEdit(this.linkManFrom).then((res) => {
            if (res.success) {
              this.$message.success("保存成功");
              this.dialogVisibleLinkMan = false;
              this.resetForm("linkManFrom");
              this.loadContactsLitst(this.linkManFrom.companyId, "sign");
            }
          });
        } else {
          return false;
        }
      });
    },
    loadCompanyList() {
      //获取客户
      GetsysCompanyOption({ keyword: "" }).then((res) => {
        if (res.success) {
          this.companyList = res.result;
        }
      });
    },
    LoadContractList(id) {
      //获取合同list
      this.contractList = [];
      getContractBaseInfos({ id: id }).then((res) => {
        if (res.success) {
          this.contractList = res.result;
        }
      });
    },
    loadContactsLitst(id, type) {
      //获取客户联系人
      if (id) {
        this.LoadContractList(id);
        getContactsBaseInfos({ companyId: id }).then((res) => {
          if (res.success) {
            this.contactsList = res.result;
            if (!this.projectId) {
              //新增
              this.formdata.companyContactIds = [];
              if (res.result && res.result.length > 0) {
                this.formdata.companyContactIds.push(res.result[0].id);
              }
            } else {
              //编辑
              if (type == "sign") {
                this.formdata.companyContactIds = [];
                if (res.result && res.result.length > 0) {
                  this.formdata.companyContactIds.push(res.result[0].id);
                }
              }
            }
          }
        });
      }
    },
    handClose() {
      this.isformdata = false;
      this.innerVisible = false;
      this.dialogVisibleOfEdit = false;
      this.dialogVisibleLinkMan = false;
      this.resetForm("linkManFrom");
    },
    handleInfoSuccess(res, file, fileList) {
      this.fileInfoList = fileList;
      this.formdata.attachmentInput.fileList = [];
      fileList.forEach((item) => {
        this.formdata.attachmentInput.fileList.push({
          filePath: item.response ? item.response.result : "",
          fileName: item.name,
          fileType: item.raw.type,
        });
      });
    },
    handleInfoError(err) {
      this.$message.error("文件上传出错");
    },
    beforeUpload: function (file) {
      const isLt = file.size / 1024 / 1024 < 20;
      if (!isLt) {
        this.$message.error("上传文件大小不能超过 20MB!");
      }
      return isLt;
    },
    handleRemove(file, fileList) {
      if (this.formdata.attachmentInput) {
        this.formdata.attachmentInput.fileList = [];
        fileList.forEach((item) => {
          this.formdata.attachmentInput.fileList.push({
            filePath: item.response ? item.response.result : "",
            fileName: item.name,
            fileType: item.raw.type,
          });
        });
      }
    },
    handleInfoChange(files, fileList) {
      this.$message.error("最多上传10份文件");
    },
    //行业不限
    Haschecked(val) {
      console.log(val);
      this.formdata.notLimitIndustry = val;
      if (val) {
        this.formdata.preferenceCandidateIndustryIds = [];
      }
    },
    //工作年限切换
    handleServiceYear(val) {
      console.log(val);
      this.formdata.serviceYearMin = null;
      this.formdata.serviceYearMax = null;
      if (val == "自定义") {
        this.formdata.serviceYear = "";
        this.isShowSelect = false;
      } else {
        if (val == "1-3年") {
          this.formdata.serviceYearMin = 1;
          this.formdata.serviceYearMax = 3;
        } else if (val == "3-5年") {
          this.formdata.serviceYearMin = 3;
          this.formdata.serviceYearMax = 5;
        } else if (val == "5-10年") {
          this.formdata.serviceYearMin = 5;
          this.formdata.serviceYearMax = 10;
        } else if (val == "10年以上") {
          this.formdata.serviceYearMin = 10;
          this.formdata.serviceYearMax = null;
        }
        this.isShowSelect = true;
      }
    },
    saveAdd(formName) {
      this.isOperation = true;
      this.isformdata = true;
      if (this.formdata.status == "失败") {
        if (this.formdata.failRemark == "" || !this.formdata.failRemark) {
          this.$message.error("失败原因不能为空");
          this.isformdata = false;
          return false;
        }
      }
      if (this.positionAlias.length == 0) {
        this.$message.error("职位别名不能为空");
        this.isformdata = false;
        return false;
      }
      //保存信息
      this.formdata.id = this.isCopy ? 0 : this.formdata.id;
      this.formdata.targetCompanies = this.dataValues;
      this.formdata.positionAlias = this.positionAlias;
      let labelIds = [];
      this.labelIdsList.forEach((i) => {
        labelIds.push(i.id);
      });
      this.formdata.labelIds = labelIds;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.innerVisible = true;
          this.isloading = true;
          this.myInterval = setInterval(() => {
            if (this.percentageNum == 99) {
              clearInterval(this.myInterval);
            } else {
              this.percentageNum++;
            }
          }, 100);
          this.isformdata = false;
          if (this.repeat) {
            projectValidateRepeat({
              companyId: this.formdata.companyId,
              position: this.formdata.position,
              projectId: this.formdata.id,
            }).then((res) => {
              if (res.success) {
                if (res.result) {
                  clearInterval(this.myInterval);
                  this.innerVisible = false;
                  this.isloading = false;
                  this.repeat = false;
                  this.repeatData = res.result;
                  this.dialogVisibleOfEdit = true;
                } else {
                  this.saveAddOrEditProject(formName);
                }
              }
            });
          } else {
            this.saveAddOrEditProject(formName);
          }
        } else {
          this.isformdata = false;
          return false;
        }
      });
    },
    saveAddOrEditProject(formName) {
      addOrEditProject(this.formdata).then((res) => {
        if (res.success) {
          this.$refs[formName].resetFields();
          this.dialogVisibleOfEdit = false;
          clearInterval(this.myInterval);
          this.innerVisible = false;
          this.isloading = false;
          let h = this.$createElement;
          this.$msgbox({
            title: "提示",
            message: h(
              "div",
              {
                style: "text-align:center;",
              },
              [
                h(
                  "strong",
                  {
                    style: "font-size:16px;font-weight: 800;",
                  },
                  `${this.formdata.id ? "职位编辑成功" : "职位创建成功"}`
                ),
                h(
                  "p",
                  {
                    style: "margin: 10px 0",
                  },
                  [
                    "已为职位自动匹配约",
                    h(
                      "span",
                      {
                        style: "color:#e60012",
                      },
                      `${res.result.hits}`
                    ),
                    "个候选人",
                    h(
                      "span",
                      {
                        class: "tabHref",
                        on: {
                          click: () => {
                            this.goAutomatic(res.result);
                          },
                        },
                      },
                      "点击查看"
                    ),
                  ]
                ),
              ]
            ),
            showCancelButton: false,
            showConfirmButton: false,
            dangerouslyUseHTMLString: true,
            customClass: "msgClass",
            beforeClose: "closeConf",
          })
            .then(() => {})
            .catch(() => {
              if (this.formdata.id) {
                this.resetForm(formName);
              } else {
                this.$router.replace({
                  path: "/supplierAllBack",
                  query: {
                    path: "/project/details",
                    id: res.result.projectId,
                  },
                });
                // this.$router.replace({
                //   path: "/project/details",
                //   query: { id: res.result.projectId },
                // });
              }
            });
        }
      });
    },
    closeConf(action, instance) {},
    loadUserBase() {
      //获取用户列表
      getEnableUserBaseInfos().then((res) => {
        if (res.success) {
          this.userList = res.result;
          this.allChecked = [];
          for (let i in this.userList) {
            this.allChecked.push(this.userList[i].id);
          }
        }
      });
    },
    LoadColumnList() {
      //加载自定义列表
      this.cityList = [];
      this.posList = [];
      cityTree().then((res) => {
        if (res.success) {
          this.cityList = res.result;
        }
      });
      getCommonColumnList({
        type: "职能",
      }).then((res) => {
        if (res.success) {
          this.posList = res.result;
        }
      });
      getCommonColumnList({
        type: "行业",
      }).then((res) => {
        if (res.success) {
          this.ColumnInList = res.result;
          ++this.isResouceShow;
        }
      });
    },
    resetForm(formName, type) {
      this.nextStep = type ? type : null;
      this.$refs[formName].resetFields();
      if (formName == "formdata") {
        if (!this.isQuick) {
          this.$emit("loadList");
        } else {
          this.$emit("cancel");
        }
      }
    },
    getInfoById(id) {
      this.formLoading = true;
      getProjectById(id).then((res) => {
        this.formLoading = false;
        if (res.success) {
          this.formdata = res.result;
          this.createTag.labelIds = res.result.skillLabels
            ? res.result.skillLabels
            : [];
          this.createTag.labelIds1 = res.result.resumeLabels
            ? res.result.resumeLabels
            : [];
          this.formdata.attachmentInput = {
            fileList: [],
            id: null,
            moduleType: "项目",
            objectId: null,
            type: "JD",
            version: 0,
          };
          this.formdata.cityIds = this.formdata.projectCityList.map((i) =>
            JSON.parse(i.cityIds)
          );
          this.formdata.funIds = this.formdata.projectFunctionList.map((i) =>
            JSON.parse(i.functionIds)
          );
          this.positionAlias = this.formdata.positionAlias
            ? this.formdata.positionAlias
            : [];
          this.dataValues = this.formdata.targetCompanies
            ? this.formdata.targetCompanies
            : [];
          this.labelIdsList = this.formdata.labelOptionVos
            ? this.formdata.labelOptionVos
            : [];
          this.formdata.preferenceCandidateIndustryIds =
            this.formdata.preferenceIndustries.map((i) =>
              JSON.parse(i.industryIds)
            );
          this.isShowSelect = true;
          if (
            this.formdata.serviceYearMin == 1 &&
            this.formdata.serviceYearMax == 3
          ) {
            this.formdata.serviceYear = "1-3年";
          } else if (
            this.formdata.serviceYearMin == 3 &&
            this.formdata.serviceYearMax == 5
          ) {
            this.formdata.serviceYear = "3-5年";
          } else if (
            this.formdata.serviceYearMin == 5 &&
            this.formdata.serviceYearMax == 10
          ) {
            this.formdata.serviceYear = "5-10年";
          } else if (
            this.formdata.serviceYearMin == 10 &&
            this.formdata.serviceYearMax == null
          ) {
            this.formdata.serviceYear = "10年以上";
          } else if (
            this.formdata.serviceYearMin != null &&
            this.formdata.serviceYearMax != null
          ) {
            this.isShowSelect = false;
            this.formdata.serviceYear = "自定义";
          } else {
            this.formdata.serviceYear = "";
          }
          // 获取客户联系人
          this.loadContactsLitst(res.result.companyId, "double");
        }
      });
    },
    //数据集数据处理
    getInpData() {
      if (
        this.formdata.dataValue[this.formdata.dataValue.length - 1] == " " &&
        this.formdata.dataValue.replace(/(^\s*)|(\s*$)/g, "").length > 0
      ) {
        this.dataValues.push(
          this.formdata.dataValue.replace(/(^\s*)|(\s*$)/g, "")
        );
        this.formdata.dataValue = null;
      }
    },
    handleTargetCompanyBlur() {
      if (this.formdata.dataValue) {
        if (this.formdata.dataValue.replace(/(^\s*)|(\s*$)/g, "").length > 0) {
          this.dataValues.push(
            this.formdata.dataValue.replace(/(^\s*)|(\s*$)/g, "")
          );
          this.formdata.dataValue = null;
        }
      }
    },
    //删除数据集
    delItem(xindex) {
      this.dataValues.splice(xindex, 1);
    },
    //数据集数据处理
    getAliasInpData() {
      if (
        this.formdata.positionAlia[this.formdata.positionAlia.length - 1] ==
          " " &&
        this.formdata.positionAlia.replace(/(^\s*)|(\s*$)/g, "").length > 0
      ) {
        this.positionAlias.push(
          this.formdata.positionAlia.replace(/(^\s*)|(\s*$)/g, "")
        );
        this.formdata.positionAlia = null;
      }
    },
    handleTargetAliasBlur() {
      if (this.formdata.positionAlia) {
        if (
          this.formdata.positionAlia.replace(/(^\s*)|(\s*$)/g, "").length > 0
        ) {
          this.positionAlias.push(
            this.formdata.positionAlia.replace(/(^\s*)|(\s*$)/g, "")
          );
          this.formdata.positionAlia = null;
        }
      }
    },
    //删除数据集
    delAliasItem(xindex) {
      this.positionAlias.splice(xindex, 1);
    },

    //显示标签库
    showagsDig() {
      this.dialogVisibleOfTag = true;
      this.searchTag = "";
      this.activeName = "first";
      this.searchHoverContentIsShow = false;
      this.isaddTag = true;
      if (this.createTag.labelIds.length == 0) {
        this.createTag.labelIds = this.formdata.skillLabels
          ? this.formdata.skillLabels
          : [];
      } else {
        this.createTag.labelIds = this.createTag.labelIds;
      }
      if (this.createTag.labelIds1.length == 0) {
        this.createTag.labelIds1 = this.formdata.resumeLabels
          ? this.formdata.resumeLabels
          : [];
      } else {
        this.createTag.labelIds1 = this.createTag.labelIds1;
      }
      this.createTag.resumeIds = [];
      // this.getCategoryAllTypeOptions()
      // this.multipleSelection.forEach((item, index) => {
      //   this.createTag.resumeIds[index] = item.id;
      // });
    },
    saveTags() {
      let list = [];
      let list1 = [];
      this.createTag.labelIds.forEach((i) => {
        this.addTypeList.forEach((item) => {
          item.labels.forEach((item1) => {
            if (i == item1.id) {
              list.push({ name: item1.name, id: item1.id, type: "技能标签" });
            }
          });
        });
      });
      this.createTag.labelIds1.forEach((i) => {
        this.resumeOptionsList.forEach((item) => {
          if (i == item.id) {
            list1.push({ name: item.name, id: item.id, type: "履历标签" });
          }
        });
      });
      list = this.unique(list);
      list1 = this.unique(list1);
      this.labelIdsList = list.concat(list1);
      this.dialogVisibleOfTag = false;
    },
    unique(arr) {
      var obj = {};
      arr = arr.reduce(function (item, next) {
        if (!obj[next.id]) {
          obj[next.id] = true && item.push(next);
        } 
        // obj[next.id] ? "" : (obj[next.id] = true && item.push(next));
        return item;
      }, []);
      return arr;
    },
    //删除数据集
    delItem1(xindex) {
      if (this.labelIdsList[xindex].type == "技能标签") {
        this.createTag.labelIds.forEach((i, index) => {
          if (i == this.labelIdsList[xindex].id) {
            this.createTag.labelIds.splice(index, 1);
          }
        });
      } else {
        this.createTag.labelIds1.forEach((i, index) => {
          if (i == this.labelIdsList[xindex].id) {
            this.createTag.labelIds1.splice(index, 1);
          }
        });
      }
      this.labelIdsList.splice(xindex, 1);
    },
    //获取标签选项
    getCategoryAllTypeOptions() {
      categoryAllTypeOptions().then((res) => {
        if (res.success) {
          this.addTypeList = res.result.skillOptions;
          this.resumeOptionsList = res.result.resumeOptions;
          this.addTypeList.forEach((item) => {
            item.isActive = false;
            item.hitsNums = 0;
          });
          if (this.addTypeList) {
            this.addTypeList[0].isActive = true;
            this.choiceTypeName = this.addTypeList[0].name;
            this.newTagList = this.addTypeList[0].labels;
            this.newTagSerchList = this.addTypeList[0].labels;
          }
        }
      });
    },
    //选中标签类别
    chioceType(row) {
      this.choiceTypeName = row.name;
      this.addTypeList.forEach((item, index) => {
        if (item.name == row.name) {
          this.chioceIndex = index;
          item.isActive = true;
          this.newTagList = row.labels;
          this.newTagSerchList = row.labels;
        } else {
          item.isActive = false;
        }
      });
    },
    search() {
      let text = this.searchTag;
      let dataList = [];
      if (text) {
        this.addTypeList.forEach((item, index) => {
          item.labels.forEach((i, index1) => {
            if (i.name.toUpperCase().match(text.toUpperCase())) {
              let val = {
                name: "",
                type: "",
                id: "",
                category: "技能标签",
                typeName: "",
              };
              val.id = i.id;
              val.name = i.name.replaceAll(
                text,
                '<font color="#ff3f3f">' + text + "</font>"
              );
              val.typeName = item.name;
              val.type = "技能标签 > " + item.name + " > " + i.name;
              dataList.push(val);
            }
          });
        });
        this.resumeOptionsList.forEach((item, index) => {
          if (item.name.toUpperCase().match(text.toUpperCase())) {
            let val = {
              name: "",
              type: "",
              id: "",
              category: "履历标签",
            };
            val.id = item.id;
            val.name = item.name.replaceAll(
              text,
              '<font color="#ff3f3f">' + text + "</font>"
            );
            val.type = "履历标签 > " + item.name;
            dataList.push(val);
          }
        });
      }
      this.serchChoiceList = dataList;
      this.searchHoverContentIsShow = true;
    },
    handleClick(tab, event) {},
    goDetails(id) {
      this.$parent.handleClose();
      let newUrl = this.$router.resolve({
        path: "/project/details",
        query: { id: id },
      });
      window.open(newUrl.href, "_blank");
    },
    goAutomatic(row) {
      statisticsResumePositionAutomatic({
        entry: "项目创建成功弹框",
        projectId: row.projectId,
      }).then();
      this.$msgbox.close();
      this.$parent.handleClose();
      let newUrl = this.$router.resolve({
        path: "/project/details",
        query: {
          id: row.projectId,
          type: "automatic",
          entry: "项目创建成功弹框",
        },
      });
      window.open(newUrl.href, "_blank");
    },
    //选中
    chioceData(i) {
      let list = [];
      if (i.category == "技能标签") {
        this.choiceTypeName = i.typeName;
        list = this.createTag.labelIds;
        let item = [i.id];
        if (!this.createTag.labelIds.includes(i.id)) {
          if (list.length === 0) {
            this.createTag.labelIds = [...item];
          } else {
            this.createTag.labelIds = [...list, ...item];
          }
        } else {
          this.$message.error("标签已选");
        }
      } else {
        list = this.createTag.labelIds1;
        let item = [i.id];
        if (!this.createTag.labelIds1.includes(i.id)) {
          if (list.length === 0) {
            this.createTag.labelIds1 = [...item];
          } else {
            this.createTag.labelIds1 = [...list, ...item];
          }
        } else {
          this.$message.error("标签已选");
        }
        // this.$set(
        //   this.createTag.labelIds1,
        //   this.createTag.labelIds1.length == 0
        //     ? this.createTag.labelIds1.length
        //     : this.createTag.labelIds1.length + 1,
        //   i.id
        // );
      }
      this.searchTag = "";
      this.searchHoverContentIsShow = false;
    },
    closeTagDig() {
      this.dialogVisibleOfTag = false;
    },
    customColorMethod(percentage) {
      if (percentage < 50) {
        return "#f56c6c";
      } else if (percentage < 100) {
        return "#e6a23c";
      } else {
        return "#67c23a";
      }
    },
    setOperationData(val) {
      this.nextStep = val;
    },
  },
  created() {
    this.startTimeStamp = Math.round(new Date().getTime() / 1000);
    this.formdata.id = this.projectId ? this.projectId : null;
    if (this.customerId) {
      this.formdata.companyId = this.customerId;
      this.loadContactsLitst(this.customerId, "sign");
    }
    this.LoadColumnList(); //加载城市
    this.loadUserBase(); //获取用户列表
    this.loadCompanyList(); //获取客户
    if (this.projectId) {
      this.getInfoById(this.projectId);
    }
    this.getCategoryAllTypeOptions();
  },
  destroyed() {
    if (!this.isOperation && (!this.projectId || !this.customerId)) {
      statisticsClickFunctionEscape({
        endTimeStamp: Math.round(new Date().getTime() / 1000),
        entry: this.isQuick
          ? "框架"
          : this.customerId
          ? this.isCustomerInfo
            ? "客户详情-新增项目"
            : "客户详情-项目tab-新增项目"
          : "项目",
        presentStep: "新增项目",
        startTimeStamp: this.startTimeStamp,
        nextStep: this.nextStep ? this.nextStep : "点击右上角x关闭弹框",
      }).then();
    }
  },
};
</script>
<style lang="scss" scope>
.addContact {
  color: #898989;
  font-size: 18px;
  position: absolute;
  display: inline-block;
  right: -30px;
  top: 10px;
  height: 18px;
  line-height: 18px;
  &:hover {
    color: #e60012;
  }
}
.inputContent {
  //   display: flex;
  border: 1px solid #dcdfe6;
  background-color: #ffffff;
  border-radius: 4px;
  overflow: hidden;
  min-height: 32px;
  line-height: 32px;
  box-sizing: border-box;
  span {
    float: left;
    height: 30px;
    line-height: 30px;
    border-radius: 5px;
    background: #f1f1f1;
    padding: 0 30px 0 8px;
    display: inline-block;
    box-sizing: border-box;
    cursor: pointer;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .l_label {
    float: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px 0 0;
  }
}
.searchHoverContent {
  background: #fff;
  margin: 5px 3px;
  box-shadow: 0px 10px 10px #eee;
  .row {
    font-size: 14px;
    line-height: 20px;
    padding: 5px;
    cursor: pointer;
    :nth-last-child(1) {
      font-size: 12px;
      color: #999;
    }
  }
  .row:hover {
    background: #f1f1f1;
  }
}
.msgClass {
  width: 500px !important;
}
.succUp {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  margin: 0;
  z-index: 9998;
  background-color: rgba(255, 255, 255, 0.8);
  .succUpBox {
    position: fixed;
    z-index: 9999;
    left: calc(50% - 125px);
    top: calc(50% - 100px);
    width: 250px;
    // z-index: 20;
    text-align: center;
    color: #666;
    i {
      margin-bottom: 10px;
    }
    p {
      color: #666;
      font-size: 18px;
      margin: 0;
    }
    .tips {
      font-size: 14px;
      .el-button--text {
        font-size: 14px;
        color: #31a6fe;
        text-decoration: underline;
      }
    }
  }
}
</style>
<style lang='scss'>
.inputContent {
  .el-input__inner {
    border: 0;
  }
}
.tagsDigContent {
  .el-tabs__nav-scroll {
    padding: 0 15px;
  }
  .el-tabs__header {
    margin: 0;
  }
}
</style>