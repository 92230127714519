<template>
  <section>
    <div
      class="selectHeight"
      style="margin: 15px 0 15px 15px"
      :style="belloUrl ? '' : 'margin-bottom:80px'"
    >
      <el-select
        style="width: 80%"
        v-model="versionVal"
        value-key="belloAttachmentId"
        @change="belloAttachmentId"
        placeholder="请选择"
        no-data-text="未上传简历原文"
      >
        <el-option
          v-for="(item, index) in versionOptions"
          :key="index"
          :label="item.name"
          :value="item"
        >
        </el-option>
      </el-select>
      <el-button
        :class="this.versionOptions.length > 0 ? 'hoverBtn' : ''"
        type="text"
        :loading="downloadBtnLoading"
        :disabled="this.versionOptions.length <= 0"
        size="mini"
        @click="downLoad"
        style="margin-left: 10px; color: #666666"
        ><i
          class="el-icon-download"
          style="font-size: 14px; margin-right: 5px"
        ></i
        >下载</el-button
      >
      <el-button
        class="hoverBtn"
        type="text"
        size="mini"
        @click="previewOpen"
        title="全局预览"
        style="margin-left: 10px; color: #666666"
        ><i
          class="drawIcon iconfont icon-quanping"
          style="font-size: 14px; margin-right: 5px"
        ></i
        >预览</el-button
      >
    </div>
    <iframe
      v-if="belloUrl"
      id="frame"
      frameborder="0"
      scrolling="no"
      style="border: 0"
      width="100%"
      height="700px"
      :src="belloUrl"
    ></iframe>
    <p
      v-if="!versionOptions"
      style="position: absolute; top: 50%; left: 50%; color: #999999"
    >
      该文件暂不支持预览……
    </p>
  </section>
</template>

<script>
import {
  previewResume,
  bellopreview,
  previewWithDownLoad,
} from "../../api/api";
import { getRequest2 } from "../../api/http";

export default {
  props: ["id"],
  data() {
    return {
      versionVal: "",
      versionOptions: [],
      downloadBtnLoading: false,
      belloUrl: "",
      AttachmentId: "",
      fileName: "",
    };
  },
  watch: {
    id: function (val) {
      this.$nextTick(() => {
        this.bellopreview();
      });
    },
  },
  created() {},
  methods: {
    // 简历原文
    bellopreview() {
      return new Promise((resolve) => {
        bellopreview({ belloResumeId: this.id }).then((res) => {
          if (res.success) {
            if (res.result[0]) {
              this.fileName = res.result[0].name;
            }
            this.versionOptions = res.result;
            let val = {
              num: this.versionOptions.length,
              type: "简历原文",
            };
            this.$emit("number", val);
            if (this.versionOptions) {
              this.versionVal = this.versionOptions[0];
              this.belloAttachmentId(this.versionVal);
            }
          }
          resolve();
        });
      });
    },
    belloAttachmentId(val) {
      if (!val) {
        return;
      }
      this.AttachmentId = val.belloAttachmentId;
      this.fileName = val.name;
      this.previewResume();
    },
    // 原文预览
    previewResume() {
      previewResume({ belloAttachmentId: this.AttachmentId }).then((res) => {
        this.belloUrl = res.result;
      });
    },
    // 下载简历原文
    downLoad() {
      // if (this.versionOptions.lengh > 0) {
      this.downloadBtnLoading = true;
      previewWithDownLoad({ belloAttachmentId: this.AttachmentId }).then(
        (res) => {
          this.downloadBtnLoading = false;
          getRequest2(res.message)
            .then((ts) => {
              this.downloadBtnLoading = false;
              let link = document.createElement("a");
              link.style.display = "none";
              link.href = ts;
              link.target = "_blank";
              link.setAttribute("download", this.fileName);
              document.body.appendChild(link);
              link.click();
            })
            .catch((e) => {
              this.downloadBtnLoading = false;
            });
        }
      );
    },
    //预览
    previewOpen() {
      let item = this.versionVal;
      const fileType = item.name.split(".")[item.name.split(".").length - 1];
      if (
        fileType == "doc" ||
        fileType == "docx" ||
        fileType == "xlsx" ||
        fileType == "pptx"
      ) {
        //wps预览
        window.open(this.belloUrl, "_blank");
      } else if (fileType == "pdf") {
        //pdf预览
        window.open(this.belloUrl, "_blank");
      } else if (fileType == "png" || fileType == "jpg") {
        //图片预览
        window.open(this.belloUrl, "_blank");
      } else if (fileType) {
        this.$message.error("不支持预览");
      }
    },
  },
};
</script>