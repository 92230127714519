<template>
  <section>
    <el-upload
      class="upload-demo uploadChanged"
      drag
      ref="upload"
      :headers="myHeaders"
      :action="actionUrl"
      :file-list="fileInfoList"
      :auto-upload="false"
      :on-exceed="handleFileExceed"
      :on-change="handleFilesChange"
      :limit="10"
      accept=".docx, .doc, .xlsx, .xls, .ppt, .pptx, .PDF, .zip, .rar, .jpg, .png, .jpeg"
      multiple
      style="text-align: center"
    >
      <i class="el-icon-upload"></i>
      <div class="el-upload__text" style="line-height: 30px">
        将文件拖到此处，或
        <span class="tabHref">点击上传</span>
      </div>
    </el-upload>
    <div
      style="margin-top: 5px; font-size: 12px; line-height: 1.5; color: #606266"
    >
      支持扩展名：.doc .docx .pdf .jpg .png，文件不超过20M，单次只能上传1份文件
    </div>
  </section>
</template>
<script>
import { attachment } from "../../api/api";
import { ApiBaseUrl } from "../../api/http";
import Cookies from "js-cookie";
export default {
  props: ["id"],
  data() {
    return {
      myHeaders: {
        Authorization: Cookies.get("accessToken"),
      },
      actionUrl: ApiBaseUrl + "/api/file/uploadOne",
      fileInfoList: [],
    };
  },
  methods: {
    //   校验数量
    handleFileExceed() {
      this.$message.error("数量超出限制");
    },
    // 校验大小
    handleFilesChange(file, fileList) {
      // this.uploadFiles.files = [];
      //   fileList.forEach(i => this.uploadFiles.files.push(i.raw));
      // debugger
      const limitMessage = [];
      this.fileInfoList = fileList.filter((f) => {
        if (f.size / 1024 / 1024 > 20) {
          return void limitMessage.push(`文件 ${f.name} 超过20MB`);
        }

        return true;
      });
      if (limitMessage.length) {
        this.$message.error(limitMessage.join("\n"));
      }
    },
    //提交
    submitFiles() {
      return new Promise((resole) => {
        if (this.fileInfoList.length > 0) {
          const fd = new FormData();
          fd.append("resumeId", this.id);
          this.fileInfoList.forEach((f) => fd.append("files", f.raw));
          attachment(fd).then((res) => {
            this.$message.success(res.message);
            resole();
          });
        } else {
          this.$message.error("请选择文件！");
        }
      });
    },
  },
};
</script>
<style lang='scss' scoped>
.uploadChanged {
  .el-upload.el-upload--text {
    width: 100% !important;

    .el-upload-dragger {
      margin: 0;
      width: 100% !important;
    }
  }
}
</style>