<template>
  <section v-loading="isloading" style="height: 100%; background-color: #fff">
    <div
      class="box-title"
      style="overflow: hidden; position: relative; background-color: #fff"
      :style="{ padding: isborder, border: '15px solid rgb(255, 255, 255)' }"
    >
      <el-row
        :gutter="24"
        style="
          margin: 0;
          height: 100%;
          overflow: hidden;
          background-color: transparent;
        "
      >
        <div
          class="wrapperFoo topBox gradient-content"
          v-if="candidateProtect.length > 0"
        >
          <p
            class="gradient-content-header"
            style="height: 20px; padding: 10px"
          >
            <span
              style="
                background: #da3738;
                width: 20px;
                height: 20px;
                margin-right: 8px;
                border-radius: 50%;
                display: inline-block;
                text-align: center;
              "
            >
              <i
                style="
                  color: #fff;
                  font-size: 12px;
                  margin: auto;
                  line-height: 20px;
                "
                class="iconfont icon-yusan-shi"
              ></i> </span
            >候选人保护机制 X
            {{ candidateProtect.length }}
            <el-popover
              placement="bottom-start"
              trigger="hover"
              width="500"
              popper-class="quickPipeline"
            >
              <div>
                <div class="content">
                  <p style="font-weight: bold; margin-bottom: 5px">
                    根据公司管理规定，候选人保护机制如下：
                  </p>
                  &nbsp;
                  &nbsp;【简历推荐】阶段的候选人，保护期为30天；当反馈为待定时，保护期为60天；当反馈为客户淘汰或候选人拒绝时，则自动解除保护机制。<br />
                  &nbsp;
                  &nbsp;【客户面试】阶段的候选人，保护期为90天；当有多轮面试时，则保护期从最新一轮的‘面试时间’往后加90天；当反馈为待定或通过时，候选人永久保护；当反馈为客户淘汰或候选人拒绝时，则自动解除保护机制。<br />
                  &nbsp;
                  &nbsp;【Offer】阶段的候选人，永久保护；当反馈为拒绝时，则自动解除保护机制。<br />
                  &nbsp;
                  &nbsp;【入职】阶段的候选人，在试用期之前永久保护；过试用期后，则自动解除保护机制。<br />
                </div>
              </div>
              <template #reference>
                <i
                  class="iconfont icon icon-wenhao-xiangsu"
                  style="color: #898989; font-size: 14px"
                ></i>
              </template>
            </el-popover>
          </p>
          <ul class="lightList">
            <li v-if="resumeInfos.lockComName">
              <span class="dics1"></span>当前任职公司<span
                class="textDecoration"
                @click="linkToCom(resumeInfos.lockComId)"
                style="color: #526ecc; cursor: pointer"
                >【{{ resumeInfos.lockComName }}】</span
              >为已签约客户，请谨慎挖人
            </li>
            <li v-if="resumeInfos.lockProjectName && resumeInfos.isLocked">
              <span class="dics1"></span>正在<span
                class="textDecoration"
                @click="linkToProject(resumeInfos.lockProjectId)"
                style="color: #526ecc; cursor: pointer"
              >
                【{{ resumeInfos.lockProjectName }}】</span
              >
              项目中，由【<span>{{ candidateInfo.userName }}</span
              >】在【<span>{{ candidateInfo.createdTime }}</span
              >】操作状态为【<span>{{ candidateInfo.status }}</span
              >】，无法加入其他项目
              <span
                v-if="
                  (resumeInfos.lockProjectResumeUserId == useId || showLock) &&
                  hover &&
                  hover.hover
                "
                @click="unLock(resumeInfos.id)"
                class="el-icon-lock"
                style="color: red"
              ></span>
            </li>
          </ul>
        </div>
        <div class="wrapperFoo">
          <div class="lightBackground">
            <div class="personFoo1">
              <div>
                <span
                  class="fontColor textsecColor fr"
                  style="font-size: 12px; margin-top: 15px"
                >
                  <div style="margin-bottom: 5px; text-align: right">
                    <el-dropdown
                      v-if="hover && hover.hover"
                      trigger="click"
                      @command="clickStatus1"
                    >
                      <span class="el-dropdown-link button1" style="padding: 0">
                        <i
                          class="el-icon-phone"
                          style="
                            margin-right: 5px;
                            padding: 6px 4px;
                            background: #ececec;
                          "
                        ></i>
                        <span style="padding: 4px"
                          ><span>{{
                            resumeInfos.mobileStatus
                              ? resumeInfos.mobileStatus
                              : "电话标注"
                          }}</span>
                          <i class="el-icon-arrow-down el-icon--right"></i
                        ></span>
                      </span>
                      <el-dropdown-menu slot="dropdown" class="resumDropdown">
                        <el-dropdown-item
                          command="正常"
                          :style="
                            resumeInfos.mobileStatus == '正常'
                              ? 'color:red'
                              : ''
                          "
                          >正常</el-dropdown-item
                        >
                        <el-dropdown-item
                          command="空号"
                          :style="
                            resumeInfos.mobileStatus == '空号'
                              ? 'color:red'
                              : ''
                          "
                          >空号</el-dropdown-item
                        >
                        <el-dropdown-item
                          command="停机"
                          :style="
                            resumeInfos.mobileStatus == '停机'
                              ? 'color:red'
                              : ''
                          "
                          >停机</el-dropdown-item
                        >
                        <el-dropdown-item
                          command="非本人"
                          :style="
                            resumeInfos.mobileStatus == '非本人'
                              ? 'color:red'
                              : ''
                          "
                          >非本人</el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                    <span
                      class="button1"
                      style="padding: 0"
                      v-if="!hover.hover && resumeInfos.mobileStatus !== null"
                    >
                      <i
                        class="el-icon-phone"
                        style="
                          margin-right: 5px;
                          padding: 6px 4px;
                          background: #ececec;
                        "
                      ></i>
                      <span style="padding: 4px"
                        ><span>{{
                          resumeInfos.mobileStatus
                            ? resumeInfos.mobileStatus
                            : "电话标注"
                        }}</span>
                      </span>
                    </span>
                    <el-dropdown
                      v-if="hover && hover.hover"
                      trigger="click"
                      @command="clickStatus"
                    >
                      <span class="el-dropdown-link button1">
                        {{ resumeInfos.contactStatus
                        }}<span
                          v-if="
                            resumeInfos.contactStatus == '无状态' ? false : true
                          "
                          >{{ resumeInfos.contactRemark }}</span
                        ><i class="el-icon-arrow-down el-icon--right"></i>
                      </span>
                      <el-dropdown-menu slot="dropdown" class="resumDropdown">
                        <el-dropdown-item command="无状态"
                          >无状态</el-dropdown-item
                        >
                        <el-dropdown-item command="看机会"
                          >看机会</el-dropdown-item
                        >
                        <el-dropdown-item command="现状稳定"
                          >现状稳定</el-dropdown-item
                        >
                        <el-dropdown-item command="黑名单"
                          >黑名单</el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                    <span class="button1" v-else>
                      {{ resumeInfos.contactStatus }}
                      <span
                        v-if="
                          resumeInfos.contactStatus == '无状态' ? false : true
                        "
                        >{{ resumeInfos.contactRemark }}</span
                      >
                    </span>
                  </div>
                  <div style="line-height: 55px">
                    {{ resumeInfos.ownerName }}
                    <span>{{ resumeInfos.createdTime }}创建</span>
                    <el-divider direction="vertical"></el-divider>
                    <span class="fontColor textsecColor updateTime"
                      >{{ resumeInfos.lastModifiedTime }}更新</span
                    >
                    <el-divider direction="vertical"></el-divider>
                    <span
                      class="fontColor textsecColor fr"
                      style="font-size: 12px"
                      >简历ID：{{ resumeInfos.id }}</span
                    >
                  </div>
                </span>
              </div>

              <h3 class="resume_Title">
                <span v-html="resumeInfos.name"></span>
                <span v-if="resumeInfos.name_pinyin"
                  >({{ resumeInfos.name_pinyin }})
                </span>
                <span
                  class="fontColor"
                  style="font-size: 14px; font-weight: normal; color: #606266"
                  >( {{ resumeInfos.resumeType }} )</span
                >
              </h3>
              <span
                class="tabHref"
                style="margin-left: 35px"
                v-if="hover && hover.hover && similarCount > 0"
              >
                <i class="iconfont icon-rc_grxx"></i>
                {{ similarName }}等
                <span @click="goSimilarResume"
                  >{{ similarCount }}个相似人才></span
                >
              </span>
              <p>
                <span>
                  <el-tooltip
                    v-if="resumeInfos.recommendTimes > 0"
                    class="item"
                    effect="dark"
                    :content="
                      '候选人被推荐了' + resumeInfos.recommendTimes + '次'
                    "
                    placement="top-start"
                  >
                    <span
                      v-if="resumeInfos.recommendTimes > 0"
                      class="badgeIcon"
                      style="background: #4690ff"
                      ><span class="num">{{
                        resumeInfos.recommendTimes
                      }}</span></span
                    >
                  </el-tooltip>
                  <el-tooltip
                    v-if="resumeInfos.isLocked"
                    class="item"
                    effect="dark"
                    :content="
                      '候选人在【' +
                      resumeInfos.lockProjectName +
                      '】项目里已锁定'
                    "
                    placement="top-start"
                  >
                    <i
                      v-if="resumeInfos.isLocked"
                      class="badgeIcon iconfont fontColor1 icon-yisuoding"
                      :title="
                        '候选人在【' +
                        resumeInfos.lockProjectName +
                        '】项目里已锁定'
                      "
                    ></i
                  ></el-tooltip>
                  <el-tooltip
                    v-if="resumeInfos.secure"
                    class="item"
                    effect="dark"
                    content="人才保密中"
                    placement="top-start"
                  >
                    <i
                      v-if="resumeInfos.secure"
                      class="badgeIcon iconfont icon-mi"
                      style="color: #da3737"
                    ></i>
                  </el-tooltip>
                  <el-tooltip
                    v-if="resumeInfos.contactStatus == '联系中'"
                    class="item"
                    effect="dark"
                    :content="
                      resumeInfos.contactRemark
                        ? resumeInfos.contactRemark + '正在联系中'
                        : '正在联系中'
                    "
                    placement="top-start"
                  >
                    <i
                      v-if="resumeInfos.contactStatus == '联系中'"
                      class="
                        badgeIcon
                        iconfont
                        fontColor1
                        icon-zhengzailianxizhong
                      "
                      :title="
                        resumeInfos.contactRemark
                          ? resumeInfos.contactRemark + '正在联系中'
                          : '正在联系中'
                      "
                    ></i>
                  </el-tooltip>
                  <el-tooltip
                    v-if="resumeInfos.channel"
                    class="item"
                    effect="dark"
                    :content="'来源：' + resumeInfos.channel"
                    placement="top-start"
                  >
                    <i
                      v-if="resumeInfos.channel"
                      class="badgeIcon iconfont fontColor1"
                      :class="{
                        'icon-liepin':
                          resumeInfos.channel == '猎聘猎头端' ||
                          resumeInfos.channel == '猎聘HR端',
                        'icon-luru': resumeInfos.channel == '录入',
                        'icon-shangchuan':
                          resumeInfos.channel == '上传List简历' ||
                          resumeInfos.channel == '上传',
                        'icon-lingying':
                          resumeInfos.channel == '领英个人版' ||
                          resumeInfos.channel == '领英',
                        'icon-lagou': resumeInfos.channel == '拉勾',
                        'icon-BOSSzhipin': resumeInfos.channel == 'BOSS直聘',
                        'icon-qianchengwuyou':
                          resumeInfos.channel == '前程无忧',
                        'icon-zhilian':
                          resumeInfos.channel == '智联' ||
                          resumeInfos.channel == '卓聘',
                        'icon-wuyoujingying': resumeInfos.channel == '无忧精英',
                        'icon-maimai': resumeInfos.channel == '脉脉',
                        'icon-qi':
                          resumeInfos.channel == '插件' ||
                          resumeInfos.channel == '合并' ||
                          resumeInfos.channel == '58同城',
                      }"
                      :title="'来源：' + resumeInfos.channel"
                    ></i>
                  </el-tooltip>
                </span>
                <span class="fontColor textColor" v-if="resumeInfos.sex">
                  {{ resumeInfos.sex }}</span
                >
                <span class="fontColor textColor" v-if="resumeInfos.age > 0">
                  · {{ resumeInfos.age }}岁</span
                >
                <span class="fontColor textColor" v-if="resumeInfos.married">
                  · {{ resumeInfos.married }}</span
                >
                <span class="fontColor textColor" v-if="resumeInfos.experience">
                  · {{ resumeInfos.experience }}年工作经验</span
                >
                <template v-if="resumeInfos.nowLocationName">
                  ·
                  <span
                    class="fontColor"
                    v-html="resumeInfos.nowLocationName"
                  ></span>
                </template>
              </p>
              <!--        联系方式-->
              <div
                class="fontColor"
                v-if="
                  resumeInfos.mobile ||
                  resumeInfos.virtual_phone ||
                  resumeInfos.email ||
                  resumeInfos.wechatOrOther
                "
                style="margin-top: 10px"
              >
                <span
                  v-if="resumeInfos.email"
                  class="hoverCall"
                  style="margin-right: 25px"
                >
                  <i
                    style="font-size: 14px; color: #898989; margin-right: 2px"
                    class="iconfont icon-rc_yx"
                  ></i>
                  <span v-if="isShowContact"> *************</span>
                  <span v-else v-html="resumeInfos.email">
                    {{ resumeInfos.email }}</span
                  >
                </span>
                <span
                  style="margin-right: 25px"
                  v-if="resumeInfos.wechatOrOther"
                >
                  <i
                    style="font-size: 14px; color: #898989; margin-right: 8px"
                    class="iconfont icon-rc_wx"
                  ></i>
                  <span v-if="isShowContact"> *************</span>
                  <span v-else v-html="resumeInfos.wechatOrOther">
                    {{ resumeInfos.wechatOrOther }}</span
                  >
                </span>
                <span
                  v-if="resumeInfos.mobile || resumeInfos.virtual_phone"
                  class="hoverCall"
                  style="margin-right: 25px"
                >
                  <i class="iconfont icon-rc_sj" style="color: #898989"></i>
                  <span v-if="isShowContact"> *************</span>
                  <span
                    v-else
                    v-html="
                      resumeInfos.mobile
                        ? resumeInfos.mobile
                        : resumeInfos.virtual_phone
                    "
                  ></span>
                  <el-tag
                    sizi="mini"
                    v-if="
                      resumeInfos.mobile && resumeInfos.virtual_phone
                        ? false
                        : resumeInfos.virtual_phone
                        ? true
                        : false
                    "
                    style="line-height: 25px; height: 25px; font-size: 12px"
                    >虚拟号
                  </el-tag>
                </span>
                <el-tag
                  v-if="resumeInfos.offerOrEntryProjectResumeId"
                  sizi="mini"
                  style="
                    line-height: 25px;
                    height: 25px;
                    font-size: 12px;
                    color: #cc6666;
                    background: #fff5f4;
                    border: 1px #ffdddc solid;
                  "
                  >【offer】和【入职】的候选人联系方式暂时隐藏
                  <span
                    v-if="
                      (resumeInfos.offerOrEntryPermission || showUnlockLock) &&
                      hover &&
                      hover.hover
                    "
                    @click="
                      unLockContact(resumeInfos.offerOrEntryProjectResumeId)
                    "
                    class="el-icon-lock"
                    title="点击解锁"
                    style="color: #cc6666"
                  ></span>
                  <i
                    v-if="
                      (resumeInfos.offerOrEntryPermission || showUnlockLock) &&
                      hover &&
                      hover.hover
                    "
                    class="iconfont fontColor1"
                    :class="isShowContact ? 'icon-rc_eye2' : 'icon-rc_eye1'"
                    title="您有权查看该候选人的联系方式"
                    @click="hideContact"
                    style="margin-left: 5px"
                  ></i>
                </el-tag>
              </div>
              <!--工作情况-->
              <div
                v-if="employmentsList.length > 0"
                class="workStatus clearfix"
                style="margin: 20px 0"
              >
                <el-col :span="12" style="padding-left: 0">
                  <div
                    v-for="(i, index) in employmentsList"
                    :key="i.id"
                    class="lineH30"
                    v-if="index < 3"
                  >
                    <el-popover
                      placement="right"
                      width="400"
                      trigger="hover"
                      :open-delay="2000"
                    >
                      <div>
                        <h3>企业信息</h3>
                        <p class="educationStyle" v-if="companyFoo.name">
                          {{ companyFoo.name }}
                        </p>
                        <p>
                          <span v-if="companyFoo.country"
                            >{{ companyFoo.country }}
                          </span>
                          <span v-if="companyFoo.city">
                            / {{ companyFoo.city }} </span
                          ><span v-if="companyFoo.industry">
                            / {{ companyFoo.industry }}</span
                          ><span v-if="companyFoo.scale">
                            / {{ companyFoo.scale }}</span
                          >
                        </p>
                        <p
                          style="color: #999999"
                          :title="showOrHide"
                          :class="{ moreRow: isMoreRow }"
                          @click="clickShowAll"
                        >
                          {{ companyFoo.profile }}
                        </p>
                      </div>
                      <el-button
                        @mouseover.native="belloCompany(i.company)"
                        @mouseout.native="clearbelloCompany()"
                        type="text"
                        class="linkButton resumeSpan btnActive"
                        icon="icon iconfont iconsousuo"
                        slot="reference"
                        :title="i.company + ' · ' + i.title"
                      >
                        <span class="underline" v-html="i.company">{{
                          i.company
                        }}</span>
                        · <span class="textColor" v-html="i.title"> </span>
                      </el-button>
                    </el-popover>
                    <span
                      class="fontColor resumeSpan textsecColor"
                      style="
                        font-size: 13px;
                        padding-left: 20px;
                        line-height: 13px;
                      "
                      v-if="i.startDate || i.endDate || i.isSoFar"
                    >
                      {{ i.startDate ? i.startDate : "-" }} 至
                      {{ i.isSoFar ? "至今" : i.endDate ? i.endDate : "-" }}
                    </span>
                  </div>
                </el-col>
                <el-col :offset="1" :span="11" style="padding-left: 0">
                  <div
                    v-for="(i, index) in educationsList"
                    :key="i.id"
                    class="text-overflows lineH30"
                    v-if="index < 3"
                  >
                    <el-popover
                      placement="right"
                      width="400"
                      trigger="hover"
                      :open-delay="2000"
                    >
                      <div>
                        <h3>院校信息</h3>
                        <div style="width: 40%; margin: 0 auto">
                          <img
                            style="width: 100%"
                            alt="暂无图片……"
                            :src="`https://assets.belloai.com/${SchoolFoo.logo_url}`"
                          />
                        </div>
                        <p class="educationStyle" v-if="SchoolFoo.foreign_name">
                          {{ SchoolFoo.foreign_name }}
                        </p>
                        <p>
                          {{ SchoolFoo.type }} / {{ SchoolFoo.college_type }} /
                          {{ SchoolFoo.create_at }}建校
                        </p>
                        <p v-if="SchoolFoo.international_ranking">
                          <span
                            style="
                              padding: 10px;
                              color: rgb(48, 135, 246);
                              display: inline-block;
                              background: rgb(238, 245, 254);
                            "
                            >世界排名{{ SchoolFoo.international_ranking }}</span
                          >
                        </p>
                        <p
                          style="color: #999999"
                          :title="showOrHide"
                          :class="{ moreRow: isMoreRow }"
                          @click="clickShowAll"
                        >
                          {{ SchoolFoo.profile }}
                        </p>
                        <p class="educationStyle" style="margin-top: 20px">
                          重点学科
                        </p>
                        <p>
                          {{
                            SchoolFoo.major ? SchoolFoo.major.join("、") : ""
                          }}
                        </p>
                      </div>
                      <el-button
                        @mouseover.native="belloSchool(i.school)"
                        @mouseout.native="clearbelloSchool()"
                        type="text"
                        class="linkButton text-overflows btnActive"
                        slot="reference"
                      >
                        <span class="underline" v-html="i.school"></span>
                      </el-button>
                    </el-popover>
                    <span style="font-size: 13px">
                      · <span class="textColor" v-html="i.education"></span>
                      <span
                        class="fontColor textsecColor"
                        style="padding-left: 20px; font-size: 13px"
                        v-if="i.startDate || i.endDate || i.isSoFar"
                      >
                        {{ i.startDate ? i.startDate : "-" }} 至
                        {{ i.isSoFar ? "至今" : i.endDate ? i.endDate : "-" }}
                      </span>
                    </span>
                  </div>
                </el-col>
              </div>
              <div
                :style="employmentsList.length == 0 ? 'margin-top:15px' : ''"
                class="tagList_box clearfix"
              >
                <div
                  style="max-width: 95%"
                  :class="{ 'text-overflows': showMoreTag }"
                >
                  <span
                    v-for="i in profileLabels"
                    :key="i.id"
                    :style="
                      i.bordered
                        ? { 'border-color': i.color, color: i.color }
                        : {}
                    "
                    v-html="i.name"
                  ></span>
                </div>
                <span
                  class="showMoreT"
                  v-if="hasMoreTags"
                  @click="showMoreTag = !showMoreTag"
                >
                  <i
                    :class="
                      showMoreTag ? 'el-icon-arrow-down' : 'el-icon-arrow-up'
                    "
                  ></i>
                  {{ showMoreTag ? "展开" : "收起" }}</span
                >
              </div>
            </div>
          </div>
          <div class="transparentBox"></div>
        </div>
        <div class="wrapperFoo1 clearfix">
          <!--        简历详情，简历原文, 附件-->
          <el-col
            :span="16"
            style="height: 100%; border-right: 10px solid #fff; padding: 0"
          >
            <div class="resume" style="margin-bottom: 10px">
              <div class="btnList" style="padding: 0">
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    background: #f5f5f5;
                    padding: 7px 15px;
                  "
                >
                  <div
                    style="
                      font-size: 14px;
                      color: #333;
                      font-weight: bold;
                      line-height: 1;
                    "
                  >
                    <i class="iconfont iconfont1 icon-jinengbiaoqian"></i>
                    <span class="titlePosition">技能标签</span>
                  </div>
                  <span
                    v-show="hover && hover.hover"
                    @click.stop="addIcon"
                    style="cursor: pointer"
                    class="activeHover"
                  >
                    <i class="el-icon-edit"></i
                    >{{
                      skillLabels.length > 0 || customLabels.length > 0
                        ? " 编辑标签"
                        : " 新增标签"
                    }}
                  </span>
                </div>
                <div style="padding: 13px 8px">
                  <span
                    v-if="skillLabels.length > 0"
                    class="lableBtn"
                    :style="
                      i.bordered
                        ? { 'border-color': i.color, color: i.color }
                        : {}
                    "
                    v-for="i in skillLabels"
                    :key="i.id"
                    :title="
                      i.generateMode == 'MANUAL'
                        ? '顾问手动标签'
                        : '系统自动标签'
                    "
                    style="cursor: pointer"
                  >
                    <i
                      v-if="i.generateMode == 'MANUAL'"
                      class="icon el-icon-user"
                    ></i>
                    <i
                      v-if="i.generateMode == 'RULE_BASED'"
                      class="icon el-icon-monitor"
                    ></i>
                    <span v-html="i.name"></span></span
                  >
                  <span
                    v-if="customLabels.length > 0"
                    class="lableBtn"
                    v-for="i in customLabels"
                    :key="i.id"
                    style="background: #ff5b5b; color: #fff"
                  >
                    <i class="icon el-icon-user"></i>
                    <span v-html="i.name"></span></span
                  >
                  <span
                    v-if="skillLabels.length == 0 && customLabels.length == 0"
                    class="lableBtn"
                    style="border: 0; height: 21px"
                    >暂无标签</span
                  >
                </div>
              </div>
            </div>
            <div class="resumeDetail">
              <el-tabs v-model="activeNameDetail" @tab-click="handleClick1">
                <el-tab-pane label="简历详情" name="first">
                  <div
                    class="gradient-content"
                    v-if="
                      candidateProtect.length +
                        risks.length +
                        advantages.length >
                      0
                    "
                  >
                    <el-collapse v-model="activeNames">
                      <el-collapse-item
                        :name="candidateProtect.length ? '2' : '1'"
                        v-if="risks.length > 0"
                      >
                        <template slot="title">
                          <p class="gradient-content-header">
                            <i
                              style="color: #7f55d4"
                              class="iconfont icon-fengxian"
                            ></i
                            >风险提示 X {{ risks.length }}
                            <span
                              class="btn fx"
                              v-for="i in risks"
                              :key="i.id"
                              >{{ i.name }}</span
                            >
                          </p>
                        </template>
                        <ul class="lightList">
                          <li v-for="i in risks" :key="i.id">
                            <span class="dics2"></span>{{ i.message }}
                          </li>
                        </ul>
                      </el-collapse-item>
                      <el-collapse-item
                        :name="
                          candidateProtect.length
                            ? '3'
                            : risks.length
                            ? '3'
                            : '1'
                        "
                        v-if="advantages.length > 0"
                      >
                        <template slot="title">
                          <p class="gradient-content-header">
                            <i
                              style="color: #ff7f57"
                              class="iconfont icon-jianliliangdian"
                            ></i
                            >简历亮点 X {{ advantages.length }}
                            <span
                              class="btn jl"
                              v-for="i in advantageNames"
                              :key="i"
                              >{{ i }}</span
                            >
                          </p>
                        </template>
                        <ul class="lightList">
                          <li v-for="i in advantages" :key="i.id">
                            <span class="dics"></span>{{ i.message }}
                          </li>
                        </ul>
                      </el-collapse-item>
                    </el-collapse>
                  </div>
                  <p class="resumeTit">
                    <i class="iconfont iconfont1 icon-rc_grxx"></i>
                    <span class="titlePosition">个人信息</span>
                  </p>
                  <div
                    class="clearfix resumeFoo"
                    style="margin-left: 8px; padding: 15px 0; font-size: 13px"
                  >
                    <el-col
                      class="fontColor text-overflows contentBox"
                      @mouseenter.native="flag && showEdit('firstDegree')"
                      @mouseleave.native="flag && hideEdit('firstDegree')"
                      :span="12"
                      v-if="resumeInfos.firstDegree"
                    >
                      <span class="leftTit">第一学历：</span>
                      <span style="color: #333333">
                        {{
                          resumeInfos.firstDegree
                            ? resumeInfos.firstDegree
                            : "-"
                        }}
                      </span>
                      <editResumeList
                        class="resumeEditIcon"
                        ref="firstDegree"
                        v-show="hover && hover.hover"
                        type="firstDegree"
                        :isPoppoverShow="isPoppoverShow"
                        :isEditDetail="true"
                        :resumeInfoList="resumeInfos"
                        @editIcon="editIcon"
                        @hideEditIcon="hideEditIcon"
                        @saveChanged="
                          loadResumeDetils(resumeDetailId || resumeId)
                        "
                      ></editResumeList>
                    </el-col>
                    <el-col
                      class="fontColor text-overflows contentBox"
                      :span="12"
                      v-if="resumeInfos.age"
                    >
                      <span class="leftTit">年龄：</span>
                      <span style="color: #333333">
                        {{ resumeInfos.age ? resumeInfos.age + "岁" : "-" }}
                      </span>
                    </el-col>
                    <el-col
                      class="fontColor text-overflows contentBox"
                      :span="12"
                      v-if="resumeInfos.experience"
                    >
                      <span class="leftTit">工作年限：</span>
                      <span style="color: #333333">
                        {{
                          resumeInfos.experience
                            ? resumeInfos.experience + "年"
                            : "-"
                        }}
                      </span>
                    </el-col>
                    <el-col
                      class="fontColor contentBox isp_card"
                      @mouseenter.native="flag && showEdit('functionName')"
                      @mouseleave.native="flag && hideEdit('functionName')"
                      :span="12"
                      v-if="resumeInfos.functionName"
                    >
                      <span class="leftTit">职能：</span>
                      <span
                        v-if="resumeInfos.functionName"
                        class="text-overflows maxW_R"
                        :title="vocationName"
                        v-html="resumeInfos.functionName"
                      >
                      </span>
                      <span v-else>-</span>
                      <editResumeList
                        class="resumeEditIcon"
                        ref="functionName"
                        type="functionName"
                        v-show="hover && hover.hover"
                        :isPoppoverShow="isPoppoverShow"
                        :isEditDetail="true"
                        :resumeInfoList="resumeInfos"
                        :ColumnPosList="ColumnPosList"
                        :functionName="functionIdsList"
                        @editIcon="editIcon"
                        @hideEditIcon="hideEditIcon"
                        @saveChanged="
                          loadResumeDetils(resumeDetailId || resumeId)
                        "
                      ></editResumeList>
                    </el-col>
                    <el-col
                      class="fontColor contentBox"
                      @mouseenter.native="flag && showEdit('vocationName')"
                      @mouseleave.native="flag && hideEdit('vocationName')"
                      :span="12"
                      v-if="resumeInfos.vocationName"
                    >
                      <span class="leftTit">行业：</span>
                      <span
                        v-if="resumeInfos.vocationName"
                        class="text-overflows maxW_R"
                        :title="resumeInfos.vocationName"
                        v-html="resumeInfos.vocationName"
                      >
                      </span>
                      <span v-else>-</span>
                      <editResumeList
                        v-show="hover && hover.hover"
                        class="resumeEditIcon"
                        ref="vocationName"
                        type="vocationName"
                        :isPoppoverShow="isPoppoverShow"
                        :isEditDetail="true"
                        :resumeInfoList="resumeInfos"
                        :ColumnInList="ColumnInList"
                        :vocationName="vocationIdsList"
                        @editIcon="editIcon"
                        @hideEditIcon="hideEditIcon"
                        @saveChanged="
                          loadResumeDetils(resumeDetailId || resumeId)
                        "
                      ></editResumeList>
                    </el-col>
                    <el-col
                      class="fontColor contentBox"
                      @mouseenter.native="flag && showEdit('current_salary')"
                      @mouseleave.native="flag && hideEdit('current_salary')"
                      :span="12"
                      v-if="resumeInfos.current_salary"
                    >
                      <span class="leftTit">薪酬：</span>
                      <span style="color: #333333">
                        {{
                          resumeInfos.current_salary
                            ? resumeInfos.current_salary
                            : "-"
                        }}
                      </span>
                      <editResumeList
                        class="resumeEditIcon"
                        v-show="hover && hover.hover"
                        ref="current_salary"
                        type="current_salary"
                        :isPoppoverShow="isPoppoverShow"
                        :isEditDetail="true"
                        :resumeInfoList="resumeInfos"
                        @editIcon="editIcon"
                        @hideEditIcon="hideEditIcon"
                        @saveChanged="
                          loadResumeDetils(resumeDetailId || resumeId)
                        "
                      ></editResumeList>
                    </el-col>
                    <el-col
                      class="fontColor contentBox"
                      @mouseenter.native="flag && showEdit('qq')"
                      @mouseleave.native="flag && hideEdit('qq')"
                      :span="12"
                      v-if="resumeInfos.qq"
                    >
                      <span class="leftTit">QQ：</span>
                      <span
                        style="color: #333333"
                        v-if="
                          resumeInfos.offerOrEntryProjectResumeId &&
                          !resumeInfos.offerOrEntryPermission
                        "
                      >
                        *************</span
                      >
                      <span
                        v-else
                        style="color: #333333"
                        v-html="resumeInfos.qq ? resumeInfos.qq : '-'"
                      >
                        {{ resumeInfos.qq ? resumeInfos.qq : "-" }}
                      </span>
                      <editResumeList
                        class="resumeEditIcon"
                        ref="qq"
                        type="qq"
                        v-show="hover && hover.hover"
                        :isPoppoverShow="isPoppoverShow"
                        :isEditDetail="true"
                        :resumeInfoList="resumeInfos"
                        @editIcon="editIcon"
                        @hideEditIcon="hideEditIcon"
                        @saveChanged="
                          loadResumeDetils(resumeDetailId || resumeId)
                        "
                      ></editResumeList>
                    </el-col>
                    <el-col
                      class="fontColor contentBox"
                      @mouseenter.native="flag && showEdit('linked_in')"
                      @mouseleave.native="flag && hideEdit('linked_in')"
                      :span="12"
                      v-if="resumeInfos.linked_in"
                    >
                      <span class="leftTit">领英：</span>
                      <span
                        style="color: #333333"
                        class="text-overflows maxW_R"
                        v-if="
                          resumeInfos.offerOrEntryProjectResumeId &&
                          !resumeInfos.offerOrEntryPermission
                        "
                      >
                        *************</span
                      >
                      <span
                        v-else
                        style="color: #333333"
                        class="text-overflows maxW_R"
                        v-html="
                          resumeInfos.linked_in ? resumeInfos.linked_in : '-'
                        "
                        :title="resumeInfos.linked_in1"
                      >
                        {{
                          resumeInfos.linked_in ? resumeInfos.linked_in : "-"
                        }}
                      </span>
                      <editResumeList
                        class="resumeEditIcon"
                        v-show="hover && hover.hover"
                        ref="linked_in"
                        type="linked_in"
                        :isPoppoverShow="isPoppoverShow"
                        :isEditDetail="true"
                        :resumeInfoList="resumeInfos"
                        @editIcon="editIcon"
                        @hideEditIcon="hideEditIcon"
                        @saveChanged="
                          loadResumeDetils(resumeDetailId || resumeId)
                        "
                      ></editResumeList>
                    </el-col>
                  </div>
                  <!--工作经历-->
                  <!--求职意向-->
                  <p :span="24" class="resumeTit">
                    <i class="iconfont iconfont1 icon-rc_qzyx"></i>
                    <span class="titlePosition">求职意向</span>
                  </p>
                  <div
                    class="clearfix"
                    style="margin-left: 8px; padding: 15px 0; font-size: 13px"
                  >
                    <el-col
                      class="fontColor contentBox"
                      @mouseenter.native="flag && showEdit('jobStatus')"
                      @mouseleave.native="flag && hideEdit('jobStatus')"
                      :span="12"
                      v-if="resumeInfos.jobStatus"
                    >
                      <span class="leftTit"> 求职状态：</span>
                      <span style="color: #333333">
                        {{
                          resumeInfos.jobStatus ? resumeInfos.jobStatus : "-"
                        }}
                      </span>
                      <editResumeList
                        class="resumeEditIcon"
                        ref="jobStatus"
                        type="jobStatus"
                        :isPoppoverShow="isPoppoverShow"
                        :isEditDetail="true"
                        :resumeInfoList="resumeInfos"
                        @editIcon="editIcon"
                        v-show="hover && hover.hover"
                        @hideEditIcon="hideEditIcon"
                        @saveChanged="
                          loadResumeDetils(resumeDetailId || resumeId)
                        "
                      ></editResumeList>
                    </el-col>
                    <el-col
                      class="fontColor contentBox"
                      @mouseenter.native="flag && showEdit('VocationList')"
                      @mouseleave.native="flag && hideEdit('VocationList')"
                      :span="12"
                      v-if="VocationList && VocationList.length > 0"
                    >
                      <span class="leftTit">期望行业：</span>
                      <span
                        v-if="VocationList && VocationList.length > 0"
                        style="color: #333333; max-width: calc(100% - 105px)"
                        class="text-overflows"
                        :title="VocationTitle"
                        v-html="VocationList.join('；')"
                      >
                      </span>
                      <span v-else>-</span>
                      <editResumeList
                        class="resumeEditIcon"
                        ref="VocationList"
                        type="VocationList"
                        :isPoppoverShow="isPoppoverShow"
                        :isEditDetail="true"
                        :resumeInfoList="resumeInfos"
                        :vocationList="VocationIdList"
                        :ColumnInList="ColumnInList"
                        @editIcon="editIcon"
                        v-show="hover && hover.hover"
                        @hideEditIcon="hideEditIcon"
                        @saveChanged="
                          loadResumeDetils(resumeDetailId || resumeId)
                        "
                      ></editResumeList>
                    </el-col>
                    <el-col
                      class="fontColor contentBox"
                      @mouseenter.native="flag && showEdit('locationList')"
                      @mouseleave.native="flag && hideEdit('locationList')"
                      :span="12"
                      v-if="locationList.length > 0"
                    >
                      <span class="leftTit">期望城市：</span>
                      <span
                        style="color: #333333; max-width: calc(100% - 105px)"
                        class="text-overflows"
                        v-if="locationList.length > 0"
                        :title="locationTitle"
                        v-html="locationList.join('；')"
                      ></span>
                      <span v-else>-</span>
                      <editResumeList
                        class="resumeEditIcon"
                        ref="locationList"
                        type="locationList"
                        :isPoppoverShow="isPoppoverShow"
                        :isEditDetail="true"
                        :resumeInfoList="resumeInfos"
                        :locationList="locationIdList"
                        :ColumnCityList="ColumnCityList"
                        @editIcon="editIcon"
                        v-show="hover && hover.hover"
                        @hideEditIcon="hideEditIcon"
                        @saveChanged="
                          loadResumeDetils(resumeDetailId || resumeId)
                        "
                      ></editResumeList>
                    </el-col>
                    <el-col
                      class="fontColor contentBox"
                      @mouseenter.native="flag && showEdit('title')"
                      @mouseleave.native="flag && hideEdit('title')"
                      :span="12"
                      v-if="resumeInfos.title"
                    >
                      <span class="leftTit">期望职位：</span>
                      <span
                        class="text-overflows maxW_R"
                        @mouseover="showResumeInfosTitle(resumeInfos.title)"
                        :title="resumeInfosTitle"
                        v-html="resumeInfos.title"
                        v-if="resumeInfos.title"
                      >
                      </span>
                      <span v-else>-</span>
                      <editResumeList
                        class="resumeEditIcon"
                        ref="title"
                        type="title"
                        :isPoppoverShow="isPoppoverShow"
                        :isEditDetail="true"
                        :resumeInfoList="resumeInfos"
                        @editIcon="editIcon"
                        @hideEditIcon="hideEditIcon"
                        v-show="hover && hover.hover"
                        @saveChanged="
                          loadResumeDetils(resumeDetailId || resumeId)
                        "
                      ></editResumeList>
                    </el-col>
                    <el-col
                      class="fontColor text-overflows contentBox"
                      @mouseenter.native="flag && showEdit('salary')"
                      @mouseleave.native="flag && hideEdit('salary')"
                      :span="12"
                      v-if="resumeInfos.isMianYi || resumeInfos.lowAimSalary"
                    >
                      <span class="leftTit">期望薪资：</span>
                      <span v-if="resumeInfos.isMianYi" style="color: #333333"
                        >面议</span
                      >
                      <span
                        v-else-if="resumeInfos.lowAimSalary"
                        style="color: #333333"
                        :title="salaryTitle"
                      >
                        {{ resumeInfos.lowAimSalary }}
                        <span v-if="resumeInfos.highAimSalary">-</span>
                        {{ resumeInfos.highAimSalary }}
                        <span v-if="resumeInfos.lowAimSalary">元/月</span>
                      </span>
                      <span v-else>-</span>
                      <editResumeList
                        class="resumeEditIcon"
                        ref="salary"
                        type="salary"
                        :isPoppoverShow="isPoppoverShow"
                        :isEditDetail="true"
                        :resumeInfoList="resumeInfos"
                        @editIcon="editIcon"
                        v-show="hover && hover.hover"
                        @hideEditIcon="hideEditIcon"
                        @saveChanged="
                          loadResumeDetils(resumeDetailId || resumeId)
                        "
                      ></editResumeList>
                    </el-col>
                  </div>
                  <p :span="24" class="resumeTit">
                    <i class="iconfont iconfont1 icon-rc_gzjl"></i>
                    <span class="titlePosition"
                      >工作经历
                      <span v-if="resumeInfos.experience" style="color: #e58a00"
                        >（{{ resumeInfos.experience }}年）</span
                      ></span
                    >
                  </p>
                  <div
                    style="padding-bottom: 20px"
                    v-if="employmentsList.length > 0"
                  >
                    <div
                      style="margin: 0 20px; padding: 10px 0"
                      class="clearfix contentBox1 showcolor"
                      v-for="(i, index) in employmentsList"
                      :key="'employmentsList' + i.id"
                    >
                      <div
                        style="padding-right: 20px; color: #999"
                        class="fontColor1"
                      >
                        <el-popover
                          placement="right"
                          width="400"
                          trigger="hover"
                          :open-delay="2000"
                        >
                          <div>
                            <h3>企业信息</h3>
                            <p class="educationStyle" v-if="companyFoo.name">
                              {{ companyFoo.name }}
                            </p>
                            <p>
                              <span v-if="companyFoo.country"
                                >{{ companyFoo.country }}
                              </span>
                              <span v-if="companyFoo.city">
                                / {{ companyFoo.city }} </span
                              ><span v-if="companyFoo.industry">
                                / {{ companyFoo.industry }}</span
                              ><span v-if="companyFoo.scale">
                                / {{ companyFoo.scale }}</span
                              >
                            </p>
                            <p
                              style="color: #999999"
                              :title="showOrHide"
                              :class="{ moreRow: isMoreRow }"
                              @click="clickShowAll"
                            >
                              {{ companyFoo.profile }}
                            </p>
                          </div>

                          <el-button
                            type="text"
                            @mouseover.native="belloCompany(i.company)"
                            @mouseout.native="clearbelloCompany()"
                            class="linkButton"
                            icon="icon iconfont iconsousuo"
                            slot="reference"
                            style="
                              margin-right: 15px;
                              line-height: 12px;
                              font-size: 14px;
                            "
                          >
                            <fixed-length-span
                              :width="250"
                              :text="i.company"
                            ></fixed-length-span>
                          </el-button>
                        </el-popover>
                        <span
                          :title="i.vocationName"
                          class="industry text-overflows"
                          >{{ i.vocationName }}</span
                        >
                        <span
                          style="padding：0 20px;"
                          v-if="i.startDate || i.endDate || i.isSoFar"
                          :title="
                            i.startDate +
                            ' 至 ' +
                            (i.isSoFar ? '至今' : i.endDate)
                          "
                        >
                          ( {{ i.startDate }} 至
                          {{ i.isSoFar ? "至今" : i.endDate }}
                          {{
                            totalExp(i.startDate, i.endDate, i.isSoFar)
                              ? "," +
                                totalExp(i.startDate, i.endDate, i.isSoFar)
                              : ""
                          }})</span
                        >
                        <i
                          v-show="hover && hover.hover"
                          class="el-icon-edit editIcon resumeEditIcon"
                          style="
                            margin-top: 12px;
                            margin-left: 8px;
                            margin-bottom: 8px;
                          "
                          @click="editExperience(index, 'employments')"
                        ></i>
                        <i
                          class="el-icon-document-copy editIcon resumeEditIcon"
                          v-show="hover && hover.hover"
                          style="
                            margin-top: 12px;
                            margin-left: 8px;
                            margin-bottom: 8px;
                          "
                          @click="copyContent(i.company)"
                        ></i>
                      </div>
                      <div class="jobTitle">
                        <p style="display: inline-block" v-html="i.title"></p>
                        <span
                          style="max-width: 23%"
                          v-if="i.jobLocation"
                          :title="i.jobLocation"
                          class="text-overflows"
                          >{{ i.jobLocation }}</span
                        ><span
                          v-if="i.salary && i.jobLocation"
                          style="margin: 0"
                          ><el-divider direction="vertical"></el-divider></span
                        ><span :style="i.jobLocation ? 'margin:0' : ''">{{
                          i.salary ? i.salary : ""
                        }}</span>
                      </div>
                      <el-row :gutter="24" style="font-size: 13px">
                        <el-col
                          class="fontColor contentBox"
                          :span="12"
                          v-if="i.leader"
                          ><div class="leftTit">汇报对象：</div>
                          <span
                            :title="i.leader"
                            class="text-overflows maxW_R"
                            >{{ i.leader ? i.leader : "-" }}</span
                          >
                        </el-col>
                        <el-col
                          class="fontColor contentBox"
                          :span="12"
                          v-if="i.department"
                          ><span class="leftTit">部门：</span>
                          <span
                            :title="i.department"
                            class="text-overflows maxW_R"
                            >{{ i.department ? i.department : "-" }}</span
                          >
                        </el-col>
                        <el-col
                          class="fontColor contentBox"
                          :span="12"
                          v-if="i.underlingNumber"
                        >
                          <span class="leftTit">下属人数：</span
                          ><span style="color: #333333">{{
                            i.underlingNumber ? i.underlingNumber : "-"
                          }}</span></el-col
                        >
                        <el-col
                          class="fontColor contentBox"
                          :span="24"
                          v-if="i.reasonOfLeaving"
                          ><p class="leftTit" style="white-space: nowrap">
                            离职原因：
                          </p>
                          <span style="color: #333333">{{
                            i.reasonOfLeaving ? i.reasonOfLeaving : "-"
                          }}</span></el-col
                        >
                        <el-col
                          class="fontColor contentBox"
                          :span="24"
                          v-if="i.jobNature"
                          ><span class="leftTit" style="white-space: nowrap"
                            >工作性质：</span
                          >
                          <span style="color: #333333">{{
                            i.jobNature ? i.jobNature : "-"
                          }}</span>
                        </el-col>
                        <el-col
                          class="fontColor contentBox"
                          :span="24"
                          v-if="i.summaryText"
                        >
                          <p class="leftTit" style="white-space: nowrap">
                            工作描述：
                          </p>
                          <span
                            style="color: #333333"
                            v-html="i.summaryText"
                            v-if="i.summaryText"
                          ></span>
                          <span v-else>-</span>
                        </el-col>
                        <el-col
                          class="fontColor contentBox"
                          :span="24"
                          v-if="i.achievementText"
                        >
                          <p style="white-space: nowrap">工作业绩：</p>
                          <span
                            style="color: #333333"
                            v-html="i.achievementText"
                            v-if="i.achievementText"
                          ></span>
                          <span v-else>-</span>
                        </el-col>
                      </el-row>
                    </div>
                  </div>
                  <div v-if="employmentsList.length == 0" class="nodata">
                    <p style="text-align: center">暂无</p>
                  </div>

                  <!--项目经历-->
                  <p :span="24" class="resumeTit">
                    <i class="iconfont iconfont1 icon-rc_xq_xm"></i>
                    <span class="titlePosition">项目经历</span>
                  </p>
                  <div
                    style="padding-bottom: 20px"
                    v-if="projectsList.length > 0"
                  >
                    <div
                      class="clearfix contentBox1 showcolor"
                      style="margin: 0 20px; padding: 15px 0"
                      v-for="(i, index) in projectsList"
                      :key="'projectsList' + i.id"
                    >
                      <div
                        style="display: flex; align-items: center; height: 28px"
                      >
                        <el-popover
                          placement="right"
                          width="500"
                          trigger="hover"
                          :open-delay="2000"
                          v-if="i.projectName"
                        >
                          <div>
                            <h3>项目信息</h3>
                            <p class="educationStyle" v-if="ProjectFoo.name">
                              {{ ProjectFoo.name }}
                            </p>
                            <p>
                              <span v-if="ProjectFoo.city"
                                >{{ ProjectFoo.city }}
                              </span>
                              <span v-if="ProjectFoo.industry">
                                / {{ ProjectFoo.industry }}
                              </span>
                            </p>
                            <p v-if="ProjectFoo.profile">项目描述</p>
                            <p>{{ ProjectFoo.profile }}</p>
                          </div>
                          <el-button
                            type="text"
                            class="linkButton"
                            icon="icon iconfont iconsousuo"
                            slot="reference"
                            @mouseover.native="projectFoo(i.projectName)"
                            @mouseout.native="clearbelloProject()"
                            v-if="i.projectName"
                            style="
                              margin-right: 15px;
                              font-size: 16px;
                              font-weight: bold;
                              color: #333 !important;
                            "
                          >
                            <fixed-length-span
                              :width="200"
                              :text="i.projectName"
                              :title="i.projectName"
                            ></fixed-length-span>
                          </el-button>
                        </el-popover>
                        <span
                          style="color: #999"
                          v-if="i.startDate || i.endDate || i.isSoFar"
                        >
                          ( {{ i.startDate }} 至
                          {{ i.isSoFar ? "至今" : i.endDate }})</span
                        >
                        <i
                          class="el-icon-edit editIcon resumeEditIcon"
                          v-show="hover && hover.hover"
                          style="
                            margin-top: 12px;
                            margin-left: 8px;
                            margin-bottom: 8px;
                          "
                          @click="editExperience(index, 'projects')"
                        ></i>
                        <i
                          class="el-icon-document-copy editIcon resumeEditIcon"
                          v-show="hover && hover.hover"
                          style="
                            margin-top: 12px;
                            margin-left: 8px;
                            margin-bottom: 8px;
                          "
                          @click="copyContent(i.projectName)"
                        ></i>
                      </div>
                      <p class="projectTitle">
                        {{ i.company }}
                        <span
                          style="padding-left: 20px"
                          v-html="i.title"
                        ></span>
                      </p>
                      <div style="font-size: 13px">
                        <el-col
                          class="fontColor delpaddleft contentBox"
                          :span="24"
                          v-if="i.projectDescriptionText"
                        >
                          <p style="white-space: nowrap">项目描述：</p>
                          <span
                            style="color: #333333"
                            v-html="
                              i.projectDescriptionText
                                ? i.projectDescriptionText
                                : '-'
                            "
                          ></span
                        ></el-col>
                        <el-col
                          class="fontColor delpaddleft contentBox"
                          :span="24"
                          v-if="i.responsibilityText"
                          ><p style="white-space: nowrap">项目职责：</p>
                          <span
                            style="color: #333333"
                            v-html="
                              i.responsibilityText ? i.responsibilityText : '-'
                            "
                          ></span
                        ></el-col>
                        <el-col
                          class="fontColor delpaddleft contentBox"
                          :span="24"
                          v-if="i.achievementText"
                          ><p style="white-space: nowrap">项目业绩：</p>
                          <span
                            style="color: #333333"
                            v-html="i.achievementText ? i.achievementText : '-'"
                          ></span
                        ></el-col>
                      </div>
                    </div>
                  </div>
                  <div v-if="projectsList.length == 0" class="nodata">
                    <p style="text-align: center">暂无</p>
                  </div>

                  <!--                教育经历-->
                  <p :span="24" class="resumeTit">
                    <i class="iconfont iconfont1 icon-rc_jyjl"></i>
                    <span class="titlePosition">教育经历</span>
                  </p>
                  <div style="padding: 15px 0" v-if="educationsList.length > 0">
                    <div
                      class="clearfix contentBox1 showcolor"
                      style="
                        line-height: 35px;
                        margin: 0 20px;
                        display: flex;
                        align-items: center;
                      "
                      v-for="(i, index) in educationsList"
                      :key="'educationsList' + i.id"
                    >
                      <div style="line-height: 1">
                        <span
                          class="fontColor1"
                          style="
                            margin-right: 20px;
                            position: relative;
                            vertical-align: middle;
                          "
                          v-if="i.startDate || i.endDate || i.isSoFar"
                        >
                          {{ i.startDate ? i.startDate : "-" }}
                          至
                          {{ i.isSoFar ? "至今" : i.endDate ? i.endDate : "-" }}
                        </span>
                        <el-popover
                          placement="right"
                          width="400"
                          trigger="hover"
                          :open-delay="2000"
                        >
                          <div>
                            <h3>院校信息</h3>
                            <div style="width: 40%; margin: 0 auto">
                              <img
                                style="width: 100%"
                                alt="暂无图片……"
                                :src="`https://assets.belloai.com/${SchoolFoo.logo_url}`"
                              />
                            </div>
                            <p
                              class="educationStyle"
                              v-if="SchoolFoo.foreign_name"
                            >
                              {{ SchoolFoo.foreign_name }}
                            </p>
                            <p>
                              {{ SchoolFoo.type }}
                              {{
                                SchoolFoo.college_type
                                  ? " / " + SchoolFoo.college_type
                                  : ""
                              }}
                              {{
                                SchoolFoo.create_at
                                  ? " / " + SchoolFoo.create_at + "建校"
                                  : ""
                              }}
                            </p>
                            <p v-if="SchoolFoo.international_ranking">
                              <span
                                style="
                                  padding: 10px;
                                  border: 1px solid rgb(210, 229, 252);
                                  color: rgb(48, 135, 246);
                                  display: inline-block;
                                  background: rgb(238, 245, 254);
                                "
                                >世界排名{{
                                  SchoolFoo.international_ranking
                                }}</span
                              >
                            </p>
                            <p
                              style="color: #999999"
                              :title="showOrHide"
                              :class="{ moreRow: isMoreRow }"
                              @click="clickShowAll"
                            >
                              {{ SchoolFoo.profile }}
                            </p>
                            <p class="educationStyle" style="margin-top: 20px">
                              重点学科
                            </p>
                            <p>
                              {{
                                SchoolFoo.major
                                  ? SchoolFoo.major.join("、")
                                  : ""
                              }}
                            </p>
                          </div>
                          <el-button
                            v-if="i.school"
                            @mouseover.native="belloSchool(i.school)"
                            @mouseout.native="clearbelloSchool()"
                            type="text"
                            class="linkButton"
                            style="position: relative; vertical-align: middle"
                            slot="reference"
                          >
                            <fixed-length-span
                              width="150"
                              :text="i.school"
                              class="schoolName"
                            ></fixed-length-span>
                          </el-button>
                        </el-popover>
                      </div>
                      <p style="margin: 0 20px">
                        {{ i.education
                        }}<span v-if="i.education && i.speciality"> · </span
                        >{{ i.speciality }}
                        <span class="tag tag22 mgl10" v-if="i.is_tongzhao">
                          统招</span
                        >
                      </p>
                      <i
                        v-show="hover && hover.hover"
                        class="el-icon-edit editIcon resumeEditIcon"
                        style="margin-top: 12px; margin-bottom: 8px"
                        @click="editExperience(index, 'educations')"
                      ></i>
                      <i
                        class="el-icon-document-copy editIcon resumeEditIcon"
                        v-show="hover && hover.hover"
                        style="
                          margin-top: 12px;
                          margin-left: 8px;
                          margin-bottom: 8px;
                        "
                        @click="copyContent(i.school)"
                      ></i>
                    </div>
                  </div>
                  <div v-if="educationsList.length == 0" class="nodata">
                    <p style="text-align: center">暂无</p>
                  </div>
                  <!--其他信息-->
                  <p :span="24" class="resumeTit">
                    <i class="iconfont iconfont1 icon-rc_qt"></i>
                    <span class="titlePosition">其他信息</span>
                  </p>
                  <div
                    class="clearfix"
                    style="margin-left: 8px; padding: 15px 0; font-size: 13px"
                  >
                    <el-col
                      class="fontColor contentBox"
                      @mouseenter.native="flag && showEdit('english')"
                      @mouseleave.native="flag && hideEdit('english')"
                      :span="24"
                      style="padding-right: 20px"
                      v-if="resumeInfos.english"
                    >
                      <p class="leftTit" style="white-space: nowrap">
                        语言能力：
                      </p>
                      <span
                        style="color: #333333; display: block"
                        v-html="resumeInfos.englishText"
                      >
                        {{
                          resumeInfos.englishText
                            ? resumeInfos.englishText
                            : "-"
                        }}
                      </span>
                      <editResumeList
                        class="resumeEditIcon"
                        ref="english"
                        type="english"
                        :isPoppoverShow="isPoppoverShow"
                        :isEditDetail="true"
                        v-show="hover && hover.hover"
                        :resumeInfoList="resumeInfos"
                        @editIcon="editIcon"
                        @hideEditIcon="hideEditIcon"
                        @saveChanged="
                          loadResumeDetils(resumeDetailId || resumeId)
                        "
                      ></editResumeList>
                    </el-col>
                    <el-col
                      class="fontColor contentBox"
                      @mouseenter.native="flag && showEdit('personal')"
                      @mouseleave.native="flag && hideEdit('personal')"
                      :span="24"
                      style="padding-right: 20px"
                      v-if="resumeInfos.personal"
                    >
                      <p class="leftTit" style="white-space: nowrap">
                        自我评价：
                      </p>
                      <p
                        style="color: #333333; display: block"
                        v-html="
                          resumeInfos.personalText
                            ? resumeInfos.personalText
                            : '-'
                        "
                      ></p>
                      <editResumeList
                        class="resumeEditIcon"
                        ref="personal"
                        type="personal"
                        :isPoppoverShow="isPoppoverShow"
                        :isEditDetail="true"
                        :resumeInfoList="resumeInfos"
                        @editIcon="editIcon"
                        v-show="hover && hover.hover"
                        @hideEditIcon="hideEditIcon"
                        @saveChanged="
                          loadResumeDetils(resumeDetailId || resumeId)
                        "
                      ></editResumeList>
                    </el-col>
                    <el-col
                      class="fontColor contentBox"
                      @mouseenter.native="flag && showEdit('certificate')"
                      @mouseleave.native="flag && hideEdit('certificate')"
                      :span="24"
                      style="padding-right: 20px"
                      v-if="resumeInfos.certificate"
                    >
                      <p class="leftTit" style="white-space: nowrap">证书：</p>
                      <span
                        style="
                          color: #333333;
                          display: block;
                          position: relative;
                        "
                        v-html="
                          resumeInfos.certificateText
                            ? resumeInfos.certificateText
                            : '-'
                        "
                      ></span>
                      <editResumeList
                        class="resumeEditIcon"
                        ref="certificate"
                        type="certificate"
                        :isPoppoverShow="isPoppoverShow"
                        :isEditDetail="true"
                        :resumeInfoList="resumeInfos"
                        @editIcon="editIcon"
                        v-show="hover && hover.hover"
                        @hideEditIcon="hideEditIcon"
                        @saveChanged="
                          loadResumeDetils(resumeDetailId || resumeId)
                        "
                      ></editResumeList>
                    </el-col>
                    <el-col
                      class="fontColor contentBox"
                      @mouseenter.native="flag && showEdit('training')"
                      @mouseleave.native="flag && hideEdit('training')"
                      :span="24"
                      style="padding-right: 20px"
                      v-if="resumeInfos.training"
                    >
                      <p class="leftTit" style="white-space: nowrap">
                        培训经历：
                      </p>
                      <span
                        style="
                          color: #333333;
                          display: block;
                          position: relative;
                        "
                        v-html="
                          resumeInfos.trainingText
                            ? resumeInfos.trainingText
                            : '-'
                        "
                      ></span>
                      <editResumeList
                        class="resumeEditIcon"
                        ref="training"
                        type="training"
                        :isPoppoverShow="isPoppoverShow"
                        :isEditDetail="true"
                        :resumeInfoList="resumeInfos"
                        @editIcon="editIcon"
                        v-show="hover && hover.hover"
                        @hideEditIcon="hideEditIcon"
                        @saveChanged="
                          loadResumeDetils(resumeDetailId || resumeId)
                        "
                      ></editResumeList>
                    </el-col>
                    <el-col
                      class="fontColor contentBox"
                      @mouseenter.native="flag && showEdit('others')"
                      @mouseleave.native="flag && hideEdit('others')"
                      :span="24"
                      style="padding-right: 20px"
                      v-if="resumeInfos.othersText"
                    >
                      <p class="leftTit" style="white-space: nowrap">其他：</p>
                      <span
                        style="
                          color: #333333;
                          display: block;
                          position: relative;
                        "
                        v-html="
                          resumeInfos.othersText ? resumeInfos.othersText : '-'
                        "
                      ></span>
                      <editResumeList
                        class="resumeEditIcon"
                        ref="others"
                        type="others"
                        :isPoppoverShow="isPoppoverShow"
                        :isEditDetail="true"
                        :resumeInfoList="resumeInfos"
                        @editIcon="editIcon"
                        v-show="hover && hover.hover"
                        @hideEditIcon="hideEditIcon"
                        @saveChanged="
                          loadResumeDetils(resumeDetailId || resumeId)
                        "
                      ></editResumeList>
                    </el-col>
                  </div>
                </el-tab-pane>
                <el-tab-pane
                  :label="
                    originalTextNum !== null || originalTextNum == 0
                      ? '简历原文' + originalTextNum
                      : '简历原文'
                  "
                  name="second"
                  style="position: relative"
                  v-if="hover && hover.hover"
                >
                  <originalText
                    ref="originalText"
                    :id="this.belloId"
                    @number="getNumber"
                  ></originalText>
                </el-tab-pane>
                <el-tab-pane
                  :label="
                    annexesNum !== null || annexesNum == 0
                      ? '附件' + annexesNum
                      : '附件'
                  "
                  name="third"
                  v-if="hover && hover.hover"
                >
                  <annexes
                    ref="annexes"
                    :id="this.resumeId"
                    @number="getNumber"
                  ></annexes>
                </el-tab-pane>
                <el-tab-pane
                  :label="
                    calendarNum !== null || calendarNum == 0
                      ? '日程' + calendarNum
                      : '日程'
                  "
                  name="fourth"
                  v-if="hover && hover.hover"
                >
                  <calendar
                    ref="calendar"
                    :id="this.resumeId"
                    @changeactive="changeactive"
                    @number="getNumber"
                  ></calendar>
                </el-tab-pane>
                <el-tab-pane
                  label="操作日志"
                  name="fifth"
                  v-if="hover && hover.hover"
                >
                  <journal
                    ref="journal"
                    :id="this.resumeId"
                    :hisPhones="resumeInfos.hisPhones"
                    :secureContacts="
                      resumeInfos.offerOrEntryProjectResumeId &&
                      !resumeInfos.offerOrEntryPermission
                    "
                  ></journal>
                </el-tab-pane>
                <el-tab-pane
                  :label="
                    connectionCount !== null || connectionCount == 0
                      ? '人脉' + connectionCount
                      : '人脉'
                  "
                  name="sixth"
                  v-if="hover && hover.hover"
                >
                  <connections
                    v-if="hover && hover.hover"
                    ref="connections"
                    :resumeId="this.resumeId"
                  ></connections>
                </el-tab-pane>
              </el-tabs>
            </div>
          </el-col>
          <!--备注，项目，日志-->
          <el-col :span="8" style="padding: 0">
            <div
              class="resume"
              v-if="hover && hover.hover"
              style="margin-bottom: 10px"
            >
              <div class="btnList clearfix">
                <div class="block" @click="editCus">
                  <i class="iconfont icon-rc_bj"></i>
                  <p class="demonstration">编辑</p>
                </div>
                <div class="block" @click="addMark(resumeInfos.resumeType)">
                  <i class="iconfont icon-rc_bz"></i>
                  <p class="demonstration">备注</p>
                </div>
                <div class="block" @click="addProgream()">
                  <i class="iconfont icon-rc_jrxm"></i>
                  <p class="demonstration">加入项目</p>
                </div>
                <div class="block" @click="showcalendarDig()">
                  <i class="iconfont icon-rc_xzrc"></i>
                  <p class="demonstration">新增日程</p>
                </div>
                <el-popover
                  placement="bottom"
                  trigger="hover"
                  width="100"
                  v-model="popoverVisible"
                  popper-class="moreOptions"
                >
                  <el-button
                    class="optionMore"
                    size="mini"
                    icon="icon iconfont icongengxinjianli-rxq"
                    @click="joinFiles"
                    >加入文件夹</el-button
                  >
                  <el-button
                    class="optionMore"
                    size="mini"
                    @click="updateResume"
                    icon="icon iconfont icongengxinjianli-rxq"
                    >更新简历</el-button
                  >
                  <el-button
                    class="optionMore"
                    size="mini"
                    @click="showAnnexesDig"
                    icon="icon iconfont icontianjiabeizhu-rxq"
                    >上传附件</el-button
                  >
                  <el-button
                    class="optionMore"
                    size="mini"
                    @click="addIcon"
                    icon="icon iconfont icondabiaoqian-rxq"
                    >打标签</el-button
                  >
                  <el-button
                    class="optionMore"
                    size="mini"
                    v-if="ownerId == useId ? true : false"
                    @click="delResume"
                    icon="icon iconfont iconshanchurenxuan-rxq"
                    >删除人才</el-button
                  >
                  <el-button
                    class="optionMore"
                    size="mini"
                    v-if="showSecureLock"
                    @click="setSecure"
                    icon="icon iconfont iconshanchurenxuan-rxq"
                    >设为保密</el-button
                  >
                  <el-button
                    class="optionMore"
                    size="mini"
                    v-if="resumeInfos.secure"
                    @click="CancelSecure"
                    icon="icon iconfont iconshanchurenxuan-rxq"
                    >取消保密</el-button
                  >
                  <div slot="reference" class="block">
                    <i class="iconfont icon-rc_gd"></i>
                    <p class="demonstration">更多操作</p>
                  </div>
                </el-popover>
              </div>
            </div>
            <div style="position: relative" class="resume-R-tab">
              <el-tabs v-model="activeNameRemark" @tab-click="handleClick2">
                <el-tab-pane label="备注" name="first">
                  <div class="clearfix">
                    <div class="markRecode">
                      <el-row :gutter="24">
                        <el-col
                          :span="24"
                          style="padding-left: 10px; padding-right: 10px"
                          v-if="[RecordList || []].length > 0"
                        >
                          <el-collapse
                            v-model="activeNamesRecord"
                            @change="handleRecordChange"
                          >
                            <el-collapse-item
                              v-for="(item, index) in RecordList"
                              :key="item.id"
                              :name="item.id"
                              class="mark_recode_item"
                            >
                              <template slot="title">
                                <div class="Recode_title">
                                  <span>{{
                                    item.createdTime
                                      ? item.createdTime.substring(0, 16)
                                      : ""
                                  }}</span>
                                  <span class="mgl10 mgr10">{{
                                    item.creatorName
                                  }}</span>
                                  <span
                                    class="tag"
                                    :class="{
                                      tag15: item.relativeType == '人才',
                                      tag14: item.relativeType == '项目候选人',
                                    }"
                                    >{{ item.commentName }}</span
                                  >
                                  <span
                                    class="edit"
                                    @click.stop="editRecode(item)"
                                    v-if="
                                      item.createdUserId == useId &&
                                      hover &&
                                      hover.hover
                                    "
                                    ><i class="el-icon-edit"></i>
                                  </span>
                                </div>
                              </template>
                              <div class="Recode_content">
                                <div class="project" v-if="item.projectName">
                                  <router-link
                                    target="_blank"
                                    class="tabHref"
                                    :to="{
                                      path: '/project/details',
                                      query: { id: item.projectId },
                                    }"
                                    >{{ item.projectName }}
                                  </router-link>
                                </div>
                                <ul>
                                  <li
                                    v-for="(i, index) in item.commentColumns"
                                    :key="'Col' + index"
                                  >
                                    <p class="tit" v-if="i.value">
                                      {{ index + 1 }}.{{ i.columnTitle }}：
                                    </p>
                                    <p
                                      class="con"
                                      style="word-break: break-all"
                                    >
                                      <span v-html="i.valueHtml"></span>
                                    </p>
                                  </li>
                                </ul>
                              </div>
                            </el-collapse-item>
                          </el-collapse>
                        </el-col>
                        <el-col
                          v-if="RecordList.length == 0"
                          :span="24"
                          style="
                            text-align: center;
                            color: #909399;
                            padding: 15px;
                          "
                        >
                          <p>暂无备注记录！</p>
                        </el-col>
                      </el-row>
                    </div>
                  </div>
                </el-tab-pane>
                <el-tab-pane
                  label="项目"
                  v-if="!defaultShowTable && hover && hover.hover"
                  name="second"
                >
                  <div class="proRecodes" v-if="activeNameRemark == 'second'">
                    <el-collapse
                      v-if="operationLogList.length > 0"
                      accordion
                      v-model="activeNameProject"
                    >
                      <el-collapse-item
                        v-for="(i, inx) in operationLogList"
                        :name="inx + 1"
                        :key="i.projectResumeId"
                      >
                        <template slot="title">
                          <h3 style="width: 100%" v-if="i.canView">
                            <div style="line-height: 12px">
                              <span
                                class="proName text-overflows tabHref"
                                :title="i.projectName"
                                @click="goProject(i.projectId)"
                                style="color: #606266 !important"
                                >{{ i.projectName }}</span
                              >
                              <span v-if="i.secure" class="secureIcon"
                                >保密</span
                              >
                              <span
                                class="tag"
                                v-if="i.canView && i.contactStatus !== '无状态'"
                                :class="{
                                  tag01: i.contactStatus == '面试',
                                  tag03: i.contactStatus == '推荐',
                                  tag05: i.contactStatus == '联系中',
                                  tag06: i.contactStatus == '待定',
                                  tag07:
                                    i.contactStatus == '不考虑' ||
                                    i.contactStatus == '黑名单' ||
                                    i.contactStatus == '不适合',
                                }"
                                >{{ i.contactStatus }}</span
                              >
                              <span
                                class="tag tag23 mgl10"
                                v-if="i.goodNewsApproving"
                                >喜报审批中</span
                              >
                            </div>
                            <span
                              class="comName text-overflows tabHref"
                              @click="linkToCom(i.companyId)"
                              >{{ i.companyName }}</span
                            >
                          </h3>
                          <h3
                            style="
                              margin: 0;
                              color: #333333;
                              font-size: 13px;
                              width: 100%;
                            "
                            v-else
                          >
                            {{
                              i.canView
                                ? i.projectName
                                : i.projectName == null
                                ? "******"
                                : Array.from({ length: i.projectName.length })
                                    .fill("*")
                                    .join("")
                            }}
                            <span v-if="i.status && i.canView"
                              >({{ i.status }})</span
                            >
                            <span v-if="i.secure" class="secureIcon">保密</span>
                            <span
                              class="tag"
                              v-if="i.canView"
                              :class="{
                                tag01: i.contactStatus == '面试',
                                tag03: i.contactStatus == '推荐',
                                tag05: i.contactStatus == '联系中',
                                tag06: i.contactStatus == '待定',
                                tag07:
                                  i.contactStatus == '无状态' ||
                                  i.contactStatus == '不考虑' ||
                                  i.contactStatus == '黑名单' ||
                                  i.contactStatus == '不适合',
                              }"
                              >{{ i.contactStatus }}</span
                            >
                          </h3>
                        </template>
                        <div class="proCon">
                          <div v-show="i.hasPermission">
                            <el-select
                              style="width: 100%; margin-bottom: 5px"
                              filterable
                              ref="status"
                              @focus="getIndex(inx, i, i.limitValue)"
                              @change="getPropName(i.limitValue, i, inx)"
                              v-model="i.limitValue"
                              placeholder="请选择"
                              popper-class="isColorPopper"
                            >
                              <el-option
                                v-for="(item, index) in limitOptions"
                                :key="'_idx' + index"
                                :disabled="
                                  i.goodNewsApproving ? true : item.disabled
                                "
                                :label="item.status"
                                :value="item.status"
                                v-show="!item.hide"
                              >
                              </el-option>
                            </el-select>
                            <el-button
                              type="text"
                              class="functionWrapper"
                              :disabled="i.goodNewsApproving"
                              @click="internalInterval(i, '内部面试')"
                              >内部面试
                            </el-button>
                            <el-button
                              type="text"
                              class="functionWrapper"
                              :disabled="i.goodNewsApproving"
                              @click="internalInterval(i, 'F2F面试')"
                              >F2F面试
                            </el-button>
                            <el-button
                              type="text"
                              class="functionWrapper"
                              :disabled="
                                resumeInfos.resumeType == '候选人' &&
                                !i.goodNewsApproving
                                  ? false
                                  : true
                              "
                              @click="writeReport(i.projectResumeId, i)"
                              >写报告
                            </el-button>
                            <el-button
                              type="text"
                              class="functionWrapper"
                              :disabled="
                                resumeInfos.resumeType == '候选人' &&
                                !i.goodNewsApproving
                                  ? false
                                  : true
                              "
                              @click="backgroundSurvey(i)"
                              >背调
                            </el-button>
                            <el-button
                              type="text"
                              class="functionWrapper"
                              :disabled="
                                i.status == '入职' || i.status == '成功'
                                  ? false
                                  : true
                              "
                              @click="createdFunction(i.status, inx, i)"
                            >
                              创建财务任务
                            </el-button>
                            <el-button
                              :disabled="
                                !(
                                  i.status == '加入项目' &&
                                  i.createdUserId == useId &&
                                  !i.goodNewsApproving
                                )
                              "
                              type="text"
                              class="functionWrapper"
                              @click="removeProgream(i)"
                              >移出项目
                            </el-button>
                            <el-button
                              type="text"
                              class="functionWrapper"
                              :disabled="
                                i.status == 'Offer' ||
                                i.status == '简历推荐' ||
                                i.status == '入职' ||
                                (i.status == '客户面试' && !i.goodNewsApproving)
                                  ? false
                                  : true
                              "
                              @click="addRemark(i.projectResumeId, i.status, i)"
                            >
                              反馈
                            </el-button>
                            <el-button type="text" class="functionWrapper">
                              <el-dropdown @command="handleCommand($event, i)">
                                <span class="el-dropdown-link">
                                  联系状态<i
                                    class="el-icon-arrow-down el-icon--right"
                                  ></i>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                  <el-dropdown-item
                                    v-for="(item, inx) in contactStatusList"
                                    :command="item.label"
                                    :key="inx"
                                    :disabled="i.goodNewsApproving"
                                  >
                                    {{ item.label }}
                                    <i
                                      v-if="item.label == i.contactStatus"
                                      class="el-icon-check"
                                      style="color: #e60012"
                                    ></i
                                  ></el-dropdown-item>
                                </el-dropdown-menu>
                              </el-dropdown>
                            </el-button>
                            <el-button type="text" class="functionWrapper">
                              <el-dropdown
                                @visible-change="flagShow($event, i)"
                                @command="handleFlag($event, i)"
                              >
                                <span class="el-dropdown-link">
                                  标识<i
                                    class="el-icon-arrow-down el-icon--right"
                                  ></i>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                  <el-dropdown-item
                                    v-for="(item, inx) in flagList"
                                    :command="item.value"
                                    :key="inx"
                                  >
                                    {{ item.value }}
                                    <i
                                      v-if="item.value == i.flag"
                                      class="el-icon-check"
                                      style="color: #e60012"
                                    ></i
                                  ></el-dropdown-item>
                                </el-dropdown-menu>
                              </el-dropdown>
                            </el-button>
                          </div>
                          <div v-if="i.list.length > 0" style="margin-top: 5px">
                            <div
                              class="statusTitle"
                              v-for="item in i.list"
                              :key="item.id"
                            >
                              <el-collapse accordion>
                                <el-collapse-item class="foldStyle">
                                  <template slot="title">
                                    <div class="headCon">
                                      {{
                                        item.createdTime
                                          ? item.createdTime.substring(0, 10)
                                          : ""
                                      }}
                                      <span class="mgl10 mgr10">{{
                                        item.creatorName
                                      }}</span>
                                      <span
                                        class="tag mgr10"
                                        :class="{
                                          tag13: item.status == '加入项目',
                                          tag10: item.status == '简历推荐',
                                          tag15: item.status == '客户面试',
                                          tag12: item.status == 'Offer',
                                          tag11: item.status == '入职',
                                          tag06: item.status == '成功',
                                        }"
                                        >{{ item.status }}</span
                                      >
                                      <span
                                        class="tag mgr10"
                                        v-if="item.feedbackStr"
                                        :class="{
                                          tag23:
                                            item.feedbackStr == '客户淘汰' ||
                                            item.feedbackStr == '候选人拒绝',
                                          tag11:
                                            item.feedbackStr == '通过' ||
                                            item.feedbackStr == '接受',
                                          tag24: item.feedbackStr == '待定',
                                        }"
                                        >{{ item.feedbackStr }}</span
                                      >
                                      <div class="edit" v-if="item.content">
                                        <span
                                          v-if="
                                            item.status != '重置' &&
                                            item.status != '成功'
                                          "
                                        >
                                          <i
                                            v-if="
                                              (item.createdUserId == useId ||
                                                item.projectManagerId ==
                                                  useId) &&
                                              item.status != '撤销'
                                            "
                                            @click.stop="
                                              editProRecode(
                                                item,
                                                i.projectResumeId
                                              )
                                            "
                                            class="el-icon-edit"
                                            style="margin-right: 7px"
                                          ></i>
                                        </span>
                                      </div>
                                    </div>
                                  </template>
                                  <div
                                    v-if="item.status == '简历推荐'"
                                    style="color: #999999; font-size: 13px"
                                  >
                                    <p>
                                      推荐时间：<span class="fontColor">{{
                                        JSON.parse(item.content).recommendTime
                                      }}</span>
                                    </p>
                                    <div
                                      v-for="fileInfo in JSON.parse(
                                        item.content
                                      ).files"
                                      :key="fileInfo.fileUrl"
                                    >
                                      推荐报告：<span
                                        @click="preOrdown(fileInfo)"
                                        class="tabHref text-overflows fileName"
                                        >{{ fileInfo.fileName }}</span
                                      ><span
                                        class="mgl10 tabHref"
                                        style="color: #666"
                                        @click="fileDownload(fileInfo)"
                                        ><i class="el-icon-download"></i
                                      ></span>
                                    </div>
                                  </div>
                                  <div
                                    v-if="item.status == '客户面试'"
                                    style="color: #999999; font-size: 13px"
                                  >
                                    <p>
                                      面试时间：<span class="fontColor">{{
                                        JSON.parse(
                                          item.content
                                        ).interviewDate.substring(0, 16)
                                      }}</span>
                                    </p>
                                    <p>
                                      面试轮数：<span class="fontColor">{{
                                        JSON.parse(item.content).interviewRound
                                      }}</span
                                      ><span
                                        v-if="JSON.parse(item.content).isFinal"
                                        >(终面)</span
                                      >
                                      <span
                                        style="float: right"
                                        v-if="
                                          JSON.parse(item.content).addToSchedule
                                        "
                                        >已添加至日程</span
                                      >
                                    </p>
                                  </div>
                                  <div
                                    v-if="item.status == 'Offer'"
                                    style="color: #999999; font-size: 13px"
                                  >
                                    <div
                                      style="
                                        border: 1px #ececec solid;
                                        margin-bottom: 10px;
                                      "
                                    >
                                      <p
                                        style="
                                          color: #333;
                                          font-size: 14px;
                                          background: #f1f1f1;
                                          padding: 0 5px;
                                        "
                                      >
                                        薪资结构
                                      </p>
                                      <div
                                        v-for="(i, index) in JSON.parse(
                                          item.content
                                        ).offerSalaries"
                                        :key="index"
                                        style="padding: 5px 10px"
                                      >
                                        <p>
                                          【{{ i.structure }}】<span
                                            class="fontColor"
                                            >{{ i.amount }}*{{ i.count }}={{
                                              i.totalAmount
                                            }}</span
                                          >
                                        </p>
                                      </div>
                                    </div>
                                    <div style="border: 1px #ececec solid">
                                      <p
                                        style="
                                          color: #333;
                                          font-size: 14px;
                                          background: #f1f1f1;
                                          padding: 0 5px;
                                        "
                                      >
                                        喜报信息
                                      </p>
                                      <div style="padding: 5px 10px">
                                        <p>
                                          offer时间：<span class="fontColor">{{
                                            JSON.parse(item.content).offerTime
                                          }}</span>
                                        </p>
                                        <p>
                                          预到岗时间：<span class="fontColor">{{
                                            JSON.parse(item.content)
                                              .planEntryTime
                                          }}</span>
                                        </p>
                                        <p>
                                          offer金额：<span class="fontColor">{{
                                            JSON.parse(item.content).offerMoney
                                          }}</span>
                                        </p>
                                        <p>更多喜报信息请见【业绩池】</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    v-if="item.status == '背景调查'"
                                    style="color: #999999; font-size: 13px"
                                  >
                                    <p>
                                      背景调查时间：<span class="fontColor">{{
                                        JSON.parse(item.content).recommendTime
                                      }}</span>
                                    </p>
                                    <div
                                      v-for="fileInfo in JSON.parse(
                                        item.content
                                      ).files"
                                      :key="fileInfo.fileUrl"
                                    >
                                      背调报告：<span
                                        @click="preOrdown(fileInfo)"
                                        class="tabHref text-overflows fileName"
                                        >{{ fileInfo.fileName }}</span
                                      >
                                    </div>
                                  </div>
                                  <div
                                    v-if="item.status == '入职'"
                                    style="color: #999999; font-size: 13px"
                                  >
                                    <p>
                                      入职时间：<span class="fontColor">{{
                                        JSON.parse(item.content).entryTime
                                      }}</span>
                                    </p>
                                    <p>
                                      试用期结束：<span class="fontColor">{{
                                        JSON.parse(item.content).endTimeOfTrial
                                      }}</span>
                                      <span
                                        style="float: right"
                                        v-if="
                                          JSON.parse(item.content).addToSchedule
                                        "
                                        >已添加至日程</span
                                      >
                                    </p>
                                    <p>
                                      约定首次开票时间：<span class="fontColor"
                                        >{{
                                          JSON.parse(item.content)
                                            .conventionFirstMakeInvoiceDate
                                        }}
                                      </span>

                                      <span
                                        style="float: right"
                                        v-if="
                                          JSON.parse(item.content)
                                            .firstMakeInvoiceAddToSchedule
                                        "
                                        >已添加至日程</span
                                      >
                                    </p>
                                  </div>
                                  <div
                                    v-if="
                                      item.feedbackRecords &&
                                      item.feedbackRecords.length > 0
                                    "
                                  >
                                    <div class="feedbackItems">
                                      <div
                                        class="feedbackItem"
                                        v-for="i in item.feedbackRecords"
                                        :key="i.id"
                                      >
                                        <p class="tit">
                                          <span class="tit_l"
                                            ><i class="iconfont icon-pl_fk"></i
                                            >反馈 【{{ i.feedback }}】 </span
                                          ><span>{{
                                            i.createdTime
                                              ? i.createdTime.substring(0, 16)
                                              : ""
                                          }}</span
                                          ><span class="mgl10">{{
                                            i.createdUserName
                                          }}</span>
                                        </p>
                                        <ul class="feedbackDes">
                                          <li
                                            v-for="(
                                              j, index
                                            ) in i.commentColumns"
                                            :key="'Col' + index"
                                          >
                                            <p class="titt">
                                              {{ index + 1 }}.{{
                                                j.columnTitle
                                              }}：
                                            </p>
                                            <p class="con">
                                              <span v-html="j.valueHtml"></span>
                                            </p>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </el-collapse-item>
                              </el-collapse>
                            </div>
                          </div>
                          <div
                            v-else
                            :span="24"
                            style="text-align: center; color: #909399"
                          >
                            <p>暂无状态记录！</p>
                          </div>
                        </div>
                      </el-collapse-item>
                    </el-collapse>
                    <el-col
                      v-if="operationLogList.length == 0"
                      :span="24"
                      style="text-align: center; color: #909399; padding: 15px"
                    >
                      <p>暂无项目记录！</p>
                    </el-col>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </div>
          </el-col>
        </div>
      </el-row>
    </div>
    <!--        请确认是否删除简历，删除后该简历及相关数据？-->
    <el-dialog
      title="提示"
      :visible.sync="delResumeDialog"
      :modal-append-to-body="false"
      append-to-body
      :close-on-click-modal="false"
      width="30%"
      :before-close="handleClose"
    >
      <p>确定要删除该人才吗？</p>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="default"
          plain
          size="mini"
          @click="delResumeDialog = false"
          >取消</el-button
        >
        <el-button type="primary" size="mini" @click="submitDelResume"
          >确定</el-button
        >
      </span>
    </el-dialog>

    <!--        候选人还是coldCall-->
    <el-dialog
      title="提示"
      :visible.sync="dialogVisibleColdCall"
      :modal-append-to-body="false"
      append-to-body
      :close-on-click-modal="false"
      width="600px"
      :before-close="handleClose"
    >
      <div style="display: flex; align-items: center">
        <i
          class="el-icon-warning"
          style="font-size: 18px; color: #fac858; margin-right: 8px"
        ></i>
        <p>
          当前备注人才为cold call ，是否补充为候选人？（但cold call
          的备注不计入KPI）
        </p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" plain size="mini" @click="goRemark"
          >直接备注</el-button
        >
        <el-button type="primary" size="mini" @click="perfectResume"
          >完善人才</el-button
        >
      </span>
    </el-dialog>
    <!--    状态-->
    <el-dialog
      title="编辑"
      :visible.sync="dialogVisibleEditStaus"
      :modal-append-to-body="false"
      append-to-body
      :close-on-click-modal="false"
      width="30%"
      :before-close="handleClose"
    >
      <el-form :model="statusData" ref="statusData" label-width="135px">
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="状态" prop="">
              <el-select v-model="statusData.status" @change="changeS">
                <el-option
                  v-for="item in stadusList"
                  :key="item.name"
                  :value="item.name"
                  :label="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="default"
          plain
          size="mini"
          @click="dialogVisibleEditStaus = false"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="mini"
          :loading="saveloading"
          @click="savestadus('statusData')"
          >保存</el-button
        >
      </span>
    </el-dialog>

    <!--编辑人才-->
    <el-drawer
      :visible.sync="dialogVisible"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      append-to-body
      :modal="false"
      direction="ttb"
      custom-class="drawerClass"
      :before-close="handleClose"
    >
      <div slot="title" class="drawerTitle">
        {{ titName }}
      </div>
      <div class="drawerCon">
        <!-- 修改信息 -->
        <resumeInfo
          v-if="iseditCus"
          :resumeId="resumeId"
          @loadResumeDetils="loadResumeDetils(resumeId)"
          ref="saveCusInfo"
        ></resumeInfo>
        <!-- 更新简历 -->
        <resumeInfo
          v-if="isUpdateResume"
          :farResumeList="farResumeList"
          :resumeId="resumeId"
          @loadResumeDetils="loadResumeDetils(resumeId)"
          ref="saveCusInfo"
        ></resumeInfo>
        <!-- 加入项目 -->
        <progream
          v-if="isaddPro"
          :entry="projectEntry"
          :resumeIds="resumeIds"
          :hookedLabelIds="searchObj ? searchObj.tagIds : ''"
        ></progream>

        <div class="drawerCon_footer fixedFoot" v-if="!isaddPro">
          <el-button
            type="default"
            plain
            size="mini"
            @click="(dialogVisible = false), cancel()"
            >取消</el-button
          >
          <el-button
            type="primary"
            size="mini"
            :loading="saveloading"
            @click="saveInfos"
            style="margin-right: 15px"
            >保存</el-button
          >
        </div>
      </div>
    </el-drawer>
    <!-- 打标签 -->
    <el-dialog
      :title="titName"
      :visible.sync="isaddTag"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :lock-scroll="false"
      append-to-body
      width="960px"
      :before-close="handleClose"
      custom-class="addTagDig tagsDigContent"
    >
      <label-management
        ref="labelManagement"
        v-if="isaddTag"
        :allSkillLabels="allSkillLabels"
        :customLabels="customLabels"
        :resumeId="resumeId"
        :entry="createTag.entry"
        @loadList="loadList(), loadResumeDetils()"
        @changeSaveLoading="changeSaveLoading"
      ></label-management>
      <span slot="footer" class="dialog-footer" v-if="isaddTag">
        <el-button
          type="default"
          plain
          size="mini"
          @click="(isaddTag = false), cancel()"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="mini"
          :loading="saveloading"
          @click="saveInfos"
          >保存</el-button
        >
      </span>
    </el-dialog>
    <!--        添加备注单个dialog-->
    <el-dialog
      class="dialogVisibleRemark1"
      :title="titName2"
      :visible.sync="dialogVisibleRemark2"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :modal="false"
      :lock-scroll="false"
      append-to-body
      width="600px"
      :before-close="handleClose"
    >
      <!--      &lt;!&ndash;            备注&ndash;&gt;-->
      <addMarkReacode
        :inputName="markName"
        v-if="isaddMark"
        :disableEdit="isNo"
        :customId="projectResumeId"
        relativeType="人才"
        appointment="人才约见"
        type="候选人沟通"
        @changeSaveLoading="changeSaveLoading"
        @loadRecodeList="loadRecodeList(resumeId, '人才')"
        ref="saveMark"
      ></addMarkReacode>
      <addMarkReacode
        :inputName="markName"
        v-if="isaddMark1"
        :customId="projectResumeId"
        relativeType="项目候选人"
        :type="titNametype"
        @changeSaveLoading="changeSaveLoading"
        @loadRecodeList="loadRecodeList(resumeId, '项目候选人')"
        ref="saveMark"
      ></addMarkReacode>
      <span slot="footer" class="dialog-footer" v-if="!isaddPro">
        <el-button
          type="default"
          plain
          size="mini"
          @click="(dialogVisibleRemark2 = false), cancel()"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="mini"
          :loading="saveloading"
          @click="saveInfosProject"
          >保存</el-button
        >
      </span>
    </el-dialog>
    <!--        添加备注单个dialog-->
    <el-dialog
      :title="titName2"
      :visible.sync="dialogVisiblefeedBack"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      append-to-body
      width="600px"
      :before-close="handleClose"
    >
      <div class="feedBackCss">
        <div class="feedBackStyle">
          <el-radio-group
            v-model="feedBackStatus"
            v-if="titNametype == '简历推荐'"
          >
            <el-radio label="待定">待定</el-radio>
            <el-radio label="客户淘汰">客户淘汰</el-radio>
            <el-radio label="候选人拒绝">候选人拒绝</el-radio>
          </el-radio-group>
          <el-radio-group
            v-model="feedBackStatus"
            v-if="titNametype == '客户面试'"
          >
            <el-radio label="待定">待定</el-radio>
            <el-radio label="通过">通过</el-radio>
            <el-radio label="客户淘汰">客户淘汰</el-radio>
            <el-radio label="候选人拒绝">候选人拒绝</el-radio>
          </el-radio-group>
          <el-radio-group
            v-model="feedBackStatus"
            v-if="titNametype == 'Offer'"
          >
            <el-radio label="拒绝">拒绝</el-radio>
            <el-radio label="接受">接受</el-radio>
          </el-radio-group>
          <el-radio-group v-model="feedBackStatus" v-if="titNametype == '入职'">
            <el-radio label="未过试用期">未过试用期</el-radio>
            <el-radio label="试用期离职">试用期离职</el-radio>
          </el-radio-group>
          <el-form
            @submit.native.prevent
            label-position="top"
            v-if="CommentWay.columns && CommentWay.columns.length > 0"
          >
            <el-row :gutter="24">
              <el-col
                :span="24"
                v-for="(item, index) in CommentWay.columns"
                :key="item.id"
              >
                <el-form-item
                  :label="index + 1 + '.' + item.title"
                  :required="item.required"
                  class="selectLabel"
                >
                  <div
                    v-if="item.type == '单选型' && item.selectType == '单选'"
                  >
                    <el-radio-group v-model="item.answer">
                      <p v-for="(i, _index) in item.options" :key="_index">
                        <el-radio
                          :label="i"
                          @click.native.prevent="clickItem(i, index)"
                          >{{ i }}</el-radio
                        >
                      </p>
                    </el-radio-group>
                  </div>
                  <div
                    v-if="item.type == '单选型' && item.selectType == '下拉'"
                  >
                    <el-select
                      v-model="item.answer"
                      clearable
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="(i, _index) in item.options"
                        :key="_index"
                        :label="i"
                        :value="i"
                      ></el-option>
                    </el-select>
                  </div>
                  <div v-if="item.type == '多选型'">
                    <el-checkbox-group v-model="item.answer1">
                      <p v-for="(i, _index) in item.options" :key="_index">
                        <el-checkbox :label="i">{{ i }}</el-checkbox>
                      </p>
                    </el-checkbox-group>
                  </div>
                  <div v-if="item.type == '文本描述型'">
                    <el-input
                      v-model="item.answer"
                      placeholder="请输入内容"
                      type="textarea"
                      :rows="3"
                    ></el-input>
                  </div>
                  <div v-if="item.type == '日期型'">
                    <el-date-picker
                      prefix-icon="el-icon-date"
                      v-model="item.answer"
                      type="date"
                      placeholder="选择日期"
                      value-format="yyyy/MM/dd"
                    ></el-date-picker>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div class="footer-btn">
          <el-button
            size="mini"
            type="default"
            @click="dialogVisiblefeedBack = false"
            >取消</el-button
          >
          <el-button type="primary" size="mini" @click="saveFeedBack"
            >确定</el-button
          >
        </div>
      </div>
    </el-dialog>
    <!--        项目-->
    <el-dialog
      :title="titName1"
      :visible.sync="dialogVisibleRemark"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      append-to-body
      :width="ishappyNews||isWhiteCollarWorker ? '88%' : '600px'"
      :before-close="handleClose"
    >
      <!-- 背景调查 -->
      <backgroundSurvey
        v-if="isBackgroundSurvey"
        ref="saveBackgroundSurvey"
        :projectResumeId="projectResumeId1"
        @changeSaveLoading="changeSaveLoading"
        @loadList="loadList()"
      ></backgroundSurvey>
      <!-- 入职 -->
      <enrty
        v-if="isEnrty"
        ref="saveEntry"
        :projectResumeId="projectResumeId1"
        @loadList="loadList()"
      ></enrty>
      <!-- 发喜报   仅在变更过offer阶段显示 -->
      <happyNews
        v-if="ishappyNews"
        ref="saveHappyNews"
        :projectResumeId="projectResumeId1"
        @changeSaveLoading="changeSaveLoading"
        @loadList="(dialogVisibleRemark = false), cancel()"
      ></happyNews>
      <whiteCollarWorker ref="whiteCollarWorker" v-if="isWhiteCollarWorker"  @loadList="loadList()" :isAnnouncement='isAnnouncement' :projectResumeId="projectResumeId1" @changeSaveLoading="changeSaveLoading"></whiteCollarWorker>
      <!-- 重置 -->
      <el-form
        v-if="isReset"
        :model="resetRuleForm"
        :rules="resetRules"
        ref="resetRuleForm"
      >
        <el-form-item prop="resetStatus">
          <el-select
            @change="selectReset"
            v-model="resetRuleForm.resetStatus"
            placeholder="请选择"
          >
            <el-option
              v-for="item in resetList"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <!--            写报告-->
      <div v-if="iswriteReport">
        <el-table
          :header-cell-style="{
            'background-color': '#f5f7fa',
          }"
          :data="optionsReport"
          style="width: 100%"
        >
          <el-table-column prop="type" label="模板文件类型"> </el-table-column>
          <el-table-column prop="nameWithExtension" label="报告名称">
          </el-table-column>
          <el-table-column label="操作" width="100">
            <template slot-scope="scope">
              <span class="tabHref" @click="downLoadReport(scope.row.id)"
                >下载报告</span
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer" v-if="!isaddPro">
        <el-button
          type="default"
          plain
          size="mini"
          @click="(dialogVisibleRemark = false), cancel()"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="mini"
          :loading="saveloading"
          @click="saveInfosProject"
          style="margin-right: 15px"
          >保存</el-button
        >
      </span>
    </el-dialog>
    <!-- 创建财务任务 -->
    <el-drawer
      :visible.sync="isfinancialTask"
      append-to-body
      :modal="false"
      custom-class="drawerClass"
      :close-on-click-modal="false"
      direction="ttb"
      :before-close="handleClose"
    >
      <div slot="title" class="drawerTitle">
        <i class="drawIcon iconfont icon-pl_cjcw"></i>创建财务任务
      </div>

      <!-- 创建财务任务 -->
      <financialTasks
        v-if="isfinancialTask"
        ref="saveFinancialTask"
        :projectResumeId="projectResumeId1"
        :companyId="companyId"
        @changeSaveLoading="changeSaveLoading"
        @loadList="(isfinancialTask = false), cancel()"
      ></financialTasks>
      <span v-if="!iswriteReport" style="margin: 20px; float: right">
        <el-button
          type="default"
          plain
          size="mini"
          @click="(dialogVisible = false), cancel()"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="mini"
          :loading="saveloading"
          @click="saveInfos"
          >保存</el-button
        >
      </span>
    </el-drawer>
    <!-- 更新简历 -->
    <el-dialog
      title="更新简历"
      :visible.sync="dialogVisibleReplace"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      append-to-body
      width="600px  "
      :before-close="handleDiaClose"
    >
      <el-upload
        ref="upload"
        class="upload-demo uploadContract uploadChanged"
        style="margin: 0 auto"
        drag
        :headers="myHeaders"
        :action="actionUrl"
        :before-upload="beforeUpload"
        :on-change="handleChange"
        :on-exceed="handleExceed"
        :on-success="handleInfoSuccess"
        :on-error="handleInfoError"
        :file-list="fileInfoList"
        :limit="1"
        :on-remove="handleRemove"
        accept=".doc, .docx, .pdf, .jpg, .png"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text" style="line-height: 30px; font-size: 14px">
          将文件拖到此处，或
          <span class="tabHref">点击上传</span>
        </div>
      </el-upload>
      <div
        style="
          margin-bottom: 30px;
          font-size: 12px;
          line-height: 1.5;
          color: #606266;
        "
      >
        支持扩展名：.doc .docx .pdf .jpg
        .png，文件不超过20M，单次只能上传1份文件
      </div>
      <div style="background: #f0f0f0; font-size: 13px; padding: 15px">
        <div style="margin-bottom: 8px; font-weight: bold">温馨提示：</div>
        <p style="margin: 5px 0">
          1.如果老简历无联系方式，则补充新简历的联系方式。
        </p>
        <p style="margin: 5px 0">2.如果老简历有联系方式，则保留原样。</p>
        <p style="margin: 5px 0">
          3.上传确认后会跳转编辑页面，可视情况调整，保存后更新生效。
        </p>
      </div>
      <span slot="footer" class="dialog-footer" v-if="!isaddPro">
        <el-button
          type="default"
          plain
          size="mini"
          @click="(dialogVisibleReplace = false), cancel()"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="mini"
          :disabled="saveDisabled"
          :loading="saveloading"
          @click="saveChangedInfos"
          >保存</el-button
        >
      </span>
    </el-dialog>
    <!-- 悬浮编辑 -->
    <el-dialog
      :title="titleName"
      :visible.sync="dialogVisibleEdit"
      :modal-append-to-body="false"
      append-to-body
      :close-on-click-modal="false"
      width="600px"
      destroy-on-close
      @close="closeDialog"
      :before-close="handleCloseEdit"
      custom-class="digEdit"
    >
      <!-- 工作经历 -->
      <el-form
        v-if="visibleEmpoyments"
        :model="editEmploymentList"
        :rules="Emrules"
        ref="editEmploymentList"
        label-width="120px"
      >
        <el-row :gutter="24">
          <el-col :span="21">
            <el-form-item label="公司名称：" prop="company">
              <el-input
                maxlength="100"
                v-model.trim="editEmploymentList.company"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="21">
            <el-form-item label="行业：" prop="vocationIds">
              <el-cascader
                style="width: 100%"
                ref="empVocation"
                filterable
                collapse-tags
                :show-all-levels="false"
                :options="ColumnInList"
                v-model="editEmploymentList.vocationIds"
                placeholder="请选择"
                :props="propsempFunction"
              ></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="21">
            <el-form-item label="职位名称：" prop="title">
              <el-input
                maxlength="50"
                v-model="editEmploymentList.title"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="21">
            <el-form-item label="任职时间：">
              <el-row :gutter="24" style="display: flex">
                <el-col>
                  <el-form-item label-width="0">
                    <el-date-picker
                      v-model="editEmploymentList.startDate"
                      type="month"
                      value-format="yyyy-MM"
                      style="width: 115px"
                      placeholder="开始年月"
                      :picker-options="employmentsStartTime"
                    ></el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col> - </el-col>
                <el-col>
                  <el-form-item label-width="0">
                    <el-date-picker
                      :disabled="editEmploymentList.isSoFar"
                      v-model="editEmploymentList.endDate"
                      type="month"
                      value-format="yyyy-MM"
                      style="width: 115px"
                      placeholder="结束年月"
                      :picker-options="employmentsEndTime"
                    ></el-date-picker>
                  </el-form-item>
                </el-col>

                <el-col>
                  <el-form-item label-width="0">
                    <el-checkbox
                      v-model="editEmploymentList.isSoFar"
                      @change="checkedTime($event)"
                      >至今</el-checkbox
                    >
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
          <el-col :span="21">
            <el-form-item label="部门：" prop="department">
              <el-input
                maxlength="50"
                v-model="editEmploymentList.department"
                placeholder="请输入部门"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="21">
            <el-form-item label="月薪：" prop="salary">
              <el-input
                maxlength="10"
                v-model="editEmploymentList.salary"
                placeholder="请输入"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="21">
            <el-form-item label="工作地点：" prop="salary">
              <el-input
                v-model="editEmploymentList.jobLocation"
                maxlength="50"
                placeholder="请输入"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="21">
            <el-form-item label="工作性质：" prop="salary">
              <el-input
                v-model="editEmploymentList.jobNature"
                maxlength="50"
                placeholder="请输入"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="21">
            <el-form-item label="下属人数：" prop="underlingNumber">
              <el-input
                maxlength="10"
                type="number"
                oninput="if(value.length > 10) value=value.slice(0, 10)"
                v-model.number="editEmploymentList.underlingNumber"
                placeholder="请输入数值"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="21">
            <el-form-item label="汇报对象：" prop="leader">
              <el-input
                maxlength="50"
                v-model.trim="editEmploymentList.leader"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="21">
            <el-form-item label="离职原因：" prop="reasonOfLeaving">
              <el-input
                type="textarea"
                maxlength="200"
                v-model.trim="editEmploymentList.reasonOfLeaving"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="21">
            <el-form-item label="工作描述：" prop="summary">
              <el-input
                type="textarea"
                :row="4"
                v-model="editEmploymentList.summary"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="21">
            <el-form-item label="工作业绩：" prop="achievement">
              <el-input
                show-word-limit
                :autosize="{ minRows: 2, maxRows: 8 }"
                type="textarea"
                v-model="editEmploymentList.achievement"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="21"> </el-row>
        <el-row :gutter="21"> </el-row>
      </el-form>
      <!-- 项目经历 -->
      <el-form
        v-if="visibleProjects"
        :model="editProjectList"
        :rules="Prorules"
        ref="editProjectList"
        label-width="120px"
      >
        <el-row :gutter="24">
          <el-col :span="21">
            <el-form-item label="项目名称：" prop="projectName">
              <el-input
                maxlength="50"
                v-model.trim="editProjectList.projectName"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="21">
            <el-form-item label="公司名称：" prop="company">
              <el-input
                maxlength="100"
                v-model.trim="editProjectList.company"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="21">
            <el-form-item label="项目职位：" prop="title">
              <el-input
                maxlength="50"
                v-model.trim="editProjectList.title"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="21">
            <el-form-item label="项目时间：" class="is-required">
              <el-row :gutter="24" style="display: flex">
                <el-col>
                  <el-form-item label-width="0" prop="startDate">
                    <el-date-picker
                      v-model="editProjectList.startDate"
                      type="month"
                      value-format="yyyy-MM"
                      style="width: 115px"
                      placeholder="开始年月"
                      :picker-options="projectsStartTime"
                    ></el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col>-</el-col>
                <el-col>
                  <el-form-item label-width="0" prop="endDate">
                    <el-date-picker
                      :disabled="editProjectList.isSoFar"
                      v-model="editProjectList.endDate"
                      type="month"
                      value-format="yyyy-MM"
                      style="width: 115px"
                      placeholder="结束年月"
                      :picker-options="projectsEndTime"
                    ></el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col>
                  <el-form-item label-width="0">
                    <el-checkbox
                      v-model="editProjectList.isSoFar"
                      @change="checkedTime($event)"
                      >至今</el-checkbox
                    >
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
          <el-col :span="21">
            <el-form-item label="项目描述：" prop="projectDescription">
              <el-input
                v-model="editProjectList.projectDescription"
                type="textarea"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="21">
            <el-form-item label="项目职责：" prop="responsibilities">
              <el-input
                v-model="editProjectList.responsibilities"
                type="textarea"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="21">
            <el-form-item label="项目业绩：" prop="achievements">
              <el-input
                v-model="editProjectList.achievements"
                type="textarea"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- 教育经历 -->
      <el-form
        v-if="visibleEducations"
        :model="editEducationList"
        :rules="Edrules"
        ref="editEducationList"
        label-width="120px"
      >
        <el-row :gutter="24">
          <el-col :span="21">
            <el-form-item label="学校名称：" prop="school">
              <el-input
                maxlength="100"
                v-model.trim="editEducationList.school"
                placeholder="请输入"
                style="width: 79%"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="21">
            <el-form-item label="专业名称：" prop="speciality">
              <el-input
                maxlength="50"
                v-model.trim="editEducationList.speciality"
                placeholder="请输入"
                style="width: 79%"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="21">
            <el-form-item label="学历：" prop="education">
              <el-select
                clearable
                filterable
                v-model="editEducationList.education"
                placeholder="请选择"
                style="width: 79%"
              >
                <el-option label="初中" value="初中"></el-option>
                <el-option label="高中" value="高中"></el-option>
                <el-option label="中技" value="中技"></el-option>
                <el-option label="中专" value="中专"></el-option>
                <el-option label="大专" value="大专"></el-option>
                <el-option label="本科" value="本科"></el-option>
                <el-option label="硕士" value="硕士"></el-option>
                <el-option label="博士" value="博士"></el-option>
                <el-option label="博士后" value="博士后"></el-option>
                <el-option label="MBA" value="MBA"></el-option>
                <el-option label="其他" value="其他"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="21">
            <el-form-item label="时间：" class="is-required">
              <el-row :gutter="24" style="display: flex">
                <el-col>
                  <el-form-item label-width="0" prop="startDate">
                    <el-date-picker
                      v-model="editEducationList.startDate"
                      type="month"
                      value-format="yyyy-MM"
                      style="width: 130px"
                      placeholder="开始年月"
                      :picker-options="educationsStartTime"
                    ></el-date-picker>
                  </el-form-item>
                </el-col>
                <span>-</span>
                <el-col>
                  <el-form-item label-width="0" prop="endDate">
                    <el-date-picker
                      :disabled="editEducationList.isSoFar"
                      v-model="editEducationList.endDate"
                      type="month"
                      value-format="yyyy-MM"
                      style="width: 130px"
                      placeholder="结束年月"
                      :picker-options="educationsEndTime"
                    ></el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col style="padding: 0">
                  <el-form-item label-width="0">
                    <el-checkbox
                      v-model="editEducationList.isSoFar"
                      @change="checkedTime($event)"
                      >至今</el-checkbox
                    >
                  </el-form-item>
                </el-col>
                <el-col>
                  <el-form-item label-width="0">
                    <el-checkbox v-model="editEducationList.is_tongzhao"
                      >统招</el-checkbox
                    >
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="default"
          plain
          size="mini"
          @click="(dialogVisibleEdit = false), cancelEdit()"
          >取消</el-button
        >
        <el-button
          type="primary"
          :loading="btnLoading"
          size="mini"
          @click="saveEditInfos"
          >保存</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="文件夹管理"
      :visible.sync="fileManagement"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      append-to-body
      width="720px  "
      :before-close="handleDiaClose"
    >
      <file
        v-if="fileManagement"
        :type="type"
        @getFavoriteData="childByValue"
        :checkbox="isCheckbox"
      ></file>

      <span slot="footer" class="dialog-footer" v-if="isCheckbox.edit">
        <el-button
          size="mini"
          @click="saveFavorite"
          type="primary"
          :disabled="favoriteList.favoriteIds.length == 0"
          >保存</el-button
        >
      </span>
    </el-dialog>
    <!-- 新增日程 -->
    <el-dialog
      :title="ctitle"
      :visible.sync="scheduleDialog"
      width="600px"
      append-to-body
    >
      <caledarfrom
        ref="caledarfrom"
        v-if="scheduleDialog"
        :targetId="this.resumeId"
      ></caledarfrom>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" plain size="mini" @click="closeDig"
          >取消</el-button
        >
        <el-button type="primary" size="mini" @click="saveSchedule()"
          >保存</el-button
        >
      </span>
    </el-dialog>
    <!-- 上传附件 -->
    <el-dialog
      title="上传附件"
      :visible.sync="annexesVisible"
      width="600px"
      append-to-body
    >
      <annexesfrom
        ref="annexesfrom"
        :id="this.resumeId"
        v-if="annexesVisible"
      ></annexesfrom>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" plain size="mini" @click="annexescloseDig"
          >取消</el-button
        >
        <el-button type="primary" size="mini" @click="saveAnnexes()"
          >保存</el-button
        >
      </span>
    </el-dialog>
    <el-drawer
      :visible.sync="isDrawerVisible"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      append-to-body
      :modal="false"
      direction="ttb"
      custom-class="drawerClass"
    >
      <div slot="title" class="drawerTitle">
        <i class="drawIcon iconfont icon-pl_cjcw"></i>{{ titName1 }}
      </div>
      <div class="drawerCon">
        <div style="padding: 15px">
          <offer
            v-if="isOffer"
            ref="saveOffer"
            @childByValue="childByValue"
            :projectResumeId="projectResumeId1"
            @loadList="loadList(), loadResumeDetils(),(isDrawerVisible = false), cancel()"
          ></offer>
          <!-- 面试 -->
          <interview
            v-if="isInterview"
            ref="saveInterview"
            :resumeMemberId="resumeMemberId"
            :projectResumeId="projectResumeId1"
            :projectId="projectId1"
            :resumeId="resumeId"
            :iseditD="iseditD"
            @loadList="loadList(), loadResumeDetils()"
            @emailDrawerClose="emailDrawerClose"
          ></interview>
          <!--        简历推荐-->
          <recommend
            @childByValue="childByValue"
            v-if="isRecommend"
            ref="saveResumeRecommendation"
            :projectResumeId="projectResumeId1"
            :projectId="projectId1"
            :resumeId="resumeId"
            :iseditD="iseditD"
            @loadList="loadList(), loadResumeDetils()"
            @emailDrawerClose="emailDrawerClose"
          ></recommend>
        </div>

        <div class="drawerCon_footer fixedFoot">
          <el-button
            type="default"
            plain
            size="mini"
            @click="(isDrawerVisible = false), cancel()"
            >取消</el-button
          >
          <el-button
            type="primary"
            size="mini"
            style="margin-right: 15px"
            :loading="saveloading"
            @click="saveInfosProject"
            >保存</el-button
          >
        </div>
      </div>
    </el-drawer>
    <el-drawer
      :visible.sync="customerDrawerVisible"
      custom-class="drawerClass"
      direction="ttb"
      :modal="false"
      append-to-body
      :before-close="handleClose"
    >
      <div slot="title" class="drawerTitle">
        <i class="drawIcon iconfont icon-kh_xq"></i>客户
      </div>
      <div class="drawerCon">
        <customerInfo
          :customId="customId"
          :activeToInfo="activedToInfo"
          @loadList="loadList(), loadResumeDetils()"
        ></customerInfo>
      </div>
    </el-drawer>

    <!--  创建文件-->
    <el-dialog
      :visible.sync="createSecure"
      :modal-append-to-body="false"
      append-to-body
      title="设为保密"
      :close-on-click-modal="false"
      width="600px"
      destroy-on-close
    >
      <el-form
        v-if="createSecure"
        :model="secureList"
        ref="secureList"
        label-width="100px"
      >
        <el-col :span="24" style="margin-bottom: 15px">
          <div
            class="fl"
            style="width: 97px; line-height: 32px; text-align: right"
          >
            特殊开放给：
          </div>
          <div class="fr" style="width: calc(100% - 100px); position: relative">
            <span
              v-if="!isShowShare"
              style="height: 32px; line-height: 32px; cursor: pointer"
              class="defaultFont"
              @click="
                addShares('添加', 0), (isShowShare = true), (isShowBtn = false)
              "
              >+ 添加</span
            >

            <template v-if="isShowShare">
              <div
                class="shareRow"
                v-for="(i, inx) in secureList.shares"
                :key="i.id"
              >
                <el-select
                  style="width: 20%"
                  filterable
                  collapse-tags
                  v-model="i.type"
                  @change="diffType"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in shareTypes"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>

                <el-select
                  style="width: 58%; margin-left: 8px"
                  filterable
                  collapse-tags
                  multiple
                  v-if="i.type == '同事'"
                  v-model="i.shareIds"
                  @change="workmate($event, inx)"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in diffShareData"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>

                <el-cascader
                  style="width: 58%; margin-left: 8px"
                  v-if="i.type == '团队'"
                  v-model="i.teamIds"
                  class="fromInput"
                  collapse-tags
                  @change="teammate($event, inx)"
                  :options="teamValueOptions"
                  :props="teamProps"
                ></el-cascader>

                <span class="operationEditOrDel">
                  <span
                    class="miniBtn el-icon-plus"
                    @click="addShares(i, inx)"
                  ></span>
                  <span
                    class="miniBtn el-icon-close"
                    @click="delShares(i, inx)"
                  ></span>
                </span>
              </div>
            </template>
          </div>
        </el-col>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="default"
          plain
          size="mini"
          @click="createSecure = false"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="mini"
          :loading="saveloading"
          @click="addOrEditFavorite('secureList', 'Skip')"
          >跳过，直接保存</el-button
        >
        <el-button
          type="primary"
          size="mini"
          :loading="saveloading"
          @click="addOrEditFavorite('secureList')"
          >保存</el-button
        >
      </span>
    </el-dialog>
  </section>
</template>
<script>
import {
  getResumeById,
  addOrEditResume,
  fileDownload,
  contactStatus,
  getResumeProjectPageList,
  unlockResume,
  getCommonRecordList, //获取备注
  belloProject,
  replaceResumeInPage,
  belloCompany,
  belloSchool,
  getCommonColumnList,
  delResume,
  postContactStatus,
  canCreateFinanceTask,
  addReset,
  getResetStatus,
  reportWrite,
  offerGoodnewsDefaultData,
  getCommonTemplateReportList,
  removeProject,
  cancelOperate,
  addToFavorite, // 候选人移出项目
  entryStatus,
  offerStatus,
  interviewStatus,
  recommendStatus,
  latestComment,
  haveSend,
  changeMobileStatus,
  cityTree, //城市树
  updateFlagType, //更新标识
  resumeOffeOrEntryUnlock,
  securePermissionSecure, //设为保密
  userOptions, // 同事
  permissionTree, //组织架构
  securePermissionCancel, //取消保密
  securePermissionData, //保密数据
} from "../api/api";
import {
  statisticsResumeProject, //记录人才详情-项目tab点击量（包括下拉项点击及常规功能点击）
  statisticsResumeAddProject, //记录加入项目点击量
  statisticsResumeDetailShow, //记录人才搜索-详情显示量；通过搜索结果，打开人才详情一次即算一次
  statisticsResumeSearchResultClick, //人才搜索-全文检索结果点击量
  statisticsResumePositionAutomaticResultClick, //记录人岗自动匹配-自动匹配人才结果点击量
  statisticsSimilarResumeDetailShow, //记录项目-项目详情-相似职位tab-列表-人才详情显示量
  statisticsProjectPipelineOperate, //记录项目详情-候选人tab-操作系列功能点击量（包括置顶、反馈、标识）
  statisticsClickSimilarResumeClick, //记录相似人才点击量
  statisticsClickConnectionsClick, //记录人脉tab点击量
  statisticsResumeOperationOperate, //人才库-路径操作
  statisticsClickFunctionEscape, //记录功能或页面跳出
} from "../api/buryingPoint";
import * as data from "./../libs/data.json";
import resumeInfo from "./resumeInfo";
import file from "../components/file/file";
import addMarkReacode from "./remark/remark";
import progream from "../components/progream";
import { ApiBaseUrl, PreviewUrl } from "../api/http.js";
import Cookies from "js-cookie";
import editResumeList from "./editResumeList";
import FixedLengthSpan from "./common/FixedLengthSpan";
import calendar from "./resume/calendar";
import caledarfrom from "../components/resume/calendarfrom";
import journal from "../components/resume/journal";
import connections from "../components/resume/connections";
import annexes from "../components/resume/annexes";
import originalText from "../components/resume/originalText";
import annexesfrom from "../components/resume/annexesfrom";
import customerInfo from "../components/customer/customerInfo";
import happyNews from "./happyNews";
import interview from "./interview";
import recommend from "./recommend";
import enrty from "./enrty";
import offer from "./offer";
import backgroundSurvey from "./backgroundSurvey";
import financialTasks from "./pipeline/financialTasks";
import labelManagement from "./resume/labelManagement";
import whiteCollarWorker from "./whiteCollarWorker";
export default {
  components: {
    FixedLengthSpan,
    addMarkReacode,
    resumeInfo,
    file,
    progream,
    editResumeList,
    calendar, //日程列表
    caledarfrom, //日程表单
    journal, //日志
    annexes, //附件
    originalText, //原文
    annexesfrom, //附件上传
    happyNews,
    interview,
    recommend,
    enrty,
    backgroundSurvey,
    offer,
    financialTasks,
    customerInfo,
    connections, //人脉
    labelManagement, //标签管理
    whiteCollarWorker
  },
  props: [
    "resumeDetailId",
    "repeatId",
    "candiateId",
    "belloIdRepeat",
    "searchKey",
    "repeatResume",
    "hover",
    "source",
    "detailShowEntry",
    "searchInfo",
    "automaticData",
    "pipelineProjectId",
    "module",
    "searchObj",
    "operateId",
    "isShowRemark"
  ],
  data() {
    return {
      showMoreTag: true,
      hasMoreTags: false,
      feedBackStatus: null,
      dialogVisiblefeedBack: false,
      CommentWay: "", //反馈对应备注方案
      type: "人才",
      favoriteList: {
        favoriteIds: [],
        itemIds: [],
      },
      positionTitle: "",
      functionNameTitle: "",
      fileUrl: "",
      isCheckbox: {
        val: true,
        edit: false,
      },
      show: false,
      saveDisabled: true,
      popoverVisible: false,
      fileManagement: false,
      channelList: data.selectChannelList,
      isPoppoverShow: false,
      newResId: "",
      flag: true,
      btnLoading: false,
      globFlag: true,
      resumeImg: true,
      titleName: "",
      belloId: "",
      ownerId: "",
      contactStatusList: [
        { value: 1, label: "联系中" },
        { value: 2, label: "推荐" },
        { value: 3, label: "面试" },
        { value: 4, label: "待定" },
        { value: 5, label: "无状态" },
        { value: 6, label: "不考虑" },
        { value: 7, label: "不合适" },
        { value: 8, label: "黑名单" },
      ],
      flagList: [
        { color: "#d03a3a", value: "红旗" },
        { color: "#ff864a", value: "橙旗" },
        { color: "#44a9ff", value: "蓝旗" },
        { color: "#a9a9a9", value: "灰旗" },
        { color: "#3a3a3a", value: "黑旗" },
      ],
      menuList: JSON.parse(this.getStore("permiss")),
      showLock: false,
      showSecureLock: false,
      showUnlockLock: false,
      markName: "",
      actionUrl: ApiBaseUrl + "/api/file/uploadOne",
      myHeaders: {
        Authorization: Cookies.get("accessToken"),
      },
      advantages: [],
      advantageNames: [],
      risks: [],
      candidateProtect: [],
      skillLabels: [],
      allSkillLabels: [],
      customLabels: [],
      similarCount: null,
      similarName: null,
      profileLabels: [],
      upResumeData: {
        files: [],
        originalId: "",
      },
      fileInfoList: [],
      fileList: [],
      farResumeList: {},
      stadusList: [{ name: "联系中" }, { name: "无状态" }], //收藏夹列表
      Authorization: Cookies.get("accessToken"),
      useId: parseInt(this.getStore("uid")),
      // onlinePreview: ApiOnlinePreview,
      dialogVisibleEditStaus: false,
      dialogVisibleReplace: false,
      dialogVisibleEdit: false,
      visibleEmpoyments: false,
      visibleEducations: false,
      visibleProjects: false,
      editEmploymentList: {},
      editEducationList: {},
      editProjectList: {},
      currentIndex: "",
      stadusVal: "联系中",
      statusData: {
        status: "",
      },
      resumeId: "", //人才id
      projectResumeId: "", //项目候选人id
      resumeIds: [],
      list: [],
      ColumnInList: [], //行业
      ColumnCityList: [], // 城市列表
      formInlineBase: {}, //人才基本信息
      labels: [],
      projectsList: [],
      forwardVocationList: "",
      VocationList: [],
      locationList: [],
      locationTitle: [],
      VocationTitle: [],
      VocationIdList: [],
      locationIdList: [],
      forwardLocationList: "",
      showOrHide: "点击展示全部",
      isMoreRow: true,
      employmentsList: [],
      educationsList: [],
      SchoolFoo: "",
      companyFoo: "",
      timer: "",
      timer2: "",
      timer3: "",
      ProjectFoo: "",
      RecordList: [], //备注记录列表
      commentColumns: [], //备注记录列表

      limitOptions: [], //项目操作记录列表
      titName: "",
      titName1: "",
      titName2: "",
      // limitValue: "",
      fileName: "",
      operationLogList: [],
      activeNames: ["1"],
      isNo: true,
      activeNameRemark: "first",
      activeNameProject: 1,
      activeNameDetail: "first",
      istop: "50px",
      isborder: "20px",
      ispadding: "15px solid #f2f2f2",
      iseditCus: false,
      isRecommend: false,
      isInterview: false,
      isReset: false,
      defaultShowTable: true,
      resetList: [],
      vocationIdsList: [],
      functionIdsList: [],
      selectResetval: "",
      resetRuleForm: { resetStatus: "" },
      resetRules: {
        resetStatus: [{ required: true, message: "请输入状态" }],
      },
      isOffer: false,
      isDrawerVisible: false,
      isFail: false,
      isUpdateResume: false,
      resumeInfos: {},
      candidateInfo: {},
      cancelOperateId: "",
      resumeInfosTitle: "",
      vocationName: "", //职能
      cancelOperateStatus: "",
      resumeVocationIds: "",
      resumeFunctionIds: "",
      textFoo: "",
      resumeType: "",
      titNametype: "",
      resumeMemberId: "",
      width: "73%",
      projectResumeId1: "",
      companyId: "",
      dialogVisibleRemark: false,
      dialogVisibleRemark2: false,
      isaddMark: false,
      isaddMark1: false,
      iswriteReport: false,
      ishappyNews: false,
      isWhiteCollarWorker:false,
      isfinancialTask: false,
      isBackgroundSurvey: false,
      isEnrty: false,
      optionsReport: [],
      isaddTag: false,
      isaddPro: false,
      dialogVisible: false,
      dialogVisibleColdCall: false,
      delResumeDialog: false,
      saveloading: false,
      createTag: {
        resumeIds: [],
        labelIds: [],
      },
      Emrules: {
        company: { required: true, message: "请输入", trigger: "blur" },
        vocationIds: { required: true, message: "请选择", trigger: "change" },
        title: { required: true, message: "请输入", trigger: "blur" },
        summary: [
          { max: 1500, message: "超过1500个字数限制", trigger: "blur" },
        ],
        achievement: [
          { max: 1500, message: "超过1500个字数限制", trigger: "blur" },
        ],
      },
      Edrules: {
        school: { required: true, message: "请输入", trigger: "blur" },
        education: { required: true, message: "请输入", trigger: "change" },
        speciality: [
          { required: true, message: "请输入专业名称", trigger: "blur" },
        ],
        schoolType: { required: true, message: "请选择", trigger: "change" },
      },
      Prorules: {
        projectName: { required: true, message: "请输入", trigger: "blur" },
        projectDescription: [
          { max: 1500, message: "超过1500个字数限制", trigger: "blur" },
        ],
        responsibilities: [
          { max: 1500, message: "超过1500个字数限制", trigger: "blur" },
        ],
        achievements: [
          { max: 1500, message: "超过1500个字数限制", trigger: "blur" },
        ],
      },
      props: {
        value: "id",
        label: "name",
        children: "children",
        checkStrictly: true,
      },
      props1: {
        value: "id",
        label: "name",
        multiple: true,
        children: "children",
        expandTrigger: "hover",
        // checkStrictly: true,
      },
      propsempFunction1: {
        value: "id",
        label: "name",
        children: "children",
        multiple: true,
        checkStrictly: true,
        disabled: "hasChild",
      },
      propsempFunction: {
        value: "id",
        label: "name",
        children: "children",
        checkStrictly: true,
        multiple: true,
        disabled: "hasChild",
      },
      ColumnPosList: [], //职能列表
      ColumnPosList1: [], //职能列表
      scheduleDialog: false, //新增日程弹窗
      // 日期选择器时间限制
      employmentsStartTime: {
        disabledDate: (time) => {
          let endDateVal = this.editEmploymentList.endDate;
          if (endDateVal) {
            return time.getTime() > new Date(endDateVal).getTime();
          } else {
            return time.getTime() > Date.now();
          }
        },
      },
      employmentsEndTime: {
        disabledDate: (time) => {
          let beginDateVal = this.editEmploymentList.startDate;
          if (beginDateVal) {
            return (
              time.getTime() < new Date(beginDateVal).getTime() ||
              time.getTime() > Date.now()
            );
          } else {
            return time.getTime() > Date.now();
          }
        },
      },
      educationsStartTime: {
        disabledDate: (time) => {
          let endDateVal = this.editEducationList.endDate;
          if (endDateVal) {
            return time.getTime() > new Date(endDateVal).getTime();
          } else {
            return time.getTime() > Date.now();
          }
        },
      },
      educationsEndTime: {
        disabledDate: (time) => {
          let beginDateVal = this.editEducationList.startDate;
          if (beginDateVal) {
            return (
              time.getTime() < new Date(beginDateVal).getTime() ||
              time.getTime() > Date.now()
            );
          } else {
            return time.getTime() > Date.now();
          }
        },
      },
      projectsStartTime: {
        disabledDate: (time) => {
          let endDateVal = this.editProjectList.endDate;
          if (endDateVal) {
            return time.getTime() > new Date(endDateVal).getTime();
          } else {
            return time.getTime() > Date.now();
          }
        },
      },
      projectsEndTime: {
        disabledDate: (time) => {
          let beginDateVal = this.editProjectList.startDate;
          if (beginDateVal) {
            return (
              time.getTime() < new Date(beginDateVal).getTime() ||
              time.getTime() > Date.now()
            );
          } else {
            return time.getTime() > Date.now();
          }
        },
      },
      originalTextNum: null, //简历原文数量
      annexesNum: null, //附件数量
      calendarNum: null, //日程数量
      connectionCount: null, //人脉数量
      annexesVisible: false, //上传附件
      ctitle: "新增日程",
      iseditD: false,
      isloading: false,
      activedToInfo: "1",
      customerDrawerVisible: false,
      customId: "",

      projectEntry: "",
      createSecure: false, //设为保密
      secureList: {
        resumeId: "",
        shares: [
          // {
          //   shareIds: [],
          //   teamIds: [],
          //   type: "同事",
          // },
        ],
        type: "",
        version: 0,
      },
      shareTypes: ["同事", "团队"],
      teamValue: "",
      teamProps: {
        label: "name",
        value: "id",
        multiple: true,
      },
      teamValueOptions: [],
      shareData: [],
      isShowShare: false,
      diffShareData: [],
      diffTypeVal: "",
      isShowBtn: true,
      isShowContact: false,
      isAnnouncement:false
    };
  },
  watch: {
    $route() {
      this.resumeId = this.resumeDetailId;
      this.loadResumeDetils(this.resumeDetailId);
    },
    "$route.query.diaTrue": function (value) {
      if (value) {
        this.titName = "编辑人才信息";
        this.dialogVisible = true;
        this.iseditCus = true;
      }
    },
    belloId(nVal, oVal) {
      if (nVal) {
        this.resumeImg = true;
      } else {
        this.resumeImg = false;
      }
    },
  },

  computed: {
    salaryTitle() {
      return (
        this.resumeInfos.lowAimSalary +
        (this.resumeInfos.highAimSalary ? "-" : "") +
        this.resumeInfos.highAimSalary
        // +"元/月"
      );
    },
    buttonStyle() {
      return (val) => {
        let buttonStyle = {};
        this.channelList.forEach((item) => {
          if (val == item.value) {
            buttonStyle = {
              color: `rgba(${item.color}, 1)`,
              backgroundColor: `rgba(${item.color}, .1)`,
              borderColor: `rgba(${item.color}, .2)`,
            };
          }
        });
        return buttonStyle;
      };
    },
    totalExp() {
      return (a1, a2, a3) => {
        let yearNum = null;
        let mounthNum = null;
        let endDate = null;
        if (a3) {
          endDate = new Date().Format("yyyy-MM");
        } else {
          endDate = a2;
        }
        let endDateArr = endDate.split("-");
        let startDateArr = a1.split("-");
        let endDateMounth = Number(endDateArr[0]) * 12 + Number(endDateArr[1]);
        let startDateMounth =
          Number(startDateArr[0]) * 12 + Number(startDateArr[1]);
        let intervalMonths = endDateMounth - startDateMounth; //相差总月数
        yearNum = Math.floor(intervalMonths / 12);
        mounthNum = intervalMonths % 12;
        return (
          (yearNum > 0 ? yearNum + "年" : "") +
          (mounthNum > 0 ? mounthNum + "个月" : "")
        );
      };
    },
    activeNamesRecord: {
      get() {
        return this.RecordList.map((i) => {
          return i.id;
        });
      },
      set(newValue) {
        return newValue;
      },
    },
  },
  methods: {
    // 反馈
    saveFeedBack(row) {
      if (!this.feedBackStatus) {
        this.$message.error("请选择反馈状态");
        return;
      }
      let commentRecord = {
        commentColumns: [],
        commentId: this.CommentWay.id,
        id: 0,
        type: this.CommentWay.type,
        version: 0,
      };
      let columns = this.CommentWay.columns;
      for (let i = 0; i < columns.length; i++) {
        if (
          columns[i].required &&
          (columns[i].answer == "" || columns[i].answer == null) &&
          columns[i].answer1.length == 0
        ) {
          this.$message.error("请将必填项填写完整");
          return false;
        }
      }
      columns.map((item) => {
        commentRecord.commentColumns.push({
          columnTitle: item.title,
          commentColumnId: item.id,
          type: item.type,
          value: item.type == "多选型" ? item.answer1.join(",") : item.answer,
        });
      });
      // 反馈
      if (this.titNametype == "简历推荐") {
        this.loadFeedBack(recommendStatus, {
          id: this.projectResumeId,
          recommendStatus: this.feedBackStatus,
          commentRecord: commentRecord,
        });
      } else if (this.titNametype == "客户面试") {
        this.loadFeedBack(interviewStatus, {
          id: this.projectResumeId,
          interviewStatus: this.feedBackStatus,
          commentRecord: commentRecord,
        });
      } else if (this.titNametype == "Offer") {
        this.loadFeedBack(offerStatus, {
          id: this.projectResumeId,
          offerStatus: this.feedBackStatus,
          commentRecord: commentRecord,
        });
      } else if (this.titNametype == "入职") {
        this.loadFeedBack(entryStatus, {
          id: this.projectResumeId,
          entryStatus: this.feedBackStatus,
          commentRecord: commentRecord,
        });
      }
    },
    loadFeedBack(apiName, queryDate) {
      apiName(queryDate).then((res) => {
        if (res.success) {
          this.$message.success("操作成功");
          this.dialogVisiblefeedBack = false;
          this.getResumeProjectPageList();
        }else{
          this.dialogVisiblefeedBack = false
        }
      });
    },
    saveFavorite() {
      this.favoriteList.itemIds = [this.resumeDetailId];
      addToFavorite(this.favoriteList).then((res) => {
        this.fileManagement = false;
        this.$message.success(res.message);
        // this.getListFavorite();
      });
    },
    clickStatus(command) {
      this.handleStatisticsResumeOperationOperate("状态标注", "详情");
      contactStatus({ id: this.resumeDetailId, contactStatus: command }).then(
        (res) => {
          this.loadResumeDetils(this.resumeDetailId);
        }
      );
    },
    clickStatus1(command) {
      this.handleStatisticsResumeOperationOperate("电话标注", "详情");
      let val = null;
      if (this.resumeInfos.mobileStatus !== command) {
        val = command;
      } else {
        val = null;
      }
      changeMobileStatus({ resumeId: this.resumeDetailId, status: val }).then(
        (res) => {
          this.loadResumeDetils(this.resumeDetailId);
        }
      );
    },
    childByValue(childValue) {
      this.favoriteList.favoriteIds = childValue;
    },
    showPosition(val) {
      const div = document.createElement("div");
      div.innerHTML = val;
      this.positionTitle = div.innerText;
    },
    /**
     * 通用高亮函数
     * val
     * 职能，
     */
    currentFun(val, name) {
      const div = document.createElement("div");

      if (name == "职能") {
        div.innerHTML = val.functionName;
        this.functionNameTitle = div.innerText;
      }
    },
    showResumeInfosTitle(val) {
      const div = document.createElement("div");
      div.innerHTML = val;
      this.resumeInfosTitle = div.innerText;
    },
    // 弹框关闭的时候重新调接口
    closeDialog() {
      // this.globFlag = true
      if (this.$refs["editEducationList"]) {
        this.$refs["editEducationList"].resetFields();
      }
    },

    handleFilesSuccess(res, file, fileList) {
      fileList.forEach((item) => {
        if (item.response) {
          this.uploadFiles.files.push({
            fileName: item.name,
            filePath: item.response.result,
          });
        } else {
          this.uploadFiles.files.push({
            fileName: item.name,
            filePath: item.filepath,
          });
        }
      });
    },
    beforeAvatarUpload(file) {
      const isLt10M = file.size / 1024 / 1024 < 20;
      if (!isLt10M) {
        this.$message.error("上传文件大小不能超过 20MB!");
      }
      return isLt10M;
    },
    handleCommand(e, i) {
      this.hanldeStatisticsResumeProject("联系状态", i);
      postContactStatus({
        contactStatus: e,
        id: i.projectResumeId,
      }).then((res) => {
        this.getResumeProjectPageList();
        this.loadResumeDetils();
      });
    },
    flagShow(val, i) {
      if (val) {
        this.handelStatisticsProjectPipelineOperate("标识", i);
      }
    },
    //标识
    handleFlag(e, i) {
      //更新标识
      updateFlagType({
        flagType: e,
        projectResumeId: i.projectResumeId,
      }).then((res) => {
        if (res.success) {
          this.getResumeProjectPageList();
          this.loadResumeDetils();
        }
      });
    },
    changeSaveLoading(val) {
      this.saveloading = val;
    },
    // 撤销
    cancelOperate() {
      cancelOperate({
        projectResumeId: this.cancelOperateId,
        status: this.cancelOperateStatus,
      }).then((res) => {
        if (res.success) {
          // debugger
          // this.$refs.status.blur();
          this.$message({
            message: "撤销成功",
            type: "success",
          });
          this.getResumeProjectPageList();
        }
      });
    },
    // 完善简历
    perfectResume() {
      this.dialogVisibleColdCall = false;
      this.editCus();
    },
    // 直接备注
    goRemark() {
      // debugger
      if (this.textFoo == "添加备注") {
        this.titName2 = "添加备注";
        this.isaddMark1 = true;
      } else if (this.textFoo == "内部面试") {
        this.titName2 = "内部面试";
        this.titNametype = "内部面试";
        this.isaddMark1 = true;
      } else if (this.textFoo == "F2F面试") {
        this.isNo = false;
        this.titNametype = "F2F面试";
        this.titName2 = "添加备注";
        this.isaddMark1 = true;
      } else if (this.textFoo == "添加") {
        this.isNo = false;
        this.titName2 = "添加备注";
        this.isaddMark = true;
      }
      // this.projectResumeId = projectResumeId;
      this.dialogVisibleRemark2 = true;
      if (this.dialogVisibleRemark2) {
        this.dialogVisibleColdCall = false;
      }
    },
    preOrdown(item) {
      const fileType =
        item.fileName.split(".")[item.fileName.split(".").length - 1];
      if (
        fileType == "doc" ||
        fileType == "docx" ||
        fileType == "xls" ||
        fileType == "xlsx" ||
        fileType == "pptx" ||
        fileType == "ppt"
      ) {
        //wps预览
        window.open(
          "https://view.officeapps.live.com/op/view.aspx?src=" +
            PreviewUrl +
            item.fileUrl,
          "_blank"
        );
      } else if (fileType == "pdf") {
        //pdf预览
        window.open(
          "/pdf/web/viewer.html?file=" + PreviewUrl + item.fileUrl,
          "_blank"
        );
      } else if (fileType == "png" || fileType == "jpg" || fileType == "jpeg") {
        //图片预览
        window.open(PreviewUrl + item.fileUrl, "_blank");
      } else if (fileType) {
        //下载
        this.fileDownload(item);
      }
    },
    fileDownload(file) {
      fileDownload({ fileName: file.fileName, filePath: file.fileUrl }).then(
        (res) => {
          if (!res) {
            this.$message.error("下载内容为空");
            return;
          }
          let url = window.URL.createObjectURL(new Blob([res]));
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          link.setAttribute("download", file.fileName);

          document.body.appendChild(link);
          link.click();
          //释放URL对象所占资源
          window.URL.revokeObjectURL(url);
          //用完即删
          document.body.removeChild(link);
        }
      );
    },
    //内部面试internalInterval
    internalInterval(i, text) {
      this.handelStatisticsProjectPipelineOperate(text, i);
      this.hanldeStatisticsResumeProject(text, i);
      this.textFoo = text;
      this.projectResumeId = i.projectResumeId;
      if (this.resumeInfos.resumeType == "候选人") {
        this.titName2 = "内部面试";
        this.titNametype = "内部面试";
        this.dialogVisibleRemark2 = true;
        this.isaddMark1 = true;
        if (text == "F2F面试") {
          this.titName2 = "F2F面试";
          this.titNametype = "F2F面试";
        }
      } else {
        this.dialogVisibleColdCall = true;
      }
    },
    //写报告
    writeReport(val, i) {
      this.handelStatisticsProjectPipelineOperate("写报告", i);
      this.projectResumeId1 = val;
      this.dialogVisibleRemark = true;
      this.titName1 = "写报告";
      this.width = "40%";
      this.getCommonTemplateReportList("候选人推荐报告");
      this.getCommonTemplateReportList("背景调查报告");
      this.iswriteReport = true;
    },
    goProject(id) {
      let newpage = this.$router.resolve({
        path: "/project/details",
        query: { id: id },
      });
      window.open(newpage.href, "_blank");
    },
    // 创建财务任务
    createdFunction(val, index, row) {
      this.handelStatisticsProjectPipelineOperate("创建财务任务", row);
      this.hanldeStatisticsResumeProject("创建财务任务", row);
      let permTypes = this.getStore("permiss");
      this.projectResumeId1 = row.projectResumeId;
      this.companyId = row.companyId;
      if (permTypes.includes("progream:index:task")) {
        //有权限
        if (this.resumeInfos.resumeType == "候选人") {
          canCreateFinanceTask({
            projectResumeId: this.projectResumeId1,
          }).then((res) => {
            if (res.success) {
              this.isfinancialTask = true;
              this.titName1 = "创建财务任务";
            }
          });
        } else {
          let div =
            "<p style='font-weight:bold'>无法创建财务任务</p>" +
            "<div>原因：人才的类型不能为【cold call】，必须是【候选人】</div>";
          this.$confirm(div, "提示", {
            confirmButtonText: "完善人才",
            cancelButtonText: "取消",
            customClass: "altCls",
            dangerouslyUseHTMLString: true,
          })
            .then(() => {
              this.perfectResume();
            })
            .catch(() => {});
        }
      } else {
        //无权限
        this.$alert(
          "您的角色未配置创建财务任务权限，如有需要请联系管理员配置",
          "提示",
          {
            confirmButtonText: "确定",
            showClose: false,
            callback: (action) => {},
          }
        );
      }

      // this.ishappyNews = true;
    },
    // 移出项目
    removeProgream(i) {
      this.handelStatisticsProjectPipelineOperate("移出项目", i);
      this.hanldeStatisticsResumeProject("移出项目", i);
      this.$confirm("请确认是否移出项目", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "altCls",
        type: "warning",
      })
        .then(() => {
          removeProject({ id: i.projectResumeId }).then((res) => {
            if (res.success) {
              this.$message({
                type: "success",
                message: "移出成功!",
              });
              this.getResumeProjectPageList();
              this.loadResumeDetils(this.resumeDetailId);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消移出",
          });
        });
    },
    //添加备注
    addRemark(projectResumeId, status, i) {
      // debugger
      // console.log(projectResumeId, status, i)
      //  statisticsProjectPipelineOperate({
      //   entry: '人才-项目tab',
      //   functionName: '反馈',
      //   phase: status,
      //   projectId: i.projectId,
      //   resumeId: this.resumeId,
      // }).then();
      this.handelStatisticsProjectPipelineOperate("反馈", i);
      this.hanldeStatisticsResumeProject("反馈", i);
      this.projectResumeId = projectResumeId;
      this.titName2 = "反馈" + " - " + status;
      this.dialogVisiblefeedBack = true;
      this.isaddMark1 = true;
      this.titNametype = status;
      // this.projectId = i.projectId;
      // this.feedBackStatus = status;
      latestComment({ type: status }).then((res) => {
        if (res.success) {
          this.CommentWay = res.result;
          this.CommentWay.columns.forEach((item) => {
            this.$set(item, "answer1", []);
            this.$set(item, "answer", "");
          });
        }
      });
    },
    // 背调
    backgroundSurvey(val) {
      this.handelStatisticsProjectPipelineOperate("背景调查", val);
      this.projectResumeId1 = val.projectResumeId;
      this.titName1 = "背景调查";
      this.width = "40%";
      this.dialogVisibleRemark = true;
      this.isBackgroundSurvey = true;
      this.hanldeStatisticsResumeProject("背景调查", val);
    },

    // 发生改变 change val名字—+row整行+index
    getPropName(val, row, index) {
      this.cancelOperateId = row.projectResumeId;
      this.cancelOperateStatus = row.status;
      this.resumeMemberId = row.projectResumeId;
      this.cancel();
      val = val.replace(/\s*/g, "");
      this.getStatus(val, row, index);
      if (!val) {
        this.dialogVisibleRemark = false;
      }
      if (val == "撤销") {
        this.dialogVisibleRemark = false;
        this.$confirm(
          "撤销后将回退到上一环节，并且上一次操作不计入KPI，确认撤销?",
          "撤销",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            this.cancelOperate();
          })
          .catch(() => {});
      }
      this.hanldeStatisticsResumeProject(val, row);
      this.handelStatisticsProjectPipelineOperate(val, row);
    },
    hanldeStatisticsResumeProject(val, row) {
      statisticsResumeProject({
        functionName: val,
        projectId: row.projectId,
        resumeId: this.resumeId,
      }).then();
    },
    offerGoodnewsDefaultData: function () {
      offerGoodnewsDefaultData({
        projectResumeId: this.projectResumeId1,
      }).then((res) => {
        if (res.success) {
          if (this.resumeInfos.resumeType != "候选人") {
            let div =
              "<p style='font-weight:bold'>无法发喜报</p>" +
              "<div>原因：人才的类型不能为【cold call】，必须是【候选人】</div>";
            this.$confirm(div, "提示", {
              confirmButtonText: "完善人才",
              cancelButtonText: "取消",
              customClass: "altCls",
              dangerouslyUseHTMLString: true,
            })
              .then(() => {
                this.perfectResume();
              })
              .catch(() => {});
          } else {
            let _this=this
            haveSend({
            projectResumeId: this.projectResumeId1,
          }).then((res1) => {
            if (res1.success) {
              if (res1.result) {
                _this.isAnnouncement=false
                //  if(res.result.businessType=='HEAD_HUNTER'){
                if(res.result.businessType=='WHITE_COLLAR'){
                    _this.isWhiteCollarWorker=true
                    _this.dialogVisibleRemark = true;
                  }else{
                _this.isOffer = true;
                _this.isDrawerVisible = true;
                _this.dialogVisibleRemark = false;
                setTimeout(function () {
                  _this.$refs.saveOffer.loadEditInfo();
                }, 100);
              }
              } else {
                _this.isAnnouncement=true
                //  if(res.result.businessType=='HEAD_HUNTER'){
                if(res.result.businessType=='WHITE_COLLAR'){
                    _this.dialogVisibleRemark=true
                    _this.isWhiteCollarWorker = true;
                  }else{
                // 判断人选是否为候选人
                // this.ishappyNews = true;
                // this.offerGoodnewsDefaultData();
                this.dialogVisibleRemark = true;
            this.ishappyNews = true;
                  }
              }
            }
          });
          }
        }
      });
    },
    // 点击获取index onfocus index + 整行 + 名字

    getIndex(index, val, lk) {
      this.cancel();
      this.limitOptions = this.limitOptions.map((i) => ({
        ...i,
        disabled: true,
      }));

      if (val.status == "加入项目") {
        this.limitOptions[1].disabled = false;
      } else if (val.status == "简历推荐") {
        this.limitOptions[2].disabled = false;
        this.limitOptions[7].disabled = false;
      } else if (val.status == "客户面试") {
        this.limitOptions[3].disabled = false;
        this.limitOptions[2].hide = true;
        this.limitOptions[3].hide = false;
        this.limitOptions[4].disabled = false;
        this.limitOptions[7].disabled = false;
      } else if (val.status == "Offer") {
        this.limitOptions[5].disabled = false;
        // this.limitOptions[7].disabled = false;
      } else if (val.status == "入职") {
        this.limitOptions[7].disabled = false;
      } else if (val.status == "失败") {
        this.limitOptions[6].disabled = false;
        this.limitOptions[7].disabled = false;
      }
    },
    // 获取状态 change事件 val名字—+row整行+index
    getStatus(val, row, index) {
      if (this.resumeInfos.resumeType == "coldcall") {
        let div =
          "<p style='font-weight:bold'>无法推荐人才</p>" +
          "<div>原因：推荐人才的类型不能为【cold call】，必须是【候选人】</div>";
        this.$confirm(div, "提示", {
          confirmButtonText: "完善人才",
          cancelButtonText: "取消",
          customClass: "altCls",
          dangerouslyUseHTMLString: true,
        }).then(() => {
          this.editCus();
        });
      } else {
        this.projectResumeId1 = row.projectResumeId;
        this.projectId1 = row.projectId;
        // if (val == "加入项目") {
        // } else
        if (val == "简历推荐") {
          this.isRecommend = true;
          this.iseditD = false;
          this.isDrawerVisible = true;
          this.titName1 = "简历推荐";
        } else if (val == "客户面试") {
          this.isInterview = true;
          this.iseditD = false;
          this.isDrawerVisible = true;
          this.titName1 = "客户面试";
        } else if (val == "Offer") {
          this.titName1 = "Offer";
          let _this = this;
          // this.projectResumeId1 = row.projectResumeId;
          this.offerGoodnewsDefaultData();
          // haveSend({
          //   projectResumeId: row.projectResumeId,
          // }).then((res) => {
          //   if (res.success) {
          //     if (res.result) {
          //       _this.isOffer = true;
          //       _this.isDrawerVisible = true;
          //       _this.dialogVisibleRemark = false;
          //       setTimeout(function () {
          //         _this.$refs.saveOffer.loadEditInfo();
          //       }, 100);
          //     } else {
          //       // 判断人选是否为候选人
          //       this.ishappyNews = true;
          //       this.offerGoodnewsDefaultData();
          //     }
          //   }
          // });
        } else if (val == "入职") {
          this.dialogVisibleRemark = false;
          canCreateFinanceTask({
            projectResumeId: this.projectResumeId1,
          }).then((res) => {
            if (res.success) {
              this.dialogVisibleRemark = true;
              this.isEnrty = true;
              this.titName1 = "入职";
            }
          });
        } else if (val == "失败") {
          this.isFail = true;
          this.titName1 = "失败";
        } else if (val == "重置") {
          this.titName1 = "重置";
          this.isReset = true;
          this.getResetList();
        }
        if (
          val !== "入职" &&
          val !== "Offer" &&
          val !== "简历推荐" &&
          val !== "客户面试"
        ) {
          this.dialogVisibleRemark = true;
        }
      }
    },
    selectReset(val) {
      this.selectResetval = val;
    },
    // 保存项目
    saveInfosProject() {
      this.saveloading = true;
      //备注记录
      if (this.isaddMark) {
        this.$refs.saveMark.saveMarkRecode().finally(() => {
          this.saveloading = false;
        });
        this.dialogVisibleColdCall = false;
      }
      // 客户面试
      if (this.isInterview) {
        this.$refs.saveInterview.saveInterview("interviewForm").finally(() => {
          this.saveloading = false;
          this.iseditD = false;
          this.isDrawerVisible = false;
        });
        this.dialogVisibleColdCall = false;
      }
      // 简历推荐
      if (this.isRecommend) {
        this.$refs.saveResumeRecommendation
          .saveResumeRecommendation("resumeRecommendationForm")
          .finally(() => {
            this.saveloading = false;
            this.iseditD = false;
            this.isDrawerVisible = false;
          });
      }
      // 背调
      if (this.isBackgroundSurvey) {
        this.projectResumeId1 = this.projectResumeId1;
        this.$refs.saveBackgroundSurvey
          .saveBackgroundSurvey("backgroundSurveyForm")
          .finally(() => {
            this.saveloading = false;
          });
      }
      // 创建财务任务
      if (this.isfinancialTask) {
        this.$refs.saveFinancialTask.saveFinancialTask().finally(() => {
          this.saveloading = false;
        });
      }
      // offer
      if (this.isOffer) {
        this.$refs.saveOffer.saveOffer("offerForm").finally(() => {
          this.saveloading = false;
          // this.isDrawerVisible = false;
        });
      }
      //入职
      if (this.isEnrty) {
        this.$refs.saveEntry.saveEntry("enrtyForm").finally(() => {
          this.saveloading = false;
        });
      }
      // 失败
      if (this.isFail) {
        this.$refs.saveFail.saveFail("failForm").finally(() => {
          this.saveloading = false;
        });
      }
      // 内部面试
      if (this.isaddMark1) {
        this.$refs.saveMark.saveMarkRecode().finally(() => {
          this.saveloading = false;
        });
        this.dialogVisibleColdCall = false;
      }
      // 发喜报
      if (this.ishappyNews) {
        this.$refs.saveHappyNews
          .saveHappyNews()
          .then(() => {
            this.saveloading = false;
            this.loadResumeDetils(this.resumeDetailId);
          })
          .catch(() => {
            this.saveloading = false;
          });
      }
      //白领
      if (this.isWhiteCollarWorker) {
        this.$refs.whiteCollarWorker
          .saveForm('whiteCollarWorkerForm')
          .then(() => {
            this.saveloading = false;
          })
          .catch(() => {
            this.saveloading = false;
          });
      }
      //重置
      if (this.isReset) {
        this.$refs.resetRuleForm.validate((valid) => {
          if (valid) {
            addReset({
              id: 0,
              objectContent: {
                status: this.resetRuleForm.resetStatus,
              },
              projectResumeId: this.projectResumeId1,
              version: 0,
            }).then((res) => {
              this.saveloading = false;
              if (res.success) {
                this.$message.success("重置成功");
                this.cancel();
                this.dialogVisibleRemark = false;
                this.getResumeProjectPageList();
                if (this.selectResetval == "简历推荐") {
                  this.loadResumeDetils();
                }
              }
            });
          } else {
            this.saveloading = false;
            this.$message("请输入状态");
          }
        });
      }
      // this.getResumeProjectPageList();
    },
    getCommonTemplateReportList: function (type) {
      getCommonTemplateReportList({
        type: type,
      }).then((res) => {
        if (res.success) {
          this.optionsReport = res.result;
        }
      });
    },
    downLoadReport(id) {
      reportWrite({
        projectResumeId: this.projectResumeId1,
        templateId: id,
      }).then((res) => {
        this.saveloading = false;
        var blob = new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document;charset=utf-8",
        }); //application/vnd.openxmlformats-officedocument.wordprocessingml.document这里表示doc类型
        var contentDisposition = res.headers["content-disposition"]; //从response的headers中获取filename, 后端response.setHeader("Content-disposition", "attachment; filename=xxxx.docx") 设置的文件名;
        var patt = new RegExp("filename=([^;]+\\.[^\\.;]+);*");
        var result = patt.exec(contentDisposition);
        var filename = result[1];
        var downloadElement = document.createElement("a");
        var href = window.URL.createObjectURL(blob); //创建下载的链接
        var reg = /^["](.*)["]$/g;
        downloadElement.style.display = "none";
        downloadElement.href = href;
        downloadElement.download = decodeURI(filename.replace(reg, "$1")); //下载后文件名
        document.body.appendChild(downloadElement);
        downloadElement.click(); //点击下载
        document.body.removeChild(downloadElement); //下载完成移除元素
        window.URL.revokeObjectURL(href); //释放掉blob对象
      });
    },
    getResetList: function () {
      getResetStatus({
        id: this.projectResumeId1,
      }).then((res) => {
        if (res.success) {
          this.resetList = res.result;
        }
      });
    },
    loadList() {
      this.cancel();
      this.getResumeProjectPageList();
      this.dialogVisibleRemark = false;
    },
    // 项目列表
    getResumeProjectPageList() {
      if (!this.resumeDetailId) {
        this.projectResumeId1 = this.resumeDetailId;
      }
      return new Promise((resolve) => {
        getResumeProjectPageList({
          resumeId:
            this.resumeDetailId || this.projectResumeId1 || this.resumeId,
        }).then((res) => {
          if (res.success) {
            this.operationLogList = res.result;
            if (this.operationLogList.length > 0) {
              this.operationLogList = this.operationLogList.map((i) => ({
                ...i,
                limitValue: i.status,
              }));
            }
          }
          resolve();
        });
      });
    },
    projectFoo(id) {
      this.timer3 = setTimeout(() => {
        belloProject({ projectName: id }).then((res) => {
          if (res.success) {
            this.ProjectFoo = res.result;
          }
        });
      }, 1500);
    },
    clearbelloProject() {
      if (this.timer3) {
        clearInterval(this.timer3);
      }
    },
    belloCompany(id) {
      this.timer = setTimeout(() => {
        belloCompany({ companyName: id }).then((res) => {
          if (res.success) {
            this.companyFoo = res.result;
          }
        });
      }, 1500);
    },
    clearbelloCompany() {
      if (this.timer) {
        clearInterval(this.timer);
      }
    },
    belloSchool(id) {
      this.timer2 = setTimeout(() => {
        this.isMoreRow = true;
        belloSchool({ schoolName: id }).then((res) => {
          if (res.success) {
            this.SchoolFoo = res.result;
          }
        });
      }, 1500);
    },
    clearbelloSchool() {
      if (this.timer2) {
        clearInterval(this.timer2);
      }
    },
    // 点击展示全部
    clickShowAll(id) {
      this.isMoreRow = !this.isMoreRow;
      if (this.isMoreRow) {
        this.showOrHide = "点击展示全部";
      } else {
        this.showOrHide = "点击隐藏";
      }
    },
    loadColumnInList(type) {
      //获取行业列表
      this.ColumnInList = [];
      getCommonColumnList({ type: type }).then((res) => {
        if (res.success) {
          this.ColumnInList = res.result;
        }
      });
    },
    loadColumnPosList(type) {
      //获取职能列表
      this.ColumnPosList = [];
      getCommonColumnList({ type: type }).then((res) => {
        if (res.success) {
          this.ColumnPosList = res.result;
          this.ColumnPosList1 = res.result;
          // this.ColumnPosList1.forEach(item=>{
          //   this.$set(item,'disabled',true)
          // })
          //  for (let i = 0; i < this.ColumnPosList1.length; i++) {
          //  this.ColumnPosList1[i].disabled=true
          // }
          // this.ColumnPosList1 = this.ColumnPosList1.map((i) => ({
          //   i.disabled true,
          // }));
        }
      });
    },
    loadColumnCityList(type) {
      //获取城市列表
      this.ColumnCityList = [];
      cityTree().then((res) => {
        if (res.success) {
          this.ColumnCityList = res.result;
        }
      });
    },
    editIcon(type) {
      this.$refs[`${type}`].$el.style.visibility = "visible";
      this.flag = false;
    },
    hideEditIcon(type) {
      this.flag = true;
      this.$refs[`${type}`].$el.style.visibility = "hidden";
    },
    showEdit(type) {
      this.$refs[`${type}`].$el.style.visibility = "visible";
    },
    hideEdit(type) {
      this.$refs[`${type}`].$el.style.visibility = "hidden";
    },
    copyContent(content) {
      var aux = document.createElement("input");
      aux.setAttribute("value", content);
      document.body.appendChild(aux);
      aux.select();
      document.execCommand("copy");
      document.body.removeChild(aux);
      this.$message({
        message: "复制成功",
        type: "success",
      });
    },
    // 编辑经历
    editExperience(i, type) {
      this.globFlag = false;

      this.currentIndex = i;
      this.dialogVisibleEdit = true;
      this.visibleEmpoyments = false;
      this.visibleProjects = false;
      this.visibleEducations = false;
      if (type == "employments") {
        this.loadResumeDetils(this.resumeDetailId || this.resumeId).then(() => {
          this.editEmploymentList = JSON.parse(
            JSON.stringify(this.employmentsList[i])
          );
        }); //点击编辑重新加载数据，确保获取到最新数据

        this.titleName = "编辑工作经历";
        this.visibleEmpoyments = true;
      } else if (type == "projects") {
        this.loadResumeDetils(this.resumeDetailId || this.resumeId).then(() => {
          this.editProjectList = JSON.parse(
            JSON.stringify(this.projectsList[i])
          );
        });
        //点击编辑重新加载数据，确保获取到最新数据
        this.titleName = "编辑项目经历";
        this.visibleProjects = true;
      } else {
        this.loadResumeDetils(this.resumeDetailId || this.resumeId).then(() => {
          this.editEducationList = JSON.parse(
            JSON.stringify(this.educationsList[i])
          );
        });
        //点击编辑重新加载数据，确保获取到最新数据

        this.titleName = "编辑教育经历";
        this.visibleEducations = true;
      }
    },
    checkedTime(e) {
      //至今
      this.editEmploymentList.endDate = "";
      this.editEducationList.endDate = "";
      this.editProjectList.endDate = "";
    },
    cancelEdit() {
      this.dialogVisibleEdit = false;
      this.currentIndex = "";
    },
    //删除左右两端的空格
    trim(str) {
      let a = "";
      a = str.replace(/(^\s*)|(\s*$)/g, "");
      return a;
    },
    saveEditInfos() {
      if (this.visibleEmpoyments) {
        // 保存工作经历
        this.$refs["editEmploymentList"].validate((valid) => {
          this.editEmploymentList.title = this.trim(
            this.editEmploymentList.title
          );
          if (
            this.editEmploymentList.underlingNumber < 0 ||
            this.editEmploymentList.salary < 0
          ) {
            this.$message.error("下属人数或者月薪不能为负！");
            return false;
          }
          if (
            this.editEmploymentList.vocationIds.length > 5 ||
            this.editEmploymentList.functionIds.length > 5
          ) {
            this.$message.error("职能或者行业不能超过5个！");
            return false;
          }
          if (valid) {
            this.btnLoading = true;
            this.employmentsList[this.currentIndex] = this.editEmploymentList;
            addOrEditResume({
              educationsList: this.educationsList,
              employmentsList: this.employmentsList,
              forwardLocationIds: this.locationIdList,
              forwardVocationIds: this.VocationIdList,
              projectsList: this.projectsList,
              resumeVocationIds: this.resumeVocationIds,
              resumeFunctionIds: this.resumeFunctionIds,
              resumeInfo: this.resumeInfos,
              doNotCheckRepeated: false,
            }).then((res) => {
              if (res.success) {
                this.newResId = res.result.id;
                if (
                  res.result.repeatedResumeInfos == null ||
                  res.result.repeatedResumeInfos.length == 0 ||
                  res.result.repeatedResumeInfos.every((item) => item == null)
                ) {
                  this.$message({
                    message: "修改成功",
                    type: "success",
                  });
                  this.loadResumeDetils(this.resumeDetailId || this.resumeId);
                } else {
                  this.$confirm("编辑人才和系统内人才疑似重复", "提示", {
                    confirmButtonText: "点击查看",
                    cancelButtonText: "取消",
                    customClass: "altCls",
                    showClose: false,
                    closeOnClickModal: false,
                    closeOnPressEscape: false,
                  })
                    .then(() => {
                      this.$router.push({
                        path: "/resume/pending",
                        query: {
                          id: this.newResId,
                        },
                      });
                    })
                    .catch(() => {
                      this.loadResumeDetils(
                        this.resumeDetailId || this.resumeId
                      );
                    });
                }
              }
              this.currentIndex = "";
              this.btnLoading = false;
              this.dialogVisibleEdit = false;
            });
          }
        });
      } else if (this.visibleProjects) {
        // 保存项目经历
        this.$refs["editProjectList"].validate((valid) => {
          if (valid) {
            if (
              this.editProjectList.startDate == "" ||
              this.editProjectList.startDate == undefined
            ) {
              this.$message.error("请选择开始时间");
              return false;
            }
            if (
              (this.editProjectList.endDate == "" ||
                this.editProjectList.endDate == undefined) &&
              !this.editProjectList.isSoFar
            ) {
              this.$message.error("请选择结束时间");
              return false;
            }
            this.btnLoading = true;
            this.projectsList[this.currentIndex] = this.editProjectList;
            addOrEditResume({
              educationsList: this.educationsList,
              employmentsList: this.employmentsList,
              forwardLocationIds: this.locationIdList,
              forwardVocationIds: this.VocationIdList,
              projectsList: this.projectsList,
              resumeVocationIds: this.resumeVocationIds,
              resumeFunctionIds: this.resumeFunctionIds,
              resumeInfo: this.resumeInfos,
              doNotCheckRepeated: false,
            }).then((res) => {
              if (res.success) {
                this.newResId = res.result.id;
                if (
                  res.result.repeatedResumeInfos == null ||
                  res.result.repeatedResumeInfos.length == 0 ||
                  res.result.repeatedResumeInfos.every((item) => item == null)
                ) {
                  this.$message({
                    message: "修改成功",
                    type: "success",
                  });
                  this.loadResumeDetils(this.resumeDetailId || this.resumeId);
                } else {
                  this.$confirm("编辑人才和系统内人才疑似重复", "提示", {
                    confirmButtonText: "点击查看",
                    cancelButtonText: "取消",
                    customClass: "altCls",
                    showClose: false,
                    closeOnClickModal: false,
                    closeOnPressEscape: false,
                  })
                    .then(() => {
                      this.$router.push({
                        path: "/resume/pending",
                        query: {
                          id: this.newResId,
                        },
                      });
                    })
                    .catch(() => {
                      this.loadResumeDetils(
                        this.resumeDetailId || this.resumeId
                      );
                    });
                }
              }
              this.currentIndex = "";
              this.btnLoading = false;
              this.dialogVisibleEdit = false;
            });
          }
        });
      } else {
        // 保存教育经历
        this.$refs["editEducationList"].validate((valid) => {
          if (valid) {
            if (
              this.editEducationList.startDate == "" ||
              this.editEducationList.startDate == undefined
            ) {
              this.$message.error("请选择开始时间");
              return false;
            }
            if (
              (this.editEducationList.endDate == "" ||
                this.editEducationList.endDate == undefined) &&
              !this.editEducationList.isSoFar
            ) {
              this.$message.error("请选择结束时间");
              return false;
            }
            this.btnLoading = true;
            this.educationsList[this.currentIndex] = this.editEducationList;
            addOrEditResume({
              educationsList: this.educationsList,
              employmentsList: this.employmentsList,
              forwardLocationIds: this.locationIdList,
              forwardVocationIds: this.VocationIdList,
              projectsList: this.projectsList,
              resumeVocationIds: this.resumeVocationIds,
              resumeFunctionIds: this.resumeFunctionIds,
              resumeInfo: this.resumeInfos,
              doNotCheckRepeated: false,
            }).then((res) => {
              if (res.success) {
                this.newResId = res.result.id;
                if (
                  res.result.repeatedResumeInfos == null ||
                  res.result.repeatedResumeInfos.length == 0 ||
                  res.result.repeatedResumeInfos.every((item) => item == null)
                ) {
                  this.$message({
                    message: "修改成功",
                    type: "success",
                  });
                  this.loadResumeDetils(this.resumeDetailId || this.resumeId);
                } else {
                  this.$confirm("编辑人才和系统内人才疑似重复", "提示", {
                    confirmButtonText: "点击查看",
                    cancelButtonText: "取消",
                    showClose: false,
                    customClass: "altCls",
                    closeOnClickModal: false,
                    closeOnPressEscape: false,
                  })
                    .then(() => {
                      this.$router.push({
                        path: "/resume/pending",
                        query: {
                          id: this.newResId,
                        },
                      });
                    })
                    .catch(() => {
                      this.loadResumeDetils(
                        this.resumeDetailId || this.resumeId
                      );
                    });
                }
              }
              this.currentIndex = "";
              this.btnLoading = false;
              this.dialogVisibleEdit = false;
            });
          }
        });
      }
    },

    handleCloseEdit(done) {
      this.cancelEdit();
    },
    addOrEditResume() {
      return new Promise((resolve) => {
        contactStatus({
          contactStatus: this.stadusVal,
          id: this.resumeDetailId,
        }).then((res) => {
          // if (res.success) {
          // }
          resolve();
        });
      });
    },
    changeS(val) {
      if (val == "无状态") {
        this.stadusVal = null;
      } else {
        this.stadusVal = val;
      }
    },
    savestadus() {
      this.saveloading = true;
      this.dialogVisibleEditStaus = false;
      this.addOrEditResume().then(() => {
        this.saveloading = false;
        this.loadResumeDetils(this.resumeDetailId);
      });
    },
    cancel() {
      this.isRecommend = false;
      this.isaddMark1 = false;
      this.isInterview = false;
      this.isOffer = false;
      this.isBackgroundSurvey = false;
      this.isEnrty = false;
      this.isFail = false;
      this.ishappyNews = false;
      this.isWhiteCollarWorker=false;
      this.isfinancialTask = false;
      this.iswriteReport = false;
      this.isReset = false;
      this.fileInfoList = [];
      this.iseditCus = false;
      this.isUpdateResume = false;
      this.isaddMark = false;
      this.isaddTag = false;
      this.isaddPro = false;
      this.dialogVisibleRemark = false;
      this.customerDrawerVisible = false;
      this.saveloading = false;
      this.resetRuleForm.resetStatus = "";
      this.removeStore("projects");
      this.removeStore("educations");
      this.removeStore("employments");
      this.getResumeProjectPageList();
      this.isAnnouncement=false
    },
    resetForm(formName) {
      this.fileInfoList = [];
      this.$refs[formName].resetFields();
    },
    handleClick2(tab, event) {
      if (tab.name == "second") {
        this.getResumeProjectPageList();
      }
      if (tab.name == "first") {
        if (!this.resumeId) {
          this.loadRecodeList(this.resumeDetailId, "人才");
        } else {
          this.loadRecodeList(this.resumeId, "人才");
        }
      }
    },
    handleClick1(tab, event) {
      this.isPoppoverShow = true;
      this.$nextTick(() => {
        this.isPoppoverShow = false;
      });
      if (tab.name == "second") {
        this.$refs.originalText.bellopreview();
      }
      if (tab.name == "third") {
        this.$refs.annexes.attachmentPageList();
      }
      if (tab.name == "fourth") {
        this.$refs.calendar.scheduleList();
      }
      if (tab.name == "fifth") {
        this.$refs.journal.loadOperateList();
      }
      if (tab.name == "sixth") {
        this.$refs.connections.loadList();
        statisticsClickConnectionsClick({
          connectionsCount: this.connectionCount,
          superResumeId: this.resumeId,
        }).then();
      }
    },
    unLock(rid) {
      //解锁
      this.$confirm(
        "<p style='font-size:18px'><strong>是否解除项目保护锁定？</strong></p><span>解锁后无法再次锁定候选人</span>",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          customClass: "altCls",
          dangerouslyUseHTMLString: true,
        }
      )
        .then(() => {
          unlockResume({ resumeId: rid }).then((res) => {
            if (res.result) {
              this.$message.success("解锁成功");
              this.loadResumeDetils(rid);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    unLockContact(rid) {
      //解锁
      this.$confirm(
        "<p style='font-size:18px;text-align:center'><strong>是否解锁候选人联系方式？</strong></p>",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          customClass: "altCls",
          dangerouslyUseHTMLString: true,
        }
      )
        .then(() => {
          resumeOffeOrEntryUnlock({ projectResumeId: rid }).then((res) => {
            if (res.success) {
              this.$message.success("解锁成功");
              this.loadResumeDetils();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    addProgream() {
      console.log(this.projectEntry);
      this.startTimeStamp = Math.round(new Date().getTime() / 1000);
      // this.handleStatisticsResumeOperationOperate("加入项目", "详情");
      this.popoverVisible = false;
      this.titName = "加入项目";
      this.resumeIds = [];
      this.resumeIds.push(this.resumeId);
      if (this.source == "SIMILAR_POSITION") {
        //相似职位页面跳转
        this.projectEntry = "相似职位-项目详情-候选人tab列表-人才详情";
      } else if (this.source == "自动匹配") {
        //自动匹配页面跳转
        this.projectEntry = "人才详情（自动匹配）";
      } else if (this.source == "相似人才") {
        //相似人才跳转
        this.projectEntry = "人才详情（相似人才）";
      } else if (this.source == "项目-候选人tab-人才详情") {
        //自动匹配页面跳转
        this.projectEntry = "人才详情（自动匹配）";
      } else {
        //人才页面跳转
        this.projectEntry = this.globFlag
          ? this.$route.query.searchKey || this.searchKey
            ? "人才详情（全文检索）"
            : "人才详情（常规搜索）"
          : "人才详情（常规搜索）";
      }
      statisticsResumeAddProject({
        resumeIds: this.resumeIds,
        entry: this.projectEntry,
      }).then();
      if (this.formInlineBase.isLocked || this.formInlineBase.comId != null) {
        this.$alert("候选人触发候选人保护机制，无法加入项目", "提示", {
          confirmButtonText: "确定",
          customClass: "altCls",
          callback: (action) => {
            if (action == "confirm") {
              this.handleStatisticsClickFunctionEscape(
                "点击了【全部触发保护机制提示弹框】的确定"
              );
            } else {
              this.handleStatisticsClickFunctionEscape(
                "点击了【全部触发保护机制提示弹框】的X"
              );
            }
          },
        });
        return false;
      }
      this.dialogVisible = true;
      this.isaddPro = true;
    }, //加入项目
    editCus() {
      this.handleStatisticsResumeOperationOperate("编辑", "详情");
      this.popoverVisible = false;
      this.titName = "编辑人才信息";
      this.dialogVisible = true;
      this.iseditCus = true;
      // this.loadResumeDetils()
    },
    // 删除人才
    delResume() {
      this.popoverVisible = false;
      this.delResumeDialog = true;
    },
    submitDelResume() {
      this.delResumeDialog = false;
      delResume({ id: this.resumeDetailId }).then((res) => {
        if (res.success) {
          this.$message.success(res.message);
          this.dialogVisible = false;
          this.$emit("getCallMobileInfo", false);
        }
      });
    },
    addMark(val) {
      this.handleStatisticsResumeOperationOperate("备注", "详情");
      this.projectResumeId = this.resumeDetailId;
      this.popoverVisible = false;
      this.$nextTick(() => {
        if (val == "候选人") {
          this.isNo = false;
          this.titName2 = "添加备注";
          this.dialogVisibleRemark2 = true;
          this.isaddMark = true;
        } else {
          this.textFoo = "添加";
          this.dialogVisibleColdCall = true;
        }
      });
    },
    addIcon() {
      //打标签
      this.handleStatisticsResumeOperationOperate("打标签", "详情");
      this.popoverVisible = false;
      this.searchTag = "";
      this.searchHoverContentIsShow = false;
      this.titName = "标签管理";
      this.isaddTag = true;
      // this.getLabelCategoryOptions();
      // this.createTag.labelIds = [];
      // if (this.allSkillLabels.length > 0) {
      //   this.allSkillLabels.forEach((item) => {
      //     this.createTag.labelIds.push(item.id);
      //   });
      // }
    },
    joinFiles() {
      this.fileManagement = true;
      this.isCheckbox.edit = true;
    },
    updateResume() {
      this.popoverVisible = false;
      this.dialogVisibleReplace = true;
      this.isUpdateResume = true;
    },
    beforeUpload: function (file) {
      const isLt = file.size / 1024 / 1024 < 20;
      if (!isLt) {
        this.$message.error("上传文件大小不能超过 20MB!");
      }
      return isLt;
    },
    handleInfoSuccess(res, file, fileList) {
      this.fileList = [];
      fileList.forEach((item) => {
        if (item.response) {
          this.fileList.push({
            fileName: item.name,
            filePath: item.response.result,
          });
        } else {
          this.fileList.push({
            fileName: item.name,
            filePath: item.filepath,
          });
        }
      });
    },
    handleRecordChange() {},
    handleInfoError(err) {
      this.$message.error("文件上传出错");
    },
    handleExceed(files, fileList) {
      this.$message.error("只能上传一个文件");
    },
    handleChange(file, fileList) {
      if (fileList.length) {
        this.saveDisabled = false;
      } else {
        this.saveDisabled = true;
      }
      const fileType = file.name.split(".")[file.name.split(".").length - 1];
      // .zip .doc .docx .pdf .jpg
      if (
        fileType != "png" &&
        fileType != "doc" &&
        fileType != "docx" &&
        fileType != "pdf" &&
        fileType != "jpg"
      ) {
        this.$message.error("上传文件格式不符合规定");
        this.fileInfoList = [];
        return false;
      }
      this.upResumeData.files = [];
      fileList.forEach((i) => {
        this.upResumeData.files.push(i.raw);
      });
    },
    handleRemove(file, fileList) {
      this.upResumeData.files = [];
      if (fileList.length) {
        this.saveDisabled = false;
      } else {
        this.saveDisabled = true;
      }
      fileList.forEach((i) => {
        this.upResumeData.files.push(i.raw);
      });
    },
    saveChangedInfos() {
      this.upResumeData.originalId = this.resumeDetailId;
      this.saveloading = true;
      if (this.upResumeData.files.length == 0) {
        this.$message.error("请先上传人才文件");
        this.saveloading = false;
        return false;
      }
      let body = new FormData();
      this.upResumeData.files.forEach((i) => {
        body.append("file", i);
      });
      body.append("originalId", this.upResumeData.originalId);
      replaceResumeInPage(body).then((res) => {
        if (res.success) {
          this.saveloading = false;
          this.dialogVisibleReplace = false;
          this.farResumeList = res.result;
          this.isUpdateResume = true;
          this.titName = "更新简历";
          this.dialogVisible = true;
          this.fileInfoList = [];
        }
      });
    },
    editProRecode(val, id) {
      this.cancel();
      //编辑备注记录
      this.resumeMemberId = id;
      this.projectResumeId1 = id;
      let _this = this;
      if (
        val.status !== "Offer" &&
        val.status !== "客户面试" &&
        val.status !== "简历推荐"
      ) {
        this.dialogVisibleRemark = true;
      }
      this.titName1 = "编辑";

      if (val.status == "简历推荐") {
        this.isRecommend = true;
        this.iseditD = true;
        this.isDrawerVisible = true;
        setTimeout(function () {
          _this.$refs.saveResumeRecommendation.loadEditInfo(val);
        }, 100);
      } else if (val.status == "客户面试") {
        this.isInterview = true;
        this.iseditD = true;
        this.isDrawerVisible = true;
        setTimeout(function () {
          _this.$refs.saveInterview.loadEditInfo(val);
        }, 100);
      } else if (val.status == "Offer") {
        this.isOffer = true;
        this.isDrawerVisible = true;
        setTimeout(function () {
          _this.$refs.saveOffer.loadEditInfo(val);
        }, 100);
      } else if (val.status == "背景调查") {
        this.isBackgroundSurvey = true;
        setTimeout(function () {
          _this.$refs.saveBackgroundSurvey.loadEditInfo(val);
        }, 100);
      } else if (val.status == "入职") {
        this.isEnrty = true;
        setTimeout(function () {
          _this.$refs.saveEntry.loadEditInfo(val);
        }, 100);
      } else if (val.status == "失败") {
        this.isFail = true;
        setTimeout(function () {
          _this.$refs.saveFail.loadEditInfo(val);
        }, 100);
      }
    },

    editRecode(row) {
      //编辑备注记录
      this.isNo = true;
      let _this = this;
      this.projectResumeId = row.relativeId;
      this.dialogVisibleRemark2 = true;
      // this.dialogVisible = true;
      this.isaddMark = true;
      this.titName2 = "编辑备注";
      this.setStore("RecordItem", row);
      this.markName = row.commentName;
      setTimeout(function () {
        _this.$refs.saveMark.loadEditInfo();
      }, 100);
    },
    handleDiaClose() {
      this.fileInfoList = [];
      this.dialogVisibleReplace = false;
      this.fileManagement = false;
    },
    saveInfos() {
      //保存客户信息 调用子组件方法
      if (this.iseditCus) {
        this.saveloading = true;
        this.$refs.saveCusInfo
          .saveResume()
          .then((value) => {
            // debugger
            this.saveloading = false;
            this.dialogVisible = false;
            this.loadResumeDetils();
          })
          .catch(() => {
            this.saveloading = false;
          });
        this.dialogVisibleColdCall = false;
      }
      if (this.isUpdateResume) {
        this.saveloading = true;
        this.$refs.saveCusInfo
          .saveResume()
          .then(() => {
            this.dialogVisible = false;
            this.saveloading = false;
          })
          .catch(() => {
            this.saveloading = false;
          });
      }
      if (this.isaddMark) {
        //备注记录
        this.$refs.saveMark.saveMarkRecode();
      }
      if (this.isInterview) {
        //面试轮数
        this.$refs.saveInterview.saveInterview();
      }
      if (this.isfinancialTask) {
        //财务任务
        this.$refs.saveFinancialTask.saveFinancialTask();
      }
      if (this.isaddTag) {
        this.saveloading = true;
        //打标签
        this.$refs.labelManagement.save();
      }
    },
    linkToCom(e) {
      if (e) {
        this.customerDrawerVisible = true;
        this.customId = e;
        // this.$router.push({
        //   path: "/customer",
        //   query: {
        //     comId: e,
        //   },
        // });
      }
    },
    linkToProject(e) {
      if (e) {
        this.$router.push({
          path: "/project/details",
          query: {
            id: e,
          },
        });
      }
    },
    handleClose(done) {
      if (this.isaddPro) {
        // this.handleStatisticsClickFunctionEscape("点击了【加入项目弹框】的X");
      }
      done();
      this.cancel();
    },
    loadResumeDetils(id, isfrist) {
      // console.log(this.resumeDetailId,this.automaticData);
      //获取人才详情
      this.cancel();
      this.dialogVisible = false;
      this.isloading = true;
      return new Promise((resolve) => {
        getResumeById({
          resumeId: id ? id : this.resumeDetailId,
          searchKey: this.globFlag
            ? this.$route.query.searchKey || this.searchKey
            : "",
          matchAll: this.hover ? this.hover.matchAll : "",
          majorSearch: this.hover ? this.hover.majorSearch : "",
          fromPage: this.resumeId ? "路由跳转详情页" : "人才列表Hover",
        }).then((res) => {
          if (res.success) {
            if (res.result) {
              const divvocationName = document.createElement("div");
              const _data = res.result;
              this.resumeInfos = _data.resumeInfo;
              if (this.resumeInfos.offerOrEntryProjectResumeId) {
                this.isShowContact = true;
              } else {
                this.isShowContact = false;
              }
              this.resumeInfos.linked_in1 = _data.resumeInfo.linked_in
                .replace("<font color='#ff3f3f'>", "")
                .replace("</font>", "");
              this.advantages = _data.advantages;
              this.advantageNames = _data.advantageNames;
              this.risks = _data.risks;
              this.profileLabels = _data.profileLabels;
              this.skillLabels = _data.skillLabels;
              this.allSkillLabels = _data.allSkillLabels;
              this.customLabels =
                _data.customLabels !== null ? _data.customLabels : [];
              divvocationName.innerHTML = _data.resumeInfo.functionName;
              this.vocationName = divvocationName.innerText;
              this.candidateInfo = _data.operateRecord || {};
              this.functionIdsList = _data.functionList.map((i) =>
                JSON.parse(i.functionIds)
              );
              this.vocationIdsList = _data.vocationList.map((i) =>
                JSON.parse(i.vocationIds)
              );
              this.belloId = _data.resumeInfo.belloId;
              this.statusData.status = _data.resumeInfo.contactStatus;
              this.employmentsList = _data.employmentsList;
              this.projectsList = _data.projectsList;
              this.educationsList = _data.educationsList;
              this.labels = _data.profileLabels;
              this.similarCount = _data.similarCount;
              this.connectionCount = _data.connectionCount;
              this.similarName = _data.similarName;
              this.ownerId = _data.resumeInfo.ownerUserId;
              this.candidateProtect = [];
              if (
                _data.resumeInfo.lockComName != null &&
                _data.resumeInfo.lockComName != ""
              ) {
                this.candidateProtect.push(_data.resumeInfo.lockComName);
              }
              if (
                _data.resumeInfo.lockProjectName != null &&
                _data.resumeInfo.lockProjectName != ""
              ) {
                this.candidateProtect.push(_data.resumeInfo.lockProjectName);
              }

              this.VocationList = _data.forwardVocationList.map(
                (i) => i.forwardVocationName
              );
              this.VocationTitle = _data.forwardVocationList.map(
                (i) => i.forwardVocation
              );
              this.locationList = _data.forwardLocationList.map(
                (i) => i.forwardLocationName
              );

              this.locationTitle = _data.forwardLocationList.map(
                (i) => i.forwardLocationName
                // (i) => i.forwardVocation
              );
              this.VocationIdList = _data.forwardVocationList.map((i) => {
                if (i.forwardVocationIds) {
                  return (i = JSON.parse(i.forwardVocationIds));
                }
              });
              this.locationIdList = _data.forwardLocationList.map((i) => {
                if (i.forwardLocationIds) {
                  return (i = JSON.parse(i.forwardLocationIds));
                }
              });
              this.formInlineBase = res.result.resumeInfo;
              this.resumeFunctionIds = res.result.functionList.map((item) =>
                JSON.parse(item.functionIds)
              );
              // 行业
              this.resumeVocationIds = res.result.vocationList.map((item) =>
                JSON.parse(item.vocationIds)
              );
              if (res.result.forwardVocationList.length > 0) {
                let VocationName = [];
                res.result.forwardVocationList.forEach((item) => {
                  if (item.forwardVocationName) {
                    VocationName.push(item.forwardVocationName);
                  }
                });
                this.forwardVocationList = VocationName.join(" / ");
              }
              // if (
              //   this.formInlineBase.certificate &&
              //   this.formInlineBase.certificate.indexOf("\n") != "-1"
              // ) {
              //   //证书
              //   this.formInlineBase.certificate =
              //     this.formInlineBase.certificate.replace(/\n/g, "");
              // }
              // if (
              //   this.formInlineBase.certificate &&
              //   this.formInlineBase.certificate.indexOf("<br/>") != "-1"
              // ) {
              //   //证书
              //   this.formInlineBase.certificate =
              //     this.formInlineBase.certificate.replace(
              //       new RegExp("<br/>", "g"),
              //       ""
              //     );
              // }
              // if (
              //   this.formInlineBase.training &&
              //   this.formInlineBase.training.indexOf("\n") != "-1"
              // ) {
              //   //培训经历
              //   this.formInlineBase.training =
              //     this.formInlineBase.training.replace(/\n/g, "");
              // }
              // if (
              //   this.formInlineBase.training &&
              //   this.formInlineBase.training.indexOf("<br/>") != "-1"
              // ) {
              //   //培训经历
              //   this.formInlineBase.training =
              //     this.formInlineBase.training.replace(
              //       new RegExp("<br/>", "g"),
              //       ""
              //     );
              // }
              // if (
              //   this.formInlineBase.personal &&
              //   this.formInlineBase.personal.indexOf("\n") != "-1"
              // ) {
              //   //自我评价
              //   this.formInlineBase.personal =
              //     this.formInlineBase.personal.replace(/\n/g, "");
              // }
              // if (
              //   this.formInlineBase.personal &&
              //   this.formInlineBase.personal.indexOf("<br/>") != "-1"
              // ) {
              //   //自我评价
              //   this.formInlineBase.personal =
              //     this.formInlineBase.personal.replace(
              //       new RegExp("<br/>", "g"),
              //       ""
              //     );
              // }
              // if (
              //   this.formInlineBase.others &&
              //   this.formInlineBase.others.indexOf("\n") != "-1"
              // ) {
              //   //其他
              //   this.formInlineBase.others = this.formInlineBase.others.replace(
              //     /\n/g,
              //     ""
              //   );
              // }
              // if (
              //   this.formInlineBase.others &&
              //   this.formInlineBase.others.indexOf("<br/>") != "-1"
              // ) {
              //   //其他
              //   this.formInlineBase.others = this.formInlineBase.others.replace(
              //     new RegExp("<br/>", "g"),
              //     ""
              //   );
              // }
              if (res.result.forwardLocationList.length > 0) {
                let LocationName = [];
                res.result.forwardLocationList.forEach((item) => {
                  if (item.forwardLocationName) {
                    LocationName.push(item.forwardLocationName);
                  }
                });
                this.forwardLocationList = LocationName.join(" / ");
              }
              if (res.result.resumeInfo.isMianYi) {
                // 求职意向工资是否面议
                this.desabled = true;
              }
              // 数据格式整理
              for (var key in this.resumeInfos) {
                try {
                  if (JSON.parse(this.resumeInfos[key]) instanceof Array) {
                    this.resumeInfos[key] = JSON.parse(this.resumeInfos[key]);
                  }
                } catch (e) {}
              }
              if (!this.resumeInfos.functionIds) {
                this.resumeInfos.functionIds = [];
              }
              if (!this.resumeInfos.nowLocationIds) {
                this.resumeInfos.nowLocationIds = [];
              }
              if (!this.resumeInfos.vocationIds) {
                this.resumeInfos.vocationIds = [];
              }
              this.employmentsList.map((item) => {
                if (!item.vocationIds) {
                  item.vocationIds = [];
                }
                if (!item.functionIds) {
                  item.functionIds = [];
                }
                return item;
              });
              setTimeout(() => {
                let tagBoxWidth = $(".tagList_box").width();
                let tagLength = $(".tagList_box span");
                let spanWidth = 0;
                for (let i = 0; i < tagLength.length; i++) {
                  spanWidth += tagLength[i].offsetWidth;
                }
                if (spanWidth >= tagBoxWidth) {
                  this.hasMoreTags = true;
                } else {
                  this.hasMoreTags = false;
                }
              }, 100);
              if (isfrist) {
                // console.log(this.source, "SIMILAR_POSITION");
                if (this.source == "SIMILAR_POSITION") {
                  statisticsSimilarResumeDetailShow({
                    entry: this.detailShowEntry,
                    projectId: this.pipelineProjectId,
                    resumeId: this.resumeDetailId,
                  }).then();
                } else {
                  if (this.detailShowEntry) {
                    // console.log(this.source)
                    statisticsResumeDetailShow({
                      entry: this.detailShowEntry,
                      resumeId: this.resumeDetailId,
                      searchObj: JSON.stringify(this.searchObj),
                      module: this.module,
                    }).then();
                  }
                }
                if (this.searchInfo) {
                  statisticsResumeSearchResultClick(this.searchInfo).then();
                }
                // console.log(this.automaticData);
                if (this.automaticData) {
                  statisticsResumePositionAutomaticResultClick(
                    this.automaticData
                  ).then();
                }
              }
              if(this.isShowRemark){
                this.addMark(this.resumeInfos.resumeType)
              }
              this.isloading = false;
            } else {
              this.isloading = false;
            }
          } else {
            if (res.message == "人才保密中，无法查看详情") {
              this.$confirm("人才保密中，无法查看详情", "提示", {
                showConfirmButton: false,
                cancelButtonText: "关闭",
                customClass: "altCls",
                dangerouslyUseHTMLString: true,
              })
                .then(() => {})
                .catch(() => {
                  this.$parent.handleClose();
                });
            }
          }
          resolve();
        });
      });
    },
    //获取标签选项
    // getLabelCategoryOptions() {
    //   personalLabelHits({ candidateId: this.resumeDetailId }).then((res) => {
    //     if (res.success) {
    //       this.addTypeListNum = res.result;
    //       getLabelCategoryOptions().then((res) => {
    //         if (res.success) {
    //           res.result.forEach((item) => {
    //             item.isActive = false;
    //             this.addTypeListNum.forEach((num) => {
    //               if (item.name == num.name) {
    //                 item.hitsNums = num.hitsNums;
    //               }
    //             });
    //           });
    //           this.addTypeList = res.result;
    //           if (this.addTypeList.length > 0) {
    //             this.addTypeList[0].isActive = true;
    //             this.choiceTypeName = this.addTypeList[0].name;
    //             this.newTagList = this.addTypeList[0].labels;
    //             this.newTagSerchList = this.addTypeList[0].labels;
    //           }
    //         }
    //       });
    //     }
    //   });
    // },
    // //选中标签类别
    // chioceType(data) {
    //   this.choiceTypeName = data.name;
    //   this.addTypeList.forEach((item) => {
    //     if (item.name == data.name) {
    //       item.isActive = true;
    //       this.newTagList = data.labels;
    //       this.newTagSerchList = data.labels;
    //     } else {
    //       item.isActive = false;
    //     }
    //   });
    // },
    // //取消标签
    // cencleLabel(type) {
    //   let that = this;
    //   let list = that.createTag.labelIds;
    //   let ids = [];
    //   if (type) {
    //     //取消当前标签
    //     that.addTypeList.forEach((item) => {
    //       if (item.name == that.choiceTypeName) {
    //         // item.isActive = false;
    //         // item.hitsNums = 0;
    //         item.labels.forEach((i) => {
    //           let index = list.indexOf(i.id);
    //           // console.log(index)
    //           if (index !== -1) {
    //             item.hitsNums = 0;
    //             ids.push(that.createTag.labelIds[index]);
    //             that.createTag.labelIds.splice(index, 1);
    //           }
    //           // console.log(i)
    //           // this.createTag.labelIds = list.splice((data) => data != i.id);
    //         });
    //       } else {
    //         item.labels.forEach((i) => {
    //           ids.forEach((x) => {
    //             if (x == i.id) {
    //               item.hitsNums--;
    //             }
    //           });
    //           // let index = list.indexOf(i.id);
    //           // console.log(index)
    //           // if (index!==-1) {
    //           //   item.hitsNums = 0;
    //           //   ids.push(that.createTag.labelIds[index])
    //           //   that.createTag.labelIds.splice(index, 1);
    //           // }
    //           // console.log(i)
    //           // this.createTag.labelIds = list.splice((data) => data != i.id);
    //         });
    //       }
    //     });
    //     this.$message.success("取消成功");
    //     // console.log(this.createTag.labelIds);
    //   } else {
    //     //取消全部
    //     that.createTag.labelIds.splice(0, that.createTag.labelIds.length);
    //     // console.log(that.createTag.labelIds)
    //     // this.addTypeListNum = [];
    //     // let data = this.addTypeList;
    //     that.addTypeList.forEach((item) => {
    //       item.hitsNums = 0;
    //       item.isActive = false;
    //     });
    //     // that.addTypeList = data;
    //     that.addTypeList[0].isActive = true;
    //     that.choiceTypeName = that.addTypeList[0].name;
    //     that.newTagList = that.addTypeList[0].labels;
    //     that.newTagSerchList = that.addTypeList[0].labels;
    //     that.$message.success("取消成功");
    //     // this.getLabelCategoryOptions();
    //   }
    // },
    // handleCheckbox(val) {},
    loadRecodeList(id, type) {
      //加载备注记录List
      this.cancel();
      this.dialogVisible = false;
      this.dialogVisibleRemark = false;
      this.dialogVisibleRemark2 = false;
      getCommonRecordList({
        relativeId: id,
        type: type,
      }).then((res) => {
        if (res.success) {
          // 首先，先复制整个接口返回的数组
          this.RecordList = res.result.map((result) => ({
            ...result,
            // 以上通过扩展运算符复制整个obj

            // 修改commentColumns
            // filter是Array下的方法对吧
            // 要是坑爹后台返回的格式不对怎么办呢
            // 我们可以通过加默认值解决!!!
            commentColumns: (result.commentColumns || []).filter(
              (comment) => comment.value
            ),
          })); // 没问题吧？嗯
        }
      });
    },
    // 判断用户是否有解锁人才保护权限
    checkMenuList() {
      if (
        this.menuList.findIndex((item) => {
          return item.authority == "resume:resumeList:lock";
        }) == -1
      ) {
        this.showLock = false;
      } else {
        this.showLock = true;
      }
      if (
        this.menuList.findIndex((item) => {
          return item.authority == "resume:resumeList:unlock";
        }) == -1
      ) {
        this.showUnlockLock = false;
      } else {
        this.showUnlockLock = true;
      }
      if (
        this.menuList.findIndex((item) => {
          return item.authority == "resume:resumeList:secureLock";
        }) == -1
      ) {
        this.showSecureLock = false;
      } else {
        this.showSecureLock = true;
      }
    },
    //新增日程
    showcalendarDig() {
      this.ctitle = "新增日程";
      this.scheduleDialog = true;
    },
    //保存
    saveSchedule() {
      this.$refs.caledarfrom
        .submitForm("ruleForm")
        .then(() => {
          this.scheduleDialog = false;
          this.$refs.calendar.scheduleList();
        })
        .catch((error) => {});
    },
    //关闭
    closeDig() {
      this.scheduleDialog = false;
      this.resetForm("ruleForm");
    },
    changeactive() {
      this.activeNameDetail = "first";
    },
    //tab数字
    getNumber(val) {
      if (val.type == "简历原文") {
        this.originalTextNum = val.num || val == 0 ? val.num : 0;
      } else if (val.type == "附件") {
        this.annexesNum = val.num || val == 0 ? val.num : 0;
      } else if (val.type == "日程") {
        this.calendarNum = val.num || val == 0 ? val.num : 0;
      }
    },
    //上传附件
    showAnnexesDig() {
      this.annexesVisible = true;
    },
    //保存
    saveAnnexes() {
      this.$refs.annexesfrom
        .submitFiles()
        .then(() => {
          this.annexesVisible = false;
          this.$refs.annexes.attachmentPageList();
        })
        .catch((error) => {});
    },
    //关闭
    annexescloseDig() {
      this.annexesVisible = false;
      // this.resetForm("ruleForm");
    },
    emailDrawerClose(val) {
      if (val == "isNoloading") {
        this.saveloading = false;
      } else {
        this.loadList();
        this.saveloading = false;
        this.isDrawerVisible = false;
        this.isRecommend = false;
        this.isInterview = false;
        this.iseditD = false;
      }
    },
    reflsh(id) {
      this.limitOptions = [
        { status: "加入项目", disabled: true },
        { status: "简历推荐", disabled: true },
        { status: "客户面试", hide: false, disabled: true },
        { status: "客户面试 ", hide: true, disabled: true },
        { status: "Offer", disabled: true },
        { status: "入职", disabled: true },
        { status: "重置", disabled: true },
        { status: "撤销", disabled: true },
        { status: "失败", disabled: true },
        { status: "成功", hide: true, disabled: true },
      ];
      if (this.belloId) {
        this.resumeImg = true;
      } else {
        this.resumeImg = false;
      }
      this.candidateProtect = [];
      this.loadColumnPosList("职能");
      this.loadColumnInList("行业");
      this.loadColumnCityList("城市");
      this.resumeId = id;
      // debugger
      if (this.repeatId) {
        this.istop = "0";
        this.isborder = "0";
        this.ispadding = "0";
      }
      if (this.$route.query.diaTrue) {
        this.titName = "编辑人才信息";
        this.dialogVisible = true;
        this.iseditCus = true;
      }
      this.checkMenuList();
      if (id) {
        this.isborder = "0";
        // belloIdRepeat疑是重复人才页面传过来
        if (this.belloIdRepeat || this.repeatResume.valId) {
          this.istop = "0";
          this.ispadding = "0";
          this.resumeImg = true;
          this.defaultShowTable = true; //按钮是否展示
        } else {
          // this.belloId本页面是否含有belloId
          this.loadResumeDetils(id).then(() => {
            if (this.belloId) {
              this.istop = "50px";
              this.ispadding = "15px solid #fff";
              this.resumeImg = true;
            }
          });
          this.defaultShowTable = false;

          // 疑是重复人才
          if (this.repeatResume.valId) {
            this.defaultShowTable = true;
            this.istop = "0";
            this.isborder = "0";
            this.ispadding = "0";
          }
        }
      } else {
        this.projectResumeId1 = id;

        this.loadResumeDetils(id).then(() => {
          if (this.belloId) {
            this.resumeImg = true;
          }
        });
        this.istop = "0";
        this.isborder = "0";
        this.ispadding = "0";
      }
      if (id || this.candiateId) {
        this.loadRecodeList(id, "人才");
        this.loadResumeDetils(id, true);
      } else if (this.repeatResume.valId) {
        this.loadResumeDetils(this.repeatResume);
      } else {
        // this.loadResumeDetils(this.resumeDetailId);
        // 默认是打开详情就掉备注接口
      }
    },
    //记录项目详情-候选人tab-操作系列功能点击量（包括置顶、反馈、标识）
    handelStatisticsProjectPipelineOperate(functionName, item) {
      statisticsProjectPipelineOperate({
        entry: "人才-项目tab",
        functionName: functionName,
        phase: item.status,
        projectId: item.projectId,
        resumeId: this.resumeId,
      }).then();
    },
    //设为保密弹窗
    setSecure() {
      this.createSecure = true;
      this.userOptions();
      this.OrganizinList();
      console.log(this.resumeInfos.secure);
      if (this.resumeInfos.secure) {
        securePermissionData({ resumeId: this.resumeId }).then((res) => {
          if (res.success) {
            if (res.result) {
              this.secureList = res.result;
              this.loadResumeDetils();
              if (this.secureList.shares.length == 0) {
                this.isShowShare = false;
              } else {
                this.isShowShare = true;
              }
            } else {
              this.isShowShare = false;
            }
          }
        });
      }
    },
    //取消保密
    CancelSecure() {
      securePermissionCancel({ resumeId: this.resumeId }).then((res) => {
        if (res.success) {
          this.$message.success("操作成功");
          this.loadResumeDetils();
        }
      });
    },
    // 新增分享
    addShares(row, inx) {
      this.secureList.shares.push({
        type: "同事",
        shareIds: [],
        teamIds: [],
      });
    },
    // 删除分享新增
    delShares(row, inx) {
      // this.isShowWrapper = true;
      if (inx == 0) {
        this.isShowBtn = true;
      }
      this.secureList.shares.splice(inx, 1);
      if (this.secureList.shares.length == 0) {
        this.isShowShare = false;
      }
    },
    // 同事
    workmate(e, inx) {
      this.secureList.shares[inx].shareIds = e;
    },
    // 团队
    teammate(e, inx) {
      this.secureList.shares[inx].teamIds = e;
    },

    diffType(val) {
      if (val == "同事") {
        this.userOptions();
        this.teamValue = [];
      } else if (val == "团队") {
        this.OrganizinList();
        this.diffTypeVal = "";
      }
    },
    // 同事
    userOptions() {
      userOptions().then((res) => {
        this.diffShareData = res.result;
      });
    },
    // 组织框架
    OrganizinList() {
      permissionTree({ type: "团队" }).then((res) => {
        this.teamValueOptions = res.result;
      });
    },
    // 新增，编辑
    addOrEditFavorite(formName, type) {
      this.saveloading = true;
      // this.$refs[formName].validate((valid) => {
      //   if (valid) {
      //     this.fileList.type = this.type;
      //     // return
      if (type == "Skip") {
        this.secureList.shares = [];
      }
      this.secureList.resumeId = this.resumeId;
      securePermissionSecure(this.secureList).then((res) => {
        if (res.success) {
          this.$message.success("操作成功");
          this.createSecure = false;
          this.loadResumeDetils();
          // this.getListFavorite();
        }
        this.saveloading = false;
      });
      //   }
      // });
    },
    //去相似候选人
    goSimilarResume() {
      this.handleStatisticsResumeOperationOperate("相似人才", "详情");
      statisticsClickSimilarResumeClick({
        resumeId: this.resumeId,
        similarResumeCount: this.similarCount,
      }).then();
      let newpage = this.$router.resolve({
        path: "/similarResume",
        query: { resumeId: this.resumeId },
      });
      window.open(newpage.href, "_blank");
    },
    //人才库-路径操作
    handleStatisticsResumeOperationOperate(operateName, pageType) {
      statisticsResumeOperationOperate({
        operateId: this.operateId,
        operateName: operateName,
        pageType: pageType,
        search: this.searchObj,
      }).then();
    },
    handleStatisticsClickFunctionEscape(nextStep) {
      statisticsClickFunctionEscape({
        endTimeStamp: Math.round(new Date().getTime() / 1000),
        entry: this.projectEntry,
        presentStep: "加入项目",
        startTimeStamp: this.startTimeStamp,
        nextStep: nextStep,
      }).then();
    },
    //显示联系方式
    hideContact() {
      this.isShowContact = !this.isShowContact;
    },
  },
  created() {
    this.limitOptions = [
      { status: "加入项目", disabled: true },
      { status: "简历推荐", disabled: true },
      { status: "客户面试", hide: false, disabled: true },
      { status: "客户面试 ", hide: true, disabled: true },
      { status: "Offer", disabled: true },
      { status: "入职", disabled: true },
      { status: "重置", disabled: true },
      { status: "撤销", disabled: true },
      { status: "失败", disabled: true },
      { status: "成功", hide: true, disabled: true },
    ];
    if (this.belloId) {
      this.resumeImg = true;
    } else {
      this.resumeImg = false;
    }
    this.candidateProtect = [];
    this.loadColumnPosList("职能");
    this.loadColumnInList("行业");
    this.loadColumnCityList("城市");
    this.resumeId = this.resumeDetailId;
    // debugger
    if (this.repeatId) {
      this.istop = "0";
      this.isborder = "0";
      this.ispadding = "0";
    }
    if (this.$route.query.diaTrue) {
      this.titName = "编辑人才信息";
      this.dialogVisible = true;
      this.iseditCus = true;
    }
    this.checkMenuList();
    if (this.resumeDetailId) {
      this.isborder = "0";
      // belloIdRepeat疑是重复人才页面传过来
      if (this.belloIdRepeat || this.repeatResume.valId) {
        this.istop = "0";
        this.ispadding = "0";
        this.resumeImg = true;
        this.defaultShowTable = true; //按钮是否展示
      } else {
        // this.belloId本页面是否含有belloId
        this.loadResumeDetils(this.resumeDetailId).then(() => {
          if (this.belloId) {
            this.istop = "50px";
            this.ispadding = "15px solid #fff";
            this.resumeImg = true;
          }
        });
        this.defaultShowTable = false;

        // 疑是重复人才
        if (this.repeatResume.valId) {
          this.defaultShowTable = true;
          this.istop = "0";
          this.isborder = "0";
          this.ispadding = "0";
        }
      }
    } else {
      this.projectResumeId1 = this.resumeDetailId;
      this.loadResumeDetils(this.resumeDetailId).then(() => {
        if (this.belloId) {
          this.resumeImg = true;
        }
      });
      this.istop = "0";
      this.isborder = "0";
      this.ispadding = "0";
    }
    if (this.resumeDetailId || this.candiateId) {
      this.loadRecodeList(this.resumeDetailId, "人才");
      this.loadResumeDetils(this.resumeDetailId, true);
    } else if (this.repeatResume.valId) {
      this.loadResumeDetils(this.repeatResume);
    } else {
      // this.loadResumeDetils(this.resumeDetailId);
      // 默认是打开详情就掉备注接口
    }
    // console.log(this.source);
    if (this.source == "SIMILAR_POSITION") {
      //相似职位页面跳转
      this.createTag.entry = "项目-候选人tab-人才详情";
      this.projectEntry = "相似职位-项目详情-候选人tab列表-人才详情";
    } else if (this.source == "自动匹配") {
      //自动匹配页面跳转
      this.createTag.entry = "自动匹配-人才详情";
      this.projectEntry = "人才详情（自动匹配）";
    } else if (this.source == "相似人才") {
      this.createTag.entry = "相似人才-人才详情";
      //自动匹配页面跳转
      this.projectEntry = "人才详情（相似人才）";
    } else if (
      this.source == "pipeline-人才详情" ||
      this.source == "项目-候选人tab-人才详情"
    ) {
      this.createTag.entry = this.source;
      //自动匹配页面跳转
      this.projectEntry = this.source;
    } else {
      //人才页面跳转
      this.createTag.entry = "人才库-人才详情";
    }
  },
  destroyed() {
    this.$store.dispatch(
      "upDateResumePreCloseTime",
      new Date().Format("yyyy-MM-dd hh:mm:ss")
    );
    this.$store.dispatch(
      "upDateAutomaticLastCloseTime",
      new Date().Format("yyyy-MM-dd hh:mm:ss")
    );
  },
};
</script>
<style lang="less" scope>
@import url("./cssFile/resumeDatil.less");
.topBox {
  border: 1px #ffdddc solid;
  padding: 15px 15px 8px 15px;
  background: #fff5f4;
  margin-bottom: 15px;
  border-radius: 4px;
  // .gradient-content-header {
  //   font-size: 16px;
  //   font-weight: 500;
  //   line-height: 20px;
  //   margin-bottom: 3px;
  // }
  // .lightList {
  //   margin-left: 36px;
  // }
  .lightList li {
    // margin-top: 12px;
    font-size: 13px;
    line-height: 1.769230769230769;
  }
  .dics1 {
    background: #606266;
  }
}
.activeHover:hover {
  color: #e60012;
}
.personFoo1 i:nth-child(1) {
  margin-left: 0;
}
.shareRow {
  margin-bottom: 20px;
  .operationEditOrDel span:nth-child(1):hover {
    color: #526ecc;
  }
  .operationEditOrDel span:nth-child(2):hover {
    color: #e60012;
  }
  // .miniBtn{
  //   .blue:hover{
  //     color: #526ecc
  //   }
  //   .red:hover{
  //     color: #e60012
  //   }
  // }
}
</style>
<style lang="less">
@import url("../views/Pipeline/unfold.less");
.resume-R-tab {
  border: 1px solid #e5e5e5;
  // margin-top: 10px;
  .el-tabs__header {
    margin-bottom: 0px;
  }
  .el-tabs__nav-wrap {
    padding: 0 20px;
  }
  .markRecode {
    height: calc(100% - 60px);
    overflow-y: scroll;
    padding: 10px;
    &::-webkit-scrollbar {
      display: none;
    }
    .mark_recode_item {
      border: 1px solid #e5e5e5;
      margin-bottom: 10px;
      border-bottom: 0;
      &:last-child {
        margin-bottom: 0;
      }
      .el-collapse-item__header {
        height: auto;
        padding: 8px 0;
        line-height: 22px;
        background-color: #f5f5f5;
        border-bottom: 1px solid #e5e5e5;
      }
      .el-collapse-item__content {
        padding-bottom: 0;
      }
      .Recode_title {
        padding-left: 10px;
        font-size: 13px;
        width: 100%;
        color: #999;
        .text-overflows {
          display: inline-block;
          vertical-align: middle;
          width: 25%;
          margin: 0 10px;
          top: -2px;
          position: relative;
        }
      }
      .Recode_content {
        padding: 15px;
        .project {
          font-size: 14px;
          font-weight: bold;
          line-height: 1;
          padding-bottom: 10px;
        }
      }
      .edit {
        float: right;
        font-weight: normal;
        color: #606266;
        cursor: pointer;
        padding-right: 10px;
        font-size: 12px;
        display: none;
        &:hover {
          color: #e60012;
        }
      }
      &:hover {
        background: #fafafa;
        box-shadow: none;
        .edit {
          display: block;
        }
      }
    }
    ul {
      padding-inline-start: 0;
      li {
        padding-bottom: 10px;
        &:last-child {
          padding-bottom: 0;
        }
        .tit {
          line-height: 1.5;
          font-size: 13px;
          color: #909399;
        }
        .con {
          line-height: 1.5;
          color: #606266;
          padding-left: 10px;
        }
      }
    }
  }
  //项目
  .proRecodes {
    .el-collapse-item__header {
      background-color: #f5f5f5;
      padding: 5px 10px 0;
      height: 60px;
      // margin-bottom: 10px;
    }
    h3 {
      margin: 0;
      .proName {
        max-width: 200px;
        margin-right: 8px;
        line-height: 1;
        display: inline-block;
        vertical-align: middle;
      }
      .comName {
        line-height: 1;
        font-weight: normal;
        font-size: 13px;
        color: #526ecc;
        padding-bottom: 8px;
      }
      &:hover {
        color: #303133 !important;
      }
    }

    .proCon {
      padding: 10px 10px 0 10px;
      .functionWrapper {
        background: #f0f0f0 !important;
        width: 33%;
        color: rgb(102, 102, 102);
        margin-left: 0 !important;
        border: 5px solid #fff;
        &:hover {
          color: #e60012;
        }
        .el-dropdown-selfdefine {
          &:hover {
            color: #e60012;
          }
        }
        // border-left: 0;
      }
    }
    .el-collapse-item {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .statusTitle {
      .el-collapse-item__header {
        height: auto !important;
        padding: 8px 10px;
        line-height: 22px;
        .headCon {
          width: 100%;
          .edit {
            position: relative;
            right: auto;
            display: none;
            &:hover {
              color: #e60012;
            }
          }
          &:hover .edit {
            display: inline-block;
          }
        }
      }
      .foldStyle {
        margin-bottom: 0px;
      }
    }
  }
}

.personFoo1 .el-collapse-item__header {
  border: none;
}
.wrapperFoo1 {
  .el-upload {
    display: block;
  }
  .el-upload-dragger {
    width: 100%;
  }
}
.resumeDetail .el-col-8 {
  line-height: 30px;
}

.resumeDetail .el-tabs__content {
  .fontColor {
    color: #999;
    line-height: 23px;
  }
  .showcolor {
    .fontColor1 {
      color: #333;
      font-size: 14px;
    }
    .linkButton {
      padding: 0;
      color: #526ecc !important;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .linkButton {
    color: #606266 !important;
    &:hover {
      text-decoration: underline;
    }
  }
  // padding-top: 15px;
}

.resumeDetail .el-tabs__header {
  margin-bottom: 0;
}
.resumeDetail .el-tabs__nav-scroll {
  padding: 0 15px;
}

.personFoo1 .el-collapse-item__wrap,
.lightBackground .el-collapse-item__wrap {
  border: none !important;
}

.lightBackground .el-collapse-item__content {
  padding-bottom: 0;
}
.lightBackground .el-collapse-item__header {
  height: 40px;
  line-height: 40px;
}
.gradient-content {
  background-color: #fffbf9;
  height: 100%;
  padding: 10px 15px;
  box-sizing: border-box;
  border-radius: 6px;

  .el-collapse-item__header {
    border-bottom: 0;
    height: 20px;
    padding: 10px;
    background-color: transparent;
  }
  .el-collapse-item__content {
    padding-bottom: 0;
  }
  .el-collapse-item__wrap {
    background-color: transparent;
    border-bottom: 0;
  }
  .gradient-content-header {
    font-size: 14px;
    color: #333;
    font-weight: bold;
    line-height: 1;
    i {
      font-size: 20px;
      vertical-align: middle;
      margin-right: 8px;
    }
    .icon-wenhao-xiangsu {
      font-weight: normal;
      font-size: 16px;
      color: #898989;
    }
    .btn {
      padding: 1px 8px;
      margin-left: 8px;
      font-size: 12px;
      border: 1px solid;
      border-radius: 2px;
    }
    .jl {
      color: #ff7f57;
      border-color: #ff7f57;
    }
    .fx {
      color: #7f55d4;
      border-color: #7f55d4;
    }
  }
}
.leftTit {
  width: 65px;
  line-height: 23px;
  display: inline-block;
}
.nodata {
  height: 36px;
  line-height: 36px;
  color: #999;
}
.schoolName {
  &:hover {
    text-decoration: underline;
  }
}
.textColor {
  color: #606266 !important;
}
.textsecColor {
  color: #999 !important;
}
.addTagDig {
  .el-dialog__body {
    max-height: 500px;
    overflow-y: auto;
    padding: 0;
  }
  .fixedFoot {
    position: fixed;
    height: 50px;
    z-index: 6;
    line-height: 50px;
    bottom: 20px;
    width: calc(100% - 40px);
    border-top: 1px solid #e5e5e5;
    left: 20px;
    background-color: #f0f0f0;
  }
}
.isp_card {
  > span:nth-child(2) {
    max-width: calc(100% - 105px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.show_popover {
  max-width: 550px;
}
.isColorPopper {
  .el-select-dropdown__item.is-disabled {
    color: #c0c4cc !important;
    font-weight: 400 !important;
  }
}
.lableBtn {
  padding: 1px 8px;
  display: inline-block;
  margin: 0 2px 5px;
  font-size: 12px;
  border: 1px solid;
  border-radius: 2px;
  line-height: 21px;
}
</style>
