var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-button',{attrs:{"size":"mini"},on:{"click":_vm.upAttachment}},[_vm._v("上传附件")]),_c('el-table',{staticClass:"tabBorder",staticStyle:{"width":"100%"},attrs:{"header-cell-style":{
      'background-color': '#f5f7fa',
    },"data":_vm.docData}},[_c('el-table-column',{attrs:{"prop":"fileName","label":"文件名"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"tabHref",attrs:{"title":scope.row.fileName&&scope.row.fileName.length > 12 ? scope.row.fileName : ''},on:{"click":function($event){return _vm.preView(scope.row)}}},[_vm._v(_vm._s(scope.row.fileName))])]}}])}),_c('el-table-column',{attrs:{"prop":"type","label":"类型","width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"tag",class:{
            tag01: scope.row.type == '公司简介' || scope.row.type == 'JD',
            tag02:
              scope.row.type == '通讯录' || scope.row.type == '付款通知书',
            tag03:
              scope.row.type == '公司架构' ||
              scope.row.type == 'Offer Letter',
            tag05: scope.row.type == '合同',
            tag06: scope.row.type == '班车表',
            tag07: scope.row.type == '其他',
          }},[_vm._v(_vm._s(scope.row.type))])]}}])}),_c('el-table-column',{attrs:{"prop":"createdTime","label":"上传时间"}}),_c('el-table-column',{attrs:{"prop":"createdUserName","label":"上传人"}}),_c('el-table-column',{attrs:{"label":"操作","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"tabHref borderR",on:{"click":function($event){return _vm.preView(scope.row)}}},[_vm._v("预览")]),_c('span',{staticClass:"tabHref",on:{"click":function($event){return _vm.handleDown(scope.row)}}},[_vm._v("下载")])]}}])})],1),(_vm.VisibleAttach)?_c('upload-attach-ment',{attrs:{"way":2,"dialogVisibleAttach":_vm.VisibleAttach,"objectId":_vm.objectId,"moduleType":_vm.moduleType},on:{"loadAttach":_vm.attachToInfo,"cancelAttath":function($event){_vm.VisibleAttach = false}}}):_vm._e(),_c('el-dialog',{attrs:{"visible":_vm.dialogVisablePre,"width":"600px","title":"文件预览","append-to-body":""},on:{"update:visible":function($event){_vm.dialogVisablePre=$event}}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":_vm.imgSrc}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }