 <template>
  <section class="enrtyFrom">
    <el-form
      @submit.native.prevent
      :model="enrtyForm"
      label-width="150px"
      ref="enrtyForm"
    >
      <el-form-item
        label="入职时间："
        :rules="[
          { required: true, message: '请选择入职时间', trigger: 'change' },
        ]"
        prop="time"
      >
        <el-date-picker
          v-model="enrtyForm.time"
          type="date"
          :disabled="disabled"
          placeholder="选择日期"
          value-format="yyyy-MM-dd"
          style="width: 360px"
          :picker-options="pickerOptions1"
        ></el-date-picker>
        <div
          style="font-size: 12px; line-height: 1; margin-top: 5px"
          v-if="disabled"
        >
          如需修改请联系管理员
        </div>
      </el-form-item>
      <el-form-item
        label="试用期结束："
        :rules="[{ required: true, message: '请选择时间', trigger: 'change' }]"
        prop="endTimeOfTrial"
        style="margin-bottom: 5px"
      >
        <el-date-picker
          v-model="enrtyForm.endTimeOfTrial"
          type="date"
          placeholder="选择日期"
          value-format="yyyy-MM-dd"
          style="width: 360px"
        ></el-date-picker>
      </el-form-item>
      <el-form-item v-if="!disabled">
        <el-checkbox v-model="enrtyForm.addToSchedule"
          >将试用期结束时间添加到日程提醒</el-checkbox
        >
      </el-form-item>
      <el-form-item
        label="约定首次开票时间："
        :rules="[{ required: true, message: '请选择时间', trigger: 'change' }]"
        prop="conventionFirstMakeInvoiceDate"
        style="margin-bottom: 0"
      >
        <el-date-picker
          v-model="enrtyForm.conventionFirstMakeInvoiceDate"
          type="date"
          placeholder="选择日期"
          value-format="yyyy-MM-dd"
          style="width: 360px"
        ></el-date-picker>
      </el-form-item>
      <el-form-item style="margin-bottom: 0;">
        <el-checkbox v-model="enrtyForm.firstMakeInvoiceAddToSchedule" disabled
          >将自动添加到日程提醒</el-checkbox
        >
      </el-form-item>
      <el-form-item label="是否设置项目成功：">
        <el-checkbox v-model="enrtyForm.projectFinished">是</el-checkbox>
      </el-form-item>
    </el-form>
  </section>
</template>
<script>
import { addOrEditEntry, getOperaterecordById, getEntryDate } from "../api/api";
export default {
  props: ["projectResumeId"],
  data() {
    return {
      childValue: false,
      enrtyForm: {
        time: null,
        endTimeOfTrial: null,
        addToSchedule: false,
        id: 0,
        version: 0,
        conventionFirstMakeInvoiceDate: null,
        firstMakeInvoiceAddToSchedule: true,
      },
      disabled: false,
      saveloading: false,
      pickerOptions1: {
        disabledDate(time) {
          return time.getTime() > new Date().getTime();
        },
      },
    };
  },
  created() {
    this.getEntryDate();
  },
  methods: {
    getEntryDate() {
      //获取入职时间
      getEntryDate({ projectResumeId: this.projectResumeId }).then((res) => {
        if (res.success) {
          this.enrtyForm.time = res.success
            ? res.result
            : new Date().Format("yyyy-MM-dd");
        }
      });
    },
    saveEntry: function (formName) {
      return new Promise((resolve, reject) => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.saveloading = true;
            addOrEditEntry({
              id: this.enrtyForm.id,
              objectContent: {
                endTimeOfTrial: this.enrtyForm.endTimeOfTrial,
                entryTime: this.enrtyForm.time,
                addToSchedule: this.enrtyForm.addToSchedule,
                projectFinished: this.enrtyForm.projectFinished,
                conventionFirstMakeInvoiceDate:
                  this.enrtyForm.conventionFirstMakeInvoiceDate,
                firstMakeInvoiceAddToSchedule:
                  this.enrtyForm.firstMakeInvoiceAddToSchedule,
              },
              projectResumeId: this.projectResumeId,
              version: this.enrtyForm.version,
            }).then((res) => {
              if (res.success) {
                this.$message.success("操作成功");
                this.$refs.enrtyForm.resetFields();
                this.$emit("loadList");
              }
              resolve();
              this.saveloading = false;
            });
          } else {
            reject();
            return false;
          }
        });
      });
    },
    loadEditInfo(row) {
      getOperaterecordById({
        id: row.id,
      }).then((res) => {
        if (res.success) {
          this.enrtyForm.time = res.result.objectContent.entryTime;
          this.enrtyForm.endTimeOfTrial =
            res.result.objectContent.endTimeOfTrial;
          this.enrtyForm.conventionFirstMakeInvoiceDate =
            res.result.objectContent.conventionFirstMakeInvoiceDate;
          this.enrtyForm.firstMakeInvoiceAddToSchedule =
           true;
          this.enrtyForm.addToSchedule = res.result.objectContent.addToSchedule;
          this.enrtyForm.id = res.result.id;
          this.enrtyForm.version = res.result.version;
          this.disabled = true;
        } else {
          this.$emit("childByValue", this.childValue);
        }
      });
    },
  },
};
</script>

<style lang="scss">
.enrtyFrom {
  .el-checkbox__input.is-checked + .el-checkbox__label, .el-checkbox__label {
    color: #606266 !important;
    font-size: 12px;
  }
}
</style>