<template>
  <div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="120px"
      class="demo-ruleForm"
      style="padding-right: 50px"
    >
      <el-form-item label="类型：" prop="scheduleType">
        <el-select
          filterable
          v-model="ruleForm.scheduleType"
          placeholder="请选择"
          style="width: 100%"
        >
          <el-option
            v-for="i in scheduleTypeList"
            :key="i.label"
            :label="i.label"
            :value="i.label"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="日期：" prop="scheduleTime">
        <el-date-picker
          type="datetime"
          placeholder="选择日期"
          value-format="yyyy-MM-dd HH:mm"
          format="yyyy-MM-dd HH:mm"
          v-model="ruleForm.scheduleTime"
          style="width: 100%"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="备注：" prop="comment">
        <el-input
          v-model="ruleForm.comment"
          type="textarea"
          maxlength="100"
          :row="3"
        ></el-input>
      </el-form-item>
      <el-form-item label="关联客户联系人：" prop="relatePersonId">
        <el-select
          filterable
          v-model="ruleForm.relatePersonId"
          clearable
          placeholder="请选择"
          style="width: 100%"
        >
          <el-option
            v-for="i in relatePersonList"
            :label="i.name"
            :key="i.id"
            :value="i.id"
          ></el-option>
        </el-select>
        <i
          class="iconfont icon-xz_bd addContact"
          @click="addCustomerContact"
        ></i>
      </el-form-item>
      <el-form-item label="参与者：" prop="joinUserIds">
        <el-select
          class="fromInput"
          multiple
          filterable
          collapse-tags
          v-model="ruleForm.joinUserIds"
          placeholder="请选择"
          style="width: 100%"
        >
          <el-option
            v-for="i in userList"
            :label="i.name"
            :key="i.id"
            :value="i.id"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>

    <el-dialog
      title="客户联系人"
      :visible.sync="addCustomerContactDialog"
      width="600px"
      append-to-body
    >
      <el-form
        @submit.native.prevent
        :model="customerContact"
        ref="linkManFrom"
        :rules="rulesLink"
        style="padding: 0 50px"
        :validate-on-rule-change="false"
        label-width="100px"
        label-position="right"
      >
        <el-form-item label="姓名：" prop="name">
          <el-input
            placeholder="请输入内容"
            v-model="customerContact.name"
            maxlength="20"
          ></el-input>
        </el-form-item>
        <el-form-item label="职位：" prop="position">
          <el-input
            placeholder="请输入内容"
            v-model="customerContact.position"
            maxlength="20"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系电话：" prop="tel">
          <el-input
            placeholder="请输入内容"
            v-model="customerContact.tel"
            maxlength="50"
          ></el-input>
        </el-form-item>
        <el-form-item label="邮箱：" prop="email">
          <el-input
            placeholder="请输入内容"
            v-model="customerContact.email"
            maxlength="50"
          ></el-input>
        </el-form-item>
        <el-form-item label="微信或其他：" prop="wechatOrOther">
          <el-input
            placeholder="请输入内容"
            v-model="customerContact.wechatOrOther"
            maxlength="50"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer" style="padding: 20px">
        <el-button @click="addCustomerContactDialog = false" size="small"
          >取 消</el-button
        >
        <el-button
          type="primary"
          size="small"
          @click="saveCustomerContact('linkManFrom')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  contactsOptions,
  getUserBaseInfos,
  scheduleAddOrEdit,
  contactsAddOrEdit,
} from "../../api/api";

export default {
  name: "schedule",
  props: ["targetId", "propScheduleData", "edit", "moduleType", "objectId"],
  data() {
    return {
      relatePersonList: [],
      userList: [],
      addCustomerContactDialog: false,
      customerContact: {
        companyId: 0,
        email: "",
        id: 0,
        name: "",
        position: "",
        tel: "",
        version: 0,
        wechatOrOther: "",
      },
      rulesLink: {
        name: [
          {
            required: true,
            trigger: "blur",
            message: "请输入姓名",
          },
        ],
        position: [
          {
            required: true,
            trigger: "blur",
            message: "请输入职位名称",
          },
        ],
      },
      ruleForm: {
        comment: "",
        id: "",
        joinUserIds: [],
        moduleType: "",
        relatePersonId: "",
        scheduleTime: "",
        scheduleType: "客户电话",
        targetId: 0,
        version: 0,
      },
      scheduleTypeList: [
        { label: "客户电话" },
        { label: "客户拜访" },
        { label: "客户邮件" },
        { label: "其他" },
      ],
      rules: {
        scheduleType: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        scheduleTime: [
          { required: true, message: "请选择", trigger: "change" },
        ],
      },
    };
  },
  created() {
    this.contactsOptions();
    this.getUser();
  },
  watch: {
    propScheduleData(n, o) {
      this.ruleForm = n;
    },
  },
  methods: {
    //删除左右两端的空格
    trim(str) {
      let a = "";
      a = str.replace(/(^\s*)|(\s*$)/g, "");
      return a;
    },
    // 保存客户联系
    saveCustomerContact() {
      this.customerContact.name = this.trim(this.customerContact.name);
      this.customerContact.companyId = this.targetId;
      contactsAddOrEdit(this.customerContact).then((res) => {
        this.addCustomerContactDialog = false;
        this.contactsOptions().then(() => {
          this.ruleForm.relatePersonId = this.relatePersonList[0].id;
        });
      });
    },

    addCustomerContact() {
      this.addCustomerContactDialog = true;
      this.customerContact = {};
    },
    submitForm(formName) {
      // debugger
      return new Promise((resole) => {
        this.ruleForm.moduleType = this.moduleType.type;
        this.ruleForm.targetId =
          this.moduleType.type == "客户" ? this.targetId : this.objectId;
        // debugger
        this.$refs[formName].validate((valid) => {
          if (valid) {
            scheduleAddOrEdit(this.ruleForm).then((res) => {
              resole();
            });
          }
        });
      }).catch((error) => {});
    },
    getUser() {
      getUserBaseInfos().then((res) => {
        if (res.success) {
          this.userList = res.result;
        }
      });
    },
    contactsOptions() {
      return new Promise((resolve) => {
        contactsOptions({ companyId: this.targetId }).then((res) => {
          this.relatePersonList = res.result;
          this.ruleForm.relatePersonId = this.relatePersonList[0].id;
          resolve();
        });
      });
    },
  },
};
</script>
