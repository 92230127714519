export function sort(list, cb) {
    for(let i=0;i<list.length-1;i++){//确定轮数
        for(let j=0;j<list.length-i-1;j++){//确定每次比较的次数
            if(cb(list[j],list[j+1])>=0){
                let tem = list[j]
                list[j] = list[j+1]
                list[j+1] = tem
            }
        }
    }
}
