<template>
  <section>
    <div>
      <div
        :style="url ? '' : 'margin-bottom:80px'"
        style="margin: 15px 0 15px 15px"
      >
        <el-select
          style="width: 70%"
          v-model="versionVal"
          value-key="id"
          @change="optionChange"
          placeholder="请选择"
          no-data-text="未上传附件"
        >
          <el-option
            v-for="(item, index) in versionOptions"
            :key="index"
            :label="item.fileName"
            :value="item"
          >
          </el-option>
        </el-select>
        <el-button
          :class="this.versionOptions.length > 0 ? 'tabHref' : ''"
          type="text"
          :loading="downloadBtnLoading"
          :disabled="this.versionOptions.length <= 0"
          size="mini"
          @click="downLoad"
          style="margin-left: 10px"
          ><i
            class="el-icon-download"
            style="font-size: 14px; margin-right: 5px"
          ></i
          >下载</el-button
        >
        <el-button
          class="tabHref"
          type="text"
          size="mini"
          @click="previewOpen"
          title="全局预览"
          style="margin-left: 10px"
          ><i
            class="drawIcon iconfont icon-quanping"
            style="font-size: 14px; margin-right: 5px"
          ></i>
          预览</el-button
        >
        <el-button
          class="tabHref"
          type="text"
          size="mini"
          @click="uploadshow"
          style="margin-left: 10px"
          ><i
            class="drawIcon iconfont icon-rc_gd_scfj"
            style="font-size: 14px; margin-right: 5px"
          ></i>
          上传</el-button
        >
      </div>
      <iframe
        v-if="url"
        id="frame"
        frameborder="0"
        scrolling="no"
        style="border: 0"
        width="100%"
        height="700px"
        :src="url"
      ></iframe>
      <p
        v-if="!versionOptions"
        style="position: absolute; top: 50%; left: 50%; color: #999999"
      >
        暂无预览……
      </p>
      <p v-if="unShow" style="height: 400px">不支持预览</p>
    </div>
    <el-dialog
      title="上传附件"
      :visible.sync="annexesVisible"
      v-model="from"
      width="40%"
      :modal-append-to-body="false"
      append-to-body
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <annexesfrom
        ref="annexesfrom"
        :id="this.id"
        v-if="annexesVisible"
      ></annexesfrom>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="handleClose()">取 消</el-button>
          <el-button type="primary" @click="submitFiles()">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </section>
</template>

<script>
import { attachmentPageList } from "../../api/api";
import { ApiBaseUrl, PreviewUrl } from "../../api/http";
import annexesfrom from "./annexesfrom.vue";
export default {
  props: ["id"],
  components: {
    annexesfrom,
  },
  data() {
    return {
      versionVal: {},
      versionOptions: [],
      downloadBtnLoading: false,
      annexesVisible: false,
      from: {},
      url: "",
      pagination: {
        total: 0,
        pageNo: 1,
        pageSize: 10,
      },
      unShow: false,
      versionValname: "",
    };
  },
  watch: {},
  created() {
    this.attachmentPageList();
  },
  methods: {
    // 附件
    attachmentPageList() {
      const params = {
        current: this.pagination.pageNo,
        size: this.pagination.pageSize,
        total: this.pagination.total,
        resumeId: this.id,
      };
      attachmentPageList(params).then((res) => {
        this.versionOptions = res.result;
        this.pagination.total = res.result.total;
        let val = {
          num: this.versionOptions.length,
          type: "附件",
        };
        this.$emit("number", val);
        if (this.versionOptions) {
          this.versionVal = this.versionOptions[0];
          this.optionChange(this.versionVal);
        }
      });
    },
    // 下载附件
    downLoad() {
      location.href =
        ApiBaseUrl +
        "/api/file/download?fileName=" +
        encodeURIComponent(this.versionVal.fileName) +
        "&filePath=" +
        this.versionVal.filePath;
      this.downloadBtnLoading = false;
    },
    optionChange(val) {
      if (!val) {
        return;
      }
      const fileType =
        val.filePath.split(".")[val.filePath.split(".").length - 1];
      this.versionVal = val;
      this.unShow = false;
      if (
        fileType == "doc" ||
        fileType == "docx" ||
        fileType == "xlsx" ||
        fileType == "ppt"
      ) {
        //wps预览
        this.url =
          "https://view.officeapps.live.com/op/view.aspx?src=" +
          PreviewUrl +
          val.filePath;
        // this.url =
        //   "https://view.officeapps.live.com/op/view.aspx?src=" +
        //   "http://yd-uat.casearching.com/pics/resumeFiles/e3840ed0-6173-4634-8d43-a2081b37234c.docx";
      } else if (fileType == "pdf") {
        //pdf预览
        this.url = "/pdf/web/viewer.html?file=" + PreviewUrl + val.filePath;
        // this.url =
        //   "/pdf/web/viewer.html?file=" +
        //   "http://yd-uat.casearching.com/pics/resumeFiles/f10d106e-74bb-4a88-a154-39773e60d696.pdf";
      } else if (fileType == "png" || fileType == "jpg" || fileType == "jpeg") {
        //图片预览
        this.url = PreviewUrl + val.filePath;
      } else if (fileType) {
        //下载
        this.unShow = true;
      }
    },
    //预览
    previewOpen() {
      let item = this.versionVal;
      const fileType =
        item.fileName.split(".")[item.fileName.split(".").length - 1];
      if (
        fileType == "doc" ||
        fileType == "docx" ||
        fileType == "xlsx" ||
        fileType == "pptx" ||
        fileType == "ppt"
      ) {
        //wps预览
        window.open(
          "https://view.officeapps.live.com/op/view.aspx?src=" +
            PreviewUrl +
            item.filePath,
          "_blank"
        );
      } else if (fileType == "pdf") {
        //pdf预览
        window.open(
          "/pdf/web/viewer.html?file=" + PreviewUrl + item.filePath,
          "_blank"
        );
      } else if (fileType == "png" || fileType == "jpg" || fileType == "jpeg") {
        //图片预览
        window.open(PreviewUrl + item.filePath, "_blank");
      } else if (fileType) {
        this.$message.error("不支持预览");
      }
    },
    // //提交
    submitFiles() {
      this.$refs.annexesfrom
        .submitFiles()
        .then(() => {
          this.annexesVisible = false;
          this.attachmentPageList();
        })
        .catch((error) => {});
    },
    //打开弹窗
    uploadshow() {
      this.annexesVisible = true;
    },
    //关闭弹窗
    handleClose() {
      this.annexesVisible = false;
      this.fileInfoList = [];
      this.attachmentPageList();
      // this.uploadFiles.files = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.tabHref:hover {
  color: #526ecc;
}
</style>
