<template>
  <section class="digScollDom customerResume">
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane :label="'在职' + count.onJob" name="在职"></el-tab-pane>
      <el-tab-pane :label="'离职' + count.departure" name="离职"></el-tab-pane>
      <el-tab-pane
        :label="'进展中' + count.onGoing"
        name="进展中"
      ></el-tab-pane>
    </el-tabs>
    <div>
    <el-row :gutter="24" class="pd40" style="padding-bottom: 10px">
      <el-col :span="24">
        <el-pagination
          :hide-on-single-page="filter.page.total > 10 ? false : true"
          v-if="filter.page.total > 0"
          style="padding: 10px 0;"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="filter.page.current"
          :page-size="filter.page.size"
          :pager-count="5"
          layout="total,prev, pager, next,sizes"
          :total="filter.page.total"
          :page-sizes="[20, 50, 100]"
        ></el-pagination>
     
          <el-table
          :header-cell-style="{
            'background-color': '#f5f7fa',
            height: '35px',
            'border-top': '1px #e5e5e5 solid',
          }"
          :data="ListData"
          v-sticky="{ top: 0, parent: '.digScollDom' }"
          class="tabBorder custor"
          v-loading="listLoading"
          ref="tableList"
          style="border-top: 0"
        >
          <el-table-column prop="resumeName" label="姓名" width="130">
            <template slot-scope="scope">
              <p class="tabHref" @click="goResumeFoo(scope.row.resumeId)">
                {{ scope.row.resumeName ? scope.row.resumeName : "--" }}
              </p>
            </template>
          </el-table-column>
          <el-table-column
            prop="company"
            label="目前公司"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column prop="title" show-overflow-tooltip label="目前职位">
          </el-table-column>
          <el-table-column prop="nowLocation" label="现居住地">
          </el-table-column>
          <el-table-column prop label="备注" width="80">
            <template slot-scope="scope">
              <div>
                <div v-if="activeName">
                  <el-popover
                    placement="bottom-start"
                    width="450"
                    trigger="hover"
                    popper-class="popperH"
                    :open-delay="1000"
                    @show="showMark(scope.row, '人才')"
                    @hide="showMark(scope.row, '人才')"
                  >
                    <el-row :gutter="24">
                      <el-col :span="24" v-if="remarkDatas.length > 0">
                        <div class="statusTitle">
                          <el-collapse v-model="activeCollapse" accordion>
                            <el-collapse-item
                              :name="'item_' + index"
                              v-for="(item, index) in remarkDatas"
                              :key="index"
                              class="foldStyle"
                            >
                              <template slot="title">
                                <span
                                  class="tag"
                                  :class="{
                                    tag09: item.type == '候选人沟通',
                                    tag10: item.type == '其他',
                                    tag12: item.type == '简历推荐',
                                  }"
                                  >{{ item.commentName }}</span
                                >
                                <div class="edit">
                                  {{
                                    item.createdTime
                                      ? item.createdTime.substring(0, 16)
                                      : ""
                                  }}<span class="mgl10">{{
                                    item.creatorName
                                  }}</span>
                                </div>
                              </template>
                              <el-form
                                label-position="top"
                                class="remaekRecode"
                              >
                                <el-form-item
                                  v-for="(i, index) in item.commentColumns"
                                  :key="'item_' + index"
                                  :label="i.columnTitle + '：'"
                                >
                                  {{ i.value }}
                                </el-form-item>
                              </el-form>
                            </el-collapse-item>
                          </el-collapse>
                        </div>
                      </el-col>
                      <el-col
                        v-else
                        :span="24"
                        style="text-align: center; color: #909399"
                      >
                        <p>数据加载中…</p>
                      </el-col>
                    </el-row>
                    <div slot="reference" class="tabHref">
                      <i class="iconfont icon-pl_bz"></i>
                      <span style="padding-left: 3px">{{
                        scope.row.remark
                      }}</span>
                    </div>
                  </el-popover>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="类型" prop="resumeType" width="130">
          </el-table-column>
          <el-table-column prop="lastModifiedTime" label="最近更新时间">
          </el-table-column>
          <el-table-column prop="creatorName" label="创建人" width="200">
          </el-table-column>
        </el-table>
    
        
        <el-pagination
          :hide-on-single-page="filter.page.total > 10 ? false : true"
          v-if="filter.page.total > 0"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="filter.page.current"
          :page-size="filter.page.size"
          :pager-count="5"
          layout="total,prev, pager, next,sizes"
          :total="filter.page.total"
          :page-sizes="[20, 50, 100]"
        ></el-pagination>
      </el-col>
    </el-row>
    </div>
    <el-drawer
      :visible.sync="isShowDetail"
      append-to-body
      :modal="false"
      custom-class="drawerClass redrawerClass"
      :close-on-click-modal="false"
      direction="ttb"
      :before-close="handleClose"
    >
      <div
        slot="title"
        class="drawerTitle"
        style="display: flex; justify-content: space-between"
      >
        <p><i class="drawIcon iconfont icon-rc_bt"></i>人才</p>
        <div>
          <el-button size="mini" @click="goNewPage(resumeId)">
            新窗口打开
          </el-button>
          <el-button size="mini" @click="showleftContent(resumeId)">
            {{ this.isshowleftContent ? "隐藏" : "显示" }}侧边栏
          </el-button>
        </div>
      </div>
      <div class="l_box" style="display: flex">
        <div class="l_content" v-if="isshowleftContent">
          <p class="l_c_tit">姓名</p>
          <div class="l_c_list">
            <p
              class="tabHref"
              v-for="(i, idx) in ListData1"
              :key="String(idx) + i.id"
              @click="updata(i.resumeId)"
              :class="i.isChoice ? 'l_c_list_active' : ''"
            >
              {{ i.resumeName }}
            </p>
          </div>
        </div>
        <resumeDetail
          ref="resumeDetails"
          :style="
            isshowleftContent ? 'width: calc(100% - 210px)' : 'width:100%'
          "
          style="height: calc(100vh - 120px); overflow: auto"
          v-if="isShowDetail"
          :resumeDetailId="resumeId"
          :hover="hoverInfo"
          :repeatResume="repeatResume"
          @getCallMobileInfo="getCallMobileInfo"
        ></resumeDetail>
      </div>
    </el-drawer>
  </section>
</template>

<script>
import {
  resumeSpecificCompanyStatus, //获取分页
  getCommonRecordList, //加载备注记录List
  resumeSpecificCompanyStatusCount, //获取项目特定项目状态下的人才数目
} from "../../api/api";
import {
  statisticsResumeNewWindow, //记录人才详情，新窗口打开点击量
} from "../../api/buryingPoint";
export default {
  props: ["cusId"],
  components: {
    resumeDetail: () => import("@/components/resumeDetail"),
  },
  computed: {
    activeCollapse() {
      return this.remarkDatas.map((i, index) => {
        return "item_" + index;
      });
    },
  },
  data() {
    return {
      activeName: "在职",
      filter: {
        companyId: "",
        type: "在职",
        page: {
          current: 1,
          size: 50,
          total: 0,
        },
      },
      listLoading: false,
      count: {
        onJob: 0,
        departure: 0,
        onGoing: 0,
      },
      ListData: [],
      hoverInfo: { matchAll: true, majorSearch: false, hover: true },
      repeatResume: { type: "列表", valId: "" },
      isshowleftContent: false,
      isShowDetail: false,
      remarkDatas: [],
      isaddMarkResume: false,
    };
  },
  created() {
    this.loadList();
  },
  methods: {
    handleClick(tab, event) {
      this.filter.type = tab.name;
      this.filter.page.current = 1;
      this.filter.page.size = 50;
      this.clearSelect();
      this.loadList();
    },
    // 获取合同列表
    loadList() {
      this.listLoading = true;
      this.filter.companyId = this.cusId;
      this.getCount(this.cusId);
      resumeSpecificCompanyStatus(this.filter).then((res) => {
        if (res.success) {
          this.listLoading = false;
          this.ListData = res.result.records;
          this.filter.page.total = res.result.total;
        }
        this.listLoading = false;
      });
    },
    getCount(id) {
      resumeSpecificCompanyStatusCount({
        companyId: id,
      }).then((res) => {
        if (res.success) {
          this.count = res.result;
        }
      });
    },
    //切换页数
    handleCurrentChange(val) {
      //currentPage 改变时会触发
      this.filter.page.current = val;
      this.loadList();
    },
    //pageSize 改变时会触发
    handleSizeChange(val) {
      this.filter.page.size = val;
      this.filter.page.current = 1;
      this.loadList();
    },
    // 跳转到详情
    goResumeFoo(id) {
      this.isShowDetail = true;
      this.resumeId = id;
      this.hoverInfo.hover = true;
    },
    getCallMobileInfo(params) {
      this.isShowDetail = false;
      this.loadList();
    },
    //打开新窗口
    goNewPage(id) {
      statisticsResumeNewWindow({ resumeId: id }).then();
      let newpage = this.$router.resolve({
        path: "/resume/detailPage",
        query: { resumeId: id },
      });
      window.open(newpage.href, "_blank");
    },
    // 显示隐藏侧边栏
    showleftContent(id) {
      this.ListData1 = this.ListData;
      this.ListData1.forEach((i) => {
        if (i.resumeId == id) {
          i.isChoice = true;
        } else {
          i.isChoice = false;
        }
      });
      this.isshowleftContent = !this.isshowleftContent;
    },
    //更新数据
    updata(id) {
      this.ListData1.forEach((i) => {
        if (i.resumeId == id) {
          i.isChoice = true;
        } else {
          i.isChoice = false;
        }
      });
      this.isShowDetail = true;
      this.resumeId = id;
      this.hoverInfo.hover = true;
      this.$refs.resumeDetails.reflsh(id);
    },
    handleClose() {
      this.isShowDetail = false;
      this.ListData1 = [];
      this.isshowleftContent = false;
    },
    showMark(row, type) {
      let _this = this;
      _this.time = false;
      _this.remarkDatas = [];
      this.timer = setTimeout(function () {
        _this.loadRecodeList(row, type);
      }, 1000);
    },
    // //加载备注记录List
    loadRecodeList(row, type) {
      this.dialogVisible = false;
      this.remarkDatas = [];
      getCommonRecordList({
        relativeId: row.resumeId,
        type: type,
      }).then((res) => {
        if (res.success) {
          this.remarkDatas = res.result;
        }
      });
    },
    clearSelect() {
      if (this.timer) {
        clearInterval(this.timer);
      }
    },
  },
  mounted() {
    // this.noScroll(); //禁止滚动
  },
  destroyed() {
    this.clearSelect();
    this.canScroll();
  },
};
</script>

<style lang='scss' scoped>
.l_box {
  // border-bottom:1px #ececec solid;
  ::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 8px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
  }
  ::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    box-shadow: inset 0 0 5px #ededed;
    background: #ededed;
  }
  ::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    // box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background: #fff;
  }
}
.l_content {
  width: 200px;
  border-right: 1px #ececec solid;
  border-bottom: 1px #ececec solid;
  overflow: auto;
  height: calc(100vh - 120px);
  .l_c_tit {
    font-weight: bold;
    font-size: 15px;
    line-height: 30px;
    padding: 5px 20px;
    border-bottom: 1px #ececec solid;
  }
  .l_c_list {
    p {
      color: #606266;
      line-height: 25px;
      padding: 5px 20px;
    }
    p:hover {
      color: #526ecc;
      background: #ececec;
      text-decoration: none;
    }
  }
  .l_c_list_active {
    color: #526ecc !important;
    background: #ececec;
    text-decoration: none;
  }
}
</style>

<style  lang='scss'>
.customerResume{
  .el-tabs__header{
    margin:0
  }
  .el-tabs__item{
    height: 32px;
    line-height: 32px;
  }
}

</style>