<template>
  <section>
    <!-- 标题 -->
    <el-row>
      <el-form @submit.native.prevent :model="filter" ref="filter">
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label prop="name" class="proserch">
              <el-input
                v-model="filter.filter"
                @keyup.enter.native="searchList()"
                placeholder="请输入项目名称/项目经理/参与者/公司名称，支持模糊搜索"
              >
                <el-button slot="append" @click="searchList()">搜索</el-button>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-row>
    <!-- 表单 -->
    <el-row :gutter="24">
      <div>
        <el-pagination
          :hide-on-single-page="pagination.total > 10 ? false : true"
          v-if="pagination.total > 0"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pagination.pageNo"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pagination.pageSize"
          layout="total,prev, pager, next,sizes"
          :total="pagination.total"
          style="padding: 0"
        ></el-pagination>
      </div>
      <el-col :span="24">
        <el-table
          :header-cell-style="{
            'background-color': '#f5f7fa',
          }"
          :data="ListData"
          class="tabBorder"
          v-loading="listLoading"
        >
          <el-table-column
            :show-overflow-tooltip="true"
            prop="id"
            label="ID"
            align="left"
            width="80px"
          ></el-table-column>

          <el-table-column
            :show-overflow-tooltip="true"
            prop="projectName"
            label="项目名称"
            align="left"
            min-width="300px"
          ></el-table-column>
          <el-table-column
            :show-overflow-tooltip="true"
            prop="managerName"
            label="项目经理"
            align="left"
            min-width="200px"
          ></el-table-column>
          <el-table-column
            :show-overflow-tooltip="true"
            prop="createdTime"
            label="创建时间"
            align="left"
            min-width="110px"
          ></el-table-column>
          <el-table-column
            :show-overflow-tooltip="true"
            prop="status"
            label="状态"
            align="left"
            min-width="100px"
          >
            <template slot-scope="scope">
              <span
                class="tag"
                :class="{
                  tag01: scope.row.status == '进行中',
                  tag02: scope.row.status == '成功',
                  tag03: scope.row.status == '暂停',
                }"
                >{{ scope.row.status }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            :show-overflow-tooltip="true"
            prop
            label="操作"
            align="left"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.inProject">已在项目中</span>
              <el-button
                v-else
                size="mini"
                class="tabHref"
                type="text"
                @click="addCandidates(scope.row.id)"
                >加入项目</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          :hide-on-single-page="pagination.total > 10 ? false : true"
          v-if="pagination.total > 0"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pagination.pageNo"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pagination.pageSize"
          layout="total,prev, pager, next,sizes"
          :total="pagination.total"
          style="padding: 0"
        ></el-pagination>
      </el-col>
    </el-row>
    <!-- 弹框 -->
    <!-- modal-append-to-body -->
    <!-- 遮罩层是否插入至 body 元素上，若为 false，则遮罩层会插入至 Dialog 的父元素上 -->
    <!-- close-on-click-modal -->
    <!-- 是否可以通过点击 modal 关闭 Dialog -->
  </section>
</template>
<script>
import {
  joinProjectList,
  getResumeProjectPageList,
  addResumesProjectresume, //添加候选人到项目
} from "../api/api";
import {
  statisticsResumeProjectSuccess, //记录加入项目成功量
  statisticsClickLabelIndexAddProjectSuccess, //记录标签搜索加入项目成功量
  statisticsClickSimilarAddProjectSuccess, //记录相似人才加入项目成功量
  statisticsClickFunctionEscape, //记录功能或页面跳出
  statisticsResumeOperationOperate, //人才库-路径操作
} from "../api/buryingPoint";
export default {
  props: ["resumeIds", "source", "entry", "hookedLabelIds"],
  created() {
    this.startTimeStamp = Math.round(new Date().getTime() / 1000);
    this.operateId = this.getStore("uid") + "-" + new Date().getTime();
    this.loadList();
  },
  data() {
    return {
      ListData: [],
      pagination: {
        total: 0,
        pageNo: 1,
        pageSize: 50,
      },
      listLoading: false,
      saveloading: false,
      filter: {
        filter: "",
      },
      projectIds: [],
      searchResumeId: 0,
      isOperation: false, //是否进行有效操作
    };
  },
  methods: {
    // 项目列表
    getResumeProjectPageList() {
      window.scrollTo(0, 0);
      return new Promise((resolve) => {
        getResumeProjectPageList({ resumeId: this.$route.query.id }).then(
          (res) => {
            if (res.success) {
              this.operationLogList = res.result;
              this.operationLogList.map((i) => ({
                ...i,
                limitValue: "",
              }));
            }
            resolve();
          }
        );
      });
    },
    addCandidates(projectId) {
      this.isOperation = true;
      this.projectIds = [projectId];
      let params = {};
      // if (this.source) {
      //   params = {
      //     projectIds: this.projectIds,
      //     resumeIds: this.resumeIds,
      //     // source: this.source,
      //     entry:this.entry
      //   };
      // } else {
      params = {
        projectIds: this.projectIds,
        resumeIds: this.resumeIds,
        entry: this.entry,
      };
      // }
      addResumesProjectresume(params).then((res) => {
        this.$emit("loadList");
        if (res.success) {
          if (
            this.entry == "人才列表（全文检索）" ||
            this.entry == "人才列表（常规搜索）"
          ) {
            statisticsResumeOperationOperate({
              operateId: this.operateId,
              operateName: "加入项目",
              pageType: "列表",
            }).then();
          } else {
            statisticsResumeOperationOperate({
              operateId: this.operateId,
              operateName: "加入项目",
              pageType: "详情",
            }).then();
          }
          if (this.entry == "人才详情（相似人才）") {
            statisticsClickSimilarAddProjectSuccess({
              resumeId: this.resumeIds[0],
              successAddProjectId: this.projectIds[0],
              superResumeId: parseInt(this.$route.query.resumeId),
            }).then();
          } else {
            statisticsResumeProjectSuccess({
              entry: this.entry,
              resumeIds: this.resumeIds,
              successAddProjectId: this.projectIds[0],
            }).then();
            let entry = "";
            if (
              this.entry == "人才列表（常规搜索）" ||
              this.entry == "人才列表（全文搜索）"
            ) {
              entry = "人才库-人才列表";
            } else if (
              this.entry == "人才详情（常规搜索）" ||
              this.entry == "人才详情（全文搜索）"
            ) {
              entry = "人才库-人才详情";
            } else {
              entry = this.entry;
            }
            if (this.hookedLabelIds) {
              statisticsClickLabelIndexAddProjectSuccess({
                entry: entry,
                resumeIds: this.resumeIds,
                successAddProjectId: this.projectIds[0],
                hookedLabelIds: this.hookedLabelIds,
              }).then();
            }
          }
          if (this.$route.query.id) {
            this.getResumeProjectPageList().then(() => {
              this.$emit("childByValue", this.operationLogList);
            });
          }

          let repeatList = "";
          if (res.result.repeats) {
            res.result.repeats.forEach((i) => {
              repeatList += "<li>" + i + "</li>";
            });
          }
          if (res.result.showRepeat == 1) {
            //均不在
            this.$message.success("候选人加入项目成功");
            setTimeout(() => {
              this.loadList();
            }, 2000);
          } else if (res.result.showRepeat == 2) {
            //部分已存在
            this.$confirm(
              '<p style="font-weight:bold">部分候选人添加项目成功</p><p>剩余添加失败原因：</p><ul style="padding-inline-start:0">' +
                repeatList +
                "</ul>",
              "提示",
              {
                confirmButtonText: "确定",
                dangerouslyUseHTMLString: true,
                showClose: false,
                customClass: "altCls",
                showCancelButton: false,
                closeOnClickModal: false,
                closeOnPressEscape: false,
              }
            )
              .then(() => {
                // this.$emit("loadList");
                this.loadList();
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "已取消",
                });
              });
          } else if (res.result.showRepeat == 3) {
            //均存在
            this.$confirm(
              '<p style="font-weight:bold">候选人加入项目失败</p><p>失败原因：</p><ul style="padding-inline-start:0">' +
                repeatList +
                "</ul>",
              "提示",
              {
                confirmButtonText: "确定",
                dangerouslyUseHTMLString: true,
                showClose: false,
                customClass: "altCls",
                showCancelButton: false,
                closeOnClickModal: false,
                closeOnPressEscape: false,
              }
            )
              .then(() => {
                // this.$emit("loadList");
                this.loadList();
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "已取消",
                });
              });
          }
        }
      });
    },
    searchList() {
      this.pagination.pageNo = 1;
      this.loadList();
    },
    loadList() {
      this.listLoading = true;
      joinProjectList({
        filter: this.filter.filter,
        resumeIds: this.resumeIds,
        page: {
          current: this.pagination.pageNo,
          size: this.pagination.pageSize,
          searchCount: true,
        },
      }).then((res) => {
        if (res.success) {
          this.listLoading = false;
          this.ListData = res.result.records;
          this.pagination.total = res.result.total;
        }
        this.listLoading = false;
      });
    },
    handleSizeChange(val) {
      //pageSize 改变时会触发
      this.pagination.pageSize = val;
      this.pagination.pageNo = 1;
      this.loadList();
    },
    handleCurrentChange(val) {
      //currentPage 改变时会触发
      this.pagination.pageNo = val;
      this.loadList();
    },
    handleClose(done) {
      done();
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.dialogVisibleProject = false;
    },
  },
  destroyed() {
    console.log(11);
    if (!this.isOperation) {
      statisticsClickFunctionEscape({
        endTimeStamp: Math.round(new Date().getTime() / 1000),
        entry: this.entry,
        presentStep: "加入项目",
        startTimeStamp: this.startTimeStamp,
        nextStep: this.nextStep ? this.nextStep : "点击了【加入项目弹框】的X",
      }).then();
    }
  },
};
</script>

<style lang='scss'>
.proserch {
  .el-form-item__content {
    line-height: 32px;
    display: flex;
  }
  .el-input__inner {
    border-radius: 5px 0 0 5px;
  }
  .el-button--mini {
    border-radius: 0px 5px 5px 0px;
    border-left: 0;
    padding: 0px 15px;
    line-height: 32px;
    height: 32px;
    margin: 1px 0;
  }
}
</style>