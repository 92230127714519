<template>
  <section style="background-color: #fff">
    <div class="">
      <el-form
        @submit.native.prevent
        :model="emailform"
        ref="emailform"
        :rules="rules"
        label-width="100px"
        class="addForm"
      >
        <el-row :gutter="24">
          <el-col :span="type !== '批量发邮件' ? 16 : 20">
            <el-form-item label="收件人：" prop="receiver">
              <el-input
                v-model="emailform.receiver"
                @input="changeSpace"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="type !== '批量发邮件' ? 16 : 20">
            <el-form-item label="抄    送：" prop="cc">
              <el-input v-model="emailform.cc"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="type !== '批量发邮件' ? 16 : 20">
            <el-form-item label="主    题：" prop="subject">
              <el-input
                v-model="emailform.subject"
                placeholder="最多不超过100个字"
                maxlength="100"
              ></el-input>
              <p
                v-for="(item, index) in annexListdata"
                :key="'ind_' + index"
                class="flexRow"
              >
                <span :title="item.fileName"> {{ item.fileName }}</span>
                <i class="el-icon-close" @click="checkAnnex(index)"></i>
              </p>
            </el-form-item>
          </el-col>
          <el-col :span="4" v-if="type !== '批量发邮件'">
            <el-select
              style="width: 100%"
              v-model="emailform.templateId"
              placeholder="请选择"
              @change="getInitEmailTemplate"
            >
              <el-option-group
                v-for="(group, index) in options"
                :key="index"
                :label="group.label"
              >
                <el-option
                  v-for="(item, index) in group.personalTemplates"
                  :key="index"
                  :label="item.templateName"
                  :value="item.id"
                >
                </el-option>
              </el-option-group>
            </el-select>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="20">
            <tinymce
              v-model="emailform.content"
              :height="300"
              @annexList="annexList"
              :ishowAnnexes="true"
              ref="removeAnList"
              style="margin-left: 30px"
            />
          </el-col>
        </el-row>
      </el-form>
    </div>
    <!-- 授权码 -->
    <el-dialog
      title="绑定授权码"
      :visible.sync="dialogVisible"
      width="600px"
      append-to-body
      :close-on-click-modal="false"
      destroy-on-close
    >
      <p
        style="
          display: flex;
          justify-content: space-between;
          margin-bottom: 15px;
        "
      >
        <span style="color: #999" v-if="isFrist"
          >首次使用请绑定{{ email }}的授权码</span
        >
        <span style="color: #999" v-else>您填写的授权码有误，请重新填写</span>
        <span class="tabHref" @click="goEmailGetCode">如何获取授权码?</span>
      </p>
      <div>
        <span>授权码：</span>
        <el-input
          show-password
          v-model.trim="authCode"
          style="width: 90%"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="mini" @click="saveCode()"
          >绑定授权码,并继续发邮件</el-button
        >
      </span>
    </el-dialog>
  </section>
</template>

<script>
import Tinymce from "@/components/Tinymce";
import {
  sendEmail,
  chooseEmailTemplate, //获取邮件子主题列表
  initEmailTemplate, //自动填充邮件模板
  emailRecommend, //邮件简历推荐
  emailInterview, //邮件面试
  curCandidateEmail, //获取当前候选人邮箱
  getBatchEmail, //批量操作根据勾选的候选人获取其邮箱
  curemail, //当前登陆人的邮箱
  setAuthCode, //设置当前登陆人邮件签名
} from "../../api/api";
import { PreviewUrl } from "../../api/http";
export default {
  props: [
    "type",
    "interviewRound",
    "interviewTime",
    "projectId",
    "resumeId",
    "projectResumeId",
    "candidateIds",
  ],
  components: {
    Tinymce,
  },
  data() {
    return {
      emailform: {
        receiver: "",
        cc: null,
        subject: "",
        content: "",
        templateId: null,
      },
      options: [],
      annexListdata: [], //附件
      rules: {
        emailType: [
          { required: true, message: "请选择收件人", trigger: "change" },
        ],
        receiver: [
          { required: true, message: "请填写收件人", trigger: "blur" },
        ],
        subject: [{ required: true, message: "请填写主 题", trigger: "blur" }],
      },
      dialogVisible: false,
      authCode: "",
      formName: null,
      data: null,
      email: "",
      isFrist: true,
    };
  },
  created() {
    if (this.type !== "批量发邮件") {
      this.getChooseEmailTemplate(this.type);
    } else {
      this.getGetEmail();
    }
  },
  methods: {
    changeSpace(val) {
      this.emailform.receiver = val.replace(" ", "；");
    },
    annexList(arr) {
      this.annexListdata = arr;
    },
    checkAnnex(i) {
      //点击删除
      this.$confirm("是否删除该附件？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.annexListdata.splice(i, 1);
          this.$refs.removeAnList.handleAnRemove(this.annexListdata);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    getChooseEmailTemplate(type) {
      chooseEmailTemplate({ emailType: this.type }).then((res) => {
        if (res.success) {
          this.options = res.result.templates;
        }
      });

      curCandidateEmail({
        projectResumeId: this.projectResumeId,
        status: type == "给客户：简历推荐" ? "简历推荐" : "客户面试",
      }).then((res) => {
        if (res.success) {
          this.emailform.receiver = res.result.emails;
          this.emailform.content = res.result.signature;
        }
      });
    },
    getGetEmail() {
      getBatchEmail({ candidateIds: this.candidateIds }).then((res) => {
        if (res.success) {
          this.emailform.receiver = res.result.emails;
          this.emailform.content = res.result.signature;
        }
      });
    },
    getInitEmailTemplate(val) {
      let that = this;
      this.emailform.content = "";
      let data = {
        templateId: val,
        interviewRound: that.interviewRound ? that.interviewRound : "",
        interviewTime: that.interviewTime ? that.interviewTime : "",
        projectId: this.projectId ? this.projectId : "",
        resumeId: this.resumeId ? this.resumeId : "",
      };
      initEmailTemplate(data).then((res) => {
        if (res.success) {
          this.emailform.subject = res.result.templateSubject;
          this.emailform.content = res.result.content;
          this.annexListdata = res.result.attachmentPaths;
          this.$refs.removeAnList.setContent(res.result.content);
          this.$refs.removeAnList.setData(res.result.attachmentPaths);
        }
      });
    },
    saveEmail(formName, data) {
      this.formName = formName;
      this.data = data;
      let req = null;
      return new Promise((resolve, reject) => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let parmas = {};
            if (
              this.emailform.content == "" ||
              this.emailform.content == null ||
              !this.emailform.content
            ) {
              this.$message.error("内容不能为空");
              this.$emit("loadList", "isNoloading");
              return;
            }
            if (this.type == "给客户：简历推荐") {
              parmas = {
                attachments: this.annexListdata,
                candidateId: this.resumeId,
                projectResumeId: this.projectResumeId,
                receiver: this.emailform.receiver,
                cc: this.emailform.cc,
                subject: this.emailform.subject,
                content: this.emailform.content,
              };
              req = emailRecommend(parmas);
            } else if (this.type == "给候选人：客户面试通知") {
              parmas = {
                addToSchedule: data.addToSchedule,
                interviewDate: data.time,
                interviewRound: data.num,
                isFinal: data.isFinal,
                attachments: this.annexListdata,
                candidateId: this.resumeId,
                projectResumeId: this.projectResumeId,
                receiver: this.emailform.receiver,
                cc: this.emailform.cc,
                subject: this.emailform.subject,
                content: this.emailform.content,
                remindCandidate: data.checked,
              };
              req = emailInterview(parmas);
            } else {
              this.emailform.attachments = this.annexListdata;
              req = sendEmail(this.emailform);
            }
            req.then((res) => {
              if (res.result.status == "SUCCESS") {
                this.$message.success("邮件发送成功");
                this.$emit("loadList", true);
                resolve();
              } else if (
                res.result.status == "NO_AUTH_CODE" ||
                res.result.status == "WRONG_AUTH_CODE"
              ) {
                this.$emit("loadList", "isNoloading");
                this.getcuremail(res.result.status);
              } else {
                this.$confirm(
                  '<p style="font-weight:bold">邮件发送失败</p><p style="color:#999">失败原因：' +
                    res.result.errorDesc +
                    "</p>",
                  "提示",
                  {
                    confirmButtonText: "确定",
                    showClose: false,
                    customClass: "altCls",
                    cancelButtonProps: "{ style: { display:'none' } }",
                    dangerouslyUseHTMLString: true,
                  }
                )
                  .then(() => {
                    this.$emit("loadList", "isNoloading");
                    //  this.$emit("loadList", true);
                  })
                  .catch(() => {
                    this.$emit("loadList", "isNoloading");
                    //  this.$emit("loadList", true);
                    // this.$message({
                    //   type: "info",
                    //   message: "已取消",
                    // });
                  });
              }
            });
          } else {
            this.$emit("loadList", "isNoloading");
          }
        });
      });
    },
    //获取当前登陆人的邮箱
    getcuremail(status) {
      if (status == "NO_AUTH_CODE") {
        this.isFrist = true;
      } else {
        this.isFrist = false;
      }
      curemail().then((res) => {
        if (res.success) {
          this.email = res.result.curEmail;
          this.dialogVisible = true;
        }
      });
    },
    goEmailGetCode() {
      //pdf文件查看
      window.open(
        "/pdf/web/viewer.html?file=" +
          PreviewUrl +
          "/pics/email-authcode-operate-guide.pdf",
        "_blank"
      );
    },
    saveCode() {
      if (this.authCode !== "" && this.authCode !== null && this.authCode) {
        setAuthCode({ authCode: this.authCode }).then((res) => {
          if (res.success) {
            // this.$message.success("保存成功！");
            this.saveEmail(this.formName, this.data);
            this.dialogVisible = false;
          }
        });
      } else {
        this.$message.error("请填写授权码");
      }
    },
  },
};
</script>
<style lang='scss' scoped>
.flexRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px;
  background: none;
  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>