<template>
  <div>
    <div v-if="RecordList.length > 0">
      <div
        v-for="(item, index) in RecordList"
        :key="index"
        class="time-line-div"
      >
        <div class="time-line_l" v-if="!contactId">
          <p class="time">{{ item.createdTime }}</p>
          <p class="name">{{ item.creatorName }}</p>
        </div>
        <p class="line" v-if="!contactId"></p>
        <div
          :class="{ 'time-line_r': !contactId }"
          :style="contactId ? 'padding:0 10px' : ''"
        >
          <div class="time-line_con">
            <p class="r_title">
              <span
                class="tag"
                :class="{
                  tag15: item.type == '项目信息' || item.type == '客户沟通',
                  tag09: item.type == '候选人反馈',
                  tag14: item.type == '客户拜访',
                }"
                >{{ item.commentName }}</span
              >
              <span v-if="item.relatePersonName" style="margin-left: 10px"
                >联系人：{{ item.relatePersonName }}</span
              >
              <i
              v-if="item.createdUserId==useId"
                class="el-icon-edit"
                @click="edit(item)"
                style="float: right; margin: 10px"
              ></i>
            </p>
            <ul class="r_con">
              <li
                v-for="(i, index) in item.commentColumns"
                :key="'Col' + index"
              >
                <p class="tit">{{ index + 1 }}.{{ i.columnTitle }}：</p>
                <p class="con"><span v-html="i.valueHtml"></span></p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="dott" v-if="!contactId"></div>
    </div>
    <div v-else style="color: #999">暂无备注记录！</div>
  </div>
</template>

<script>
import {
  getCommonRecordList, //附件列表
  GetCompanyContactCommentrecordList,
} from "../../api/api";
export default {
  name: "markRecode",
  props: ["objectId", "moduleType", "contactId"],
  data() {
    return {
      //附件
      RecordList: [],
      useId: parseInt(this.getStore("uid")),
    };
  },
  created() {
    if (this.contactId) {
      this.loadListBycontactId();
    } else {
      this.loadRecodeList(this.objectId, this.moduleType);
    }
  },
  methods: {
    loadRecodeList(id, type) {
      //加载备注记录List
      getCommonRecordList({
        relativeId: id,
        type: type,
      }).then((res) => {
        if (res.success) {
          this.RecordList = [];
          this.RecordList = res.result.map((result) => ({
            ...result,
            commentColumns: (result.commentColumns || []).filter(
              (d) => d.value
            ),
          }));
        }
      });
    },
    loadListBycontactId() {
      GetCompanyContactCommentrecordList(this.contactId).then((res) => {
        if (res.success) {
          this.RecordList = [];
          this.RecordList = res.result.map((result) => ({
            ...result,
            commentColumns: (result.commentColumns || []).filter(
              (d) => d.value
            ),
          }));
        }
      });
    },
    edit(data) {
      this.$parent.addMark(data);
    },
  },
};
</script>

<style lang="scss" scoped>
.time-line-div {
  position: relative;
  min-height: 85px;
  margin-top: 15px;
  &:nth-child(1) {
    margin-top: 0 !important;
  }
  .time-line_l {
    position: absolute;
    left: 0;
    width: 135px;
    text-align: right;
    p {
      margin: 0;
      line-height: 1;
    }
    .time {
      padding-top: 24px;
      font-size: 12px;
      color: #999;
    }
    .name {
      font-size: 13px;
      margin-top: 10px;
      color: #606266;
    }
  }
  .line {
    position: absolute;
    left: 155px;
    width: 12px;
    height: 12px;
    top: 20px;
    background: #e4e7ed;
    border-radius: 50%;
    z-index: 10;
  }
  .time-line_r {
    position: relative;
    left: 191px;
    width: calc(100% - 180px);
  }
  .time-line_con {
    border: 1px solid #e5e5e5;
    .r_title {
      height: 40px;
      line-height: 40px;
      background-color: #f5f5f5;
      margin: 0;
      padding-left: 10px;
      color: #606266;
      border-bottom: 1px solid #e5e5e5;
    }
    .r_con {
      padding: 15px 20px;
      font-size: 13px;
      line-height: 21px;
      .tit {
        font-size: 13px;
        color: #606266;
        // margin-top: 8px;
      }
      .con {
        color: #333;
        font-style: 13px;
        line-height: 20px;
      }
    }
  }
}
.dott {
  position: absolute;
  width: 5px;
  left: 173px;
  height: calc(100% - 45px);
  top: 15px;
  bottom: 5px;
  z-index: 1;
  background-color: #e5e5e5;
}
</style>