<template>
  <div ref="editResumeList">
    <!-- 详情页弹出框 -->
    <el-popover
      ref="editPopper"
      v-if="isEditDetail"
      placement="bottom"
      trigger="manual"
      v-model="tempPopoverVisible"
      @show="showPop"
      @hide="hidePop"
    >
      <div
        v-if="!topOrBottom"
        style="text-align: right; margin: 0 0 15px; padding-bottom: 15px"
      >
        <el-button size="mini" type="default" @click="cancelPopover()"
          >取消</el-button
        >
        <el-button type="primary" size="mini" @click="saveEditItemOfDetail()"
          >确定</el-button
        >
      </div>
      <el-form
        v-if="type == 'salary'"
        :model="formData"
        ref="formData"
        :rules="desabled ? {} : rulesOfSalary"
      >
        <!-- 范围 -->
        <el-row>
          <el-col :span="8" style="padding: 0">
            <el-form-item label-width="0" prop="lowAimSalary">
              <el-input
                :placeholder="editPlaceHolder"
                :disabled="desabled"
                maxlength="11"
                v-model="formData.lowAimSalary"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col
            :span="1"
            style="text-align: center; padding: 0; line-height: 35px"
            >-</el-col
          >
          <el-col :span="8" style="padding: 0">
            <el-form-item label-width="0" prop="highAimSalary">
              <el-input
                :placeholder="editPlaceHolder"
                maxlength="11"
                :disabled="desabled"
                v-model="formData.highAimSalary"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="2" style="padding-left: 5px; margin-top: 10px"
            >元/月</el-col
          >
          <el-col :span="2" style="padding-left: 10px">
            <el-form-item>
              <el-checkbox
                v-model="formData.isMianYi"
                @change="Haschecked($event)"
                >面议</el-checkbox
              >
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-form v-else :model="formData" ref="formData">
        <el-form-item prop="tempEditContent" :rules="rules">
          <!-- input框 -->
          <el-input
            v-if="
              type == 'current_salary' ||
              type == 'qq' ||
              type == 'linked_in' ||
              type == 'title'
            "
            :type="
              (type == 'qq' || type == 'linked_in') &&
              resumeInfoList.offerOrEntryProjectResumeId &&
              !resumeInfoList.offerOrEntryPermission
                ? 'password'
                : ''
            "
            :maxlength="type == 'qq' ? 11 : type == 'title' ? 20 : ''"
            :placeholder="editPlaceHolder"
            v-model.trim="formData.tempEditContent"
          ></el-input>
          <span class="repeatCla" v-if="type == 'qq' && repeatQq">
            QQ号码重复,
            <el-button
              type="text"
              style="color: #ff3f3f"
              size="mini"
              @click="checkRepeat"
              >点击查看重复人才</el-button
            >
          </span>
          <span class="repeatCla" v-if="type == 'linked_in' && repeatLinkedin">
            领英重复,
            <el-button
              type="text"
              style="color: #ff3f3f"
              size="mini"
              @click="checkRepeat"
              >点击查看重复人才</el-button
            >
          </span>
          <!-- 下拉选择器 -->
          <el-select
            filterable
            clearable
            v-if="type == 'firstDegree'"
            :ref="refName"
            @visible-change="changeVisible"
            :placeholder="editPlaceHolder"
            v-model="formData.tempEditContent"
          >
            <el-option label="初中" value="初中"></el-option>
            <el-option label="高中" value="高中"></el-option>
            <el-option label="中技" value="中技"></el-option>
            <el-option label="中专" value="中专"></el-option>
            <el-option label="大专" value="大专"></el-option>
            <el-option label="本科" value="本科"></el-option>
            <el-option label="硕士" value="硕士"></el-option>
            <el-option label="博士" value="博士"></el-option>
            <el-option label="博士后" value="博士后"></el-option>
            <el-option label="MBA" value="MBA"></el-option>
            <el-option label="其他" value="其他"></el-option>
          </el-select>
          <el-select
            filterable
            clearable
            v-if="type == 'jobStatus'"
            :ref="refName"
            @visible-change="changeVisible"
            :placeholder="editPlaceHolder"
            v-model="formData.tempEditContent"
          >
            <el-option label="在职看机会" value="在职看机会"></el-option>
            <el-option label="在职不看机会" value="在职不看机会"></el-option>
            <el-option label="离职" value="离职"></el-option>
            <el-option label="应届毕业生" value="应届毕业生"></el-option>
          </el-select>
          <el-cascader
            v-if="
              type == 'VocationList' ||
              type == 'locationList' ||
              type == 'functionName' ||
              type == 'vocationName'
            "
            :show-all-levels="false"
            :ref="refName"
            filterable
            v-model="formData.tempEditContent"
            :options="options"
            :props="props"
            placeholder="请选择"
            collapse-tags
            @visible-change="changeVisible"
            class="cascaderOfEditResumeList"
          ></el-cascader>
          <!-- 日期选择器 -->
          <el-date-picker
            :ref="refName"
            v-if="type == 'birth' || type == 'beginWorkTime'"
            v-model="formData.tempEditContent"
            value-format="yyyy-MM"
            type="month"
            :placeholder="editPlaceHolder"
            style="width: 100%"
            @focus="changeVisible"
          ></el-date-picker>
          <!-- textarea -->
          <el-input
            :rows="2"
            maxlength="500"
            type="textarea"
            style="min-width: 300px"
            :placeholder="editPlaceHolder"
            :dangerouslyUseHTMLString="true"
            v-if="
              type == 'personal' ||
              type == 'certificate' ||
              type == 'english' ||
              type == 'training' ||
              type == 'others'
            "
            v-model="formData.tempEditContent"
          ></el-input>
        </el-form-item>
      </el-form>
      <div
        style="text-align: right; margin: 15px 0 0; padding-top: 15px"
        v-if="topOrBottom"
      >
        <el-button size="mini" type="default" @click="cancelPopover()"
          >取消</el-button
        >
        <el-button type="primary" size="mini" @click="saveEditItemOfDetail()"
          >确定</el-button
        >
      </div>
      <i
        slot="reference"
        @click="tempPopoverVisible = !tempPopoverVisible"
        class="el-icon-edit edit-icon-style"
      ></i>
    </el-popover>
    <!-- 列表页弹出框 -->
    <el-popover
      v-else
      placement="top"
      trigger="manual"
      v-model="tempPopVisible"
      @show="showEditPopover(index, 'type')"
      @hide="hideEditPopover(index, 'type')"
      :append-to-body="false"
    >
      <el-form v-if="editcontent" :model="formData" ref="formData">
        <el-form-item prop="tempEditContent" :rules="rules">
          <el-select
            clearable
            filterable
            v-if="type == 'education' || type == 'sex'"
            @visible-change="optionVisibleChange"
            v-model="formData.tempEditContent"
            placeholder="请选择"
          >
            <div v-if="type == 'education'">
              <el-option label="初中" value="初中"></el-option>
              <el-option label="高中" value="高中"></el-option>
              <el-option label="中技" value="中技"></el-option>
              <el-option label="中专" value="中专"></el-option>
              <el-option label="大专" value="大专"></el-option>
              <el-option label="本科" value="本科"></el-option>
              <el-option label="硕士" value="硕士"></el-option>
              <el-option label="博士" value="博士"></el-option>
              <el-option label="博士后" value="博士后"></el-option>
              <el-option label="MBA" value="MBA"></el-option>
              <el-option label="其他" value="其他"></el-option>
            </div>
            <div v-if="type == 'sex'">
              <el-option label="男" value="男"></el-option>
              <el-option label="女" value="女"></el-option>
            </div>
          </el-select>
          <el-input
            v-if="
              type == 'name' ||
              type == 'company' ||
              type == 'title' ||
              type == 'school'
            "
            :placeholder="editPlaceHolder"
            v-model.trim="formData.tempEditContent"
          ></el-input>
        </el-form-item>
      </el-form>

      <div style="text-align: right; margin: 15px 0 0; padding-top: 15px">
        <el-button size="mini" type="default" @click="cancelPop()"
          >取消</el-button
        >
        <el-button type="primary" size="mini" @click="saveEditItem(row)"
          >确定</el-button
        >
      </div>
      <i
        v-show="editcontent && showEditIcon"
        slot="reference"
        class="el-icon-edit edit-icon-style"
        @click.stop="tempPopVisible = !tempPopVisible"
        style="
          float: right;
          height: 50px;
          line-height: 50px;
          font-size: 14px;
          padding-right: 10px;
        "
      ></i>
    </el-popover>
  </div>
</template>

<script>
import {
  getResumeById,
  addOrEditResume,
  getRepeatedResumeByEmailOrMobile,
} from "../api/api";
import resumeDetail from "./resumeDetail.vue";
export default {
  name: "editResumeList",
  data() {
    const validateLinkedin = (rule, value, callback) => {
      // 校验领英地址
      if (value && value.indexOf("linkedin") == -1) {
        callback(new Error("请输入正确的网址"));
      } else {
        callback();
      }
    };
    const intNum = (rule, value, callback) => {
      // 校验数字
      let reg = /^[0-9]*$/;
      if (value && !reg.test(value)) {
        callback(new Error("请输入数字"));
      } else {
        callback();
      }
    };
    const CheckRepeatQq = (rule, value, callback) => {
      // 校验QQ是否重复
      if (value) {
        getRepeatedResumeByEmailOrMobile({
          resumeId: this.resumeInfoList.id,
          email: "",
          mobile: "",
          wechat: "",
          qq: value,
          linked_in: "",
        }).then((res) => {
          if (res.success && res.result && res.result.length > 0) {
            this.repeatQq = true;
            this.repeatResumeId = res.result[0].id;
            callback();
          } else {
            this.repeatQq = false;
            callback();
          }
        });
      } else {
        this.repeatQq = false;
        callback();
      }
    };
    const CheckRepeatLinkedin = (rule, value, callback) => {
      // 校验领英是否重复
      if (value) {
        getRepeatedResumeByEmailOrMobile({
          resumeId: this.resumeInfoList.id,
          email: "",
          mobile: "",
          wechat: "",
          qq: "",
          linked_in: value,
        }).then((res) => {
          if (res.success && res.result && res.result.length > 0) {
            this.repeatLinkedin = true;
            this.repeatResumeId = res.result[0].id;
            callback();
          } else {
            this.repeatLinkedin = false;
            callback();
          }
        });
      } else {
        this.repeatLinkedin = false;
        callback();
      }
    };
    return {
      pagination: {
        total: 0,
        pageNo: 1,
        pageSize: 10,
      },
      formData: { tempEditContent: "", name: "" },
      repeatQq: false,
      repeatLinkedin: false,
      repeatResumeId: "",
      refName: "",
      editPlaceHolder: "",
      editcontent: "",
      flag: true,
      resumeList: [],
      tempPopoverVisible: false,
      tempPopVisible: false,
      showEditIcon: false,
      isIconShow: true,
      desabled: true,
      topOrBottom: true,
      options: [],
      props: {},
      props1: {
        value: "id",
        label: "name",
        children: "children",
        checkStrictly: true,
      },
      props3: {
        value: "id",
        label: "name",
        children: "children",
        multiple: true,
        // disabled: "hasChild",
        checkStrictly: true,
      },

      props2: {
        value: "id",
        label: "name",
        children: "children",
        multiple: true,
        // checkStrictly: true,
      },
      rules: [{ required: true, message: "内容不能为空", trigger: "blur" }],
      rulesOfQQ: [
        { validator: CheckRepeatQq, trigger: "blur" },
        { required: true, message: "内容不能为空", trigger: "blur" },
        { validator: intNum, trigger: "blur" },
      ],
      rulesOfLinkedIn: [
        { validator: CheckRepeatLinkedin, trigger: "blur" },
        { required: true, message: "内容不能为空", trigger: "blur" },
        { validator: validateLinkedin, trigger: "blur" },
      ],
      rulesOfSalary: {
        lowAimSalary: [
          { validator: intNum, trigger: "blur" },
          { required: true, message: "内容不能为空", trigger: "blur" },
        ],
        highAimSalary: [
          { validator: intNum, trigger: "blur" },
          { required: true, message: "内容不能为空", trigger: "blur" },
        ],
      },
      useId: parseInt(this.getStore("uid")),
    };
  },
  props: [
    "row",
    "index",
    "type",
    "popVisible",
    "isEditDetail",
    "resumeInfoList",
    "vocationList",
    "locationList",
    "ColumnCityList",
    "ColumnPosList",
    "ColumnInList",
    "isPoppoverShow",
    "vocationName",
    "functionName",
  ],
  created() {
    if (this.isEditDetail) {
    } else {
      switch (this.type) {
        case "name":
          this.editPlaceHolder = "请输入要修改的姓名";
          this.showEditIcon = this.row.showEditOfName;
          this.editcontent = this.row.name;
          break;
        case "sex":
          this.editPlaceHolder = "请选择";
          this.showEditIcon = this.row.showEditOfSex;
          this.editcontent = this.row.sex;
          break;
        case "company":
          this.editPlaceHolder = "请输入要修改的公司名";
          this.showEditIcon = this.row.showEditOfCompany;
          this.editcontent = this.row.lastCompany;
          break;
        case "title":
          this.editPlaceHolder = "请输入要修改的职位";
          this.showEditIcon = this.row.showEditOfTitle;
          this.editcontent = this.row.lastTitle;
          break;
        case "education":
          this.editPlaceHolder = "请选择学历";
          this.showEditIcon = this.row.showEditOfEducation;
          this.editcontent = this.row.education;
          break;
        case "school":
          this.editPlaceHolder = "请输入要修改的学校";
          this.showEditIcon = this.row.showEditOfSchool;
          this.editcontent = this.row.school;
          break;
      }
      this.formData.tempEditContent = this.editcontent;
      this.tempPopVisible = this.popVisible;
    }
  },
  watch: {
    row(newRow) {
      switch (this.type) {
        case "name":
          this.editPlaceHolder = "请输入要修改的姓名";
          this.showEditIcon = newRow.showEditOfName;
          this.editcontent = newRow.name;
          break;
        case "sex":
          this.editPlaceHolder = "请选择";
          this.showEditIcon = this.row.showEditOfSex;
          this.editcontent = this.row.sex;
          break;
        case "company":
          this.editPlaceHolder = "请输入要修改的公司名";
          this.showEditIcon = newRow.showEditOfCompany;
          this.editcontent = newRow.lastCompany;
          break;
        case "title":
          this.editPlaceHolder = "请输入要修改的职位";
          this.showEditIcon = newRow.showEditOfTitle;
          this.editcontent = newRow.lastTitle;
          break;
        case "education":
          this.editPlaceHolder = "请选择学历";
          this.showEditIcon = newRow.showEditOfEducation;
          this.editcontent = newRow.education;
          break;
        case "school":
          this.editPlaceHolder = "请输入要修改的学校";
          this.showEditIcon = newRow.showEditOfSchool;
          this.editcontent = newRow.school;
          break;
      }
      this.formData.tempEditContent = this.editcontent;
    },
    // "formData.tempEditContent"(newValue, oldValue) {
    //   if (this.type == "functionName") {
    //     if (newValue.length > 5) {
    //       this.$message({
    //         message: "最多不超过5个",
    //         duration: 1500,
    //         type: "error",
    //       });
    //       this.$nextTick(() => {
    //         this.formData.tempEditContent = oldValue;
    //       });
    //     }
    //   }
    // },
    "row.showEditOfName"(newVal) {
      if (this.type == "name") {
        this.showEditIcon = newVal;
      }
    },
    "row.showEditOfSex"(newVal) {
      if (this.type == "sex") {
        this.showEditIcon = newVal;
      }
    },
    "row.showEditOfCompany"(newVal) {
      if (this.type == "company") {
        this.showEditIcon = newVal;
      }
    },
    "row.showEditOfTitle"(newVal) {
      if (this.type == "title") {
        this.showEditIcon = newVal;
      }
    },
    "row.showEditOfEducation"(newVal) {
      if (this.type == "education") {
        this.showEditIcon = newVal;
      }
    },
    "row.showEditOfSchool"(newVal) {
      if (this.type == "school") {
        this.showEditIcon = newVal;
      }
    },
    popVisible(newVisible) {
      this.tempPopVisible = false;
    },
    isPoppoverShow(newVisible) {
      this.tempPopoverVisible = false;
    },
  },
  methods: {
    functionOrVocation(val) {},
    checkRepeat() {
      this.$drawer({
        options: {
          title: "人才",
          icon: "icon-rc_bt",
          modal: false,
          direction: "ttb",
          customClass: "drawerClass",
        },
        template: resumeDetail,
        props: {
          hover: { matchAll: true, majorSearch: false, hover: true },
          resumeDetailId: this.repeatResumeId,
          repeatResume: { type: "列表", valId: "" },
        },
        emit: {
          handleNewResume: [this.handleNewResume, "close"],
          handleSuccess: this.handleSuccess,
        },
      });
    },
    showEditPopover() {
      this.flag = false;
      this.$emit("getFlag", this.flag);

      this.formData.tempEditContent = this.editcontent;
      this.$emit("gettempPopVisible", this.tempPopVisible);
    },
    hideEditPopover() {
      this.flag = true;
      this.$emit("getFlag", this.flag);
      this.$emit("gettempPopVisible", this.tempPopVisible);
    },
    cancelPop() {
      this.tempPopVisible = false;
      this.$emit("closePop");
    },
    optionVisibleChange(val) {
      if (val) {
        this.$emit("gettempPopVisible", false);
      } else {
        this.$emit("gettempPopVisible", true);
      }
    },
    saveEditItem() {
      this.$refs["formData"].validate((valid) => {
        if (valid) {
          getResumeById({
            resumeId: this.row.id,
            fromPage: "单项编辑",
          }).then((res) => {
            if (res.success) {
              this.resumeList = res.result;
              try {
                switch (this.type) {
                  case "name":
                    this.resumeList.resumeInfo.name =
                      this.formData.tempEditContent;
                    break;
                  case "sex":
                    this.resumeList.resumeInfo.sex =
                      this.formData.tempEditContent;
                    break;
                  case "company":
                    this.resumeList.resumeInfo.lastCompany =
                      this.formData.tempEditContent;
                    break;
                  case "title":
                    this.resumeList.resumeInfo.lastTitle =
                      this.formData.tempEditContent;
                    break;
                  case "education":
                    this.resumeList.resumeInfo.education =
                      this.formData.tempEditContent;
                    break;
                  case "school":
                    this.resumeList.resumeInfo.school =
                      this.formData.tempEditContent;
                    break;
                }
              } catch (e) {}
              this.transitionForm(this.resumeList);
              this.saveChanged();
            }
          });
          this.cancelPop();
        } else {
          return false;
        }
      });
      this.popVisible = false;
    },
    // preText (pretext) {
    //   return pretext.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,'&nbsp;')
    // },
    saveChanged() {
      addOrEditResume({
        educationsList: this.resumeList.educationsList,
        employmentsList: this.resumeList.employmentsList,
        forwardLocationIds: this.resumeList.forwardLocationList,
        forwardVocationIds: this.resumeList.forwardVocationList,
        projectsList: this.resumeList.projectsList,
        resumeVocationIds: this.resumeList.vocationIds,
        resumeFunctionIds: this.resumeList.functionIds,
        resumeInfo: this.resumeList.resumeInfo,
        doNotCheckRepeated: false,
      }).then((res) => {
        if (res.success) {
          this.newResId = res.result.id;
          if (
            res.result.repeatedResumeInfos == null ||
            res.result.repeatedResumeInfos.length == 0 ||
            res.result.repeatedResumeInfos.every((item) => item == null)
          ) {
            this.$message({
              message: "修改成功",
              type: "success",
            });
            this.$emit("saveChanged");
          } else {
            this.$confirm("编辑人才和系统内人才疑似重复", "提示", {
              confirmButtonText: "点击查看",
              cancelButtonText: "取消",
              customClass: "altCls",
              showClose: false,
              closeOnClickModal: false,
              closeOnPressEscape: false,
            })
              .then(() => {
                this.$router.push({
                  path: "/resume/pending",
                  query: {
                    id: this.newResId,
                  },
                });
              })
              .catch(() => {
                this.$emit("saveChanged");
              });
          }
        }
      });
    },
    cancelPopover() {
      this.tempPopoverVisible = false;
    },
    saveEditItemOfDetail() {
      return new Promise((resolve) => {
        this.$refs["formData"].validate((valid) => {
          if (valid) {
            if (
              (this.type == "qq" && this.repeatQq) ||
              (this.type == "linked_in" && this.repeatLinkedin)
            ) {
              return;
            }
            getResumeById({
              resumeId: this.resumeInfoList.id,
              fromPage: "单项编辑",
            }).then((res) => {
              if (res.success) {
                this.resumeList = res.result;
                this.resumeList.functionIds = this.resumeList.functionList.map(
                  (i) => JSON.parse(i.functionIds)
                );
                this.resumeList.vocationIds = this.resumeList.vocationList.map(
                  (i) => JSON.parse(i.vocationIds)
                );
                switch (this.type) {
                  case "firstDegree":
                    this.resumeList.resumeInfo.firstDegree =
                      this.formData.tempEditContent;
                    break;
                  case "birth":
                    this.resumeList.resumeInfo.birth =
                      this.formData.tempEditContent;
                    break;
                  case "beginWorkTime":
                    this.resumeList.resumeInfo.beginWorkTime =
                      this.formData.tempEditContent;
                    break;
                  case "functionName":
                    this.resumeList.functionIds = this.formData.tempEditContent;
                    break;
                  case "vocationName":
                    if (this.formData.tempEditContent.length > 5) {
                      this.$message.error("行业不能超过5个");
                      return;
                    }
                    this.resumeList.vocationIds = this.formData.tempEditContent;
                    this.$forceUpdate();
                    break;
                  case "current_salary":
                    this.resumeList.resumeInfo.current_salary =
                      this.formData.tempEditContent;
                    break;
                  case "qq":
                    this.resumeList.resumeInfo.qq =
                      this.formData.tempEditContent;
                    break;
                  case "linked_in":
                    this.resumeList.resumeInfo.linked_in =
                      this.formData.tempEditContent;
                    break;
                  case "jobStatus":
                    this.resumeList.resumeInfo.jobStatus =
                      this.formData.tempEditContent;
                    break;
                  case "VocationList":
                    this.resumeList.forwardVocationList =
                      this.formData.tempEditContent;
                    break;
                  case "locationList":
                    this.resumeList.forwardLocationList =
                      this.formData.tempEditContent;
                    break;
                  case "title":
                    this.resumeList.resumeInfo.title =
                      this.formData.tempEditContent;
                    break;
                  case "english":
                    this.resumeList.resumeInfo.english =
                      this.formData.tempEditContent;
                    break;
                  case "others":
                    this.resumeList.resumeInfo.others =
                      this.formData.tempEditContent;
                    break;

                  case "personal":
                    this.resumeList.resumeInfo.personal =
                      this.formData.tempEditContent;
                    break;
                  case "certificate":
                    this.resumeList.resumeInfo.certificate =
                      this.formData.tempEditContent;
                    break;
                  case "training":
                    this.resumeList.resumeInfo.training =
                      this.formData.tempEditContent;
                    //  this.preText(this.formData.tempEditContent);
                    break;

                  case "salary":
                    this.resumeList.resumeInfo.lowAimSalary =
                      this.formData.lowAimSalary;
                    this.resumeList.resumeInfo.highAimSalary =
                      this.formData.highAimSalary;
                    this.resumeList.resumeInfo.isMianYi =
                      this.formData.isMianYi;
                    break;
                }
                this.transitionForm(this.resumeList);
                this.saveChanged();
              }
              resolve();
            });
            this.cancelPopover();
          }
        });
      });
    },
    Haschecked(e) {
      //  是否面议
      this.desabled = e;
      this.formData.lowAimSalary = "";
      this.formData.highAimSalary = "";
      this.$refs["formData"].clearValidate();
    },
    showPop() {
      const div = document.createElement("div");
      const divFunction = document.createElement("div");
      this.$emit("editIcon", this.type);
      this.$refs["formData"].clearValidate();
      this.topOrBottom = true;
      this.editPlaceHolder = "";
      this.editcontent = "";
      this.options = [];
      this.props = {};
      this.rules = [
        { required: true, message: "内容不能为空", trigger: "blur" },
      ];
      this.desabled = true;
      this.formData.tempEditContent = "";
      this.refName = "";
      switch (this.type) {
        case "firstDegree":
          this.refName = "firstDegree";
          // this.topOrBottom = false;
          this.topOrBottom = true;
          this.editPlaceHolder = "请选择学历";
          this.editcontent = this.resumeInfoList.firstDegree;
          break;
        case "birth":
          this.refName = "birth";
          this.topOrBottom = true;
          this.editPlaceHolder = "请选择日期";
          this.editcontent = this.resumeInfoList.birth;
          break;
        case "beginWorkTime":
          this.refName = "beginWorkTime";
          // this.topOrBottom = false;
          this.topOrBottom = true;
          this.editPlaceHolder = "请选择日期";
          this.editcontent = this.resumeInfoList.beginWorkTime;
          break;
        case "functionName":
          this.refName = "functionName";
          // this.topOrBottom = false;
          this.topOrBottom = true;
          this.editPlaceHolder = "请选择职能";
          this.options = this.ColumnPosList;
          this.props = this.props2;
          // this.props.disabled = "hasChild";
          this.editcontent = this.functionName;
          break;
        case "vocationName":
          this.refName = "vocationName";
          // this.topOrBottom = false;
          this.topOrBottom = true;
          this.editPlaceHolder = "请选择行业";
          this.options = this.ColumnInList;
          this.props = this.props2;
          this.props.checkStrictly = true;
          this.props.disabled = "hasChild";
          this.editcontent = this.vocationName;
          break;
        case "current_salary":
          this.editPlaceHolder = "请输入薪酬";
          this.editcontent = this.resumeInfoList.current_salary;
          break;
        case "qq":
          this.rules = this.rulesOfQQ;
          this.editPlaceHolder = "请输入QQ";
          this.editcontent = this.resumeInfoList.qq;
          break;
        case "linked_in":
          this.rules = this.rulesOfLinkedIn;
          this.editPlaceHolder = "请输入领英地址";
          this.editcontent = this.resumeInfoList.linked_in1;
          break;
        case "jobStatus":
          this.refName = "jobStatus";
          // this.topOrBottom = false;
          this.topOrBottom = true;
          this.editPlaceHolder = "请选择求职状态";
          this.editcontent = this.resumeInfoList.jobStatus;
          break;
        case "VocationList":
          this.refName = "VocationList";
          // this.topOrBottom = false;
          this.topOrBottom = true;
          this.editPlaceHolder = "请选择期望行业";
          this.options = this.ColumnInList;
          this.props = this.props2;
          this.props.checkStrictly = true;
          this.props.disabled = "hasChild";
          this.editcontent = this.vocationList;
          break;
        case "locationList":
          this.refName = "locationList";
          // this.topOrBottom = false;
          this.topOrBottom = true;
          this.editPlaceHolder = "请选择期望城市";
          this.options = this.ColumnCityList;
          this.props = this.props3;
          this.editcontent = this.locationList;
          break;
        case "title":
          this.editPlaceHolder = "请输入期望职位";
          divFunction.innerHTML = this.resumeInfoList.title;
          this.editcontent = divFunction.innerText;
          break;
        case "english":
          this.editPlaceHolder = "请填写语言能力";
          this.editcontent = this.resumeInfoList.english;
          break;
        case "personal":
          this.editPlaceHolder = "请填写自我评价内容";
          div.innerHTML = this.resumeInfoList.personal;
          const innercontent = div.innerText;
          this.editcontent = innercontent;
          break;
        case "certificate":
          this.editPlaceHolder = "请填写证书";
          div.innerHTML = this.resumeInfoList.certificate;
          const innerText = div.innerText;
          this.editcontent = innerText;
          break;
        case "training":
          this.editPlaceHolder = "请填写培训经历";
          div.innerHTML = this.resumeInfoList.training;
          const innerText1 = div.innerText;
          this.editcontent = innerText1;
          break;
        case "others":
          this.editPlaceHolder = "请填写其他";
          this.editcontent = this.resumeInfoList.others;
          break;
        case "salary":
          this.editPlaceHolder = "请输入";
          this.desabled = this.resumeInfoList.isMianYi;
          this.editcontent = {
            lowAimSalary: this.resumeInfoList.lowAimSalary,
            highAimSalary: this.resumeInfoList.highAimSalary,
            isMianYi: this.resumeInfoList.isMianYi,
          };
          break;
      }
      if (this.type == "salary") {
        this.formData = this.editcontent;
      } else {
        this.formData.tempEditContent = this.editcontent;
      }
    },
    hidePop() {
      this.$emit("hideEditIcon", this.type);
    },
    transitionForm(list) {
      //  转换接口请求数据的格式
      for (var key in list.resumeInfo) {
        try {
          if (JSON.parse(list.resumeInfo[key]) instanceof Array) {
            list.resumeInfo[key] = JSON.parse(list.resumeInfo[key]);
          }
        } catch (e) {}
      }
      if (!list.resumeInfo.functionIds) {
        list.resumeInfo.functionIds = [];
      }
      if (!list.resumeInfo.nowLocationIds) {
        list.resumeInfo.nowLocationIds = [];
      }
      if (!list.resumeInfo.vocationIds) {
        list.resumeInfo.vocationIds = [];
      }
      list.employmentsList.map((item) => {
        if (!item.vocationIds) {
          item.vocationIds = [];
        }
        if (!item.functionIds) {
          item.functionIds = [];
        }
        return item;
      });
      if (this.type != "VocationList") {
        if (list.forwardVocationList && list.forwardVocationList[0]) {
          list.forwardVocationList = list.forwardVocationList.map((item) => {
            if (
              item.forwardVocationIds == null ||
              item.forwardVocationIds == ""
            ) {
              item = [];
            } else if (item.forwardVocationIds) {
              item = JSON.parse(item.forwardVocationIds);
            }
            return item;
          });
        }
      }
      if (this.type != "locationList") {
        if (list.forwardLocationList && list.forwardLocationList[0]) {
          list.forwardLocationList = list.forwardLocationList.map((item) => {
            if (
              item.forwardLocationIds == null ||
              item.forwardLocationIds == ""
            ) {
              item = [];
            } else if (item.forwardLocationIds) {
              item = JSON.parse(item.forwardLocationIds);
            }
            return item;
          });
        }
      }

      // }
    },
    changeVisible(val) {
      if (val) {
        this.$nextTick(() => {
          // 级联选择器
          if (
            this.type == "functionName" ||
            this.type == "vocationName" ||
            this.type == "VocationList" ||
            this.type == "locationList"
          ) {
            if (
              this.$refs[`${this.type}`].$refs.popper.attributes[2].value ==
              "bottom-start"
            ) {
              // this.topOrBottom = false;
              this.topOrBottom = true;
            } else {
              if (!this.topOrBottom) {
                this.$nextTick(() => {
                  this.$refs[`${this.type}`].$refs.popper.style.top =
                    this.$refs[`${this.type}`].$refs.popper.attributes[1]
                      .ownerElement.offsetTop -
                    60 +
                    "px";
                });
              }
              this.topOrBottom = true;
            }
          }
          // 下拉选择器
          if (this.type == "firstDegree" || this.type == "jobStatus") {
            if (
              this.$refs[`${this.type}`].$refs.popper.popperElm.attributes[2]
                .value == "bottom-start"
            ) {
              // this.topOrBottom = false;
              this.topOrBottom = true;
            } else {
              if (!this.topOrBottom) {
                this.$nextTick(() => {
                  this.$refs[`${this.type}`].$refs.popper.popperElm.style.top =
                    this.$refs[`${this.type}`].$refs.popper.popperElm
                      .attributes[1].ownerElement.offsetTop -
                    60 +
                    "px";
                });
              }
              this.topOrBottom = true;
            }
          }
          // 日期选择器
          if (this.type == "birth" || this.type == "beginWorkTime") {
            this.$nextTick(() => {
              if (
                this.$refs[`${this.type}`].popperElm.attributes[2].value ==
                "bottom-start"
              ) {
                // this.topOrBottom = false;
                this.topOrBottom = true;
              } else {
                if (!this.topOrBottom) {
                  this.$nextTick(() => {
                    this.$refs[`${this.type}`].popperElm.style.top =
                      this.$refs[`${this.type}`].popperElm.offsetTop -
                      60 +
                      "px";
                  });
                }
                this.topOrBottom = true;
              }
            });
          }
        });
      } else {
        //  this.topOrBottom = true;
      }
    },
  },
  components: {},
};
</script>

<style lang="scss" scoped>
.edit-icon-style {
  cursor: pointer;
}
.edit-icon-style:hover {
  color: #ff3f3f;
}
.repeatCla,
.el-form-item__error {
  font-size: 12px;
  color: #ff3f3f;
  height: 18px;
  line-height: 18px;
  padding: 0;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  transition: width 0.5s;
  -webkit-transition: width 0.5s;
}
.repeatCla:hover,
.el-form-item__error:hover {
  width: 245px;
}
</style>
<style lang="scss">
.cascaderOfEditResumeList {
  min-width: 200px;
  .el-cascader__search-input {
    min-width: 0 !important;
  }
}
</style>
