<template>
  <section>
    <div class="serachInp">
      <el-input
        placeholder="搜索标签"
        class="input-with-select"
        v-model="searchTag"
      >
        <el-button @click="search" type="primary" slot="append">查询</el-button>
      </el-input>
      <div class="searchHoverContent" v-if="searchHoverContentIsShow">
        <div
          class="row"
          v-for="(i, index) in serchChoiceList"
          :key="index"
          @click="chioceData(i)"
        >
          <p v-html="i.name">{{ i.name }}</p>
          <p>{{ i.type }}</p>
        </div>
        <div
          class="nodata"
          v-if="serchChoiceList.length == 0"
          @click="(searchHoverContentIsShow = false), (searchTag = '')"
        >
          暂无数据……
        </div>
      </div>
    </div>
    <div
      class="tagsContent"
      @click="(searchHoverContentIsShow = false), (searchTag = '')"
    >
      <div class="tagsContent_L">
        <p
          :class="i.isActive ? 'tagsContent_L_active' : ''"
          v-for="(i, index) in addTypeList"
          :key="index"
          @click="chioceType(i)"
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <span>{{ i.name }}</span>
          <span v-if="i.hitsNums > 0" class="tagNums">{{ i.hitsNums }}</span>
        </p>
      </div>
      <div class="tagsContent_R">
        <el-checkbox-group
          class="tagLis"
          size="mini"
          v-model="createTag.labelIds"
        >
          <el-checkbox
            v-for="item in newTagList"
            :label="item.id"
            :key="item.id"
            border
            :style="
              item.bordered
                ? { 'border-color': item.color, color: item.color }
                : {}
            "
            >{{ item.name }}</el-checkbox
          >
        </el-checkbox-group>
        <el-empty
          v-if="newTagList == null"
          style="text-align: center"
          description="暂无标签"
        ></el-empty>
      </div>
    </div>
  </section>
</template>

<script>
import {
  getLabelOptions, //获取标签列表
  getLabelCategoryOptions, //获取所有标签类别
  labelFavouriteSet, //保存标签
} from "../../api/api";
export default {
  props: ["usedTags"],
  data() {
    return {
      searchTag: "",
      searchHoverContentIsShow: false,
      serchChoiceList: [],
      addTypeList: [],
      createTag: {
        resumeIds: "",
        labelIds: [],
      },
      newTagList: [],
      choiceTypeName: "",
      newTagSerchList: [],
      chioceIndex: 0,
      isFrist: false,
    };
  },
  watch: {
    "createTag.labelIds"(nVal, oVal) {
      console.log(nVal, oVal);
      if (!this.isFrist) {
        let list = null;
        list = oVal.concat(nVal).filter(function (v, i, arr) {
          return arr.indexOf(v) === arr.lastIndexOf(v);
        });
        this.addTypeList.forEach((item) => {
          if (item.name == this.choiceTypeName) {
            item.hitsNums = item.hitsNums + (nVal.length - oVal.length);
          } else {
            item.labels.forEach((i) => {
              if (i.id == list[0]) {
                item.hitsNums = item.hitsNums + (nVal.length - oVal.length);
              }
            });
          }
        });
      } else {
        this.isFrist = false;
      }
    },
  },
  created() {
    this.getLabelCategoryOptions();
    this.loadTagList("人才");
  },
  methods: {
    //获取标签选项
    getLabelCategoryOptions() {
      this.isFrist = true;
      getLabelCategoryOptions().then((res) => {
        if (res.success) {
          res.result.forEach((item) => {
            item.isActive = false;
            item.hitsNums = 0;
            item.labels.forEach((l) => {
              if (this.usedTags.length > 0) {
                this.usedTags.forEach((i) => {
                  if (i.id == l.id) {
                    item.hitsNums++;
                  }
                });
              }
            });
          });
          this.addTypeList = res.result;
          if (this.addTypeList.length > 0) {
            this.addTypeList[0].isActive = true;
            this.choiceTypeName = this.addTypeList[0].name;
            this.newTagList = this.addTypeList[0].labels;
            this.newTagSerchList = this.addTypeList[0].labels;
          }
          this.createTag.labelIds = [];
          if (this.usedTags.length > 0) {
            this.usedTags.forEach((item) => {
              this.createTag.labelIds.push(item.id);
            });
          }
        }
      });
    },
    //选中标签类别
    chioceType(data) {
      this.choiceTypeName = data.name;
      this.addTypeList.forEach((item, index) => {
        if (item.name == data.name) {
          this.chioceIndex = index;
          item.isActive = true;
          this.newTagList = data.labels;
          this.newTagSerchList = data.labels;
        } else {
          item.isActive = false;
        }
      });
    },
    loadTagList(type) {
      //标签列表
      getLabelOptions().then((res) => {
        if (res.success) {
          this.TagList = res.result;
        }
      });
    },
    search() {
      let text = this.searchTag;
      let data = [];
      if (text) {
        this.addTypeList.forEach((item, index) => {
          item.labels.forEach((i, index1) => {
            if (i.name.toUpperCase().match(text.toUpperCase())) {
              let val = {
                name: "",
                type: "",
                id: "",
                category: "技能标签",
                typeName: "",
              };
              val.id = i.id;
              val.name = i.name.replaceAll(
                text,
                '<font color="#ff3f3f">' + text + "</font>"
              );
              val.typeName = item.name;
              val.type = "技能标签 > " + item.name + " > " + i.name;
              data.push(val);
            }
          });
        });
      }
      this.serchChoiceList = data;
      this.searchHoverContentIsShow = true;
      // this.newTagList = this.newTagSerchList.filter(
      //   (item) => item.name.indexOf(this.searchTag) > -1
      // );
    },
    //选中
    chioceData(i) {
      this.choiceTypeName = i.typeName;
      let list = [];
      list = this.createTag.labelIds;
      let item = [i.id];
      if (!this.createTag.labelIds.includes(i.id)) {
        if (list.length === 0) {
          this.createTag.labelIds = [...item];
        } else {
          this.createTag.labelIds = [...list, ...item];
        }
      } else {
        this.$message.error("标签已选");
      }
      this.searchTag = "";
      this.searchHoverContentIsShow = false;
    },
    saveTags() {
      // if (this.createTag.labelIds.length == 0) {
      //   this.$message.error("请选择标签后再保存");
      //   return false;
      // }
      // this.saveloading = true;
      // this.createTag.batchOperate = true;
      // this.createTag.entry = "人才库-人才列表";
      labelFavouriteSet({
        labelIds: this.createTag.labelIds,
      }).then((res) => {
        if (res.success) {
          this.$message.success("操作成功");
          this.$parent.handleClose();
          this.$emit("loadList");
          // this.dialogVisibleOfTag = false;
          // this.dialogVisible = false;
          // this.dialogVisibleRemark = false;
          // if (this.$route.query.id) {
          //   this.listForChild(this.$route.query.id);
          // } else {
          //   this.loadList();
          // }
          // this.getLabelCategoryOptions();
        }
        // this.saveloading = false;
      });
    },
  },
};
</script>
