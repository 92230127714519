<template>
  <section class="resmeInfo">
    <div class="drawerCon_from">
      <el-form
        @submit.native.prevent
        @submit.enter.prevent
        :model="resumeInfo"
        ref="resumeInfo"
        :validate-on-rule-change="false"
        :rules="rulesList"
        label-width="125px"
        v-loading="loading"
      >
        <p class="drawerCon_from_tit">基本信息</p>
        <el-collapse-transition>
          <div v-show="showMoreComVal" class="content">
            <el-row :gutter="24">
              <el-col :span="12">
                <el-form-item label="人才类型：" class="is-required">
                  <el-radio-group
                    v-model="resumeType"
                    @change="changeResumeType($event)"
                  >
                    <el-radio label="候选人">候选人</el-radio>
                    <el-radio label="coldcall">cold call</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="拥有者：" prop="ownerUserId">
                  <el-select
                    class="searchSelect"
                    :disabled="forbidden"
                    v-model="resumeInfo.ownerUserId"
                    filterable
                    placeholder="请选择"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="i in userList"
                      :key="i.id"
                      :label="i.name"
                      :value="i.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="姓名：" prop="name">
                  <el-input
                    placeholder="请输入姓名"
                    maxlength="50"
                    v-model.trim="resumeInfo.name"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="性别：" prop="sex">
                  <el-radio-group v-model="resumeInfo.sex">
                    <el-radio label="男">男</el-radio>
                    <el-radio label="女">女</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="24">
              <el-form-item label="" label-width="20px" class="contact-people">
                <p style="margin-bottom: 10px">
                  联系方式<span style="color: #e68a01">（至少填写一项）</span>
                </p>
                <el-col :span="12" style="width: calc(50% - 50px)">
                  <el-form-item
                    label="邮箱："
                    prop="email"
                    style="margin-bottom: 15px"
                    label-width="60px"
                  >
                    <el-input
                      placeholder="请输入邮箱"
                      :type="
                        resumeInfo.offerOrEntryProjectResumeId &&
                        !resumeInfo.offerOrEntryPermission
                          ? 'password'
                          : ''
                      "
                      v-model.trim="resumeInfo.email"
                      @change="emailChanged($event)"
                    ></el-input>
                    <span class="repeatCla" v-if="repeatEmail">
                      <el-button type="text" size="mini" @click="checkRepeat"
                        >点击查看重复简历</el-button
                      >或<el-button type="text" size="mini" @click="goPending"
                        >去合并</el-button
                      >
                    </span>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item
                    label="手机号码："
                    prop="mobile"
                    style="margin-bottom: 15px"
                    label-width="160px"
                  >
                    <el-input
                      :type="
                        resumeInfo.offerOrEntryProjectResumeId &&
                        !resumeInfo.offerOrEntryPermission
                          ? 'password'
                          : ''
                      "
                      placeholder="请输入手机号码"
                      v-model.trim="resumeInfo.mobile"
                      @change="telChanged($event)"
                      @keyup.native="inputChange('account')"
                    ></el-input>
                    <span class="repeatCla" v-if="repeatTel">
                      <el-button type="text" size="mini" @click="checkRepeat"
                        >点击查看重复简历</el-button
                      >或<el-button type="text" size="mini" @click="goPending"
                        >去合并</el-button
                      >
                    </span>
                  </el-form-item>
                </el-col>
                <el-col :span="12" style="width: calc(50% - 50px)">
                  <el-form-item
                    label="微信："
                    prop="wechatOrOther"
                    style="margin-bottom: 15px"
                    label-width="60px"
                  >
                    <el-input
                      :type="
                        resumeInfo.offerOrEntryProjectResumeId &&
                        !resumeInfo.offerOrEntryPermission
                          ? 'password'
                          : ''
                      "
                      placeholder="请输入微信号"
                      @change="wx($event)"
                      v-model.trim="resumeInfo.wechatOrOther"
                    ></el-input>
                    <span class="repeatCla" v-if="repeatWechat">
                      <el-button type="text" size="mini" @click="checkRepeat"
                        >点击查看重复简历</el-button
                      >或<el-button type="text" size="mini" @click="goPending"
                        >去合并</el-button
                      >
                    </span>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item
                    label="QQ："
                    prop="qq"
                    style="margin-bottom: 15px"
                    label-width="160px"
                  >
                    <el-input
                      :type="
                        resumeInfo.offerOrEntryProjectResumeId &&
                        !resumeInfo.offerOrEntryPermission
                          ? 'password'
                          : ''
                      "
                      placeholder="请输入QQ号"
                      :maxlength="11"
                      @change="qqChanged($event)"
                      v-model.trim="resumeInfo.qq"
                    ></el-input>
                    <span class="repeatCla" v-if="repeatQq">
                      <el-button type="text" size="mini" @click="checkRepeat"
                        >点击查看重复简历</el-button
                      >或<el-button type="text" size="mini" @click="goPending"
                        >去合并</el-button
                      >
                    </span>
                  </el-form-item>
                </el-col>
                <el-col :span="12" style="width: calc(50% - 50px)">
                  <el-form-item
                    label="领英："
                    prop="linked_in"
                    style="margin-bottom: 5px"
                    label-width="60px"
                  >
                    <el-input
                      :type="
                        resumeInfo.offerOrEntryProjectResumeId &&
                        !resumeInfo.offerOrEntryPermission
                          ? 'password'
                          : ''
                      "
                      placeholder="请输入领英地址"
                      @change="linkedinChanged($event)"
                      v-model.trim="resumeInfo.linked_in"
                    ></el-input>
                    <span class="repeatCla" v-if="repeatLinkedin">
                      <el-button type="text" size="mini" @click="checkRepeat"
                        >点击查看重复简历</el-button
                      >或<el-button type="text" size="mini" @click="goPending"
                        >去合并</el-button
                      >
                    </span>
                  </el-form-item>
                </el-col>
              </el-form-item>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="职能：" prop="resumeFunctionIds">
                  <el-cascader
                    clearable
                    class="fromInput"
                    collapse-tags
                    filterable
                    :options="ColumnPosList"
                    :show-all-levels="false"
                    v-model="resumeInfo.resumeFunctionIds"
                    :props="props2"
                    style="width: 400px"
                  ></el-cascader>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="行业：" prop="resumeVocationIds">
                  <el-cascader
                    class="fromInput"
                    clearable
                    filterable
                    collapse-tags
                    ref="empVocation"
                    :show-all-levels="false"
                    :options="ColumnInList"
                    v-model="resumeInfo.resumeVocationIds"
                    placeholder="请选择"
                    :props="props3"
                    style="width: 400px"
                  ></el-cascader>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="现居住城市：" prop="nowLocationIds">
                  <el-cascader
                    :show-all-levels="false"
                    :options="ColumnCityList"
                    filterable
                    v-model="resumeInfo.nowLocationIds"
                    placeholder="请选择"
                    :props="props"
                  ></el-cascader>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="薪酬：" prop="current_salary">
                  <el-input
                    placeholder="请输入薪酬"
                    v-model.trim="resumeInfo.current_salary"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="出生年月：" prop="birth">
                  <el-date-picker
                    suffix-icon="el-icon-date"
                    v-model="resumeInfo.birth"
                    value-format="yyyy-MM"
                    type="month"
                    placeholder="请选择"
                    style="width: 400px"
                  ></el-date-picker>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="第一学历：" prop="firstDegree">
                  <el-select
                    v-model="resumeInfo.firstDegree"
                    clearable
                    filterable
                    placeholder="请选择"
                  >
                    <el-option label="初中" value="初中"></el-option>
                    <el-option label="高中" value="高中"></el-option>
                    <el-option label="中技" value="中技"></el-option>
                    <el-option label="中专" value="中专"></el-option>
                    <el-option label="大专" value="大专"></el-option>
                    <el-option label="本科" value="本科"></el-option>
                    <el-option label="硕士" value="硕士"></el-option>
                    <el-option label="博士" value="博士"></el-option>
                    <el-option label="博士后" value="博士后"></el-option>
                    <el-option label="MBA" value="MBA"></el-option>
                    <el-option label="其他" value="其他"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="12" v-if="!resumeId">
                <el-form-item label="加入项目：" prop="firstDegree">
                  <el-select
                    v-model="resumeInfo.projectIds"
                    filterable
                    collapse-tags
                    multiple
                    clearable
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="i in inJoinProject"
                      :key="i.id"
                      :label="i.name"
                      :value="i.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-collapse-transition>

        <p class="drawerCon_from_tit">
          求职意向
          <span
            class="show-more fr"
            @click="showMorePurpose = !showMorePurpose"
          >
            <i
              :class="
                showMorePurpose ? 'el-icon-arrow-right' : 'el-icon-arrow-down'
              "
            ></i>
          </span>
        </p>

        <el-collapse-transition>
          <div v-show="showMorePurpose" class="content">
            <el-row :gutter="24">
              <el-col :span="12">
                <el-form-item label="求职状态：" prop="jobStatus">
                  <el-select
                    v-model="resumeInfo.jobStatus"
                    placeholder="请选择"
                  >
                    <el-option
                      label="在职看机会"
                      value="在职看机会"
                    ></el-option>
                    <el-option
                      label="在职不看机会"
                      value="在职不看机会"
                    ></el-option>
                    <el-option label="离职" value="离职"></el-option>
                    <el-option
                      label="应届毕业生"
                      value="应届毕业生"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="期望行业：" prop="forwardVocationList">
                  <el-cascader
                    size="mini"
                    filterable
                    :show-all-levels="false"
                    :options="ColumnInList"
                    v-model="forwardVocationList"
                    placeholder="请选择"
                    :props="props3"
                    collapse-tags
                    style="width: 400px"
                  ></el-cascader>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="期望城市：" prop="forwardLocationList">
                  <el-cascader
                    filterable
                    size="mini"
                    :show-all-levels="false"
                    :options="ColumnCityList"
                    v-model="forwardLocationList"
                    placeholder="请选择"
                    :props="props1"
                    collapse-tags
                    style="width: 400px"
                  ></el-cascader>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="期望职位：" prop="title">
                  <el-input
                    placeholder="请输入职位"
                    v-model.trim="resumeInfo.title"
                    maxlength="20"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="24">
              <el-col>
                <el-form-item label="期望月薪：" prop>
                  <div style="display: flex">
                    <el-col style="padding: 0; width: 200px">
                      <el-form-item label-width="0" prop="lowAimSalary">
                        <el-input
                          placeholder="请输入"
                          :disabled="desabled"
                          maxlength="11"
                          v-model="resumeInfo.lowAimSalary"
                          style="width: 100%"
                        >
                          <template #suffix> 元/月 </template>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <p style="width：10px;text-align:center;margin:0 5px">-</p>
                    <el-col style="padding: 0; width: 185px">
                      <el-form-item
                        label-width="0"
                        prop="highAimSalary"
                        style="display: flex"
                      >
                        <el-input
                          placeholder="请输入"
                          maxlength="11"
                          :disabled="desabled"
                          v-model="resumeInfo.highAimSalary"
                          style="width: 100%"
                        >
                          <template #suffix> 元/月 </template>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col
                      :span="1"
                      style="margin-left: 10px; padding-left: 0"
                    >
                      <el-checkbox
                        v-model="resumeInfo.isMianYi"
                        @change="Haschecked($event)"
                        >面议</el-checkbox
                      >
                    </el-col>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-collapse-transition>

        <p class="drawerCon_from_tit">
          工作经历
          <span
            v-if="employmentsList.length > 0"
            class="tabHref"
            @click="addNewEx(1)"
            style="font-weight: 400; font-size: 13px"
            >+增加工作经历</span
          >
          <span
            class="show-more fr"
            @click="showMoreJobExp = !showMoreJobExp"
            style="width: calc(100% - 170px)"
          >
            <i
              :class="
                showMoreJobExp ? 'el-icon-arrow-right' : 'el-icon-arrow-down'
              "
            ></i>
          </span>
        </p>

        <el-collapse-transition>
          <div v-show="showMoreJobExp" class="content-list">
            <el-row :gutter="24">
              <el-col :span="24">
                <div class="block" v-if="employmentsList.length > 0">
                  <el-collapse accordion class="collapse">
                    <el-collapse-item
                      v-for="(item, index) in employmentsList"
                      :key="index"
                      style="margin-left: 10px"
                    >
                      <template slot="title">
                        <p class="collapseTit">
                          <span class="workExperince">
                            <span style="font-size: 14px; color: #526ecc">{{
                              item.company
                            }}</span>
                            <span
                              class="fontColor"
                              style="margin-left: 10px; font-size: 14px"
                              >（
                              {{
                                item.startDate ? item.startDate + " 至 " : ""
                              }}
                              {{
                                item.isSoFar
                                  ? "至今"
                                  : item.endDate
                                  ? item.endDate
                                  : ""
                              }}）
                            </span>
                          </span>

                          <span class="contral">
                            <i
                              class="iconfont icon-kh_gsxq_bj"
                              @click="editControl(1, index)"
                            ></i>
                            <i
                              class="el-icon-delete"
                              @click="delControl(1, index)"
                            ></i>
                          </span>
                        </p>
                      </template>
                      <p class="jobTitle">
                        {{ item.title
                        }}<span
                          style="max-width: 23%"
                          v-if="item.jobLocation"
                          :title="item.jobLocation"
                          class="text-overflows"
                          >{{ item.jobLocation }}</span
                        ><span
                          v-if="item.salary && item.jobLocation"
                          style="margin: 0"
                          ><el-divider direction="vertical"></el-divider></span
                        ><span :style="item.jobLocation ? 'margin:0' : ''">{{
                          item.salary ? item.salary + "元/月" : ""
                        }}</span>
                      </p>
                      <el-row :gutter="24" style="font-size: 13px">
                        <el-col class="fontColor contentBox" :span="12"
                          ><div class="leftTit">汇报对象：</div>
                          <span
                            :title="item.leader"
                            class="text-overflows maxW_R"
                            >{{ item.leader ? item.leader : "-" }}</span
                          >
                        </el-col>
                        <el-col class="fontColor contentBox" :span="12"
                          ><span class="leftTit">部门：</span>
                          <span
                            :title="item.department"
                            class="text-overflows maxW_R"
                            >{{ item.department ? item.department : "-" }}</span
                          >
                        </el-col>
                        <el-col class="fontColor contentBox" :span="12">
                          <span class="leftTit">下属人数：</span
                          ><span style="color: #333333">{{
                            item.underlingNumber ? item.underlingNumber : "-"
                          }}</span></el-col
                        >
                        <el-col class="fontColor contentBox" :span="24"
                          ><p class="leftTit" style="white-space: nowrap">
                            离职原因：
                          </p>
                          <span style="color: #333333">{{
                            item.reasonOfLeaving ? item.reasonOfLeaving : "-"
                          }}</span></el-col
                        >
                        <el-col class="fontColor contentBox" :span="24"
                          ><span class="leftTit" style="white-space: nowrap"
                            >工作性质：</span
                          >
                          <span style="color: #333333">{{
                            item.jobNature ? item.jobNature : "-"
                          }}</span>
                        </el-col>
                        <el-col class="fontColor contentBox" :span="24">
                          <p class="leftTit" style="white-space: nowrap">
                            工作描述：
                          </p>
                          <span
                            style="color: #333333"
                            v-html="item.summary"
                            v-if="item.summary"
                          ></span>
                          <span v-else>-</span>
                        </el-col>
                        <el-col class="fontColor contentBox" :span="24">
                          <p style="white-space: nowrap">工作业绩：</p>
                          <span
                            style="color: #333333"
                            v-html="item.achievement"
                            v-if="item.achievement"
                          ></span>
                          <span v-else>-</span>
                        </el-col>
                      </el-row>
                    </el-collapse-item>
                  </el-collapse>
                </div>
                <div
                  class="addNewList"
                  v-if="employmentsList.length == 0"
                  @click="addNewEx(1)"
                >
                  <i class="el-icon-plus"></i>增加工作经历
                </div>
              </el-col>
            </el-row>
          </div>
        </el-collapse-transition>

        <p class="drawerCon_from_tit">
          项目经历
          <span
            v-if="projectsList.length > 0"
            class="tabHref"
            @click="addNewEx(3)"
            style="font-weight: 400; font-size: 13px"
            >+增加项目经历</span
          >
          <span
            class="show-more fr"
            @click="showMoreProExp = !showMoreProExp"
            style="width: calc(100% - 170px)"
          >
            <i
              :class="
                showMoreProExp ? 'el-icon-arrow-right' : 'el-icon-arrow-down'
              "
            ></i>
          </span>
        </p>

        <el-collapse-transition>
          <div v-show="showMoreProExp" class="content-list">
            <el-row :gutter="24">
              <el-col :span="24">
                <div class="block" v-if="projectsList.length > 0">
                  <el-collapse accordion class="collapse">
                    <el-collapse-item
                      v-for="(item, index) in projectsList"
                      :key="index"
                    >
                      <template slot="title">
                        <div class="collapseTit">
                          <p
                            class="fl text-overflows"
                            style="width: calc(100% - 100px); margin: 0"
                          >
                            <span
                              style="
                                margin-right: 5px;
                                font-size: 14px;
                                color: #333;
                              "
                            >
                              {{ item.projectName }}
                            </span>
                            <span>
                              {{
                                item.startDate ? item.startDate + " 至 " : ""
                              }}

                              {{ item.endDate ? item.endDate : "至今" }}</span
                            >
                            <span style="margin-left: 10px">{{
                              item.title
                            }}</span>
                          </p>
                          <p class="fr" style="width: 100px">
                            <span class="contral">
                              <i
                                class="iconfont icon-kh_gsxq_bj"
                                @click="editControl(3, index)"
                              ></i>
                              <i
                                class="el-icon-delete"
                                @click="delControl(3, index)"
                              ></i>
                            </span>
                          </p>
                        </div>
                      </template>
                      <el-form-item
                        label="项目描述："
                        label-width="90px"
                        class="itemStyle"
                        >{{ item.projectDescription }}</el-form-item
                      >
                      <el-form-item
                        label="项目职责："
                        label-width="90px"
                        class="itemStyle"
                        >{{ item.responsibilities }}</el-form-item
                      >
                      <el-form-item
                        label="工作业绩："
                        label-width="90px"
                        class="itemStyle"
                        >{{ item.achievements }}</el-form-item
                      >
                    </el-collapse-item>
                  </el-collapse>
                </div>
                <div
                  v-if="projectsList.length == 0"
                  class="addNewList"
                  @click="addNewEx(3)"
                >
                  <i class="el-icon-plus"></i>增加项目经历
                </div>
              </el-col>
            </el-row>
          </div>
        </el-collapse-transition>

        <p class="drawerCon_from_tit">
          教育经历
          <span
            v-if="educationsList.length > 0"
            class="tabHref"
            @click="addNewEx(2)"
            style="font-weight: 400; font-size: 13px"
            >+增加教育经历</span
          >
          <span
            class="show-more fr"
            @click="showMoreEduExp = !showMoreEduExp"
            style="width: calc(100% - 170px)"
          >
            <i
              :class="
                showMoreEduExp ? 'el-icon-arrow-right' : 'el-icon-arrow-down'
              "
            ></i>
          </span>
        </p>
        <el-collapse-transition>
          <div v-show="showMoreEduExp" class="content-list">
            <el-row :gutter="24">
              <el-col :span="24">
                <div class="block" v-if="educationsList.length > 0">
                  <el-table
                    class="eduList"
                    :data="educationsList"
                    :show-header="status"
                    style="width: 100%"
                  >
                    <el-table-column
                      prop="school"
                      :show-overflow-tooltip="true"
                    ></el-table-column>
                    <el-table-column
                      prop="speciality"
                      :show-overflow-tooltip="true"
                    ></el-table-column>
                    <el-table-column
                      prop="education"
                      :show-overflow-tooltip="true"
                    ></el-table-column>
                    <el-table-column
                      prop="is_tongzhao"
                      :show-overflow-tooltip="true"
                    >
                      <template slot-scope="scope">
                        {{ scope.row.is_tongzhao ? "统招" : "" }}
                      </template>
                    </el-table-column>

                    <el-table-column :show-overflow-tooltip="true">
                      <template slot-scope="scope">
                        {{
                          scope.row.startDate
                            ? scope.row.startDate + " - "
                            : ""
                        }}{{
                          scope.row.endDate != "" && scope.row.endDate
                            ? scope.row.endDate
                            : "至今"
                        }}
                      </template>
                    </el-table-column>
                    <el-table-column :show-overflow-tooltip="true">
                      <template slot-scope="scope">
                        <p class="fr" style="width: 100px">
                          <span class="contral">
                            <i
                              class="iconfont icon-kh_gsxq_bj"
                              @click="editControl(2, scope.$index)"
                            ></i>
                            <i
                              class="el-icon-delete"
                              @click="delControl(2, scope.$index)"
                              style="font-size: 18px"
                            ></i>
                          </span>
                        </p>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
                <div
                  v-if="educationsList.length == 0"
                  class="addNewList"
                  @click="addNewEx(2)"
                >
                  <i class="el-icon-plus"></i>增加教育经历
                </div>
              </el-col>
            </el-row>
          </div>
        </el-collapse-transition>

        <p class="drawerCon_from_tit">
          其他信息
          <span class="show-more fr" @click="showMoreOthers = !showMoreOthers">
            <i
              :class="
                showMoreOthers ? 'el-icon-arrow-right' : 'el-icon-arrow-down'
              "
            ></i>
          </span>
        </p>
        <el-collapse-transition>
          <div
            v-show="showMoreOthers"
            class="content-list"
            style="padding: 0 10px 5px 0"
          >
            <el-row :gutter="24">
              <el-col :span="24">
                <el-collapse-transition>
                  <div class="block">
                    <el-col :span="24">
                      <el-form-item label="语言能力：" prop="english">
                        <el-input
                          placeholder
                          maxlength="500"
                          v-model="resumeInfo.english"
                          show-word-limit
                          :autosize="{ minRows: 2, maxRows: 8 }"
                          type="textarea"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="24">
                      <el-form-item label="自我评价：" prop="personal">
                        <el-input
                          maxlength="500"
                          show-word-limit
                          :autosize="{ minRows: 2, maxRows: 8 }"
                          placeholder
                          v-model="resumeInfo.personal"
                          type="textarea"
                          @click.stop.native="stopAdd"
                          @keyup.enter.native="stopAdd"
                          @keyup.enter.stop="stopAdd"
                          @keydown.enter.stop="stopAdd"
                          @keyenter.enter.stop="stopAdd"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="24">
                      <el-form-item
                        label="证书："
                        maxlength="500"
                        prop="certificate"
                      >
                        <el-input
                          maxlength="500"
                          show-word-limit
                          :autosize="{ minRows: 2, maxRows: 8 }"
                          placeholder
                          v-model="resumeInfo.certificate"
                          type="textarea"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="24">
                      <el-form-item
                        label="培训经历："
                        maxlength="500"
                        prop="training"
                      >
                        <el-input
                          maxlength="500"
                          show-word-limit
                          :autosize="{ minRows: 2, maxRows: 8 }"
                          placeholder
                          v-model="resumeInfo.training"
                          style="white-space: pre-wrap"
                          type="textarea"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="24">
                      <el-form-item
                        label="其他："
                        maxlength="500"
                        prop="others"
                      >
                        <el-input
                          maxlength="500"
                          show-word-limit
                          :autosize="{ minRows: 2, maxRows: 8 }"
                          placeholder
                          v-model="resumeInfo.others"
                          type="textarea"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </div>
                </el-collapse-transition>
              </el-col>
            </el-row>
          </div>
        </el-collapse-transition>
      </el-form>
    </div>
    <div class="succUp" v-show="innerVisible">
      <div class="succUpBox">
        <div v-if="isloading">
          <el-progress
            :stroke-width="20"
            :percentage="percentageNum"
            :text-inside="true"
            :color="customColorMethod"
            style="margin-bottom: 20px"
          ></el-progress>
          <p>{{ resumeId ? "简历编辑中..." : "简历创建中..." }}</p>
          <p class="tips" style="margin-top: 10px">
            请勿刷新此页（刷新会导致{{ resumeId ? "编辑" : "创建" }}失败）
          </p>
        </div>
      </div>
    </div>
    <el-dialog
      :title="titName"
      :visible.sync="dialogVisible"
      :modal-append-to-body="false"
      append-to-body
      :close-on-click-modal="false"
      :width="dialogWidth"
      :before-close="handleClose"
    >
      <el-form
        v-if="isEducations"
        :model="createdEducations"
        :rules="Edrules"
        ref="createdEducations"
        label-width="120px"
      >
        <el-row :gutter="24">
          <el-col :span="21">
            <el-form-item label="学校名称：" prop="school">
              <el-input
                v-model.trim="createdEducations.school"
                placeholder="请输入"
                maxlength="100"
                style="width: 79%"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="21">
            <el-form-item label="专业名称：" prop="speciality">
              <el-input
                v-model.trim="createdEducations.speciality"
                placeholder="请输入"
                maxlength="50"
                style="width: 79%"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="21">
            <el-form-item label="学历：" prop="education">
              <el-select
                clearable
                filterable
                v-model="createdEducations.education"
                placeholder="请选择"
                style="width: 79%"
              >
                <el-option label="初中" value="初中"></el-option>
                <el-option label="高中" value="高中"></el-option>
                <el-option label="中技" value="中技"></el-option>
                <el-option label="中专" value="中专"></el-option>
                <el-option label="大专" value="大专"></el-option>
                <el-option label="本科" value="本科"></el-option>
                <el-option label="硕士" value="硕士"></el-option>
                <el-option label="博士" value="博士"></el-option>
                <el-option label="博士后" value="博士后"></el-option>
                <el-option label="MBA" value="MBA"></el-option>
                <el-option label="其他" value="其他"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="21">
            <el-form-item label="时间：" class="is-required">
              <el-row :gutter="24" style="display: flex; align-items: center">
                <el-col>
                  <el-form-item label-width="0" prop="startDate">
                    <el-date-picker
                      v-model="createdEducations.startDate"
                      type="month"
                      value-format="yyyy-MM"
                      placeholder="开始年月"
                      :picker-options="educationsStartTime"
                      style="width: 130px"
                    ></el-date-picker>
                  </el-form-item>
                </el-col>
                <span>-</span>
                <el-col>
                  <el-form-item label-width="0" prop="endDate">
                    <el-date-picker
                      :disabled="createdEducations.isSoFar"
                      v-model="createdEducations.endDate"
                      type="month"
                      value-format="yyyy-MM"
                      placeholder="结束年月"
                      :picker-options="educationsEndTime"
                      style="width: 130px"
                    ></el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col style="padding: 0">
                  <el-form-item label-width="0">
                    <el-checkbox
                      v-model="createdEducations.isSoFar"
                      @change="checkedTime($event)"
                      >至今</el-checkbox
                    >
                  </el-form-item>
                </el-col>
                <el-col>
                  <el-form-item label-width="0">
                    <el-checkbox v-model="createdEducations.is_tongzhao"
                      >统招</el-checkbox
                    >
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-form
        v-if="isProjects"
        :model="createdProjects"
        :rules="Prorules"
        ref="createdProjects"
        label-width="120px"
      >
        <el-row :gutter="24">
          <el-col :span="21">
            <el-form-item label="项目名称：" prop="projectName">
              <el-input
                v-model.trim="createdProjects.projectName"
                placeholder="请输入"
                maxlength="50"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="21">
            <el-form-item label="项目时间：" class="is-required">
              <el-row :gutter="24" style="display: flex">
                <el-col>
                  <el-form-item label-width="0" prop="startDate">
                    <el-date-picker
                      v-model="createdProjects.startDate"
                      type="month"
                      value-format="yyyy-MM"
                      style="width: 168px"
                      placeholder="开始年月"
                      :picker-options="projectsStartTime"
                    ></el-date-picker>
                  </el-form-item>
                </el-col>
                -
                <el-col>
                  <el-form-item label-width="0" prop="endDate">
                    <el-date-picker
                      :disabled="createdProjects.isSoFar"
                      v-model="createdProjects.endDate"
                      type="month"
                      value-format="yyyy-MM"
                      style="width: 168px"
                      placeholder="结束年月"
                      :picker-options="projectsEndTime"
                    ></el-date-picker>
                  </el-form-item>
                </el-col>
                <el-form-item label-width="0">
                  <el-checkbox
                    v-model="createdProjects.isSoFar"
                    @change="checkedTime($event)"
                    >至今</el-checkbox
                  >
                </el-form-item>
              </el-row>
            </el-form-item>
          </el-col>
          <el-col :span="21">
            <el-form-item label="项目职位：" prop="title">
              <el-input
                v-model.trim="createdProjects.title"
                placeholder="请输入"
                maxlength="50"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="21">
            <el-form-item label="公司名称：" prop="company">
              <el-input
                v-model.trim="createdProjects.company"
                placeholder="请输入"
                maxlength="100"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="21">
            <el-form-item label="项目描述：" prop="projectDescription">
              <el-input
                v-model="createdProjects.projectDescription"
                type="textarea"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="21">
            <el-form-item label="项目职责：" prop="responsibilities">
              <el-input
                v-model="createdProjects.responsibilities"
                type="textarea"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="21">
            <el-form-item label="项目业绩：" prop="achievements">
              <el-input
                v-model="createdProjects.achievements"
                type="textarea"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="default"
          plain
          size="mini"
          @click="(dialogVisible = false), cancel()"
          >取消</el-button
        >
        <el-button type="primary" size="mini" @click="saveInfos">
          保存</el-button
        >
      </span>
    </el-dialog>

    <el-drawer
      :visible.sync="drawerVisible"
      append-to-body
      :modal="false"
      custom-class="drawerClass"
      :close-on-click-modal="false"
      direction="ttb"
    >
      <div slot="title" class="drawerTitle">{{ titName }}</div>
      <div class="drawerCon">
        <el-form
          :model="createdEmployments"
          :rules="Emrules"
          ref="createdEmployments"
          label-width="120px"
        >
          <el-row :gutter="24" class="workExperinceForm">
            <el-col :span="12">
              <el-form-item label="公司名称：" prop="company">
                <el-input
                  v-model.trim="createdEmployments.company"
                  placeholder="请输入"
                  maxlength="100"
                  style="width: 500px"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="行业：" class="fromInput" prop="vocationIds">
                <el-cascader
                  style="width: 500px"
                  filterable
                  ref="empVocation"
                  collapse-tags
                  :show-all-levels="false"
                  :options="ColumnInList"
                  v-model="createdEmployments.vocationIds"
                  placeholder="请选择"
                  :props="propsempFunction"
                ></el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="职位名称：" prop="title">
                <el-input
                  v-model="createdEmployments.title"
                  placeholder="请输入"
                  @change="getJob"
                  maxlength="50"
                  style="width: 500px"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="任职时间：">
                <el-row style="display: flex">
                  <el-col style="width: 209px">
                    <el-form-item label-width="0">
                      <el-date-picker
                        v-model="createdEmployments.startDate"
                        type="month"
                        value-format="yyyy-MM"
                        placeholder="请选择"
                        :picker-options="employmentsStartTime"
                        style="width: 209px"
                      ></el-date-picker>
                    </el-form-item>
                  </el-col>
                  <span style="margin: 0 7px">-</span>
                  <el-col style="width: 209px">
                    <el-form-item label-width="0">
                      <el-date-picker
                        :disabled="createdEmployments.isSoFar"
                        v-model="createdEmployments.endDate"
                        type="month"
                        value-format="yyyy-MM"
                        placeholder="请选择"
                        :picker-options="employmentsEndTime"
                        style="width: 209px"
                      ></el-date-picker>
                    </el-form-item>
                  </el-col>
                  <!--                工作经历-->
                  <el-form-item label-width="0" style="margin-left: 15px">
                    <el-checkbox v-model="createdEmployments.isSoFar"
                      >至今</el-checkbox
                    >
                  </el-form-item>
                </el-row>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="12">
              <el-form-item label="部门：" prop="department">
                <el-input
                  v-model="createdEmployments.department"
                  maxlength="50"
                  placeholder="请输入部门"
                  style="width: 500px"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="月薪：" prop="salary">
                <el-input
                  v-model="createdEmployments.salary"
                  maxlength="10"
                  placeholder="请输入"
                  style="width: 500px"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="工作地点：" prop="salary">
                <el-input
                  v-model="createdEmployments.jobLocation"
                  maxlength="50"
                  placeholder="请输入"
                  style="width: 500px"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="工作性质：" prop="salary">
                <el-input
                  v-model="createdEmployments.jobNature"
                  maxlength="50"
                  placeholder="请输入"
                  style="width: 500px"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="下属人数：" prop="underlingNumber">
                <el-input
                  type="number"
                  v-model="createdEmployments.underlingNumber"
                  maxlength="10"
                  oninput="if(value.length > 10) value=value.slice(0, 10)"
                  placeholder="请输入数值"
                  style="width: 500px"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="汇报对象：" prop="leader">
                <el-input
                  v-model.trim="createdEmployments.leader"
                  maxlength="50"
                  placeholder="请输入"
                  style="width: 500px"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="离职原因：" prop="reasonOfLeaving">
                <el-input
                  maxlength="500"
                  v-model.trim="createdEmployments.reasonOfLeaving"
                  placeholder="请输入"
                  style="width: 500px"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="工作描述：" prop="summary">
                <el-input
                  type="textarea"
                  v-model="createdEmployments.summary"
                  style="width: 500px"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="工作业绩：" prop="achievement">
                <el-input
                  show-word-limit
                  :autosize="{ minRows: 2, maxRows: 8 }"
                  type="textarea"
                  v-model="createdEmployments.achievement"
                  style="width: 500px"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24"> </el-row>
          <el-row :gutter="24"> </el-row>
        </el-form>

        <div class="drawerCon_footer fixedFoot">
          <el-button
            type="default"
            plain
            size="mini"
            @click="(drawerVisible = false), cancel()"
            >取消</el-button
          >
          <el-button
            type="primary"
            size="mini"
            style="margin-right: 15px"
            @click="saveInfos"
            >保存</el-button
          >
        </div>
      </div>
    </el-drawer>
    <el-dialog
      title="项目管理"
      width="65%"
      :visible.sync="dialogProVisible"
      :modal-append-to-body="false"
      append-to-body
      :close-on-click-modal="false"
      :before-close="closedPop"
    >
      <el-button size="mini" type="primary" @click="addCandidates"
        >添加候选人</el-button
      >
      <el-table
        :header-row-style="{ height: '50px' }"
        :header-cell-style="{
          padding: '0px',
          'background-color': '#f6f6f6',
          'font-weight': 'normal',
        }"
        :row-style="{ height: '50px' }"
        :cell-style="{ padding: '0px' }"
        :data="ProList"
        class="tabBorder"
        style="margin-top: 20px"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          :show-overflow-tooltip="true"
          type="selection"
          fixed="left"
          width="55"
        ></el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="createdTime"
          label="项目创建时间"
          align="left"
        ></el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="projectName"
          label="项目名称"
          align="left"
        ></el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="managerName"
          label="项目经理"
          align="left"
        ></el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="status"
          label="项目状态"
          align="left"
        ></el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="function"
          label="职能"
          align="left"
        ></el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop
          label="薪资"
          align="left"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.monthlySalary"
              >{{ scope.row.monthlySalary }}元x{{
                scope.row.monthlySalary
              }}个月</span
            >
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop
          label="JD"
          align="left"
        >
          <template slot-scope="scope">
            <el-button size="mini" type="text" @click="checkCon(scope.row.jd)"
              >点击查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </section>
</template>

<script>
import {
  getUserBaseInfos,
  getCommonColumnList,
  addOrEditResume,
  getRepeatedResumeByEmailOrMobile,
  getResumeById,
  getProjectsInProgress,
  addResumesProjectresume,
  cityTree, //
} from "../api/api";
import {
  statisticsResumeAddDuration, //人才新增-操作时长
  statisticsClickFunctionEscape, //记录功能或页面跳出
} from "../api/buryingPoint";
import Cookies from "js-cookie";
import { sort } from "../libs/sort";
import FixedLengthSpan from "./common/FixedLengthSpan";
export default {
  components: { FixedLengthSpan },
  props: ["resumeId", "path", "farResumeList", "isPipe", "entry"],
  data() {
    const validateTel = (rule, value, callback) => {
      if (value != "") {
        let reg = /^[^A-Za-z\u4E00-\u9FA5]{8,}$/;
        if (!reg.test(value)) {
          callback(new Error("请输入正确的手机号"));
        } else {
          callback();
        }
      } else {
        if (this.resumeInfo.resumeType == "coldcall") {
          callback();
          return;
        }
        if (
          this.resumeInfo.wechatOrOther ||
          this.resumeInfo.email ||
          this.resumeInfo.qq ||
          this.resumeInfo.linked_in
        ) {
          callback();
          return;
        }
      }
    };
    const validateLinkedin = (rule, value, callback) => {
      if (value && value.indexOf("linkedin") == -1) {
        callback(new Error("请输入正确内容"));
      } else {
        callback();
      }
    };
    const intNum = (rule, value, callback) => {
      let reg = /^[0-9]*$/;
      if (value && !reg.test(value)) {
        callback(new Error("请输入数字"));
      } else {
        callback();
      }
    };
    const CheckRepeatTel = (rule, value, callback) => {
      if (value) {
        getRepeatedResumeByEmailOrMobile({
          resumeId: this.resumeId,
          email: "",
          mobile: value,
          wechat: "",
          qq: "",
          linked_in: "",
        }).then((res) => {
          if (res.success && res.result.length > 0) {
            this.repeatTel = true;
            this.repeatResumeId = res.result[0].id;
            callback();
          } else {
            this.repeatTel = false;
            callback();
          }
        });
      } else {
        callback();
      }
    };
    const CheckRepeatEmail = (rule, value, callback) => {
      if (value) {
        getRepeatedResumeByEmailOrMobile({
          resumeId: this.resumeId,
          email: value,
          mobile: "",
          wechat: "",
          qq: "",
          linked_in: "",
        }).then((res) => {
          if (res.success && res.result.length > 0) {
            this.repeatEmail = true;
            this.repeatResumeId = res.result[0].id;
            callback();
          } else {
            this.repeatEmail = false;
            callback();
          }
        });
      } else {
        if (this.resumeType == 2) {
          this.repeatEmail = false;
          callback();
        } else {
          callback();
        }
      }
    };
    const CheckRepeatWechat = (rule, value, callback) => {
      if (value) {
        getRepeatedResumeByEmailOrMobile({
          resumeId: this.resumeId,
          email: "",
          mobile: "",
          wechat: value,
          qq: "",
          linked_in: "",
        }).then((res) => {
          if (res.success && res.result && res.result.length > 0) {
            this.repeatWechat = true;
            this.repeatResumeId = res.result[0].id;
            callback();
          } else {
            this.repeatWechat = false;
            callback();
          }
        });
      } else {
        if (this.resumeType == 2) {
          this.repeatWechat = false;
          callback();
        } else {
          callback();
        }
      }
    };
    const CheckRepeatQq = (rule, value, callback) => {
      if (value) {
        getRepeatedResumeByEmailOrMobile({
          resumeId: this.resumeId,
          email: "",
          mobile: "",
          wechat: "",
          qq: value,
          linked_in: "",
        }).then((res) => {
          if (res.success && res.result && res.result.length > 0) {
            this.repeatQq = true;
            this.repeatResumeId = res.result[0].id;
            callback();
          } else {
            this.repeatQq = false;
            callback();
          }
        });
      } else {
        if (this.resumeType == 2) {
          this.repeatQq = false;
          callback();
        } else {
          callback();
        }
      }
    };
    const CheckRepeatLinkedin = (rule, value, callback) => {
      if (value) {
        getRepeatedResumeByEmailOrMobile({
          resumeId: this.resumeId,
          email: "",
          mobile: "",
          wechat: "",
          qq: "",
          linked_in: value,
        }).then((res) => {
          if (res.success && res.result && res.result.length > 0) {
            this.repeatLinkedin = true;
            this.repeatResumeId = res.result[0].id;
            callback();
          } else {
            this.repeatLinkedin = false;
            callback();
          }
        });
      } else {
        if (this.resumeType == 2) {
          this.repeatLinkedin = false;
          callback();
        } else {
          callback();
        }
      }
    };
    const asyncValidator = (rule, value, callback) => {
      const { email, wechatOrOther, mobile, qq, linked_in } = this.resumeInfo;
      const isEmpty =
        (email + "").length +
          (wechatOrOther + "").length +
          (mobile + "").length +
          (qq + "").length +
          (linked_in + "").length >
        0;
      if (!isEmpty) {
        return callback(new Error("请输入联系方式中的任意一项"));
      }
      callback();
    };
    return {
      searchManager: "",
      searchUserList: [],
      inJoinProject: [],
      managerName: "",
      loading: true,
      isLoad: true,
      required: true,
      status: false,
      forbidden: false,
      showMoreComVal: true,
      showMorePerInfo: true,
      showMorePurpose: true,
      showMoreJobExp: true,
      showMoreEduExp: true,
      showMoreProExp: true,
      showMoreOthers: true,
      baseUrl: process.env.VUE_APP_baseUrl,
      myHeaders: {
        Authorization: Cookies.get("accessToken"),
      },
      Authorization: Cookies.get("accessToken"),
      checked: false, //是否面议
      desabled: false,
      dialogProVisible: false,
      multipleSelection: "",
      ProList: [], //项目列表
      projectIds: [],
      pagination: {
        total: 0,
        pageNo: 1,
        pageSize: 10,
      },
      imgUrl: "",
      userList: [],
      newResId: "", //智能推荐获取的id
      repeatEmail: false,
      repeatTel: false,
      repeatWechat: false,
      repeatQq: false,
      repeatLinkedin: false,
      ColumnCityList: [], // 城市列表
      ColumnInList: [], //行业
      ColumnInList1: [], //行业
      ColumnPosList: [], //职能
      forwardLocationList: [], //期望城市
      forwardVocationList: [], //期望行业
      employmentsList: [], //工作经历
      educationsList: [], //教育经历
      projectsList: [], //项目经历
      remarks: [],
      isEmployments: false,
      isEducations: false,
      isProjects: false,
      showMore: false, //非必填显隐
      createdEmployments: {
        company: "",
        endDate: "",
        jobNature: "",
        jobLocation: "",
        department: "",
        functionIds: [],
        functionName: "",
        id: 0,
        leader: "",
        reasonOfLeaving: "",
        resumeId: 0,
        salary: "",
        startDate: "",
        summary: "",
        achievement: "",
        title: "",
        underlingNumber: "",
        version: 0,
        vocationIds: [],
        vocationName: "",
        isSoFar: false,
      },
      createdEducations: {
        education: "",
        endDate: "",
        id: 0,
        is_tongzhao: false,
        resumeId: 0,
        school: "",
        schoolType: null,
        speciality: "",
        startDate: "",
        version: 0,
        isSoFar: false,
      },
      createdProjects: {
        achievements: "",
        company: "",
        endDate: "",
        id: 0,
        isSoFar: false,
        projectDescription: "",
        projectName: "",
        responsibilities: "",
        resumeId: 0,
        startDate: "",
        title: "",
        version: 0,
      },
      Emrules: {
        company: { required: true, message: "请输入", trigger: "blur" },
        vocationIds: { required: true, message: "请选择", trigger: "change" },
        title: { required: true, message: "请输入", trigger: "blur" },
        summary: [
          { max: 1500, message: "超过1500个字数限制", trigger: "blur" },
        ],
        achievement: [
          { max: 1500, message: "超过1500个字数限制", trigger: "blur" },
        ],
      },
      Edrules: {
        school: { required: true, message: "请输入", trigger: "blur" },
        education: { required: true, message: "请输入", trigger: "change" },
        speciality: [
          { required: true, message: "请输入专业名称", trigger: "blur" },
        ],
        schoolType: { required: true, message: "请选择", trigger: "change" },
      },
      Prorules: {
        projectName: { required: true, message: "请输入", trigger: "blur" },
        projectDescription: [
          { max: 1500, message: "超过1500个字数限制", trigger: "blur" },
        ],
        responsibilities: [
          { max: 1500, message: "超过1500个字数限制", trigger: "blur" },
        ],
        achievements: [
          { max: 1500, message: "超过1500个字数限制", trigger: "blur" },
        ],
      },
      titName: "",
      dialogVisible: false,
      dialogWidth: "600px",
      props: {
        value: "id",
        label: "name",
        children: "children",
        checkStrictly: true,
        // disabled: "hasChild",
      },
      propsempFunction: {
        value: "id",
        label: "name",
        children: "children",
        checkStrictly: true,
        multiple: true,
        disabled: "hasChild",
      },
      propsempFunction1: {
        value: "id",
        label: "name",
        children: "children",
        multiple: true,
        checkStrictly: true,
        disabled: "hasChild",
      },
      props2: {
        value: "id",
        label: "name",
        children: "children",
        multiple: true,
      },
      props3: {
        value: "id",
        label: "name",
        children: "children",
        checkStrictly: true,
        multiple: true,
        disabled: "hasChild",
      },
      props1: {
        value: "id",
        label: "name",
        children: "children",
        multiple: true,
        // disabled: "hasChild",
        checkStrictly: true,
      },
      resumeType: "候选人",
      resumeInfo: {
        lowAimSalary: null,
        highAimSalary: null,
        beginWorkTime: "",
        birth: "",
        projectIds: [],
        resumeVocationIds: [],
        resumeFunctionIds: [],
        certificate: "",
        education: "",
        email: "",
        english: "",
        experience: "",
        firstDegree: "",
        id: "",
        isMianYi: false,
        vocationIds: [],
        functionIds: [],
        wechatOrOther: "",
        jobStatus: "",
        married: "",
        mobile: "",
        name: "",
        nowLocationIds: [],
        others: "",
        ownerUserId: parseInt(this.getStore("uid")),
        personal: "",
        photoFilePath: "",
        resumeType: null,
        school: "",
        sex: "",
        title: "",
        training: "",
        version: 0,
        qq: "",
        linked_in: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入姓名", trigger: "blur" },
          { min: 2, message: "长度至少2个字符", trigger: "blur" },
        ],
        resumeType: [{ required: true, message: "请选择", trigger: "change" }],
        ownerUserId: [{ required: true, message: "请选择", trigger: "change" }],
        sex: [{ required: true, message: "请选择", trigger: "change" }],
        nowLocationIds: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        resumeFunctionIds: [
          {
            required: true,
            trigger: "change",
            message: "请选择职能",
          },
        ],
        resumeVocationIds: [
          { required: true, message: "请选择行业", trigger: "change" },
        ],
        email: [
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: "blur",
          },
          { validator: CheckRepeatEmail, trigger: "blur" },
          { validator: asyncValidator, trigger: "blur" },
        ],
        mobile: [
          { validator: asyncValidator, trigger: "blur" },
          { validator: validateTel, trigger: "blur" },
          { validator: CheckRepeatTel, trigger: "blur" },
        ],
        wechatOrOther: [
          { validator: asyncValidator, trigger: "blur" },
          { validator: CheckRepeatWechat, trigger: "blur" },
        ],
        qq: [
          { validator: intNum, trigger: "blur" },
          { validator: asyncValidator, trigger: "blur" },
          { validator: CheckRepeatQq, trigger: "blur" },
        ],
        linked_in: [
          { validator: asyncValidator, trigger: "blur" },
          { validator: validateLinkedin, trigger: "blur" },
          { validator: CheckRepeatLinkedin, trigger: "blur" },
        ],
        lowAimSalary: [{ validator: intNum, trigger: "blur" }],
        highAimSalary: [{ validator: intNum, trigger: "blur" }],
        aimSalary: { validator: validateTel, trigger: "blur" },
      },
      rules1: {
        resumeType: [{ required: true, message: "请选择", trigger: "change" }],
        ownerUserId: [{ required: true, message: "请选择", trigger: "change" }],
        name: [
          { required: true, message: "请输入姓名", trigger: "blur" },
          { min: 2, message: "长度至少2个字符", trigger: "blur" },
        ],
        email: [
          { validator: CheckRepeatEmail, trigger: "blur" },
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: "blur",
          },
        ],
        wechatOrOther: [{ validator: CheckRepeatWechat, trigger: "blur" }],
        mobile: [
          { validator: validateTel, trigger: "blur" },
          { validator: CheckRepeatTel, trigger: "blur" },
        ],
        qq: [
          { validator: intNum, trigger: "blur" },
          { validator: CheckRepeatQq, trigger: "blur" },
        ],
        linked_in: [
          { validator: validateLinkedin, trigger: "blur" },
          { validator: CheckRepeatLinkedin, trigger: "blur" },
        ],
        lowAimSalary: [
          //
          { validator: intNum, trigger: "blur" },
        ],
        highAimSalary: [
          //
          { validator: intNum, trigger: "blur" },
        ],
      },
      // 日期选择器时间限制
      employmentsStartTime: {
        disabledDate: (time) => {
          let endDateVal = this.createdEmployments.endDate;
          if (endDateVal) {
            return time.getTime() > new Date(endDateVal).getTime();
          } else {
            return time.getTime() > Date.now();
          }
        },
      },
      employmentsEndTime: {
        disabledDate: (time) => {
          let beginDateVal = this.createdEmployments.startDate;
          if (beginDateVal) {
            return (
              time.getTime() < new Date(beginDateVal).getTime() ||
              time.getTime() > Date.now()
            );
          } else {
            return time.getTime() > Date.now();
          }
        },
      },
      educationsStartTime: {
        disabledDate: (time) => {
          let endDateVal = this.createdEducations.endDate;
          if (endDateVal) {
            return time.getTime() > new Date(endDateVal).getTime();
          } else {
            return time.getTime() > Date.now();
          }
        },
      },
      educationsEndTime: {
        disabledDate: (time) => {
          let beginDateVal = this.createdEducations.startDate;
          if (beginDateVal) {
            return (
              time.getTime() < new Date(beginDateVal).getTime() ||
              time.getTime() > Date.now()
            );
          } else {
            return time.getTime() > Date.now();
          }
        },
      },
      projectsStartTime: {
        disabledDate: (time) => {
          let endDateVal = this.createdProjects.endDate;
          if (endDateVal) {
            return time.getTime() > new Date(endDateVal).getTime();
          } else {
            return time.getTime() > Date.now();
          }
        },
      },
      projectsEndTime: {
        disabledDate: (time) => {
          let beginDateVal = this.createdProjects.startDate;
          if (beginDateVal) {
            return (
              time.getTime() < new Date(beginDateVal).getTime() ||
              time.getTime() > Date.now()
            );
          } else {
            return time.getTime() > Date.now();
          }
        },
      },
      drawerVisible: false,
      isloading: false,
      isShowComplation: false,
      innerVisible: false,
      percentageNum: 0,
      myInterval: null,
      pointData: {
        endOperation: "",
        endTime: "",
        entry: "",
        resumeEdit: {},
        startTime: "",
      },
      useId: parseInt(this.getStore("uid")),
      isOperation: false, //是否进行有效操作
    };
  },
  watch: {
    farResumeList() {
      this.farResume();
    },
    //设置工作经历排序
    employmentsList(v, ov) {
      this.sortDate(v);
    },
    //项目经历排序
    projectsList(v, ov) {
      this.sortDate(v);
    },
    //教育经历排序
    educationsList(v, ov) {
      this.sortDate(v);
    },
    "createdEmployments.isSoFar"(v, ov) {},
  },
  computed: {
    rulesList() {
      this.repeatWechat = false;
      this.repeatQq = false;
      this.repeatLinkedin = false;
      this.repeatTel = false;
      this.repeatEmail = false;
      if (this.resumeType == "候选人") {
        this.required = true;
        return this.rules;
      } else {
        this.required = false;
        return this.rules1;
      }
    },
  },
  methods: {
    sortDate(list) {
      sort(list, function (a, b) {
        let a1 = a.startDate || 0;
        let b1 = b.startDate || 0;
        if (a1 == 0) return 1;
        if (b1 == 0) return -1;
        if (a1) {
          let year1 = a1.split("-")[0];
          let month1 = a1.split("-")[1];
          let year2 = b1.split("-")[0];
          let month2 = b1.split("-")[1];
          if (year1 != year2) {
            return year2 - year1;
          }
          return month2 - month1;
        }
      });
    },
    getProjectsInProgress() {
      getProjectsInProgress().then((res) => {
        this.inJoinProject = res.result;
      });
    },
    getJob() {
      if (this.createdEmployments.title.split(" ").join("").length == 0) {
        this.createdEmployments.title = "";
      }
    },
    checkRepeat() {
      this.$drawer({
        options: {
          title: "人才",
          icon: "icon-rc_bt",
          modal: false,
          direction: "ttb",
          customClass: "drawerClass",
        },
        template: "/resumeInfo",
        props: {
          hover: { matchAll: true, majorSearch: false, hover: true },
          resumeDetailId: this.repeatResumeId,
          repeatResume: { type: "列表", valId: "" },
        },
        emit: {
          handleNewResume: [this.handleNewResume, "close"],
          handleSuccess: this.handleSuccess,
        },
      });
    },
    //去合并
    goPending() {
      this.isloading = true;
      return new Promise((resolve, reject) => {
        addOrEditResume({
          educationsList: this.educationsList,
          employmentsList: this.employmentsList,
          forwardLocationIds: this.forwardLocationList,
          forwardVocationIds: this.forwardVocationList,
          projectsList: this.projectsList,
          resumeInfo: this.resumeInfo,
          resumeVocationIds: this.resumeInfo.resumeVocationIds,
          resumeFunctionIds: this.resumeInfo.resumeFunctionIds,
          doNotCheckRepeated: this.path ? true : false,
        }).then((res) => {
          if (res.success) {
            this.$parent.handleClose();
            this.$router.replace({
              path: "/supplierAllBack",
              query: {
                path: "/resume/pending",
                id: res.result.id,
              },
            });
            // this.$router.push({
            //   path: "/resume/pending",
            //   query: { resumeId: res.result.id },
            // });
          }
          resolve(true);
        });
      });
    },
    handleAvatarSuccess(res, file) {
      this.imgUrl = URL.createObjectURL(file.raw);
      this.resumeInfo.photoPath = res;
    },
    cancel() {
      if (this.isEmployments) {
        if (this.getStore("employments")) {
          this.employmentsList = JSON.parse(this.getStore("employments"));
          this.resetForm("createdEmployments");
        }
      }
      if (this.isEducations) {
        if (this.getStore("educations")) {
          this.educationsList = JSON.parse(this.getStore("educations"));
          this.resetForm("createdEducations");
        }
      }
      if (this.isProjects) {
        if (this.getStore("projects")) {
          this.projectsList = JSON.parse(this.getStore("projects"));
          this.resetForm("createdProjects");
        }
      }
      this.removeStore("Index");
      this.isProjects = false;
      this.isEducations = false;
      this.isEmployments = false;
    },
    addNewEx(type) {
      this.removeStore("Index"); // 点击新增时，首先去除localStorage里面的Index
      if (type == 1) {
        //新增工作经历
        this.drawerVisible = true;
        this.isEmployments = true;
        this.titName = "新增工作经历";
        this.createdEmployments = {};
      }
      if (type == 2) {
        //新增教育经历
        this.dialogVisible = true;
        this.isEducations = true;
        this.titName = "新增教育经历";
        this.dialogWidth = "600px";
        this.createdEducations = {};
      }
      if (type == 3) {
        //新增项目经历
        this.dialogVisible = true;
        this.isProjects = true;
        this.titName = "增加项目经历";
        this.dialogWidth = "600px";
        this.createdProjects = {};
      }
    },
    editControl(type, index) {
      //编辑工作经验
      this.setStore("Index", index); //编辑时有索引
      if (type == 1) {
        this.titName = "编辑工作经历";
        this.isEmployments = true;
        this.drawerVisible = true;
        this.createdEmployments = this.employmentsList[index];
      }
      if (type == 2) {
        this.titName = "编辑教育经历";
        this.isEducations = true;
        this.createdEducations = this.educationsList[index];
        this.dialogVisible = true;
      }
      if (type == 3) {
        this.titName = "编辑项目";
        this.isProjects = true;
        this.createdProjects = this.projectsList[index];
        this.dialogVisible = true;
      }
    },
    delControl(type, index) {
      //删除工作经验
      this.$confirm("是否删除该项?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "altCls",
        type: "warning",
      })
        .then(() => {
          if (type == 1) {
            this.employmentsList.splice(index, 1);
            this.setStore("employments", this.employmentsList); //列表List
          }
          if (type == 2) {
            this.educationsList.splice(index, 1);
            this.setStore("educations", this.educationsList); //列表List
          }
          if (type == 3) {
            this.projectsList.splice(index, 1);
            this.setStore("projects", this.projectsList); //列表List
          }
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //删除左右两端的空格
    trim(str) {
      let a = "";
      a = str.replace(/(^\s*)|(\s*$)/g, "");
      return a;
    },
    saveInfos() {
      if (this.isEmployments) {
        //工作经历
        this.createdEmployments.title = this.trim(
          this.createdEmployments.title
        );
        if (
          this.createdEmployments.underlingNumber < 0 ||
          this.createdEmployments.salary < 0
        ) {
          this.$message.error("下属人数或者月薪不能为负！");
          return false;
        }
        if (
          (this.createdEmployments.vocationIds || []).length > 5 ||
          (this.createdEmployments.functionIds || []).length > 5
        ) {
          this.$message.error("职能或者行业不能超过5个！");
          return false;
        }
        this.$refs["createdEmployments"].validate((valid) => {
          if (valid) {
            // if (
            //   this.createdEmployments.functionIds != null &&
            //   this.createdEmployments.functionIds != []
            // ) {
            // }
            let _index = this.getStore("Index");
            this.setStore("employmentsList", this.createdEmployments);
            if (_index) {
              //编辑
              this.employmentsList[_index] = JSON.parse(
                this.getStore("employmentsList")
              );
              this.sortDate(this.employmentsList);
            } else {
              //新增
              if (this.getStore("employmentsList")) {
                this.employmentsList.push(
                  JSON.parse(this.getStore("employmentsList"))
                );
              }
            }
            this.isEmployments = false;
            this.resetForm("createdEmployments");
            this.removeStore("employmentsList");
            this.setStore("employments", this.employmentsList); //列表List
            this.drawerVisible = false;
          }
        });
      }

      if (this.isEducations) {
        this.$refs["createdEducations"].validate((valid) => {
          if (valid) {
            if (
              this.createdEducations.startDate == "" ||
              this.createdEducations.startDate == undefined
            ) {
              this.$message.error("请选择开始时间");
              return false;
            }
            if (
              (this.createdEducations.endDate == "" ||
                this.createdEducations.endDate == undefined) &&
              !this.createdEducations.isSoFar
            ) {
              this.$message.error("请选择结束时间");
              return false;
            }
            let _index = this.getStore("Index");
            this.setStore("educationsList", this.createdEducations);
            if (_index) {
              this.$set(
                this.educationsList,
                _index,
                JSON.parse(this.getStore("educationsList"))
              );
              this.sortDate(this.educationsList);
            } else {
              //新增
              if (this.getStore("educationsList")) {
                this.$set(
                  this.educationsList,
                  this.educationsList.length,
                  JSON.parse(this.getStore("educationsList"))
                );
              }
            }
            this.isEducations = false;
            this.resetForm("createdEducations");
            this.removeStore("educationsList");
            this.setStore("educations", this.educationsList); //列表List
            this.dialogVisible = false;
          }
        });
      }
      if (this.isProjects) {
        this.$refs["createdProjects"].validate((valid) => {
          if (valid) {
            if (
              this.createdProjects.startDate == "" ||
              this.createdProjects.startDate == undefined
            ) {
              this.$message.error("请选择开始时间");
              return false;
            }
            if (
              (this.createdProjects.endDate == "" ||
                this.createdProjects.endDate == undefined) &&
              !this.createdProjects.isSoFar
            ) {
              this.$message.error("请选择结束时间");
              return false;
            }
            let _index = this.getStore("Index");
            this.setStore("projectsList", this.createdProjects);
            if (_index) {
              //编辑
              this.projectsList[_index] = JSON.parse(
                this.getStore("projectsList")
              );
              this.sortDate(this.projectsList);
            } else {
              //新增
              if (this.getStore("projectsList")) {
                this.projectsList.push(
                  JSON.parse(this.getStore("projectsList"))
                );
              }
            }
            this.isProjects = false;
            this.resetForm("createdProjects");
            this.removeStore("projectsList");
            this.setStore("projects", this.projectsList); //列表List
            this.dialogVisible = false;
            // this.$emit("loadList");
          }
        });
      }
    },
    handleSizeChange(val) {
      //pageSize 改变时会触发
      this.pagination.pageSize = val;
      this.pagination.pageNo = 1;
      this.loadList();
    },
    handleCurrentChange(val) {
      //currentPage 改变时会触发
      this.pagination.pageNo = val;
      this.loadList();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    checkCon(jd) {
      this.$alert(jd, "JD", {
        confirmButtonText: "确定",
        showConfirmButton: false,
        callback: (action) => {},
      });
    },
    addCandidates() {
      this.projectIds = [];
      if (this.multipleSelection.length == 0) {
        this.$message.error("请先选择需要加入的项");
        return false;
      }
      this.multipleSelection.forEach((item) => {
        this.projectIds.push(item.id);
      });
      addResumesProjectresume({
        projectIds: this.projectIds,
        resumeIds: this.newResId,
      }).then((res) => {
        if (res.success) {
          if (res.result.showRepeat == 1) {
            //均不在
            this.$alert("候选人加入项目成功", "提示", {
              confirmButtonText: "确定",
              customClass: "altCls",
              showClose: false,
              closeOnClickModal: false,
              closeOnPressEscape: false,
              callback: (action) => {
                if (!this.resumeId) {
                  this.$emit("loadList");
                } else {
                  this.$emit("loadResumeDetils");
                }
              },
            });
          }
        }
      });
    },
    customColorMethod(percentage) {
      if (percentage < 50) {
        return "#f56c6c";
      } else if (percentage < 100) {
        return "#e6a23c";
      } else {
        return "#67c23a";
      }
    },
    saveAll() {
      this.isOperation = true;
      this.innerVisible = true;
      this.isloading = true;
      this.myInterval = setInterval(() => {
        if (this.percentageNum == 99) {
          clearInterval(this.myInterval);
        } else {
          this.percentageNum++;
        }
      }, 100);
      return new Promise((resolve, reject) => {
        addOrEditResume({
          educationsList: this.educationsList,
          employmentsList: this.employmentsList,
          forwardLocationIds: this.forwardLocationList,
          forwardVocationIds: this.forwardVocationList,
          projectsList: this.projectsList,
          resumeInfo: this.resumeInfo,
          resumeVocationIds: this.resumeInfo.resumeVocationIds,
          resumeFunctionIds: this.resumeInfo.resumeFunctionIds,
          doNotCheckRepeated: this.path ? true : false,
        }).then((res) => {
          if (res.success) {
            clearInterval(this.myInterval);
            this.$emit("cleanAll", "success");
            this.newResId = res.result.id;
            if (
              res.result.repeatedResumeInfos == null ||
              res.result.repeatedResumeInfos.length == 0 ||
              res.result.repeatedResumeInfos.every((item) => item == null)
            ) {
              let tips = "";
              if (!this.resumeId || this.$route.path.indexOf("repeat") > 0) {
                this.GetstatisticsResumeAddDuration(
                  "点击保存并成功保存至人才库"
                );
                tips = "创建人才成功";
                this.$message.success(tips);
                resolve(true);
              } else {
                if (this.isPipe) {
                  this.$message.success("操作成功");
                } else {
                  this.$message.success("人才修改成功");
                }
                resolve(true);
              }
            } else {
              clearInterval(this.myInterval);
              this.$confirm("录入人才和系统内人才疑似重复", "提示", {
                confirmButtonText: "点击查看",
                cancelButtonText: "取消",
                showClose: false,
                customClass: "altCls",
                closeOnClickModal: false,
                closeOnPressEscape: false,
              })
                .then(() => {
                  this.GetstatisticsResumeAddDuration(
                    "点击保存并成功保存至重复简历待处理"
                  );
                  this.$router.push({
                    path: "/resume/pending",
                    query: {
                      id: this.newResId,
                    },
                  });
                })
                .catch(() => {
                  // if (this.path == 1) {
                  //   this.$router.push({
                  //     path: "/resumeList",
                  //   });
                  // }
                  if (!this.resumeId) {
                    this.$emit("loadList");
                  } else {
                    this.$emit("loadResumeDetils");
                  }
                });
            }
          } else {
            this.$emit("cleanAll");
          }
          this.innerVisible = false;
          resolve(true);
        });
      });
    },
    closedPop() {
      this.$emit("loadResumeDetils");
    },
    saveResume() {
      return new Promise((resolve, reject) => {
        let _this = this;

        if ((this.resumeInfo.resumeVocationIds || []).length > 5) {
          this.$message.error("行业不能超过5个！");
          reject();
          return false;
        }
        _this.$refs["resumeInfo"].validate((valid) => {
          if (valid) {
            this.isLoad = false;
            if (_this.resumeType == null) {
              reject();
              _this.$message.error("请先选择人才类型");
              return false;
            }
            _this.resumeInfo.resumeType = _this.resumeType;
            this.saveAll().finally(() => {
              this.$emit("cleanAll", "success");
              resolve();
            });
          } else {
            // 如果失败 验证不成功
            this.$emit("cleanAll");
            reject();
          }
        });
        this.$emit("cleanAll");
      });
    },
    handleClose(done) {
      done();
      this.cancel();
    },
    Haschecked(e) {
      //面议
      this.desabled = e;
      this.resumeInfo.highAimSalary = "";
      this.resumeInfo.lowAimSalary = "";
    },
    checkedTime(e) {
      this.createdEmployments.endDate = "";
      this.createdEducations.endDate = "";
      this.createdProjects.endDate = "";
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传文件只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传文件大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    loadColumnCityList(type) {
      //城市列表
      this.ColumnCityList = [];
      cityTree().then((res) => {
        if (res.success) {
          this.ColumnCityList = res.result;
        }
      });
    },
    loadColumnInList(type) {
      //行业列表
      this.ColumnInList = [];
      getCommonColumnList({ type: type }).then((res) => {
        if (res.success) {
          this.ColumnInList = res.result;
        }
      });
    },
    loadColumnPosList(type) {
      //职能列表
      this.ColumnPosList = [];
      getCommonColumnList({ type: type }).then((res) => {
        if (res.success) {
          this.ColumnPosList = res.result;
        }
      });
    },
    loadUserBase() {
      //获取用户列表
      getUserBaseInfos().then((res) => {
        if (res.success) {
          this.userList = res.result;
        }
      });
    },
    loadInfoByresumeId(resId) {
      getResumeById({
        resumeId: resId,
        fromPage: "编辑人才",
      }).then((res) => {
        if (res.success) {
          let _this = this;
          this.loading = false;
          this.resumeInfo = res.result.resumeInfo;
          if (this.resumeInfo.linked_in == null) {
            this.resumeInfo.linked_in = "";
          }
          if (this.resumeInfo.qq == null) {
            this.resumeInfo.qq = "";
          }
          if (
            res.result.resumeInfo.resumeType == "有重复" ||
            res.result.resumeInfo.resumeType == "需完善" ||
            res.result.resumeInfo.resumeType == 4 ||
            res.result.resumeInfo.resumeType == 0
          ) {
            this.resumeType = null;
          } else {
            this.resumeType = res.result.resumeInfo.resumeType;
          }
          res.result.educationsList.forEach((item, index) => {
            this.$set(this.educationsList, index, item);
          });
          this.projectsList = res.result.projectsList
            ? res.result.projectsList
            : [];
          this.forwardLocationList = [];
          this.forwardVocationList = [];
          this.resumeInfo.vocationIds = [];
          this.resumeInfo.functionIds = [];
          this.forwardVocationList = [];
          this.imgUrl = res.result.resumeInfo.photoPath
            ? this.baseUrl +
              res.result.resumeInfo.photoPath +
              "?Authorization=" +
              this.Authorization
            : res.result.resumeInfo.photoBase64String
            ? "data:image/jpeg;base64," +
              res.result.resumeInfo.photoBase64String
            : "";
          if (res.result.resumeInfo.isMianYi) {
            this.desabled = true;
          }
          if (
            res.result.resumeInfo.ownerUserId !== parseInt(this.getStore("uid"))
          ) {
            this.forbidden = true;
          }
          this.setStore("educations", res.result.educationsList);
          this.setStore("projects", res.result.projectsList);
          this.employmentsList = res.result.employmentsList.map((item) => {
            if (typeof item.vocationIds == "string") {
              let vocationIds = eval(item.vocationIds);
              item.vocationIds = vocationIds;
            }
            if (typeof item.functionIds == "string") {
              let functionIds = eval(item.functionIds);
              item.functionIds = functionIds;
            }
            return item;
          });
          this.setStore("employments", this.employmentsList);

          let functionIds = eval(res.result.functionList);
          let vocationIds = eval(res.result.vocationList);
          _this.resumeFunctionIds = [];
          _this.resumeVocationIds = [];
          if (functionIds != null && functionIds.length > 0) {
            _this.resumeFunctionIds = functionIds;
          }
          if (vocationIds != null && vocationIds.length > 0) {
            _this.resumeVocationIds = vocationIds;
          }
          let nowLocationIds = eval(res.result.resumeInfo.nowLocationIds);
          _this.resumeInfo.nowLocationIds = [];
          if (nowLocationIds != null && nowLocationIds.length > 0) {
            _this.resumeInfo.nowLocationIds = nowLocationIds;
          }
          if (res.result.forwardLocationList.length > 0) {
            res.result.forwardLocationList.forEach((item) => {
              let LocationList = eval(item.forwardLocationIds);
              this.forwardLocationList.push(LocationList);
            });
          }
          if (res.result.forwardVocationList.length > 0) {
            res.result.forwardVocationList.forEach((item) => {
              let VocationList = eval(item.forwardVocationIds);
              this.forwardVocationList.push(VocationList);
            });
          }
          this.$set(this.resumeInfo, "resumeFunctionIds", []);
          this.$set(this.resumeInfo, "resumeVocationIds", []);
          // 职能
          // debugger
          this.resumeInfo.resumeFunctionIds = res.result.functionList.map(
            (item) => JSON.parse(item.functionIds)
          );
          // 行业
          this.resumeInfo.resumeVocationIds = res.result.vocationList.map(
            (item) => JSON.parse(item.vocationIds)
          );
        }
      });
    },
    farResume() {
      let _this = this;
      this.loading = false;
      let res = this.farResumeList;
      this.resumeInfo = res.resumeInfo;
      this.resumeType =
        res.resumeInfo.resumeType == 4 || res.resumeInfo.resumeType == 0
          ? null
          : res.resumeInfo.resumeType;
      if (res.educationsList) {
        res.educationsList.forEach((item, index) => {
          this.$set(this.educationsList, index, item);
        });
      } else {
        this.educationsList = [];
      }
      this.projectsList = res.projectsList ? res.projectsList : [];
      this.forwardLocationList = [];
      this.forwardVocationList = [];
      this.imgUrl = res.resumeInfo.photoPath
        ? this.baseUrl +
          res.resumeInfo.photoPath +
          "?Authorization=" +
          this.Authorization
        : res.resumeInfo.photoBase64String
        ? "data:image/jpeg;base64," + res.resumeInfo.photoBase64String
        : "";
      if (res.resumeInfo.isMianYi) {
        this.desabled = true;
      }
      if (res.resumeInfo.ownerUserId !== parseInt(this.getStore("uid"))) {
        this.forbidden = true;
      }
      this.setStore("educations", res.educationsList);
      this.setStore("projects", res.projectsList);
      if (res.employmentsList) {
        this.employmentsList = res.employmentsList.map((item) => {
          if (typeof item.vocationIds == "string") {
            let vocationIds = eval(item.vocationIds);
            item.vocationIds = vocationIds;
          }
          if (typeof item.functionIds == "string") {
            let functionIds = eval(item.functionIds);
            item.functionIds = functionIds;
          }
          return item;
        });
      } else {
        this.employmentsList = [];
      }
      this.setStore("employments", this.employmentsList);
      //职能处理
      let functionIds = eval(res.resumeInfo.functionIds);
      let vocationIds = eval(res.resumeInfo.vocationIds);
      _this.resumeInfo.functionIds = [];
      _this.resumeInfo.vocationIds = [];
      if (functionIds != null && functionIds.length > 0) {
        _this.resumeInfo.functionIds = functionIds;
      }
      if (vocationIds != null && vocationIds.length > 0) {
        _this.resumeInfo.vocationIds = vocationIds;
      }
      let nowLocationIds = eval(res.resumeInfo.nowLocationIds);
      _this.resumeInfo.nowLocationIds = [];
      if (nowLocationIds != null && nowLocationIds.length > 0) {
        _this.resumeInfo.nowLocationIds = nowLocationIds;
      }
      if (res.forwardLocationList && res.forwardLocationList.length > 0) {
        res.forwardLocationList.forEach((item) => {
          let LocationList = eval(item.forwardLocationIds);
          this.forwardLocationList.push(LocationList);
        });
      }
      if (res.forwardVocationList && res.forwardVocationList.length > 0) {
        res.forwardVocationList.forEach((item) => {
          let VocationList = eval(item.forwardVocationIds);
          this.forwardVocationList.push(VocationList);
        });
      }
    },
    emailChanged(e) {
      this.repeatEmail = false;
      this.$nextTick(() => {
        this.$refs["resumeInfo"].clearValidate([
          "mobile",
          "wechatOrOther",
          "qq",
          "linked_in",
        ]);
      });
    },
    telChanged(e) {
      this.repeatTel = false;
      this.$nextTick(() => {
        this.$refs["resumeInfo"].clearValidate([
          "email",
          "wechatOrOther",
          "qq",
          "linked_in",
        ]);
      });
    },
    wx(e) {
      this.repeatWechat = false;
      this.$nextTick(() => {
        this.$refs["resumeInfo"].clearValidate([
          "email",
          "mobile",
          "qq",
          "linked_in",
        ]);
      });
    },
    qqChanged(e) {
      this.repeatQq = false;
      this.$nextTick(() => {
        this.$refs["resumeInfo"].clearValidate([
          "email",
          "mobile",
          "wechatOrOther",
          "linked_in",
        ]);
      });
    },
    linkedinChanged(e) {
      this.repeatLinkedin = false;
      this.$nextTick(() => {
        this.$refs["resumeInfo"].clearValidate([
          "email",
          "mobile",
          "wechatOrOther",
          "qq",
        ]);
      });
    },
    changeResumeType(e) {
      this.resumeInfo.resumeType = e;
      if (
        this.resumeInfo.nowLocationIds &&
        this.resumeInfo.nowLocationIds.every((item) => {
          return item == 0;
        })
      ) {
        this.resumeInfo.nowLocationIds = [];
      }
      if (e != 1) {
        this.$refs["resumeInfo"].clearValidate([
          "sex",
          "nowLocationIds",
          "email",
          "mobile",
          "wechatOrOther",
          "functionIds",
          "vocationIds",
          "resumeVocationIds",
          "resumeFunctionIds",
          "qq",
          "linked_in",
        ]);
      }
    },
    inputChange(type) {
      //   // 手机号
      this.resumeInfo.mobile = this.resumeInfo.mobile.replace(
        /[A-Za-z\u4E00-\u9FA5]/g,
        ""
      );
    },
    showTraining(val) {},
    stopAdd() {},
    //人才新增-操作时长埋点
    GetstatisticsResumeAddDuration(val) {
      // console.log(val);
      this.pointData.endOperation = val;
      this.pointData.resumeEdit = {
        educationsList: this.educationsList,
        employmentsList: this.employmentsList,
        forwardLocationIds: this.forwardLocationList,
        forwardVocationIds: this.forwardVocationList,
        projectsList: this.projectsList,
        resumeInfo: this.resumeInfo,
        resumeVocationIds: this.resumeInfo.resumeVocationIds,
        resumeFunctionIds: this.resumeInfo.resumeFunctionIds,
        doNotCheckRepeated: this.path ? true : false,
      };
      this.pointData.endTime = new Date().Format("yyyy-MM-dd hh:mm:ss");
      statisticsResumeAddDuration(this.pointData).then();
    },
  },
  created() {
    // console.log(this.entry);
    this.pointData.entry = this.entry;
    this.pointData.startTime = new Date().Format("yyyy-MM-dd hh:mm:ss");
    this.startTimeStamp = Math.round(new Date().getTime() / 1000);
    this.getProjectsInProgress();
    this.resumeInfo.resumeType = 1;
    this.loadColumnPosList("职能");
    this.loadColumnInList("行业");
    this.loadColumnCityList("城市");
    if (this.farResumeList) {
      this.farResume();
    } else {
      if (this.resumeId) {
        this.loadInfoByresumeId(this.resumeId);
      } else {
        setTimeout(() => {
          this.loading = false;
        }, 500);
      }
    }
    this.loadUserBase();
  },
  destroyed() {
    if (!this.isOperation && !this.resumeId) {
      statisticsClickFunctionEscape({
        endTimeStamp: Math.round(new Date().getTime() / 1000),
        entry: this.entry,
        presentStep: "新增人才",
        startTimeStamp: this.startTimeStamp,
        nextStep: this.pointData.endOperation,
      }).then();
    }
  },
};
</script>
<style lang="scss" scoped>
.drawerCon_from_tit {
  margin: 0 0 8px 0 !important;
  background-color: #f5f5f5;
  border: #eee 1px solid;
  // padding: 8px 15px !important;
}
.drawerCon_from .show-more {
  display: block;
  cursor: pointer;
  width: calc(100% - 75px);
}
.show-more span {
  font-weight: normal;
}
.workExperince {
  display: block;
  width: calc(100% - 65px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.repeatCla {
  height: 20px !important;
  line-height: 20px !important;
  color: #606669;
  font-size: 12px;
  // line-height: 1;
  position: absolute;
  top: 100%;
  left: 0;
  .el-button--text {
    margin-right: 10px;
    height: 20px !important;
    line-height: 20px !important;
    padding: 0;
    color: #526ecc;
    text-decoration: underline;
  }
}
.avatartype {
  width: 90px;
  height: 107px;
  display: block;
  border: 1px solid #ccc;
}
.addNewList {
  cursor: pointer;
  // margin-top: 20px;
  text-align: center;
  height: 32px;
  line-height: 32px;
  border: 1px dashed #ededed;
  font-size: 12px;
  color: #999;
}
.addNewList:hover {
  color: #ff3f3f;
  border: 1px dashed #ff3f3f;
}
.titleTagResume {
  margin: 0;
  border-bottom: 1px solid #ededed;
  padding: 10px 0;
  font-weight: normal;
  line-height: 20px;
  background-color: #fff;

  p.show-more {
    font-size: 12px;
    color: #999;
    cursor: pointer;
    margin-top: 0;
    font-weight: 400;
  }

  p.show-more:hover {
    color: #ff3f3f;
  }
  span.title {
    font-size: 14px;
    color: #333;
    font-weight: 700;
  }
}
.titleTagResume:first-child {
  padding-top: 0;
}
div.content {
  padding: 20px 20px 8px 0;
  .el-select,
  .el-cascader {
    width: 100%;
  }
}
div.content-list {
  padding: 15px;
}
div.content-list:last-child {
  padding-bottom: 0;
}
p.normalState {
  font-size: 12px;
  text-indent: 2em;
  color: #999;
  margin-bottom: 10px;
  cursor: pointer;
  text-align: center;
  margin-top: 0;
}
p.normalState:hover {
  color: #ff3f3f;
}
p.showMoreState {
  color: #ff3f3f;
}
.defalutImgType:hover {
  span {
    visibility: visible !important;
  }
}
.jobTitle {
  font-size: 16px;
  color: #333;
  padding-bottom: 10px;
  line-height: 20px;
  font-weight: bold;
  span {
    font-weight: normal;
    font-size: 13px;
    color: #606266;
    margin-left: 10px;
    display: inline-block;
    vertical-align: middle;
  }
}
.leftTit {
  width: 65px;
  line-height: 23px;
  display: inline-block;
}
.fontColor {
  color: #999;
  line-height: 23px;
}
.succUp {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  margin: 0;
  z-index: 9998;
  background-color: rgba(255, 255, 255, 0.8);
  .succUpBox {
    position: fixed;
    z-index: 9999;
    left: calc(50% - 125px);
    top: calc(50% - 100px);
    width: 250px;
    // z-index: 20;
    text-align: center;
    color: #666;
    i {
      margin-bottom: 10px;
    }
    p {
      color: #666;
      font-size: 18px;
      margin: 0;
    }
    .tips {
      font-size: 14px;
      .el-button--text {
        font-size: 14px;
        color: #31a6fe;
        text-decoration: underline;
      }
    }
  }
}
</style>
<style lang="scss">
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.drawerCon .show-more {
  text-align: right;
  // padding-right: 10px;
  color: #606266;
}
.jcTips {
  color: #898989;
  font-size: 18px;
  position: absolute;
  display: inline-block;
  right: -30px;
  top: 10px;
  height: 18px;
  line-height: 18px;
  &:hover {
    color: #e60012;
  }
}
.comList {
  max-height: 400px;
  overflow: hidden;
  overflow-y: scroll;
  li {
    height: 32px;
    line-height: 32px;
    padding: 0 35px 0 15px;
    cursor: pointer;
    &:hover {
      background-color: #f0f0f0;
    }
    .comName {
      width: 80%;
      float: left;
      p {
        max-width: 60%;
        float: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      span {
        height: 20px;
        width: 62px;
        text-align: center;
        line-height: 20px;
        display: inline-block;
        color: #efa14d;
        border: 1px solid #efa14d;
        border-radius: 3px;
        margin-left: 8px;
      }
    }
  }
}

.collapse {
  border: 0;
  .el-collapse-item__wrap {
    border: 0;
  }
  .el-collapse-item {
    border-bottom: 1px dashed #ededed;
    .el-form-item {
      margin-bottom: 0;
    }
  }
  .el-collapse-item__header {
    background-color: #fff;
    border: 0;
  }
  .collapseTit {
    padding-left: 20px;
    width: 100%;
    position: relative;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    .contral {
      float: right;
      font-size: 18px;
      position: absolute;
      right: 0;
      top: 0;
      i {
        color: #999;
        text-indent: 0;
        margin-right: 10px;
        &:hover {
          color: #ff3f3f;
        }
      }
    }
  }
  .collapse_con {
    span {
      &:nth-child(2n) {
        margin: 0 10px;
      }
    }
  }
  .el-collapse-item__wrap {
    border: 0;
  }
}
.eduList {
  padding-inline-start: 0;
  color: #333;
  .el-table__row {
    font-size: 14px;
    padding-left: 10px;
    height: 40px;
    line-height: 40px;
    position: relative;
    border-bottom: 2px dashed #f6f6f6;
    span {
      display: block;
      float: left;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .contral {
      // font-size: 18px;
      i {
        // font-size: 14px !important;
        // visibility:hidden;
        color: #999;
        text-indent: 0;
        margin-right: 10px;
        &:hover {
          color: #ff3f3f;
        }
      }
    }
  }
}
.itemStyle {
  .el-form-item__label,
  .el-form-item__content {
    font-size: 12px;
    color: #666;
  }
}
.eduList {
  .el-table__row:hover {
    background-color: #f6f6f6;
    i {
      visibility: visible !important;
      cursor: pointer;
    }
  }
}
.searchInput {
  .el-input__icon.el-input__validateIcon.el-icon-circle-check,
  .el-input__icon.el-input__validateIcon.el-icon-circle-close {
    visibility: hidden;
  }
}
.contact-people {
  background: #fafafa;
  margin: 0 5px 15px 25px;
  border: 1px #e5e5e5 solid;
  padding: 5px 5px 15px;
  .repeatCla,
  .el-form-item__error {
    height: 18px;
    line-height: 18px;
    padding: 0;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    transition: width 0.5s;
    -webkit-transition: width 0.5s;
  }
  .repeatCla:hover,
  .el-form-item__error:hover {
    width: 245px;
  }
}
.resmeInfo {
  .el-input {
    width: 400px;
  }
}
</style>
