import Vue from 'vue';
import Vuex from 'vuex';
import * as actions from './actions'
import * as getters from './getters'
Vue.use(Vuex);
// 应用初始状态
const state = {
    count: 0,
    approvalcount:0,
    avatar: null,
    userName: null,
    collapsed: false,
    mobile: null,
    messageList: [], // 右上角消息列表
    addresumeStatus: false,
    selfMsgIsShow: false,
    isAddResume: false,
    resumeId: null,
    preCloseTime: null,
    automaticId: null,
    automaticLastCloseTime: null,
    projectClickList: [], // 项目候选人自动匹配表人才详情点击列表
}

// 定义所需的 mutations
const mutations = {
    INCREMENT(state) {
        state.count++
    },
    DECREMENT(state) {
        if (state.count <= 0) {
            return
        }
        state.count--
    },
    UPDATEMOBILE(state) {
        state.mobile = localStorage.getItem('mobile')
    },
    UPDACALLAPSED(state) {
        state.collapsed = !state.collapsed
    },
    UPADDRESUMESTATUS(state) {
        state.addresumeStatus = !state.addresumeStatus
    },
    UPDATEAVATAR(state) {
        state.avatar = localStorage.getItem('avatar')
    },
    UPDATEUSERNAME(state) {
        state.userName = localStorage.getItem('nickName') || "佚名"
    },
    UPDATECOUNT(state, count) {
        state.count = count;
    },
    UPDATEAPPROVALCOUNT(state, count) {
        state.approvalcount = count;
    },
    // RESDATA(state) {
    //     state;
    // },
    UPDATEMESSAGELIST(state, list) {
        state.messageList = list;
    },
    UPDATESELFSTATUS(state, status) {
        state.selfMsgIsShow = status;
    },
    UPDATEADDRESUMSTATUS(state, status) {
        state.isAddResume = status;
    },
    UPDATERESUMID(state, id) {
        state.resumeId = id;
    },
    UPDATERESUMPRECLOSETIME(state, time) {
        state.preCloseTime = time;
    },
    UPDATEAUTOMATICID(state, id) {
        state.automaticId = id;
    },
    UPDATEAUTOMATICLASTCLOSETIME(state, time) {
        state.automaticLastCloseTime = time;
    },
    UPDATEPROJECTCLICKLIST(state, list) {
        state.projectClickList = list;
    },
}

// 创建 store 实例
export default new Vuex.Store({
    actions,
    getters,
    state,
    mutations
})