<template>
  <section class="addContract">
    <div class="drawerCon_from">
      <el-form
        @submit.native.prevent
        :model="contract"
        ref="contract"
        :rules="rules"
        label-width="110px"
        class="addForm"
      >
        <div class="titleTagResume">
          <span class="title">基础信息</span>
        </div>
        <el-row :gutter="24">
          <el-col :offset="1" :span="10">
            <el-form-item label="合同名称：" prop="name">
              <el-input
                v-model.trim="contract.name"
                placeholder="请不要输入超过30个字"
                maxlength="30"
                id="addContractName"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :offset="1" :span="10">
            <el-form-item label="客户公司：" prop="companyId">
              <el-select
                v-model="contract.companyId"
                multiple
                filterable
                clearable
                collapse-tags
                placeholder="请选择"
                :disabled="disabled"
                style="width: 100%"
                class="untSel"
                id="addCompanyId"
              >
                <el-option
                  v-for="i in ComBaseList"
                  :key="i.id"
                  :label="i.name"
                  :value="i.id"
                  :id="'addCompanyId' + i.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :offset="1" :span="10">
            <el-form-item label="签约公司：" prop="signCompanyId">
              <el-select
                v-model="contract.signCompanyId"
                placeholder="请选择"
                style="width: 100%"
                id="addContractSignCompanyId"
              >
                <el-option
                  v-for="i in signComList"
                  :key="i.id"
                  :label="i.name"
                  :value="i.id"
                  :id="'addContractSignCompanyId' + i.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :offset="1" :span="10" class="islong">
            <el-form-item
              label="合同有效期："
              prop
              class="is-required"
              style="margin-bottom: 0 !important"
            >
              <el-row style="position: relative">
                <el-col :span="11">
                  <el-form-item label-width="0" prop="startDate">
                    <el-date-picker
                      v-model="contract.startDate"
                      type="date"
                      :picker-options="startTime"
                      placeholder="请选择开始时间"
                      value-format="yyyy/MM/dd"
                      style="width: 100%"
                      id="addContractStartDate"
                    ></el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="2" style="text-align: center">-</el-col>
                <el-col :span="11">
                  <el-form-item label-width="0" prop="endDate">
                    <el-date-picker
                      v-model="contract.endDate"
                      type="date"
                      :picker-options="endTime"
                      placeholder="请选择结束时间"
                      :disabled="desabled"
                      value-format="yyyy/MM/dd"
                      style="width: 100%"
                      id="addContractEndDate"
                    ></el-date-picker>
                  </el-form-item>
                </el-col>
                <el-checkbox
                  class="long"
                  v-model="checked"
                  @change="Haschecked($event)"
                  id="addContractNoDeadline"
                  >无期限</el-checkbox
                >
              </el-row>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :offset="1" :span="21">
            <el-form-item label="保证期：" prop="guaranteePeriod">
              <el-input
                v-model.trim="contract.guaranteePeriod"
                placeholder="请输入"
                maxlength="150"
                id="addContractGuaranteePeriod"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :offset="1" :span="21">
            <el-form-item label="收费标准：" prop="feeStandard">
              <el-input
                v-model.trim="contract.feeStandard"
                placeholder="请输入"
                maxlength="150"
                id="addContractFeeStandard"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :offset="1" :span="21">
            <el-form-item label="付款周期：" prop="paymentCycle">
              <div style="display: flex; align-items: center">
                <el-input
                  v-model.trim="contract.paymentCycle"
                  placeholder="填写示例：入职后首付款70%，收到发票确认无误后30天内；保证期满开具发票，收到发票后7日内支付（保证期6个月）"
                  maxlength="150"
                  id="addContractPaymentCycle"
                  style="margin-right: 15px"
                ></el-input>
                <el-tooltip
                  content="填写示例： 入职15个工作日付60%，保证期后7个工作日付40%（1.候选人年薪≤30万，保证期为_3个月；2.候选人年薪>30万，保证期为6个月）"
                  placement="bottom"
                  effect="dark"
                >
                  <i
                    class="iconfont icon icon-wenhao-xiangsu"
                    style="margin-right: -30px"
                  ></i>
                </el-tooltip>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :offset="1" :span="21">
            <el-form-item prop="mail" label="是否需要邮寄：">
              <el-radio-group
                v-model="contract.sendMail"
                id="addContractSendMail"
              >
                <el-radio :label="true">需要邮寄</el-radio>
                <el-radio :label="false">不需要邮寄</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :offset="1" :span="21">
            <el-form-item label="合同附件：" class="is-required">
              <el-upload
                ref="upload"
                class="upload-demo uploadContract"
                drag
                :headers="myHeaders"
                :action="actionUrl"
                :before-upload="beforeUpload"
                :on-success="handleInfoSuccess"
                :on-error="handleInfoError"
                :file-list="fileInfoList"
                :on-remove="handleRemove"
                accept=".zip,.doc,.docx,.pdf,.jpg"
                id="addContractUploadContract"
              >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                  将文件拖到此处，或 <span class="tabHref">点击上传</span>
                </div>
                <div
                  slot="tip"
                  style="margin-top: -15px; font-size: 12px; color: #999"
                >
                  文件类型支持docx，doc，pdf，jpg，zip,支持上传多份文件,单个文件不超过20M
                </div>
              </el-upload>
              <a
                class="tempList"
                v-for="(i, index) in TemplateList"
                :key="index"
                @click="downSingFile(i.fileName, i.filePath)"
                >{{ i.name }}</a
              >
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :offset="1" :span="21">
            <el-form-item label="先章方：" prop="firstSeal">
              <el-input
                v-model.trim="contract.firstSeal"
                placeholder="请输入"
                maxlength="500"
                :rows="4"
                type="textarea"
                id="addContractFirstSeal"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :offset="1" :span="21">
            <el-form-item label="备注：" prop="remark">
              <el-input
                v-model.trim="contract.remark"
                placeholder="请输入"
                maxlength="500"
                :rows="4"
                type="textarea"
                id="addContractRemark"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </section>
</template>

<script>
import {
  getCommonSignCompanyList,
  getCompanyBaseInfos,
  addOrEditContract,
  GetCompanyeditInfo, //通过id获取合同信息
} from "../../api/api";
import {
  statisticsContractAddSuccess, //新增合同成功
} from "../../api/buryingPoint";
import { ApiBaseUrl } from "@/api/http";
import Cookies from "js-cookie";
export default {
  props: ["customId", "contractId", "status", "isQuick", "entry"],
  data() {
    return {
      actionUrl: ApiBaseUrl + "/api/file/uploadOne",
      myHeaders: {
        Authorization: Cookies.get("accessToken"),
      },
      BaseUrl: ApiBaseUrl,
      fileInfoList: [],
      disabled: false,
      showPopover: false,
      selectComValue: "",
      checked: false,
      desabled: false,
      saveloading: false,
      signComList: [], //签约公司
      ComBaseList: [], //客户列表
      TemplateList: [], //模板列表
      contract: {
        companyId: [],
        sendMail: false,
        endDate: "",
        feeStandard: "",
        guaranteePeriod: "",
        id: "",
        name: "",
        paymentCycle: "",
        remark: "",
        firstSeal: "",
        signCompanyId: "",
        startDate: "",
        status: 0,
        version: 0,
      },
      fileList: [],
      startTime: {
        disabledDate: (time) => {
          let endDateVal = this.contract.endDate;
          if (endDateVal) {
            return time.getTime() > new Date(endDateVal).getTime();
          }
        },
      },
      endTime: {
        disabledDate: (time) => {
          let beginDateVal = this.contract.startDate;
          if (beginDateVal) {
            return (
              time.getTime() < new Date(beginDateVal).getTime() ||
              time.getTime() < Date.now() - 1 * 24 * 60 * 60 * 1000
            );
          } else {
            return time.getTime() < Date.now() - 1 * 24 * 60 * 60 * 1000;
          }
        },
      },
      rules: {
        name: [
          {
            required: true,
            trigger: "blur",
            message: "请填写合同名称",
          },
        ],
        companyId: [
          {
            required: true,
            trigger: "change",
            message: "请选择客户公司名称",
          },
        ],
        signCompanyId: [
          {
            required: true,
            trigger: "change",
            message: "请选择签约公司",
          },
        ],
        guaranteePeriod: [
          {
            required: true,
            trigger: "blur",
            message: "请填写保证期",
          },
        ],
        paymentCycle: [
          {
            required: true,
            trigger: "blur",
            message: "请填写付款周期",
          },
        ],
        feeStandard: [
          {
            required: true,
            trigger: "blur",
            message: "请填写收费标准",
          },
        ],
        firstSeal: [
          {
            required: true,
            trigger: "blur",
            message: "请填写先章方",
          },
        ],
      },
    };
  },
  watch: {
    status(newValue, oldValue) {
      this.contract.status = newValue;
    },
  },
  methods: {
    resetSearch() {},
    checkValue() {
      this.contract.companyId = this.selectComValue.name;
      this.showPopover = false;
    },
    downSingFile(name, path) {
      location.href =
        this.BaseUrl +
        "/api/file/download?fileName=" +
        encodeURIComponent(name) +
        "&filePath=" +
        path;
    },
    handleInfoSuccess(res, file, fileList) {
      this.fileList = [];
      fileList.forEach((item) => {
        if (item.response) {
          this.fileList.push({
            fileName: item.name,
            filePath: item.response.result,
          });
        } else {
          this.fileList.push({
            fileName: item.name,
            filePath: item.filepath,
          });
        }
      });
    },
    handleInfoError(err) {
      this.$message.error("文件上传出错");
    },
    beforeUpload: function (file) {
      const isLt = file.size / 1024 / 1024 < 20;
      if (!isLt) {
        this.$message.error("上传文件大小不能超过 20MB!");
      }
      return isLt;
    },
    handleRemove(file, fileList) {
      this.fileList = [];
      fileList.forEach((item) => {
        this.fileList.push({
          fileName: item.name,
          filePath: item.response.result,
        });
      });
    },
    loadSignCom() {
      //加载签约公司
      getCommonSignCompanyList().then((res) => {
        if (res.success) {
          this.signComList = res.result;
        }
      });
    },
    loadComBaseList() {
      //获取客户列表
      getCompanyBaseInfos().then((res) => {
        if (res.success) {
          this.ComBaseList = res.result;
        }
      });
    },
    Haschecked(e) {
      //无限期
      this.desabled = e;
      this.contract.endDate = "";
    },
    save(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (
            this.contract.startDate == "" ||
            this.contract.startDate == null
          ) {
            this.$message.error("请选择合同开始日期");
            return false;
          }
          if (
            (this.contract.endDate == "" || this.contract.endDate == null) &&
            this.checked == false
          ) {
            this.$message.error("请选择合同截止日期");
            return false;
          }
          if (this.fileList == null || this.fileList.length == 0) {
            this.$message.error("请上传合同附件");
            return false;
          }
          this.$emit("changeSaveLoading", true);
          const { companyId, ...restContract } = this.contract;

          addOrEditContract({
            contract: restContract,
            companyIds: this.contract.companyId,
            fileList: this.fileList,
            invoiceList: this.invoiceList,
          }).then((res) => {
            if (res.success) {
              statisticsContractAddSuccess({
                entry: this.entry,
                contractId: res.result,
              }).then();
              this.$message.success(res.message);
              if (this.isQuick) {
                //快速操作
                this.$emit("cancel");
                setTimeout(() => {
                  this.$router.push({
                    path: "/contracts",
                    query: { t: +Date.now() },
                  });
                }, 100);
              } else {
                this.resetForm("contract");
                this.removeStore("Invoice");
                this.invoiceList = [];
                this.fileList = [];
                this.TemplateList = [];
                this.fileInfoList = [];
                this.contract.sendMail = false;
                this.disabled = false;
                this.checked = false;
                this.$emit("loadList");
              }
            }
            this.$emit("changeSaveLoading", false);
            // this.saveloading = false;
          });
        }
      });
    },
    handleClose(done) {
      done();
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    loadContractInfo() {
      //加载合同信息
      GetCompanyeditInfo(this.contractId).then((res) => {
        if (res.success) {
          this.fileInfoList = [];
          //this.contract=res.result.contract//合同基本信息
          this.invoiceList = res.result.invoiceList; //开票信息
          // this.fileList = res.result.fileList; //合同附件
          this.contract.companyId = res.result.companyIds;
          this.contract.endDate = res.result.contract.endDate;
          this.contract.feeStandard = res.result.contract.feeStandard;
          this.contract.guaranteePeriod = res.result.contract.guaranteePeriod;
          this.contract.id = res.result.contract.id;
          this.contract.name = res.result.contract.name;
          this.contract.paymentCycle = res.result.contract.paymentCycle;
          this.contract.firstSeal = res.result.contract.firstSeal;
          this.contract.remark = res.result.contract.remark;
          this.contract.signCompanyId = res.result.contract.signCompanyId;
          this.contract.startDate = res.result.contract.startDate;
          this.contract.version = res.result.contract.version;
          this.contract.sendMail = res.result.contract.sendMail;
          if (!res.result.contract.endDate) {
            this.desabled = true;
            this.checked = true;
          }
          // res.result.fileList.forEach((item) => {
          //   this.fileInfoList.push({
          //     name: item.fileName,
          //     filepath: item.filePath,
          //   });
          // });
        }
      });
    },
  },
  created() {
    this.loadSignCom(); //加载签约公司
    this.loadComBaseList(); //加载客户公司
    this.invoiceList = [];
    if (this.status) {
      this.contract.status = this.status;
    }

    if (this.customId) {
      this.contract.companyId = [Number(this.customId)];
      this.disabled = true;
    }
    if (this.contractId) {
      //合同id 加载合同信息
      this.loadContractInfo();
    }
  },
};
</script>
<style lang="scss" scoped>
.long {
  position: absolute;
  right: -85px;
}
.addForm {
  // border: 1px solid #ededed;
  .titleTagResume {
    // margin-top: 0;
    // padding: 5px 15px;
    // font-weight: normal;
    // padding-top: 5px !important;
    // background-color: #f5f5f5;
    // border-top: 1px solid #e5e5e5;
    // border-bottom: 1px solid #e5e5e5;
    // color: #606266;
    // margin: 0;
    // border-bottom: 1px solid #ededed;
    // padding: 10px 0;
    // font-weight: normal;
    // line-height: 20px;
    // background-color: #fff;
    height: 35px;
    line-height: 35px;
    font-size: 14px;
    font-weight: bold;
    color: #606266;
    text-align: left;
    padding: 0 15px;
    margin: 0 0 20px;
    background-color: #f5f5f5;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
    p.show-more {
      font-size: 12px;
      color: #999;
      cursor: pointer;
      float: right;
      margin-top: 0;
      font-weight: 400;
    }
    p.show-more:hover {
      color: #ff3f3f;
    }
    span.title {
      font-size: 14px;
      color: #606266;
      font-weight: 700;
    }
  }
}
.tempList {
  padding: 0 10px;
  display: block;
  line-height: 1;
  float: left;
  color: #666;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: #ff3f3f;
  }
}
.add {
  width: 100%;
  height: 32px;
  text-align: center;
  line-height: 32px;
  font-size: 12px;
  border: 1px dashed #e7e5e4;
  color: #999;
  margin: 40px auto;
  display: block;
  cursor: pointer;
}
.add:hover {
  color: #ff3f3f;
  border: 1px dashed #ff3f3f;
}
.inoviceList {
  padding-top: 10px;
  li {
    margin-bottom: 20px;
    position: relative;
    border: 1px solid #ededed;
    border-left: 3px solid #777788;
    .item-btn {
      visibility: hidden;
      position: absolute;
      right: 12px;
      top: 0;
      bottom: 0;
      background-color: #f9f9f9;
      cursor: pointer;
      .icon {
        text-align: center;
        font-size: 18px;
        height: 100%;
        i {
          // display: block;
          height: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        i:hover {
          color: #ff3f3f;
        }
      }
    }
    .info {
      padding-left: 10px;
      padding-bottom: 10px;
      .el-col {
        margin-top: 10px;
      }
    }
  }
  li:hover {
    .item-btn {
      visibility: visible;
    }
  }
}
p.show-more {
  font-size: 14px;
  color: #bbb;
  cursor: pointer;
  float: right;
  margin-top: 0;
  font-weight: 400;
}
p.show-more:hover {
  color: #ff3f3f;
}
.titleTagResume:first-child {
  padding-top: 0;
}
</style>
<style lang='scss' scoped>
.addContract {
  .el-select :deep(.el-select__tags-text) {
    /* width: 200px !important; */
    display: inline-block;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .el-select :deep(.el-tag__close.el-icon-close) {
    // top: -7px;
  }
  .el-select :deep(.el-tag) {
    margin-left: 6px;
  }
}

.radioBox {
  height: 300px;
  overflow-y: auto;
  width: 100%;
}
</style>
<style lang="scss">
.untSel {
  .el-tag.el-tag--info {
    max-width: 170px;
    display: flex;
    align-items: center;
    .el-select__tags-text {
      max-width: 120px;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
.uploadContract {
  width: 100%;
  .el-upload.el-upload--text {
    width: 100%;
    line-height: 1;
    .tips {
      margin-top: 10px;
    }
    .el-upload-dragger {
      width: 100%;
    }
  }
}
.islong {
  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #606266 !important;
  }
}
</style>
