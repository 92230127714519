//test
export const getCount = state => {
    return state.count
}

export const getApprovalCount = state => {
    return state.approvalcount
}

export const upDateUserName = state => {
    return state.userName
}

export const getMessageList = state => {
    return state.messageList
}
export const upDateCollapsed = state => {
    return state.collapsed
}
export const upStatusAddResume = state => {
    return state.addresumeStatus
}
export const upDateMobile = state => {
    return state.mobile
}
export const upDateAvatar = state => {
    return state.avatar
}
export const upDateSelfStatus = state => {
    return state.selfMsgIsShow
}
export const upDateAddResumeStatus = state => {
    return state.isAddResume
}
export const upDateResumeId = state => {
    return state.resumeId
}
export const upDateResumePreCloseTime = state => {
    return state.preCloseTime
}
export const upDateAutomaticId = state => {
    return state.automaticId
}
export const upDateAutomaticLastCloseTime = state => {
    return state.automaticLastCloseTime
}
export const upDateProjectClickList = state => {
    return state.projectClickList
}
