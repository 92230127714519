<template>
  <div
    :class="{ fullscreen: fullscreen }"
    class="tinymce-container"
    :style="{ width: containerWidth }"
  >
    <textarea
      :id="tinymceId"
      class="tinymce-textarea"
      :placeholder="placeholder"
    ></textarea>
    <div class="editor-custom-btn-container">
      <editorImage
        color="#1890ff"
        :ishowAnnexes="ishowAnnexes"
        :ishowVariables="ishowVariables"
        :ishowImage="ishowImage"
        :onlyImage="onlyImage"
        @showTheDig="showTheDig"
        class="editor-upload-btn"
        ref="imgAnli"
        @successCBK="imageSuccessCBK"
        @annex="annexList"
      />
    </div>
  </div>
</template>

<script>
/**
 * docs:
 * https://panjiachen.github.io/vue-element-admin-site/feature/component/rich-editor.html#tinymce
 */
import editorImage from "./components/EditorImage";
import plugins from "./plugins";
import toolbar from "./toolbar";
import { fileFploadOne } from "../../api/api";
import * as api from "../../api/http";
// import tinymceCDN from'./tinymce'
// why use this cdn, detail see https://github.com/PanJiaChen/tinymce-all-in-one
const tinymceCDN =
  // window.location.origin +"/tinymce/tinymce.min.js";
  "https://cdn.jsdelivr.net/npm/tinymce-all-in-one@4.9.3/tinymce.min.js";
// const tinymceCDN =tinymceCDN;

export default {
  name: "Tinymce",
  components: { editorImage },
  props: {
    id: {
      type: String,
      default: function () {
        return (
          "vue-tinymce-" +
          +new Date() +
          ((Math.random() * 1000).toFixed(0) + "")
        );
      },
    },
    value: {
      type: String,
      default: "",
    },
    toolbar: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    menubar: {
      type: String,
      // default: 'file edit insert view format table'
      default: "",
    },
    height: {
      type: [Number, String],
      required: false,
      default: 360,
    },
    width: {
      type: [Number, String],
      required: false,
      default: "auto",
    },
    ishowImage: {
      type: Boolean,
      required: false,
      default: false,
    },
    ishowAnnexes: {
      type: Boolean,
      required: false,
      default: false,
    },
    ishowVariables: {
      type: Boolean,
      required: false,
      default: false,
    },
    onlyImage: {
      type: Boolean,
      required: false,
      default: false,
    },
    placeholder: {
      type: String,
      // default: 'file edit insert view format table'
      default: "",
    },
  },
  data() {
    return {
      hasChange: false,
      hasInit: false,
      tinymceId: this.id,
      fullscreen: false,
      languageTypeList: {
        en: "en",
        zh: "zh_CN",
        es: "es_MX",
        ja: "ja",
      },
    };
  },
  computed: {
    containerWidth() {
      const width = this.width;
      if (/^[\d]+(\.[\d]+)?$/.test(width)) {
        // matches `100`, `'100'`
        return `${width}px`;
      }
      return width;
    },
  },
  watch: {
    value(val) {
      if (!this.hasChange && this.hasInit) {
        this.$nextTick(() =>
          window.tinymce.get(this.tinymceId).setContent(val || "")
        );
      }
    },
  },
  mounted() {
    this.init();
  },
  activated() {
    if (window.tinymce) {
      this.initTinymce();
    }
  },
  deactivated() {
    this.destroyTinymce();
  },
  destroyed() {
    this.destroyTinymce();
  },
  methods: {
    init() {
      // dynamic load tinymce from cdn
      // load(tinymceCDN, (err) => {
      //   if (err) {
      //     this.$message.error(err.message);
      //     return;
      //   }
      //   this.initTinymce();
      // });
      this.initTinymce();
    },
    handleAnRemove(data) {
      this.$refs.imgAnli.handleAnRemove(data);
    },
    initTinymce() {
      const _this = this;
      tinymce.init({
        selector: `#${this.tinymceId}`,
        language: this.languageTypeList["zh"],
        height: this.height,
        body_class: "panel-body ",
        object_resizing: false,
        toolbar: this.onlyImage
          ? []
          : this.toolbar.length > 0
          ? this.toolbar
          : toolbar,
        menubar: this.menubar,
        plugins: plugins,
        end_container_on_empty_block: true,
        powerpaste_word_import: "clean",
        branding: false, //隐藏tinymce右下角水印
        code_dialog_height: 450,
        code_dialog_width: 1000,
        paste_data_images: true,
        fontsize_formats: "12px 14px 16px 18px 24px 36px 48px 56px 72px",
        imagetools_cors_hosts: [api.ApiBaseUrl],
        imagetools_proxy: "proxy.php",
        paste_preprocess: function (plugin, args) {
          if (args.content.search(/<img /i) >= 0) {
            args.content = "";
          }
        },
        style_formats: [
          {
            title: "行高",
            items: [
              { title: "1", block: "p", styles: { "line-height": "1.0" } },
              { title: "1.5", block: "p", styles: { "line-height": "1.5" } },
              { title: "2", block: "p", styles: { "line-height": "2.0" } },
              { title: "3", block: "p", styles: { "line-height": "3.0" } },
              { title: "4", block: "p", styles: { "line-height": "4.0" } },
              { title: "5", block: "p", styles: { "line-height": "5.0" } },
            ],
          },
        ],
        style_formats_merge: false,
        style_formats_autohide: false,

        // advlist_bullet_styles: 'square',
        // advlist_number_styles: 'default',
        // imagetools_cors_hosts: ['www.tinymce.com', 'codepen.io'],
        default_link_target: "_blank",
        link_title: false,
        nonbreaking_force_tab: true, // inserting nonbreaking space &nbsp; need Nonbreaking Space Plugin
        init_instance_callback: (editor) => {
          if (_this.value) {
            editor.setContent(_this.value);
          }
          _this.hasInit = true;
          editor.on("NodeChange Change KeyUp SetContent", () => {
            this.hasChange = true;
            this.$emit("input", editor.getContent());
          });
          editor.on("paste", (evt) => {
            // 监听粘贴事件
            this.onPaste(evt);
          });
        },
        setup(editor) {
          editor.on("FullscreenStateChanged", (e) => {
            _this.fullscreen = e.state;
          });
        },
        // 整合七牛上传
        // images_dataimg_filter(img) {
        //   setTimeout(() => {
        //     const $image = $(img);
        //     $image.removeAttr('width');
        //     $image.removeAttr('height');
        //     if ($image[0].height && $image[0].width) {
        //       $image.attr('data-wscntype', 'image');
        //       $image.attr('data-wscnh', $image[0].height);
        //       $image.attr('data-wscnw', $image[0].width);
        //       $image.addClass('wscnph');
        //     }
        //   }, 0);
        //   return img
        // },
        // images_upload_handler(blobInfo, success, failure, progress) {
        //   progress(0);
        //   const token = _this.$store.getters.token;
        //   getToken(token).then(response => {
        //     const url = response.data.qiniu_url;
        //     const formData = new FormData();
        //     formData.append('token', response.data.qiniu_token);
        //     formData.append('key', response.data.qiniu_key);
        //     formData.append('file', blobInfo.blob(), url);
        //     upload(formData).then(() => {
        //       success(url);
        //       progress(100);
        //     })
        //   }).catch(err => {
        //     failure('出现未知问题，刷新页面，或者联系程序员')
        //   });
        // },
      });
    },
    onPaste(event) {
      // 实现图片粘贴上传
      const items = (event.clipboardData || window.clipboardData).items;

      // 搜索剪切板items 只取第一张
      if (items[0].type.indexOf("image") !== -1) {
        const file = items[0].getAsFile();
        const formData = new FormData();
        formData.append("file", file);
        // 上传图片
        fileFploadOne(formData).then((res) => {
          if (res.code === 200) {
            // 放到内容当中
            let url = api.ApiBaseUrl + "/pics/temporary/" + res.result;
            window.tinymce
              .get(this.tinymceId)
              .insertContent(`<img src="${url}" >`);
          } else {
            this.$message.error("图片上传失败,联系开发人员");
          }
        });
      }
    },
    destroyTinymce() {
      const tinymce = window.tinymce.get(this.tinymceId);
      if (this.fullscreen) {
        tinymce.execCommand("mceFullScreen");
      }

      if (tinymce) {
        tinymce.destroy();
      }
    },
    setContent(value) {
      window.tinymce.get(this.tinymceId).setContent(value);
    },
    getContent() {
      window.tinymce.get(this.tinymceId).getContent();
    },
    annexList(arr) {
      this.$emit("annexList", arr);
    },
    imageSuccessCBK(arr) {
      const _this = this;
      arr.forEach((v) => {
        window.tinymce
          .get(_this.tinymceId)
          .insertContent(`<img class="wscnph" src="${v.url}" >`);
      });
    },
    showTheDig() {
      this.$emit("showTheDig", "isrichText");
    },
    addNewText(val) {
      window.tinymce.activeEditor.execCommand("mceInsertContent", false, val);
    },
    setData(val) {
      this.$refs.imgAnli.setData(val);
    },
  },
};
</script>

<style lang='scss' scoped>
.tinymce-container {
  position: relative;
  line-height: normal;
  :deep(.mce-panel) {
    box-shadow: none;
    // border: 1px solid #DCDFE6;
  }
}
.tinymce-container :deep(.mce-fullscreen) {
  z-index: 10000;
}
.tinymce-textarea {
  visibility: hidden;
  z-index: -1;
}
.editor-custom-btn-container {
  position: absolute;
  right: 4px;
  top: 4px;
  font-size: 16px;
  width: 140px;
  /*z-index: 2005;*/
}
.fullscreen .editor-custom-btn-container {
  z-index: 10000;
  position: fixed;
}
:deep(.mce-ico) {
  color: #999;
}
:deep(.mce-menubar) {
  border-width: 0 !important;
}
</style>
