<template>
  <div style="padding-bottom: 40px">
    <div>
      <el-button size="mini" @click="addNew">新增合同</el-button>
      <el-pagination
        :hide-on-single-page="filter.page.total > 10 ? false : true"
        v-if="filter.page.total > 0"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="filter.page.current"
        :page-sizes="[10, 20, 50, 100]"
        :pager-count="5"
        :page-size="filter.page.size"
        layout="total,prev, pager, next,sizes"
        :total="filter.page.total"
      ></el-pagination>
    </div>
    <el-table
      :header-cell-style="{
        'background-color': '#f5f7fa',
      }"
      :data="ListData"
      class="tabBorder"
      style="width: 100%"
      v-loading="listLoading"
    >
      <el-table-column label="合同编号" width="130">
        <template slot-scope="scope">
          {{ scope.row.number ? scope.row.number : "-" }}
        </template>
      </el-table-column>
      <el-table-column
        label="合同名称"
        prop="name"
        min-width="100"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column label="客户公司" min-width="240">
        <template slot-scope="scope">
          <span v-for="(i, index) in scope.row.company" :key="index">
            <span class="tabHref" :key="i.id" @click="toCustomer(i.id)">{{
              i.companyName
            }}</span
            ><span v-if="index != scope.row.company.length - 1">、</span>
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="合同状态" width="180">
      </el-table-column>
      <el-table-column label="有效期" width="260">
        <template slot-scope="scope">
          <span
            >{{
              scope.row.startDate
                ? scope.row.startDate + " 至 "
                : scope.row.startDate
            }}{{ scope.row.endDate ? scope.row.endDate : "无期限" }}</span
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="creatorUserName"
        show-overflow-tooltip
        label="创建人"
        width="180"
      >
      </el-table-column>
      <el-table-column label="创建时间" width="180">
        <template slot-scope="scope">
          {{ scope.row.creationTime ? scope.row.creationTime : "-" }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="80"
        ><template slot-scope="scope">
          <span class="tabHref" @click="detailsPop(scope.row.id)">查看</span>
        </template></el-table-column
      >
    </el-table>
    <el-pagination
      :hide-on-single-page="filter.page.total > 10 ? false : true"
      v-if="filter.page.total > 0"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="filter.page.current"
      :page-sizes="[10, 20, 50, 100]"
      :pager-count="5"
      :page-size="filter.page.size"
      layout="total,prev, pager, next,sizes"
      :total="filter.page.total"
    ></el-pagination>
    <el-drawer
      :visible.sync="drawerVisible"
      custom-class="drawerClass"
      direction="ttb"
      :modal="false"
      append-to-body
      :before-close="handleClose"
    >
      <div slot="title" class="drawerTitle">新增合同</div>
      <div class="drawerCon">
        <add-contract
          ref="saveInfo"
          :customId="cusId"
          @changeSaveLoading="changeSaveLoading"
          @loadList="loadList"
          entry="客户详情-合同tab"
        ></add-contract>
        <div class="drawerCon_footer fixedFoot">
          <el-button
            type="default"
            plain
            size="mini"
            @click="drawerVisible = false"
            >取消</el-button
          >
          <el-button
            type="primary"
            size="mini"
            style="margin-right"
            :loading="saveloading"
            @click="save()"
            >保存</el-button
          >
        </div>
      </div>
    </el-drawer>
    <!-- 查看 -->
    <el-drawer
      :visible.sync="isCheckInfo"
      custom-class="drawerClass"
      direction="ttb"
      :modal="false"
      append-to-body
    >
      <div slot="title" class="drawerTitle">
        <i class="drawIcon iconfont icon-ht"></i>合同详情
      </div>
      <div class="drawerCon">
        <contractInfo
          v-if="isCheckInfo"
          :contractId="contractId"
          :activeName="'创建'"
          @loadList="loadList()"
        ></contractInfo>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { getContractList, getIsSettingApproval } from "../../api/api";
import {
  statisticsContractAdd, //点击新增合同
} from "../../api/buryingPoint";
import addContract from "../contract/addContract";
import contractInfo from "../contract/contractInfo";
export default {
  name: "cusToContract",
  props: ["cusId"],
  components: { addContract, contractInfo },
  data() {
    return {
      drawerVisible: false,
      isCheckInfo: false,
      contractId: null,
      saveloading: false,
      filter: {
        companyId: 0,
        companyName: "",
        contractNumber: "",
        contractStatus: [],
        createdUserIds: [],
        filter: "",
        listType: "",
        page: {
          current: 1,
          size: 50,
          total: 0,
        },
      },
      ListData: [],
      listLoading: false,
    };
  },
  created() {
    this.loadList();
  },
  methods: {
    //保存新增
    save() {
      this.$refs.saveInfo.save("contract");
    },
    //保存修改
    changeSaveLoading(val) {
      this.saveloading = val;
    },
    addNew() {
      statisticsContractAdd({ entry: "客户详情-合同tab" }).then();
      getIsSettingApproval().then((res) => {
        if (res.success) {
          if (res.result) {
            this.drawerVisible = true;
          } else {
            this.$alert(
              "你的账户角色尚未配置合同审批流程，无法创建合同，请联系系统管理员配置",
              "提示",
              {
                confirmButtonText: "确定",
                customClass: "altCls",
                callback: (action) => {},
              }
            );
          }
        }
      });
    },
    handleClose() {
      this.drawerVisible = false;
    },
    detailsPop(id) {
      //跳详情
      this.contractId = id;
      this.isCheckInfo = true;
    },
    //客户详情
    toCustomer(comId) {
      if (comId == this.cusId) {
        //客户id为当前
        this.$emit("toCusInfo");
      } else {
        this.$router.push({
          path: "/customer",
          query: {
            comId: comId,
          },
        });
      }
    },
    loadList() {
      this.listLoading = true;
      this.drawerVisible = false;
      this.filter.companyId = this.cusId;
      this.$emit("loadCount");
      getContractList(this.filter).then((res) => {
        if (res.success) {
          this.listLoading = false;
          this.ListData = res.result.records;
          this.filter.page.total = res.result.total;
        }
        this.listLoading = false;
      });
    },
    handleSizeChange(val) {
      //pageSize 改变时会触发
      this.filter.page.size = val;
      this.filter.page.current = 1;
      this.loadList();
    },
    handleCurrentChange(val) {
      //currentPage 改变时会触发
      this.filter.page.current = val;
      this.loadList();
    },
  },
};
</script>

<style lang="scss" scoped>
.borderR {
  border-right: 1px solid #e5e5e5;
  padding-right: 8px;
  margin-right: 8px;
}
</style>