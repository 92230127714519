 <template>
  <section>
    <el-form
      @submit.native.prevent
      :model="offerForm"
      label-width="120px"
      ref="offerForm"
      :rules="offerFormRule"
      class="clearfix offerFrom"
    >
      <p class="tips" v-if="businessType!=='WHITE_COLLAR'">
        <i class="el-icon-warning"></i
        >提示：该候选人已发过喜报，可在【业绩池】查询
      </p>
      <div class="searchList">
        <el-collapse
          accordion
          v-model="activeName"
          class="scoreTitle"
          style="border-bottom: 0 !important"
        >
          <el-collapse-item name="score">
            <template slot="title">
              <div class="scoreTotal">
                Offer薪资<span>（将自动生成在候选人简历里）</span>
              </div>
            </template>
            <div style="margin: 20px">
              <el-table
                :data="offerForm.offerSalaries"
                border
                stripe
                style="width: 100%"
                align="center"
              >
                <el-table-column
                  prop="structure"
                  label="薪资结构"
                  align="center"
                >
                  <template slot-scope="scope">
                    <el-input
                      v-if="
                        scope.row.structure !== '基本薪资' &&
                        scope.row.structure !== '补贴' &&
                        scope.row.structure !== '股权' &&
                        scope.row.structure !== '固定奖金' &&
                        scope.row.structure !== null
                      "
                      v-model.trim="scope.row.structure"
                    />
                    <el-select
                      v-model="scope.row.structure"
                      placeholder="请选择"
                      v-else
                      style="width: 100%"
                    >
                      <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="amount"
                  label="金额（元）"
                  align="center"
                >
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="'offerSalaries[' + scope.$index + '].amount'"
                      label-width="0"
                      style="margin: 15px 0"
                      :rules="[
                        {
                          required: true,
                          message: '请输入金额，仅限两位小数',
                          trigger: 'blur',
                        },
                        {
                          pattern: /^\d{1,9}(\.\d{1,2})?$/,
                          message: '精确到小数点后两位',
                        },
                      ]"
                    >
                      <el-input
                        type="number"
                        @mousewheel.native.prevent
                        oninput="if(value.length > 9) value=value.slice(0, 9)"
                        v-model="scope.row.amount"
                      />
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column prop="count" label="数量" align="center">
                  <template slot-scope="scope">
                    <el-input
                      style="width: 80%"
                      type="number"
                      v-model="scope.row.count"
                      oninput="value=value.replace(/[^\d.]/g,'')"
                    />
                  </template>
                </el-table-column>
                <el-table-column prop="totalAmount" label="总额" align="center">
                  <template slot-scope="scope">
                    <span>{{
                      (scope.row.totalAmount = (
                        Number(scope.row.amount) * Number(scope.row.count)
                      ).toFixed(2))
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="操作" width="100">
                  <template slot-scope="scope">
                    <span class="tabHref" @click="addNewTable">增加</span>
                    <el-divider
                      direction="vertical"
                      v-if="scope.$index !== 0"
                    ></el-divider>
                    <span
                      class="tabHref"
                      v-if="scope.$index !== 0"
                      @click="delTable(scope.$index)"
                      >删除</span
                    >
                  </template>
                </el-table-column>
              </el-table>
              <p>总计：{{ offertotal }}</p>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="searchList">
        <el-collapse
          accordion
          v-model="activeNames1"
          class="scoreTitle"
          style="border-bottom: 0 !important"
        >
          <el-collapse-item name="score">
            <template slot="title">
              <div class="scoreTotal">offer信息</div>
            </template>
            <el-row style="margin: 20px 0 0">
              <el-col :span="6"
                ><el-form-item label="offer时间：" prop="time">
                  <el-date-picker
                    v-model="offerForm.time"
                    type="date"
                    placeholder="选择日期"
                    value-format="yyyy-MM-dd"
                  ></el-date-picker> </el-form-item
              ></el-col>
              <el-col :span="6"
                ><el-form-item prop="num" label="offer金额：">
                  <el-input
                    v-model="offerForm.num"
                    placeholder="请填写候选人年薪"
                    @mousewheel.native.prevent
                  ></el-input> </el-form-item
              ></el-col>
              <el-col :span="12"
                ><el-form-item label="预到岗时间：" prop="entryTime">
                  <el-date-picker
                    v-model="offerForm.entryTime"
                    style="margin-right: 8px"
                    type="date"
                    placeholder="选择日期"
                    value-format="yyyy-MM-dd"
                  ></el-date-picker>
                  <el-checkbox disabled v-model="offerForm.addToSchedule"
                    >将预到岗时间添加到日程提醒</el-checkbox
                  >
                </el-form-item></el-col
              >
            </el-row>
          </el-collapse-item>
        </el-collapse>
      </div>
    </el-form>
  </section>
</template>
<script>
import { addOrEditOffer, getOperaterecordById } from "../api/api";
export default {
  props: ["projectResumeId", "projectResumeId1"],
  data() {
    var checkCent = (rule, value, callback) => {
      if (value == 0 || !value) {
        return callback(new Error("候选人年薪不能为空"));
      }
      var reg = /^\d+(\.\d{0,2})?$/;
      if (!reg.test(value)) {
        return callback(new Error("请输入数字或含两位小数的数值"));
      }
      callback();
    };
    return {
      childValue: false,
      offerForm: {
        time: new Date().Format("yyyy-MM-dd"),
        entryTime: null,
        num: null,
        addToSchedule: true,
        id: 0,
        version: 0,
        offerSalaries: [],
      },
      offerFormRule: {
        time: [
          { required: true, message: "请选择offer时间", trigger: "change" },
        ],
        entryTime: [
          { required: true, message: "请选择预到岗时间", trigger: "change" },
        ],
        num: [{ required: true, validator: checkCent, trigger: "blur" }],
      },
      options: [
        { value: "基本薪资", label: "基本薪资" },
        { value: "补贴", label: "补贴" },
        { value: "股权", label: "股权" },
        { value: "固定奖金", label: "固定奖金" },
        { value: "自定义", label: "自定义" },
      ],
      activeName: ["score"],
      activeNames1: ["score"],
      businessType:''
    };
  },
  computed: {
    offertotal: {
      get() {
        let offertotal = 0;
        for (let i in this.offerForm.offerSalaries) {
          this.offerForm.offerSalaries[i].totalAmount = (
            Number(this.offerForm.offerSalaries[i].amount) *
            Number(this.offerForm.offerSalaries[i].count)
          ).toFixed(2);
          if (this.offerForm.offerSalaries[i].totalAmount) {
            offertotal += Number(this.offerForm.offerSalaries[i].totalAmount);
          }
        }
        return Number(offertotal).toFixed(2);
      },
      set() {},
    },
  },
  methods: {
    saveOffer: function (formName) {
      return new Promise((resolve, reject) => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let that = this;
            for (let o in that.offerForm.offerSalaries) {
              if (
                that.offerForm.offerSalaries[o].structure ||
                that.offerForm.offerSalaries[o].amount == 0 ||
                that.offerForm.offerSalaries[o].count == 0 ||
                that.offerForm.offerSalaries[o].totalAmount == 0 ||
                that.offerForm.offerSalaries[o].structure == null ||
                that.offerForm.offerSalaries[o].structure == ""
              ) {
                if (
                  that.offerForm.offerSalaries[o].structure == null &&
                  that.offerForm.offerSalaries[o].amount == 0 &&
                  that.offerForm.offerSalaries[o].count == 0
                ) {
                  reject();
                  that.$message.error("薪资情况请至少填写一行");
                  return;
                }
                if (
                  that.offerForm.offerSalaries[o].structure == null ||
                  that.offerForm.offerSalaries[o].structure == ""
                ) {
                  reject();
                  that.$message.error("请填写薪资结构");
                  return;
                }
                if (that.offerForm.offerSalaries[o].amount == 0) {
                  reject();
                  that.$message.error("请填写薪资金额");
                  return;
                }
                if (that.offerForm.offerSalaries[o].count == 0) {
                  reject();
                  that.$message.error("请填写数量");
                  return;
                }
              }
            }
            addOrEditOffer({
              id: this.offerForm.id,
              objectContent: {
                offerMoney: this.offerForm.num,
                offerTime: this.offerForm.time,
                planEntryTime: this.offerForm.entryTime,
                addToSchedule: this.offerForm.addToSchedule,
                offerSalaries: this.offerForm.offerSalaries,
              },
              projectResumeId: this.projectResumeId,
              version: this.offerForm.version,
            }).then((res) => {
              if (res.success) {
                this.$message.success("操作成功");
                this.$refs.offerForm.resetFields();
                this.$emit("loadList");
              }
              resolve();
            });
          } else {
            reject();
            return false;
          }
        });
      });
    },
    loadEditInfo(row) {
      if (row) {
        getOperaterecordById({
          id: row.id,
        }).then((res) => {
          if (res.success) {
            this.offerForm.offerSalaries = JSON.parse(row.content).offerSalaries
              ? JSON.parse(row.content).offerSalaries
              : [
                  {
                    structure: null,
                    amount: 0,
                    count: 0,
                    totalAmount: null,
                  },
                ];
            this.offerForm.num = res.result.objectContent.offerMoney;
            this.offerForm.time = res.result.objectContent.offerTime;
            this.offerForm.entryTime = res.result.objectContent.planEntryTime;
            this.offerForm.id = res.result.id;
            this.offerForm.version = res.result.version;
            this.businessType = res.result.businessType;
          } else {
            this.$emit("childByValue", this.childValue);
          }
        });
      } else {
        this.offerForm.offerSalaries = [
          {
            structure: null,
            amount: 0,
            count: 0,
            totalAmount: null,
          },
        ];
      }
    },
    //新增offer薪资列
    addNewTable() {
      let data = {
        structure: null,
        amount: 0,
        count: 0,
        totalAmount: null,
      };
      this.offerForm.offerSalaries.push(data);
    },
    //删除offer薪资列
    delTable(index) {
      this.offerForm.offerSalaries.splice(index, 1);
    },
  },
};
</script>

<style lang='scss' scoped>
.tips {
  height: 35px;
  line-height: 35px;
  font-size: 13px;
  color: #f05656;
  padding: 0 10px;
  margin-bottom: 20px;
  background-color: rgba(240, 86, 86, 0.129411764705882);
  border: 1px solid #f05656;
  i {
    color: #e60012;
    font-size: 16px;
    margin-right: 10px;
    vertical-align: baseline;
  }
}
</style>
<style lang='scss'>
.offerFrom {
  .searchList {
    font-size: 0;
    .scoreTitle {
      margin: 0;
      border: 1px solid #e5e5e5 !important;
      .el-collapse-item__content {
        padding-bottom: 10px;
      }
      .scoreTotal {
        font-size: 14px;
        padding: 0 10px;
        color: #333;
        width: 100%;
        line-height: 35px;
        background-color: #f0f0f0;
        font-weight: bold;
        span {
          font-weight: normal;
          color: #606266;
          s {
            text-decoration: none;
            color: #e58a00;
          }
        }
        .addSearchBtn {
          float: right;
          color: #526ecc;
          font-size: 12px;
          i {
            font-size: 14px;
          }
        }
      }
    }
    .liStyle {
      height: 40px;
      width: 100%;
      margin: 0;
      padding: 0 15px 0 10px;
    }
    .el-collapse-item__header {
      height: 35px;
      line-height: 35px;
      border: none;
      background-color: #f0f0f0;
    }
    .el-form-item__label,
    .el-form-item__content {
      font-size: 12px;
      line-height: normal;
      line-height: 32px;
    }
    .el-icon-delete {
      color: #999;
      font-size: 13px;
      &:hover {
        color: #e60012;
      }
    }
  }
}
</style>