<template>
  <div
    class="upload-container EditTinymce"
    style="text-align: right; margin-right: 10px; font-size: 13px"
  >
    <el-button
      v-if="ishowVariables"
      icon="el-icon-message"
      size="mini"
      type="text"
      style="color: #999"
      @click="showTheDig"
      >邮件变量</el-button
    >
    <span
      class="dliver"
      v-if="(ishowVariables && ishowImage) || (ishowVariables && ishowAnnexes)"
      >|</span
    >
    <el-button
      v-if="ishowImage"
      icon="el-icon-picture"
      size="mini"
      type="text"
      style="color: #999"
      @click="dialogVisible = true"
      >插入图片</el-button
    >
    <span class="dliver" v-if="ishowImage && ishowAnnexes">|</span>
    <el-upload
      v-if="ishowAnnexes"
      style="display: inline-block"
      multiple
      :show-file-list="false"
      :file-list="fileAnList"
      :on-remove="handleAnRemove"
      :before-upload="beforeAnnexUpload"
      :on-success="handleAnnexSuccess"
      :on-exceed="handleChange"
      class="editor-slide-upload"
      :action="actionUrl"
      :headers="myHeaders"
      :limit="10"
    >
      <el-button
        icon="el-icon-paperclip"
        size="mini"
        type="text"
        style="color: #999"
        title="支持20M以内的docx，doc，xlsx，xls，ppt，pptx，pdf，jpg，jpeg，png，zip，rar文件，支持上传多份文件，最多不超过10份。"
        >附件</el-button
      >
    </el-upload>
    <el-dialog
      :visible.sync="dialogVisible"
      append-to-body
      class="uploadImg"
      title="插入图片"
    >
      <el-upload
        :multiple="true"
        :file-list="fileList"
        :show-file-list="true"
        :on-remove="handleRemove"
        :on-success="handleSuccess"
        :before-upload="beforeUpload"
        class="editor-slide-upload"
        :action="actionUrl"
        :headers="myHeaders"
        list-type="picture-card"
        accept="image/jpeg, image/png"
      >
        <i class="el-icon-plus hover-state"></i>
      </el-upload>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="dialogVisible = false">取消</el-button>
        <el-button size="mini" type="primary" @click="handleSubmit"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import { getToken } from 'api/qiniu'
import { ApiBaseUrl } from "@/api/http";
import Cookies from "js-cookie";
export default {
  name: "EditorSlideUpload",
  props: {
    color: {
      type: String,
      default: "#1890ff",
    },
    ishowImage: {
      type: Boolean,
      default: false,
    },
    ishowAnnexes: {
      type: Boolean,
      default: false,
    },
    ishowVariables: {
      type: Boolean,
      default: false,
    },
    onlyImage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      actionUrl: ApiBaseUrl + "/api/file/uploadOne",
      myHeaders: {
        Authorization: Cookies.get("accessToken"),
      },
      dialogVisible: false,
      listObj: {},
      fileList: [],
      fileAnList: [],
      annexList: [],
      hasArry: [],
    };
  },
  methods: {
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    checkAllSuccess() {
      return Object.keys(this.listObj).every(
        (item) => this.listObj[item].hasSuccess
      );
    },
    handleAnRemove(data) {
      this.fileAnList = [];
      this.hasArry = data;
    },
    handleSubmit() {
      const arr = Object.keys(this.listObj).map((v) => this.listObj[v]);
      if (!this.checkAllSuccess()) {
        this.$message(
          "Please wait for all images to be uploaded successfully. If there is a network problem, please refresh the page and upload again!"
        );
        return;
      }
      this.$emit("successCBK", arr);
      this.listObj = {};
      this.fileList = [];
      this.dialogVisible = false;
    },
    handleAnnexSuccess(res, file, fileList) {
      let _this = this;
      if (res.success) {
        _this.annexList = [];
        if (_this.hasArry.length > 0) {
          _this.hasArry.forEach((item) => {
            _this.annexList.push({
              fileName: item.fileName,
              fileUrl: item.fileUrl,
            });
          });
        }
        fileList.forEach((item) => {
          _this.annexList.push({
            fileName: item.name,
            fileUrl: item.response ? item.response.result : res.result,
          });
        });
        _this.$emit("annex", _this.annexList);
      }
    },
    handleSuccess(response, file) {
      const uid = file.uid;
      const objKeyArr = Object.keys(this.listObj);
      for (let i = 0, len = objKeyArr.length; i < len; i++) {
        if (this.listObj[objKeyArr[i]].uid === uid) {
          this.listObj[objKeyArr[i]].url =
            ApiBaseUrl + "/pics/temporary/" + response.result;
          this.listObj[objKeyArr[i]].hasSuccess = true;
          return;
        }
      }
    },
    handleRemove(file) {
      const uid = file.uid;
      const objKeyArr = Object.keys(this.listObj);
      for (let i = 0, len = objKeyArr.length; i < len; i++) {
        if (this.listObj[objKeyArr[i]].uid === uid) {
          delete this.listObj[objKeyArr[i]];
          return;
        }
      }
    },
    handleChange(files, fileList) {
      this.$message.error(
        `当前限制选择 10 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    beforeAnnexUpload(file) {
      const isLt = file.size / 1024 / 1024 < 20;
      if (!isLt) {
        this.$message.error("上传文件大小不能超过 20MB!");
      }
      return isLt;
    },
    beforeUpload(file) {
      const _self = this;
      const _URL = window.URL || window.webkitURL;
      const fileName = file.uid;
      this.listObj[fileName] = {};
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = _URL.createObjectURL(file);
        img.onload = function () {
          _self.listObj[fileName] = {
            hasSuccess: false,
            uid: file.uid,
            width: this.width,
            height: this.height,
          };
        };
        resolve(true);
      });
    },
    showTheDig() {
      this.$emit("showTheDig");
    },
    setData(val) {
      val.forEach((item) => {
        this.fileAnList.push({
          name: item.fileName,
          response: {
            result: item.fileUrl,
          },
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
// .editor-slide-upload {
//   ::v-deep .el-upload--picture-card {
//     // width: 100%;
//   }
// }
.el-upload.el-upload--picture-card:hover {
  .hover-state {
    color: #ff3f3f;
  }
}
.dliver {
  font-size: 10px;
  color: #ececec;
  margin: 0 8px;
}
</style>
<style lang="scss">
.uploadImg .el-dialog__body {
  padding-bottom: 0;
  border: 0;
  border-top: 1px solid #ededed;
}
.EditTinymce {
  .el-button--text:hover {
    color: #eb3341 !important;
  }
}
</style>

