//test
export const increment = ({ commit }) => {
    commit('INCREMENT')
}
export const decrement = ({ commit }) => {
    commit('DECREMENT')
}
export const upDateAvatar = ({ commit }) => {
    commit('UPDATEAVATAR')
}
export const upDateUserName = ({ commit }) => {
    commit('UPDATEUSERNAME')
}
export const upDateCollapsed = ({ commit }) => {
    commit('UPDACALLAPSED')
}
export const upStatusAddResume = ({ commit }) => {
    commit('UPADDRESUMESTATUS')
}
export const updateCount = ({ commit }, count) => {
    commit('UPDATECOUNT', count)
}
export const updateApprovalCount = ({ commit }, count) => {
    commit('UPDATEAPPROVALCOUNT', count)
}
export const updateMessageList = ({ commit }, list) => {
    commit('UPDATEMESSAGELIST', list)
}
export const upDateMobile = ({ commit }) => {
    commit('UPDATEMOBILE')
}
export const upDateSelfStatus = ({ commit }, status) => {
    commit('UPDATESELFSTATUS', status)
}
export const upDateAddResumeStatus = ({ commit }, status) => {
    commit('UPDATEADDRESUMSTATUS', status)
}
export const upDateResumeId = ({ commit }, id) => {
    commit('UPDATERESUMID', id)
}
export const upDateResumePreCloseTime = ({ commit }, time) => {
    commit('UPDATERESUMPRECLOSETIME', time)
}
export const upDateAutomaticId = ({ commit }, id) => {
    commit('UPDATEAUTOMATICID', id)
}
export const upDateAutomaticLastCloseTime = ({ commit }, time) => {
    commit('UPDATEAUTOMATICLASTCLOSETIME', time)
}
export const upDateProjectClickList = ({ commit }, list) => {
    commit('UPDATEPROJECTCLICKLIST', list)
}

