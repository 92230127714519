<template>
  <el-upload
    ref="upload"
    class="upload-demo"
    drag
    :headers="myHeaders"
    :action="actionUrl"
    :on-success="handleInfoSuccess"
    :on-remove="handleRemove"
    :on-exceed="handleFileExceed"
    :before-upload="beforeAvatarUpload"
    :file-list="fileInfoList"
    :limit='10'
  >
    <i class="el-icon-upload" style="font-size:60px;color:#999"></i>
    <div class="el-upload__text">
      将文件拖到此处，或
      <span class="tabHref">点击上传</span>
       <div slot="tip" style="margin-top: -5px;font-size:12px;line-height:1.5;color:#999">支持docx，doc，xlsx，xls，ppt，pptx，pdf，jpg，jpeg，png，zip，rar文件，单份不超过20M</div>
    </div>
   
  </el-upload>
</template>
<script>
import * as api from "../api/http";
import Cookies from "js-cookie";
export default {
  props: ["fileInfoList"],
  data() {
    return {
      myHeaders: {
        Authorization: Cookies.get("accessToken")
      },
      actionUrl: null
    };
  },
  created() {
    this.actionUrl = api.ApiBaseUrl + "/api/file/uploadOne";
  },
  methods: {
    handleRemove(file, fileList) {
      for (let i in this.fileInfoList) {
        if (this.fileInfoList[i].url == file.url) {
          this.fileInfoList.splice(i, 1);
          break;
        }
      }
      this.$emit("getList", this.fileInfoList);
    },
    handleFileExceed() {
      this.$message.error("最多上传10份文件");
    },
    handleInfoSuccess: function(res, file, fileList) {
      this.fileInfoList.push({
        name: file.name,
        url: file.response.result
      });
      this.$emit("getList", this.fileInfoList);
    },
    beforeAvatarUpload(file) {
      const isLt10M = file.size / 1024 / 1024 < 20;
      if (!isLt10M) {
        this.$message.error("上传文件大小不能超过 20MB!");
      }
      return isLt10M;
    }
  }
};
</script>