<template>
  <section>
    <div v-if="isadd" style="padding: 10px 10px 0 10px">
      <el-button size="mini" @click="addNew"> 新增日程 </el-button>
      <el-row :gutter="24">
        <el-col :span="24" style="padding: 0">
          <el-table
            :header-cell-style="{
              'background-color': '#f5f7fa',
            }"
            class="tabBorder"
            :data="scheduleDta"
            style="width: 100%"
          >
            <el-table-column prop="scheduleType" label="任务类型" width="100">
              <template slot-scope="scope">
                <span
                  class="tag"
                  :class="{
                    tag01: scope.row.scheduleType == '顾问面试',
                    tag06: scope.row.scheduleType == '客户面试',
                    tag21: scope.row.scheduleType == '候选人电话',
                    tag22: scope.row.scheduleType == '候选人背调',
                    tag16: scope.row.scheduleType == 'offert谈判',
                    tag17: scope.row.scheduleType == '候选人入职',
                    tag17: scope.row.scheduleType == '试用期结束',
                    tag04: scope.row.scheduleType == '客户电话',
                    tag19: scope.row.scheduleType == '客户拜访',
                    tag20: scope.row.scheduleType == '客户邮件',
                    tag18: scope.row.scheduleType == '其他',
                    tag26: scope.row.scheduleType == '联系候选人',
                    tag27: scope.row.scheduleType == '候选人面试',
                  }"
                  >{{ scope.row.scheduleType }}</span
                >
              </template>
            </el-table-column>
            <el-table-column prop="targetName" label="任务对象">
              <template slot-scope="scope">
                <span class="tabHref" @click="Toggle()">{{
                  scope.row.targetName
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="备注"
              show-overflow-tooltip
              min-width="250"
              prop="comment"
            >
              <template slot-scope="scope">
                {{ scope.row.comment ? scope.row.comment : "-" }}
              </template>
            </el-table-column>
            <el-table-column
              label="任务时间"
              min-width="160"
              prop="scheduleTime"
            >
            </el-table-column>
            <el-table-column
              label="创建人"
              min-width="160"
              prop="createUserName"
            >
            </el-table-column>
            <el-table-column prop="joinUserName" width="180" label="参与者">
              <template slot-scope="scope">
                {{ scope.row.joinUserName ? scope.row.joinUserName : "-" }}
              </template>
            </el-table-column>
            <el-table-column label="操作" width="80" fixed="right">
              <template slot-scope="scope">
                <span class="tabHref" @click="editSchedule(scope.row.id)"
                  >编辑</span
                >
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
    </div>
    <el-dialog
      :title="scheduleTitle"
      :visible.sync="scheduleDialog"
      width="600px"
      append-to-body
    >
      <caledarfrom
        ref="caledarfrom"
        v-if="scheduleDialog"
        :propScheduleData="propScheduleData"
        :targetId="id"
      ></caledarfrom>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" plain size="mini" @click="closeDig"
          >取消</el-button
        >
        <el-button type="primary" size="mini" @click="saveSchedule()"
          >保存</el-button
        >
      </span>
    </el-dialog>
  </section>
</template>

<script>
import {
  scheduleList, //获取日程列表
  editSchedule, //日程详情
} from "../../api/api";
import caledarfrom from "./calendarfrom.vue";
export default {
  props: ["id", "show"],
  components: { caledarfrom },
  data() {
    return {
      scheduleDta: [],
      scheduleListType: {
        id: 0,
        type: "人才",
      },
      scheduleTitle: "新增日程",
      scheduleDialog: false,
      propScheduleData: {},
      isadd: true,
    };
  },
  created() {
    if (this.show) {
      this.isadd = false;
      this.scheduleDialog = true;
    }
    this.scheduleList();
    // this.getUser();
  },
  mounted() {},
  methods: {
    //日程列表
    scheduleList() {
      this.scheduleListType.id = this.id;
      scheduleList(this.scheduleListType).then((res) => {
        this.scheduleDta = res.result;
        let val = {
          num: this.scheduleDta.length,
          type: "日程",
        };
        this.$emit("number", val);
      });
    },
    //新增日程
    addNew() {
      this.scheduleTitle = "新增日程";
      this.scheduleDialog = true;
    },
    //获取日程详情
    editSchedule(id) {
      this.scheduleTitle = "编辑日程";
      this.scheduleDialog = true;
      editSchedule(id).then((res) => {
        this.propScheduleData = res.result;
      });
    },
    //保存
    saveSchedule() {
      this.$refs.caledarfrom
        .submitForm("ruleForm")
        .then(() => {
          this.scheduleDialog = false;
          this.scheduleList();
        })
        .catch((error) => {});
    },
    //关闭
    closeDig() {
      this.scheduleDialog = false;
      this.resetForm("ruleForm");
    },
    //清空
    resetForm(formName) {
      this.$refs[formName].resetFields();
      if (formName == "ruleForm") {
        this.ruleForm.moduleType = "人才";
        this.ruleForm.scheduleType = "联系候选人";
      }
    },
    //切换到详情
    Toggle() {
      this.$emit("changeactive");
    },
  },
};
</script>
<style lang='scss'>
.el-table__fixed-right {
  height: 100% !important;
}
.el-table__fixed-left {
  height: 100% !important;
}
.el-table__fixed {
  height: 100% !important; //设置高优先，以覆盖内联样式
}
</style>