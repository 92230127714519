<template>
  <div>
    <el-button size="mini" @click="upAttachment">上传附件</el-button>
    <el-table
      :header-cell-style="{
        'background-color': '#f5f7fa',
      }"
      :data="docData"
      class="tabBorder"
      style="width: 100%"
    >
      <el-table-column prop="fileName" label="文件名">
        <template slot-scope="scope">
          <span
            class="tabHref"
            :title="scope.row.fileName&&scope.row.fileName.length > 12 ? scope.row.fileName : ''"
            @click="preView(scope.row)"
            >{{ scope.row.fileName }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="type" label="类型" width="180">
        <template slot-scope="scope">
          <span
            class="tag"
            :class="{
              tag01: scope.row.type == '公司简介' || scope.row.type == 'JD',
              tag02:
                scope.row.type == '通讯录' || scope.row.type == '付款通知书',
              tag03:
                scope.row.type == '公司架构' ||
                scope.row.type == 'Offer Letter',
              tag05: scope.row.type == '合同',
              tag06: scope.row.type == '班车表',
              tag07: scope.row.type == '其他',
            }"
            >{{ scope.row.type }}</span
          >
        </template>
      </el-table-column>
      <el-table-column prop="createdTime" label="上传时间"> </el-table-column>
      <el-table-column prop="createdUserName" label="上传人"> </el-table-column>
      <el-table-column label="操作" width="150">
        <template slot-scope="scope">
          <span class="tabHref borderR" @click="preView(scope.row)">预览</span>
          <span class="tabHref" @click="handleDown(scope.row)">下载</span>
        </template>
      </el-table-column>
    </el-table>
    <upload-attach-ment
      v-if="VisibleAttach"
      :way="2"
      @loadAttach="attachToInfo"
      @cancelAttath="VisibleAttach = false"
      :dialogVisibleAttach="VisibleAttach"
      :objectId="objectId"
      :moduleType="moduleType"
    ></upload-attach-ment>
    <el-dialog
      :visible.sync="dialogVisablePre"
      width="600px"
      title="文件预览"
      append-to-body
    >
      <img style="width: 100%" :src="imgSrc" />
    </el-dialog>
  </div>
</template>

<script>
import {
  getAttachmentPage, //附件列表
} from "../api/api";
import { ApiBaseUrl, PreviewUrl } from "../api/http";
import uploadAttachMent from "./uploadAttachMent.vue";
export default {
  name: "attachment",
  props: ["objectId", "moduleType"],
  components: { uploadAttachMent },
  data() {
    return {
      //附件
      dialogVisablePre: false,
      imgSrc: "",
      VisibleAttach: false,
      docData: [],
    };
  },
  created() {
    this.loadAttachmentPage(this.objectId);
  },
  methods: {
    attachToInfo(id) {
      this.loadAttachmentPage(id);
      this.$emit('loadCount')
      this.VisibleAttach = false;
    },
    upAttachment() {
      //上传附件
      this.VisibleAttach = true;
    },
    preView(row) {
      if (row.fileType === "application/pdf") {
        //pdf文件查看
        window.open("/pdf/web/viewer.html?file=" + PreviewUrl + row.filePath);
      } else if (
        row.filePath.indexOf("document") >= 0 ||
        row.filePath.indexOf("sheet") >= 0 ||
        row.fileType == "application/msword" ||
        row.fileType == "application/vnd.ms-excel" ||
        row.fileType == "application/vnd.ms-powerpoint"||
        row.fileType=="application/vnd.openxmlformats-officedocument.presentationml.presentation"||
        row.fileType=="application/vnd.openxmlformats-officedocument.wordprocessingml.document"||
        row.fileType=="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        //wps查看
        window.open(
          "https://view.officeapps.live.com/op/view.aspx?src=" +
            PreviewUrl +
            row.filePath
        );
      } else if (row.fileType === "application/zip") {
        this.$message.error("附件类型不支持在线预览");
      } else {
        if (row.filePath.indexOf("rar") >= 0) {
          this.$message.error("附件类型不支持在线预览");
        } else {
          //图片
          this.dialogVisablePre = true;
          this.imgSrc = ApiBaseUrl + row.filePath;
        }
      }
    },
    handleDown(row) {
      //下载文件
      location.href =
        ApiBaseUrl +
        "/api/file/download?fileName=" +
        encodeURIComponent(row.fileName) +
        "&filePath=" +
        row.filePath;
    },
    loadAttachmentPage(id) {
      getAttachmentPage({
        objectId: id,
        moduleType: this.moduleType,
        page: {
          current: 1,
          pages: 1,
          size: 50,
          total: 0,
          orders: [
            {
              asc: false,
              column: "createdTime",
            },
          ],
        },
      }).then((res) => {
        if (res.success) {
          this.docData = res.result.records;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.borderR {
  border-right: 1px solid #e5e5e5;
  padding-right: 8px;
  margin-right: 8px;
}
</style>