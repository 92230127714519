<template>
  <section class="whiteCollarWorker">
    <el-form
      @submit.native.prevent
      :model="whiteCollarWorkerForm"
      ref="whiteCollarWorkerForm"
      :rules="whiteCollarWorkerFormRule"
      label-width="120px"
    >
        <div class="heards searchList">
            <el-collapse
                accordion
                v-model="activeNames"
                class="scoreTitle"
                style="border-bottom: 0 !important"
            >
                <el-collapse-item name="score">
                    <template slot="title">
                        <div class="scoreTotal">Offer信息</div>
                    </template>
                    <p class="tips" v-if="isAnnouncement">
                        <i class="el-icon-warning"></i>提示：已勾选的内容将显示在公示的喜报中，如有疑问请提前与Leader确认
                    </p>
                    <el-row style="margin: 20px 0 0">
                        <el-col :span="8">
                            <el-form-item
                            prop="candidateNameItem.name"
                            label="候选人姓名："
                            >
                            {{ whiteCollarWorkerForm.candidateNameItem.name }}
                            <el-checkbox
                                v-model="whiteCollarWorkerForm.candidateNameItem.isShow"
                                style="margin: 0 10px"
                            ></el-checkbox>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item prop="companyNameItem.name" label="公司简称：">
                            <span
                                v-if="
                                whiteCollarWorkerForm.companyNameItem.name &&
                                whiteCollarWorkerForm.companyNameItem.name.length > 10
                                "
                                :title="whiteCollarWorkerForm.companyNameItem.name"
                                >{{
                                whiteCollarWorkerForm.companyNameItem.name.substring(0, 10)
                                }}…</span
                            >
                            <span v-else>{{ whiteCollarWorkerForm.companyNameItem.name }}</span>
                            <el-checkbox
                                v-model="whiteCollarWorkerForm.companyNameItem.isShow"
                                style="margin: 0 10px"
                            ></el-checkbox>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8" v-if="isAnnouncement">
                            <el-form-item prop="mobile" label="手机号：">
                            <el-input
                                style="width: 80%"
                                v-model.trim="whiteCollarWorkerForm.mobile"
                            ></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item prop="positionItem.name" label="职位名称：">
                            <el-input
                                style="width: 80%"
                                v-model.trim="whiteCollarWorkerForm.positionItem.name"
                                maxlength="20"
                            ></el-input>
                            <el-checkbox
                                v-model="whiteCollarWorkerForm.positionItem.isShow"
                                style="margin: 0 10px"
                            ></el-checkbox>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item
                            class="offerStyle"
                            prop="offerTimeItem.name"
                            label="Offer时间："
                            >
                            <el-date-picker
                                v-model="whiteCollarWorkerForm.offerTimeItem.name"
                                type="date"
                                placeholder="选择日期"
                                value-format="yyyy-MM-dd"
                                style="width: 80%"
                            ></el-date-picker>
                            <el-checkbox
                                label=""
                                v-model="whiteCollarWorkerForm.offerTimeItem.isShow"
                                style="margin: 0 10px"
                            ></el-checkbox>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item  class="is-required" label="薪资情况：">
                               <div style="display:flex;">
                                <el-form-item prop="amount" label-width='0' style="width: 39%">
                                    <el-input
                                        style="width: 94%"
                                        type="number"
                                        v-model="whiteCollarWorkerForm.amount"
                                        maxlength="20"
                                        placeholder="月薪"
                                    ></el-input>
                                </el-form-item>
                                <span style="margin-right: 10px;">X</span>
                                <el-form-item prop="count" label-width='0' style="width: 38.3%">
                                        <el-input
                                            style="width: 94%;text-align: right;"
                                            type="number"
                                            v-model="whiteCollarWorkerForm.count"
                                            placeholder="月数"
                                            maxlength="20"
                                        ></el-input>
                                </el-form-item>
                               </div>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item
                            prop="entryTimeItem.name"
                            label="预到岗时间："
                            style="margin-bottom: 8px;"
                            >
                            <div>
                            <el-date-picker
                                v-model="whiteCollarWorkerForm.entryTimeItem.name"
                                type="date"
                                placeholder="选择日期"
                                value-format="yyyy-MM-dd"
                                style="width: 80%"
                            ></el-date-picker>
                            <el-checkbox disabled v-model="whiteCollarWorkerForm.addToSchedule" :style="isAnnouncement?'margin-left:8px;position: absolute;':''">将预到岗时间添加到日程提醒</el-checkbox>
                            </div>
                            </el-form-item>
                        </el-col>
                       
                    </el-row>
                </el-collapse-item>
            </el-collapse>
        </div>
        <div class="searchList">
            <el-collapse
                accordion
                v-model="activeNames1"
                class="scoreTitle"
                style="border-bottom: 0 !important"
            >
                <el-collapse-item name="score">
                    <template slot="title">
                        <div class="scoreTotal"> 员工信息同步到紫牛招聘的offer环节</div>
                    </template>
                    <el-row style="margin: 20px 0 0" v-if="isAnnouncement">
                        <el-col :span="8">
                            <el-form-item
                                prop="projectId"
                                label="紫牛项目名称："
                            >
                                <el-select
                                filterable
                                clearable
                                    placeholder="请选择"
                                    v-model="whiteCollarWorkerForm.projectId"
                                    size="mini"
                                    @change="changeProjectId"
                                    style="width: 80%"
                                    remote
                                    reserve-keyword
                                    :remote-method="remoteMethod"
                                    :loading="loading"
                                    @clear="remoteMethod('')"
                                >
                                    <el-option
                                        v-for="(i, indexKPI) in projectList"
                                        :key="indexKPI"
                                        :label="i.name"
                                        :value="i.id"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item
                                prop="jobId"
                                label="紫牛岗位名称："
                            >
                                <el-select
                                filterable
                                clearable
                                    placeholder="请选择"
                                    v-model="whiteCollarWorkerForm.jobId"
                                    size="mini"
                                    style="width: 80%"
                                >
                                    <el-option
                                        v-for="(i, indexKPI) in jobList"
                                        :key="indexKPI"
                                        :label="i.name"
                                        :value="i.id"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item
                                prop="recruitUserId"
                                label="招聘人："
                            >
                                <el-select
                                filterable
                                clearable
                                    placeholder="请选择"
                                    v-model="whiteCollarWorkerForm.recruitUserId"
                                    size="mini"
                                    style="width: 80%"
                                >
                                    <el-option
                                        v-for="(i, indexKPI) in recruitUsersList"
                                        :key="indexKPI"
                                        :label="i.name"
                                        :value="i.id"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item
                                prop="positionChannelFirstValue"
                                label="招聘渠道："
                            >
                                <!-- <el-select
                                    placeholder="请选择"
                                    v-model="whiteCollarWorkerForm.id"
                                    size="mini"
                                >
                                    <el-option
                                        v-for="(i, indexKPI) in recruitUsersList"
                                        :key="indexKPI"
                                        :label="i.name"
                                        :value="i.id"
                                    ></el-option>
                                </el-select> -->
                                <el-cascader
                                style="width: 80%"
                                    clearable
                                    size="mini"
                                    filterable
                                    :show-all-levels="true"
                                    :options="recruitChannelsList"
                                    collapse-tags
                                    v-model="positionChannelIds"
                                    :props="props1"
                                    @change='changePositionChannelIds'
                                ></el-cascader>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <p class="tips" v-else>
                        重新offer的候选人无法再次同步紫牛，如有需要请登录紫牛的招聘页面手动修改状态；
                    </p>
                </el-collapse-item>
            </el-collapse>
        </div>
    </el-form>
  </section>
</template>

<script>
import{
    adaptersZiniuOfferJobOptions,//白领Offer岗位选项
    adaptersZiniuOfferProjectOptions,//白领Offer项目选项
    adaptersZiniuOfferRecruitChannels,//白领Offer招聘渠道
    adaptersZiniuOfferRecruitUsers,//白领Offer招聘人
    addOrEditOffer,
    offerGoodnewsDefaultData,
    offerGoodnewsSend
}from "../api/api";
export default {
    props:[
        'isAnnouncement',//是否发过喜报
        'projectResumeId'
    ],
    data(){
        const validateTel = (rule, value, callback) => {
            let reg = /^[^A-Za-z\u4E00-\u9FA5]{8,}$/;
            if (!reg.test(value)) {
                callback(new Error("请输入正确的手机号"));
            } else {
                callback();
            }
        };
        return{
            whiteCollarWorkerForm:{
                candidateNameItem: { name: null, isShow: true },
                companyNameItem: { name: null, isShow: true },
                offerTimeItem: { name: null, isShow: true },
                entryTimeItem: { name: null, isShow: true },
                positionItem: { name: null, isShow: true },
                addToSchedule:true,
                amount:null,
                count:null,
                mobile:'',
                projectId:null,
                jobId:null,
                recruitUserId:null,
                positionChannelFirstValue:null,
                positionChannelSecondValue:null,
                positionChannelThirdValue:null,
                id: 0,
        version: 0,
        offerSalaries:[]
            },
            positionChannelIds:[],
            whiteCollarWorkerFormRule:{
                mobile: [
                    { required: true, message: "手机号不能为空", trigger: "blur" },
                    { validator: validateTel, trigger: "blur" },
                ],
                "positionItem.name": [
                    { required: true, message: "职位不能为空", trigger: "blur" },
                ],
                "offerTimeItem.name": [
                    { required: true, message: "offer时间不能为空", trigger: "change" },
                ],
                "entryTimeItem.name": [
                    { required: true, message: "预到岗时间不能为空", trigger: "change" },
                ],
                "amount": [
                    { required: true, message: "月薪不能为空", trigger: "blur"},
                    {
                          pattern: /^\d{1,9}(\.\d{1,2})?$/,
                          message: '精确到小数点后两位',
                        },
                ],
                "count": [
                    { required: true, message: "月数不能为空", trigger: "blur" },
                    {
                          pattern: /^\d{1,9}(\.\d{1,2})?$/,
                          message: '精确到小数点后两位',
                        },
                ],
                "projectId": [
                    { required: true, message: "紫牛项目名称不能为空", trigger: "change" },
                ],
                "jobId": [
                    { required: true, message: "紫牛岗位名称不能为空", trigger: "change" },
                ],
                "recruitUserId": [
                    { required: true, message: "招聘人不能为空", trigger: "change" },
                ],
                "positionChannelFirstValue": [
                    { required: true, message: "招聘渠道不能为空", trigger: "change" },
                ],
            },
            activeNames: ["score"],
            activeNames1: ["score"],
            projectList:[],
            jobList:[],
            recruitChannelsList:[],
            recruitUsersList:[],
            props1: {
                value: "value",
                label: "label",
                children: "children",
                multiple: false,
                checkStrictly: false,
            },
            options:[],
            loading:false,
            filter:''
        }
    },
    created(){
        this.offerGoodnewsDefaultData()
        // this.getAdaptersZiniuOfferJobOptions();
        this.getAdaptersZiniuOfferProjectOptions();
        this.getAdaptersZiniuOfferRecruitChannels();
        this.getAdaptersZiniuOfferRecruitUsers();
    },
    methods:{
        //白领Offer岗位选项
        getAdaptersZiniuOfferJobOptions(){
            adaptersZiniuOfferJobOptions({
                projectId:this.whiteCollarWorkerForm.projectId
            }).then((res) => {
                if (res.success) {
                this.jobList = res.result;
                }
            });
        },
         //白领Offer项目选项
         getAdaptersZiniuOfferProjectOptions(){
            adaptersZiniuOfferProjectOptions({filter:this.filter}).then((res) => {
                if (res.success) {
                this.projectList = res.result;
                }
                this.loading = false;
            });
        },
         //白领Offer招聘渠道
         getAdaptersZiniuOfferRecruitChannels(){
            adaptersZiniuOfferRecruitChannels().then((res) => {
                if (res.success) {
                this.recruitChannelsList = res.result;
                }
            });
        },
         //白领Offer招聘人
         getAdaptersZiniuOfferRecruitUsers(){
            adaptersZiniuOfferRecruitUsers().then((res) => {
                if (res.success) {
                this.recruitUsersList = res.result;
                }
            });
        },
        offerGoodnewsDefaultData() {
            offerGoodnewsDefaultData({
                projectResumeId: this.projectResumeId,
            }).then((res) => {
                if (res.success) {
                    this.whiteCollarWorkerForm = res.result;
                    this.whiteCollarWorkerForm.id=0
                    this.whiteCollarWorkerForm.version=0
                    this.whiteCollarWorkerForm.addToSchedule = true;
                    if (this.whiteCollarWorkerForm.offerTimeItem.name == null) {
                        this.whiteCollarWorkerForm.offerTimeItem.name = new Date().Format(
                        "yyyy-MM-dd"
                        );
                    } else {
                        this.whiteCollarWorkerForm.offerTimeItem = res.result.offerTimeItem;
                    }
                }
            });
        },
        saveForm: function (formName) {
            return new Promise((resolve, reject) => {
                this.$refs[formName].validate((valid) => {
                if (valid) {
                    let that = this;
                    console.log(that.isAnnouncement)
                    if(!that.isAnnouncement){
                        addOrEditOffer({
                            id: that.whiteCollarWorkerForm.id,
                            objectContent: {
                                offerTime: that.whiteCollarWorkerForm.offerTimeItem.name,
                                planEntryTime: that.whiteCollarWorkerForm.entryTimeItem.name,
                                addToSchedule: that.whiteCollarWorkerForm.addToSchedule,
                                offerSalaries: [{
                                    structure:'基本薪资',
                                    amount:that.whiteCollarWorkerForm.amount,
                                    count:that.whiteCollarWorkerForm.count,
                                }],
                            },
                            projectResumeId: that.projectResumeId,
                            version: that.whiteCollarWorkerForm.version,
                        }).then((res) => {
                            if (res.success) {
                                this.$message.success("操作成功");
                                this.$emit("changeSaveLoading", false);
                                this.$refs.whiteCollarWorkerForm.resetFields();
                                this.$emit("loadList");
                            }
                        });
                    }else{
                        this.whiteCollarWorkerForm.offerSalaries=[{
                            structure:'基本薪资',
                            amount:that.whiteCollarWorkerForm.amount,
                            count:that.whiteCollarWorkerForm.count,
                        }]
                        offerGoodnewsSend(this.whiteCollarWorkerForm).then((res) => {
                            if (res.success) {
                                if (res.result.type == "未配置审批流") {
                                this.$confirm(
                                    '<p style="font-weight:bold">未配置喜报审批流，请联系管理员</p><p style="color:#999">审批流配置后将自动流转给审批人进行喜报审批</p>',
                                    "提示",
                                    {
                                    confirmButtonText: "确定",
                                    showClose: false,
                                    customClass: "altCls",
                                    dangerouslyUseHTMLString: true,
                                    showCancelButton: false,
                                    closeOnClickModal: false,
                                    }
                                ).then(() => {
                                    this.$message.success("操作成功！");
                                    this.$emit("changeSaveLoading", false);
                                    this.$emit("loadList");
                                    this.$refs.whiteCollarWorkerForm.resetFields();
                                });
                                // .catch(() => {
                                //   // this.$message({
                                //   //   type: "info",
                                //   //   message: "已取消",
                                //   // });
                                // });
                                } else if (res.result.type == "成功需审批") {
                                this.$confirm(
                                    '<p style="font-weight:bold">喜报已提交至' +
                                    res.result.message +
                                    "审批，请跟进</p>",
                                    "提示",
                                    {
                                    confirmButtonText: "确定",
                                    showClose: false,
                                    customClass: "altCls",
                                    dangerouslyUseHTMLString: true,
                                    showCancelButton: false,
                                    closeOnClickModal: false,
                                    }
                                ).then(() => {
                                    this.$message.success("操作成功！");
                                    this.$emit("changeSaveLoading", false);
                                    this.$emit("loadList");
                                    this.$refs.whiteCollarWorkerForm.resetFields();
                                });
                                // .catch(() => {
                                //   // this.$message({
                                //   //   type: "info",
                                //   //   message: "已取消",
                                //   // });
                                // });
                                } else {
                                reject();
                                this.$message.success("操作成功！");
                                this.$emit("changeSaveLoading", false);
                                this.$emit("loadList");
                                this.$refs.whiteCollarWorkerForm.resetFields();
                                }
                            }
                            resolve();
                        })
                    }
                    
                } else {
                    reject();
                    return false;
                }
                });
            });
        },
        changePositionChannelIds(val){
            console.log(val)
            if(val&&val.length>0){
                this.whiteCollarWorkerForm.positionChannelFirstValue=val[0]?val[0]:null;
                this.whiteCollarWorkerForm.positionChannelSecondValue=val[1]?val[1]:null;
                this.whiteCollarWorkerForm.positionChannelThirdValue=val[2]?val[2]:null;
            }
        },
        changeProjectId(val){
            this.whiteCollarWorkerForm.projectId=val
            this.whiteCollarWorkerForm.jobId=null
            this.getAdaptersZiniuOfferJobOptions()
        },
        remoteMethod(query) {
          console.log(query)
        // if (query !== '') {
          this.loading = true;
        //   setTimeout(() => {
        //     this.loading = false;
        //     this.filter=query
        //     this.getAdaptersZiniuOfferProjectOptions()
        //     // this.options = this.list.filter(item => {
        //     //   return item.label.toLowerCase()
        //     //     .indexOf(query.toLowerCase()) > -1;
        //     // });
        //   }, 200);
        // } else {
        //   this.options = [];
        // }
        this.filter=query
            this.getAdaptersZiniuOfferProjectOptions()
      }
    }
}

</script>

<style  lang='less'>
.whiteCollarWorker {
    // .tips {
    //   height: 35px;
    //   line-height: 35px;
    //   font-size: 13px;
    //   color: #606266;
    //   padding: 0 10px;
    //   margin: 15px;
    //   background-color: #fff9e6;
    //   border: 1px solid #ffdf94;
    //   i {
    //     color: #ff9900;
    //     font-size: 16px;
    //     margin-right: 10px;
    //     vertical-align: baseline;
    //   }
    // }
    .tips {
  height: 35px;
  line-height: 35px;
  font-size: 13px;
  color: #f05656;
  padding: 0 10px;
  margin: 20px;
  background-color: rgba(240, 86, 86, 0.129411764705882);
  border: 1px solid #ffcaca;
  border-radius: 3px;
  i {
    color: #e60012;
    font-size: 16px;
    margin-right: 10px;
    vertical-align: baseline;
  }
}
  .heards {
    background-color: #f5f7fa;
    // padding: 15px;
  
    .scoreTitle {
      margin: 0;
      border: 1px solid #e5e5e5 !important;
      .el-collapse-item__content {
        padding-bottom: 10px;
      }
      .scoreTotal {
        font-size: 14px;
        padding: 0 10px;
        color: #333;
        width: 100%;
        line-height: 35px;
        background-color: #f0f0f0;
        font-weight: bold;
        span {
          font-weight: normal;
          color: #606266;
          s {
            text-decoration: none;
            color: #e58a00;
          }
        }
        .addSearchBtn {
          float: right;
          color: #526ecc;
          font-size: 12px;
          i {
            font-size: 14px;
          }
        }
      }
    }
    .el-collapse-item__header {
      height: 35px;
      line-height: 35px;
      border: none;
      background-color: #f0f0f0;
    }
  }

  .searchList {
    font-size: 0;
    .scoreTitle {
      margin: 0;
      border: 1px solid #e5e5e5 !important;
      .el-collapse-item__content {
        padding-bottom: 10px;
      }
      .scoreTotal {
        font-size: 14px;
        padding: 0 10px;
        color: #333;
        width: 100%;
        line-height: 35px;
        background-color: #f0f0f0;
        font-weight: bold;
        span {
          font-weight: normal;
          color: #606266;
          s {
            text-decoration: none;
            color: #e58a00;
          }
        }
        .addSearchBtn {
          float: right;
          color: #526ecc;
          font-size: 12px;
          i {
            font-size: 14px;
          }
        }
      }
    }
    .liStyle {
      height: 40px;
      width: 100%;
      margin: 0;
      padding: 0 15px 0 10px;
    }
    .el-collapse-item__header {
      height: 35px;
      line-height: 35px;
      border: none;
      background-color: #f0f0f0;
    }
    .el-form-item__label,
    .el-form-item__content {
      font-size: 12px;
      line-height: normal;
      line-height: 32px;
    }
    .el-icon-delete {
      color: #999;
      font-size: 13px;
      &:hover {
        color: #e60012;
      }
    }
  }
  .icon-wenhao-xiangsu {
    font-weight: normal;
    font-size: 16px;
    color: #898989;
    margin-left: 10px;
  }
}
.titleOfPerformance {
  position: relative;
  margin-bottom: 8px;
  border: 1px solid #e5e5e5;
  .el-collapse-item__header::after {
    content: none !important;
  }

  .el-collapse-item__wrap {
    border: none;
  }
  .el-collapse-item__content {
    padding: 0 !important;
  }
}
.contentOfPerformance {
  background-color: #fff;
  padding: 15px 10px;
  .el-form-item {
    padding: 5px 0;
    margin: 0;
    .el-form-item__label {
      font-size: 14px;
    }
  }
  .canEdit {
    padding: 0 10px;
    i {
      display: none;
    }
    &:hover {
      background-color: #f9f9f9;
      i {
        display: inline-block;
      }
    }
  }
}
.editPercent {
  .el-dialog__body {
    padding: 10px 40px 20px;
    .el-form-item__error {
      padding-top: 0 !important;
    }
  }
}
.iconOfClose {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #606266;
  &:hover {
    color: #e60012;
  }
}
</style>