<template>
  <section class="personalizedTags">
    <div class="personalizedTagsContent">
      <p class="tips">
        个性化标签是由顾问自己定制自己使用，对其他人不会产生任何影响，请放心设置
      </p>
      <div class="top">
        <p>个性化标签：</p>
        <el-input
          v-model.trim="personalizedTags.name"
          maxlength="20"
          placeholder="输入个性化标签名称后，点击添加"
          style="width: 300px"
          @blur="cheakLabel"
        ></el-input>
        <el-button
          type="text"
          :disabled="personalizedTags.list.length == 8"
          class="tabHref"
          @click="addPersonalizedTag"
          >添加</el-button
        >
      </div>
      <div class="bottom">
        <p>已添加标签 <span>最多支持设置8个个性化标签</span></p>
        <div class="rowList">
          <div
            class="row"
            v-for="(i, index) in personalizedTags.list"
            :key="index"
          >
            <p>
              {{ i.name }}
              <i class="closed" @click.stop="deletePersonalizedTag(i)">
                &times;
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  labelCustomTagged, //当前用户所有已设置的个性标签
  labelCustomAdd, //添加个性化标签
  labelCustomDelete, //删除个性化标签
} from "../api/api";
export default {
  data() {
    return {
      personalizedTags: {
        name: "",
        list: [],
      },
    };
  },
  created() {
    this.getPersonalizedTags();
  },
  methods: {
    //获取当前用户所有已设置的个性标签
    getPersonalizedTags() {
      labelCustomTagged().then((res) => {
        if (res.success) {
          this.personalizedTags.list = res.result;
        }
      });
    },
    cheakLabel(val) {
      // for (var i = 0; i < this.personalizedTags.list.length; i++) {
      //   if (this.personalizedTags.list[i].name == this.personalizedTags.name) {
      //     this.$message.error("标签名不能重复");
      //     return false;
      //   }
      // }
    },
    //添加个性化标签
    addPersonalizedTag() {
      if (this.personalizedTags.name.length == 0) {
        this.$message.error("标签名不能为空");
        return false;
      }
      for (var i = 0; i < this.personalizedTags.list.length; i++) {
        if (this.personalizedTags.list[i].name == this.personalizedTags.name) {
          this.$message.error("标签名不能重复");
          return false;
        }
      }
      labelCustomAdd({
        name: this.personalizedTags.name,
      }).then((res) => {
        if (res.success) {
          this.$message.success("操作成功");
          this.personalizedTags.name = "";
          this.getPersonalizedTags();
        }
      });
    },
    //删除个性化标签
    deletePersonalizedTag(data) {
      console.log(data);
      let isDelete = false;
      if (!data.hits) {
        isDelete = true;
      } else {
        let div =
          "<p style='font-weight:bold'>确定删除？ " +
          "<p>人选将同步解除标签绑定关系</p>";
        this.$confirm(div, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          customClass: "altCls",
          showClose: false,
          closeOnClickModal: false,
          closeOnPressEscape: false,
          dangerouslyUseHTMLString: true,
        })
          .then(() => {
            isDelete = true;
            labelCustomDelete(data.id).then((res) => {
              if (res.success) {
                this.$message.success("操作成功");
                this.getPersonalizedTags();
              }
            });
          })
          .catch(() => {
            isDelete = false;
          });
      }
      if (isDelete) {
        labelCustomDelete(data.id).then((res) => {
          if (res.success) {
            this.$message.success("操作成功");
            this.getPersonalizedTags();
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.personalizedTagsContent {
  .tips {
    height: 35px;
    line-height: 35px;
    padding: 0 10px;
    margin: -15px 0 15px;
    background-color: #fff9e6;
    border: 1px solid #f7d095;
    color: #ed730a;
    font-size: 12px;
  }
  .top {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .el-button {
      margin-left: 8px;
      &:hover {
        color: #526ecc !important;
      }
    }
  }
  .bottom {
    margin-bottom: 10px;
    span {
      color: #999;
      font-size: 12px;
      margin-left: 8px;
    }
    .rowList {
      margin-top: 10px;
      .row {
        margin: 10px;
        display: contents;
        p {
          border: 1px #ececec solid;
          display: inline-flex;
          padding: 2px 8px;
          border-radius: 4px;
          margin-right: 10px;
          margin-left: 0px !important;
          margin-bottom: 10px;
          // float: left;
          .closed {
            // font-size: 18px;
            color: #606266;
            display: none;
            cursor: pointer;
          }
          &:hover {
            color: #333;
            .closed {
              display: block;
            }
          }
        }
      }
    }
  }
}
</style>
