<template>
  <section class="labelManagement tagsDigContent">
    <div class="serachInp">
      <el-input
        placeholder="搜索标签"
        class="input-with-select"
        v-model="searchTag"
      >
        <el-button @click="search" type="primary" slot="append">查询</el-button>
      </el-input>
    </div>
    <div class="searchHoverContent" v-if="searchHoverContentIsShow">
      <div
        class="row"
        v-for="(i, index) in serchChoiceList"
        :key="index"
        @click="chioceData(i)"
      >
        <p v-html="i.name">{{ i.name }}</p>
        <p>{{ i.type }}</p>
      </div>
      <div
        class="nodata"
        v-if="serchChoiceList.length == 0"
        @click="(searchHoverContentIsShow = false), (searchTag = '')"
      >
        暂无数据……
      </div>
    </div>
    <el-tabs v-model="activeNameDetail" @tab-click="handleClick">
      <el-tab-pane label="常用 /个性化标签" name="first">
        <div class="rowContent">
          <div class="rowList">
            <p class="title">
              常用标签 <span>为了使用方便，建议您编辑常用标签</span>
              <span class="tabHref" @click="editUsedTags()">编辑</span>
            </p>
            <p v-if="usedTags.length == 0" class="noDataTips">
              <span>您还未设置常用标签</span>
              <span class="tabHref" @click="editUsedTags()">去设置</span>
            </p>
            <el-checkbox-group
              v-else
              class="tagLis"
              size="mini"
              v-model="createTag.favouriteLabelIds"
              @change="handleUsedTagsCheckbox"
            >
              <el-checkbox
                v-for="item in usedTags"
                :label="item.id"
                :key="item.id"
                border
                :style="
                  item.bordered
                    ? { 'border-color': item.color, color: item.color }
                    : {}
                "
              >
                <i
                  v-if="item.generateMode == 'MANUAL'"
                  class="icon el-icon-user"
                ></i>
                <i
                  v-if="item.generateMode == 'RULE_BASED'"
                  class="icon el-icon-monitor"
                ></i>
                {{ item.name }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
          <div class="rowList">
            <p class="title">
              个性化标签 <span>支持顾问自定义个性化标签</span>
              <span class="tabHref" @click="editPersonalizedTags()">编辑</span>
            </p>
            <p v-if="personalizedTags.list == 0" class="noDataTips">
              <span>个性化标签</span>
              <span class="tabHref" @click="editPersonalizedTags()"
                >去设置</span
              >
            </p>
            <el-checkbox-group
              v-else
              class="tagLis"
              size="mini"
              v-model="createTag.customLabelIds"
              @change="handlePersonalizedTagsCheckbox"
              :key="usedTagsKey"
            >
              <el-checkbox
                v-for="item in personalizedTags.list"
                :label="item.id"
                :key="item.id"
                border
                :style="
                  item.bordered
                    ? { 'border-color': item.color, color: item.color }
                    : {}
                "
              >
                {{ item.name }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="系统标签" name="second">
        <div
          class="tagsContent"
          @click="(searchHoverContentIsShow = false), (searchTag = '')"
        >
          <div class="tagsContent_L">
            <p
              :class="i.isActive ? 'tagsContent_L_active' : ''"
              v-for="(i, index) in addTypeList"
              :key="index"
              @click="chioceType(i)"
              style="
                display: flex;
                justify-content: space-between;
                align-items: center;
              "
            >
              <span>{{ i.name }}</span>
              <span v-if="i.hitsNums > 0" class="tagNums">{{
                i.hitsNums
              }}</span>
            </p>
          </div>
          <div class="tagsContent_R">
            <p style="margin-bottom: 20px; text-align: right">
              <span class="tabHref" @click="cencleLabel('now')"
                >取消当前Tab自动标签</span
              ><el-divider direction="vertical"></el-divider
              ><span class="tabHref" @click="cencleLabel()"
                >取消全部自动标签</span
              >
            </p>
            <el-checkbox-group
              class="tagLis"
              size="mini"
              v-model="createTag.labelIds"
              @change="handleCheckbox"
            >
              <el-checkbox
                v-for="item in newTagList"
                :label="item.id"
                :key="item.id"
                border
                :style="
                  item.bordered
                    ? { 'border-color': item.color, color: item.color }
                    : {}
                "
              >
                <i
                  v-if="item.generateMode == 'MANUAL'"
                  class="icon el-icon-user"
                ></i>
                <i
                  v-if="item.generateMode == 'RULE_BASED'"
                  class="icon el-icon-monitor"
                ></i>
                {{ item.name }}</el-checkbox
              >
            </el-checkbox-group>
            <el-empty
              v-if="newTagList == null"
              style="text-align: center"
              description="暂无标签"
            ></el-empty>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
    <!-- 个性化标签 -->
    <el-dialog
      :visible.sync="personalizedTagsDialog"
      :modal-append-to-body="false"
      append-to-body
      title="编辑个性化标签"
      :close-on-click-modal="false"
      width="600px"
    >
      <personalized-tags
        v-if="personalizedTagsDialog"
        ref="personalizedTags"
      ></personalized-tags>
      <span slot="footer" class="dialog-footer">
        <el-button
          size="mini"
          @click="(personalizedTagsDialog = false), getPersonalizedTags()"
          >完成</el-button
        >
      </span>
    </el-dialog>
    <!-- 常用标签 -->
    <el-dialog
      title="添加常用标签"
      :visible.sync="dialogVisibleOfTag"
      append-to-body
      :close-on-click-modal="false"
      width="960px"
      custom-class="addTagDig tagsDigContent"
      destroy-on-close
      :before-close="handleClose"
    >
      <labels
        ref="labels"
        v-if="dialogVisibleOfTag"
        :usedTags="usedTags"
        @loadList="getUsedTags"
      ></labels>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          size="mini"
          :loading="saveTagsloading"
          @click="saveTags"
          >添加至常用标签</el-button
        >
      </span>
    </el-dialog>
  </section>
</template>

<script>
import {
  getLabelCategoryOptions, //获取所有标签类别
  personalLabelHits, //人才详情获取标签数量
  labelAddRemarksToResume, //保存标签
  labelCustomTagged, //当前用户所有已设置的个性标签
  labelFavouriteCurUser, //当前顾问常用标签
} from "../../api/api";
import personalizedTags from "../personalizedTags"; //个性标签
import labels from "./labels"; //常用标签
export default {
  props: ["allSkillLabels", "resumeId", "entry", "customLabels"],
  components: {
    personalizedTags, //个性标签
    labels, //常用标签
  },
  data() {
    return {
      searchTag: "",
      searchHoverContentIsShow: false,
      serchChoiceList: [],
      addTypeList: [],
      newTagList: [], //标签列表
      addTypeListNum: [],
      choiceTypeName: "",
      createTag: {
        resumeIds: [],
        labelIds: [],
        favouriteLabelIds: [],
        customLabelIds: [],
      },
      isaddTag: false,
      activeNameDetail: "first",
      personalizedTagsDialog: false,
      personalizedTags: {
        name: "",
        list: [],
      },
      usedTags: [],
      dialogVisibleOfTag: false,
      saveTagsloading: false,
      usedTagsKey: 0,
      favouriteLabelIds: [],
      labelIds: [],
      isFrist: false,
    };
  },
  watch: {
    "createTag.labelIds"(nVal, oVal) {
      if (oVal.length == 0 && nVal.length == 0) {
        this.addTypeList.forEach((item) => {
          item.hitsNums = 0;
        });
      } else {
        if (!this.isFrist) {
          let list = null;
          list = oVal.concat(nVal).filter(function (v, i, arr) {
            return arr.indexOf(v) === arr.lastIndexOf(v);
          });
          this.addTypeList.forEach((item) => {
            if (item.name == this.choiceTypeName) {
              item.hitsNums = item.hitsNums + (nVal.length - oVal.length);
            } else {
              item.labels.forEach((i) => {
                if (i.id == list[0]) {
                  item.hitsNums = item.hitsNums + (nVal.length - oVal.length);
                }
              });
            }
          });
        } else {
          this.isFrist = false;
        }
      }
    },
  },
  created() {
    this.createTag.entry = this.entry;
    this.getPersonalizedTags();
    this.getUsedTags();
    this.getLabelCategoryOptions();
  },
  methods: {
    handleClick(tab) {
      //   if (tab.name == "first") {
      //     this.getPersonalizedTags();
      //     this.getUsedTags();
      //   } else {
      //     this.getLabelCategoryOptions();
      //   }
    },
    //编辑个性化标签
    editPersonalizedTags() {
      this.personalizedTagsDialog = true;
    },
    //编辑常用标签
    editUsedTags() {
      this.dialogVisibleOfTag = true;
    },
    //获取当前用户所有已设置的个性标签
    getPersonalizedTags() {
      labelCustomTagged().then((res) => {
        if (res.success) {
          this.personalizedTags.list = res.result;
          if (this.customLabels) {
            for (var i = 0; i < this.customLabels.length; i++) {
              //回显常用标签
              for (var l = 0; l < this.personalizedTags.list.length; l++) {
                if (
                  this.customLabels[i].id == this.personalizedTags.list[l].id
                ) {
                  this.createTag.customLabelIds.push(
                    this.personalizedTags.list[l].id
                  );
                }
              }
            }
          }
        }
      });
    },
    //当前顾问常用标签
    getUsedTags() {
      labelFavouriteCurUser().then((res) => {
        if (res.success) {
          this.usedTags = res.result;
          this.createTag.favouriteLabelIds = [];
          if (this.allSkillLabels) {
            for (var i = 0; i < this.allSkillLabels.length; i++) {
              //回显常用标签
              for (var l = 0; l < this.usedTags.length; l++) {
                if (this.allSkillLabels[i].id == this.usedTags[l].id) {
                  this.createTag.favouriteLabelIds.push(this.usedTags[l].id);
                  this.usedTags[l].generateMode =
                    this.allSkillLabels[i].generateMode;
                }
              }
            }
            this.favouriteLabelIds = this.createTag.favouriteLabelIds;
          }
        }
      });
    },
    //获取标签选项
    getLabelCategoryOptions() {
      this.isFrist = true;
      personalLabelHits({ candidateId: this.resumeId }).then((res) => {
        if (res.success) {
          this.addTypeListNum = res.result;
          getLabelCategoryOptions().then((res1) => {
            if (res1.success) {
              res1.result.forEach((item) => {
                item.isActive = false;
                this.addTypeListNum.forEach((num) => {
                  if (item.name == num.name) {
                    item.hitsNums = num.hitsNums;
                  }
                });
              });
              this.addTypeList = res1.result;
              // console.log(this.addTypeList);
              if (this.addTypeList.length > 0) {
                this.addTypeList[0].isActive = true;
                this.choiceTypeName = this.addTypeList[0].name;
                this.newTagList = this.addTypeList[0].labels;
                this.newTagSerchList = this.addTypeList[0].labels;
              }
              this.createTag.labelIds = [];
              if (this.allSkillLabels && this.allSkillLabels.length > 0) {
                this.allSkillLabels.forEach((item) => {
                  this.createTag.labelIds.push(item.id);
                  for (var i = 0; i < this.addTypeList.length; i++) {
                    for (
                      var l = 0;
                      l < this.addTypeList[i].labels.length;
                      l++
                    ) {
                      if (this.addTypeList[i].labels[l].id == item.id) {
                        this.addTypeList[i].labels[l].generateMode =
                          item.generateMode;
                      }
                    }
                    // if (this.newTagList[i].id == item.id) {
                    //   this.newTagList[i].generateMode = item.generateMode;
                    // }
                  }
                });
              }
              this.labelIds = this.createTag.labelIds;
            }
          });
        }
      });
    },
    //选中标签类别
    chioceType(data) {
      this.choiceTypeName = data.name;
      this.addTypeList.forEach((item) => {
        if (item.name == data.name) {
          item.isActive = true;
          this.newTagList = data.labels;
          this.newTagSerchList = data.labels;
        } else {
          item.isActive = false;
        }
      });
      this.allSkillLabels.forEach((item) => {
        // this.createTag.labelIds.push(item.id);
        for (var i = 0; i < this.newTagList.length; i++) {
          if (this.newTagList[i].id == item.id) {
            this.newTagList[i].generateMode = item.generateMode;
          }
        }
      });
    },
    //取消标签
    cencleLabel(type) {
      let that = this;
      let list = that.createTag.labelIds;
      let ids = [];
      if (type) {
        //取消当前标签
        that.addTypeList.forEach((item) => {
          if (item.name == that.choiceTypeName) {
            // item.isActive = false;
            // item.hitsNums = 0;
            item.labels.forEach((i) => {
              let index = list.indexOf(i.id);
              // console.log(index)
              if (index !== -1 && i.generateMode == "RULE_BASED") {
                item.hitsNums = 0;
                ids.push(that.createTag.labelIds[index]);
                that.createTag.labelIds.splice(index, 1);
              }
              // console.log(i)
              // this.createTag.labelIds = list.splice((data) => data != i.id);
            });
          } else {
            item.labels.forEach((i) => {
              ids.forEach((x) => {
                if (x == i.id && i.generateMode == "RULE_BASED") {
                  item.hitsNums--;
                }
              });
              // let index = list.indexOf(i.id);
              // console.log(index)
              // if (index!==-1) {
              //   item.hitsNums = 0;
              //   ids.push(that.createTag.labelIds[index])
              //   that.createTag.labelIds.splice(index, 1);
              // }
              // console.log(i)
              // this.createTag.labelIds = list.splice((data) => data != i.id);
            });
          }
        });
        this.$message.success("取消成功");
        // console.log(this.createTag.labelIds);
      } else {
        // console.log(11111);
        //取消全部
        that.addTypeList.forEach((item) => {
          item.labels.forEach((i) => {
            let index = list.indexOf(i.id);
            // console.log(index)
            if (index !== -1 && i.generateMode == "RULE_BASED") {
              item.hitsNums--;
              ids.push(that.createTag.labelIds[index]);
              that.createTag.labelIds.splice(index, 1);
            }
            // console.log(i)
            // this.createTag.labelIds = list.splice((data) => data != i.id);
          });
          item.isActive = false;
        });
        // console.log(that.createTag.labelIds);
        // that.createTag.labelIds.splice(0, that.createTag.labelIds.length);
        // console.log(that.createTag.labelIds)
        // this.addTypeListNum = [];
        // let data = this.addTypeList;
        // that.addTypeList.forEach((item) => {
        //   item.hitsNums = 0;
        //   item.isActive = false;
        // });
        // that.addTypeList = data;
        that.addTypeList[0].isActive = true;
        that.choiceTypeName = that.addTypeList[0].name;
        that.newTagList = that.addTypeList[0].labels;
        that.newTagSerchList = that.addTypeList[0].labels;
        that.$message.success("取消成功");
        // this.getLabelCategoryOptions();
      }
    },
    handleCheckbox(val) {
      let labelIds = this.createTag.favouriteLabelIds;
      let list = [];
      if (this.labelIds.length !== this.createTag.labelIds.length) {
        list = this.labelIds
          .concat(this.createTag.labelIds)
          .filter(function (v, i, arr) {
            return arr.indexOf(v) === arr.lastIndexOf(v);
          });
        let index = labelIds.indexOf(list[0]);
        if (index == -1) {
          labelIds = [...labelIds, ...list];
        } else {
          labelIds.splice(index, 1);
        }
        this.$set(this.createTag, "favouriteLabelIds", labelIds);
        this.labelIds = this.createTag.labelIds;
        this.favouriteLabelIds = labelIds;
      }
    },
    //常用 /个性化标签选中
    handleUsedTagsCheckbox(val) {
      let list = [];
      let labelIds = this.createTag.labelIds;
      list = this.favouriteLabelIds.concat(val).filter(function (v, i, arr) {
        return arr.indexOf(v) === arr.lastIndexOf(v);
      });
      let index = labelIds.indexOf(list[0]);
      if (index == -1) {
        labelIds = [...labelIds, ...list];
      } else {
        labelIds.splice(index, 1);
      }
      this.$set(this.createTag, "labelIds", labelIds);
      this.favouriteLabelIds = val;
      this.labelIds = labelIds;
      this.usedTagsKey++;
    },
    handlePersonalizedTagsCheckbox(val) {},
    search() {
      let text = this.searchTag;
      let data = [];
      if (text) {
        this.addTypeList.forEach((item, index) => {
          item.labels.forEach((i, index1) => {
            if (i.name.toUpperCase().match(text.toUpperCase())) {
              let val = {
                name: "",
                type: "",
                id: "",
                category: "技能标签",
                typeName: "",
                categoryName: "系统标签",
              };
              val.id = i.id;
              val.name = i.name.replaceAll(
                text,
                '<font color="#ff3f3f">' + text + "</font>"
              );
              val.typeName = item.name;
              val.type = "系统标签 > " + item.name + " > " + i.name;
              data.push(val);
            }
          });
        });
        this.usedTags.forEach((item, index) => {
          if (item.name.toUpperCase().match(text.toUpperCase())) {
            let val = {
              name: "",
              type: "",
              id: "",
              category: "常用 /个性化标签",
              typeName: "",
              categoryName: "常用标签",
            };
            val.id = item.id;
            val.name = item.name.replaceAll(
              text,
              '<font color="#ff3f3f">' + text + "</font>"
            );
            val.typeName = item.name;
            val.type = "常用 /个性化标签 > " + item.name;
            data.push(val);
          }
        });
        this.personalizedTags.list.forEach((item, index) => {
          if (item.name.toUpperCase().match(text.toUpperCase())) {
            let val = {
              name: "",
              type: "",
              id: "",
              category: "常用 /个性化标签",
              typeName: "",
              categoryName: "个性化标签",
            };
            val.id = item.id;
            val.name = item.name.replaceAll(
              text,
              '<font color="#ff3f3f">' + text + "</font>"
            );
            val.typeName = item.name;
            val.type = "常用 /个性化标签 > " + item.name;
            data.push(val);
          }
        });
      }
      this.serchChoiceList = data;
      this.searchHoverContentIsShow = true;
      // this.newTagList = this.newTagSerchList.filter(
      //   (item) => item.name.indexOf(this.searchTag) > -1
      // );
    },
    //选中
    chioceData(i) {
      this.choiceTypeName = i.typeName;
      let list = [];
      list = this.createTag.labelIds;
      let customLabelIds = this.createTag.customLabelIds;
      let favouriteLabelIds = this.createTag.favouriteLabelIds;
      let item = [i.id];
      if (i.categoryName == "系统标签") {
        if (!this.createTag.labelIds.includes(i.id)) {
          if (list.length === 0) {
            this.createTag.labelIds = [...item];
          } else {
            this.createTag.labelIds = [...list, ...item];
          }
        } else {
          this.$message.error("标签已选");
        }
      } else if (i.categoryName == "常用标签") {
        if (!this.createTag.favouriteLabelIds.includes(i.id)) {
          if (favouriteLabelIds.length === 0) {
            this.createTag.favouriteLabelIds = [...item];
          } else {
            this.createTag.favouriteLabelIds = [...favouriteLabelIds, ...item];
          }
        } else {
          this.$message.error("标签已选");
        }
      } else if (i.categoryName == "个性化标签") {
        if (!this.createTag.customLabelIds.includes(i.id)) {
          if (customLabelIds.length === 0) {
            this.createTag.customLabelIds = [...item];
          } else {
            this.createTag.customLabelIds = [...customLabelIds, ...item];
          }
        } else {
          this.$message.error("标签已选");
        }
      }
      this.searchTag = "";
      this.searchHoverContentIsShow = false;
    },
    save() {
      if (this.resumeId instanceof Array) {
        this.createTag.resumeIds = this.resumeId;
        this.createTag.batchOperate = true;
      } else {
        this.createTag.resumeIds = [this.resumeId];
        this.createTag.batchOperate = false;
      }

      // this.createTag.favouriteLabelIds = [];
      // this.createTag.customLabelIds = [];
      // this.personalizedTags.list.forEach((item) => {
      //   this.createTag.favouriteLabelIds.push(item.id);
      // });
      // this.usedTags.forEach((item) => {
      //   this.createTag.customLabelIds.push(item.id);
      // });
      if (
        this.createTag.labelIds.length > 0 ||
        this.createTag.favouriteLabelIds.length > 0 ||
        this.createTag.customLabelIds.length > 0
      ) {
        labelAddRemarksToResume(this.createTag).then((res) => {
          if (res.success) {
            this.$message.success(res.message);
            this.dialogVisible = false;
            this.dialogVisibleRemark = false;
            this.$parent.handleClose();
            this.$emit("loadList");
            //   this.loadResumeDetils(this.resumeId);
            // this.getLabelCategoryOptions();
          }
          this.$parent.saveloading = false;
        });
      } else {
        this.$emit("changeSaveLoading", false);
        this.$message.error("请选择标签后再保存");
      }
    },
    //保存常用标签
    saveTags() {
      this.saveTagsloading = true;
      this.$refs.labels.saveTags();
    },
    //关闭弹窗
    handleClose() {
      this.dialogVisibleOfTag = false;
      this.saveTagsloading = false;
      this.personalizedTagsDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.searchHoverContent {
  position: absolute;
  width: 955px;
  background: #fff;
  z-index: 9;
}
.rowContent {
  padding: 15px;
  height: 425px;
  .rowList {
    margin-bottom: 20px;
    clear: both;
    .title {
      margin-bottom: 10px;
      font-weight: 700;
    }
    .tabHref {
      color: #526ecc !important;
    }
    span {
      color: #999;
      font-size: 12px;
      margin-left: 8px;
      font-weight: 400;
    }
    .labelList {
      clear: both;
      .label {
        margin: 10px 0;
        p {
          border: 1px #ececec solid;
          display: inline-flex;
          padding: 2px 8px;
          border-radius: 4px;
          margin-right: 10px;
          margin-left: 0px !important;
          margin-bottom: 10px;
          float: left;
        }
      }
    }
  }
  .noDataTips {
    margin: 20px auto;
    text-align: center;
  }
}
</style>
