<template>
  <section style="background-color: #fff">
    <div class="drawerCon_from">
      <el-form
        @submit.native.prevent
        :model="company"
        ref="company"
        :rules="rules"
        :validate-on-rule-change="false"
        v-loading="formLoading"
        label-width="120px"
        label-position="right"
      >
        <p class="drawerCon_from_tit">基本信息</p>
        <el-row :gutter="24" v-if="company.company">
          <el-col :offset="1" :span="10">
            <el-form-item label="公司名称：" prop="company.name">
              <el-input
                placeholder="请输入内容，支持输入简称，比如卡思"
                v-model="company.company.name"
                maxlength="100"
                class="comCls searchInput"
                @keyup.enter.native="getComInfo(company.company.name)"
                @blur="getComInfo(company.company.name)"
              >
                <template slot="append">
                  <el-icon
                    class="el-icon-search"
                    @click.native="getComInfo(company.company.name)"
                  ></el-icon>
                </template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :offset="1" :span="10">
            <el-form-item label="简称：" prop="company.commonCompanyName">
              <el-input
                maxlength="12"
                v-model="company.company.commonCompanyName"
              ></el-input>
              <el-popover
                placement="bottom-end"
                width="200"
                trigger="hover"
                content="简称指该公司方便大家理解的对外常用的名称，比如‘行吟信息科技（上海）有限公司’简称可以为‘小红书’，‘腾讯科技(深圳)有限公司’简称可以为‘腾讯’或‘腾讯科技’"
              >
                <i slot="reference" class="iconfont icon-kh_wh jcTips"></i>
              </el-popover>
            </el-form-item>
          </el-col>
          <el-col :offset="1" :span="10">
            <el-form-item label="行业：" prop="companyIndustryIds">
              <el-cascader
                filterable
                clearable
                collapse-tags
                class="fromInput"
                ref="cascader"
                :show-all-levels="false"
                v-model="company.companyIndustryIds"
                :options="industryList"
                style="width: 100%"
                :props="{
                  value: 'id',
                  label: 'name',
                  children: 'children',
                  checkStrictly: true,
                  multiple: true,
                  disabled: 'hasChild',
                }"
                placeholder="请选择"
              ></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :offset="1" :span="10">
            <el-form-item label="类型：" prop="company.status">
              <el-select
                placeholder="请选择"
                filterable
                :disabled="!company.hasResetPermission"
                v-model="company.company.status"
                style="width: 100%"
              >
                <el-option
                  v-for="(item, index) in customerType"
                  :key="'a_' + index"
                  :value="item.value"
                  :disabled="
                    (item.value == '开发中' && !company.hasResetDevelop) ||
                    (item.value == 'trial_case' && !company.hasReseTrialCase) ||
                    (item.value == '终止合作' &&
                      !company.hasReseStopCooperate) ||
                    item.value == '签约中' ||
                    item.value == '合同到期' ||
                    item.value == '已签约'
                  "
                  :label="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :offset="1" :span="10">
            <el-form-item label="企业性质：" prop="company.natureType">
              <el-select
                placeholder="请选择"
                filterable
                v-model="company.company.natureType"
                style="width: 100%"
              >
                <el-option
                  v-for="(item, index) in natureTypeList"
                  :key="'n_' + index"
                  :value="item.value"
                  :label="item.name"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :offset="1" :span="10">
            <el-form-item label="城市：" prop="allCityIds">
              <el-cascader
                style="width: 100%"
                v-model="company.allCityIds"
                :options="cityList"
                filterable
                :props="{
                  value: 'id',
                  label: 'name',
                  children: 'children',
                  checkStrictly: true,
                }"
                :show-all-levels="false"
                placeholder="请选择"
              ></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :offset="1" :span="10">
            <el-form-item
              label="BD线索顾问："
              prop="company.clueConsultantId"
              :rules="
                company.clueConsultantInfo.canEdit &&
                (company.company.status == 'trial_case' ||
                  company.company.status == '签约中' ||
                  company.company.status == '已签约')
                  ? {
                      required: true,
                      message: '请选择BD线索顾问',
                      trigger: 'change',
                    }
                  : []
              "
            >
              <el-select
                style="width: 100%"
                v-model="company.company.clueConsultantId"
                clearable
                filterable
                :disabled="!company.clueConsultantInfo.canEdit"
                placeholder="请选择"
                @change="consultantLinkage"
              >
                <el-option
                  v-for="i in userList"
                  :key="i.id"
                  :label="i.name"
                  :value="i.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :offset="1" :span="10">
            <el-form-item label="地址：" prop="company.address">
              <el-input
                placeholder="请输入"
                maxlength="100"
                v-model.trim="company.company.address"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :offset="1" :span="10">
            <el-form-item
              label="BD主导顾问："
              prop="company.leadingConsultantId"
              :rules="
                company.clueConsultantInfo.canEdit &&
                (company.company.status == 'trial_case' ||
                  company.company.status == '签约中' ||
                  company.company.status == '已签约')
                  ? {
                      required: true,
                      message: '请选择BD主导顾问',
                      trigger: 'change',
                    }
                  : []
              "
            >
              <el-select
                style="width: 100%"
                clearable
                filterable
                :disabled="!company.leadingConsultantInfo.canEdit"
                v-model="company.company.leadingConsultantId"
                placeholder="请选择"
                @change="consultantLinkage"
              >
                <el-option
                  v-for="i in userList"
                  :key="i.id"
                  :label="i.name"
                  :value="i.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :offset="1" :span="10">
            <el-form-item label="合作情况：" prop="labelIds">
              <el-select
                style="width: 100%"
                clearable
                filterable
                multiple
                collapse-tags
                v-model="company.labelIds"
                placeholder="请选择"
              >
                <el-option
                  v-for="i in labelList"
                  :key="i.id"
                  :label="i.name"
                  :value="i.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :offset="1" :span="10">
            <el-form-item label="参与顾问：" prop="participantIds">
              <el-cascader
                :key="participantKey"
                class="searchCascader"
                style="width: 100%"
                filterable
                clearable
                :options="options2"
                collapse-tags
                :show-all-levels="false"
                v-model="company.participantIds"
                :props="props"
                placeholder="请选择小类"
                ref="dataRef"
                :filter-method="filterFun"
                :disabled="!company.canEditParticipant && customId"
              >
              </el-cascader>
            </el-form-item>
          </el-col>
          <el-col :offset="1" :span="10">
            <el-form-item label="公司电话：" prop="company.tel">
              <el-input
                placeholder="请输入"
                maxlength="18"
                v-model.trim="company.company.tel"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :offset="1" :span="10">
            <el-form-item label="公司网址：" prop="company.website">
              <el-input
                placeholder="请不要输入超过50个字"
                maxlength="50"
                v-model.trim="company.company.website"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :offset="1" :span="10">
            <el-form-item
              label="子母公司："
              prop="company.parentChildRelationType"
            >
              <el-radio-group
                v-model="company.company.parentChildRelationType"
                @change="checkParent($event)"
              >
                <el-radio label="无">无</el-radio>
                <el-radio label="子公司">子公司</el-radio>
                <el-radio label="母公司">母公司</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>

          <el-col
            :offset="1"
            :span="10"
            v-if="company.company.parentChildRelationType == '子公司'"
          >
            <el-form-item label="母公司名称：" prop="company.parentId">
              <el-select
                placeholder="请选择"
                filterable
                v-model="company.company.parentId"
                style="width: 100%"
              >
                <el-option
                  v-for="item in AllCompany"
                  :key="item.id"
                  :value="item.id"
                  :label="item.name"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <p class="drawerCon_from_tit">联系人</p>
        <el-row :gutter="24">
          <el-col :offset="1" :span="10">
            <el-form-item
              label="姓名："
              :rules="
                company.company.status != '普通公司'
                  ? { required: true, trigger: 'blur', message: '请输入姓名' }
                  : {}
              "
              prop="newestContact.name"
            >
              <el-input
                placeholder="请输入"
                v-model="company.newestContact.name"
                :type="!canEditContacts && customId ? 'password' : ''"
                :disabled="!canEditContacts && customId"
                maxlength="20"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :offset="1" :span="10">
            <el-form-item
              label="职位："
              :rules="
                company.company.status != '普通公司'
                  ? { required: true, trigger: 'blur', message: '请输入职位' }
                  : {}
              "
              prop="newestContact.position"
            >
              <el-input
                placeholder="请输入"
                v-model="company.newestContact.position"
                maxlength="20"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :offset="1" :span="10">
            <el-form-item label="联系电话：" prop="newestContact.tel">
              <el-input
                placeholder="请输入"
                v-model="company.newestContact.tel"
                :type="!canEditContacts && customId ? 'password' : ''"
                :disabled="!canEditContacts && customId"
                maxlength="50"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :offset="1" :span="10">
            <el-form-item label="邮箱：" prop="newestContact.email">
              <el-input
                placeholder="请输入"
                v-model="company.newestContact.email"
                :type="!canEditContacts && customId ? 'password' : ''"
                :disabled="!canEditContacts && customId"
                maxlength="50"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :offset="1" :span="10">
            <el-form-item
              label="微信或其他："
              prop="newestContact.wechatOrOther"
            >
              <el-input
                placeholder="请输入"
                v-model="company.newestContact.wechatOrOther"
                :type="!canEditContacts && customId ? 'password' : ''"
                :disabled="!canEditContacts && customId"
                maxlength="50"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <p class="drawerCon_from_tit">公司简介</p>
        <el-row :gutter="24">
          <el-col :offset="1" :span="21">
            <el-form-item label="公司简介：" prop="company.description">
              <el-input
                placeholder="请输入"
                maxlength="500"
                v-model="company.company.description"
                type="textarea"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="drawerCon_footer fixedFoot">
      <el-button size="small" @click="resetForm('company')">取消</el-button>
      <el-button
        type="primary"
        size="small"
        :loading="iscompany"
        style="margin-right: 15px"
        @click="saveAdd('company')"
        >保存</el-button
      >
    </div>
    <el-dialog
      :title="
        fuzzyItems.length > 0 || specialItems.length > 0 ? '查询结果' : '提示'
      "
      :visible.sync="dialogVisible"
      width="720px"
      append-to-body
      :before-close="handleClose"
    >
      <ul
        class="comList"
        v-if="fuzzyItems.length > 0 || specialItems.length > 0"
      >
        <li v-for="item in fuzzyItems" :key="item.id">
          <div class="comName">
            <p>{{ item.name }}</p>
          </div>
          <span>{{ item.base }}</span
          ><span class="tabHref fr" @click="checkCom(item)">选择</span>
        </li>
        <li v-for="item in specialItems" :key="item">
          <div class="comName">
            <p>{{ item }}</p>
            <span class="specicon">特殊公司</span>
          </div>
          <span class="tabHref fr" @click="checkCom(item)">选择</span>
        </li>
      </ul>
      <div v-else class="noReuslt">
        <p>无返回结果</p>
        <span>你可以：1.调整公司名称后重试；2.联系管理员添加为特殊公司</span>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import {
  getCommonColumnList,
  getEnableUserBaseInfos,
  addOrEditCustomer,
  GetBusinessInfo,
  GetFuzzyQuery,
  GetsysCompanyOption,
  GetCustomerDetailById,
  cityTree,
  customerCoopOptions,
  treeList,
} from "../../api/api";
import * as data from "../../common/json/commData.json";
export default {
  props: ["customId", "isQuick", "canEditContacts"],
  inject: ["reload"],
  name: "addorEditCustomer",
  data() {
    return {
      formLoading: false,
      industryList: [],
      customerType: data.customerType,
      dialogVisible: false,
      specialItems: [],
      fuzzyItems: [],
      AllCompany: [],
      cityList: [],
      userList: [],
      labelList: [],
      company: {
        allCityIds: [],
        labelIds: [],
        changeStatus: "",
        childrenCompany: [],
        clueConsultantInfo: {
          canEdit: true,
          name: "",
          relativeId: 0,
        },
        company: {
          address: "",
          cityId: null,
          cityIds: "",
          cityParentId: null,
          clueConsultantId: null,
          commonCompanyName: "",
          companyTrackerId: null,
          creditCode: "",
          description: "",
          failReason: "",
          id: null,
          industryId: null,
          industryIds: "",
          industryParentId: null,
          leadingConsultantId: null,
          name: "",
          otherName: "",
          parentId: null,
          parentChildRelationType: "无",
          signDate: "",
          status: "开发中",
          natureType: "",
          tags: "",
          tel: "",
          version: null,
          website: "",
        },
        companyIndustryIds: [],
        companyIndustryList: [],
        companyTrackerName: "",
        newestContact: {
          companyId: null,
          email: "",
          id: null,
          name: "",
          position: "",
          tel: "",
          version: null,
          wechatOrOther: "",
        },
        contractSigned: true,
        hasResetPermission: true,
        hasResetDevelop: true,
        hasReseTrialCase: true,
        leadingConsultantInfo: {
          canEdit: true,
          name: "",
          relativeId: null,
        },
        participantIds: [],
      },
      rules: {
        "company.name": [
          {
            required: true,
            trigger: "blur",
            message: "请输入公司名称",
          },
        ],
        "company.commonCompanyName": [
          {
            required: true,
            trigger: "blur",
            message: "请输入公司简称",
          },
        ],
        companyIndustryIds: [
          {
            required: true,
            trigger: "change",
            message: "请选择行业",
          },
        ],
        "company.status": [
          {
            required: true,
            trigger: "change",
            message: "请选择合作类型",
          },
        ],
        "company.natureType": [
          {
            required: true,
            trigger: "change",
            message: "请选择企业性质",
          },
        ],
        allCityIds: [
          {
            required: true,
            trigger: "change",
            message: "请选择城市",
          },
        ],
        participantIds: [
          {
            required: true,
            trigger: "change",
            message: "请选择参与顾问",
          },
        ],
      },
      iscompany: false,
      options2: [],
      props: {
        value: "id",
        label: "name",
        children: "children",
        multiple: true,
      },
      participantKey: 0,
      natureTypeList:[
      {name:'央企',value:'CENTRAL_ENTERPRISES'},  
      {name:'国企',value:'NATIONAL_ENTERPRISES'},  
      {name:'外资-欧美',value:'EUROPE_AND_UNITED_STATES_FUNDED_ENTERPRISES'},  
      {name:'外资-非欧美',value:'NO_EUROPE_AND_UNITED_STATES_FUNDED_ENTERPRISES'},  
      {name:'民营',value:'PRIVATE_ENTERPRISES'},  
      {name:'政府/事业单位',value:'GOVERNMENT'},  
      ]
    };
  },
  watch: {
    "company.companyIndustryIds"(newValue, oldValue) {
      if (newValue.length > 5) {
        this.$message({
          message: "最多不超过5个",
          duration: 1500,
          type: "error",
        });
        this.$nextTick(() => {
          this.company.companyIndustryIds = oldValue;
        });
      }
    },
  },
  methods: {
    checkParent(e) {
      if (e == "子公司") {
        this.loadAllCompany();
      }
    },
    handleClose(done) {
      this.company.company.name = "";
      done();
    },
    checkCom(item) {
      if (typeof item == "object") {
        GetBusinessInfo({
          comId: this.customId || "",
          companyType: item.companyType,
          id: item.id,
          name: item.name,
        }).then((res) => {
          if (res.success) {
            if (res.result.error_code == 0) {
              if (this.customId) {
                //编辑
                this.company.company.address = res.result.registeredAddress;
                this.company.company.name = item.name;
                this.dialogVisible = false;
              } else {
                this.company.company.address = res.result.registeredAddress;
                this.company.company.name = item.name;
                // this.company.company.commonCompanyName = res.result.alias;
                this.company.company.creditCode = res.result.creditCode;
                this.dialogVisible = false;
              }
            } else if (res.result.error_code == 2) {
              this.$confirm("该公司已创建，请选择是否查看?", "提示", {
                confirmButtonText: "查看",
                cancelButtonText: "取消",
                customClass: "altCls",
                type: "warning",
              })
                .then(() => {
                  this.$emit("tuComInfo", res.result.comId);
                })
                .catch(() => {
                  this.$message({
                    type: "info",
                    message: "已取消",
                  });
                });
            } else {
              this.$alert(res.result.reason, "提示", {
                confirmButtonText: "确定",
                customClass: "altCls",
                callback: (action) => {},
              });
            }
          }
        });
      } else {
        this.company.company.name = item;
        this.dialogVisible = false;
      }
    },
    getComInfo(val) {
      //校验公司名
      if (val) {
        GetFuzzyQuery({ keyword: val }).then((res) => {
          if (res.success) {
            this.specialItems = res.result.specialItems
              ? res.result.specialItems
              : [];
            this.fuzzyItems = res.result.fuzzyItems
              ? res.result.fuzzyItems
              : [];
            this.dialogVisible = true;
          }
        });
      }
    },
    saveAdd(formName) {
      this.iscompany = true;
      console.log(this.company.participantIds);
      //保存客户信息
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (
            this.company.newestContact.name != "" &&
            !this.company.newestContact.tel &&
            !this.company.newestContact.email &&
            !this.company.newestContact.wechatOrOther
          ) {
            this.$message.error("联系电话/邮箱/微信或其他至少填写1项");
            this.iscompany = false;
            return false;
          }
          let status = this.company.company.status;
          addOrEditCustomer(this.company).then((res) => {
            if (res.success) {
              if (res.result) {
                if (res.result.repeat) {
                  let h = this.$createElement;
                  this.$msgbox({
                    title: "提示",
                    message: h(
                      "div",
                      {
                        style: "text-align:center;",
                      },
                      [
                        h(
                          "strong",
                          {
                            style:
                              "font-size:16px;font-weight: 800;margin-top: 10px;",
                          },
                          `${this.company.company.id ? "编辑成功" : "新增成功"}`
                        ),
                        h(
                          "p",
                          {
                            style: "margin: 30px 0px;color:#e60012",
                          },
                          [
                            "客户联系人无法正常保存到人才库（人才重复），请及时到",
                            h(
                              "span",
                              {
                                class: "tabHref",
                                on: {
                                  click: () => {
                                    this.goResunme(this.company.company.id);
                                  },
                                },
                              },
                              "【待处理人才】"
                            ),
                            "处理",
                          ]
                        ),
                      ]
                    ),
                    cancelButtonText: "关闭",
                    showConfirmButton: false,
                    dangerouslyUseHTMLString: true,
                    customClass: "msgClass1",
                    beforeClose: "closeConf",
                  })
                    .then(() => {})
                    .catch(() => {
                      if (!this.company.company.id) {
                        //新增
                        if (this.isQuick) {
                          //快速操作
                          this.$emit("cancel");
                        } else {
                          this.$emit("loadList");
                        }
                        setTimeout(() => {
                          this.$router.push({
                            path: "/customer",
                            query: {
                              ownCustomer: status !== "普通公司" ? true : false,
                              status: status !== "普通公司" ? status : "all",
                              t: +Date.now(),
                            },
                          });
                        }, 100);
                      } else {
                        //编辑
                        this.$emit("tuComInfo", this.company.company.id);
                      }
                      this.resetForm("company");
                      this.iscompany = false;
                    });
                } else {
                  let div = this.company.company.id
                    ? "<p style='font-weight:bold;text-align:center'>编辑成功</p>"
                    : "<p style='font-weight:bold;text-align:center'>新增成功</p>" +
                      "<div style='text-align:center'>联系人已同步至人才库</div>";
                  this.$confirm(div, "提示", {
                    showConfirmButton: false,
                    cancelButtonText: "关闭",
                    customClass: "altCls",
                    dangerouslyUseHTMLString: true,
                  })
                    .then(() => {})
                    .catch(() => {
                      if (!this.company.company.id) {
                        //新增
                        if (this.isQuick) {
                          //快速操作
                          this.$emit("cancel");
                        } else {
                          this.$emit("loadList");
                        }
                        setTimeout(() => {
                          this.$router.push({
                            path: "/customer",
                            query: {
                              ownCustomer: status !== "普通公司" ? true : false,
                              status: status !== "普通公司" ? status : "all",
                              t: +Date.now(),
                            },
                          });
                        }, 100);
                      } else {
                        //编辑
                        this.$emit("tuComInfo", this.company.company.id);
                      }
                      this.resetForm("company");
                      this.iscompany = false;
                    });
                }
              } else {
                this.$message.success(res.message);
                if (!this.company.company.id) {
                  //新增
                  if (this.isQuick) {
                    //快速操作
                    this.$emit("cancel");
                  } else {
                    this.$emit("loadList");
                  }
                  setTimeout(() => {
                    this.$router.push({
                      path: "/customer",
                      query: {
                        ownCustomer: status !== "普通公司" ? true : false,
                        status: status !== "普通公司" ? status : "all",
                        t: +Date.now(),
                      },
                    });
                  }, 100);
                } else {
                  //编辑
                  this.$emit("tuComInfo", this.company.company.id);
                }
                this.resetForm("company");
                this.iscompany = false;
              }
            }
            this.iscompany = false;
          });
        } else {
          this.iscompany = false;
          return false;
        }
      });
    },
    closeConf(action, instance) {},
    goResunme(id) {
      if (!id) {
        //新增
        if (this.isQuick) {
          //快速操作
          this.$emit("cancel");
        } else {
          this.$emit("loadList");
        }
      } else {
        //编辑
        this.$emit("tuComInfo", id);
      }
      this.resetForm("company");
      this.iscompany = false;
      this.$msgbox.close();
      let newUrl = this.$router.resolve({
        path: "/resume/pending",
      });
      window.open(newUrl.href, "_blank");
    },
    loadUserBase() {
      //获取用户列表
      getEnableUserBaseInfos().then((res) => {
        if (res.success) {
          this.userList = res.result;
        }
      });
    },
    loadLabelBase() {
      //获取用户列表
      customerCoopOptions().then((res) => {
        if (res.success) {
          this.labelList = res.result;
        }
      });
    },
    loadAllCompany() {
      //获取所有母公司
      GetsysCompanyOption().then((res) => {
        if (res.success) {
          this.AllCompany = res.result;
        }
      });
    },
    LoadColumnList() {
      //加载自定义列表
      this.industryList = [];
      this.cityList = [];
      getCommonColumnList({
        type: "行业",
      }).then((res) => {
        if (res.success) {
          this.industryList = res.result;
        }
      });
      cityTree().then((res) => {
        if (res.success) {
          this.cityList = res.result;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      if (this.customId) {
        this.$emit("loadList", "edit");
      } else {
        this.$emit("loadList");
      }
      this.$parent.handleClose();
    },
    getInfoById(id) {
      this.formLoading = true;
      GetCustomerDetailById(id).then((res) => {
        this.formLoading = false;
        if (res.success) {
          this.company = res.result;
          this.company.allCityIds = eval(res.result.company.cityIds);
          if (res.result.newestContact == null) {
            this.company.newestContact = {
              companyId: null,
              email: "",
              id: null,
              name: "",
              position: "",
              tel: "",
              version: null,
              wechatOrOther: "",
            };
          }
          this.$set(this.company, "companyIndustryIds", []);
          if (
            res.result.companyIndustryList &&
            res.result.companyIndustryList.length > 0
          ) {
            res.result.companyIndustryList.forEach((item, index) => {
              if (item.industryIds) {
                this.company.companyIndustryIds[index] = eval(item.industryIds);
              }
            });
          }
        }
      });
    },
    // 获取参与顾问
    permissionTree() {
      treeList().then((res) => {
        if (res.success) {
          this.options2 = res.result;
          if (!this.customId) {
            this.company.participantIds.push(
              this.changeDetSelect(
                parseInt(this.getStore("uid")),
                this.options2
              )
            );
            this.participantKey++;
          }
          // this.changeDetSelect(this.options2)
        }
      });
    },
    //参与顾问
    // getSearch(val) {
    //   const _nodeId = this.$refs.dataRef.getCheckedNodes(true);
    //   this.company.participantIds = this.participantIds.map((i) => {
    //     return i[i.length - 1];
    //   });
    // },
    filterFun(list, value) {
      // 弹窗 - 拼音过滤
      return list.data.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
    },
    //递归选中参与顾问
    changeDetSelect(key, treeData) {
      let arr = []; // 在递归时操作的数组
      let returnArr = []; // 存放结果的数组
      let depth = 0; // 定义全局层级
      // 定义递归函数
      function childrenEach(childrenData, depthN) {
        for (var j = 0; j < childrenData.length; j++) {
          depth = depthN; // 将执行的层级赋值 到 全局层级
          arr[depthN] = childrenData[j].id;
          if (childrenData[j].id == key) {
            returnArr = arr.slice(0, depthN + 1); //将目前匹配的数组，截断并保存到结果数组，
            break;
          } else {
            if (
              childrenData[j].children &&
              childrenData[j].children.length > 0
            ) {
              depth++;
              childrenEach(childrenData[j].children, depth);
            }
          }
        }
        return returnArr;
      }
      return childrenEach(treeData, depth);
    },
    consultantLinkage(val) {
      if (!this.customId) {
        let ids = this.company.participantIds;
        let key = this.changeDetSelect(val, this.options2);
        let isadd = true;
        ids.forEach((item) => {
          if (item.toString() == key.toString()) {
            isadd = false;
          } else {
            isadd = true;
          }
        });
        if (isadd) {
          ids.push(this.changeDetSelect(val, this.options2));
          this.company.participantIds = ids;
          this.participantKey++;
        }
      }
    },
  },
  created() {
    // console.log(this.customId,parseInt(this.getStore("uid")))
    this.company.company.id = this.customId;
    this.LoadColumnList(); //加载行业，城市
    this.loadUserBase(); //获取用户列表
    this.loadLabelBase(); //获取标签列表
    this.permissionTree(); //获取参与顾问
    if (this.customId) {
      this.getInfoById(this.customId);
    }
  },
};
</script>
<style lang="scss" >
.comCls {
  .el-input__suffix {
    top: 2px !important;
  }
  .el-input-group__append {
    background: #e60012;
    color: #fff;
    border: 1px #e60012 solid;
  }
}
.jcTips {
  color: #898989;
  font-size: 18px;
  position: absolute;
  display: inline-block;
  right: -30px;
  top: 10px;
  height: 18px;
  line-height: 18px;
  &:hover {
    color: #e60012;
  }
}
.noReuslt {
  text-align: center;
  padding: 30px;
  p {
    line-height: 40px;
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }
  span {
    color: #666;
    font-size: 14px;
  }
}
.comList {
  max-height: 400px;
  overflow: hidden;
  overflow-y: scroll;
  li {
    height: 32px;
    line-height: 32px;
    padding: 0 35px 0 15px;
    cursor: pointer;
    &:hover {
      background-color: #f0f0f0;
    }
    .comName {
      width: 80%;
      float: left;
      p {
        max-width: 60%;
        float: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      span {
        height: 20px;
        width: 62px;
        text-align: center;
        line-height: 20px;
        display: inline-block;
        color: #efa14d;
        border: 1px solid #efa14d;
        border-radius: 3px;
        margin-left: 8px;
      }
    }
  }
}
</style>