
<template>
  <el-dialog
    :visible.sync="dialogVisible"
    append-to-body
    :close-on-click-modal="closeOnClickModal"
    :width="width"
    :fullscreen="fullscreen"
    :top="top"
    :modal="modal"
    :modal-append-to-body="modalAppendToBody"
    :append-to-body="appendToBody"
    :lock-scroll="lockScroll"
    :custom-class="customClass"
    :close-on-press-escape="closeOnPressEscape"
    :before-close="beforeClose"
    :destroy-on-close="true"
  >
    <div slot="title" class="drawerTitle">
      <i :class="'drawIcon iconfont ' + icon"></i>{{ title }}
    </div>

    <div :id="'from_' + seed"></div>
  </el-dialog>
</template>

<script>
import Vue from "vue";
export default {
  name: "e-dialog",
  props: {
    icon: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },

    modal: {
      type: Boolean,
      default: true,
    },

    modalAppendToBody: {
      type: Boolean,
      default: true,
    },

    appendToBody: {
      type: Boolean,
      default: false,
    },

    lockScroll: {
      type: Boolean,
      default: true,
    },

    closeOnClickModal: {
      type: Boolean,
      default: true,
    },

    closeOnPressEscape: {
      type: Boolean,
      default: true,
    },

    showClose: {
      type: Boolean,
      default: true,
    },

    width: String,

    fullscreen: Boolean,

    customClass: {
      type: String,
      default: "",
    },

    top: {
      type: String,
      default: "15vh",
    },
    beforeClose: Function,
    center: {
      type: Boolean,
      default: false,
    },
    props: {
      type: Object,
      default: null,
    },

    destroyOnClose: Boolean,
    seed: Number,
    fromTemplate: Object,
    emit: Object,
  },
  methods: {
    close() {
      this.$destroy(true);
      this.$el.parentNode.removeChild(this.$el);
    },
  },
  data() {
    return {
      dialogVisible: true,
    };
  },
  beforeDestroy() {
    this.close();
  },
  mounted() {
    let that = this;
    this.$nextTick(function () {
      if (this.fromTemplate) {
        let fromTemplate = Vue.extend({
          mixins: [this.fromTemplate],
        });
        let t = new fromTemplate({
          propsData: {
            ...this.props,
          },
          store: window.$globalHub.$store,
          router: window.$globalHub.$router,
        });
        for (let k in this.emit) {
          let v = this.emit[k];
          if (v instanceof Array) {
            t.$on(k, function (args) {
              for (let i = 0; i < v.length; i++) {
                let vv = v[i];
                if (typeof vv == "function") {
                  vv(args);
                } else {
                  that[vv](args);
                }
              }
            });
          } else {
            t.$on(k, v);
          }
        }

        t.$mount("#from_" + this.seed);
      }
    });
  },
};
</script>

