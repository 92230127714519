let caluTitle = function (title, len) {
    if (title.length > len) {
        title = title.substr(0, len) + '...'
    }
    return title
}

let numberFormat = function (value) {
    var param = 0;
    var k = 10000,
        sizes = ['', '万', '亿', '万亿'],
        i;
    if (value >= k) {
        i = Math.floor(Math.log(value) / Math.log(k));
        param = ((value / Math.pow(k, i))).toFixed(2) + sizes[i]
    } else {
        param = value
    }
    return param;
}


export default {
    caluTitle,
    numberFormat
}