// buryingPoint 埋点
// 统一请求路径前缀在libs/axios.js中修改
import {
    getRequest,
    postRequestUseBodyParam
} from './http';

//系统更新消息阅读时长
export const statisticsMessageReadDuration = (params) => {
    return postRequestUseBodyParam('/statistics/duration/message-read-duration', params)
}

//点击新增人才
export const statisticsCandidateAdd = (params) => {
    return postRequestUseBodyParam('/statistics/click/candidate-add', params)
}

//点击上传人才
export const statisticsCandidateUpload = (params) => {
    return postRequestUseBodyParam('/statistics/click/candidate-upload', params)
}

//点击上传人才List
export const statisticsCandidateUploadList = (params) => {
    return postRequestUseBodyParam('/statistics/click/candidate-upload-list', params)
}

//点击全文搜索
export const statisticsFulltextIndex = () => {
    return getRequest('/statistics/click/fulltext-index')
}

//点击全网搜
export const statisticsNetworkWide = () => {
    return getRequest('/statistics/click/network-wide')
}

//点击新增合同
export const statisticsContractAdd = (params) => {
    return postRequestUseBodyParam('/statistics/click/contract-add', params)
}

//新增合同成功
export const statisticsContractAddSuccess = (params) => {
    return postRequestUseBodyParam('/statistics/click/contract-add-success', params)
}

//人才新增-操作时长
export const statisticsResumeAddDuration = (params) => {
    return postRequestUseBodyParam('/statistics/duration/resume-add-duration', params)
}

//点击统计查看消息
export const statisticsMessageRead = (params) => {
    return postRequestUseBodyParam('/statistics/click/message-read', params)
}

//点击统计消息未读总数
export const statisticsMessageCount = (params) => {
    return postRequestUseBodyParam('/statistics/click/message-count', params)
}

//系统消息展示量
export const statisticsMessageShow = (params) => {
    return postRequestUseBodyParam('/statistics/click/message-show', params)
}

//点击框架菜单
export const statisticsFrameClick = (params) => {
    return postRequestUseBodyParam('/statistics/click/frame-click', params)
}

//记录全文搜索详情
export const statisticsResumeIndex = (params) => {
    return postRequestUseBodyParam('/statistics/click/resume-index', params)
}

//人才搜索-全文检索人才详情页停留时间
export const statisticsResumeSearchDetailDuration = (params) => {
    return postRequestUseBodyParam('/statistics/duration/resume-search-detail-duration', params)
}

//记录相似职位点击与添加成功
export const statisticsProjectSimilar = (params) => {
    return postRequestUseBodyParam('/statistics/click/project-similar', params)
}

//记录项目-项目详情-相似职位tab-列表-项目名称和ID点击量
export const statisticsProjectSimilarClick = (params) => {
    return postRequestUseBodyParam('/statistics/click/project-similar-click', params)
}

//记录人才精确搜索；用户仅使用精确搜索模块中的内容进行检索，检索一次上报一次
export const statisticsResumeIndexPrecision = (params) => {
    return postRequestUseBodyParam('/statistics/click/resume-index-precision', params)
}

//记录人才详情-项目tab点击量（包括下拉项点击及常规功能点击）
export const statisticsResumeProject = (params) => {
    return postRequestUseBodyParam('/statistics/click/resume-project', params)
}

//记录人才详情，新窗口打开点击量
export const statisticsResumeNewWindow = (params) => {
    return postRequestUseBodyParam('/statistics/click/resume-new-window', params)
}

//人岗匹配-人才详情页停留时间
export const statisticsProjectResumedDetailDuration = (params) => {
    return postRequestUseBodyParam('/statistics/duration/project-resume-detail-duration', params)
}

//项目-项目详情-候选人tab/相似职位点击量
export const statisticsProjectResumedTabClick = (params) => {
    return postRequestUseBodyParam('/statistics/duration/project-resume-tab-click', params)
}

//记录项目详情-候选人tab-pipeline阶段点击量
export const statisticsProjectPipeline = (params) => {
    return postRequestUseBodyParam('/statistics/click/project-pipeline', params)
}

//记录项目详情-候选人tab-操作系列功能点击量（包括置顶、反馈、标识）
export const statisticsProjectPipelineOperate = (params) => {
    return postRequestUseBodyParam('/statistics/click/project-pipeline-operate', params)
}

//记录加入项目点击量
export const statisticsResumeAddProject = (params) => {
    return postRequestUseBodyParam('/statistics/click/resume-add-project', params)
}

//记录加入项目成功量
export const statisticsResumeProjectSuccess = (params) => {
    return postRequestUseBodyParam('/statistics/click/resume-add-project-success', params)
}

//记录人岗自动匹配-自动匹配人才点击量
export const statisticsResumePositionAutomatic = (params) => {
    return postRequestUseBodyParam('/statistics/click/resume-position-automatic', params)
}

//记录人才搜索-详情显示量；通过搜索结果，打开人才详情一次即算一次
export const statisticsResumeDetailShow = (params) => {
    return postRequestUseBodyParam('/statistics/click/resume-detail-show', params)
}

//人才搜索-全文检索结果点击量
export const statisticsResumeSearchResultClick= (params) => {
    return postRequestUseBodyParam('/statistics/duration/resume-search-result-click', params)
}

//记录人岗自动匹配-自动匹配人才结果点击量
export const statisticsResumePositionAutomaticResultClick= (params) => {
    return postRequestUseBodyParam('/statistics/click/resume-position-automatic-result', params)
}

//记录项目-项目详情-相似职位tab-列表-人才详情显示量
export const statisticsSimilarResumeDetailShow= (params) => {
    return postRequestUseBodyParam('/statistics/click/similar-resume-detail-show', params)
}

//记录人岗自动匹配-移出点击量
export const statisticsClickResumePositionReject= (params) => {
    return postRequestUseBodyParam('/statistics/click/resume-position-reject', params)
}

//记录相似人才点击量
export const statisticsClickSimilarResumeClick= (params) => {
    return postRequestUseBodyParam('/statistics/click/similar-resume-click', params)
}

//记录相似人才加入项目成功量
export const statisticsClickSimilarAddProjectSuccess= (params) => {
    return postRequestUseBodyParam('/statistics/click/similar-add-project-success', params)
}

//记录人脉tab点击量
export const statisticsClickConnectionsClick= (params) => {
    return postRequestUseBodyParam('/statistics/click/connections-click', params)
}

//记录人脉匹配结果点击量
export const statisticsClickConnectionsResultClick= (params) => {
    return postRequestUseBodyParam('/statistics/click/connections-result-click', params)
}

//记录点击框架菜单时长
export const statisticsClickFrameClickDuration= (params) => {
    return postRequestUseBodyParam('/statistics/click/frame-click-duration', params)
}

//人才库-路径操作
export const statisticsResumeOperationOperate= (params) => {
    return postRequestUseBodyParam('/statistics/resume-operation/operate', params)
}

//记录功能或页面跳出
export const statisticsClickFunctionEscape= (params) => {
    return postRequestUseBodyParam('/statistics/click/function-escape', params)
}

//记录标签搜索加入项目成功量
export const statisticsClickLabelIndexAddProjectSuccess= (params) => {
    return postRequestUseBodyParam('/statistics/click/label-index-add-project-success', params)
}

//记录相似人才列表点击量
export const statisticsClickSimilarDetailShow= (params) => {
    return postRequestUseBodyParam('/statistics/click/similar-detail-show', params)
}

//记录点击新增项目
export const statisticsClickProjectAdd= (params) => {
    return postRequestUseBodyParam('/statistics/click/project-add', params)
}