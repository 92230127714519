var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',{staticClass:"Etitle"},[_c('p',[_vm._v("基本信息")]),_c('el-form',{ref:"interviewForm",attrs:{"model":_vm.interviewForm,"label-width":"130px"},nativeOn:{"submit":function($event){$event.preventDefault();}}},[_c('el-row',{staticStyle:{"display":"flex"}},[_c('el-col',[_c('el-form-item',{attrs:{"label":"面试时间：","rules":[
              {
                required: true,
                message: '请选择面试时间',
                trigger: 'change',
              },
            ],"prop":"time"}},[_c('el-date-picker',{staticStyle:{"width":"100%"},attrs:{"type":"datetime","placeholder":"选择日期","value-format":"yyyy-MM-dd HH:mm","format":"yyyy-MM-dd HH:mm"},model:{value:(_vm.interviewForm.time),callback:function ($$v) {_vm.$set(_vm.interviewForm, "time", $$v)},expression:"interviewForm.time"}})],1)],1),_c('el-col',[(!_vm.isEdit)?_c('el-form-item',{attrs:{"label-width":"15px"}},[_c('el-checkbox',{model:{value:(_vm.interviewForm.addToSchedule),callback:function ($$v) {_vm.$set(_vm.interviewForm, "addToSchedule", $$v)},expression:"interviewForm.addToSchedule"}},[_vm._v("添加到日程提醒")])],1):_vm._e()],1),_c('el-col',[_c('el-form-item',{attrs:{"label":"面试轮数：","rules":[
              {
                required: true,
                message: '请输入面试轮数',
                trigger: 'change',
              },
            ],"prop":"num"}},[_c('el-input-number',{staticStyle:{"width":"200px"},attrs:{"min":1},model:{value:(_vm.interviewForm.num),callback:function ($$v) {_vm.$set(_vm.interviewForm, "num", $$v)},expression:"interviewForm.num"}})],1)],1),_c('el-col',[_c('el-form-item',{staticStyle:{"margin-bottom":"0"},attrs:{"label-width":"15px"}},[_c('el-checkbox',{model:{value:(_vm.interviewForm.isFinal),callback:function ($$v) {_vm.$set(_vm.interviewForm, "isFinal", $$v)},expression:"interviewForm.isFinal"}},[_vm._v("终面")])],1)],1)],1)],1)],1),(!_vm.iseditD)?_c('div',{staticClass:"Etitle"},[_c('p',[_c('el-checkbox',{staticStyle:{"margin-right":"8px"},model:{value:(_vm.interviewForm.checked),callback:function ($$v) {_vm.$set(_vm.interviewForm, "checked", $$v)},expression:"interviewForm.checked"}}),_vm._v("通知候选人 ")],1),(_vm.interviewForm.checked)?_c('sendEmailFrom',{ref:"sendEmailFrom",attrs:{"type":"给候选人：客户面试通知","projectId":_vm.projectId,"resumeId":_vm.resumeId,"projectResumeId":_vm.projectResumeId,"interviewRound":_vm.interviewForm.num,"interviewTime":_vm.interviewForm.time},on:{"loadList":_vm.changeLoading}}):_vm._e()],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }