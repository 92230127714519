// 统一请求路径前缀在libs/axios.js中修改
import {
    getRequest,
    postRequest,
    postRequestUseBodyParam,
    putRequest,
    putRequestUseBodyParams,
    getRequestWithStreamResponse,
    deleteRequest,
    getRequestCanCancel,
    postRequestCanCancel,
} from './http';

// 登陆
export const login = (params) => {
    return postRequest('/lietou/login', params)
}
// 登陆为某个用户
export const loginAs = (params) => {
    return postRequest('/sys/user/login/as', params)
}
// 获取用户登录信息
export const userInfo = (params) => {
    return getRequest('/user/info', params)
}
// 发送重置密码邮件
export const sendPasswordResetCode = (params) => {
    return getRequest('/sys/user/sendPasswordResetCode', params)
}
//重置密码
export const resetPassword = (params) => {
    return postRequestUseBodyParam('/sys/user/resetPassword', params)
}
//获取用户信息
export const getCurrentUser = (params) => {
    return getRequest('/sys/user/getCurrentUser', params)
}

// 账户设置保存当前用户基础信息
export const updateCurrentAccount = (params) => {
    return postRequestUseBodyParam('/sys/user/updateCurrentAccount', params)
}

// 账户设置获取当前用户基础信息
export const getCurrentAccount = (params) => {
    return getRequest('/sys/user/getCurrentAccount', params)
}

// 账户设置修改密码
export const updateAccountPassword = (params) => {
    return postRequestUseBodyParam('/sys/user/updateAccountPassword', params)
}

//common
//获取通用角色列表
export const getRoleBaseInfos = (params) => {
    return getRequest('/sys/common/role/getRoleBaseInfos', params)
}
//获取有权限的用户ID列表
export const permissionUserIds = (params) => {
    return getRequest('/sys/common/user/permissionUserIds', params)
}
//获取通用部门树
export const getDepartmentList = (params) => {
    return getRequest('/sys/common/getDepartmentList', params)
}
//获取通用用户列表
export const getUserBaseInfos = (params) => {
    return getRequest('/sys/common/user/getUserBaseInfos', params)
}
//获取启用用户列表
export const getEnableUserBaseInfos = (params) => {
    return getRequest('/sys/common/user/getEnableUserBaseInfos', params)
}
//获取通用自定义字段树
export const getCommonColumnList = (params) => {
    return getRequest('/sys/common/custom-column-tree', params)
}
//获取通用签约公司
export const getCommonSignCompanyList = (params) => {
    return getRequest('/sys/common/SignCompany/getBaseInfos', params)
}
//获取通用客户列表
export const getCompanyBaseInfos = (params) => {
    return getRequest('/sys/common/company/getCompanyBaseInfos', params)
}
//获取模板報告文件列表
export const getCommonTemplateReportList = (params) => {
    return getRequest('/sys/common/getTemplateReportList', params)
}
//新增 编辑备注记录列表
export const addOrEditCommonRecord = (params) => {
    return postRequestUseBodyParam('/common/commentrecord/addOrEdit', params)
}
//获取备注记录列表
export const getCommonRecordList = (params) => {
    return getRequest('/common/commentrecord/getList', params)
}
//获取备注记录列表
export const getRecordPlanList = (params) => {
    return getRequest('/sys/common/comment/getBaseInfos', params)
}
//获取当前登录人是否已配置合同审批流
export const getIsSettingApproval = (params) => {
    return getRequest('/sys/common/contract/getIsSettingApproval', params)
}
//组织架构
//新增/编辑组织架构
export const addOrganizin = (params) => {
    return postRequestUseBodyParam('/sys/department/addOrEdit', params)
}
//获取组织架构
export const OrganizinList = (params) => {
    return getRequest('/sys/department/getList', params)
}
//通过id获取组织架构
export const getDepById = (params) => {
    return getRequest('/sys/department/getDepById', params)
}
//通过id删除组织架构
export const deleteDepById = (params) => {
    return deleteRequest('/sys/department/delete', params)
}
// 获取某个组织类型的部门列表
export const getGroupsByType = (params) => {
    return getRequest('/sys/department/list/1', params)
}
export const getAreasByType = (params) => {
    return getRequest('/sys/department/list/2', params)
}
export const getTeamsByType = (params) => {
    return getRequest('/sys/department/list/3', params)
}
export const getSubTeamsByType = (params) => {
    return getRequest('/sys/department/list/4', params)
}

//新增/编辑菜单权限
export const addMenuPermission = (params) => {
    return postRequestUseBodyParam('/sys/permission/addOrEdit', params)
}
//获取菜单权限
export const MenuPermissionList = (params) => {
    return getRequest('/sys/permission/getAllList', params)
}
//通过id获取菜单权限
export const getMenuPermission = (params) => {
    return getRequest('/sys/permission/getPermById', params)
}
//通过id删除菜单权限
export const deleteMenuPermission = (params) => {
    return deleteRequest('/sys/permission/delete', params)
}
//角色管理
//新增/编辑角色
export const addRoles = (params) => {
    return postRequestUseBodyParam('/sys/role/addoredit', params)
}
//获取角色
export const RolesList = (params) => {
    return getRequest('/sys/role/getList', params)
}
//通过id获取角色
export const getRoles = (params) => {
    return getRequest('/sys/role/getRoleById', params)
}
//通过id删除角色
export const deleteRoles = (params) => {
    return deleteRequest('/sys/role/delete', params)
}

// 用户管理
//新增/编辑
export const addUser = (params) => {
    return postRequest('/sys/user/addOrEdit', params)
}
//获取
export const UserList = (params) => {
    return postRequest('/sys/user/getPageUser', params)
}
//通过id获取
export const getUser = (params) => {
    return getRequest('/sys/user/getUserById', params)
}
//更新状态
export const putUser = (params) => {
    return postRequestUseBodyParam('/sys/user/updateUserStatus', params)
}

/* 自定义字段 */
// 新增/编辑 自定义
export const addOrEditCustom = (params) => {
    return postRequestUseBodyParam('/sys/customcolumn/addOrEdit', params)
}
// 删除自定义
export const deleteCustom = (params) => {
    return deleteRequest('/sys/customcolumn/delete', params)
}

// 根据ID获取自定义
export const getCustomById = (params) => {
    return getRequest('/sys/customcolumn/getColumnById', params)
}
/* 特殊公司管理 */
// 特殊公司分页列表
export const getPageSpecial = (params) => {
    return getRequest('/sys/specialcompany/getPagedList', params)
}
// 新增/编辑 特殊公司
export const addOrEditSpecial = (params) => {
    return postRequestUseBodyParam('/sys/specialcompany/addOrEdit', params)
}
// 根据ID获取特殊公司
export const getSpecialById = (params) => {
    return getRequest('/sys/specialcompany/getDetailById', params)
}

//备注管理
// 备注分页列表
export const getPageComment = (params) => {
    return getRequest('/sys/comment/getPageComment', params)
}
// 新增/编辑 备注
export const addOrEditComment = (params) => {
    return postRequestUseBodyParam('/sys/comment/addOrEdit', params)
}
// 删除备注
export const deleteComment = (params) => {
    return deleteRequest('/sys/comment/delete', params)
}

// 根据ID获取备注
export const getCommentById = (params) => {
    return getRequest('/sys/comment/getCommentById', params)
}
// 获取最新备注
export const latestComment = (params) => {
    return getRequest('/sys/comment/latestComment', params)
}
//备注配置管理
// 新增/编辑 备注配置
export const addOrEditCommentColumn = (params) => {
    return postRequestUseBodyParam('/sys/commentcolumn/batchOperate', params)
}
// 新增/编辑 备注配置
export const changeStatusColumn = (params) => {
    return postRequest('/sys/comment/changeStatus', params)
}
// 根据ID获取备注配置
export const getCommentColumnById = (params) => {
    return getRequest('/sys/commentcolumn/getCommentColumn', params)
}

/* 模板报告管理 */

// 模板报告分页列表
export const getTemplatereportList = (params) => {
    return getRequest('/sys/templatereport/getPagedList', params)
}
// 新增/编辑 模板报告
export const addOrEditTemplatereport = (params) => {
    return postRequestUseBodyParam('/sys/templatereport/addOrEdit', params)
}
// 删除模板报告
export const deleteTemplatereport = (params) => {
    return deleteRequest('/sys/templatereport/delete', params)
}

// 根据ID获取模板报告
export const getTemplatereportById = (params) => {
    return getRequest('/sys/templatereport/getDetailById', params)
}

//默认业绩分配管理
export const getdefaultKPIList = (params) => {
    return getRequest('/sys/performanceallocationplan/getList', params)
}
// 新增/编辑 业绩分配
export const addOrEditdefaultKPI = (params) => {
    return postRequestUseBodyParam('/sys/performanceallocationplan/addOrEdit', params)
}
// 删除业绩分配
export const deletedefaultKPI = (params) => {
    return deleteRequest('/sys/performanceallocationplan/delete', params)
}

// 根据ID获取模板报告
export const getdefaultKPIById = (params) => {
    return getRequest('/sys/performanceallocationplan/getById', params)
}

//审批流设置
export const getflowroleById = (params) => {
    return getRequest('/sys/approvalflowrole/getRoleIds', params)
}
// 新增/编辑 审批流设置
export const addOrEditflowrole = (params) => {
    return postRequestUseBodyParam('/sys/approvalflowrole/configRoles', params)
}
//审批流List
export const getConfiguredFlow = (params) => {
    return getRequest('/sys/approvalflowrole/getConfiguredFlow', params)
}

// 签约公司分页列表
export const getSigncompanyList = (params) => {
    return getRequest('/sys/signcompany/getPagedList', params)
}
// 新增/编辑 签约公司
export const addOrEditSigncompany = (params) => {
    return postRequestUseBodyParam('/sys/signcompany/addOrEdit', params)
}

// 根据ID获取签约公司
export const getSigncompanyById = (params) => {
    return getRequest('/sys/signcompany/getDetailById', params)
}

//收藏夹

//获取收藏夹列表
export const getListFavorite = (params) => {
    return getRequest('/favorite/favorite/getList', params)
}
// 根据ID获取收藏夹
export const getFavoriteById = (params) => {
    return getRequest('/favorite/favorite/getById', params)
}
// 删除收藏夹
export const deleteFavorite = (params) => {
    return deleteRequest('/favorite/favorite/delete', params)
}
// 新增/编辑 收藏夹
export const addOrEditFavorite = (params) => {
    return postRequestUseBodyParam('/favorite/favorite/addOrEdit', params)
}
// 添加到收藏夹
export const addToFavorite = (params) => {
    return postRequestUseBodyParam('/favorite/favorite/addToFavorite', params)
}
//从收藏夹移出
export const removeFromFavorite = (params) => {
    return postRequestUseBodyParam('/favorite/favorite/removeFromFavorite', params)
}

//订阅搜索管理
//新增-编辑
export const SearchAddOrEdit = (params) => {
    return postRequestUseBodyParam('/common/subscribeSearch/addOrEdit', params)
}
// 根据ID获取方案
export const GetSearchById = (id) => {
    return getRequest(`/common/subscribeSearch/${id}`)
}
//获取列表
export const GetSearchPage = (params) => {
    return getRequest('/common/subscribeSearch/page', params)
}
//通过id删除方案
export const deleteSearchById = (id) => {
    return deleteRequest(`/common/subscribeSearch/${id}`)
}
// 客户
//联系人编辑新增
export const comContactsAddOrEdit = (params) => {
    return postRequestUseBodyParam('/company/contacts/addOrEdit', params)
}
export const comContactsAdds = (params) => {
    return postRequestUseBodyParam('/company/contacts/addInfo', params)
}
// 获取客户分页列表
export const GetCustomerPagedList = (params) => {
    return postRequestUseBodyParam('/company/company/page', params)
}
// 新增/编辑 客户
export const addOrEditCustomer = (params) => {
    return postRequestUseBodyParam('/company/company/addOrEdit', params)
}
// 获取所有客户下拉列表
export const GetAllCompanyOption = (params) => {
    return getRequest('/company/company/getAllCompanyOption', params)
}

// 获取关联数量
export const getRelatedCount = (id) => {
    return getRequest(`/company/company/relatedCount/${id}`)
}
// 编辑 合作信息基本信息
export const SaveContractBaseInfo = (params) => {
    return postRequestUseBodyParam('/company/company/SaveContractBaseInfo', params)
}
// 保存客户标签
export const SaveCompanyTags = (params) => {
    return postRequestUseBodyParam('/company/company/SaveCompanyTags', params)
}
// 查询合作信息编辑信息
export const GetContractInfoById = (params) => {
    return getRequest('/company/company/GetContractInfoById', params)
}
// 查询编辑信息
export const GetCustomerDetailById = (id) => {
    return getRequest(`/company/company/${id}`)
}

//查看公司详情
export const GetCompanyById = (id) => {
    return getRequest(`/company/company/detail/${id}`)
}
// 天眼查查询工商信息
export const GetBusinessInfo = (params) => {
    return getRequest('/company/company/businessInfo', params)
}
// 天眼查模糊查询
export const GetFuzzyQuery = (params) => {
    return getRequest('/company/company/fuzzyQuery', params)
}
// 新增母公司子公司
export const AddRelativeCompany = (params) => {
    return getRequest('/company/company/AddRelativeCompany', params)
}
// 新增母公司子公司
export const GetRelativeCompanyOption = (params) => {
    return getRequest('/company/company/GetRelativeCompanyOption', params)
}
// 新增或编辑客户list
export const addOrEditComList = (params) => {
    return postRequestUseBodyParam('/company/comlist/addOrEdit', params)
}
// 获取客户list列表
export const getComListPagedList = (params) => {
    return getRequest('/company/comlist/getPagedList', params)
}

//导出客户列表
export const getCompanyExport = (params) => {
    return postRequestUseBodyParam('/company/company/export', params)
}

// 获取客户所有操作日志
export const getOperateList = (id) => {
    return getRequest(`/company/operateRecord/${id}`)
}
// 获取项目所有操作日志
export const getProOperateList = (id) => {
    return getRequest(`/project/info/operateRecord/${id}`)
}
//附件管理
//新增或编辑
export const attachmentAddOrEdit = (params) => {
    return postRequestUseBodyParam('/common/attachment/add-batch', params)
}
// 获取附件分页列表
export const getAttachmentPage = (params) => {
    return postRequestUseBodyParam('/common/attachment/page', params)
}
// 根据ID获取附件信息
export const getAtachmentInfo = (id) => {
    return getRequest(`/common/attachment/${id}`)
}
/* 项目管理 */
// 项目分页列表(高级搜索)
export const getProjectPageList = (params) => {
    return postRequestUseBodyParam('/project/project/page', params)
}
// 候选人移出项目
export const removeProject = (params) => {
    return deleteRequest('/project/projectresume', params)
}

// 新增/编辑 项目
export const addOrEditProject = (params) => {
    return postRequestUseBodyParam('/project/project/addOrEdit', params)
}
// 判断当前用户是否可以操作某个项目-编辑
export const canOperate = (params) => {
    return getRequest(`/project/project/canOperate`, params)
}
// 获取项目详情
export const getProjectDetail = (id) => {
    return getRequest(`/project/project/detail/${id}`)
}
// 获取项目关联数量
export const getproRelatedCount = (id) => {
    return getRequest(`/project/project/relatedCount/${id}`)
}
//项目导出list
export const exportProjectList = (params) => {
    return postRequestUseBodyParam('/project/project/export', params)
}
// 项目权限
export const getPermissionUsers = (params) => {
    return getRequest('/project/projectpermission/getPermissionUsers', params)
}

// 项目分析
export const projectAnalysis = (params) => {
    return getRequest('/project/projectresume/analysis', params)
}

// 添加候选人到项目
export const addResumesProjectresume = (params) => {
    return postRequest('/project/projectresume/addResumes', params)
}

// 获取当前用户是否可以操作某个项目
// export const canOperateProject = (params) => {
//     return getRequest('/project/project/canOperate', params)
// }

// 用ID获取项目（用于编辑）
export const getProjectById = (id) => {
    return getRequest(`/project/project/${id}`)
}
// 项目候选人分页列表
export const statusInfoWithHistory = (params) => {
    return postRequestUseBodyParam('/project/projectresume/statusInfoWithHistory', params)
}
// 项目候选人分页列表
export const getProjectresumePageList = (params) => {
    return postRequestUseBodyParam('/project/projectresume/getPageList', params)
}

// 更新联系状态
export const postContactStatus = (params) => {
    return postRequestUseBodyParam('/project/projectresume/contactStatus', params)
}

//合同管理
//获取合同列表
export const getContractPageList = (params) => {
    return getRequest('/company/contract/GetPagedList', params)
}

// 项目候选人操作日志

// 新增/编辑 背景调查操作记录
export const addOrEditBackground = (params) => {
    return postRequestUseBodyParam('/project/operaterecord/addOrEditBackground', params)
}

// 新增/编辑 入职操作记录
export const addOrEditEntry = (params) => {
    return postRequestUseBodyParam('/project/operaterecord/addOrEditEntry', params)
}

// 新增/编辑 失败操作记录
export const addOrEditFail = (params) => {
    return postRequestUseBodyParam('/project/operaterecord/addOrEditFail', params)
}

// 新增/编辑 面试操作记录
export const addOrEditInterview = (params) => {
    return postRequestUseBodyParam('/project/operaterecord/addOrEditInterview', params)
}

// 新增/编辑 Offer操作记录
export const addOrEditOffer = (params) => {
    return postRequestUseBodyParam('/project/operaterecord/addOrEditOffer', params)
}
// 延期入职
export const delayEntry = (params) => {
    return postRequestUseBodyParam('/project/operaterecord/delay-entry', params)
}
// 新增/编辑 推荐操作记录
export const addOrEditOperaterecordRecommend = (params) => {
    return postRequestUseBodyParam('/project/operaterecord/addOrEditRecommend', params)
}

// 根据ID获取操作记录
export const getOperaterecordById = (params) => {
    return getRequest('/project/operaterecord/getById', params)
}
// 获取喜报入职时间
export const genEntryTime = (params) => {
    return getRequest('/project/operaterecord/genEntryTime', params)
}

//获取客户下的项目
export const getCompanyProject = (params) => {
    return getRequest('/project/project/selectCompanyProject', params)
}


// 导出
export const exportExcel = (params) => {
    return postRequestUseBodyParam('/project/projectresume/export', params)
}

// 获取导出字段选项
export const getExportOptions = (params) => {
    return postRequest('/project/projectresume/export-column-options', params)
}

// 项目操作记录列表
export const getOperaterecordPageList = (params) => {
    return getRequest('/project/operaterecord/getPageList', params)
}


// 获取合同选项列表
export const getContractBaseInfos = (params) => {
    return getRequest('/sys/common/contract/getContractBaseInfos', params)
}

// 获取客户联系人列表
export const getContactsBaseInfos = (params) => {
    return getRequest('/sys/common/contacts/option', params)
}
// 获取客户下拉列表
export const GetsysCompanyOption = (params) => {
    return getRequest('/sys/common/company/option', params)
}

// 新增/编辑 合同信息
export const addOrEditContract = (params) => {
    return postRequestUseBodyParam('/company/contract/addOrEdit', params)
}
//通过id获取合同详情
export const getContractEditById = (params) => {
    return getRequest('/company/contract/GetContractEditById', params)
}
// 获取合同审批列表
export const getContractApprovalList = (params) => {
    return getRequest('/company/contractapproval/GetPagedList', params)
}
// 获取查看合同和审批信息
export const getContractandApprovalById = (params) => {
    return getRequest('/company/contractapproval/GetContractAndApprovalByContractId', params)
}
//取消审批
export const cancelContractApproval = (params) => {
    return getRequest('/company/contractapproval/cancelContractApproval', params)
}
//审批通过
export const passContractApproval = (params) => {
    return postRequestUseBodyParam('/company/contractapproval/passContractApproval', params)
}
//通过时判断是否为法务
export const getIsLowPass = (params) => {
    return getRequest('/company/contractapproval/getIsLowPass', params)
}
//审批驳回
export const rejectContractAndApproval = (params) => {
    return postRequestUseBodyParam('/company/contractapproval/rejectContractAndApproval', params)
}
// 上传合同
export const uploadContractAndApproval = (params) => {
    return postRequestUseBodyParam('/company/contractapproval/uploadContract', params)
}
// 盖章
export const signContractAndApproval = (params) => {
    return postRequestUseBodyParam('/company/contractapproval/signContract', params)
}
// 编辑合同附件
export const editContractFile = (params) => {
    return postRequestUseBodyParam('/company/contractapproval/edit-contract-file', params)
}

/* 喜报 */
// 根据ID获取喜报
export const goodnewsInfo = (params) => {
    return getRequest('/project/goodnews/info', params)
}

// 获取组织架构中的地区
export const getCompanySource = (params) => {
    return getRequest('/sys/department/companySource', params)
}

// 写报告
export const reportWrite = (params) => {
    return getRequestWithStreamResponse('/project/projectresume/report', params)
}

//简历管理
//简历分页列表
export const selectAdvancePageList = (params) => {
    return postRequestUseBodyParam('/resume/resume/selectAdvancePageList', params)
}
//获取简历详情
export const getResumeById = (params) => {
    return getRequest('/resume/resume/getResumeById', params)
}
//根据临时id获取简历详情(插件)
export const getResumeByTemplateId = (params) => {
    return getRequest('/resume/resume/getResumeByTemplateId', params)
}
// 给候选人打标签
export const addRemarksToResume = (params) => {
    return postRequestUseBodyParam('/resume/resume/addRemarksToResume', params)
}
// 新增/编辑 简历
export const addOrEditResume = (params) => {
    return postRequestUseBodyParam('/resume/resume/addOrEdit', params)
}
//下载简历
export const downloadResume = (params) => {
    return getRequestWithStreamResponse('/resume/resume/downloadResume', params)
}
//获取简历操作记录
export const getResumeOperateRecord = (params) => {
    return getRequest('/resume/operaterecord/getResumeOperateRecord', params)
}
//获取简历报告
export const getResumeReportList = (params) => {
    return getRequest('/resume/report/list', params)
}
//根据邮箱或者手机号码查找重复简历
export const getRepeatedResumeByEmailOrMobile = (params) => {
    return getRequest('/resume/resume/getRepeatedResumeByEmailOrMobile', params)
}
//解锁简历
export const unlockResume = (params) => {
    return getRequest('/resume/resume/unlockResume', params)
}
//已上传人才数量
export const analysisResumeNum = (params, that) => {
    return getRequestCanCancel('/resume/resume/analysisResumeNum', params, that)
}

/* 项目财务任务 */
// 创建财务任务
export const addOrEditFinancetask = (params) => {
    return postRequestUseBodyParam('/finance/finance-task/addOrEdit', params)
}

// 获取关联合同
export const getCompanyContractList = (params) => {
    return getRequest('/company/contract/list', params)
}

// 修改到账金额
export const editAccountMoney = (params) => {
    return postRequestUseBodyParam('/finance/finance-task/edit-account-money', params)
}

// 获取查看合同财务和审批信息
export const getFinanceDetail = (params) => {
    return getRequest('/finance/finance-task/detail', params)
}

// 审批财务任务
export const approvalFinanceApproval = (params) => {
    return postRequestUseBodyParam('/finance/approval/approval', params)
}

//简历字段匹配 编辑
export const resumeValueMatchedit = (params) => {
    return postRequestUseBodyParam('/sys/resume-value-match/edit', params)
}
//简历字段匹配 匹配
export const resumeValueMatchmatch = (params) => {
    return postRequestUseBodyParam('/sys/resume-value-match/match', params)
}
//简历字段匹配 列表
export const resumeValueMatchlist = (params) => {
    return getRequest('/sys/resume-value-match/list', params)
}

// 获取分页信息
export const getFinancetaskList = (params) => {
    return postRequestUseBodyParam('/finance/finance-task/getPagedList', params)
}

// 检查项目操作权限
export const checkOperatePermission = (params) => {
    return postRequestUseBodyParam('/project/project/checkOperatePermission', params)
}
// 简历智能推荐项目列表
export const selectRecommendProject = (params) => {
    return getRequest('/project/project/selectRecommendProject', params)
}
// 获取进展中的有权限的项目选项列表
export const getProjectsInProgress = (params) => {
    return getRequest('/project/project/getProjectsInProgress ', params)
}
// 获取加入项目列表
export const joinProjectList = (params) => {
    return postRequestUseBodyParam('/project/project/selectJoinProject ', params)
}


// 获取简历导入记录
export const getResumeImportRecord = (params) => {
    return getRequest('/resume/analysis-record/getResumeImportRecord', params)
}
// 获取待完善简历列表
export const getNeedUpdateResumeInfoList = (params) => {
    return getRequest('/resume/analysis-record/getNeedUpdateResumeInfoList', params)
}
// 获取疑似重复简历列表
export const getRepeatedResumeInfos = (params) => {
    return getRequest('/resume/analysis-record/getRepeatedResumeInfos', params)
}
//合并简历
export const postMergeResume = (params) => {
    return postRequestUseBodyParam('/resume/analysis-record/merge', params)
}
//上传简历
export const uploadResumes = (params) => {
    return postRequestUseBodyParam('/resume/resume/uploadResumes', params)
}
//上传list简历
export const uploadListResumes = (params) => {
    return postRequestUseBodyParam('/resume/resume/excel', params)
}
// 替换简历
export const replaceResumeInPage = (params) => {
    return postRequestUseBodyParam('/resume/resume/replaceResumeInPage', params)
}
// 替换重复简历
export const replaceRepeatedResume = (params) => {
    return getRequest('/resume/analysis-record/replaceRepeatedResume', params)
}
// 获取简历详情
export const getAnalysisFailRecordByRecordId = (params) => {
    return getRequest('/resume/analysisfailrecord/getAnalysisFailRecordByRecordId', params)

}

// 新增 重置操作记录
export const addReset = (params) => {
    return postRequestUseBodyParam('/project/operaterecord/addReset', params)
}
// 获取重置状态选项
export const getResetStatus = (params) => {
    return getRequest('/project/operaterecord/getResetStatus', params)
}

// 消息列表
export const getPageList = (params) => {
    return getRequest('/common/message/getPageList', params)
}
// 消息列表
export const allGoodNews = (params) => {
    return getRequest('/common/message/all-goodnews', params)
}
// 当前用户未读消息数量
export const getUnReadCount = (params) => {
    return getRequest('/common/message/getUnReadCount', params)
}

// 全部已读
export const readAll = (params) => {
    return postRequest('/common/message/readAll', params)
}
// 单个已读
export const readMessage = (params) => {
    return postRequest('/common/message/readMessage', params)
}
// 喜报标记已关闭
export const closeMessage = (params) => {
    return postRequest('/common/message/readBox', params)
}
//数据支持-业绩详情byID
export const performanceInfo = (params) => {
    return getRequest('/performance/info', params)
}
//数据支持-编辑业绩
export const performanceEdit = (params) => {
    return postRequestUseBodyParam('/performance/edit', params)
}
//获取用户的集团,地区,团队分配数据
export const departAllocations = (params) => {
    return getRequest('/performance/depart-allocations', params)
}

// 更新简历推荐状态
export const recommendStatus = (params) => {
    return postRequestUseBodyParam('/project/projectresume/recommendStatus', params)
}
// 更新简历推荐状态
export const contactStatus = (params) => {
    return postRequestUseBodyParam('/resume/resume/contactStatus', params)
}

// 更新客户面试状态
export const interviewStatus = (params) => {
    return postRequestUseBodyParam('/project/projectresume/interviewStatus', params)
}

// 更新Offer状态
export const offerStatus = (params) => {
    return postRequestUseBodyParam('/project/projectresume/offerStatus', params)
}
// 更新入职状态
export const entryStatus = (params) => {
    return postRequestUseBodyParam('/project/projectresume/entryStatus', params)
}

// 查询kpi考核项
export const getKpiPlanList = (params) => {
    return getRequest('/data/kpi/getKpiPlanList', params)
}
// 查询kpi考核项
export const getKpiDataList = (params) => {
    return getRequest('/data/kpi/getKpiDataList', params)
}
// kpi报表导出
export const exportKpiDetailList = (params) => {
    return postRequestUseBodyParam('/data/kpi/exportKpiDetailList', params)
}
// KPI详情导出
export const exportKpiDetail = (params) => {
    return getRequest('/data/kpi/exportKpiDetail', params)
}
// 新增/编辑kpi
export const addorEdit = (params) => {
    return postRequestUseBodyParam('/data/kpi/addOrEdit', params)
}

// 我的Kpi方案列表
export const myKpiDetailList = (params) => {
    return postRequestUseBodyParam('/data/kpi/myKpiDetailList', params)
}

// Kpi项详情
export const kpiItemDetail = (params) => {
    return getRequest('/data/kpi/kpiItemDetail', params)
}

// 业绩池
// 获取业绩列表
export const performancePoolList = (params) => {
    return postRequestUseBodyParam('/performance-pool/page-list', params)
}

// 导出业绩列表
export const exportPerformancePoolList = (params, cancelToken) => {
    return postRequestCanCancel('/performance-pool/export', params, cancelToken)
}

//倍罗
export const belloProject = (params) => {
    return getRequest('/bello/info/project', params)
}

// 获取客户信息
export const belloCompany = (params) => {
    return getRequest('/bello/info/company', params)
}
// 获取学校信息
export const belloSchool = (params) => {
    return getRequest('/bello/info/school', params)
}
// 获取列表信息
export const bellopreview = (params) => {
    return getRequest('/bello/resume/preview', params)
}
// 简历原文预览
export const previewResume = (params) => {
    return getRequest('/bello/resume/previewResume', params)
}
// 下载原文
export const previewWithDownLoad = (params) => {
    return getRequest('/bello/resume/previewWithDownLoad', params)
}
// 项目列表
export const getResumeProjectPageList = (params) => {
    return getRequest('/project/operaterecord/getResumeProjectPageList', params)
}
// 查询维度 -- 小类
export const permissionTree = (params) => {
    return getRequest('/sys/department/permission-tree', params)
}
// 大类
export const permissionType = (params) => {
    return getRequest('/sys/department/permission-type', params)
}
// Offer业绩详情
export const achievementListOffer = (params) => {
    return postRequestUseBodyParam('/data/archives/achievementList', params)
}
// 获取BD客户
export const bdCompanyPagedList = (params) => {
    return postRequestUseBodyParam('/data/archives/bdCompanyPagedList', params)
}
// 回款
export const accountList = (params) => {
    return postRequestUseBodyParam('/data/archives/accountList', params)
}
// 开票业绩
export const makeInvoiceList = (params) => {
    return postRequestUseBodyParam('/data/archives/makeInvoiceList', params)
}
// 开票业绩
export const exportArchive = (params) => {
    return postRequestUseBodyParam('/data/archives/export', params)
}
// 用户列表
export const getUserList = (params) => {
    return postRequestUseBodyParam('/sys/user/get-dept-user', params)
}

// 通讯录搜索
export const serachGroup = (params) => {
    return getRequest('/sys/department/dept-search-list', params)
}
// 待完善简历
export const updateResume = (params) => {
    return getRequest('/resume/resume/to-complete', params)
}
// 待完善简历
export const resumeMember = (params) => {
    return getRequest('/project/operaterecord/resume-recommend-count', params)
}
// 待完善简历
export const listOfferALl = (params) => {
    return postRequestUseBodyParam('/data/archives/achievement-by-creation-time-list', params)
}

// 已盖章
export const editMark = (params) => {
    return postRequestUseBodyParam('/company/contractapproval/edit-mark', params)
}

// 已盖章
export const cancelOperate = (params) => {
    return postRequestUseBodyParam('/project/operaterecord/cancel-last-operate', params)
}

//删除人才
export const delResume = (params) => {
    return deleteRequest('/resume/resume/delete', params)
}

//删除人才
export const delHasShareName = (params) => {
    return deleteRequest('/favorite/favorite/delete/' + params)
}

//完善开票
export const completeInvoice = (params) => {
    return postRequestUseBodyParam('/finance/approval/complete-invoice', params)
}
//完善到账
export const completeAccount = (params) => {
    return postRequestUseBodyParam('/finance/approval/complete-account', params)
}

// 取消/驳回/作废财务任务
export const approvalCancel = (params) => {
    return postRequestUseBodyParam('/finance/approval/approval', params)
}

// 财务任务
export const getFinanceAccountMoneyRecord = (id) => {
    return getRequest('/finance/account-money-record/' + id)
}

// 编辑财务任务
export const editFinanceAccountMoneyRecord = (params) => {
    return postRequestUseBodyParam('/finance/account-money-record/edit', params)
}

// 用户
export const treeList = (params) => {
    return getRequest('/sys/user/tree-list', params)
}

// 更改标识
export const updateFlagType = (params) => {
    return putRequest('/project/projectresume/updateFlagType', params)
}

// 上传简历附件
export const attachment = (params) => {
    return postRequestUseBodyParam('/resume/attachment/upload', params)
}

// 简历附件分页列表
export const attachmentPageList = (params) => {
    return getRequest('/resume/attachment/pageList', params)
}

// 删除附件
export const deleteAttachment = (params) => {
    return deleteRequest('/resume/attachment/delete', params)
}

// 下载附件
export const fileDownload = (params) => {
    return getRequestWithStreamResponse('/api/file/download', params)
}

// 批量设为保密
export const secureProject = (params) => {
    return postRequestUseBodyParam('/project/project/secure-projects', params)
}
// 判断当前用户是否有设为保密项目的权限
export const hasSecureProject = (params) => {
    return getRequest('project/project/hasUserRolePermission', params)
}
// 取消保密
export const cancelSecure = (params) => {
    return postRequestUseBodyParam('/project/project/cancel-secure', params)
}
// 新增/编辑 红冲
export const reserveSave = (params) => {
    return postRequestUseBodyParam('/finance/reverse/addOrEdit', params)
}


// 是否有项目的查看权限
export const permissionSecure = (params) => {
    return getRequest('/project/project/view-permission', params)
}

// 业绩-分配方案
export const allocationPool = (params) => {
    return getRequest('/performance-pool/allocation', params)
}

// 财务查询分页信息
export const queryPagedList = (params) => {
    return postRequestUseBodyParam('/finance/finance-task/queryPagedList', params)
}

// 删除红冲
export const deleteReverse = (params) => {
    return deleteRequest('/finance/reverse/delete/' + params)
}

// 删除红冲
export const deleteRefund = (params) => {
    return deleteRequest('/finance/refund/delete/' + params)
}

// 退款金额
export const addRefund = (params) => {
    return postRequestUseBodyParam('/finance/refund/add', params)
}

// 退款金额
export const editRefund = (params) => {
    return postRequestUseBodyParam('/finance/refund/edit', params)
}

// 检查修改后的业绩金额是否合规
export const validateMoney = (params) => {
    return postRequestUseBodyParam('/performance/validate_money', params)
}

// 修改开票信息
export const editInvoice = (params) => {
    return postRequestUseBodyParam('/finance/approval/edit-invoice', params)
}

// 修改客户联系人
export const contactsAddOrEdit = (params) => {
    return postRequestUseBodyParam('/company/contacts/addOrEdit', params)
}

// 修改财务任务
export const editProjectInfo = (params) => {
    return getRequest('/finance/finance-task/edit-info/' + params)
}
//日程列表
export const scheduleList = (params) => {
    return getRequest('/schedule/schedule/list', params)
}

// 同事
export const userOptions = (params) => {
    return getRequest('/sys/user/user-and-depart/options', params)
}

// 同事
export const contactsOptions = (params) => {
    return getRequest('/sys/common/contacts/option', params)
}

// 编辑日程
export const editSchedule = (params) => {
    return getRequest('/schedule/schedule/get-edit/' + params)
}

// 删除到账记录
export const delAccontMoney = (params) => {
    return deleteRequest('/finance/account-money-record/delete/' + params)
}

//日程-日程列表
export const schedulePage = (params) => {
    return postRequestUseBodyParam('/schedule/schedule/page', params)
}

//日程-新增编辑日程
export const scheduleAddOrEdit = (params) => {
    return postRequestUseBodyParam('/schedule/schedule/add-or-edit', params)
}

// 日程-日程详情
export const getScheduleById = (id) => {
    return getRequest(`/schedule/schedule/get-edit/${id}`)
}

//获取项目选项列表
export const GetsysProjectOption = (params) => {
    return getRequest('/sys/common/project/option/', params)
}

// 日程-日程标记状态
export const getScheduleStatusById = (params) => {
    return postRequestUseBodyParam('/schedule/schedule/status', params)
}

//获取关联候选人列表
export const GetsysResumeOption = (params) => {
    return getRequest('/sys/common/resume/option', params)
}

// 合同-合同管理列表
export const getContractList = (params) => {
    return postRequestUseBodyParam('/company/contract/pageList', params)
}
export const GetCompanyContactCommentrecordList = (id) => {
    return getRequest(`/common/commentrecord/list-by-contact/${id}`)
}

// 合同-合同审核列表
export const contractApprovalList = (params) => {
    return postRequestUseBodyParam('/company/contractapproval/pageList', params)
}

//判断当前审批人是否为法务审批
export const isLawApproval = (id) => {
    return getRequest('company/contractapproval/isLawApproval')
}

//合同管理-合同详情
export const GetContractManaInfo = (params) => {
    return getRequest('/company/contractapproval/contract', params)
}

//合同审批-审批数量
export const GetApprovalCount = (params) => {
    return getRequest('/company/contractapproval/approvalCount', params)
}

// 设置-定时任务管理
export const getQuartzJobList = (params) => {
    return getRequest('/quartz/job/page', params)
}
// 设置-添加定时任务
export const addQuartzJob = (params) => {
    return postRequest('/quartz/job', params)
}
// 设置-编辑定时任务
export const editQuartzJob = (params) => {
    return putRequest('/quartz/job', params)
}
// 暂停定时任务
export const pauseQuartzJob = (id) => {
    return putRequest(`/quartz/job/pause/${id}`);
}
// 恢复定时任务
export const resumeQuartzJob = (id) => {
    return putRequest(`/quartz/job/resume/${id}`);
}
// 删除定时任务
export const deleteQuartzJob = (id) => {
    return deleteRequest(`/quartz/job/${id}`);
}
// 手动执行定时任务
export const manualRunQuartzJob = (id) => {
    return postRequest(`/quartz/job/manual/${id}`);
}
// 查看执行历史
export const showQuartzJobHistory = (id, params) => {
    return getRequest(`/quartz/job/history/${id}`, params);
}
// 删除定时任务执行记录
export const deleteQuartzJobHis = (id) => {
    return deleteRequest(`/quartz/job/history/${id}`);
}

//根据id获取合同编辑信息
export const GetCompanyeditInfo = (id) => {
    return getRequest(`/company/contract/edit-info/${id}`)
}

// 获取客户联系人列表
export const getContactsBaseInfos1 = (params) => {
    return getRequest('/sys/common/project/contacts/option', params)
}

// 日程-人才任务对象
export const calendarResumeOptionList = (params) => {
    return postRequestUseBodyParam('/sys/common/resume/page/option', params)
}

// 判断是否可以创建财务任务
export const canCreateFinanceTask = (params) => {
    return getRequest('/finance/finance-task/canCreateFinanceTask', params)
}

// 获取财务任务初始分配方案
export const financeTaskDefaultInfo = (params) => {
    return getRequest('/finance/finance-task/financeTaskDefaultInfo', params)
}

// 校验开票比例
export const validateInvoicedPercent = (params) => {
    return postRequestUseBodyParam('/finance/finance-task/validateInvoicedPercent', params)
}

// 删除红冲校验
export const deleteValidate = (params) => {
    return deleteRequest('/finance/reverse/delete/validate/' + params)
}

// 删除到账校验
export const delAccontMoneyValidate = (params) => {
    return deleteRequest('/finance/account-money-record/delete/validate/' + params)
}

// 业绩目标配置列表
export const performanceTargetSetting = (params) => {
    return postRequestUseBodyParam('/performance-target-setting/page', params)
}

// 业绩目标配置
export const performanceTargetSettingEdit = (params) => {
    return postRequestUseBodyParam('/performance-target-setting/edit', params)
}

// 删除业绩目标配置
export const deletePerformanceTargetSetting = (params) => {
    return deleteRequest('/performance-target-setting/' + params)
}

// 业绩目标配置新增
export const performanceTargetSettingAdd = (params) => {
    return postRequestUseBodyParam('/performance-target-setting/add', params)
}

// 业绩目标配置新增校验
export const performanceTargetSettingExist = (params) => {
    return postRequestUseBodyParam('/performance-target-setting/exist', params)
}

//业绩目标报表
export const achieveChartList = (params) => {
    return postRequestUseBodyParam('/achieve/chart', params)
}

//业绩目标报表导出
export const achieveChartExport = (params) => {
    return postRequestUseBodyParam('/achieve/chart/export', params)
}

//业绩目标报表详情Offer业绩预到岗业绩实际到岗业绩
export const achieveChartDetail = (params) => {
    return postRequestUseBodyParam('/achieve/chart/detail/achieve', params)
}

//业绩目标报表详情开票业绩
export const achieveChartDetailInvoice = (params) => {
    return postRequestUseBodyParam('/achieve/chart/detail/invoice', params)
}

//业绩目标报表详情回款业绩
export const achieveChartDetailAccount = (params) => {
    return postRequestUseBodyParam('/achieve/chart/detail/account', params)
}

//业绩目标报表详情Offer业绩预到岗业绩实际到岗业绩导出
export const achieveChartExportAchieve = (params) => {
    return postRequestUseBodyParam('/achieve/chart/export/achieve', params)
}

//业绩目标报表详情开票业绩导出
export const achieveChartExportInvoice = (params) => {
    return postRequestUseBodyParam('/achieve/chart/export/invoice', params)
}

//业绩目标报表详情回款业绩导出
export const achieveChartExportAccount = (params) => {
    return postRequestUseBodyParam('/achieve/chart/export/account', params)
}

// 获取实时监控-实时业绩
export const totalAchievement = () => {
    return getRequest('/monitor/total-achievement')
}

// 获取实时监控-年度业绩情况图表
export const yearAchievementCompleteRate = (params) => {
    return getRequest('/monitor/year-achievement-complete-rate', params)
}

// 获取实时监控-顾问业绩Top5图表
export const userRank = (params) => {
    return getRequest('/monitor/user-rank', params)
}

// 获取实时监控-顾问业绩Top5图表
export const companyRank = (params) => {
    return getRequest('/monitor/company-rank', params)
}

// 获取实时监控-Offer量分析图表
export const offerAnalysis = (params) => {
    return getRequest('/monitor/offer-analysis', params)
}

// 获取实时监控-平均单产图表
export const averagePerformance = (params) => {
    return getRequest('/monitor/average-performance', params)
}

// 获取实时监控-业绩趋势分析图表
export const Trend = (params) => {
    return getRequest('/monitor/trend', params)
}

// 获取实时监控-行业业绩分布图表
export const industryPerformance = (params) => {
    return getRequest('/monitor/industry-performance', params)
}

// 首页-个人业绩分析图表
export const personalAchievement = (params) => {
    return getRequest('/index/personal-achievement', params)
}

// 首页-个人KPI分析
export const personalKpi = (params) => {
    return getRequest('/index/personal-kpi', params)
}

// 首页-消息中心
export const indexMessage = (params) => {
    return getRequest('/common/message/index-list', params)
}

//业绩分析-达成率TOP5
export const getCompleteRate = (params) => {
    return postRequestUseBodyParam('/analyze/complete-rate', params)
}
//业绩分析-开票业绩TOP5
export const getInvoiceRate = (params) => {
    return postRequestUseBodyParam('/analyze/invoice-rank', params)
}
//业绩分析-0ffer业绩TOP5
export const getOfferRate = (params) => {
    return postRequestUseBodyParam('/analyze/offer-rank', params)
}
//业绩分析-导出达成率排行
export const exportCompleteRate = (params) => {
    return postRequestUseBodyParam('/analyze/export-complete-rate', params)
}
//业绩分析-导出开票业绩排行
export const exportInvoicerRate = (params) => {
    return postRequestUseBodyParam('/analyze/export-invoice-rank', params)
}
//业绩分析-导出Offer业绩排行
export const exportOfferRate = (params) => {
    return postRequestUseBodyParam('/analyze/export-offer-rank', params)
}
//业绩分析-业绩情况
export const achievementCompleteRate = (params) => {
    return postRequestUseBodyParam('/analyze/achievement-complete-rate', params)
}
//业绩分析-回款业绩详情
export const analyzeDetailAccount = (params) => {
    return postRequestUseBodyParam('/analyze-detail/account', params)
}
//业绩分析-导出回款业绩详情
export const analyzeDetailExportAccount = (params) => {
    return postRequestUseBodyParam('/analyze-detail/export-account', params)
}
//业绩分析-导出开票业绩详情
export const analyzeDetailExportInvoice = (params) => {
    return postRequestUseBodyParam('/analyze-detail/export-invoice', params)
}
//业绩分析-导出Offer-到岗业绩详情
export const analyzeDetailExportOffer = (params) => {
    return postRequestUseBodyParam('/analyze-detail/export-offer', params)
}
//业绩分析-开票业绩详情
export const analyzeDetailInvoice = (params) => {
    return postRequestUseBodyParam('/analyze-detail/invoice', params)
}
//业绩分析-Offer-到岗业绩详情
export const analyzeDetailOffer = (params) => {
    return postRequestUseBodyParam('/analyze-detail/offer', params)
}
//导出顾问业绩
export const exportuserRank = () => {
    return getRequest('/monitor/export-user-rank')
}

//顾问业绩详情
export const userrankDetail = (params) => {
    return postRequestUseBodyParam('/monitor-detail/user-rank-detail', params)
}

//导出顾问业绩详情
export const exportuserRankDetail = (params) => {
    return postRequestUseBodyParam('/monitor-detail/export-user-rank-detail', params)
}

//客户到岗业绩详情
export const companyRankDetail = (params) => {
    return postRequestUseBodyParam('/monitor-detail/company-rank-detail', params)
}

//导出客户到岗业绩排行
export const exportcompanyRank = () => {
    return getRequest('/monitor/export-company-rank')
}

//导出客户到岗业绩详情
export const exportcompanyRankDetail = (params) => {
    return postRequestUseBodyParam('/monitor-detail/export-company-rank-detail', params)
}

//Offer量详情
export const offerDetail = (params) => {
    return postRequestUseBodyParam('/monitor-detail/offer-detail', params)
}

//导出Offer量详情
export const exportofferRankDetail = (params) => {
    return postRequestUseBodyParam('/monitor-detail/export-offer-detail', params)
}

//行业客户到岗业绩排行
export const industryCompanyRrank = (params) => {
    return postRequestUseBodyParam('/monitor-detail/industry-company-rank', params)
}

//行业客户业绩详情
export const industryCompanyDetail = (params) => {
    return postRequestUseBodyParam('/monitor-detail/industry-company-detail', params)
}

//导出行业客户业绩详情
export const exportIndustryCompanyDetail = (params) => {
    return postRequestUseBodyParam('/monitor-detail/export-industry-company-detail', params)
}

//导出行业客户到岗业绩排行
export const exportIndustryCompanyRank = (params) => {
    return postRequestUseBodyParam('/monitor-detail/export-industry-company-rank', params)
}

//业绩报表数透
export const dataThrough = (params) => {
    return postRequestUseBodyParam('/achieve/data-through', params)
}

//业绩报表数透
export const exportDataThrough = (params) => {
    return postRequestUseBodyParam('/achieve/export-data-through', params)
}

//KPI数透
export const kpiDataThrough = (params) => {
    return postRequestUseBodyParam('/data/kpi/data-through', params)
}

//导出KPI数透
export const exportKpiDataThrough = (params) => {
    return postRequestUseBodyParam('/data/kpi/export-data-through', params)
}

//客户到岗业绩排行
export const analyzeCompanyRank = (params) => {
    return postRequestUseBodyParam('/analyze/company-rank', params)
}

//导出客户到岗业绩排行
export const analyzeExportCompanyRank = (params) => {
    return postRequestUseBodyParam('/analyze/export-company-rank', params)
}

//客户到岗业绩排行
export const analyzeCompanyRankDetail = (params) => {
    return postRequestUseBodyParam('/analyze-detail/company-rank-detail', params)
}
//客户到岗业绩排行
export const analyzeExportCompanyRankDetail = (params) => {
    return postRequestUseBodyParam('/analyze-detail/export-company-rank-detail', params)
}

//项目email导出list
export const emailExportProjectList = (params) => {
    return postRequestUseBodyParam('/project/project/email-export', params)
}

// 业绩-查看顾问分配方案
export const getAllocationInfo = (id) => {
    return getRequest(`/performance-pool/allocation-info/${id}`)
}

//业绩修改为0
export const editToZero = (params) => {
    return postRequestUseBodyParam('/performance/edit-to-zero', params)
}

//导出实习生或Researcher业绩
export const exportOther = (params) => {
    return postRequestUseBodyParam('/performance-pool/export-other', params)
}

//喜报审批流设置
export const setApproval = (params) => {
    return postRequestUseBodyParam('/sys/goodnews-approvalflowrole/set-approval', params)
}

// 当前用户所有日程
export const getSchedulePersonal = (id) => {
    return getRequest('/schedule/schedule/personal')
}
// 是否已设置审批流
export const getIsApproval = (params) => {
    return getRequest('/sys/goodnews-approvalflowrole/is-approval', params)
}

// 获取喜报审批流设置
export const getApproval = (params) => {
    return getRequest('/sys/goodnews-approvalflowrole/get-approval', params)
}

//查询公告及通知列表
export const announcementPage = (params) => {
    return postRequestUseBodyParam('/sys/announcement/page', params)
}

//更新系统版本更新公告
export const announcementUpdate = (params) => {
    return postRequestUseBodyParam('/sys/announcement/update', params)
}

//删除系统版本更新公告
export const announcementDeleteVersion = (params) => {
    return deleteRequest('/sys/announcement/delete', params)
}

//添加系统版本更新公告
export const announcementAddVersion = (params) => {
    return postRequestUseBodyParam('/sys/announcement/add-version', params)
}

//添加系统版本更新公告
export const announcementAddAnnouncement = (params) => {
    return postRequestUseBodyParam('/sys/announcement/add-announcement', params)
}

//添加自定义通知
export const announcementAddCustom = (params) => {
    return postRequestUseBodyParam('/sys/announcement/add-custom', params)
}

// 根据id查询公告详情
export const getAnnouncementDetail = (params) => {
    return getRequest('/sys/announcement/detail', params)
}

// 获取Offer数据
export const offerGoodnewsDefaultData = (params) => {
    return getRequest('/project/offer-goodnews/default-data', params)
}

// 白领Offer岗位选项
export const adaptersZiniuOfferJobOptions = (params) => {
    return getRequest('/adapters/ziniu/data/offer-job-options', params)
}
// 白领Offer项目选项
export const adaptersZiniuOfferProjectOptions = (params) => {
    return getRequest('/adapters/ziniu/data/offer-project-options',params)
}
// 白领Offer招聘渠道
export const adaptersZiniuOfferRecruitChannels = () => {
    return getRequest('/adapters/ziniu/data/offer-recruit-channels')
}
// 白领Offer招聘人
export const adaptersZiniuOfferRecruitUsers = () => {
    return getRequest('/adapters/ziniu/data/offer-recruit-users')
}

//发Offer
export const offerGoodnewsSend = (params) => {
    return postRequestUseBodyParam('/project/offer-goodnews/send', params)
}

//喜报审批
export const offerGoodnewsApprovalPageList = (params) => {
    return postRequestUseBodyParam('/project/offer-goodnews-approval/page-list', params)
}

//喜报驳回
export const offerGoodnewsApprovalReject = (id) => {
    return postRequestUseBodyParam(`/project/offer-goodnews-approval/reject/${id}`)
}

// 获取审核的喜报数据
export const offerGoodnewsApproval = (id) => {
    return getRequest(`/project/offer-goodnews-approval/${id}`)
}

//喜报确认并提交
export const offerGoodnewsApprovalPass = (params) => {
    return postRequestUseBodyParam('/project/offer-goodnews-approval/pass', params)
}

//我offer的候选人
export const personalOfferedList = (params) => {
    return postRequestUseBodyParam('/project/projectresume/personal-offered-list', params)
}

//转正反馈
export const projectresumeFteStatus = (params) => {
    return postRequestUseBodyParam('/project/projectresume/fteStatus', params)
}

// 获取喜报入职时间
export const getEntryDate = (params) => {
    return getRequest('/project/operaterecord/getEntryDate', params)
}

//当前登陆人的邮箱
export const curemail = () => {
    return getRequest('/sys/user-email-controller/cur-email')
}

//设置当前登陆人邮件签名
export const setSignature = (params) => {
    return postRequestUseBodyParam('/sys/user-email-controller/set-signature', params)
}

//分页查询邮件模板
export const emailTemplatePage = (params) => {
    return postRequestUseBodyParam('/sys/user-email-controller/email-template-page', params)
}

//删除邮件模板
export const deleteEmailTemplate = (params) => {
    return postRequestUseBodyParam('/sys/user-email-controller/delete-email-template', params)
}

//共享邮件模板
export const shareEmailTemplate = (params) => {
    return postRequestUseBodyParam('/sys/user-email-controller/share-email-template', params)
}

//添加邮件模板
export const addEmailTemplate = (params) => {
    return postRequestUseBodyParam('/sys/user-email-controller/add-email-template', params)
}

//是否已经发送过喜报
export const haveSend = (params) => {
    return getRequest('/project/goodnews/have-send', params)
}

//查看邮件模板详情
export const userEmailControllerDetail = (params) => {
    return getRequest('/sys/user-email-controller/detail', params)
}

//修改邮件模板
export const editEmailTemplate = (params) => {
    return postRequestUseBodyParam('/sys/user-email-controller/edit-email-template', params)
}

//发送邮件
export const sendEmail = (params) => {
    return postRequestUseBodyParam('/sys/user-email-controller/send-email', params)
}

//获取邮件子主题列表
export const chooseEmailTemplate = (params) => {
    return postRequestUseBodyParam('/sys/user-email-controller/choose-email-template', params)
}

//自动填充邮件模板
export const initEmailTemplate = (params) => {
    return postRequestUseBodyParam('/project/operaterecord/initEmailTemplate', params)
}

//邮件简历推荐
export const emailRecommend = (params) => {
    return postRequestUseBodyParam('/project/operaterecord/emailRecommend', params)
}

//邮件面试
export const emailInterview = (params) => {
    return postRequestUseBodyParam('/project/operaterecord/emailInterview', params)
}

//获取当前候选人邮箱
export const curCandidateEmail = (params) => {
    return postRequestUseBodyParam('/sys/user-email-controller/curCandidateEmail', params)
}

//批量操作根据勾选的候选人获取其邮箱
export const getBatchEmail = (params) => {
    return postRequestUseBodyParam('/sys/user-email-controller/get-batch-email', params)
}

//分页查询邮件发送统计
export const emailStatisticsPage = (params) => {
    return postRequestUseBodyParam('/sys/user-email-controller/email-statistics-page', params)
}

//分页查询邮件发送统计
export const setAuthCode = (params) => {
    return postRequestUseBodyParam('/sys/user-email-controller/set-auth-code', params)
}

//用户名带是否离职选项列表
export const nameAndStatusOptions = () => {
    return getRequest('/sys/user/name-and-status-options')
}

//单文件上传
export const fileFploadOne = (params) => {
    return postRequestUseBodyParam('/api/file/uploadOne', params)
}

//下载模板
export const downloadTemplates = () => {
    return getRequest('/performance-target-setting/download-templates')
}

//校验excel表数据
export const validateExcel = (params) => {
    return postRequestUseBodyParam('/performance-target-setting/validate-excel', params)
}

//从excel表设置
export const excelSetting = (params) => {
    return postRequestUseBodyParam('/performance-target-setting/excel-setting', params)
}

//获取已设置的对象
export const existObjects = (params) => {
    return postRequestUseBodyParam('/performance-target-setting/exist-objects', params)
}

//获取设置列表
export const existSettings = (params) => {
    return postRequestUseBodyParam('/performance-target-setting/exist-settings', params)
}

//获取导出记录列表
export const reportReportPageList = (params) => {
    return postRequestUseBodyParam('/common/report-export/page-list', params)
}

//更改候选人电话状态
export const changeMobileStatus = (params) => {
    return postRequestUseBodyParam('/resume/resume/change-mobile-status', params)
}

//城市选项树
export const cityTree = () => {
    return getRequest('/sys/city/city-tree')
}

//新增或编辑城市选项树
export const addOrEditcityTree = (params) => {
    return postRequestUseBodyParam('/sys/city/addOrEdit', params)
}

//城市详情
export const cityDetail = (params) => {
    return getRequest('/sys/city/detail', params)
}

//删除自定义字段
export const cityDelete = (params) => {
    return deleteRequest('/sys/city/delete', params)
}

//批量匹配
export const resumeBatchMatch = (params) => {
    return postRequestUseBodyParam('/sys/resume-value-match/batchMatch', params)
}

//标签分页查询
export const labelPage = (params) => {
    return postRequestUseBodyParam('/label/page', params)
}

//新增标签类别
export const labelCategory = (params) => {
    return postRequestUseBodyParam('/label/category', params)
}

//分页获取标签类别
export const labelCategoryGetPageList = (params) => {
    return postRequestUseBodyParam('/label/category/getPageList', params)
}

//获取所有标签类别
export const getLabelCategory = (params) => {
    return getRequest('/label/category', params)
}

//获取人才规则因子-或
export const getLabelFactorCandidateOr = (params) => {
    return getRequest('/label/factor/candidate/or', params)
}

//获取人才规则因子-且
export const getLabelFactorCandidateAnd = (params) => {
    return getRequest('/label/factor/candidate/and', params)
}

//创建标签
export const addLabel = (params) => {
    return postRequestUseBodyParam('/label', params)
}

//检查名称是否已存在
export const isNameExist = (params) => {
    return getRequest('/label/is-name-exist', params)
}

//检查顺序是否已存在
export const isOrderExist = (params) => {
    return getRequest('/label/is-order-exist', params)
}

//检查类别名称是否已存在
export const categoryIsNameExist = (params) => {
    return getRequest('/label/category/is-name-exist', params)
}

//检查类别顺序是否已存在
export const categoryIsOrderExist = (params) => {
    return getRequest('/label/category/is-order-exist', params)
}

//获取标签编辑数据
export const labelEditData = (id) => {
    return getRequest(`/label/edit-data/${id}`)
}

//编辑标签
export const editLabel = (params) => {
    return putRequestUseBodyParams('/label', params)
}

//获取所有标签类别
export const getLabelCategoryOptions = () => {
    return getRequest('/label/category/options')
}

// 给候选人打标签
export const labelAddRemarksToResume = (params) => {
    return postRequestUseBodyParam('/label/candidate/addRemarksToResume', params)
}

//获取所有标签选项
export const getLabelOptions = () => {
    return getRequest('/label/options')
}

//处理当前标签
export const setLabelProcess = (params, that) => {
    return getRequestCanCancel('/label/process', params, that)
}

//置顶人才
export const setTopProjectResume = (params) => {
    return getRequest('/project/projectresume/topProjectResume', params)
}

//取消置顶人才
export const cancelTopProjectResume = (params) => {
    return getRequest('/project/projectresume/removeTopResume', params)
}

//快捷菜单人才数量
export const resumeDataCount = () => {
    return getRequest('/resume/data-count')
}

//人才详情获取标签数量
export const personalLabelHits = (params) => {
    return getRequest('/label/category/personal-label-hits', params)
}

//项目标签选项
export const categoryAllTypeOptions = () => {
    return getRequest('/label/category/all-type-options')
}

//校验重复的项目
export const projectValidateRepeat = (params) => {
    return postRequestUseBodyParam('/project/project/validateRepeat', params)
}

//相似职位列表
export const getSimilarProject = (params) => {
    return getRequest('/project/similar-project', params)
}

//添加相似职位
export const postSimilarProject = (params) => {
    return postRequestUseBodyParam('/project/similar-project', params)
}

//添加相似职位选项列表
export const getSimilarProjectAddList = (params) => {
    return getRequest('/project/similar-project/add/list', params)
}

//团队KPI分页列表
export const teamKpiPage = (params) => {
    return postRequestUseBodyParam('/data/team-kpi/page', params)
}

//按项目维度统计的数据
export const teamKpiProjectDetail = (params) => {
    return postRequestUseBodyParam('/data/team-kpi/project-detail', params)
}

//导出团队KPI分页列表
export const teamKpiExport = (params) => {
    return postRequestUseBodyParam('/data/team-kpi/export', params)
}

//团队KPI项详情
export const teamKpiItemDetail = (params) => {
    return postRequestUseBodyParam('/data/team-kpi/item-detail', params)
}

//导出团队KPI详情
export const teamKpiItemDetailExport = (params) => {
    return postRequestUseBodyParam('/data/team-kpi/item-detail/export', params)
}

//导出按项目维度统计的数据
export const projectDetailExport = (params) => {
    return postRequestUseBodyParam('/data/team-kpi/project-detail/export', params)
}

//推荐人才分页列表
export const resumeRecommendPage = (params) => {
    return postRequestUseBodyParam('/project/resume-recommend/page', params)
}

//搜索初始参数
export const announcementReadCustom = (params) => {
    return getRequest('/sys/announcement/read-custom', params)
}

//读取最近一条未读的自定义通知
export const announcementLatestCustom = () => {
    return getRequest('/sys/announcement/latest-custom')
}

//删除人才字段匹配
export const deleteResumeValueMatch = (params) => {
    return postRequestUseBodyParam('/sys/resume-value-match/delete', params)
}

//待匹配字段管理人选
export const deleteResumeValueMatchResumes = (params) => {
    return postRequestUseBodyParam('/sys/resume-value-match/matchedResumes', params)
}

//分页展示同义词
export const synonymPage = (params) => {
    return postRequestUseBodyParam('/sys/synonym/page', params)
}

//新增同义词记录
export const synonymAdd = (params) => {
    return postRequestUseBodyParam('/sys/synonym/add', params)
}

//编辑此同义词记录
export const synonymEdit = (params) => {
    return postRequestUseBodyParam('/sys/synonym/edit', params)
}

//校验同义词是否重复 , 返回为null表示无重复 , 否则表示词条重复
export const synonymValidate = (params) => {
    return postRequestUseBodyParam('/sys/synonym/validate', params)
}

//删除此同义词记录
export const synonymdelete = (params) => {
    return deleteRequest('/sys/synonym/delete', params)
}

//此同义词记录详情
export const synonymDetail = (params) => {
    return getRequest('/sys/synonym/detail', params)
}

//获取客户合作类标签选项
export const customerCoopOptions = (params) => {
    return getRequest('/label/customer-coop-options', params)
}

//获取客户合作类标签选项
export const customerOptions = (params) => {
    return getRequest('/label/customer-options', params)
}

//获取特定项目状态中的人才
export const resumeSpecificCompanyStatus = (params) => {
    return postRequestUseBodyParam('/company/resume/specific-company-status', params)
}

//获取项目特定项目状态下的人才数目
export const resumeSpecificCompanyStatusCount = (params) => {
    return getRequest('/company/resume/specific-company-status/count', params)
}

//获取项目特定项目状态下的人才数目
export const roleGetAll = () => {
    return getRequest('/sys/role/getAll')
}

//团队PipeLine分页列表
export const projectresumeGetTeamPageList = (params) => {
    return postRequestUseBodyParam('/project/projectresume/getTeamPageList', params)
}

//无合同审批分页列表
export const noContractFinanceList = (params) => {
    return postRequestUseBodyParam('/no-contract-finance/page-list', params)
}

//所有无合同开票审批记录
export const noContractFinanceAll = (params) => {
    return postRequestUseBodyParam('/no-contract-finance/all', params)
}

//无合同审批详情
export const noContractFinanceDetail = (params) => {
    return getRequest('/no-contract-finance/detail', params)
}

//无合同审批审批
export const noContractFinanceApprove = (params) => {
    return postRequestUseBodyParam('/no-contract-finance/approve', params)
}

//查看无合同开票审批流
export const noContractFinanceApprovalFlow = (params) => {
    return getRequest('/no-contract-finance/approval-flow', params)
}

//个人年度业绩
export const personalAchievementSum = () => {
    return getRequest('/index/personal-achievement-sum')
}

//逾期回款详情
export const personalAchievementsDetail = (params) => {
    return postRequestUseBodyParam('/index/overdue-achievement-detail', params)
}

//逾期回款详情
export const personalApprovalPage = (params) => {
    return postRequestUseBodyParam('/project/projectresume/personal-approval-page', params)
}

//获取喜报详情 , 包括当前的审批流程
export const offerGoodnewsApprovalDetail = (params) => {
    return getRequest('/project/offer-goodnews-approval/detail', params)
}

//批量添加菜单权限
export const batchAddPermission = (params) => {
    return postRequestUseBodyParam('/sys/role/batch-add-permission', params)
}

//标记为无效
export const qualityCheckMarkAsinValid = (params) => {
    return postRequestUseBodyParam('/kpi/quality-check/mark-as-invalid', params)
}

//恢复为有效
export const qualityCheckrecoverAsinValid = (params) => {
    return postRequestUseBodyParam('/kpi/quality-check/recover-as-valid', params)
}

//KPI
export const detailQualifiedCount = (params) => {
    return getRequest('/data/kpi/detail-qualified-count', params)
}

//其他消息详情
export const kpiMessageDetail = (params) => {
    return postRequestUseBodyParam('/kpi/message/detail', params)
}

//逾期回款分页信息
export const financeTaskOverDuePagedList = (params) => {
    return postRequestUseBodyParam('/finance/finance-task/overDuePagedList', params)
}

//添加备注
export const financeTaskAddOverDueComment = (params) => {
    return postRequestUseBodyParam('/finance/finance-task/add-overdue-comment', params)
}

//新增或编辑逾期财务任务的预计到账时间
export const financeTaskAddOrEditeExpectAccountDate = (params) => {
    return postRequestUseBodyParam('/finance/finance-task/addOrEdit-expectAccountDate', params)
}

//导出逾期回款信息
export const financeTaskExportOverdue = (params) => {
    return postRequestUseBodyParam('/finance/finance-task/export-overdue', params)
}

//根据无合同开票的原因校验参数
export const financeTaskNoContractValidate = (params) => {
    return getRequest('/finance/finance-task/no-contract-validate', params)
}

//获取无合同开票的关联合同选项
export const financeTaskNoContractOptions = (params) => {
    return getRequest('/finance/finance-task/no-contract-options', params)
}

//查看客户无合同开票审记录
export const financeTaskListByCompany = (params) => {
    return getRequest('/no-contract-finance/list-by-company', params)
}

//获取日程的项目下拉选项列表
export const CommonProjectPageOption = (params) => {
    return postRequestUseBodyParam('/sys/common/project/page/option', params)
}

//获取日程的客户下拉选项列表
export const CommonCompanyPageOption = (params) => {
    return postRequestUseBodyParam('/sys/common/company/page/option', params)
}

//当前用户待审批数量
export const commonApprovalCount = (params) => {
    return getRequest('/common/approval/count', params)
}

//当前用户待审批列表
export const commonApprovalList = (params) => {
    return getRequest('/common/approval/list', params)
}

//解锁
export const resumeOffeOrEntryUnlock = (params) => {
    return getRequest('/resume/offer-or-entry/unlock', params)
}

//反馈查重问题列表
export const resumeRepeatRecordPage = (params) => {
    return postRequestUseBodyParam('/resume/repeat-record/page', params)
}

//新增反馈查重问题
export const resumeRepeatRecordFeedbackRepeat = (params) => {
    return postRequestUseBodyParam('/resume/repeat-record/feedback-repeat', params)
}

//编辑反馈查重问题
export const resumeRepeatRecordEdit = (params) => {
    return postRequestUseBodyParam('/resume/repeat-record/edit', params)
}

//此查重问题编辑详情
export const resumeRepeatRecordEditDetail = (params) => {
    return getRequest('/resume/repeat-record/edit-detail', params)
}

//进度跟踪
export const resumeRepeatRecordFeedbackTrack = (params) => {
    return postRequestUseBodyParam('/resume/repeat-record/feedback-track', params)
}

//最近一条进度跟踪详情
export const resumeRepeatRecordLastFeedbackInfo = (params) => {
    return getRequest('/resume/repeat-record/last-feedback-info', params)
}

//批量转移用户至某个组织架构
export const userBatchShiftDept = (params) => {
    return postRequestUseBodyParam('/sys/user/batch-shift-dept', params)
}

//设为保密
export const securePermissionSecure = (params) => {
    return postRequestUseBodyParam('/resume/secure-permission/secure', params)
}

//取消保密
export const securePermissionCancel = (params) => {
    return getRequest('/resume/secure-permission/cancel', params)
}

//获取人才保密设置的数据
export const securePermissionData = (params) => {
    return getRequest('/resume/secure-permission/data', params)
}

//从自动匹配人才列表剔除某个人
export const resumeRecommendReject = (params) => {
    return postRequestUseBodyParam('/project/resume-recommend/reject', params)
}

//人脉分页列表
export const resumeConnectionsPage = (params) => {
    return postRequestUseBodyParam('/resume/connections/page', params)
}

//补充关系
export const resumeConnectionsAdd = (params) => {
    return postRequestUseBodyParam('/resume/connections/add', params)
}

//相似人才列表
export const resumeSimilarPage = (params) => {
    return postRequestUseBodyParam('/resume/similar/page', params)
}

//业绩报表根据开票与回款时间进行分页
export const achieveChartInvoiceAndAccount = (params) => {
    return postRequestUseBodyParam('/achieve/chart/invoiceAndAccount', params)
}

//导出业绩报表且查询
export const achieveChartExportInvoiceAndAccount = (params) => {
    return postRequestUseBodyParam('/achieve/chart/export/invoiceAndAccount', params)
}

//团队客户分页列表
export const achieveChartTeamCompanyPageList = (params) => {
    return postRequestUseBodyParam('/achieve/chart/team-company/page-list', params)
}

//业绩报表-团队的客户业绩数透
export const achieveChartTeamCompanyDataThrough = (params) => {
    return postRequestUseBodyParam('/achieve/chart/team-company/data-through', params)
}

//业绩报表-导出团队客户业绩列表
export const achieveChartTeamCompanyExport = (params) => {
    return postRequestUseBodyParam('/achieve/chart/team-company/export', params)
}

//业绩报表-导出业绩报表团队的客户业绩数透
export const achieveChartTeamCompanyExportDataThrough = (params) => {
    return postRequestUseBodyParam('/achieve/chart/team-company/export-data-through', params)
}

//客户的团队分页列表
export const achieveChartCompanyTeamPageList = (params) => {
    return postRequestUseBodyParam('/achieve/chart/company-team/page-list', params)
}

//业绩报表-客户的团队业绩数透
export const achieveChartCompanyTeamDataThrough = (params) => {
    return postRequestUseBodyParam('/achieve/chart/company-team/data-through', params)
}

//业绩报表-导出客户的团队业绩列表
export const achieveChartCompanyTeamExport = (params) => {
    return postRequestUseBodyParam('/achieve/chart/company-team/export', params)
}

//业绩报表-导出业绩报表客户的团队业绩数透
export const achieveChartCompanyTeamExportDataThrough = (params) => {
    return postRequestUseBodyParam('/achieve/chart/company-team/export-data-through', params)
}


//获取当前用户可设置的主页列表
export const sysUserIndexOptions = (params) => {
    return getRequest('/sys/user/index-options', params)
}

//增加一条合同日常备注
export const companyContractAddDailyComment = (params) => {
    return postRequestUseBodyParam('/company/contract/add-daily-comment', params)
}

// 合同关联的日常备注列表
export const companyContractDailyComment = (params) => {
    return getRequest('/company/contract/daily-comment', params)
}

//校验是否完全开票
export const financeTaskValidateCompletelyInvoice = (params) => {
    return postRequestUseBodyParam('/finance/finance-task/validateCompletelyInvoice', params)
}

//设置默认首页
export const sysUserDefaultIndex = (params) => {
    return postRequestUseBodyParam('/sys/user/default-index', params)
}

//同期分析
export const analyzeAchievementYOY = (params) => {
    return postRequestUseBodyParam('/analyze/achievement-yearOnYear', params)
}

// // 当前用户所有已设置的个性标签
// export const labelCustomPersonalAll = () => {
//     return getRequest('/label-custom/personal-all')
// }

// 当前用户的个性化标签命中数目
export const labelCustomTagged = () => {
    return getRequest('/label-custom/tagged')
}

//添加个性化标签
export const labelCustomAdd = (params) => {
    return postRequestUseBodyParam('/label-custom/add', params)
}

//删除个性化标签
export const labelCustomDelete = (id) => {
    return deleteRequest(`/label-custom/delete/${id}`);
}

//个性化标签分页
export const labelCustomPage = (params) => {
    return postRequestUseBodyParam('/label-custom/page', params)
}

// 当前顾问常用标签
export const labelFavouriteCurUser = () => {
    return getRequest('/label/favourite/curUser')
}

//设置常用标签
export const labelFavouriteSet = (params) => {
    return postRequestUseBodyParam('/label/favourite/set', params)
}

//发送人才激活邮件
export const candidateActiveSend = (params) => {
    return postRequestUseBodyParam('/candidate/active/send', params)
}

//预览邮件内容人才激活邮件
export const candidateActivePreview = (params) => {
    return postRequestUseBodyParam('/candidate/active/preview', params)
}

//发送职位邀请邮件
export const jobInviteSend = (params) => {
    return postRequestUseBodyParam('/job/invite/send', params)
}

//预览职位邀请邮件
export const jobInvitePreview = (params) => {
    return postRequestUseBodyParam('/job/invite/preview', params)
}

//自定义项目列表表头
export const projectProjectSetHeaders = (params) => {
    return postRequestUseBodyParam('/project/project/setHeaders', params)
}

// 获取自定义的项目表头
export const projectProjectGetHeaders = () => {
    return getRequest('/project/project/getHeaders')
}

//项目推进数据概览
export const projectProjectBoostOverview = (params) => {
    return postRequestUseBodyParam('/project/project/boostOverview', params)
}

//项目淘汰、推进时长、Offer周期的数据概览
export const projectProjectDeliveryOverview = (params) => {
    return postRequestUseBodyParam('/project/project/deliveryOverview', params)
}

// 交付概览
export const companyDeliveryOverview = (params) => {
    return getRequest('/company/company/deliveryOverview', params)
}

//获取自定义表头
export const projectProjectresumeGetHeaders = (params) => {
    return postRequestUseBodyParam('/project/projectresume/getHeaders', params)
}

//设置自定义表头
export const projectProjectresumeSetHeaders = (params) => {
    return postRequestUseBodyParam('/project/projectresume/setHeaders', params)
}

//添加节假日
export const sysHolidayAdd = (params) => {
    return postRequestUseBodyParam('/sys/holiday/add', params)
}

//删除节假日
export const sysHolidayDelete = (params) => {
    return deleteRequest('/sys/holiday/delete', params)
}

//编辑节假日
export const sysHolidayEdit = (params) => {
    return postRequestUseBodyParam('/sys/holiday/edit', params)
}

//节假日列表
export const sysHolidayList = (params) => {
    return postRequestUseBodyParam('/sys/holiday/list', params)
}

//奖金计算模型分页搜索
export const bonusCalculateModelPage = (params) => {
    return postRequestUseBodyParam('/bonus/calculate-model/page', params)
}

//奖金计算模型新增或编辑
export const bonusCalculateModelAddOrEdit = (params) => {
    return postRequestUseBodyParam('/bonus/calculate-model/add-or-edit', params)
}

//奖金计算模型获取编辑参数
export const bonusCalculateModelEditData = (params) => {
    return getRequest('/bonus/calculate-model/edit-data', params)
}

//顾问奖金设置分页
export const bonusModelUserSettingPage = (params) => {
    return postRequestUseBodyParam('/bonus/model-user-setting/page', params)
}

//顾问奖金设置单个删除
export const bonusModelUserSettingDeleteSingle = (params) => {
    return deleteRequest('/bonus/model-user-setting/deleteSingle', params)
}

//顾问奖金设置单个修改
export const bonusModelUserSettingEditSingle = (params) => {
    return postRequestUseBodyParam('/bonus/model-user-setting/editSingle', params)
}

//顾问奖金设置模板下载
export const bonusModelUserSettingDownloadTemplate = (params) => {
    return getRequest('/bonus/model-user-setting/downloadTemplate', params)
}

//顾问奖金设置
export const bonusModelUserSettingNormalSet = (params) => {
    return postRequestUseBodyParam('/bonus/model-user-setting/normalSet', params)
}

//校验顾问奖金设置数据
export const bonusModelUserSettingValidateNormal = (params) => {
    return postRequestUseBodyParam('/bonus/model-user-setting/validateNormal', params)
}

//顾问奖金设置表格
export const bonusModelUserSettingExcelSet = (params) => {
    return postRequestUseBodyParam('/bonus/model-user-setting/excelSet', params)
}

//校验顾问奖金设置数据表格
export const bonusModelUserSettingValidateExcel = (params) => {
    return postRequestUseBodyParam('/bonus/model-user-setting/validateExcel', params)
}


//分页查询年度差额
export const bonusAnnualDifferencePage = (params) => {
    return postRequestUseBodyParam('/bonus/annual-difference/page', params)
}

//删除单个年度差额
export const bonusAnnualDifferenceDeleteSingle = (params) => {
    return deleteRequest('/bonus/annual-difference/deleteSingle', params)
}

//编辑单个年度差额
export const bonusAnnualDifferenceEditSingle = (params) => {
    return postRequestUseBodyParam('/bonus/annual-difference/editSingle', params)
}

//下载年度差额设置模板
export const bonusAnnualDifferenceDownloadTemplate = (params) => {
    return getRequest('/bonus/annual-difference/downloadTemplate', params)
}

//设置年度差额
export const bonusAnnualDifferenceNormalSet = (params) => {
    return postRequestUseBodyParam('/bonus/annual-difference/normalSet', params)
}

//设置年度差额表格
export const bonusAnnualDifferenceExcelSet = (params) => {
    return postRequestUseBodyParam('/bonus/annual-difference/excelSet ', params)
}

//校验年度差额
export const bonusAnnualDifferenceValidate = (params) => {
    return postRequestUseBodyParam('/bonus/annual-difference/validate ', params)
}

//获取奖金计算模型下拉列表
export const bonusCalculateModelOptionList = () => {
    return getRequest('/bonus/calculate-model/option-list')
}

//测算申请
export const bonusCalculateSscCalculate = (params) => {
    return postRequestUseBodyParam('/bonus/calculate-ssc/calculate', params)
}

//检查当年是否有测算表
export const bonusCalculateSscExistTable = (params) => {
    return getRequest('/bonus/calculate-ssc/exist-table', params)
}

//获取奖金表选项列表
export const bonusDataTableOptions = (params) => {
    return getRequest('/bonus/data/table-options', params)
}

//奖金表信息
export const bonusDataTableInfo = (params) => {
    return getRequest('/bonus/data/table-info', params)
}

//获取测算结果数据
export const bonusDataGeneratedList = (params) => {
    return postRequestUseBodyParam('/bonus/data/generated-list', params)
}

//导出核对测算奖金数据
export const bonusDataExportGeneratedList = (params) => {
    return postRequestUseBodyParam('/bonus/data/export-generated-list', params)
}

//获取待发奖金数据
export const bonusDataToPayList = (params) => {
    return postRequestUseBodyParam('/bonus/data/to-pay-list', params)
}

//导出待发奖金数据
export const bonusDataExportToPayList = (params) => {
    return postRequestUseBodyParam('/bonus/data/export-to-pay-list', params)
}

//获取已发奖金数据
export const bonusDataPaidList = (params) => {
    return postRequestUseBodyParam('/bonus/data/paid-list', params)
}

//导出获取已发奖金数据
export const bonusDataExportPaidList = (params) => {
    return postRequestUseBodyParam('/bonus/data/export-paid-list', params)
}

//生成待发奖金表
export const bonusDataGeneratedToPay = (params) => {
    return postRequestUseBodyParam('/bonus/data/generate-to-pay', params)
}

//删除奖金数据
export const bonusDataDelete = (params) => {
    return postRequestUseBodyParam('/bonus/record/delete', params)
}

//测算表重新测算
export const bonusCalculateSscReCalculate = (params) => {
    return postRequestUseBodyParam('/bonus/calculate-ssc/re-calculate', params)
}

//待发表重新测算
export const bonusCalculateSscReCalculateToPay = (params) => {
    return postRequestUseBodyParam('/bonus/calculate-ssc/re-calculate-to-pay', params)
}

//发送给负责人确认
export const bonusConfirmSendToConfirm = (params) => {
    return postRequestUseBodyParam('/bonus/confirm/send-to-confirm', params)
}

//编辑备注
export const bonusRecordEditComment = (params) => {
    return postRequestUseBodyParam('/bonus/record/edit-comment', params)
}

//编辑奖金
export const bonusRecordEditBonus = (params) => {
    return postRequestUseBodyParam('/bonus/record/edit-bonus', params)
}

//编辑总代发奖金备注
export const bonusRecordEditTotalBonusCmment = (params) => {
    return postRequestUseBodyParam('/bonus/record/edit-total-bonus-comment', params)
}

//累计已发奖金列表
export const bonusRecordItemAccruedBonusList = (params) => {
    return getRequest('/bonus/record-item/accrued-bonus-list', params)
}

//导出累计已发奖金列表
export const bonusRecordItemExportAccruedBonus = (params) => {
    return getRequest('/bonus/record-item/export-accrued-bonus', params)
}

//负责人确认奖金
export const bonusConfirm = (params) => {
    return postRequestUseBodyParam('/bonus/confirm', params)
}

//负责人确认奖金数据
export const bonusConfirmPageList = (params) => {
    return postRequestUseBodyParam('/bonus/confirm/page-list', params)
}


//工资记录列表
export const bonusSalaryRecordList = (params) => {
    return getRequest('/bonus/salary-record/list', params)
}

//导出工资记录列表
export const bonusSalaryRecordExport = (params) => {
    return getRequest('/bonus/salary-record/export', params)
}

//修改工资
export const bonusSalaryRecordModify = (params) => {
    return postRequestUseBodyParam('/bonus/salary-record/modify', params)
}

//累计总奖金详情
export const bonusRecordItemAccruedBonusInfo = (params) => {
    return getRequest('/bonus/record-item/accrued-bonus-info', params)
}

//测算选取的顾问
export const bonusCalculateSscCalculateUser = (params) => {
    return postRequestUseBodyParam('/bonus/calculate-ssc/calculate-user', params)
}

//回款业绩详情
export const bonusAchievementDetailAccount = (params) => {
    return postRequestUseBodyParam('/bonus/achievement-detail/account', params)
}

//导出回款业绩
export const bonusAchievementDetailExportAccount = (params) => {
    return postRequestUseBodyParam('/bonus/achievement-detail/export/account', params)
}

//开票业绩详情
export const bonusAchievementDetailInvoice = (params) => {
    return postRequestUseBodyParam('/bonus/achievement-detail/invoice', params)
}

//导出开票业绩
export const bonusAchievementDetailExportInvoice = (params) => {
    return postRequestUseBodyParam('/bonus/achievement-detail/export/invoice', params)
}

//确认发放奖金
export const bonusDataPay = (params) => {
    return postRequestUseBodyParam('/bonus/data/pay', params)
}

//奖金表获取下拉列表
export const bonusCalculateModelBonusOptionList = () => {
    return getRequest('/bonus/calculate-model/bonus-option-list',)
}

//计提测算申请
export const accrualCalculateAccrualCalculate = (params) => {
    return postRequestUseBodyParam('/accrual/calculate-accrual/calculate', params)
}

//计提导出获取已发奖金数据
export const accrualDataExportPaidList = (params) => {
    return postRequestUseBodyParam('/accrual/data/export-paid-list', params)
}

//计提导出核对测算表数据
export const accrualDataExportGeneratedList = (params) => {
    return postRequestUseBodyParam('/accrual/data/export-generated-list', params)
}

//计提导出待发奖金数据
export const accrualDataExportToPayList = (params) => {
    return postRequestUseBodyParam('/accrual/data/export-to-pay-list', params)
}

//计提测算选取的顾问
export const accrualCalculateSscCalculateUser = (params) => {
    return postRequestUseBodyParam('/accrual/calculate-accrual/calculate-user', params)
}

//计提测算表重新测算
export const accrualCalculateSscReCalculate = (params) => {
    return postRequestUseBodyParam('/accrual/calculate-accrual/re-calculate', params)
}

//计提待发表重新测算
export const accrualCalculateSscReCalculateToPay = (params) => {
    return postRequestUseBodyParam('/accrual/calculate-accrual/re-calculate-to-pay', params)
}

//计提明细
export const accrualReportDetail = (params) => {
    return postRequestUseBodyParam('/accrual/report/detail', params)
}

//导出计提明细
export const accrualReportExportDetail = (params) => {
    return postRequestUseBodyParam('/accrual/report/export-detail', params)
}

//团队计提明细
export const accrualReportTeamDetail = (params) => {
    return postRequestUseBodyParam('/accrual/report/team-detail', params)
}

//导出团队计提明细
export const accrualReportExportTeamDetail = (params) => {
    return postRequestUseBodyParam('/accrual/report/export-team-detail', params)
}

//计提奖金总额
export const accrualReportRecords = (params) => {
    return postRequestUseBodyParam('/accrual/report/records', params)
}

//计提奖金月总额
export const accrualReportTotal = (params) => {
    return postRequestUseBodyParam('/accrual/report/total', params)
}

//奖金明细
export const bonusReportDetail = (params) => {
    return postRequestUseBodyParam('/bonus/report/detail', params)
}

//导出奖金明细
export const bonusReportExportDetail = (params) => {
    return postRequestUseBodyParam('/bonus/report/export-detail', params)
}

//奖金发放记录
export const bonusReportRecords = (params) => {
    return postRequestUseBodyParam('/bonus/report/records', params)
}

//奖金总额
export const bonusReportTotal = (params) => {
    return postRequestUseBodyParam('/bonus/report/total', params)
}

//顾问测算
export const bonusReportUserCalculate = (params) => {
    return postRequestUseBodyParam('/bonus/report/user-calculate', params)
}
