 <template>
  <section>
    <div class="Etitle">
      <p>基本信息</p>
      <el-form
        @submit.native.prevent
        :model="interviewForm"
        label-width="130px"
        ref="interviewForm"
      >
        <el-row style="display: flex">
          <el-col>
            <el-form-item
              label="面试时间："
              :rules="[
                {
                  required: true,
                  message: '请选择面试时间',
                  trigger: 'change',
                },
              ]"
              prop="time"
            >
              <el-date-picker
                v-model="interviewForm.time"
                type="datetime"
                style="width: 100%"
                placeholder="选择日期"
                value-format="yyyy-MM-dd HH:mm"
                format="yyyy-MM-dd HH:mm"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item v-if="!isEdit" label-width="15px">
              <el-checkbox v-model="interviewForm.addToSchedule"
                >添加到日程提醒</el-checkbox
              >
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item
              label="面试轮数："
              :rules="[
                {
                  required: true,
                  message: '请输入面试轮数',
                  trigger: 'change',
                },
              ]"
              prop="num"
            >
              <el-input-number
                style="width: 200px"
                v-model="interviewForm.num"
                :min="1"
              ></el-input-number>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item style="margin-bottom: 0" label-width="15px">
              <el-checkbox v-model="interviewForm.isFinal">终面</el-checkbox>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="Etitle" v-if="!iseditD">
      <p>
        <el-checkbox
          v-model="interviewForm.checked"
          style="margin-right: 8px"
        ></el-checkbox
        >通知候选人
      </p>
      <sendEmailFrom
        v-if="interviewForm.checked"
        ref="sendEmailFrom"
        type="给候选人：客户面试通知"
        :projectId="projectId"
        :resumeId="resumeId"
        :projectResumeId="projectResumeId"
        :interviewRound="interviewForm.num"
        :interviewTime="interviewForm.time"
        @loadList="changeLoading"
      ></sendEmailFrom>
    </div>
  </section>
</template>
<script>
import sendEmailFrom from "./pipeline/sendEmailFrom";
import {
  addOrEditInterview,
  getOperaterecordById,
  resumeMember,
} from "../api/api";
export default {
  components: {
    sendEmailFrom,
  },
  // resumeMemberId 计算面试轮数
  props: [
    "projectResumeId",
    "projectResumeId1",
    "resumeMemberId",
    "resumeId",
    "projectId",
    "iseditD",
  ],
  data() {
    return {
      childValue: false,
      interviewForm: {
        time: null,
        num: 1,
        // type: null,
        addToSchedule: false,
        isFinal: false,
        id: 0,
        version: 0,
        checked: false,
      },
      isEdit: false,
      saveloading: false,
    };
  },
  created() {
    if (!this.iseditD) {
      this.resumeMember();
    }
  },
  methods: {
    saveInterview: function (formName) {
      return new Promise((resolve, reject) => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.saveloading = true;
            // return
            if (this.interviewForm.checked) {
              this.$refs.sendEmailFrom
                .saveEmail("emailform", this.interviewForm)
                .then(() => {
                  this.saveloading = false;
                })
                .then(() => {
                  this.saveloading = false;
                });
            } else {
              addOrEditInterview({
                id: this.interviewForm.id,
                objectContent: {
                  interviewDate: this.interviewForm.time,
                  interviewRound: this.interviewForm.num,
                  isFinal: this.interviewForm.isFinal,
                  addToSchedule: this.interviewForm.addToSchedule,
                },
                projectResumeId: this.projectResumeId,
                version: this.interviewForm.version,
              }).then((res) => {
                if (res.success) {
                  this.$message.success("操作成功");
                  this.$refs.interviewForm.resetFields();
                  this.$emit("loadList");
                }
                this.saveloading = false;
                resolve();
              });
            }
          } else {
            // reject();
            this.changeLoading("isNoloading");
            return false;
          }
        });
      });
    },

    resumeMember() {
      return new Promise((resolve) => {
        this.interviewForm.num = "";
        resumeMember({ projectResumeId: this.resumeMemberId }).then((res) => {
          if (res.success) {
            this.interviewForm.num = res.result.interviewRound;
          }
          resolve();
        });
      });
    },
    loadEditInfo(row) {
      getOperaterecordById({
        id: row.id,
      }).then((res) => {
        if (res.success) {
          this.interviewForm.time =
            res.result.objectContent.interviewDate.split(" ").length > 1
              ? res.result.objectContent.interviewDate
              : res.result.objectContent.interviewDate + " 00:00:00";
          this.interviewForm.num = res.result.objectContent.interviewRound;
          this.interviewForm.type = res.result.objectContent.interviewType;
          this.interviewForm.isFinal = res.result.objectContent.isFinal;
          this.interviewForm.addToSchedule =
            res.result.objectContent.addToSchedule;
          this.interviewForm.id = res.result.id;
          this.interviewForm.version = res.result.version;
          this.isEdit = true;
          // this.resumeMember();
        } else {
          this.$emit("childByValue", this.childValue);
        }
      });
    },
    changeLoading(val) {
      this.saveloading = false;
      this.$emit("emailDrawerClose", val);
    },
  },
};
</script>

<style scoped>
.Etitle {
  margin: 0;
}
</style>
