<template>
  <el-form
    :model="ruleForm"
    :rules="rules"
    ref="ruleForm"
    label-width="100px"
    class="demo-ruleForm"
  >
    <el-form-item label="类型：" prop="scheduleType">
      <el-select
        filterable
        v-model="ruleForm.scheduleType"
        placeholder="请选择"
        style="width: 80%"
      >
        <el-option
          v-for="(i, index) in scheduleTypeList"
          :key="index"
          :label="i.label"
          :value="i.val"
        ></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="日期：" prop="scheduleTime">
      <el-date-picker
        type="datetime"
        value-format="yyyy-MM-dd HH:mm"
        format="yyyy-MM-dd HH:mm"
        placeholder="选择日期"
        v-model="ruleForm.scheduleTime"
        style="width: 80%"
      ></el-date-picker>
    </el-form-item>
    <el-form-item label="备注：" prop="comment">
      <el-input
        v-model="ruleForm.comment"
        type="textarea"
        :row="3"
        maxlength="100"
        style="width: 80%"
      ></el-input>
    </el-form-item>
    <el-form-item label="参与者：" class="fromInput" prop="joinUserIds">
      <el-select
        multiple
        filterable
        collapse-tags
        v-model="ruleForm.joinUserIds"
        placeholder="请选择"
        style="width: 80%"
      >
        <el-option
          v-for="i in userList"
          :label="i.name"
          :value="i.id"
          :key="i.id"
        ></el-option>
      </el-select>
    </el-form-item>
  </el-form>
</template>

<script>
import {
  getUserBaseInfos,
  scheduleAddOrEdit
} from "../../api/api";
export default {
  name: "caledarfrom",
  props: ["propScheduleData", "targetId"],
  data() {
    return {
      ruleForm: {
        comment: "",
        id: "",
        joinUserIds: [],
        moduleType: "人才",
        scheduleTime: "",
        scheduleType: "联系候选人",
        targetId: 0,
        version: 0,
      },
      rules: {
        scheduleType: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        scheduleTime: [
          { required: true, message: "请选择", trigger: "change" },
        ],
      },
      scheduleTypeList: [
        { label: "联系候选人", val: "联系候选人" },
        { label: "候选人面试", val: "候选人面试" },
        { label: "其他", val: "其他" },
      ],
      userList: [],
    };
  },
  watch: {
    propScheduleData(n, o) {
      this.ruleForm = n;
    },
  },
  created() {
    this.getUser();
  },
  methods: {
    //获取参与者
    getUser() {
      getUserBaseInfos().then((res) => {
        this.userList = res.result;
      });
    },
    //提交
    submitForm(formName) {
      // debugger
      return new Promise((resole) => {
        // debugger
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.ruleForm.targetId = this.targetId;
            scheduleAddOrEdit(this.ruleForm).then((res) => {
              resole();
            });
          }
        });
      }).catch((error) => {
      });
    },
  },
};
</script>