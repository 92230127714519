var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('el-row',[_c('el-form',{ref:"filter",attrs:{"model":_vm.filter},nativeOn:{"submit":function($event){$event.preventDefault();}}},[_c('el-row',{attrs:{"gutter":24}},[_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{staticClass:"proserch",attrs:{"label":"","prop":"name"}},[_c('el-input',{attrs:{"placeholder":"请输入项目名称/项目经理/参与者/公司名称，支持模糊搜索"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.searchList()}},model:{value:(_vm.filter.filter),callback:function ($$v) {_vm.$set(_vm.filter, "filter", $$v)},expression:"filter.filter"}},[_c('el-button',{attrs:{"slot":"append"},on:{"click":function($event){return _vm.searchList()}},slot:"append"},[_vm._v("搜索")])],1)],1)],1)],1)],1)],1),_c('el-row',{attrs:{"gutter":24}},[_c('div',[(_vm.pagination.total > 0)?_c('el-pagination',{staticStyle:{"padding":"0"},attrs:{"hide-on-single-page":_vm.pagination.total > 10 ? false : true,"current-page":_vm.pagination.pageNo,"page-sizes":[10, 20, 50, 100],"page-size":_vm.pagination.pageSize,"layout":"total,prev, pager, next,sizes","total":_vm.pagination.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}}):_vm._e()],1),_c('el-col',{attrs:{"span":24}},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.listLoading),expression:"listLoading"}],staticClass:"tabBorder",attrs:{"header-cell-style":{
          'background-color': '#f5f7fa',
        },"data":_vm.ListData}},[_c('el-table-column',{attrs:{"show-overflow-tooltip":true,"prop":"id","label":"ID","align":"left","width":"80px"}}),_c('el-table-column',{attrs:{"show-overflow-tooltip":true,"prop":"projectName","label":"项目名称","align":"left","min-width":"300px"}}),_c('el-table-column',{attrs:{"show-overflow-tooltip":true,"prop":"managerName","label":"项目经理","align":"left","min-width":"200px"}}),_c('el-table-column',{attrs:{"show-overflow-tooltip":true,"prop":"createdTime","label":"创建时间","align":"left","min-width":"110px"}}),_c('el-table-column',{attrs:{"show-overflow-tooltip":true,"prop":"status","label":"状态","align":"left","min-width":"100px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"tag",class:{
                tag01: scope.row.status == '进行中',
                tag02: scope.row.status == '成功',
                tag03: scope.row.status == '暂停',
              }},[_vm._v(_vm._s(scope.row.status))])]}}])}),_c('el-table-column',{attrs:{"show-overflow-tooltip":true,"prop":"","label":"操作","align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.inProject)?_c('span',[_vm._v("已在项目中")]):_c('el-button',{staticClass:"tabHref",attrs:{"size":"mini","type":"text"},on:{"click":function($event){return _vm.addCandidates(scope.row.id)}}},[_vm._v("加入项目")])]}}])})],1),(_vm.pagination.total > 0)?_c('el-pagination',{staticStyle:{"padding":"0"},attrs:{"hide-on-single-page":_vm.pagination.total > 10 ? false : true,"current-page":_vm.pagination.pageNo,"page-sizes":[10, 20, 50, 100],"page-size":_vm.pagination.pageSize,"layout":"total,prev, pager, next,sizes","total":_vm.pagination.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}}):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }