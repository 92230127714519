<template>
  <div class="markRecode" style="margin: 15px">
    <div class="time-line">
      <div
        v-for="(item, index) in OperateList"
        :key="index"
        class="time-line-div"
      >
        <div class="time-line_l">
          <p class="time">{{ item.createdTime }}</p>
          <p class="name">{{ item.createdUserName }}</p>
        </div>
        <p class="line"></p>
        <div class="time-line_r">
          <div class="time-line_con">
            <p class="r_title">
              <span class="tag tag15">{{ item.operateType }}</span>
            </p>
            <div class="r_con">
              <div
                style="color: #666; font-size: 13px"
                v-html="item.content"
              ></div>
              <div
                v-if="item.operateType == '简历合并' && hisPhones"
                style="color: #666; font-size: 13px"
              >
                历史联系方式：{{ hisPhones }}
              </div>
              <el-button
                v-if="
                  item.fileId &&
                  (item.operateType == '更新简历' ||
                    item.operateType == '创建简历')
                "
                type="text"
                size="mini"
                style="color: rgba(86, 176, 240, 1)"
                @click="previewResumeFile(item.fileId)"
                >查看简历
              </el-button>
              <el-button
                type="text"
                size="mini"
                v-if="item.fileId"
                @click="download(item.fileId)"
                >下载原始人选
              </el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="dott"></div>
    </div>
  </div>
</template>

<script>
import { ApiBaseUrl, PreviewUrl } from "../../api/http";
import { getResumeOperateRecord } from "../../api/api";
import { previewFile, downloadFile } from "../../libs/filePreview";
export default {
  props: ["id", "secureContacts", "hisPhones"],
  data() {
    return {
      OperateList: [],
    };
  },
  created() {
    // this.loadOperateList();
  },
  methods: {
    //加载操作日志
    loadOperateList() {
      getResumeOperateRecord({
        resumeId: this.id,
        secureContacts: this.secureContacts,
      }).then((res) => {
        if (res.success) {
          this.OperateList = res.result;
        }
      });
    },
    previewResumeFile(id) {
      previewFile(id);
    },
    // 预览
    downOrgResume(item) {
      const fileType =
        item.resumeName.split(".")[item.resumeName.split(".").length - 1];
      if (
        fileType == "doc" ||
        fileType == "docx" ||
        fileType == "xlsx" ||
        fileType == "ppt"
      ) {
        //wps预览
        window.open(
          "https://view.officeapps.live.com/op/view.aspx?src=" +
            PreviewUrl +
            item.resumePath,
          "_blank"
        );
      } else if (fileType == "pdf") {
        //pdf预览
        window.open(
          "/pdf/web/viewer.html?file=" + PreviewUrl + item.resumePath,
          "_blank"
        );
      } else if (fileType == "png" || fileType == "jpg" || fileType == "jpeg") {
        //图片预览
        window.open(PreviewUrl + item.resumePath, "_blank");
      } else if (fileType) {
        //下载
        location.href =
          ApiBaseUrl +
          "/api/file/download?fileName=" +
          encodeURIComponent(item.resumeName) +
          "&filePath=" +
          item.resumePath;
      }
      // .zip .doc .docx .pdf .jpg
      // window.open(link,'_blank');
    },
    //下载
    download(id) {
      downloadFile(id);
    },
    //下载
    down(item) {
      location.href =
        ApiBaseUrl +
        "/api/file/download?fileName=" +
        encodeURIComponent(item.resumeName) +
        "&filePath=" +
        item.resumePath;
    },
  },
};
</script>

<style lang="scss" scoped>
.time-line {
  position: relative;
  padding: 0 15px;
}
.time-line-div {
  position: relative;
  min-height: 85px;
  margin-top: 15px;
  &:nth-child(1) {
    margin-top: 0 !important;
  }
  .time-line_l {
    position: absolute;
    left: 0;
    width: 135px;
    text-align: right;
    p {
      margin: 0;
      line-height: 1;
    }
    .time {
      padding-top: 24px;
      font-size: 12px;
      color: #999;
    }
    .name {
      font-size: 13px;
      margin-top: 10px;
      color: #606266;
    }
  }
  .line {
    position: absolute;
    left: 155px;
    width: 12px;
    height: 12px;
    top: 20px;
    background: #e4e7ed;
    border-radius: 50%;
    z-index: 10;
  }
  .time-line_r {
    position: relative;
    left: 191px;
    width: calc(100% - 180px);
    .time-line_con {
      border: 1px solid #e5e5e5;
      .r_title {
        height: 40px;
        line-height: 40px;
        background-color: #f5f5f5;
        margin: 0;
        padding-left: 10px;
        border-bottom: 1px solid #e5e5e5;
      }
      .r_con {
        padding: 15px 20px;
        font-size: 13px;
        line-height: 21px;
      }
    }
  }
}
.dott {
  position: absolute;
  width: 5px;
  left: 173px;
  height: calc(100% - 45px);
  top: 15px;
  bottom: 5px;
  z-index: 1;
  background-color: #e5e5e5;
}
</style>
