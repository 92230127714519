import Vue from 'vue'
import './plugins/axios'
import store from './store/index'
import Vuex from 'vuex'
import ElementUI from 'element-ui';
import dialog from './plugins/dialog'
// import 'element-ui/lib/theme-chalk/index.css';
import "../theme/index.css"
import App from './App.vue';
import VueRouter from 'vue-router'
import routes from './router/router'
import hasPermission from '@/libs/hasPermission'
import hasMenuPage from '@/libs/hasMenuPage'
import Cookies from 'js-cookie';
import horizontalScroll from 'el-table-horizontal-scroll'
import '@/directives/table-sticky.js'
import 'default-passive-events'
import {
  setStore,
  getStore,
  removeStore
} from '@/libs/storage'
import filter from './libs/filter'
// 全局导入过滤器
Object.keys(filter).forEach(key => Vue.filter(key, filter[key]));
Vue.use(ElementUI);
Vue.use(Vuex);
Vue.use(hasPermission);
Vue.use(dialog)
Vue.use(horizontalScroll)
Vue.config.productionTip = false
Vue.prototype.hasMenuPage = hasMenuPage;
Vue.prototype.setStore = setStore;
Vue.prototype.getStore = getStore;
Vue.prototype.removeStore = removeStore;
const originalReplace = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalReplace.call(this, location).catch(err => err)
}
Date.prototype.Format = function (fmt) { //author: meizz
  var o = {
    "M+": this.getMonth() + 1, //月份
    "d+": this.getDate(), //日
    "h+": this.getHours(), //小时
    "m+": this.getMinutes(), //分
    "s+": this.getSeconds(), //秒
    "q+": Math.floor((this.getMonth() + 3) / 3), //季度
    "S": this.getMilliseconds() //毫秒
  };
  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
  return fmt;
}
const router = new VueRouter({
  mode: 'history', // require service support
  scrollBehavior: () => ({
    y: 0
  }),
  routes
})
//主页禁止滑动
Vue.prototype.noScroll = function () {
  var mo = function (e) {
    e.preventDefault()
  }
  document.body.style.overflow = 'hidden'
  document.addEventListener('touchmove', mo, false)//禁止页面滑动
}

//主页恢复滑动
Vue.prototype.canScroll = function () {
  var mo = function (e) {
    e.preventDefault()
  }
  document.body.style.overflow = ''//出现滚动条
  document.removeEventListener('touchmove', mo, false)
}
router.beforeEach((to, from, next) => {


  let allMenu = []
  allMenu = routes[0].children.filter(item => {
    return item.authority
  })
  if (to.meta.name) {
    document.title = to.meta.name
  }
  if (to.matched.length === 0) { // 如果未匹配到路由
    next('/404')
  }
  if (to.path == '/403' || to.path == '/404' || to.path == "/resetPwd"||to.path=='/activation'||to.path=='/uploadFile'||to.path=='/onlineEdit'||to.path=='/updateResume') {
    next()
  }
  else {
    if (!Cookies.get('accessToken') && to.path !== '/login' || to.path == '/') {
      next({
        path: '/login'
      })
    } else if (Cookies.get('accessToken') && to.path == '/login') {
      setStore("activeIndex", '/Home');
      next({
        path: '/Home'
      });
    } else {
      for (let i = 0; i < allMenu.length; i++) {
        if (allMenu[i].path == to.path) {
          if (hasMenuPage(allMenu[i].authority)) { //是否有页面权限
            next()
          } else {
            next({
              path: '/403'
            })
          }
        } else {
          next()
        }
      }

      next()
    }
  }
})
router.afterEach((to, from, next) => { document.querySelector("body").setAttribute("style", "overflow: auto !important;") });
window.$globalHub = new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')