<template>
  <div>
    <div class="drawerCon_from">
      <el-form
        @submit.native.prevent
        :model="addInfo"
        ref="addInfo"
        :rules="rulesLink"
        :validate-on-rule-change="false"
        label-width="100px"
        label-position="right"
      >
        <p class="drawerCon_from_tit">基本信息</p>
        <el-row :gutter="24">
          <el-col :offset="1" :span="10">
            <el-form-item label="姓名：" prop="contacts.name">
              <el-input
                placeholder="请输入内容"
                v-model.trim="addInfo.contacts.name"
                maxlength="20"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :offset="1" :span="10">
            <el-form-item label="职位：" prop="contacts.position">
              <el-input
                placeholder="请输入内容"
                v-model.trim="addInfo.contacts.position"
                maxlength="20"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :offset="1" :span="10">
            <el-form-item label="联系电话：" prop="contacts.tel">
              <el-input
                placeholder="请输入内容"
                v-model.trim="addInfo.contacts.tel"
                maxlength="50"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :offset="1" :span="10">
            <el-form-item label="邮箱：" prop="contacts.email">
              <el-input
                placeholder="请输入内容"
                v-model.trim="addInfo.contacts.email"
                maxlength="50"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :offset="1" :span="10">
            <el-form-item label="微信或其他：" prop="contacts.wechatOrOther">
              <el-input
                placeholder="请输入内容"
                v-model.trim="addInfo.contacts.wechatOrOther"
                maxlength="50"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-collapse v-model="scheduleNames" style="margin-bottom: 10px">
          <el-collapse-item name="日程" class="fileCollapse drawerC">
            <template slot="title">
              日程<span class="collR tabHref">{{
                scheduleNames == "日程" ? "删除" : "新增"
              }}</span>
            </template>
            <div v-if="scheduleNames == '日程'">
              <el-row :gutter="24" style="margin-top: 20px">
                <el-col :offset="1" :span="10">
                  <el-form-item
                    label="类型："
                    prop="scheduleVo.scheduleType"
                    :rules="
                      scheduleNames == '日程'
                        ? {
                            required: true,
                            message: '请选择类型',
                            trigger: 'change',
                          }
                        : []
                    "
                  >
                    <el-select
                      filterable
                      v-model="addInfo.scheduleVo.scheduleType"
                      placeholder="请选择"
                      style="width: 100%"
                    >
                      <el-option
                        v-for="i in scheduleTypeList"
                        :key="i.label"
                        :label="i.label"
                        :value="i.label"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="24">
                <el-col :offset="1" :span="10">
                  <el-form-item
                    label="日期："
                    prop="scheduleVo.scheduleTime"
                    :rules="
                      scheduleNames == '日程'
                        ? {
                            required: true,
                            message: '请选择日期',
                            trigger: 'change',
                          }
                        : []
                    "
                  >
                    <el-date-picker
                      type="datetime"
                      placeholder="选择日期"
                      value-format="yyyy-MM-dd HH:mm"
                      format="yyyy-MM-dd HH:mm"
                      v-model="addInfo.scheduleVo.scheduleTime"
                      style="width: 100%"
                    ></el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="24">
                <el-col :offset="1" :span="10">
                  <el-form-item
                    label="参与者："
                    class="fromInput"
                    prop="scheduleVo.joinUserIds"
                  >
                    <el-select
                      multiple
                      filterable
                      collapse-tags
                      v-model="addInfo.scheduleVo.joinUserIds"
                      placeholder="请选择"
                      style="width: 100%"
                    >
                      <el-option
                        v-for="i in userList"
                        :label="i.name"
                        :key="i.id"
                        :value="i.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :offset="1" :span="21">
                  <el-form-item label="备注：" prop="scheduleVo.comment">
                    <el-input
                      v-model="addInfo.scheduleVo.comment"
                      type="textarea"
                      maxlength="100"
                      :row="3"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </el-collapse-item>
        </el-collapse>
        <el-collapse v-model="RecordNames">
          <el-collapse-item name="备注" class="fileCollapse drawerC">
            <template slot="title">
              备注<span class="collR tabHref">{{
                RecordNames == "备注" ? "删除" : "新增"
              }}</span>
            </template>
            <div v-if="RecordNames == '备注'">
              <el-row :gutter="24" style="margin-top: 20px">
                <el-col :offset="1" :span="10">
                  <el-form-item
                    label="备注方案："
                    prop="commentRecordDto.conenT"
                    :rules="
                      RecordNames == '备注'
                        ? {
                            required: true,
                            message: '请选择备注方案',
                            trigger: 'change',
                          }
                        : []
                    "
                  >
                    <el-select
                      style="width: 100%"
                      v-model="addInfo.commentRecordDto.conenT"
                      value-key="id"
                      placeholder="请选择"
                      clearable
                      @change="getPlanInfo($event)"
                    >
                      <el-option-group label="客户沟通">
                        <el-option
                          v-for="i in RecodePlan1"
                          :key="i.id"
                          :label="i.name"
                          :value="i"
                        ></el-option>
                      </el-option-group>
                      <el-option-group
                        label="客户拜访"
                        v-if="RecodePlan2.length > 0"
                      >
                        <el-option
                          v-for="i in RecodePlan2"
                          :key="i.id"
                          :label="i.name"
                          :value="i"
                        ></el-option>
                      </el-option-group>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-form
                @submit.native.prevent
                label-position="top"
                v-if="RecodePlanQA.length > 0"
              >
                <el-row :gutter="24" style="padding: 0 50px">
                  <el-col
                    :offset="1"
                    :span="22"
                    v-for="(item, index) in RecodePlanQA"
                    :key="item.id"
                  >
                    <el-form-item
                      :label="index + 1 + '.' + item.title"
                      :required="item.required"
                      class="selectLabel"
                    >
                      <div
                        v-if="
                          item.type == '单选型' && item.selectType == '单选'
                        "
                      >
                        <el-radio-group v-model="item.answer">
                          <p v-for="(i, _index) in item.options" :key="_index">
                            <el-radio
                              :label="i"
                              @click.native.prevent="clickItem(i, index)"
                              >{{ i }}</el-radio
                            >
                          </p>
                        </el-radio-group>
                      </div>
                      <div
                        v-if="
                          item.type == '单选型' && item.selectType == '下拉'
                        "
                      >
                        <el-select
                          v-model="item.answer"
                          clearable
                          placeholder="请选择"
                        >
                          <el-option
                            v-for="(i, _index) in item.options"
                            :key="_index"
                            :label="i"
                            :value="i"
                          ></el-option>
                        </el-select>
                      </div>
                      <div v-if="item.type == '多选型'">
                        <el-checkbox-group v-model="item.answer1">
                          <p v-for="(i, _index) in item.options" :key="_index">
                            <el-checkbox :label="i">{{ i }}</el-checkbox>
                          </p>
                        </el-checkbox-group>
                      </div>
                      <div v-if="item.type == '文本描述型'">
                        <el-input
                          v-model="item.answer"
                          placeholder="请输入内容"
                          type="textarea"
                          :rows="4"
                        ></el-input>
                      </div>
                      <div v-if="item.type == '日期型'">
                        <el-date-picker
                          prefix-icon="el-icon-date"
                          v-model="item.answer"
                          type="date"
                          placeholder="选择日期"
                          value-format="yyyy/MM/dd"
                        ></el-date-picker>
                      </div>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-form>
    </div>
    <div class="drawerCon_footer fixedFoot">
      <el-button size="small" @click="cancel">取 消</el-button>
      <el-button
        type="primary"
        :loading="isLoading"
        size="small"
        @click="saveAdd('addInfo')"
        style="margin-right:20px"
        >保 存</el-button
      >
    </div>
  </div>
</template>

<script>
import {
  getUserBaseInfos, //获取用户
  getCommentColumnById,
  getRecordPlanList,
  comContactsAdds,
} from "../../api/api";
import addMarkReacode from "../remark/remark"; //备注
export default {
  name: "AddLSCInfo",
  components: { addMarkReacode },
  props: ["customId"],
  data() {
    return {
      RecodePlan1: [], //备注方案List
      RecodePlan3: [], //备注方案List
      RecodePlan2: [], //备注方案List
      RecodePlanQA: [], //备注方案ListQA
      scheduleNames: [],
      RecordNames: [],
      userList: [],
      scheduleTypeList: [
        { label: "客户电话" },
        { label: "客户拜访" },
        { label: "客户邮件" },
        { label: "其他" },
      ],
      addInfo: {
        commentRecordDto: {
          commentColumns: [],
          commentId: 0,
          id: 0,
          conenT: "",
          relativeId: 0,
          relativeType: "客户",
          version: 0,
        },
        contacts: {
          companyId: 0,
          email: "",
          id: 0,
          name: "",
          position: "",
          tel: "",
          version: 0,
          wechatOrOther: "",
        },
        scheduleVo: {
          comment: "",
          id: 0,
          joinUserIds: [],
          moduleType: "客户",
          relatePersonId: 0,
          scheduleStatus: "",
          scheduleTime: "",
          scheduleType: "",
          targetId: 0,
          version: 0,
        },
      },
      rulesLink: {
        "contacts.name": [
          {
            required: true,
            trigger: "blur",
            message: "请输入姓名",
          },
        ],
        "contacts.position": [
          {
            required: true,
            trigger: "blur",
            message: "请输入职位名称",
          },
        ],
      },
      isLoading: false,
    };
  },
  created() {
    this.getUser();
    this.loadRecodePlanList("客户沟通");
    this.loadRecodePlanList("客户拜访");
  },
  methods: {
    cancel() {
      this.$emit("drawer");
    },
    getPlanInfo(e, ans) {
      //获取备注方案问题
      if (e) {
        this.addInfo.commentRecordDto.commentId = e.id;

        getCommentColumnById({ commentId: e.id }).then((res) => {
          if (res.success) {
            this.RecodePlanQA = res.result;
            if (ans) {
              this.RecodePlanQA.forEach((item) => {
                ans.forEach((i) => {
                  if (item.id == i.commentColumnId) {
                    if (i.type != "多选型") {
                      this.$set(item, "answer", i.value);
                      this.$set(item, "answer1", []);
                    } else {
                      this.$set(item, "answer", "");
                      this.$set(
                        item,
                        "answer1",
                        i.value ? i.value.split(",") : []
                      );
                    }
                  }
                });
              });
            } else {
              this.RecodePlanQA.forEach((item) => {
                this.$set(item, "answer1", []);
                this.$set(item, "answer", "");
              });
            }
          }
        });
      } else {
        this.hasSelType = false;
        this.RecodePlanQA = [];
      }
    },
    clickItem(i, index) {
      if (i == this.RecodePlanQA[index].answer) {
        this.RecodePlanQA[index].answer = "";
      } else {
        this.RecodePlanQA[index].answer = i;
      }
    },
    loadRecodePlanList(type) {
      //加载备注记录List
      getRecordPlanList({
        type: type,
      }).then((res) => {
        if (res.success) {
          if (type == "客户拜访") {
            this.RecodePlan2 = res.result;
          } else {
            this.RecodePlan1 = res.result;
          }
        }
      });
    },
    saveAdd(formName) {
      this.isLoading = true;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (
            !this.addInfo.contacts.tel &&
            !this.addInfo.contacts.email &&
            !this.addInfo.contacts.wechatOrOther
          ) {
            this.$message.error("联系电话/邮箱/微信或其他至少填写1项");
            return false;
          }
          this.addInfo.commentRecordDto.commentColumns = [];
          for (let i = 0; i < this.RecodePlanQA.length; i++) {
            if (
              this.RecodePlanQA[i].required &&
              (this.RecodePlanQA[i].answer == "" ||
                this.RecodePlanQA[i].answer == null) &&
              this.RecodePlanQA[i].answer1.length == 0
            ) {
              reject();
              this.$message.error("请将必填项填写完整");
              return false;
            }
          }
          this.RecodePlanQA.map((item) => {
            this.addInfo.commentRecordDto.commentColumns.push({
              columnTitle: item.title,
              commentColumnId: item.id,
              type: item.type,
              value:
                item.type == "多选型" ? item.answer1.join(",") : item.answer,
            });
          });
          delete this.addInfo.commentRecordDto.conenT;
          this.addInfo.commentRecordDto.relativeId = Number(this.customId);
          this.addInfo.contacts.companyId = Number(this.customId);
          this.addInfo.scheduleVo.targetId = Number(this.customId);
          comContactsAdds(this.addInfo).then((res) => {
            if (res.success) {
              if (res.result) {
                if (res.result.repeat) {
                  let h = this.$createElement;
                  this.$msgbox({
                    title: "提示",
                    message: h(
                      "div",
                      {
                        style: "text-align:center;",
                      },
                      [
                        h(
                          "strong",
                          {
                            style: "font-size:16px;font-weight: 800;margin-top: 10px;",
                          },
                          "编辑成功"
                        ),
                        h(
                          "p",
                          {
                            style: "margin: 30px 0px;color:#e60012",
                          },
                          [
                            "客户联系人无法正常保存到人才库（人才重复），请及时到",
                            h(
                              "span",
                              {
                                class: "tabHref",
                                on: {
                                  click: () => {
                                    this.goResunme();
                                  },
                                },
                              },
                              "【待处理人才】"
                            ),
                            "处理",
                          ]
                        ),
                      ]
                    ),
                    cancelButtonText: "关闭",
                    showConfirmButton: false,
                    dangerouslyUseHTMLString: true,
                    customClass: "msgClass1",
                    beforeClose: "closeConf",
                  })
                    .then(() => {})
                    .catch(() => {
                      this.$emit("drawer");
                      this.$emit("loadcusInfoById");
                    });
                } else {
                  let div =
                    "<p style='font-weight:bold;text-align:center'>新增成功</p>" +
                    "<div style='text-align:center'>联系人已同步至人才库</div>";
                  this.$confirm(div, "提示", {
                    showConfirmButton: false,
                    cancelButtonText: "关闭",
                    customClass: "altCls",
                    dangerouslyUseHTMLString: true,
                  })
                    .then(() => {})
                    .catch(() => {
                      this.$emit("drawer");
                      this.$emit("loadcusInfoById");
                    });
                }
              } else {
                this.$message.success(res.message);
                this.$emit("drawer");
                this.$emit("loadcusInfoById");
              }
              this.isLoading = false;
            }
          });
        } else {
          this.isLoading = false;
          return false;
        }
      });
    },
    goResunme() {
      this.$emit("drawer");
      this.$emit("loadcusInfoById");
      this.$msgbox.close();
      let newUrl = this.$router.resolve({
        path: "/resume/pending",
      });
      window.open(newUrl.href, "_blank");
    },
    handleSChange(val) {
      this.scheduleNames = val[0];
    },
    handleRChange(val) {
      this.RecordNames = val[0];
    },
    getUser() {
      getUserBaseInfos().then((res) => {
        if (res.success) {
          this.userList = res.result;
        }
      });
    },
  },
};
</script>
<style lang="scss">
.drawerC {
  position: relative;
  .el-collapse-item__arrow {
    display: none;
  }
  .el-collapse-item__header {
    padding-left: 15px;
    color: #606266;
    font-weight: bold;
  }
  .collR {
    position: absolute;
    font-weight: normal;
    right: 15px;
  }
  .el-collapse-item__wrap {
    // border-bottom: 0;
  }
}
.msgClass1{
  width: 550px!important;
}
</style>