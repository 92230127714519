<template>
  <div class="clearfix">
    <span
      class="defaultFont"
      style="margin-bottom: 10px; display: inline-block; cursor: pointer"
      @click="createFileBtn"
      id="fileCreate"
      >+创建文件夹</span
    >
    <el-collapse v-model="activeName">
      <el-collapse-item title="我的文件夹" name="1" class="fileCollapse">
        <!--    我的文件夹-->

        <el-tree
          :data="myFileData"
          node-key="id"
          :show-checkbox="checkbox.edit ? true : false"
          @check-change="handleCheckChange"
          :check-on-click-node="true"
          empty-text="您还未创建文件夹"
          :props="defaultProps"
        >
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <span>{{ node.label }}</span>
            <div class="fileHover">
              <el-button
                type="text"
                @click.stop="editHasCreatedFile(node.data)"
                id="fileEditHasCreatedFileMy"
                >编辑</el-button
              >
              <el-divider direction="vertical"></el-divider>
              <el-button
                type="text"
                @click.stop="delHasCreatedFile(data.id, 'my')"
                id="fileDelHasCreatedFileMy"
                >删除</el-button
              >
            </div>
          </span>
        </el-tree>
      </el-collapse-item>

      <el-collapse-item title="共享文件夹" name="2" class="fileCollapse">
        <!--    共享文件夹-->
        <el-tree
          :data="shareData"
          node-key="id"
          empty-text="暂无共享文件夹"
          :show-checkbox="checkbox.edit ? true : false"
          @check-change="handleCheckChange"
          :check-on-click-node="true"
          :props="defaultProps"
        >
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <span class="fix">
              {{ node.label }}
            </span>
            <span class="create text-overflows"
              >创建者：{{ data.creatorName }}
              <span v-if="data.shareNames">
                共享给：{{ data.shareNames }}</span
              ></span
            >
            <div class="fileHover">
              <el-button
                type="text"
                @click.stop="editHasCreatedFile(node.data, data)"
                id="fileEditHasCreatedFileShare"
                >编辑</el-button
              >
              <el-divider direction="vertical"></el-divider>
              <el-button
                type="text"
                @click.stop="delHasCreatedFile(data.id, 'share')"
                id="fileDelHasCreatedFileShare"
                >删除</el-button
              >
            </div>
          </span>
        </el-tree>
      </el-collapse-item>
    </el-collapse>

    <!--  创建文件-->
    <el-dialog
      :visible.sync="createFile"
      :modal-append-to-body="false"
      append-to-body
      :title="createOrEditFile"
      :close-on-click-modal="false"
      width="600px"
    >
      <el-form
        :model="fileList"
        ref="fileList"
        :rules="rulesFiles"
        label-width="100px"
      >
        <el-col :span="20">
          <el-form-item label="文件夹名：" prop="name">
            <el-input
              maxLength="12"
              v-model.trim="fileList.name"
              placeholder="请输入"
              id="fileCreateFileName"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="24" style="margin-bottom: 15px">
          <div
            v-if="isShowWrapper"
            class="fl"
            style="width: 97px; line-height: 32px; text-align: right"
          >
            共享给：
          </div>
          <div class="fr" style="width: calc(100% - 100px); position: relative">
            <span
              style="height: 32px; line-height: 32px; cursor: pointer"
              class="defaultFont"
              @click="
                addShares('添加', 0), (isShowShare = true), (isShowBtn = false)
              "
              id="fileCreateFileAddShares"
              >+ 添加</span
            >

            <template v-if="isShowShare">
              <div
                class="shareRow"
                v-for="(i, inx) in fileList.shares"
                :key="i.id"
              >
                <el-select
                  style="width: 20%"
                  filterable
                  collapse-tags
                  v-model="i.type"
                  @change="diffType"
                  placeholder="请选择"
                  :id="'fileCreateFileAddSharesType' + inx"
                >
                  <el-option
                    v-for="(item, index) in shareTypes"
                    :key="item"
                    :label="item"
                    :value="item"
                    :id="'fileCreateFileAddSharesTypeList' + inx + index"
                  >
                  </el-option>
                </el-select>

                <el-select
                  style="width: 58%; margin-left: 8px"
                  filterable
                  collapse-tags
                  multiple
                  v-if="i.type == '同事'"
                  v-model="i.shareIds"
                  @change="workmate($event, inx)"
                  placeholder="请选择"
                  :id="'fileCreateFileAddSharesWorkmate' + inx"
                >
                  <el-option
                    v-for="(item, index) in diffShareData"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                    :id="
                      'fileCreateFileAddSharesDiffShareDataList' + inx + index
                    "
                  >
                  </el-option>
                </el-select>

                <el-cascader
                  style="width: 58%; margin-left: 8px"
                  v-if="i.type == '团队'"
                  v-model="i.teamIds"
                  class="fromInput"
                  collapse-tags
                  @change="teammate($event, inx)"
                  :options="teamValueOptions"
                  :props="teamProps"
                  :id="'fileCreateFileAddSharesTeammate' + inx"
                ></el-cascader>

                <span class="operationEditOrDel">
                  <span
                    class="miniBtn el-icon-plus"
                    @click="addShares(i, inx)"
                    :id="'fileCreateFileAddShares' + inx"
                  ></span>
                  <span
                    class="miniBtn el-icon-close"
                    @click="delShares(i, inx)"
                    :id="'fileCreateFileDelShares' + inx"
                  ></span>
                </span>
              </div>
            </template>
          </div>
        </el-col>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="default"
          plain
          size="mini"
          @click="createFile = false"
          id="fileCreateFileCencel"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="mini"
          :loading="saveloading"
          @click="addOrEditFavorite('fileList')"
          id="fileCreateFileSave"
          >保存</el-button
        >
      </span>
    </el-dialog>

    <!--    超出100个文件夹-->
    <el-dialog
      :visible.sync="overFilesDialog"
      :modal-append-to-body="false"
      append-to-body
      title="提示"
      :close-on-click-modal="false"
      width="400px"
    >
      <p>文件夹数量已达100个上限，无法继续创建</p>
    </el-dialog>
  </div>
</template>

<script>
import {
  addOrEditFavorite,
  getListFavorite,
  permissionTree,
  userOptions,
  delHasShareName,
  getFavoriteById,
} from "../../api/api";
export default {
  name: "file",
  props: ["type", "checkbox"],
  data() {
    return {
      favoriteData: [],
      saveloading: false,
      overFilesDialog: false,
      createOrEditFile: "创建文件夹",
      isShowWrapper: true,
      isShowBtn: true,
      createFile: false,
      defaultShowbox: false,
      rulesFiles: {
        name: [
          { required: true, message: "请输入文件夹名称", trigger: "blur" },
        ],
      },
      fileList: {
        id: "",
        name: "",
        shares: [
          {
            shareTypeDefault: "同事",
            shareIds: [],
            teamIds: [],
            type: "同事",
          },
        ],
        type: "",
        version: 0,
      },
      file: {},
      shareTypes: ["同事", "团队", "所有人"],
      activeName: ["1", "2"],
      teamValue: "",
      teamProps: {
        label: "name",
        value: "id",
        multiple: true,
      },
      defaultProps: {
        children: "children",
        label: "name",
      },
      teamValueOptions: [],
      myFileData: [],
      shareData: [],
      isShowShare: false,

      diffShareData: [],
      diffTypeVal: "",
      // shareTypeDefault: '同事',
    };
  },
  created() {
    this.getListFavorite();
    this.userOptions(); //同事
    this.OrganizinList(); //组织架构
  },
  methods: {
    // 列表
    getListFavorite() {
      getListFavorite({ type: this.type }).then((res) => {
        this.shareData = res.result.shareList;
        this.myFileData = res.result.myList;
        this.$emit("length", this.myFileData.length + this.shareData.length);
      });
    },
    // 保存加入文件夹
    saveFavorite() {},
    handleCheckChange(data, checked, indeterminate) {
      if (checked) {
        this.favoriteData.push(data.id);
      } else {
        // 取消的时候移除对应的id
        this.favoriteData.filter((i, inx) => {
          if (data.id == i) {
            this.favoriteData.splice(inx, 1);
          }
        });
      }
      this.$emit("getFavoriteData", this.favoriteData);
    },
    // 编辑创建的文件夹
    editHasCreatedFile(row, data) {
      this.createFile = true;

      this.createOrEditFile = "编辑文件夹";
      getFavoriteById({ id: row.id }).then((res) => {
        this.fileList = res.result;
        this.isShowShare = true;
      });

      this.fileList = row;
    },
    // 删除创建的文件夹
    delHasCreatedFile(id, type) {
      const foo =
        type == "share"
          ? "其他共享人的文件夹将同步删除，请确认是否删除？"
          : "是否删除该项";
      this.$confirm(foo, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "altCls",
        type: "warning",
      })
        .then(() => {
          this.delHasShareName(id);
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          this.$emit("delFile", id);
          // this.getListFavorite();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    delHasShareName(id) {
      delHasShareName(id).then((res) => {
        this.getListFavorite();
        this.favoriteData = [];
        this.$emit("getFavoriteData", this.favoriteData);
      });
    },
    // 新增分享
    addShares(row, inx) {
      this.fileList.shares.push({
        shareTypeDefault: "同事",
        type: "同事",
        shareIds: [],
        teamIds: [],
      });
    },

    // 删除分享新增
    delShares(row, inx) {
      this.isShowWrapper = true;
      if (inx == 0) {
        this.isShowBtn = true;
      }
      this.fileList.shares.splice(inx, 1);
    },

    // 同事
    workmate(e, inx) {
      this.fileList.shares[inx].shareIds = e;
    },
    // 团队
    teammate(e, inx) {
      this.fileList.shares[inx].teamIds = e;
    },

    diffType(val) {
      if (val == "同事") {
        this.userOptions();
        this.teamValue = [];
      } else if (val == "团队") {
        this.OrganizinList();
        this.diffTypeVal = "";
      }
    },
    /**
     * 我的文件+共享文件大于100不可添加
     * 加提示框
     */
    createFileBtn() {
      this.createOrEditFile = "创建文件夹";
      this.fileList.name = "";
      this.fileList.id = "";
      this.fileList.version = 0;
      this.fileList.shares = [];
      if (this.shareData.length + this.myFileData.length > 100) {
        this.overFilesDialog = true;
      } else {
        this.createFile = true;
        this.isShowBtn = true;
      }
    },
    // 同事
    userOptions() {
      userOptions().then((res) => {
        this.diffShareData = res.result;
      });
    },
    // 新增，编辑
    addOrEditFavorite(formName) {
      this.saveloading = true;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.fileList.type = this.type;
          // return
          addOrEditFavorite(this.fileList).then((res) => {
            if (res.success) {
              this.createFile = false;
              this.getListFavorite();
            }
            this.saveloading = false;
          });
        }
      });
    },

    // 组织框架
    OrganizinList() {
      permissionTree({ type: "团队" }).then((res) => {
        this.teamValueOptions = res.result;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.shareRow {
  position: relative;
  top: -32px;
  margin-bottom: 10px;
}
.custom-tree-node {
  /*flex: 1 2 100px;*/
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
.operationEditOrDel {
  .miniBtn {
    // border: 1px solid #999;
    // border-radius: 4px;
    // color: #999;
    // &:hover {
    //   color: #e60012;
    //   border-color: #e60012;
    // }
  }
  .el-icon-plus:hover {
    color: #526ecc;
  }
  .el-icon-close {
    // margin-left: 10px;
    // color: #999;
    // font-size: 20px;
    // vertical-align: middle;
    &:hover {
      color: #e60012;
    }
  }
}
</style>
<style lang="scss">
@import "src/common/css/file.less";
</style>
