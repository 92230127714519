<template>
  <section>
    <div class="drawerCon_head">
      <p
        class="titname"
        v-if="contractInfo.contract"
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
        "
      >
        <span style="max-width: 650px">
          <span class="name"> {{ contractInfo.contract.name }}</span>
          <span class="comStatus" v-if="contractInfo.contract.status">{{
            contractInfo.contract.status
          }}</span>
        </span>
        <span class="subInfo">
          审批编号：<span>{{ contractInfo.contract.id }}</span
          ><el-divider direction="vertical"></el-divider> 合同编号：<span>{{
            contractInfo.contract.number ? contractInfo.contract.number : "-"
          }}</span
          ><el-divider direction="vertical"></el-divider> 创建人：<span>{{
            contractInfo.createdUserName
          }}</span
          ><el-divider direction="vertical"></el-divider>创建时间：<span>{{
            contractInfo.contract.createdTime
          }}</span>
        </span>
      </p>
    </div>
    <div class="box-title" style="padding-bottom: 60px">
      <div class="conInfo">
        <el-container v-if="contractInfo">
          <el-aside width="75%">
            <p class="conTit">
              <span class="conIntoTitle">基础信息</span>
            </p>
            <el-row style="padding: 0 20px; margin: 10px 0">
              <el-form
                label-width="120px"
                label-position="left"
                class="baseStyle"
              >
                <el-col :span="22">
                  <el-form-item label="客户公司名称："
                    ><div class="clearfix" style="width: calc(100% - 180px)">
                      <p
                        style="margin-top: 0; margin-right: 10px"
                        v-for="(i, index) in contractInfo.companyName"
                        :key="index"
                      >
                        {{ i }}
                      </p>
                    </div></el-form-item
                  >
                </el-col>
                <el-col :span="22">
                  <el-form-item label="签约公司：">{{
                    contractInfo.signCompanyName
                  }}</el-form-item>
                </el-col>
                <el-col :span="22">
                  <el-form-item label="合同有效期："
                    >{{
                      contractInfo.contract.startDate
                        ? contractInfo.contract.startDate.replace(
                            RegExp("/", "g"),
                            "-"
                          )
                        : ""
                    }}
                    至
                    {{
                      contractInfo.contract.endDate
                        ? contractInfo.contract.endDate.replace(
                            RegExp("/", "g"),
                            "-"
                          )
                        : "无期限"
                    }}</el-form-item
                  >
                </el-col>
                <el-col :span="22">
                  <el-form-item label="保证期：">{{
                    contractInfo.contract.guaranteePeriod
                  }}</el-form-item>
                </el-col>
                <el-col :span="22">
                  <el-form-item label="付款周期：">{{
                    contractInfo.contract.paymentCycle
                  }}</el-form-item>
                </el-col>
                <el-col :span="22">
                  <el-form-item label="收费标准：">{{
                    contractInfo.contract.feeStandard
                  }}</el-form-item>
                </el-col>
                <el-col :span="22">
                  <el-form-item label="合同附件：">
                    <p
                      v-for="(item, index) in contractInfo.fileList"
                      :key="index"
                      class="textd"
                      style="margin-right: 20px; cursor: pointer"
                    >
                      <a style="color: #2b80ff; font-style: normal">
                        <span @click="previewOpen(item)">{{
                          item.fileName
                        }}</span>
                        <i
                          @click="downFile(item)"
                          class="el-icon-download"
                          style="margin-left: 15px"
                        ></i>
                      </a>
                      <i
                        class="iconfont icondaochubiaoge"
                        style="
                          font-size: 12px;
                          font-weight: bold;
                          color: #bdbdbd;
                        "
                      ></i>
                    </p>
                  </el-form-item>
                </el-col>
                <el-col :span="22">
                  <el-form-item label="备注："
                    ><span
                      style="
                        word-wrap: break-word;
                        word-break: normal;
                        line-height: 1.5;
                      "
                    >
                      {{
                        contractInfo.contract.remark
                          ? contractInfo.contract.remark
                          : "-"
                      }}
                    </span></el-form-item
                  >
                </el-col>
                <el-col :span="22">
                  <el-form-item label="先章方：">{{
                    contractInfo.contract.firstSeal
                      ? contractInfo.contract.firstSeal
                      : "-"
                  }}</el-form-item>
                </el-col>
                <el-col :span="22">
                  <el-form-item label="邮寄：">{{
                    contractInfo.contract.sendMail ? "需要" : "不需要"
                  }}</el-form-item>
                </el-col>
              </el-form>
            </el-row>
            <p class="conTit">
              <span class="conIntoTitle"> 日常备注</span>
            </p>
            <el-row style="padding: 0 20px; margin: 10px 0 20px">
              <el-form
                label-width="120px"
                label-position="left"
                class="baseStyle"
              >
                <el-col :span="22">
                  <el-form-item label="日常备注：" style="margin-bottom: 20px"
                    ><div
                      class="clearfix"
                      style="
                        width: calc(100% - 180px);
                        display: flex;
                        align-items: center;
                      "
                    >
                      <el-input
                        v-model.trim="comment"
                        placeholder="请输入"
                        maxlength="500"
                        :rows="4"
                        type="textarea"
                        id="contractsInfoComment"
                      ></el-input>
                      <p
                        class="tabHref"
                        @click="handleComment"
                        style="margin-left: 15px; width: 50px"
                        id="contractsInfoCommentAdd"
                      >
                        添加
                      </p>
                    </div></el-form-item
                  >
                </el-col>
                <el-col :span="22">
                  <el-form-item label="备注记录："
                    ><div class="clearfix" style="width: calc(100% - 180px)">
                      <p v-if="commentList.length == 0">-</p>
                      <el-timeline v-else>
                        <el-timeline-item
                          v-for="(activity, index) in commentList"
                          :key="index"
                          :timestamp="activity.createdTime"
                          style="
                            font-size: 13px;
                            line-height: 20px;
                            color: #606269;
                          "
                        >
                          {{ activity.creatorName }} {{ activity.content }}
                        </el-timeline-item>
                      </el-timeline>
                    </div></el-form-item
                  >
                </el-col>
              </el-form>
            </el-row>
            <p class="conTit conIntoTitle" v-if="contractInfo.contract.number">
              法务信息
            </p>
            <el-form
              @submit.native.prevent
              class="lawFoo"
              v-if="contractInfo.contract.number"
              label-position="left"
            >
              <el-row :gutter="24" style="margin: 20px">
                <el-col :span="12" style="padding: 0">
                  <el-form-item label="合同编号：" label-width="120px">{{
                    contractInfo.contract.number
                  }}</el-form-item>
                </el-col>
                <el-col :span="23" style="padding: 0">
                  <el-form-item label="合同附件(终)：" label-width="120px">
                    <el-col :span="22">
                      <span
                        v-for="(item, index) in contractInfo.imgList"
                        :key="index"
                        style="
                          margin-right: 20px;
                          margin-bottom: 10px;
                          cursor: pointer;
                        "
                      >
                        <i
                          @click="previewOpen(item)"
                          class="iHover"
                          style="color: #2b80ff; font-style: normal"
                          >{{ item.fileName }}</i
                        >
                        <i
                          @click="downFile(item)"
                          class="el-icon-download"
                          style="margin-left: 15px"
                        ></i>
                        <i
                          class="iconfont icondaochubiaoge"
                          style="
                            font-size: 12px;
                            font-weight: bold;
                            color: #bdbdbd;
                          "
                        ></i>
                      </span>
                    </el-col>
                    <el-col :span="2">
                      <el-button
                        v-if="
                          isFawu &&
                          contractInfo.imgList &&
                          contractInfo.imgList.length > 0
                        "
                        icon="el-icon-edit"
                        type="text"
                        style="color: #2b80ff; float: right; padding: 8px 0 0 0"
                        @click="handleContractEdit"
                      ></el-button>
                    </el-col>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-aside>
          <el-main
            v-if="contractInfo.approvalInfo.length > 0"
            class="conRecord"
          >
            <el-card
              shadow="never"
              style="margin-bottom: 8px"
              v-for="(i, index) in contractInfo.approvalInfo"
              :key="index"
            >
              <div class="recordTit" style="display: flex; align-items: center">
                <p>{{ i.approvalStatus }}</p>
                <div
                  v-if="index == 0"
                  style="margin-left: 10px; display: flex"
                  v-show="
                    contractInfo.canCancel ||
                    contractInfo.canPass ||
                    contractInfo.canReject ||
                    contractInfo.canEdit ||
                    contractInfo.canChange ||
                    contractInfo.canAddDate ||
                    contractInfo.canUpload ||
                    contractInfo.canSign
                  "
                >
                  <div>
                    <el-button
                      size="mini"
                      type="primary"
                      plain
                      v-if="contractInfo.canCancel"
                      @click="handleCancel(contractInfo.contractApprovalItemId)"
                      id="contractApprovalCanCancel"
                      >取消审批</el-button
                    >
                    <el-button
                      size="mini"
                      type="primary"
                      plain
                      v-if="contractInfo.canPass"
                      @click="handlePass(contractInfo.contractApprovalItemId)"
                      id="contractApprovalCanPass"
                      >通过</el-button
                    >
                    <el-button
                      size="mini"
                      type="primary"
                      plain
                      v-if="contractInfo.canReject"
                      @click="handleReject(contractInfo.contractApprovalItemId)"
                      id="contractApprovalCanReject"
                      >驳回</el-button
                    >
                  </div>
                  <el-button
                    size="mini"
                    type="primary"
                    plain
                    v-if="contractInfo.canEdit"
                    @click="
                      handleEditCheck(
                        contractInfo.contract.id,
                        contractInfo.contract
                      )
                    "
                    id="contractApprovalCanEdit"
                    >调整</el-button
                  >
                  <div style="margin: 0 4px">
                    <el-button
                      size="mini"
                      type="primary"
                      plain
                      v-if="contractInfo.canChange"
                      @click="handleCanChange(contractInfo.contract.id)"
                      id="contractApprovalChange"
                      >变更</el-button
                    >
                    <el-button
                      size="mini"
                      type="primary"
                      plain
                      v-if="contractInfo.canAddDate"
                      @click="handleAddDate(contractInfo.contract.id)"
                      id="contractApprovalCanAddDate"
                      >续约</el-button
                    >
                    <el-button
                      size="mini"
                      type="primary"
                      plain
                      v-if="contractInfo.canUpload"
                      @click="handleUpload(contractInfo.contract.id)"
                      id="contractApprovalCanUpload"
                      >归档</el-button
                    >
                    <el-button
                      size="mini"
                      type="primary"
                      plain
                      v-if="contractInfo.canSign"
                      @click="handleStamp(contractInfo.contractApprovalItemId)"
                      id="contractApprovalCanSign"
                      >盖章</el-button
                    >
                  </div>
                </div>
              </div>
              <el-timeline class="recordList">
                <el-timeline-item
                  v-for="(j, index) in i.contractApprovalResultStringList"
                  :key="index"
                  :color="colorFilter(j.colorStatus, index)"
                >
                  <span>{{ j.approvalTime }}</span>
                  <div class="result">
                    <p v-if="j.colorStatus == 1" style="color: #333">
                      {{ j.result }}
                    </p>
                    <p v-else>{{ j.result }}</p>
                    <template
                      v-if="
                        j.id &&
                        j.result.split('').slice(-4, -1).join('') == '已盖章'
                      "
                    >
                      <p style="word-wrap: break-word; word-break: normal">
                        备注：{{ j.mark ? j.mark : "-" }}
                        <i
                          v-if="isEditIcon"
                          class="iconfont iconbianji-rxq"
                          @click="
                            (StampdialogVisible = true),
                              isEditStamp(j.mark, j.id)
                          "
                        ></i>
                      </p>
                    </template>
                  </div>
                  <p
                    style="
                      color: #ff3f3f;
                      word-break: break-all;
                      word-wrap: break-word;
                      font-size: 13px;
                      margin: 8px 0;
                    "
                    v-if="
                      j.suggestion &&
                      j.result.split('').slice(-4, -1).join('') != '已盖章'
                    "
                  >
                    建议：{{ j.suggestion }}
                  </p>
                  <p
                    style="
                      color: #ff3f3f;
                      word-break: break-all;
                      word-wrap: break-word;
                      font-size: 13px;
                      margin: 8px 0;
                    "
                    v-if="j.contractNumber"
                  >
                    合同编号：{{ j.contractNumber }}
                  </p>
                  <p
                    style="
                      color: #ff3f3f;
                      word-break: break-all;
                      word-wrap: break-word;
                      font-size: 13px;
                      margin: 8px 0;
                    "
                    v-if="
                      j.remark &&
                      j.result.split('').slice(-4, -1).join('') != '已退回'
                    "
                  >
                    <span style="color: #606266; font-size: 13px"
                      >驳回理由：</span
                    >{{ j.remark }}
                  </p>
                  <p
                    style="
                      color: #ff3f3f;
                      word-break: break-all;
                      word-wrap: break-word;
                      font-size: 13px;
                      margin: 8px 0;
                    "
                    v-if="
                      j.remark &&
                      j.result.split('').slice(-4, -1).join('') == '已退回'
                    "
                  >
                    退回理由：{{ j.remark }}
                  </p>
                  <p
                    style="
                      color: #606266;
                      font-size: 13px !important;
                      margin: 8px 0;
                    "
                    v-if="j.files"
                  >
                    附件：
                  </p>
                  <p v-if="j.files" style="margin: 8px 0">
                    <span
                      v-for="(item, index) in j.files"
                      :key="index"
                      style="display: block; font-size: 12px; cursor: pointer"
                    >
                      <i
                        @click="previewOpen(item)"
                        class="iHover"
                        style="color: #2b80ff; font-style: normal"
                        >{{ item.fileName }}
                      </i>
                      <i
                        @click="downFile(item)"
                        v-if="item"
                        class="el-icon-download"
                        style="margin: 0 15px; color: #2b80ff"
                      ></i>
                    </span>
                  </p>
                  <p v-if="j.needLowInfo" style="margin: 8px 0">
                    合同附件(终)：
                  </p>
                  <p v-if="j.needLowInfo" style="margin: 8px 0">
                    <span
                      v-for="(item, index) in contractInfo.imgList"
                      :key="index"
                      style="display: block; font-size: 12px; cursor: pointer"
                    >
                      <i
                        @click="previewOpen(item)"
                        class="iHover"
                        style="color: #2b80ff; font-style: normal"
                        >{{ item.fileName }}</i
                      >
                      <i
                        @click="downFile(item)"
                        v-if="item"
                        class="el-icon-download"
                        style="margin: 0 15px"
                      ></i>
                      <i
                        class="iconfont icondaochubiaoge"
                        style="
                          font-size: 12px;
                          font-weight: bold;
                          color: #bdbdbd;
                        "
                      ></i>
                    </span>
                  </p>
                </el-timeline-item>
              </el-timeline>
            </el-card>
          </el-main>
        </el-container>

        <el-drawer
          :title="innerTitName"
          :visible.sync="drawerdialogVisible"
          :modal-append-to-body="false"
          :close-on-click-modal="false"
          append-to-body
          :modal="false"
          direction="ttb"
          custom-class="drawerClass"
          :before-close="handleClose"
        >
          <div class="drawerCon">
            <!-- 修改信息 -->
            <addInfo
              ref="saveInfo"
              v-if="iseditInfo"
              :contractId="contractId"
              :status="status"
              @changeSaveLoading="changeSaveLoading"
              @loadList="loadList"
            ></addInfo>
            <div class="drawerCon_footer fixedFoot">
              <el-button
                type="default"
                plain
                size="mini"
                @click="(drawerdialogVisible = false), cancel()"
                id="editCancel"
                >取消</el-button
              >
              <el-button
                type="primary"
                size="mini"
                :loading="saveloading"
                @click="saveInfos"
                id="editSave"
                >保存</el-button
              >
            </div>
          </div>
        </el-drawer>
        <el-dialog
          :width="innerWidth"
          :title="innerTitName"
          :visible.sync="innerVisible"
          append-to-body
          :modal-append-to-body="false"
          :close-on-click-modal="false"
        >
          <el-form
            @submit.native.prevent
            :model="createFWinfo"
            v-if="isAddFWinfo"
            ref="createFWinfo"
            label-width="120px"
            style="margin: 0 20px"
          >
            <el-row :gutter="24">
              <el-col :span="24">
                <el-form-item label="合同附件(终)：" class="is-required">
                  <el-upload
                    ref="upload"
                    :headers="myHeaders"
                    :action="actionUrl"
                    :before-upload="beforeUpload"
                    :on-success="handleInfoSuccess"
                    :on-error="handleInfoError"
                    :file-list="fileInfoList"
                    :on-remove="handleRemove"
                    accept=".pdf, .doc, .docx, .jpg, .png, .bmp"
                    id="contractApprovalCanUploadUpload"
                  >
                    <el-button size="small">点击上传</el-button>
                    <div
                      slot="tip"
                      class="el-upload__tip"
                      style="margin: 0px; line-height: 30px"
                    >
                      支持.pdf .doc .docx .jpg .png
                      .bmp文件，可上传多份附件，单份不超过20M
                    </div>
                  </el-upload>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button
              type="default"
              plain
              size="mini"
              @click="cancel()"
              id="contractApprovalCanUploadCancel"
              >取消</el-button
            >
            <el-button
              type="primary"
              size="mini"
              :loading="saveloading"
              @click="save()"
              id="contractApprovalCanUploadSave"
              >保存</el-button
            >
          </span>
        </el-dialog>
        <!-- 法务驳回 -->
        <el-dialog
          :title="titleName"
          width="600px"
          :visible.sync="isLowPassVisibel"
          :close-on-click-modal="false"
          append-to-body
          @closed="
            (rejectForm.files = []),
              (rejectForm.remark = ''),
              (fileInfoList = []),
              (passForm.files = []),
              (passForm.suggestion = ''),
              (rejectVisible = false),
              (passVisible = false)
          "
        >
          <el-form
            v-if="rejectVisible"
            ref="rejectForm"
            :model="rejectForm"
            :rules="rules"
            label-width="100px"
            style="margin-right: 20px"
          >
            <el-form-item label="驳回理由：" prop="remark">
              <el-input
                type="textarea"
                v-model.trim="rejectForm.remark"
                placeholder="请输入驳回理由"
                id="contractApprovalPassFormRejectRemark"
              ></el-input>
            </el-form-item>
            <el-form-item label="附件：">
              <el-upload
                ref="upload"
                :headers="myHeaders"
                :action="actionUrl"
                :before-upload="beforeUpload"
                :on-success="handleRejectInfoSuccess"
                :on-error="handleInfoError"
                :file-list="fileInfoList"
                :on-remove="handleRemoveOfReject"
                accept=" .doc, .docx, .jpg, .png"
              >
                <el-button size="small">点击上传</el-button>
                <div
                  slot="tip"
                  class="el-upload__tip"
                  style="margin: 0; line-height: 30px"
                >
                  支持.pdf .doc .docx .jpg .png
                  .bmp文件，可上传多份附件，单份不超过20M
                </div>
              </el-upload>
            </el-form-item>
          </el-form>
          <el-form
            v-if="passVisible"
            ref="passForm"
            :model="passForm"
            label-width="80px"
            style="margin-right: 20px"
          >
            <p
              style="
                font-size: 16px;
                font-weight: bold;
                margin-bottom: 20px;
                margin-left: 32px;
              "
            >
              请确认是否通过审批？
            </p>
            <el-form-item label="建议：" prop="suggestion">
              <el-input
                type="textarea"
                v-model="passForm.suggestion"
                placeholder="请输入建议"
              ></el-input>
            </el-form-item>
            <el-form-item label="附件：">
              <el-upload
                ref="upload"
                :headers="myHeaders"
                :action="actionUrl"
                :before-upload="beforeUpload"
                :on-success="handlePassInfoSuccess"
                :on-error="handleInfoError"
                :file-list="fileInfoList"
                :on-remove="handleRemoveOfPass"
                accept=".doc, .docx, .jpg, .png"
              >
                <el-button size="small">点击上传</el-button>
                <div
                  slot="tip"
                  class="el-upload__tip"
                  style="margin: 0; line-height: 30px"
                >
                  支持.pdf .doc .docx .jpg .png
                  .bmp文件，可上传多份附件，单份不超过20M
                </div>
              </el-upload>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button
              size="mini"
              @click="
                (isLowPassVisibel = false),
                  (rejectForm.files = []),
                  (rejectForm.remark = ''),
                  (fileInfoList = []),
                  (passForm.files = []),
                  (passForm.suggestion = ''),
                  (rejectVisible = false),
                  (passVisible = false)
              "
              id="contractApprovalPassFormCencel"
              >取消</el-button
            >
            <el-button
              v-if="rejectVisible"
              type="primary"
              size="mini"
              @click="
                saveRejectForm(contractInfo.contractApprovalItemId, 'reject')
              "
              id="contractApprovalPassFormRejectSave"
              >保存</el-button
            >
            <el-button
              v-if="passVisible"
              type="primary"
              size="mini"
              @click="
                saveRejectForm(contractInfo.contractApprovalItemId, 'pass')
              "
              id="contractApprovalPassFormPassSave"
              >保存</el-button
            >
          </span>
        </el-dialog>
        <el-dialog
          width="40%"
          title="编辑合同附件"
          :visible.sync="editContractVisible"
          append-to-body
          :modal-append-to-body="false"
          :close-on-click-modal="false"
        >
          <el-form
            :model="contractAccessory"
            ref="contractAccessory"
            v-if="editContractVisible"
          >
            <el-form-item prop="imgList">
              <el-upload
                class="upload-demo uploadContract"
                drag
                :headers="myHeaders"
                :action="actionUrl"
                :before-upload="beforeUpload"
                :on-success="handleContractInfoSuccess"
                :on-error="handleInfoError"
                :file-list="contractFileInfoList"
                :on-remove="handleContractRemove"
                accept=".pdf,.doc,.docx,.jpg,.png,.bmp"
              >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                  将文件拖到此处，或
                  <span class="tabHref">点击上传</span>
                  <div
                    slot="tip"
                    style="
                      margin-top: -5px;
                      font-size: 12px;
                      line-height: 1.5;
                      color: #606266;
                    "
                  >
                    支持.pdf .doc .docx .jpg .png
                    .bmp文件，可上传多份附件，单份不超过20M
                  </div>
                </div>
              </el-upload>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button
              type="default"
              plain
              size="mini"
              @click="editContractVisible = false"
              >取消</el-button
            >
            <el-button
              type="primary"
              size="mini"
              :loading="saveloading"
              @click="saveContract()"
              >保存</el-button
            >
          </span>
        </el-dialog>
        <!--        是否盖章-->
        <el-dialog
          title="提示"
          :visible.sync="isStampdialogVisible"
          width="600px"
          append-to-body
          :close-on-click-modal="false"
        >
          <div style="margin: 0 40px">
            <p style="font-size: 16px; font-weight: bold; margin-bottom: 20px">
              请确定是否盖章？
            </p>
            <div style="display: flex; align-items: center">
              <p style="width: 60px">备注：</p>
              <el-input v-model="remark"></el-input>
            </div>
          </div>

          <div slot="footer">
            <el-button
              size="mini"
              @click="isStampdialogVisible = false"
              id="contractApprovalCanSignCancel"
              >取 消</el-button
            >
            <el-button
              type="primary"
              size="mini"
              @click="saveStamp(contractInfo.contractApprovalItemId)"
              id="contractApprovalCanSignSave"
              >确 定</el-button
            >
          </div>
        </el-dialog>
        <!--        已盖章-->
        <el-dialog
          title="编辑"
          :visible.sync="StampdialogVisible"
          width="30%"
          append-to-body
          :close-on-click-modal="false"
        >
          <span>备注：</span
          ><el-input style="width: 80%" v-model="remarkTrue"></el-input>
          <div slot="footer">
            <el-button size="mini" @click="StampdialogVisible = false"
              >取 消</el-button
            >
            <el-button size="mini" type="primary" @click="stampTrue"
              >确 定</el-button
            >
          </div>
        </el-dialog>
      </div>
    </div>
  </section>
</template>

<script>
import {
  GetContractManaInfo, //获取详情
  editMark,
  cancelContractApproval,
  passContractApproval,
  isLawApproval, //判断是否为法务
  uploadContractAndApproval, //上传合同
  rejectContractAndApproval,
  signContractAndApproval, // 盖章
  editContractFile, // 编辑合同附件
  companyContractAddDailyComment, //增加一条合同日常备注
  companyContractDailyComment, //合同关联的日常备注列表
} from "../../api/api";
import addInfo from "./addContract.vue";
import { ApiBaseUrl, PreviewUrl } from "@/api/http";
import Cookies from "js-cookie";
export default {
  name: "contractInfo",
  components: { addInfo },
  props: ["contractId", "activeName"],
  data() {
    return {
      editContractVisible: false,
      drawerdialogVisible: false,
      isStampdialogVisible: false,
      StampdialogVisible: false,
      contractFileInfoList: [],
      contractAccessory: {
        imgList: [],
      },
      isFawu: JSON.parse(this.getStore("roles"))[0] == "法务",
      titleName: "",
      remark: "",
      remarkTrue: "",
      contractOption: {
        contract: {
          number: "",
        },
      },
      actionUrl: ApiBaseUrl + "/api/file/uploadOne",
      myHeaders: {
        Authorization: Cookies.get("accessToken"),
      },
      baseUrl: ApiBaseUrl,
      contractInfo: "",
      isEditStampId: "",
      iseditInfo: false,
      isEditIcon: false,
      innerVisible: false,
      isLowPassVisibel: false,
      passVisible: false,
      rejectVisible: false,
      saveloading: false,
      isAddFWinfo: false,
      innerWidth: "",
      innerTitName: "",
      fileInfoList: [],
      status: "",
      createFWinfo: {
        contractApprovalItemId: "",
        number: "",
        imgList: [],
        isLowPass: false,
      },
      judgeLowPass: false,
      rejectForm: {
        contractApprovalItemId: "",
        remark: "",
        files: [],
      },
      passForm: {
        suggestion: "",
        files: [],
        contractApprovalItemId: "",
        isLowPass: false,
        number: "",
      },
      rules: {
        remark: { required: true, message: "请输入驳回理由", trigger: "blur" },
      },
      commentList: [],
      comment: "",
    };
  },
  methods: {
    changeSaveLoading(val) {
      this.saveloading = val;
    },
    // 编辑合同附件
    handleContractEdit() {
      this.contractFileInfoList = [];
      if (this.contractInfo.imgList && this.contractInfo.imgList.length > 0) {
        this.contractInfo.imgList.forEach((item) => {
          this.contractFileInfoList.push({
            name: item.fileName,
            url: item.filePath,
          });
        });
      }
      this.editContractVisible = true;
    },
    // 取消
    cancelContract() {
      this.contractFileInfoList = [];
    },
    // 确定
    saveContract() {
      if (this.contractFileInfoList.length == 0) {
        this.$message.error("至少有一份合同附件");
        return false;
      }
      editContractFile({
        contractId: this.contractInfo.contract.id,
        imgList: this.contractAccessory.imgList,
      }).then((res) => {
        if (res.success) {
          this.$message.success(res.message);
          this.editContractVisible = false;
          this.loadDetails(this.contractId, this.contractOption);
        }
      });
    },
    handleContractInfoSuccess(res, file, fileList) {
      if (res.success) {
        this.contractAccessory.imgList = [];
        this.contractFileInfoList = [];
        fileList.forEach((item) => {
          this.contractAccessory.imgList.push({
            fileName: item.name,
            filePath: item.response ? item.response.result : item.url,
          });
          this.contractFileInfoList.push(item);
        });
      }
    },
    handleContractRemove(file, fileList) {
      this.contractAccessory.imgList = [];
      this.contractFileInfoList = [];
      fileList.forEach((item) => {
        this.contractAccessory.imgList.push({
          fileName: item.name,
          filePath: item.response ? item.response.result : item.url,
        });
        this.contractFileInfoList.push(item);
      });
    },
    colorFilter(val, index) {
      if (val == 1) {
        return "#e60012";
        // return "#4ac893";
      } else if (val == 0) {
        return "#e4e7ed";
        // return "#2b80ff";
      } else if (val == 3) {
        return "#e4e7ed";
        // return "#999999";
      } else {
        return;
      }
    },
    handleInfoSuccess(res, file, fileList) {
      if (res.success) {
        this.createFWinfo.imgList = [];
        fileList.forEach((item) => {
          this.createFWinfo.imgList.push({
            fileName: item.name,
            filePath: item.response.result,
          });
        });
      }
    },
    handleRejectInfoSuccess(res, file, fileList) {
      if (res.success) {
        this.rejectForm.files = [];
        fileList.forEach((item) => {
          this.rejectForm.files.push({
            fileName: item.name,
            filePath: item.response.result,
          });
        });
      }
    },
    handlePassInfoSuccess(res, file, fileList) {
      if (res.success) {
        this.passForm.files = [];
        fileList.forEach((item) => {
          this.passForm.files.push({
            fileName: item.name,
            filePath: item.response.result,
          });
        });
      }
    },
    handleInfoError(err) {
      this.$message.error("文件上传出错");
    },
    beforeUpload: function (file) {
      const isLt = file.size / 1024 / 1024 < 20;
      if (!isLt) {
        this.$message.error("上传文件大小不能超过 20MB!");
      }

      return isLt;
    },
    handleRemove(file, fileList) {
      this.createFWinfo.imgList = [];
      fileList.forEach((item) => {
        this.createFWinfo.imgList.push({
          fileName: item.name,
          filePath: item.response.result,
        });
      });
    },
    handleRemoveOfReject(file, fileList) {
      this.rejectForm.files = [];
      fileList.forEach((item) => {
        this.rejectForm.files.push({
          fileName: item.name,
          filePath: item.response.result,
        });
      });
    },
    handleRemoveOfPass(file, fileList) {
      this.passForm.files = [];
      fileList.forEach((item) => {
        this.passForm.files.push({
          fileName: item.name,
          filePath: item.response.result,
        });
      });
    },
    cancel() {
      this.innerVisible = false;
      this.iseditInfo = false;
      this.isAddFWinfo = false;
      this.fileInfoList = [];
    },
    downFile(item) {
      location.href =
        this.baseUrl +
        "/api/file/download?fileName=" +
        encodeURIComponent(item.fileName) +
        "&filePath=" +
        item.filePath;
    },
    //预览
    previewOpen(item) {
      // let item = this.versionVal;
      const fileType =
        item.fileName.split(".")[item.fileName.split(".").length - 1];
      if (
        fileType == "doc" ||
        fileType == "docx" ||
        fileType == "xlsx" ||
        fileType == "pptx" ||
        fileType == "ppt"
      ) {
        //wps预览
        window.open(
          "https://view.officeapps.live.com/op/view.aspx?src=" +
            PreviewUrl +
            item.filePath,
          "_blank"
        );
      } else if (fileType == "pdf") {
        //pdf预览
        window.open(
          "/pdf/web/viewer.html?file=" + PreviewUrl + item.filePath,
          "_blank"
        );
      } else if (fileType == "png" || fileType == "jpg" || fileType == "jpeg") {
        //图片预览
        window.open(PreviewUrl + item.filePath, "_blank");
      } else if (fileType) {
        this.$message.error("不支持预览");
      }
    },
    saveInfos() {
      this.$refs.saveInfo.save("contract");
    },
    save() {
      if (this.isAddFWinfo) {
        this.$refs["createFWinfo"].validate((valid) => {
          if (valid) {
            if (this.createFWinfo.imgList.length == 0) {
              this.$message.error("请先上传附件");
              return false;
            }
            this.createFWinfo.number = this.contractInfo.contract.number;
            this.createFWinfo.contractApprovalItemId =
              this.contractInfo.contractApprovalItemId;
            uploadContractAndApproval({
              contractApprovalItemId: this.createFWinfo.contractApprovalItemId,
              imgList: this.createFWinfo.imgList,
            }).then((res) => {
              if (res.success) {
                this.$message.success(res.message);
                this.innerVisible = false;
                this.resetForm("createFWinfo");
                this.fileInfoList = [];
                this.$emit("loadList");
                // this.$router.push({
                //   path: "/contract/index",
                //   query: { activeName: "0" },
                // });
              }
            });
          }
        });
      }
    },
    loadList() {
      this.drawerdialogVisible = false;
      this.innerVisible = false;
      this.loadDetails(this.contractId, this.option);
      this.$emit("loadList");
      this.$emit("handle");
    },
    loadDetails(rid, option) {
      GetContractManaInfo({ contractId: rid, option: this.activeName }).then(
        (res) => {
          if (res.success) {
            this.contractInfo = res.result;
            this.contractFileInfoList = [];
            this.contractAccessory.imgList = [];
            if (
              this.contractInfo.imgList &&
              this.contractInfo.imgList.length > 0
            ) {
              this.contractInfo.imgList.forEach((item) => {
                this.contractFileInfoList.push({
                  name: item.fileName,
                  url: item.filePath,
                });
                this.contractAccessory.imgList.push({
                  fileName: item.fileName,
                  filePath: item.filePath,
                });
              });
            }
          }
        }
      );
    },
    //取消审批
    handleCancel(id) {
      let that = this;
      that
        .$confirm("请确认是否取消审批？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          customClass: "altCls",
          type: "warning",
        })
        .then(() => {
          cancelContractApproval({ contractApprovalItemId: id }).then((res) => {
            if (res.success) {
              that.$message.success(res.message);
              that.loadList();
              this.$emit("handle");
            }
          });
        })
        .catch(() => {
          that.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    handlePass(id) {
      // 判断操作人是否为法务
      this.titleName = "提示";
      this.passVisible = true;
      this.isLowPassVisibel = true;
    },
    handleReject(id) {
      // 操作人为法务时弹出dialog对话框
      this.titleName = "提示";
      this.isLowPassVisibel = true;
      this.rejectVisible = true;
    },
    saveRejectForm(id, type) {
      // 操作人为法务时的保存
      if (type == "reject") {
        // 驳回
        this.$refs["rejectForm"].validate((valid) => {
          if (valid) {
            this.rejectForm.contractApprovalItemId = id;
            rejectContractAndApproval(this.rejectForm).then((res) => {
              if (res.success) {
                this.$message.success(res.message);
                this.rejectForm.files = [];
                this.rejectForm.remark = "";
                this.fileInfoList = [];
                this.$emit("loadList");
                this.$emit("handle");
                this.$emit("close");
                // this.$router.push({ path: "/contract/approval" });
              }
            });
          }
        });
      } else {
        // 通过
        this.$refs["passForm"].validate((valid) => {
          if (valid) {
            this.passForm.contractApprovalItemId = id;
            this.passForm.isLowPass = this.judgeLowPass;

            passContractApproval(this.passForm).then((res) => {
              if (res.success) {
                this.$message.success(res.message);
                this.passForm.files = [];
                this.passForm.suggestion = "";
                this.fileInfoList = [];
                this.$emit("loadList");
                this.$emit("handle");
                this.$emit("close");
                // this.$router.push({ path: "/contract/approval" });
              }
            });
          }
        });
      }
    },
    isEditStamp(val, id) {
      this.remarkTrue = val;
      this.isEditStampId = id;
    },
    stampTrue() {
      if (this.remarkTrue) {
        editMark({
          contractApprovalItemId: this.isEditStampId,
          imgList: [
            {
              fileName: "",
              filePath: "",
            },
          ],
          mark: this.remarkTrue,
        }).then((res) => {
          if (res.success) {
            this.StampdialogVisible = false;
            this.loadDetails(this.contractId);
          }
        });
      } else {
        this.StampdialogVisible = false;
        // this.$message.error('备注为空，不允许保存！')
      }
    },
    // 盖章
    saveStamp(id) {
      signContractAndApproval({
        contractApprovalItemId: id,
        mark: this.remark,
      }).then((res) => {
        if (res.success) {
          this.isStampdialogVisible = false;
          this.$message.success("操作成功");
          this.$emit("loadList");
          // this.$router.push({ path: "/contract/approval" });
        }
      });
    },
    handleStamp(id) {
      // 法务盖章操作
      this.isStampdialogVisible = true;
    },
    handleInfoChange(files, fileList) {
      this.$message.error("只能上传单个文件");
    },
    handleEditCheck(id, info) {
      this.drawerdialogVisible = true;
      this.iseditInfo = true;
      this.contractId = id;
      this.innerTitName = "编辑合同";
      this.status = 0;
      // if (info.status == "变更_取消" || info.status == "变更_驳回") {
      //   this.status = "变更_审批中";
      // } else if (info.status == "续约_取消" || info.status == "续约_驳回") {
      //   this.status = "续约_审批中";
      // }
    },
    handleCanChange(id) {
      this.drawerdialogVisible = true;
      this.iseditInfo = true;
      this.contractId = id;
      this.innerTitName = "编辑合同";
      this.status = 3;
      // this.status = "变更_审批中";
    },
    handleAddDate(id) {
      this.drawerdialogVisible = true;
      this.iseditInfo = true;
      this.contractId = id;
      this.innerTitName = "编辑合同";
      this.status = 6;
      // this.status = "续约_审批中";
    },
    handleClose(done) {
      done();
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    judgeIsLowPass(e) {
      // 判断操作人是否为法务
      isLawApproval().then((res) => {
        if (res.result) {
          this.judgeLowPass = true;
        }
      });
    },
    handleUpload(id) {
      // 上传合同
      //法务审批
      this.innerVisible = true;
      this.innerWidth = "40%";
      this.innerTitName = "归档";
      this.isAddFWinfo = true;
    },
    // 法务退回
    handleSendBack(id) {
      this.$prompt("退回理由", "退回操作", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPlaceholder: "请输入退回理由",
        // inputPattern: /\S/,
        // inputErrorMessage: "请输入退回理由",
        inputType: "textarea",
        closeOnClickModal: false,
      })
        .then(({ value }) => {
          rejectContractAndApproval({
            contractApprovalItemId: id,
            remark: value,
          }).then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.$emit("loadList");
              // this.$router.push({ path: "/contract/approval" });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    //合同关联的日常备注列表
    getCommentList() {
      companyContractDailyComment({ contractId: this.contractId }).then(
        (res) => {
          if (res.success) {
            this.commentList = res.result;
          }
        }
      );
    },
    //增加一条合同日常备注
    handleComment() {
      if (!this.comment) {
        this.$message.error("请填写日常备注");
        return false;
      }
      companyContractAddDailyComment({
        contractId: this.contractId,
        comment: this.comment,
      }).then((res) => {
        if (res.success) {
          this.comment = "";
          this.getCommentList();
        }
      });
    },
  },
  created() {
    this.loadDetails(this.contractId, this.option);
    this.judgeIsLowPass();
    this.isEditIcon = this.getStore("roles").includes("法务");
    this.getCommentList();
  },
  destroyed() {},
};
</script>
<style lang="scss" scoped>
.drawerCon_head {
  margin-bottom: 15px;
  p {
    margin: 0;
  }
  .titname {
    .name {
      font-size: 16px;
      font-weight: bold;
      color: #333;
    }

    .comStatus {
      font-weight: normal;
      padding: 0 5px;
      height: 20px;
      line-height: 20px;
      border-radius: 2px;
      background-color: #fde9e9;
      color: #f05656;
      display: inline-block;
      margin: 0 8px;
      font-size: 12px;
      vertical-align: initial;
    }
    .icon-kh_wh {
      color: #898989;
      font-weight: normal;
      vertical-align: bottom;
      &:hover {
        color: #f05656;
      }
    }
  }
  .subInfo {
    font-size: 12px;
    font-weight: normal;
    color: #999;
    float: right;
    margin: 0 15px;
    span {
      color: #606266;
    }
  }
}
.iconbianji-rxq {
  font-size: 12px;
  padding-left: 10px;
  cursor: pointer;
  &:hover {
    color: #ff3f3f;
  }
}
.box-height {
  position: relative;
}
.box-title {
  padding: 0;
  //   background-color: #f1f1f1;
}
.conInfo {
  height: 100%;
  .el-aside {
    border: 1px #e5e5e5 solid;
    padding: 0;
    height: 100%;
    background-color: #fff;
    // padding: 0 20px 20px;
    // overflow-y: scroll;
    margin-right: 10px;
    .el-row {
      margin: 20px 0;
    }
    .el-col-22 {
      padding: 0;
    }
    .el-form-item {
      margin-bottom: 0;
    }
    .conTit {
      color: #333;
      font-size: 14px;
      padding: 7px;
      border-bottom: 1px solid #efefef;
      background: #f5f5f5;
    }
    .invoceListdetails {
      padding-inline-start: 0;
      width: 100%;
      margin: 0 auto;
      li {
        border: 1px solid #ededed;
        border-left: 2px solid #777788;
        padding: 25px 20px;
        margin-bottom: 20px;
        /*border-bottom: 1px dotted #ebebeb;*/
        color: rgb(102, 102, 102);
        .tit {
          text-align: center;
          position: relative;
          &::after {
            content: "";
            width: 100px;
            height: 2px;
            background-color: #ff3f3f;
            position: absolute;
            bottom: -10px;
            left: calc(50% - 50px);
          }
        }
        .cont {
          p {
            width: 50%;
            float: left;
            margin: 0;
            padding-bottom: 10px;
            &:nth-child(3n) {
              margin-right: 0;
            }
            span {
              color: #666666;
              display: inline-block;
              text-align: left;
            }
          }
        }
      }
    }
  }
  .el-main {
    height: 100%;
    padding: 0;
    // background-color: #f1f1f1;
    // overflow: scroll;
    border-radius: 0;
    // border: 1px solid #e5e5e5;
    &::-webkit-scrollbar-thumb {
      background-color: #f1f1f1;
      background-clip: padding-box;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
    }
    &:hover::-webkit-scrollbar-thumb {
      background-color: #dddddd;
      /*滚动条鼠标移上去*/
    }
    .el-card__body {
      padding-top: 0;
    }
    .recordTit {
      background: #f5f5f5;
      padding: 7px;
      font-size: 14px;
      color: #333333;
      font-weight: 600;
      //   height: 40px;
      //   line-height: 40px;
      margin-top: 0;
      border-bottom: 1px solid #ebebeb;
    }
    .recordList {
      padding-inline-start: 0;
      padding: 15px 15px 5px;
      span {
        font-size: 12px;
        color: #bbb;
      }
      .result {
        font-size: 13px;
        color: #999;
      }
    }
  }
}
.conFlooter {
  display: flex;
  justify-content: center;
  margin: 20px 0;
  position: fixed;
  border-top: 1px solid #e5e5e5;
  width: calc(100% - 40px);
  background-color: #f0f0f0;
  padding: 11px 0;
  left: 20px;
  bottom: 0;
  .el-button {
    margin: 0 5px;
  }
}
.textd {
  &:hover {
    text-decoration: underline;
  }
}
</style>

<style lang="less">
.conRecord {
  .el-card {
    border: 1px #e5e5e5 solid;
  }
  .el-card__body {
    padding: 0;
  }
  .el-timeline-item {
    padding-bottom: 7px;
  }
}
.iHover {
  overflow-wrap: break-word;
  max-width: 100%;
  &:hover {
    border-bottom: 1px solid rgb(43, 128, 255) !important;
  }
}
.conIntoTitle {
  font-size: 14px;
  color: #333333;
  font-weight: 600;
}
.baseStyle .el-form-item__content,
.lawFoo .el-form-item__content {
  color: #333;
  line-height: 30px;
  font-size: 13px;
}
.baseStyle .el-form-item__label,
.lawFoo .el-form-item__label {
  line-height: 30px;
  font-size: 13px;
}
.promptClass {
  // background: red;
}
</style>
