//文件预览
import {
    ApiBaseUrl,
    getRequest
} from '../api/http';

//获取文件预览地址
export const getPreviewUrl = (id) => {
    return getRequest(`/file/preview/url/${id}`)
}

//下载文件
export const downloadFile = (id) => {
    location.href =
        ApiBaseUrl + `/file/download/${id}`
}

export const previewFile = function(id) {
    getPreviewUrl(id).then((res) => {
       if(res.success){
            window.open(res.result);
       } 
    })
};