 <template>
  <section>
    <div v-if="!iseditD">
      <el-radio v-model="type" label="写邮件">写邮件</el-radio>
      <el-radio v-model="type" label="已推荐，记录推荐信息"
        >已推荐，记录推荐信息</el-radio
      >
    </div>
    <div class="Etitle" v-if="type == '写邮件' && !iseditD">
      <p>写邮件</p>
      <sendEmailFrom
        ref="sendEmailFrom"
        type="给客户：简历推荐"
        :projectId="projectId"
        :resumeId="resumeId"
        :projectResumeId="projectResumeId"
        @loadList="changeLoading"
      ></sendEmailFrom>
    </div>
    <div class="Etitle" v-if="type == '已推荐，记录推荐信息' || iseditD">
      <p>推荐信息</p>
      <el-form
        @submit.native.prevent
        :model="resumeRecommendationForm"
        label-width="100px"
        ref="resumeRecommendationForm"
      >
        <el-form-item
          label="推荐时间："
          :rules="[
            { required: true, message: '请选择推荐时间', trigger: 'change' },
          ]"
          prop="time"
        >
          <el-date-picker
            v-model="resumeRecommendationForm.time"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
            style="width: 95%"
          ></el-date-picker>
        </el-form-item>

        <el-form-item label="推荐报告：" prop="filePath" class="is-required">
          <imgUpload
            @getList="getFileInfoList"
            :fileInfoList="fileInfoList"
            style="width: 95%"
            class="uploadReport"
          ></imgUpload>
        </el-form-item>
      </el-form>
    </div>
  </section>
</template>
<script>
import imgUpload from "./imgUpload";
import sendEmailFrom from "./pipeline/sendEmailFrom";
import {
  addOrEditOperaterecordRecommend,
  getOperaterecordById,
  getResumeProjectPageList,
} from "../api/api";
export default {
  components: {
    imgUpload,
    sendEmailFrom,
  },
  props: [
    "projectResumeId",
    "projectResumeId1",
    "projectResumeId3",
    "projectId",
    "resumeId",
    "iseditD",
  ],
  data() {
    return {
      resumeRecommendationForm: {
        time: new Date().Format("yyyy-MM-dd"),
        fileName: null,
        filePath: null,
        version: 0,
        id: 0,
      },
      saveloading: false,
      childValue: false,
      fileInfoList: [],
      type: "写邮件",
    };
  },
  methods: {
    getFileInfoList: function (fileInfoList) {
      this.fileInfoList = fileInfoList;
    },
    getResumeProjectPageList() {
      return new Promise((resolve) => {
        getResumeProjectPageList({ resumeId: this.$route.query.id }).then(
          (res) => {
            if (res.success) {
              this.operationLogList = res.result;
              this.operationLogList.map((i) => ({
                ...i,
                limitValue: "",
              }));
            }
            resolve();
          }
        );
      });
    },
    // 确定人选推荐
    saveResumeRecommendation: function (formName) {
      if (this.type == "写邮件" && !this.iseditD) {
        this.$refs.sendEmailFrom
          .saveEmail("emailform")
          .then(() => {
            this.saveloading = false;
          })
          .then(() => {
            this.saveloading = false;
          });
      } else {
        return new Promise((resolve, reject) => {
          this.$refs[formName].validate((valid) => {
            if (valid) {
              if (!this.fileInfoList.length) {
                this.$message.error("请上传推荐报告!");
                // reject();
                return;
              }
              this.saveloading = true;
              let queryData = {
                id: this.resumeRecommendationForm.id,
                objectContent: {
                  files: [],
                  recommendTime: this.resumeRecommendationForm.time,
                },
                projectResumeId: this.projectResumeId,
                version: this.resumeRecommendationForm.version,
              };
              for (let i in this.fileInfoList) {
                queryData.objectContent.files.push({
                  fileName: this.fileInfoList[i].name,
                  fileUrl: this.fileInfoList[i].url,
                });
              }
              // return
              addOrEditOperaterecordRecommend(queryData).then((res) => {
                this.saveloading = false;
                if (res.success) {
                  this.$message.success("操作成功");
                  this.resetForm(formName);
                  this.$emit("loadList");
                }
                resolve();
              });
            } else {
              // reject();
              this.changeLoading("isNoloading");
              return false;
            }
          });
        });
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.fileInfoList = [];
    },
    loadEditInfo(row) {
      getOperaterecordById({
        id: row.id,
      }).then((res) => {
        if (res.success) {
          this.resumeRecommendationForm.time =
            res.result.objectContent.recommendTime;
          this.resumeRecommendationForm.fileName =
            res.result.objectContent.files[0].fileName;
          this.resumeRecommendationForm.filePath =
            res.result.objectContent.files[0].fileUrl;
          for (let i in res.result.objectContent.files) {
            this.fileInfoList.push({
              name: res.result.objectContent.files[i].fileName,
              url: res.result.objectContent.files[i].fileUrl,
            });
          }
          this.resumeRecommendationForm.id = res.result.id;
          this.resumeRecommendationForm.version = res.result.version;
        } else {
          this.$emit("childByValue", this.childValue);
        }
      });
    },
    changeLoading(val) {
      this.saveloading = false;
      this.$emit("emailDrawerClose", val);
    },
  },
};
</script>
<style lang="less">
.uploadReport {
  .el-upload.el-upload--text {
    width: 100%;
    .el-upload-dragger {
      width: 100%;
    }
  }
}
</style>
<style lang="scss" scoped>
</style>