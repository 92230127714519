 <template>
  <section class="happyNews">
    <el-form
      @submit.native.prevent
      :model="happyNewsForm"
      ref="happyNewsForm"
      :rules="happyNewsFormRule"
      label-width="120px"
    >
      <div class="searchList" v-if="inpath !== 'happyNewsApproval'">
        <el-collapse
          accordion
          v-model="activeName"
          class="scoreTitle"
          style="border-bottom: 0 !important"
        >
          <el-collapse-item name="score">
            <template slot="title">
              <div class="scoreTotal">
                Offer薪资<span>（将自动生成在候选人简历里）</span>
              </div>
            </template>
            <div style="margin: 20px">
              <el-table
                :data="happyNewsForm.offerSalaries"
                border
                stripe
                style="width: 100%"
                align="center"
              >
                <el-table-column
                  prop="structure"
                  label="薪资结构"
                  align="center"
                >
                  <template slot-scope="scope">
                    <el-input
                      v-if="
                        scope.row.structure !== '基本薪资' &&
                        scope.row.structure !== '补贴' &&
                        scope.row.structure !== '股权' &&
                        scope.row.structure !== '固定奖金' &&
                        scope.row.structure !== null
                      "
                      v-model.trim="scope.row.structure"
                    />
                    <el-select
                      v-model="scope.row.structure"
                      placeholder="请选择"
                      v-else
                      style="width: 100%"
                    >
                      <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="amount"
                  label="金额（元）"
                  align="center"
                >
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="'offerSalaries[' + scope.$index + '].amount'"
                      label-width="0"
                      style="margin: 15px 0"
                      :rules="[
                        {
                          required: true,
                          message: '请输入金额，仅限两位小数',
                          trigger: 'blur',
                        },
                        {
                          pattern: /^\d{1,9}(\.\d{1,2})?$/,
                          message: '精确到小数点后两位',
                        },
                      ]"
                    >
                      <el-input
                        type="number"
                        @mousewheel.native.prevent
                        oninput="if(value.length > 9) value=value.slice(0, 9)"
                        v-model="scope.row.amount"
                      />
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column prop="count" label="数量" align="center">
                  <template slot-scope="scope">
                    <el-input
                      type="number"
                      min="0"
                      v-model="scope.row.count"
                      oninput="value=value.replace(/[^\d.]/g,'')"
                    />
                  </template>
                </el-table-column>
                <el-table-column prop="totalAmount" label="总额" align="center">
                  <template slot-scope="scope">
                    <span>{{
                      (
                        Number(scope.row.amount) * Number(scope.row.count)
                      ).toFixed(2)
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="操作" width="180">
                  <template slot-scope="scope">
                    <span class="tabHref" @click="addNewTable">增加</span>
                    <el-divider
                      direction="vertical"
                      v-if="scope.$index !== 0"
                    ></el-divider>
                    <span
                      class="tabHref"
                      v-if="scope.$index !== 0"
                      @click="delTable(scope.$index)"
                      >删除</span
                    >
                  </template>
                </el-table-column>
              </el-table>
              <p>总计：{{ offertotal }}</p>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="heards" style="padding: 0">
        <el-collapse accordion v-model="activeName2" class="scoreTitle">
          <el-collapse-item name="score">
            <template slot="title">
              <div class="scoreTotal">喜报信息</div>
            </template>
            <p class="tips">
              <i class="el-icon-warning"></i
              >提示：已勾选的内容将显示在公示的喜报中，如有疑问请提前与Leader确认
            </p>
            <el-row>
              <el-col :span="8">
                <el-form-item
                  prop="candidateNameItem.name"
                  label="候选人姓名："
                >
                  {{ happyNewsForm.candidateNameItem.name }}
                  <el-checkbox
                    v-model="happyNewsForm.candidateNameItem.isShow"
                    style="margin: 0 10px"
                  ></el-checkbox>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item prop="companyNameItem.name" label="公司简称：">
                  <span
                    v-if="
                      happyNewsForm.companyNameItem.name &&
                      happyNewsForm.companyNameItem.name.length > 10
                    "
                    :title="happyNewsForm.companyNameItem.name"
                    >{{
                      happyNewsForm.companyNameItem.name.substring(0, 10)
                    }}…</span
                  >
                  <span v-else>{{ happyNewsForm.companyNameItem.name }}</span>
                  <el-checkbox
                    v-model="happyNewsForm.companyNameItem.isShow"
                    style="margin: 0 10px"
                  ></el-checkbox>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item prop="positionItem.name" label="职位名称：">
                  <el-input
                    style="width: 80%"
                    v-model="happyNewsForm.positionItem.name"
                    maxlength="20"
                    @blur="handlePositionBlur"
                  ></el-input>
                  <el-checkbox
                    v-model="happyNewsForm.positionItem.isShow"
                    style="margin: 0 10px"
                  ></el-checkbox>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item
                  class="offerStyle"
                  prop="offerTimeItem.name"
                  label="offer时间："
                >
                  <el-date-picker
                    v-model="happyNewsForm.offerTimeItem.name"
                    type="date"
                    placeholder="选择日期"
                    value-format="yyyy-MM-dd"
                    style="width: 80%"
                  ></el-date-picker>
                  <el-checkbox
                    label=""
                    v-model="happyNewsForm.offerTimeItem.isShow"
                    style="margin: 0 10px"
                  ></el-checkbox>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="offer金额：" prop="offerMoneyItem.name">
                  <el-input
                    v-model="happyNewsForm.offerMoneyItem.name"
                    type="number"
                    @mousewheel.native.prevent
                    style="width: 80%"
                  ></el-input>
                  <el-checkbox
                    v-model="happyNewsForm.offerMoneyItem.isShow"
                    style="margin: 0 10px"
                  ></el-checkbox>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item prop="feeStandard" label="收费标准：">
                  <el-input
                    style="width: 80%"
                    v-model="happyNewsForm.feeStandard"
                    maxlength="12"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item
                  prop="entryTimeItem.name"
                  label="预到岗时间："
                  style="margin-bottom: 8px"
                >
                  <el-date-picker
                    v-model="happyNewsForm.entryTimeItem.name"
                    type="date"
                    placeholder="选择日期"
                    value-format="yyyy-MM-dd"
                    style="width: 80%"
                  ></el-date-picker>
                  <el-checkbox
                    v-model="happyNewsForm.entryTimeItem.isShow"
                    style="margin: 0 10px"
                  ></el-checkbox>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  prop="performanceIndustryIds"
                  label="业绩归属行业："
                  style="margin-bottom: 0"
                >
                  <el-cascader
                    style="width: 80%"
                    clearable
                    size="mini"
                    filterable
                    :show-all-levels="false"
                    :options="ColumnInList"
                    collapse-tags
                    v-model="happyNewsForm.performanceIndustryIds"
                    :props="props1"
                  ></el-cascader>
                  <el-tooltip
                    content="将作为【行业业绩】数据统计依据"
                    placement="bottom"
                    effect="dark"
                  >
                    <i class="iconfont icon-wenhao-xiangsu"></i>
                  </el-tooltip>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  prop="moneyItem.name"
                  label="服务费用："
                  style="margin-bottom: 0"
                >
                  <el-input
                    type="number"
                    @mousewheel.native.prevent
                    v-model="happyNewsForm.moneyItem.name"
                    autocomplete="off"
                    placeholder="请输入服务费用金额"
                    style="width: 80%"
                  ></el-input>
                  <el-checkbox
                    v-model="happyNewsForm.moneyItem.isShow"
                    style="margin: 0 10px"
                  ></el-checkbox>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item style="margin-bottom: 0">
                  <el-checkbox disabled v-model="happyNewsForm.addToSchedule"
                    >将预到岗时间添加到日程提醒</el-checkbox
                  >
                </el-form-item>
              </el-col>
            </el-row>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="searchList">
        <el-collapse accordion v-model="activeNames" class="scoreTitle">
          <el-collapse-item name="score">
            <template slot="title">
              <div class="scoreTotal">
                业绩分配<span
                  >（当前分配比例总和：<s>{{ totalPercent }}%</s>）</span
                >
                <span class="tabHref addSearchBtn" @click.stop="add">
                  <i class="el-icon-plus"></i> 添加业绩分配者
                </span>
              </div>
            </template>
            <div style="padding: 10px">
              <el-collapse accordion>
                <el-collapse-item
                  class="titleOfPerformance"
                  v-for="(item, index) in happyNewsForm.performanceAllocations"
                  :key="index"
                >
                  <template slot="title">
                    <div class="liStyle">
                      <span>
                        <el-select
                          placeholder="请选择"
                          v-model="item.id"
                          @change="changeItem($event, index)"
                          size="mini"
                        >
                          <el-option
                            v-for="(i, indexKPI) in JSON.parse(
                              defaultPerformanceAllocations
                            )"
                            :key="'sel' + indexKPI"
                            :label="i.roleName"
                            :value="i.id"
                            :disabled="i.rdisabled"
                          ></el-option>
                        </el-select>
                      </span>
                      <span>
                        <el-select
                          v-model="
                            happyNewsForm.performanceAllocations[index].userId
                          "
                          placeholder="请选择"
                          collapse-tags
                          filterable
                          style="width: 200px; margin-left: 10px"
                          size="mini"
                          clearable
                          @change="checkUser($event, index)"
                        >
                          <el-option
                            v-for="(i, index) in userList"
                            :key="'selUser' + index"
                            :label="i.name"
                            :value="i.id"
                          ></el-option>
                        </el-select>
                      </span>
                      <el-form-item
                        label="比例："
                        style="display: inline-block"
                        label-width="80px"
                        class="is-required"
                      >
                        <div @click.stop style="display: inline-block">
                          <el-input-number
                            v-model="item.percent"
                            :max="100"
                            :min="0"
                            label="比例"
                          ></el-input-number>
                        </div>
                      </el-form-item>
                      <span style="float: right">
                        <i
                          class="el-icon-delete"
                          @click.stop="delPercentList(index)"
                        >
                          删除</i
                        >
                      </span>
                    </div>
                  </template>
                  <div class="contentOfPerformance">
                    <el-form>
                      <el-row>
                        <el-col :span="11" class="canEdit">
                          <el-form-item label="集团：">
                            <span
                              v-for="(_item, idx) in item.allocationItems
                                ? item.allocationItems.groups
                                : []"
                              :key="idx"
                              >{{ _item.departmentName }} -
                              {{ _item.percent }}%<span
                                v-if="
                                  item.allocationItems.groups &&
                                  idx != item.allocationItems.groups.length - 1
                                "
                                >、</span
                              ></span
                            >
                            <i
                              class="iconfont icon-kh_gsxq_bj fr"
                              @click="editOrganization('groups', index)"
                            ></i>
                          </el-form-item>
                        </el-col>
                        <el-col :offset="1" :span="11" class="canEdit">
                          <el-form-item label="地区：">
                            <span
                              v-for="(_item, idx) in item.allocationItems
                                ? item.allocationItems.areas
                                : []"
                              :key="idx"
                              >{{ _item.departmentName }} -
                              {{ _item.percent }}%<span
                                v-if="
                                  item.allocationItems.areas &&
                                  idx != item.allocationItems.areas.length - 1
                                "
                                >、</span
                              ></span
                            >
                            <i
                              class="iconfont icon-kh_gsxq_bj fr"
                              @click="editOrganization('areas', index)"
                            ></i>
                          </el-form-item>
                        </el-col>
                        <el-col :span="11" class="canEdit">
                          <el-form-item label="团队：">
                            <span
                              v-for="(_item, idx) in item.allocationItems
                                ? item.allocationItems.teams
                                : []"
                              :key="idx"
                              >{{ _item.departmentName }} -
                              {{ _item.percent }}%<span
                                v-if="
                                  item.allocationItems.teams &&
                                  idx != item.allocationItems.teams.length - 1
                                "
                                >、</span
                              ></span
                            >
                            <i
                              class="iconfont icon-kh_gsxq_bj fr"
                              @click="editOrganization('teams', index)"
                            ></i>
                          </el-form-item>
                        </el-col>
                        <el-col :offset="1" :span="11" class="canEdit">
                          <el-form-item label="子团队：">
                            <span
                              v-for="(_item, idx) in item.allocationItems
                                ? item.allocationItems.subTeams
                                : []"
                              :key="idx"
                              >{{ _item.departmentName }} -
                              {{ _item.percent }}%<span
                                v-if="
                                  item.allocationItems.subTeams &&
                                  idx !=
                                    item.allocationItems.subTeams.length - 1
                                "
                                >、</span
                              ></span
                            >
                            <i
                              class="iconfont icon-kh_gsxq_bj fr"
                              @click="editOrganization('subTeams', index)"
                            ></i>
                          </el-form-item>
                        </el-col>
                      </el-row>
                    </el-form>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="searchList">
        <el-collapse accordion v-model="activeNames1" class="scoreTitle">
          <el-collapse-item name="score">
            <template slot="title">
              <div class="scoreTotal">
                业绩备注<span>（实习生/Researcher的业绩详情请填写在这里）</span>
                <span class="tabHref addSearchBtn" @click.stop="addR">
                  <i class="el-icon-plus"></i> 添加实习生/Researcher备注
                </span>
              </div>
            </template>
            <div style="padding: 4px 10px">
              <div
                v-for="(item1, index1) in happyNewsForm.performanceOthers"
                :key="index1"
              >
                <div class="liStyle">
                  <span>
                    实习生/Researcher：
                    <el-select
                      placeholder="请选择类型"
                      v-model="item1.type"
                      clearable
                      size="mini"
                    >
                      <el-option
                        v-for="(i, indexKPI) in srType"
                        :key="'sel' + indexKPI"
                        :label="i.name"
                        :value="i.id"
                      ></el-option>
                    </el-select>
                  </span>
                  <span>
                    <el-select
                      v-model="happyNewsForm.performanceOthers[index1].userId"
                      placeholder="请选择"
                      collapse-tags
                      filterable
                      style="width: 200px; margin-left: 10px"
                      size="mini"
                      clearable
                    >
                      <el-option
                        v-for="(i, index) in userList"
                        :key="'selUser' + index"
                        :label="i.name"
                        :value="i.id"
                      ></el-option>
                    </el-select>
                  </span>
                  <el-form-item
                    label="比例："
                    style="display: inline-block"
                    label-width="80px"
                    :class="item1.type == 'Researcher' ? 'is-required' : ''"
                  >
                    <div @click.stop style="display: inline-block">
                      <el-input-number
                        v-model="item1.percent"
                        :max="100"
                        :min="0"
                        label="比例"
                      ></el-input-number>
                    </div>
                  </el-form-item>
                  <span style="margin-left: 10px">
                    团队：
                    <el-select
                      placeholder="请选择团队"
                      v-model="item1.teamId"
                      clearable
                      size="mini"
                    >
                      <el-option
                        v-for="(i, index) in teamsList"
                        :key="'team' + index"
                        :label="i.name"
                        :value="i.id"
                      ></el-option>
                    </el-select>
                  </span>
                  <el-button
                    v-if="index1 > 0"
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click.stop="delResearcherList(index1)"
                    style="margin-left: 20px"
                  ></el-button>
                </div>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="addSearch"></div>
    </el-form>
    <el-dialog
      title="编辑"
      :visible.sync="dialogVisibleOfEdit"
      width="600px"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :append-to-body="true"
      custom-class="editPercent"
    >
      <div style="padding: 0 10px 20px; color: #e60012">
        累计比例：{{ total }}%（非100%不可保存）
        <el-button class="fr" size="mini" type="primary" @click="addPercent()"
          >添加</el-button
        >
      </div>
      <el-form ref="percentForm" :model="percentForm" :rules="ruleForm">
        <el-row
          style="
            padding: 0 30px 0 10px;
            position: relative;
            height: auto;
            margin-bottom: 15px;
          "
          :gutter="20"
          v-for="(i, idx) in currentAllocationItems"
          :key="idx"
        >
          <el-col :span="11" style="height: 40px">
            <el-form-item
              label="名称："
              label-width="60px"
              :prop="'departmentId' + idx"
            >
              <el-select
                v-model="i.departmentId"
                @change="changeDepartment($event, i)"
                clearable
                filterable
              >
                <el-option
                  v-for="item in currentList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11" style="height: 40px">
            <el-form-item
              label="比例%："
              label-width="80px"
              :prop="'percent' + idx"
            >
              <el-input
                v-model.trim.number="i.percent"
                style="width: 100%"
                @mousewheel.native.prevent
              ></el-input>
            </el-form-item>
          </el-col>
          <i class="el-icon-delete iconOfClose" @click="delPercent(idx)">
            删除</i
          >
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="dialogVisibleOfEdit = false"
          >取 消</el-button
        >
        <el-button size="mini" type="primary" @click="saveEditPercent()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </section>
</template>
<script>
import {
  nameAndStatusOptions,
  getCompanySource,
  getGroupsByType, // 获取集团
  getAreasByType, // 获取地区
  getTeamsByType, // 获取团队
  getSubTeamsByType, // 获取子团队
  departAllocations, //获取用户的集团,地区,团队分配数据
  offerGoodnewsDefaultData,
  offerGoodnewsSend,
  offerGoodnewsApproval,
  getCommonColumnList,
  offerGoodnewsApprovalPass,
} from "../api/api";
export default {
  props: ["projectResumeId", "inpath"],
  data() {
    let checkCent = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入金额"));
      }
      setTimeout(() => {
        let reg = /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/;
        if (!reg.test(value)) {
          callback(new Error("请输入数字（小数点后两位）"));
        } else {
          callback();
        }
      }, 500);
    };
    return {
      activeNames: ["score"],
      activeNames1: ["score"],
      activeName: ["score"],
      activeName2: ["score"],
      groupsList: [],
      areasList: [],
      teamsList: [],
      subTeamsList: [],
      currentEditType: "",
      currentIndex: 0,
      currentList: [],
      currentAllocationItems: [],
      dialogVisibleOfEdit: false,
      searchUserId: "",
      companySourceList: [],
      happyNewsForm: {
        candidateNameItem: { name: null, isShow: true },
        companyNameItem: { name: null, isShow: true },
        companySourceItem: { name: null, isShow: true },
        offerTimeItem: { name: null, isShow: true },
        entryTimeItem: { name: null, isShow: true },
        positionItem: { name: null, isShow: true },
        feeStandard: null,
        performanceIndustryIds: [],
        moneyItem: { name: 0, isShow: true },
        offerMoneyItem: { name: 0, isShow: true },
        display: false,
        performanceAllocations: [],
        performanceOthers: [],
        projectResumeId: 0,
        offerSalaries: [
          // {
          //   structure: null,
          //   amount: 0,
          //   count: 0,
          //   totalAmount: 0,
          // },
        ],
        addToSchedule: true,
      },
      happyNewsFormRule: {
        "moneyItem.name": [
          {
            required: true,
            message: "请输入金额，仅限两位小数",
            trigger: "blur",
          },
          {
            pattern: /^\d{1,9}(\.\d{1,2})?$/,
            message: "金额精确到两位小数点,不超过1000000000",
          },
        ],
        "positionItem.name": [
          { required: true, message: "职位不能为空", trigger: "blur" },
        ],
        "companySourceItem.name": [
          { required: true, message: "客户来源不能为空", trigger: "change" },
        ],
        "offerTimeItem.name": [
          { required: true, message: "offer时间不能为空", trigger: "change" },
        ],
        "entryTimeItem.name": [
          { required: true, message: "预到岗时间不能为空", trigger: "change" },
        ],
        "offerMoneyItem.name": [
          {
            required: true,
            validator: checkCent,
            trigger: "blur",
            message: "请输入候选人年薪",
          },
        ],
        feeStandard: [
          { required: true, trigger: "blur", message: "收费标准不能为空" },
        ],
        performanceIndustryIds: [
          {
            required: true,
            message: "业绩归属行业不能为空",
            trigger: "change",
          },
        ],
      },
      defaultKPIList: [],
      userList: [],
      defaultPerformanceAllocations: null,
      srType: [
        {
          name: "实习生",
          id: "实习生",
        },
        {
          name: "Researcher",
          id: "Researcher",
        },
      ],
      ResearcherList: [],
      options: [
        { value: "基本薪资", label: "基本薪资" },
        { value: "补贴", label: "补贴" },
        { value: "股权", label: "股权" },
        { value: "固定奖金", label: "固定奖金" },
        { value: "自定义", label: "自定义" },
      ],
      ColumnInList: [], //行业列表
      props1: {
        value: "id",
        label: "name",
        children: "children",
        multiple: false,
        checkStrictly: false,
      },
    };
  },
  computed: {
    totalPercent: function () {
      let totalPercent = 0;
      for (let i in this.happyNewsForm.performanceAllocations) {
        if (this.happyNewsForm.performanceAllocations[i].percent) {
          totalPercent += this.happyNewsForm.performanceAllocations[i].percent;
        }
      }
      return totalPercent;
    },
    offertotal: {
      get() {
        let offertotal = 0;
        for (let i in this.happyNewsForm.offerSalaries) {
          this.happyNewsForm.offerSalaries[i].totalAmount = (
            Number(this.happyNewsForm.offerSalaries[i].amount) *
            Number(this.happyNewsForm.offerSalaries[i].count)
          ).toFixed(2);
          if (this.happyNewsForm.offerSalaries[i].totalAmount) {
            offertotal += Number(
              this.happyNewsForm.offerSalaries[i].totalAmount
            );
          }
        }
        return Number(offertotal).toFixed(2);
      },
      set() {},
    },
    total: {
      get() {
        let total = 0;
        if (
          this.currentAllocationItems &&
          this.currentAllocationItems.length > 0
        ) {
          this.currentAllocationItems.forEach((item) => {
            if (item.departmentId && item.percent) {
              total += item.percent;
            }
          });
        }
        return total;
      },
      set() {},
    },
    percentForm() {
      let percentForm = {};
      this.currentAllocationItems.forEach((item, index) => {
        percentForm["departmentId" + index] = item.departmentId;
        percentForm["percent" + index] = item.percent;
      });
      return percentForm;
    },
    ruleForm() {
      let ruleForm = {};
      this.currentAllocationItems.forEach((item, index) => {
        ruleForm["departmentId" + index] = [
          { required: true, message: "内容不能为空", trigger: "change" },
        ];
        ruleForm["percent" + index] = [
          { required: true, message: "内容不能为空", trigger: "change" },
          {
            pattern: /^(([1-9][0-9]?)|100)$/,
            message: "范围1-100",
            trigger: "change",
          },
        ];
      });
      return ruleForm;
    },
  },
  created() {
    // 获取喜报初始分配方案
    this.offerGoodnewsDefaultData();
    // 加载用户列表
    this.loadUserBase();
    // 获取组织框架中的地区
    this.getCompanySourceList();
    this.getGroups(); // 获取集团
    this.getAreas(); // 获取地区
    this.getTeams(); // 获取团队
    this.getSubTeams(); // 获取子团队
    this.loadColumnInList("行业"); //获取行业列表
  },
  watch: {},
  methods: {
    handlePositionBlur() {
      this.happyNewsForm.position.name =
        this.happyNewsForm.position.name.trim();
    },
    changeItem: function (val, index) {
      let defaultPerformanceAllocations = JSON.parse(
        this.defaultPerformanceAllocations
      );
      for (let x = 0; x < this.ResearcherList.length; x++) {
        if (this.ResearcherList[x].id) {
          if (
            this.ResearcherList[x].id !==
            this.happyNewsForm.performanceAllocations[x].id
          ) {
            for (let i = 0; i < defaultPerformanceAllocations.length; i++) {
              if (
                defaultPerformanceAllocations[i].id == this.ResearcherList[x].id
              ) {
                defaultPerformanceAllocations[i].rdisabled = false;
              }
              if (defaultPerformanceAllocations[i].id == val) {
                defaultPerformanceAllocations[i].rdisabled = true;
                this.$set(
                  this.happyNewsForm.performanceAllocations,
                  index,
                  defaultPerformanceAllocations[i]
                );
              }
            }
            this.defaultPerformanceAllocations = JSON.stringify(
              defaultPerformanceAllocations
            );
          }
        } else {
          for (let i = 0; i < defaultPerformanceAllocations.length; i++) {
            if (defaultPerformanceAllocations[i].id == val) {
              defaultPerformanceAllocations[i].rdisabled = true;
              this.$set(
                this.happyNewsForm.performanceAllocations,
                index,
                defaultPerformanceAllocations[i]
              );
            }
          }
          this.defaultPerformanceAllocations = JSON.stringify(
            defaultPerformanceAllocations
          );
        }
      }
      if (!this.happyNewsForm.performanceAllocations[index].allocationItems) {
        this.happyNewsForm.performanceAllocations[index].allocationItems = {
          groups: [],
          areas: [],
          teams: [],
          subTeams: [],
        };
      } else {
        Object.keys(
          this.happyNewsForm.performanceAllocations[index].allocationItems
        ).map((i) => {
          if (
            !this.happyNewsForm.performanceAllocations[index].allocationItems[i]
          ) {
            return (this.happyNewsForm.performanceAllocations[
              index
            ].allocationItems[i] = []);
          }
        });
      }
      this.ResearcherList = JSON.parse(
        JSON.stringify(this.happyNewsForm.performanceAllocations)
      );
    },
    delPercentList(index) {
      if (this.happyNewsForm.performanceAllocations[index].userId) {
        this.$confirm("是否删除该项?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          customClass: "altCls",
          type: "warning",
        })
          .then(() => {
            let data = JSON.parse(this.defaultPerformanceAllocations);
            for (var i = 0; i < data.length; i++) {
              if (
                data[i].id ==
                this.happyNewsForm.performanceAllocations[index].id
              ) {
                data[i].rdisabled = false;
              }
            }
            this.defaultPerformanceAllocations = JSON.stringify(data);
            this.happyNewsForm.performanceAllocations.splice(index, 1);
            this.ResearcherList.splice(index, 1);
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          })
          .catch(() => {
            // this.$message({
            //   type: "info",
            //   message: "已取消删除",
            // });
          });
      } else {
        let data = JSON.parse(this.defaultPerformanceAllocations);
        for (var i = 0; i < data.length; i++) {
          if (
            data[i].id == this.happyNewsForm.performanceAllocations[index].id
          ) {
            data[i].rdisabled = false;
          }
        }
        this.defaultPerformanceAllocations = JSON.stringify(data);
        this.happyNewsForm.performanceAllocations.splice(index, 1);
        this.ResearcherList.splice(index, 1);
      }
    },
    delResearcherList(index) {
      this.happyNewsForm.performanceOthers.splice(index, 1);
    },
    loadUserBase() {
      //获取用户列表
      nameAndStatusOptions().then((res) => {
        if (res.success) {
          this.userList = res.result;
        }
      });
    },
    offerGoodnewsDefaultData() {
      let req = null;
      if (this.inpath == "happyNewsApproval") {
        req = offerGoodnewsApproval(this.projectResumeId);
      } else {
        req = offerGoodnewsDefaultData({
          projectResumeId: this.projectResumeId,
        });
      }
      req.then((res) => {
        if (res.success) {
          let performanceIdList = [];
          this.happyNewsForm = res.result;
          this.happyNewsForm.addToSchedule = true;
          this.$emit("getisApproval", res.result.status);
          if (this.happyNewsForm.offerTimeItem.name == null) {
            this.happyNewsForm.offerTimeItem.name = new Date().Format(
              "yyyy-MM-dd"
            );
          } else {
            this.happyNewsForm.offerTimeItem = res.result.offerTimeItem;
          }
          if (this.happyNewsForm.offerSalaries) {
            if (this.happyNewsForm.offerSalaries.length == 0) {
              this.happyNewsForm.offerSalaries = [
                {
                  structure: null,
                  amount: 0,
                  count: 0,
                  totalAmount: 0,
                },
              ];
            } else {
              this.happyNewsForm.offerSalaries;
            }
          }
          // this.happyNewsForm.offerSalaries
          //   ? this.happyNewsForm.offerSalaries.length == 0
          //     ? (this.happyNewsForm.offerSalaries = [
          //         {
          //           structure: null,
          //           amount: 0,
          //           count: 0,
          //           totalAmount: 0,
          //         },
          //       ])
          //     : this.happyNewsForm.offerSalaries
          //   : "";
          this.ResearcherList = JSON.parse(
            JSON.stringify(res.result.performanceAllocations)
          );
          this.happyNewsForm.performanceOthers = !this.happyNewsForm
            .performanceOthers
            ? [
                {
                  type: "",
                  id: "",
                  percent: 0,
                  teamId: null,
                },
              ]
            : res.result.performanceOthers;
          this.happyNewsForm.performanceAllocations.map((item) => {
            item.rdisabled = true;
            if (!item.allocationItems) {
              return (item.allocationItems = {
                groups: [],
                areas: [],
                teams: [],
                subTeams: [],
              });
            } else {
              Object.keys(item.allocationItems).map((i) => {
                if (!item.allocationItems[i]) {
                  return (item.allocationItems[i] = []);
                }
              });
            }
          });
          this.defaultPerformanceAllocations = JSON.stringify(
            res.result.defaultAllocations
          );
          res.result.performanceAllocations.forEach((item) => {
            performanceIdList.push(
              this.userList.find((_item) => {
                return _item.id == item.userId;
              }) || {}
            );
          });
        }
      });
    },

    add() {
      this.happyNewsForm.performanceAllocations.push({
        allocationItems: { groups: [], areas: [], teams: [], subTeams: [] },
        roleName: "",
        userId: "",
        percent: 0,
        version: 0,
      });
      this.ResearcherList.push({
        allocationItems: { groups: [], areas: [], teams: [], subTeams: [] },
        roleName: "",
        userId: "",
        percent: 0,
        version: 0,
      });
    },
    addR() {
      let data = this.happyNewsForm.performanceOthers;
      data.push({
        userId: "",
        id: "",
        percent: 0,
        teamId: null,
      });
      this.happyNewsForm.performanceOthers = data;
      this.$forceUpdate();
    },
    saveHappyNews: function () {
      return new Promise((resolve, reject) => {
        this.$refs.happyNewsForm.validate((valid) => {
          if (valid) {
            let that = this;
            for (let o in that.happyNewsForm.offerSalaries) {
              if (
                that.happyNewsForm.offerSalaries[o].structure ||
                that.happyNewsForm.offerSalaries[o].amount == 0 ||
                that.happyNewsForm.offerSalaries[o].count == 0 ||
                that.happyNewsForm.offerSalaries[o].totalAmount == 0 ||
                that.happyNewsForm.offerSalaries[o].structure == null ||
                that.happyNewsForm.offerSalaries[o].structure == ""
              ) {
                if (
                  that.happyNewsForm.offerSalaries[o].structure == null &&
                  that.happyNewsForm.offerSalaries[o].amount == 0 &&
                  that.happyNewsForm.offerSalaries[o].count == 0
                ) {
                  reject();
                  that.$message.error("薪资情况请至少填写一行");
                  return;
                }
                if (
                  that.happyNewsForm.offerSalaries[o].structure == null ||
                  that.happyNewsForm.offerSalaries[o].structure == ""
                ) {
                  reject();
                  that.$message.error("请填写薪资结构");
                  return;
                }
                if (that.happyNewsForm.offerSalaries[o].amount == 0) {
                  reject();
                  that.$message.error("请填写薪资金额");
                  return;
                }
                if (that.happyNewsForm.offerSalaries[o].count == 0) {
                  reject();
                  that.$message.error("请填写数量");
                  return;
                }
              }
            }
            if (this.totalPercent != 100) {
              reject();
              this.$message.error("当前分配比例总和不是100%");
              return;
            }
            for (let i in this.happyNewsForm.performanceAllocations) {
              if (!this.happyNewsForm.performanceAllocations[i].id) {
                reject();
                this.$message.error("业绩分配人角色不能为空");
                return;
              }
              if (!this.happyNewsForm.performanceAllocations[i].userId) {
                reject();
                this.$message.error("业绩分配人不能为空");
                return;
              }
              if (
                !Number.isInteger(
                  this.happyNewsForm.performanceAllocations[i].percent
                )
              ) {
                reject();
                this.$message.error("请输入数字值");
                return;
              }
            }
            for (let y in that.happyNewsForm.performanceOthers) {
              if (that.happyNewsForm.performanceOthers[y].type) {
                if (!that.happyNewsForm.performanceOthers[y].userId) {
                  reject();
                  that.$message.error("实习生/Researcher业绩分配人不能为空");
                  return;
                }
                if (!that.happyNewsForm.performanceOthers[y].teamId) {
                  reject();
                  that.$message.error("实习生/Researcher团队不能为空");
                  return;
                }
              }
              if (that.happyNewsForm.performanceOthers[y].userId) {
                if (!that.happyNewsForm.performanceOthers[y].type) {
                  reject();
                  that.$message.error(
                    "实习生/Researcher业绩分配人角色不能为空"
                  );
                  return;
                }
              }
              if (
                that.happyNewsForm.performanceOthers[y].type == "Researcher"
              ) {
                if (
                  !Number.isInteger(
                    that.happyNewsForm.performanceOthers[y].percent
                  ) ||
                  that.happyNewsForm.performanceOthers[y].percent == 0
                ) {
                  reject();
                  that.$message.error("请输入Researcher比例");
                  return;
                }
              }
            }
            // return
            this.$emit("changeSaveLoading", true);
            offerGoodnewsSend(this.happyNewsForm).then((res) => {
              if (res.success) {
                if (res.result.type == "未配置审批流") {
                  this.$confirm(
                    '<p style="font-weight:bold">未配置喜报审批流，请联系管理员</p><p style="color:#999">审批流配置后将自动流转给审批人进行喜报审批</p>',
                    "提示",
                    {
                      confirmButtonText: "确定",
                      showClose: false,
                      customClass: "altCls",
                      dangerouslyUseHTMLString: true,
                      showCancelButton: false,
                      closeOnClickModal: false,
                    }
                  ).then(() => {
                    this.$message.success("操作成功！");
                    this.$emit("changeSaveLoading", false);
                    this.$emit("loadList");
                    this.$refs.happyNewsForm.resetFields();
                  });
                  // .catch(() => {
                  //   // this.$message({
                  //   //   type: "info",
                  //   //   message: "已取消",
                  //   // });
                  // });
                } else if (res.result.type == "成功需审批") {
                  this.$confirm(
                    '<p style="font-weight:bold">喜报已提交至' +
                      res.result.message +
                      "审批，请跟进</p>",
                    "提示",
                    {
                      confirmButtonText: "确定",
                      showClose: false,
                      customClass: "altCls",
                      dangerouslyUseHTMLString: true,
                      showCancelButton: false,
                      closeOnClickModal: false,
                    }
                  ).then(() => {
                    this.$message.success("操作成功！");
                    this.$emit("changeSaveLoading", false);
                    this.$emit("loadList");
                    this.$refs.happyNewsForm.resetFields();
                  });
                  // .catch(() => {
                  //   // this.$message({
                  //   //   type: "info",
                  //   //   message: "已取消",
                  //   // });
                  // });
                } else {
                  reject();
                  this.$message.success("操作成功！");
                  this.$emit("changeSaveLoading", false);
                  this.$emit("loadList");
                  this.$refs.happyNewsForm.resetFields();
                }
              }
              resolve();
            });
          } else {
            reject();
            return false;
          }
        });
      });
    },
    saveHappyNewsApproval: function () {
      return new Promise((resolve, reject) => {
        this.$refs.happyNewsForm.validate((valid) => {
          if (valid) {
            let that = this;
            if (this.totalPercent != 100) {
              reject();
              this.$message.error("当前分配比例总和不是100%");
              return;
            }
            for (let i in this.happyNewsForm.performanceAllocations) {
              if (!this.happyNewsForm.performanceAllocations[i].id) {
                reject();
                this.$message.error("业绩分配人角色不能为空");
                return;
              }
              if (!this.happyNewsForm.performanceAllocations[i].userId) {
                reject();
                this.$message.error("业绩分配人不能为空");
                return;
              }
              if (
                !Number.isInteger(
                  this.happyNewsForm.performanceAllocations[i].percent
                )
              ) {
                reject();
                this.$message.error("请输入数字值");
                return;
              }
            }
            for (let y in that.happyNewsForm.performanceOthers) {
              if (that.happyNewsForm.performanceOthers[y].type) {
                if (!that.happyNewsForm.performanceOthers[y].userId) {
                  reject();
                  that.$message.error("实习生/Researcher业绩分配人不能为空");
                  return;
                }
                if (!that.happyNewsForm.performanceOthers[y].teamId) {
                  reject();
                  that.$message.error("实习生/Researcher团队不能为空");
                  return;
                }
              }
              if (that.happyNewsForm.performanceOthers[y].userId) {
                if (!that.happyNewsForm.performanceOthers[y].type) {
                  reject();
                  that.$message.error(
                    "实习生/Researcher业绩分配人角色不能为空"
                  );
                  return;
                }
              }
              if (
                that.happyNewsForm.performanceOthers[y].type == "Researcher"
              ) {
                if (
                  !Number.isInteger(
                    that.happyNewsForm.performanceOthers[y].percent
                  ) ||
                  that.happyNewsForm.performanceOthers[y].percent == 0
                ) {
                  reject();
                  that.$message.error("请输入Researcher比例");
                  return;
                }
              }
            }
            this.$emit("changeSaveLoading", true);
            offerGoodnewsApprovalPass(this.happyNewsForm).then((res) => {
              if (res.success) {
                this.$message.success(res.message);
                this.$emit("changeSaveLoading", false);
                this.$emit("loadList");
                this.$emit("closeThis");
                this.$refs.happyNewsForm.resetFields();
                reject();
              } else {
                this.$emit("changeSaveLoading", false);
              }
              resolve();
            });
          } else {
            reject();
            return false;
          }
        });
      });
    },
    getCompanySourceList() {
      // 获取客户来源数据
      getCompanySource().then((res) => {
        if (res.success) {
          res.result.forEach((item, index) => {
            this.$set(this.companySourceList, index, {
              value: item,
              label: item,
            });
          });
        }
      });
    },
    // 编辑分配比例
    editOrganization(type, index) {
      this.dialogVisibleOfEdit = true;
      this.currentEditType = type;
      this.currentIndex = index;
      this.total = 0;
      if (type == "groups") {
        this.currentList = this.groupsList;
        this.currentAllocationItems = JSON.parse(
          JSON.stringify(
            this.happyNewsForm.performanceAllocations[this.currentIndex]
              .allocationItems.groups
          )
        );
      } else if (type == "areas") {
        this.currentList = this.areasList;
        this.currentAllocationItems = JSON.parse(
          JSON.stringify(
            this.happyNewsForm.performanceAllocations[this.currentIndex]
              .allocationItems.areas
          )
        );
      } else if (type == "teams") {
        this.currentList = this.teamsList;
        this.currentAllocationItems = JSON.parse(
          JSON.stringify(
            this.happyNewsForm.performanceAllocations[this.currentIndex]
              .allocationItems.teams
          )
        );
      } else if (type == "subTeams") {
        this.currentList = this.subTeamsList;
        this.currentAllocationItems = JSON.parse(
          JSON.stringify(
            this.happyNewsForm.performanceAllocations[this.currentIndex]
              .allocationItems.subTeams
          )
        );
      }
    },
    changeDepartment(e, i) {
      this.currentList.forEach((item) => {
        if (item.id == e) {
          i.departmentName = item.name;
        }
      });
    },
    // 添加分配比例项
    addPercent() {
      this.currentAllocationItems.push({
        departmentId: "",
        departmentName: "",
        percent: "",
      });
    },
    // 删除分配比例项
    delPercent(i) {
      this.currentAllocationItems.splice(i, 1);
    },
    checkUser(e, index) {
      if (e) {
        departAllocations({ userId: e }).then((res) => {
          if (res.success) {
            this.happyNewsForm.performanceAllocations[index].allocationItems =
              res.result;
            Object.keys(
              this.happyNewsForm.performanceAllocations[index].allocationItems
            ).map((i) => {
              if (
                !this.happyNewsForm.performanceAllocations[index]
                  .allocationItems[i]
              ) {
                return (this.happyNewsForm.performanceAllocations[
                  index
                ].allocationItems[i] = []);
              }
            });
          }
        });
      } else {
        this.happyNewsForm.performanceAllocations[index].allocationItems = {
          groups: [],
          areas: [],
          teams: [],
          subTeams: [],
        };
      }
    },
    // 保存分配比例
    saveEditPercent() {
      this.$refs["percentForm"].validate((valid) => {
        if (valid) {
          if (this.total != 100 && this.total > 0) {
            this.$message.error("当前分配比例总和不是100%");
            return;
          }
          const departmentIdArr = [];
          let flag = false;
          this.currentAllocationItems.forEach((item) => {
            if (item.departmentId && item.percent) {
              departmentIdArr.push(item.departmentId);
              for (let i = 0; i < departmentIdArr.length - 1; i++) {
                if (departmentIdArr[i] == item.departmentId) {
                  flag = true;
                  break;
                }
              }
            }
          });
          if (flag) {
            this.$message.error("分配比例中有重复字段");
            return;
          }
          this.dialogVisibleOfEdit = false;
          this.currentAllocationItems = this.currentAllocationItems.filter(
            (item) => {
              return item.departmentId && item.percent;
            }
          );
          if (this.currentEditType == "groups") {
            this.happyNewsForm.performanceAllocations[
              this.currentIndex
            ].allocationItems.groups = JSON.parse(
              JSON.stringify(this.currentAllocationItems)
            );
          } else if (this.currentEditType == "areas") {
            this.happyNewsForm.performanceAllocations[
              this.currentIndex
            ].allocationItems.areas = JSON.parse(
              JSON.stringify(this.currentAllocationItems)
            );
          } else if (this.currentEditType == "teams") {
            this.happyNewsForm.performanceAllocations[
              this.currentIndex
            ].allocationItems.teams = JSON.parse(
              JSON.stringify(this.currentAllocationItems)
            );
          } else if (this.currentEditType == "subTeams") {
            this.happyNewsForm.performanceAllocations[
              this.currentIndex
            ].allocationItems.subTeams = JSON.parse(
              JSON.stringify(this.currentAllocationItems)
            );
          }
        }
      });
    },
    // 获取集团
    getGroups() {
      getGroupsByType().then((res) => {
        if (res.success) {
          this.groupsList = res.result;
        }
      });
    },
    // 获取地区
    getAreas() {
      getAreasByType().then((res) => {
        if (res.success) {
          this.areasList = res.result;
        }
      });
    },
    // 获取团队
    getTeams() {
      getTeamsByType().then((res) => {
        if (res.success) {
          this.teamsList = res.result;
        }
      });
    },
    // 获取子团队
    getSubTeams() {
      getSubTeamsByType().then((res) => {
        if (res.success) {
          this.subTeamsList = res.result;
        }
      });
    },
    //新增offer薪资列
    addNewTable() {
      let data = {
        structure: null,
        amount: 0,
        count: 0,
        totalAmount: null,
      };
      this.happyNewsForm.offerSalaries.push(data);
    },
    //删除offer薪资列
    delTable(index) {
      this.happyNewsForm.offerSalaries.splice(index, 1);
    },
    //获取行业列表
    loadColumnInList(type) {
      this.ColumnInList = [];
      getCommonColumnList({ type: type }).then((res) => {
        if (res.success) {
          this.ColumnInList = res.result;
        }
      });
    },
  },
};
</script>
<style  lang='less'>
.happyNews {
  .heards {
    background-color: #f5f7fa;
    padding: 15px;
    .tips {
      height: 35px;
      line-height: 35px;
      font-size: 13px;
      color: #606266;
      padding: 0 10px;
      margin: 15px;
      background-color: #fff9e6;
      border: 1px solid #ffdf94;
      i {
        color: #ff9900;
        font-size: 16px;
        margin-right: 10px;
        vertical-align: baseline;
      }
    }
    .scoreTitle {
      margin: 0;
      border: 1px solid #e5e5e5 !important;
      .el-collapse-item__content {
        padding-bottom: 10px;
      }
      .scoreTotal {
        font-size: 14px;
        padding: 0 10px;
        color: #333;
        width: 100%;
        line-height: 35px;
        background-color: #f0f0f0;
        font-weight: bold;
        span {
          font-weight: normal;
          color: #606266;
          s {
            text-decoration: none;
            color: #e58a00;
          }
        }
        .addSearchBtn {
          float: right;
          color: #526ecc;
          font-size: 12px;
          i {
            font-size: 14px;
          }
        }
      }
    }
    .el-collapse-item__header {
      height: 35px;
      line-height: 35px;
      border: none;
      background-color: #f0f0f0;
    }
  }

  .searchList {
    font-size: 0;
    .scoreTitle {
      margin: 0;
      border: 1px solid #e5e5e5 !important;
      .el-collapse-item__content {
        padding-bottom: 10px;
      }
      .scoreTotal {
        font-size: 14px;
        padding: 0 10px;
        color: #333;
        width: 100%;
        line-height: 35px;
        background-color: #f0f0f0;
        font-weight: bold;
        span {
          font-weight: normal;
          color: #606266;
          s {
            text-decoration: none;
            color: #e58a00;
          }
        }
        .addSearchBtn {
          float: right;
          color: #526ecc;
          font-size: 12px;
          i {
            font-size: 14px;
          }
        }
      }
    }
    .liStyle {
      height: 40px;
      width: 100%;
      margin: 0;
      padding: 0 15px 0 10px;
    }
    .el-collapse-item__header {
      height: 35px;
      line-height: 35px;
      border: none;
      background-color: #f0f0f0;
    }
    .el-form-item__label,
    .el-form-item__content {
      font-size: 12px;
      line-height: normal;
      line-height: 32px;
    }
    .el-icon-delete {
      color: #999;
      font-size: 13px;
      &:hover {
        color: #e60012;
      }
    }
  }
  .icon-wenhao-xiangsu {
    font-weight: normal;
    font-size: 16px;
    color: #898989;
    margin-left: 10px;
  }
}
.titleOfPerformance {
  position: relative;
  margin-bottom: 8px;
  border: 1px solid #e5e5e5;
  .el-collapse-item__header::after {
    content: none !important;
  }

  .el-collapse-item__wrap {
    border: none;
  }
  .el-collapse-item__content {
    padding: 0 !important;
  }
}
.contentOfPerformance {
  background-color: #fff;
  padding: 15px 10px;
  .el-form-item {
    padding: 5px 0;
    margin: 0;
    .el-form-item__label {
      font-size: 14px;
    }
  }
  .canEdit {
    padding: 0 10px;
    i {
      display: none;
    }
    &:hover {
      background-color: #f9f9f9;
      i {
        display: inline-block;
      }
    }
  }
}
.editPercent {
  .el-dialog__body {
    padding: 10px 40px 20px;
    .el-form-item__error {
      padding-top: 0 !important;
    }
  }
}
.iconOfClose {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #606266;
  &:hover {
    color: #e60012;
  }
}
</style>