import { getStore } from './storage';
const hasMenuPage = function (authority) {

    let userinfo = JSON.parse(getStore('useInfo'))||''
    let hasPermission = []
    if(userinfo){
        userinfo.permissions.forEach(res => {
            hasPermission.push(res.authority)
        });
        if (hasPermission.includes(authority)) {
            return true
        } else {
            return false
        }
    }

};

export default hasMenuPage;
