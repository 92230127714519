import { getStore } from './storage';
const hasPermission = {
    install (Vue, options) {
        Vue.directive('has', {
            inserted (el, binding) {
                let permTypes = getStore("permiss");
                if (!permTypes.includes(binding.value)) {
                    el.parentNode.removeChild(el);
                }
            }
        });
    }
};

export default hasPermission;
